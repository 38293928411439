import { httpService } from "..";
import ToasterFunc from "../../components/common/toaster/Toaster";
import { API_CONSTANTS } from "../../constants";

class StripeService {
  async createSessionCheckout(data) {
    try {
      const response = await httpService.post(API_CONSTANTS.STRIPE_SESSION_CHECKOUT, data);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async createSessionCheckoutForAppointment(data) {
    try {
      const response = await httpService.post(API_CONSTANTS.STRIPE_SESSION_CHECKOUT_FOR_APPOINTMENT, data);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

};



export default new StripeService();