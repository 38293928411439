import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../../constants";
import AutoComplete from "./AutoComplete";
// import AutoComplete from "../../authentication/sign-up-as-patient/AutoComplete";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";

function DeliveryLocation() {
  // const {
  //   ready,
  //   value,
  //   suggestions: { status, data },
  //   setValue,
  //   clearSuggestions,
  // } = usePlacesAutocomplete({
  //   requestOptions: {
  //     /* Define search scope here */
  //   },
  //   debounce: 300,
  // });
  // const ref = useOnclickOutside(() => {
  //   // When user clicks outside of the component, we can dismiss
  //   // the searched suggestions by calling this method
  //   clearSuggestions();
  // });
  let history = useHistory();
  let location = useLocation();
  console.log("location", location);

  const [Address, setAddress] = useState("");
  const [AddressErr, setAddressErr] = useState("");
  const [LastName, setLastName] = useState("");
  const [Lat, setLat] = useState("");
  const [Lng, setLng] = useState("");
  const [GooglePlace, setGooglePlace] = useState({
    search: "",
    address: "",
    landmark: "",
    pincode: "",
  });
  const [GooglePlaceErr, setGooglePlaceErr] = useState({
    searchErr: "",
    addressErr: "",
    landmarkErr: "",
    pincodeErr: "",
  });

  useEffect(() => {
    if ((GooglePlace.search != "", GooglePlace.address != "" && GooglePlace.pincode != "")) {
      setGooglePlaceErr({
        search: "",
        addressErr: "",
        pincodeErr: "",
      });
    }
  }, [GooglePlace]);

  const handlePlaceChanged = (place) => {
    let location = place?.geometry?.location;
    let address = place?.formatted_address;
    let lat = location?.lat();
    let lng = location?.lng();

    console.log("place", place);
    // if (data === "search") {

    // } else

    // setAddress(address);

    setLat(lat);
    setLng(lng);
    console.log("ssssssssssssssssssssss");
    if (place) {
      console.log("ssssssssssssssssssssss");
      place.address_components.map((item) => {
        if (item.types[0] === "administrative_area_level_1") {
          console.log("yes, it is coming ===> 92", item.long_name);
          setGooglePlace({
            // ...GooglePlace,
            address: place.formatted_address,
          });
        }
        if (item.types[0] === "locality" || item.types[0] === "postal_town") {
          console.log("yes, it is coming ===> 92", item.long_name);
        }
        if (item.types[0] === "postal_code") {
          console.log("yes, it is coming ===> 92", item.long_name);
          setGooglePlace({
            // ...GooglePlace,
            pincode: item.long_name,
          });
        }
      });
      let address = `${place && place.name && place.name},${place && place.formatted_address && place.formatted_address}`;
      setAddress(address);
      // setGooglePlace({
      //   ...GooglePlace,
      //   address: place.formatted_address,
      // });
      // setGooglePlace({
      //   pincode: value,
      // });
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("Deliverylocationdata")) {
      console.log(JSON.parse(sessionStorage.getItem("Deliverylocationdata")));
      setAddress(JSON.parse(sessionStorage.getItem("Deliverylocationdata"))[0]?.searchData);
      setGooglePlace({
        // search: JSON.parse(sessionStorage.getItem("Deliverylocationdata"))[0]?.searchData,
        address: JSON.parse(sessionStorage.getItem("Deliverylocationdata"))[0]?.addressData.map((item) => item),
        landmark: JSON.parse(sessionStorage.getItem("Deliverylocationdata"))[0]?.landmarkData,
        pincode: JSON.parse(sessionStorage.getItem("Deliverylocationdata"))[0]?.pincodeData,
      });
      setLat(JSON.parse(sessionStorage.getItem("Deliverylocationdata"))[0]?.lat);
      setLng(JSON.parse(sessionStorage.getItem("Deliverylocationdata"))[0]?.long);
    }
  }, [sessionStorage.getItem("Deliverylocationdata")]);

  console.log("Address", Address);

  console.log(Address, Address.split(",").slice(1, Address.split(",").length - 1), GooglePlace.pincode);

  const handleAddress = (e, data) => {
    let value = e.target.value;
    if (data === "address") {
      setGooglePlace({
        ...GooglePlace,
        address: value,
      });
    }
    if (data === "landmark") {
      setGooglePlace({
        ...GooglePlace,
        landmark: value,
      });
    }
    if (data === "pincode") {
      setGooglePlace({
        ...GooglePlace,
        pincode: value,
      });
    }
  };

  const handleSave = () => {
    if (!Address && !GooglePlace.address && !GooglePlace.pincode) {
      setGooglePlaceErr({
        ...GooglePlaceErr,
        searchErr: "*Please search here",
        addressErr: "*Please provide address",
        pincodeErr: "*Please provide pincode",
      });
    } else if (!GooglePlace.search) {
      setGooglePlaceErr({
        ...GooglePlaceErr,
        searchErr: "*Please provide address",
      });
    } else if (GooglePlace.address === "") {
      setGooglePlaceErr({
        ...GooglePlaceErr,
        addressErr: "*Please provide address",
      });
    } else if (GooglePlace.pincode === "") {
      setGooglePlaceErr({
        ...GooglePlaceErr,
        pincodeErr: "*Please provide pincode",
      });
    } else {
      setGooglePlaceErr({
        searchErr: "",
        addressErr: "",
        pincodeErr: "",
      });
    }
    let totalDetailArr = [];
    totalDetailArr.push({
      ...location.state,
      searchData: Address ? Address : "",
      addressData: Address ? Address.split(",").slice(1, Address.split(",").length - 1) : "",
      landmarkData: GooglePlace.landmark ? GooglePlace.landmark : "",
      pincodeData: GooglePlace.pincode ? GooglePlace.pincode : "",
      lat: Lat,
      long: Lng,
    });

    sessionStorage.setItem("Deliverylocationdata", JSON.stringify(totalDetailArr));
    if (GooglePlace.search != "" && GooglePlace.address != "" && GooglePlace.pincode != "") {
      history.push({ pathname: ROUTES_CONSTANTS.PATIENT_BOOK_LABORATORY, state: totalDetailArr });
    }
  };
  //   onClick={() => history.push(ROUTES_CONSTANTS.PATIENT_BOOK_LABORATORY)}
  //   console.log("City", City);
  console.log(GooglePlace.landmark);

  // const handleInput = (e) => {
  //   // Update the keyword of the input element
  //   setValue(e.target.value);
  //   setAddress(e.target.value);
  // };

  // const handleSelect = ({ description }) => () => {
  //   // When user selects a place, we can replace the keyword without request data from API
  //   // by setting the second parameter to "false"
  //   setValue(description, false);
  //   clearSuggestions();
  //   console.log("description", description);
  //   setAddress(description);

  //   // Get latitude and longitude via utility functions
  //   getGeocode({ address: description })
  //     .then((results) => getLatLng(results[0]))
  //     .then(({ lat, lng }) => {
  //       console.log("📍 Coordinates: ", { lat, lng });
  //       setLat(lat);
  //       setLng(lng);
  //     })
  //     .catch((error) => {
  //       console.log("😱 Error: ", error);
  //     });
  // };

  // const renderSuggestions = () =>
  //   data.map((suggestion) => {
  //     const {
  //       place_id,
  //       structured_formatting: { main_text, secondary_text },
  //     } = suggestion;

  //     return (
  //       <li key={place_id} onClick={handleSelect(suggestion)}>
  //         <strong>{main_text}</strong> <small>{secondary_text}</small>
  //       </li>
  //     );
  //   });

  return (
    <div className=" col-lg-8 col-xl-9">
      <div className="row">
        <div className="col-lg-12 ">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Select Delivery Location</h4>
            </div>
            <div className="card-body">
              <div className="form-group row">
                <div className="col-lg-12 ">
                  <span>
                    <i className="fa fa-map-marker mr-1" aria-hidden="true"></i>Use GPS to track Location
                  </span>
                  <AutoComplete
                    classNameName="form-control"
                    id="Address"
                    name="Address"
                    maxLength="60"
                    placeholder="Search"
                    data={Address}
                    onPlaceSelected={(Place) => {
                      handlePlaceChanged(Place);
                    }}
                  />
                  {/* <input class="form-control" name="address" maxLength="60" value={value} onChange={handleInput} disabled={!ready} placeholder="Address" />
                  {status === "OK" && <ul>{renderSuggestions()}</ul>}
                  {GooglePlaceErr.searchErr ? <span className="validation_error">{GooglePlaceErr.searchErr}</span> : null} */}
                </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <div className="form-group row">
                    <div className="col-lg-12 ">
                      <input type="text" className="form-control" value={GooglePlace.address} placeholder="Address" onChange={(e) => handleAddress(e, "address")} />
                      {GooglePlaceErr.addressErr ? <span className="validation_error">{GooglePlaceErr.addressErr}</span> : null}
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-12 ">
                      <input type="text" className="form-control" value={GooglePlace.landmark} placeholder="Landmark" maxLength="32" onChange={(e) => handleAddress(e, "landmark")} />
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-lg-12 ">
                      <input type="number" className="form-control" value={GooglePlace.pincode} placeholder="Pincode" onChange={(e) => handleAddress(e, "pincode")} />
                      {GooglePlaceErr.pincodeErr ? <span className="validation_error">{GooglePlaceErr.pincodeErr}</span> : null}
                    </div>
                  </div>
                </div>
              </div>

              <div className="mt-4 d-flex justify-content-center">
                {/* <!-- <a href="#" data-toggle="modal" className="btn cancel-outline  mr-2">Cancel</a> --> */}
                <button className="btn btn-primary w-25 " onClick={handleSave}>
                  Save & Continue
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliveryLocation;
