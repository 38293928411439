import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactStars from "react-stars";
import defaultImg from "../../../../../assets/img/default-img.png";
import { ROUTES_CONSTANTS } from "../../../../../constants";
import { patient } from "../../../../../redux/action";
import AddRatingModal from "./AddRatingModal";
import { useParams } from "react-router-dom";
import moment from "moment";
import ToasterFunc from "../../../../common/toaster/Toaster";

function CompletedAppDetails({ DocData }) {
  let history = useHistory();
  console.log("DocData", DocData);
  let { DocAppointmentId } = useParams();
  const [RatingModal, setRatingModal] = useState(false);
  const dispatch = useDispatch();
  // get patient details
  const PatientProfileInfo = async () => dispatch(patient.patientProfileDetailsAction());
  const PatientData = useSelector((state) => state?.patient?.patientProdileInfo);
  useEffect(() => {
    PatientProfileInfo();
  }, []);
  const setFav = async (payload) => dispatch(patient.setFavourite(payload));
  const [Bookmarktoggle, setBookmarktoggle] = useState(false);

  const handleScheduleApp = () => {
    history.push(`${ROUTES_CONSTANTS.PATIENT_BOOK_APPOINTMENT}/${DocData.serviceProviderId._id}`);
  };
  const handleToInbox = (patData) => {
    console.log("patientData", patData, "PatientData", PatientData);
    const receiver_id = patData?.serviceProviderId?._id;
    const idData = {
      senderid: PatientData?.userData?._id,
      receiverid: receiver_id,
      userType: "patient",
      path: "Through_appointmentdetails",
      patientData: patData,
    };
    history.push(ROUTES_CONSTANTS.PATIENT_MESSAGES, idData);
  };
  useEffect(() => {
    if (Bookmarktoggle) {
      console.log('eseE==>', DocData?.serviceProviderId?.isFavourite);
      if (PatAppointmentData.isFavourite) {
        ToasterFunc("success", "Added to favourite list");
      } else {
        ToasterFunc("success", "Removed from favourite list");
      }
    }
  }, [DocData?.serviceProviderId]);

  const handleBookmarked = async () => {
    setBookmarktoggle(true);
    const payload = {
      userId: DocData?.serviceProviderId?._id,
      favourite: !PatAppointmentData?.isFavourite,
    };
    console.log('==!!==>>',payload);
    const res = await setFav(payload);

    // HIT DETAILS API AFTER THAT
    if (res.status === 200) {
      // hit Appoitment details api here
      viewAppointmentDetail(DocAppointmentId);

    }
  };

  const PatAppointmentData = useSelector((state) => state?.patient?.patientViewAppointmentData?.appointmentDetails);

  console.log("Fav==>", PatAppointmentData?.isFavourite);

  useEffect(() => {
    if (DocAppointmentId) {
      viewAppointmentDetail(DocAppointmentId);
    }
    console.log('Pat_-_-_---> Patient Data',"DocAppointmentId",DocAppointmentId);
  }, [DocAppointmentId, RatingModal ]);

  const viewAppointmentDetail = async (payload) => dispatch(patient.viewAppointmentPatientAction(payload));

  const handleDocNavigation = (item) => {
    history.push(`${ROUTES_CONSTANTS.PATIENT_VIEW_DOCTOR_DETAILS}/${item?._id}`);
  };
  return (
    <div role="tabpanel" id="Appointments-d" class="tab-pane fade show active appointments">
      <div class="appointment-list">
        <div class="profile-info-widget">
          <a class="booking-doc-img" onClick={()=>handleDocNavigation(PatAppointmentData && PatAppointmentData.serviceProviderId)}>
            <img src={PatAppointmentData && PatAppointmentData.serviceProviderId && PatAppointmentData.serviceProviderId.profilePic ? PatAppointmentData.serviceProviderId.profilePic : defaultImg} alt="User Image" />
          </a>
          <div class="profile-det-info">
            <h3 onClick={()=>handleDocNavigation(PatAppointmentData && PatAppointmentData.serviceProviderId)}>
              <a>{PatAppointmentData && PatAppointmentData.serviceProviderId && PatAppointmentData.serviceProviderId.fullName ? PatAppointmentData.serviceProviderId.fullName : ""}</a>
            </h3>
            <div class="patient-details">
              <h5>${PatAppointmentData && PatAppointmentData.amount ? PatAppointmentData.amount : ""} Consultation Fee</h5>
              <h5>
                {PatAppointmentData && PatAppointmentData.serviceProviderId && PatAppointmentData.serviceProviderId.experience_in_years ? `${PatAppointmentData.serviceProviderId.experience_in_years} Years  ` : ""}
                {PatAppointmentData && PatAppointmentData.serviceProviderId && PatAppointmentData.serviceProviderId.experience_in_months ? `${PatAppointmentData.serviceProviderId.experience_in_months} Months` : ""}
                &nbsp;of Experience
              </h5>
              <a data-toggle="modal" data-target="#speciality-popup" data-dismiss="modal">
                <h6>{DocData?.serviceProviderId?.primary_specialtyId?.primary_specialty}</h6>
              </a>
              <div class=" rating ">
                <ReactStars count={5} value={DocData?.serviceProviderId?.rating} size={16} color2={"#ffd700"} edit={false} />
              </div>
            </div>
          </div>
        </div>
        <div className="fav-wapper">
        <div onClick={handleBookmarked} className={`${PatAppointmentData?.isFavourite ? "favourite pointer" :"unfavourite pointer"} sm-fav-bx`}>
            <i class={PatAppointmentData?.isFavourite ? "fa fa-bookmark" : "far fa-bookmark"}></i>  <span>{!PatAppointmentData?.isFavourite ? "Mark as favourite" : "Unmark as favourite"}</span>
          </div>
        <div class="appointment-action sm-appt-action">
          <a onClick={() => handleToInbox(PatAppointmentData)}  class="btn btn-sm bg-info-light">
            Send Message
          </a>
        </div>
        
        </div>
      </div>
      <div class="card">
        <div class="card-body">
          <h4>
            <b>Appointment details </b>
          </h4>
          <div>
            <span class="color-grey ">
              <b>Date:</b>
              &nbsp;
              {PatAppointmentData && PatAppointmentData.appointmentStartTime ? new Date(PatAppointmentData.appointmentStartTime).toLocaleDateString() : "--"}
            </span>
          </div>
          <div>
            <span class="color-grey">
              <b>Time:</b>
              &nbsp;
              {moment(PatAppointmentData && PatAppointmentData.appointmentStartTime).format("LT")} - {moment(PatAppointmentData && PatAppointmentData.appointmentEndTime).format("LT")}
              {/* {`${
                PatAppointmentData && PatAppointmentData.appointmentStartTime
                  ? `${new Date(PatAppointmentData.appointmentStartTime).toLocaleTimeString().split(":")[0]}:${new Date(PatAppointmentData.appointmentStartTime).toLocaleTimeString().split(":")[1]} ${
                      new Date(PatAppointmentData.appointmentStartTime).toLocaleTimeString().split(" ")[1]
                    }`
                  : ""
              } -
                ${
                  PatAppointmentData && PatAppointmentData.appointmentEndTime
                    ? `${new Date(PatAppointmentData.appointmentEndTime).toLocaleTimeString().split(":")[0]}:${new Date(PatAppointmentData.appointmentEndTime).toLocaleTimeString().split(":")[1]} ${
                        new Date(PatAppointmentData.appointmentEndTime).toLocaleTimeString().split(" ")[1]
                      }`
                    : ""
                }`} */}
            </span>
          </div>
          <h4 class="mt-4 ">
            <b>Reason for Appointment</b>
          </h4>
          <div class="mb-4">
            <span class="color-grey ">{PatAppointmentData && PatAppointmentData.reason ? PatAppointmentData.reason : ""}</span>
          </div>
          <div className="card">
            <div className="card-body">
              {DocData && DocData.document ? (
                <React.Fragment>
                  <a href={DocData.document}>
                    <div>
                      <span>
                        <i className="fa fa-file-pdf fa-3x"></i>
                      </span>
                    </div>
                  </a>
                </React.Fragment>
              ) : (
                <div>
                  <span>No attachments found.</span>
                </div>
              )}
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-2 text-right">
              <button class="btn btn-primary mr-1 " onClick={handleScheduleApp}>
                Schedule an appointment
              </button>
            </div>
           {PatAppointmentData?.isRatingGiven === false && 
            <div class="col-md-6">
              <button type="button" class="btn btn-primary " onClick={() => setRatingModal(!RatingModal)}>
                Add Rating
              </button>
            </div>}
          </div>
        </div>
      </div>
      <AddRatingModal userId={DocData?.serviceProviderId?._id} RatingModal={RatingModal} setRatingModal={setRatingModal} />
    </div>
  );
}

export default CompletedAppDetails;
