import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doctor, patient } from "../../../redux/action";
import { getSessionStorageOrDefault } from "../../../utils";
import ToasterFunc from "../../common/toaster/Toaster";

function UpgradePaymentDetail({ formik, handleCard, CardErr, CardnumErr, handleConfirm, handleSelectedCardPayment, SubscriptionId, SubscriptionPrice, setPaymentModal, setConfirmModal }) {
  let dispatch = useDispatch();
  const [addCard, setAddCard] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [cvvValue, setCvvValue] = useState("");
  const [cvvValueErr, setCvvValueErr] = useState(false);
  const [WalletToggle, setWalletToggle] = useState(false);
  const [WalletPay, setWalletPay] = useState("");

  const cardListData = useSelector((state) => state.patient?.patientCardListinPayment?.documentList);
  // patientUpgradeSubscriptionFromWalletAction
  const patientUpgradeSubscriptionFromWallet = async (payload) => dispatch(patient.patientUpgradeSubscriptionFromWalletAction(payload));
  const doctorUpgradeSubscriptionFromWallet = async (payload) => dispatch(doctor.doctorUpgradeSubscriptionFromWalletAction(payload));
  
  React.useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.target.name === "CardNum"){
        event.target.value = event.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
      }
      if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
        var form = event.target.form;
        var index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + 1].focus();
        event.preventDefault();
      }
    });
  }, []);
  
  const handleExpiryDate = () => {
    const inp = document.querySelector("#input");

    inp.onkeydown = function (e) {
      const key = e.keyCode || e.charCode;

      if (key !== 8 && key !== 46) {
        if (inp.value.length == 2) {
          inp.value = inp.value + "/";
        }
      }

      if ((key == 8 || key == 46) && inp.value.length === 4) {
        inp.value = inp.value.slice(0, 3);
      }
    };
  };

  const handleCardPayment = async () => {
    if (cvvValue) {
      if (cvvValue.length < 3) {
        setCvvValueErr(true);
      } else {
        handleSelectedCardPayment(selectedCard, cvvValue);
      }
    } else if (WalletPay) {
      const userType = await getSessionStorageOrDefault("userType");
      // if (SubscriptionPrice > patientWalletData?.totalBalance) {
      const paymentPayloadFromWallet = {
        subscriptionId: SubscriptionId,
      };
      let res = null;
      if (userType == "1") {
        res = await doctorUpgradeSubscriptionFromWallet(paymentPayloadFromWallet);
      } else if (userType == "2") {
        res = await patientUpgradeSubscriptionFromWallet(paymentPayloadFromWallet);
      }

      if (res?.status === 200) {
        ToasterFunc("success", "Subcription upgraded succesfully");
        setPaymentModal(false);
        setConfirmModal(true);
      }
    } else {
      ToasterFunc("error", "You have insufficient balance in your wallet");
    }
    // }
  };

  const patientWalletData = useSelector((state) => state?.patient?.patientWalletData?.walletData);
  console.log("patientWalletData", patientWalletData);
  useEffect(() => {
    patientWalletInfo();
  }, []);
  const patientWalletInfo = async () => dispatch(patient.patientWalletInWebAction());
  console.log("PatientData", patientWalletData);

  const handleWalletPayment = (e, data) => {
    console.log(e.target.checked);
    // let checkedValue = e.target.checked;
    if (data === "walletpayment") {
      // setWalletToggle(checkedValue);
      setWalletPay(data);
    } else {
      setWalletPay("");
    }
  };

  console.log(WalletPay, !WalletPay);
  console.log(SubscriptionId);

  return (
    <div className="modal-body p-4">
      <div className="row">
        <div className="col-lg-12 ">
          <div className="card mb-0">
            <div className="card-header">
              <h4 className="card-title">Payment Details</h4>
              {console.log("cardListData", cardListData)}
            </div>
            {!addCard && cardListData?.filter((data) => data.isPrimary)?.length > 0 && (
              <div className="card-body">
                <div className="card-list">
                  {cardListData
                    ?.filter((data) => data.isPrimary)
                    ?.map((cardData) => {
                      return (
                        <div className="card">
                          <div className="card-header">
                            <div className="row">
                              <div className="col-md-12">
                                <div className="d-flex justify-content-between">
                                  <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="radio" name="Card" id="added-card-1" value="Credit" checked={cardData?._id === selectedCard?._id ? true : false} onChange={() => setSelectedCard(cardData)} />
                                    <label className="form-check-label" for="added-card-1">
                                      {cardData.cardType === "CREDIT" ? "Credit Card" : "Debit Card"}
                                    </label>
                                  </div>
                                </div>
                                <label className="card-number ">{`${cardData?.cardNumber?.substring(0, 4)}XXXXXXXX${cardData?.cardNumber?.substring(11, 15)}`}</label>
                              </div>
                              {cardData?._id === selectedCard?._id && (
                                <div className="col-md-12">
                                  <div className="card-list-cvv">
                                    <input
                                      className="form-control w-25"
                                      type="password"
                                      name="CVV"
                                      id="added-card-1"
                                      placeholder="CVV"
                                      maxLength={3}
                                      onFocus={() => setCvvValueErr(false)}
                                      value={cvvValue}
                                      onChange={(e) => setCvvValue(e.target.value.replace(/[^0-9]/g, ""))}
                                    />
                                    {cvvValueErr && <span className="validation_error">*Please enter correct cvv.</span>}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  <div className="card">
                    <div className="card-header">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="d-flex justify-content-between">
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" name="Card" id="added-card-1" onChange={(e) => handleWalletPayment(e, "walletpayment")} />
                              <label className="form-check-label" for="added-card-1">
                                Pay from Wallet
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card-number">Wallet Balance : {patientWalletData?.totalBalance}</div>
                    </div>
                  </div>
                  <div className="btn-p btn-margin mt-3">
                    <button type="submit" className="btn btn-primary btn-new" onClick={() => setAddCard(true)}>
                      Add New
                    </button>
                    <button disabled={selectedCard || cvvValue || WalletPay !== "" ? false : true} type="submit" className="btn btn-primary btn-new" onClick={handleCardPayment}>
                      Continue
                    </button>
                  </div>
                </div>
              </div>
            )}
            {(cardListData?.filter((data) => data.isPrimary)?.length === 0 || addCard) && (
              <form onSubmit={formik.handleSubmit}>
                <div className="card-body">
                  <div className="row mb-2">
                    <div className="col-md-12">
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="Card" id="inlineRadio1" value="Credit" defaultChecked onClick={(e) => handleCard(e)} />
                        <label className="form-check-label" for="inlineRadio1">
                          Credit
                        </label>
                      </div>
                      <div className="form-check form-check-inline">
                        <input className="form-check-input" type="radio" name="Card" id="inlineRadio1" value="Debit" onClick={(e) => handleCard(e)} />
                        <label className="form-check-label" for="inlineRadio2">
                          Debit
                        </label>
                      </div>
                    </div>
                  </div>
                  {CardErr ? <div className="validation_error card">{CardErr}</div> : null}

                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group text-left">
                        <label for="Cardholder name">Full Name on Card</label>
                        <input type="text" className="form-control" name="fullname" maxLength="20" {...formik.getFieldProps("fullname")} />
                        {formik.touched.fullname && formik.errors.fullname && formik.values.fullname == "" ? <span className="validation_error">{formik.errors.fullname}</span> : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group text-left">
                        <label for="Cardholder name">Card Number</label>
                        <input type="text" className="form-control" 
                         onPaste={(e)=>{
                          e.preventDefault()
                          return false;
                        }}
                        name="CardNum" maxLength="19" {...formik.getFieldProps("CardNum")} />
                        {formik.touched.CardNum && formik.errors.CardNum && formik.values.CardNum == "" ? <span className="validation_error">{formik.errors.CardNum}</span> : null}
                        {CardnumErr ? <span className="validation_error">{CardnumErr}</span> : null}
                      </div>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group  text-left">
                        <label for="Cardholder name">CVV</label>
                        <input type="password" className="form-control" placeholder="CVV" maxLength="3" name="cvv" {...formik.getFieldProps("cvv")} />
                        {formik.touched.cvv && formik.errors.cvv && formik.values.cvv == "" ? <span className="validation_error">{formik.errors.cvv}</span> : null}
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group text-left">
                        <label for="Cardholder name">Card Expiry</label>
                        <input type="text" id="input" className="form-control" placeholder="MM/YY" name="cardexpiry" maxLength="5" {...formik.getFieldProps("cardexpiry")} onFocus={handleExpiryDate} />
                        {formik.touched.cardexpiry && formik.errors.cardexpiry && formik.values.cardexpiry == "" ? <span className="validation_error">{formik.errors.cardexpiry}</span> : null}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-check">
                        <input type="checkbox" className="form-check-input" id="payments" value="SaveForLater" onChange={(e) => handleCard(e)} />
                        <label className="savemefor" value="SaveForLater" name="SaveForLater" for="payments">
                          Save card for future payments
                        </label>
                      </div>
                    </div>
                  </div>

                  <div className="btn-p">
                    {cardListData?.filter((data) => data.isPrimary).length > 0 && (
                      <button type="submit" className="btn btn-primary mt-2" onClick={() => setAddCard(false)}>
                        BACK
                      </button>
                    )}
                    <button type="submit" className="btn btn-primary mt-2 ml-2" onClick={(e) => handleConfirm(e)}>
                      Confirm
                    </button>
                  </div>
                </div>
              </form>
            )}{" "}
          </div>
        </div>
      </div>
    </div>
  );
}

export default UpgradePaymentDetail;
