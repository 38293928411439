import moment from "moment";
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../constants";

function PatViewPersonal({ ProfileData }) {
  let history = useHistory();

  const handleEdit = () => [history.push(ROUTES_CONSTANTS.PATIENT_EDIT_PERSONAL_INFO)];
  return (
    <div className="tab-pane fade show active" id="per_details_tab">
      {/* <!-- Personal Details --> */}
      <div className="row">
        <div className="col-lg-12">
          <div className="card borderless">
            <div className="card-body padding-zero">
              <div className="profile d-flex justify-content-center mt-3 mb-3 subscription">
                <div className="round-circle">
                  {" "}
                  <img src={ProfileData.userData && ProfileData.userData.profilePic ? ProfileData.userData.profilePic : IMAGE_CONSTANT.USER_ICON} className="img-fluid " alt="..." />
                  {/* <label className="position-camera">
                    <input type="file" hidden /> <i className="fa fa-camera "> </i>
                  </label> */}
                </div>
              </div>
              <div className="row">
                <p
                  className="
col-sm-2
text-muted text-sm-right
mb-0 mb-sm-3
"
                >
                  First Name
                </p>
                <p className="col-sm-10">{ProfileData?.userData?.firstName} </p>
              </div>
              <div className="row">
                <p
                  className="
col-sm-2
text-muted text-sm-right
mb-0 mb-sm-3
"
                >
                  Last Name
                </p>
                <p className="col-sm-10"> {ProfileData?.userData?.lastName}</p>
              </div>
              <div className="row">
                <p
                  className="
col-sm-2
text-muted text-sm-right
mb-0 mb-sm-3
"
                >
                  Date of Birth
                </p>
                <p className="col-sm-10">{moment(ProfileData?.userData?.date_of_birth).format("MM/DD/YYYY")}</p>
              </div>
              <div className="row">
                <p className=" col-sm-2 text-muted text-sm-right mb-0 mb-sm-3">Gender</p>
                <p className="col-sm-10">{ProfileData?.userData?.gender === "Male" ? "Male" : ProfileData?.userData?.gender === "Female" ? "Female" : "I Prefer not to say"}</p>
              </div>
              <div className="row">
                <p
                  className="
col-sm-2
text-muted text-sm-right
mb-0 mb-sm-3
"
                >
                  Marital Status
                </p>
                <p className="col-sm-10">{ProfileData?.userData?.marital_status}</p>
              </div>
              <div className="row">
                <p
                  className="
col-sm-2
text-muted text-sm-right
mb-0 mb-sm-3
"
                >
                  Email ID
                </p>
                <p className="col-sm-10">{ProfileData?.userData?.email}</p>
              </div>
              <div className="row">
                <p className="col-sm-2 text-muted text-sm-right mb-0 mb-sm-3">Mobile</p>
                <p className="col-sm-10">{ProfileData?.userData?.mobileNumber}</p>
              </div>
              <div className="row">
                <p className="col-sm-2 text-muted text-sm-right mb-0">Address</p>
                <p className="col-sm-10 mb-0">
                  {ProfileData?.userData?.location?.address}
                  {/* <br />
                  Miami, <br />
                  Florida - 33165, */}
                  {/* <br /> */}
                  {/* United States. */}
                </p>
              </div>
              <div className="row">
                <p className="col-sm-2 text-muted text-sm-right mb-0">Lifestyle</p>
                <p className="col-sm-10 mb-0">{ProfileData?.userData?.bio}</p>
              </div>
              <div className="d-flex justify-content-center mt-5">
                {" "}
                <button className="btn btn-primary" onClick={handleEdit}>
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /Personal Details --> */}
    </div>
  );
}

export default PatViewPersonal;
