import { patient } from "../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import UpgradeSubscription from "../../findDoctorsNearBy/UpgradeSubscription";

function PatientSubscription() {
  let dispatch = useDispatch();
  const [PatSubcriptionModal, setPatSubcriptionModal] = useState(false);
  const [PatUpgradeSub, setPatUpgradeSub] = useState(false);
  const patientSubscriptionData = useSelector((state) => state?.patient?.patientSubscriptionData?.result?.subscriptionId);
  console.log("patientSubscriptionData", patientSubscriptionData);
  useEffect(() => {
    window.scrollTo(0, 0);
    patientSubscriptionInfo();
  }, []);
  const patientSubscriptionInfo = async () => dispatch(patient.patientSubscriptionInWebAction());
  const handleUpgrade = () => {
    setPatSubcriptionModal(!PatSubcriptionModal);
    setPatUpgradeSub(true);
  };
  return (
    <div class=" col-lg-8 col-xl-9 content_ipad_p">
      <div class="card">
        <div class="card-header">
          <h4>Subscription</h4>
        </div>
        <div class="card-body ">
          <div class="account-content">
            <div class="row">
              <div class="col-md-12 col-lg-6">
                <div class="card subscription-packages">
                  <div class="card-body">
                    <div class="d-flex justify-content-between mb-1 ">
                      <div>
                        {/* <!-- <input type="checkbox" class="form-check-input" id="Subscription1"> --> */}
                        <label class="form-check-label" for="Subscription1">
                          Subscription
                        </label>
                      </div>
                      <div>
                        <label class="color-grey">
                          <b>${patientSubscriptionData && patientSubscriptionData.price}/month</b>
                        </label>
                      </div>
                    </div>

                    <div class="card p-2 mt-1 package-description">
                      <div>
                        <label class="color-grey">Number of Sessions: {patientSubscriptionData && patientSubscriptionData.session_numbers}</label>
                      </div>
                      <div>
                        <label class="color-grey">Number of Messages: {patientSubscriptionData && patientSubscriptionData.numberOfMessages}</label>
                      </div>
                      <div>
                        <label class="color-grey">Subscription Validity(days): {patientSubscriptionData && patientSubscriptionData.days_duration}</label>
                      </div>
                    </div>
                    <button class="btn btn-primary w-100" onClick={handleUpgrade}>
                      Upgrade/Downgrade
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpgradeSubscription patientSubscriptionData = {patientSubscriptionData} PatSubcriptionModal={PatSubcriptionModal} setPatSubcriptionModal={setPatSubcriptionModal} PatUpgradeSub={PatUpgradeSub} />
    </div>
  );
}

export default PatientSubscription;
