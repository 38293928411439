import React, { useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";
import { commonAction, doctor } from "../../../redux/action";
import { useHistory, useParams , useLocation } from "react-router-dom";
import { connect } from "twilio-video";
import { getSessionStorageOrDefault } from "../../../utils";
import IncomingCall from "./IncomingCall";
import OutgoingCall from "./OutgoingCall";
import Ring from "../../../assets/ring.wav";
import Room from "./Room";
import {  isCallStarted, disconnectCallRequest, IsCallerDetails} from '../../../getDeviceToken'
import { ROUTES_CONSTANTS } from "../../../constants";
import { ReactComponent as RotateCameraSvg } from "../../../assets/img/reverse-camera.svg";
import ToasterFunc from "../../common/toaster/Toaster";



function VideoCall({IsHeaderClicked}) {
  
  

  const dispatch = useDispatch();
  const history = useHistory();
  const [incominCall, setIncomingCall] = useState(false);
  const [outgoing, setOutgoing] = useState();
  const [seconds, setSeconds] = useState("");
  const [isParticipentJoined, setIsParticipentJoined] = useState(false);
  const [joinCall, setJoinCall] = useState(false);
  const [disabledVideo, setDisabledVideo] = useState(false);
  const [isMute, setIsMute] = useState(false);
  const [GetRemainingTime, setGetRemainingTime] = useState({});
  const location = useLocation();

  const [isViewSwitch, setIsViewSwitch] = useState(false);
  const [IsCallDisconncted, setIsCallDisconncted] = useState(false);
  const isGetRemainingMinute = useRef(false);

  let Interval;
  const audio = useRef(null);

  const [callerDetails, setCallerData] = useState();
  const [roomData, setRoomData] = useState(null);
  const [appointmentEndTimeStamp, setappointmentEndTimeStamp] = useState(null);
  const { appointmentId, receiverId, pushType, userType } = useParams();
  const getTwilioToken = async (payload) => dispatch(commonAction.getTwilioTokenAction(payload));

  const disConnectCall = async (payload) => dispatch(commonAction.disconnectCall(payload));
  const getRemainingMinute = async () => dispatch(commonAction.getRemainingMinute());
  const updateRemainingMinute = async (payload) => dispatch(commonAction.updateRemainingMinute(payload));
  const docViewAppointmentInfo = async (payload) => dispatch(doctor.docViewAppointmentAction(payload));

  const pathname = window.location.pathname.split('/')[1];


 useEffect(async () => {
  console.log("appointmentId ===>> ",appointmentId , receiverId.slice(5) );  
  if(appointmentId && receiverId && (getSessionStorageOrDefault("userType") == 1)){
    const docAppointmentData = await docViewAppointmentInfo(appointmentId.length > 50 ? receiverId.slice(5) : appointmentId );
    if(docAppointmentData?.data?.responseCode === 200){
           let appointmentData = docAppointmentData?.data?.appointmentDetails;
           let appointmentEndTime  = appointmentData?.appointmentEndTime; //"2022-10-01T09:00:00.000Z"
           let appEntTimeStamp = new Date(appointmentEndTime).getTime();
           setappointmentEndTimeStamp(appEntTimeStamp);
    }
  }
 }, [appointmentId])
 

  useEffect(() => {
     if (IsHeaderClicked){
      callDisConnect()
     }
  }, [IsHeaderClicked]);

  useEffect(() => {    
    if (incominCall || outgoing ){
      // timer count started here 
      const startTime = Date.now() - 0;
      Interval = setInterval(() => {
       let duration = Date.now() - startTime;
       let seconds = Math.floor((duration / 1000) % 60);
       let minutes = Math.floor((duration / (1000 * 60)) % 60);
       let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);
 
       hours = hours < 10 ? "0" + hours : hours;
       minutes = minutes < 10 ? "0" + minutes : minutes;
       seconds = seconds < 10 ? "0" + seconds : seconds;
       if (!joinCall && (incominCall || outgoing  ) && (seconds == '60') ){
          audio.current?.pause && audio.current.pause();
          seconds = '';
          callDisConnect()
       }
      //  setSeconds(`${hours}:${minutes}:${seconds}`);
     }, 1000);
    }
  
    if (pathname !== 'video-call'){
        callDisConnect();
      }
      return ()=>{
        audio.current?.pause && audio.current.pause();
        clearInterval(Interval);
      }
  }, [pathname ,joinCall , incominCall , outgoing ])
  

  useEffect(() => {
    if ((outgoing || incominCall) && !joinCall ) {
      var iscallerDetailsTrue ;
      IsCallerDetails.subscribe(res=>{
        iscallerDetailsTrue = res;
      });
      if(iscallerDetailsTrue){

        audio.current = new Audio(Ring);
        audio.current.loop = true;
        audio.current?.play();
      }else{
        audio.current?.pause();
      }
    }
  
  }, [incominCall, outgoing,joinCall]);



  useEffect(async () => {
    var isCallerDetailsVal ;
    IsCallerDetails.subscribe(async (res)=>{
      isCallerDetailsVal = res ;
    })
    
    if (isCallerDetailsVal && !isGetRemainingMinute.current){
      if (getSessionStorageOrDefault("userType") == 1) {
        const getRemainingMinuteResponse = await getRemainingMinute();
        isGetRemainingMinute.current =  true ;
        if (getRemainingMinuteResponse?.status === 200) {
          let result = getRemainingMinuteResponse?.data?.result;
          let remainingVideoMin = result?.minutesOfVideoCalls ? result?.minutesOfVideoCalls : 0 ; 
          let remainAudioMin  = result?.minutesOfAudioCalls ? result?.minutesOfAudioCalls  :  0 ;
          ToasterFunc("success", `You have left ${remainingVideoMin} video min and ${remainAudioMin} audio min`);
          setGetRemainingTime(result);
        }
      }

      if (userType == "outgoing") {
        getToken();
        localStorage.setItem('outgoingcall','true');
        setOutgoing(true);
      } else {
        // connectCall();
        localStorage.setItem('incomingcall','true');
        setIncomingCall(true);
      }    
    }
    if (getSessionStorageOrDefault('callerDetails')){
      setCallerData(JSON.parse(getSessionStorageOrDefault("callerDetails")));
    }
   
    return () => {
      audio.current?.pause && audio.current.pause();
      clearInterval(Interval);
    };
  }, []);

  const getToken = async () => {   
    if(appointmentId && receiverId && pushType){
   
    const res = await getTwilioToken({
      appointmentId: appointmentId,
      receiverId: receiverId,
      pushType: pushType,
    });
    if (res?.status == 200) {
      if (res.data.twilioToken) {
        var room = await connect(res.data.twilioToken, {
          name: res.data.roomName,
          audio: true,
          video: { width: 640 },
          video: pushType === "video" ? { width: 840 } : false,
        });
        if (room != "") {
          setRoomData(room);
        }
      }
    }
       
  }
  };

  const startTimer = () => {
    clearInterval(Interval);
    audio.current?.pause && audio.current.pause();
    setIsParticipentJoined(true);
    const startTime = Date.now() - 0;
    Interval = setInterval(() => {
      let duration = Date.now() - startTime;
      let seconds = Math.floor((duration / 1000) % 60);
      let minutes = Math.floor((duration / (1000 * 60)) % 60);
      let hours = Math.floor((duration / (1000 * 60 * 60)) % 24);

      hours = hours < 10 ? "0" + hours : hours;
      minutes = minutes < 10 ? "0" + minutes : minutes;
      seconds = seconds < 10 ? "0" + seconds : seconds;
      setSeconds(`${hours}:${minutes}:${seconds}`);
    }, 1000);
  };

  const connectCall = async () => {
    if (sessionStorage.getItem('callerDetails')) {
    var room = await connect(appointmentId, {
      name: receiverId,
      audio: true,
      video: pushType === "video" ? { width: 840 } : false,
    });
    if (room != "") {
      setRoomData(room);
      startTimer();
      setOutgoing(false);
      setIncomingCall(false);
      audio.current?.pause && audio.current.pause();
      setJoinCall(true);
      isCallStarted.next(true);
    }
   }
  };

  useEffect(() => {
    if (seconds) {
      if (getSessionStorageOrDefault("userType") == 1) {
        let hour = Number(seconds.split(":")[0]);
        let min = Number(seconds.split(":")[1]);
        min = hour * 60 + min;
        if (pushType === "video" && GetRemainingTime?.minutesOfVideoCalls === min) {
          callDisConnect();
        }
        if (pushType === "audio" && GetRemainingTime?.minutesOfAudioCalls === min) {
          callDisConnect();
        }
        if(appointmentEndTimeStamp < Date.now()){
          callDisConnect();
        }
      }
    }
  }, [seconds]);

  
  const callDisConnect = async () => {
    if (receiverId || callerDetails?.senderId ){
    audio.current?.pause && audio.current.pause();
    const payload = {
      receiverId: userType == "outgoing" ? receiverId : callerDetails?.senderId,
      pushType: pushType,
    };
    window.close();
    setIsCallDisconncted(true);
    const res = await disConnectCall(payload);
    audio.current?.pause && audio.current.pause();

    if (res?.data?.responseCode == 200) {
      let pushData = res?.data?.pushdata;
      if (getSessionStorageOrDefault("userType") == 1) {
        let hour = Number(seconds.split(":")[0]);
        let min = Number(seconds.split(":")[1]);
        min = hour * 60 + min;
        if (pushType || min ){
          const updateMinpayload = {
            type: pushType,
            minutes: min,
          };
          const res = await updateRemainingMinute(updateMinpayload);
        }
      
      }
    }
    if(outgoing){
      sessionStorage.removeItem("callerDetails");
      IsCallerDetails.next(false);
      setIncomingCall(false);
      setOutgoing(false);
      window.history.go(-1);
      window.close();
    }else{

      sessionStorage.removeItem("callerDetails");
      IsCallerDetails.next(false);
      setIncomingCall(false);
      setOutgoing(false);
      window.close();
    }
    // history.goBack();    
    
   }
  };

  const onSwicthCamers = () => {
    setIsViewSwitch(!isViewSwitch);
  };

  const handleMuteUnMute = () => {
    if (!isMute) {
      roomData.localParticipant.audioTracks.forEach((publication) => {
        publication.track.disable();
      });
    } else {
      roomData.localParticipant.audioTracks.forEach((publication) => {
        publication.track.enable();
      });
    }
    setIsMute(!isMute);
  };

  const handleVideoDisable = () => {
    if (!disabledVideo) {
      roomData.localParticipant.videoTracks.forEach((publication) => {
        publication.track.disable();
      });
    } else {
      roomData.localParticipant.videoTracks.forEach((publication) => {
        publication.track.enable();
      });
    }
    setDisabledVideo(!disabledVideo);
  };

  const returnToLobby = () => {
    audio.current?.pause && audio.current.pause();
    setRoomData(null);
    if (getSessionStorageOrDefault("userType") == "1") history.goBack();
    else history.push(ROUTES_CONSTANTS.PATIENT_DASHBOARD);
    sessionStorage.removeItem("callerDetails");
    IsCallerDetails.next(false);

    // setTimeout(() => {
    //   window.location.reload();
    // }, 1000);
  };

  return (
    <div className="col-xl-12 mt-4 mb-4">
      <div class="container-fluid video-call-custom">
        <div class="call-wrapper">
          <div class="call-main-row">
            <div class="call-main-wrapper">
              <div class="call-view">
                <div class="call-window">
                  <div class="fixed-header">
                    <div class="navbar">
                      <div class="user-details">
                        <div class="float-left user-img">
                          <a class="avatar avatar-sm mr-2" title="Charlene Reed">
                            <img src={callerDetails?.profilePic} alt="User Image" class="rounded-circle" />
                            <span class="status online"></span>
                          </a>
                        </div>
                        <div class="user-info float-left">
                          <a>
                            <span>{callerDetails?.fullName}</span>
                          </a>
                          <span class="last-seen">Online</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {outgoing && <OutgoingCall setOutgoing={setOutgoing} callDisConnect={callDisConnect} />}
                  {incominCall && <IncomingCall setIncomingCall={setIncomingCall} callDisConnect={callDisConnect} connectCall={connectCall} />}

                  {/*incomimg changes {outgoing && (
                    <OutgoingCall
                      setOutgoing={setOutgoing}
                      callDisConnect={callDisConnect}
                    />
                  )}
                  {incominCall && (
                    <IncomingCall
                      callDisConnect={callDisConnect}
                      connectCall={connectCall}
                    />
                  )}
                   */}
                  {roomData && (
                    <Room
                      returnToLobby={returnToLobby}
                      room={roomData}
                      isViewSwitch={isViewSwitch}
                      startTimer={startTimer}
                      audio={audio}
                      setIsParticipentJoined={(value) => {
                        setIsParticipentJoined(true);
                        setOutgoing(false);
                        setIncomingCall(false);
                        setJoinCall(true);
                      }}
                    />
                  )}
                  {joinCall && (
                    <>
                      <div class="call-contents">
                        <div class="call-content-wrap">
                          <div class="user-video">
                            {pushType != "video" && (
                              <>
                                <div className="profile">
                                  <figure className="voice_pro">
                                    <img src={callerDetails?.profilePic} alt="" />
                                  </figure>
                                </div>

                                <span class="call-duration audio_du">{seconds}</span>
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                      <div class="call-footer">
                        <div class="call-icons">
                          {pushType == "video" && <span class="call-duration video_du">{seconds}</span>}
                          <ul class="call-items">
                            {pushType == "video" && (
                              <li class="call-item">
                                <a href title="Enable Video" data-placement="top" data-toggle="tooltip" onClick={handleVideoDisable}>
                                  <i class={disabledVideo ? "fas fa-video-slash" : "fas fa-video"}></i>
                                </a>
                              </li>
                            )}
                            <li class="call-item">
                              <a href title="Mute Audio" data-placement="top" data-toggle="tooltip" onClick={handleMuteUnMute}>
                                <i class={isMute ? "fa fa-microphone-slash" : "fa fa-microphone"}></i>
                              </a>
                            </li>
                            {pushType == "video" && (
                              <li class="call-item">
                                <a href title="Flip Camera" data-placement="top" data-toggle="tooltip" onClick={onSwicthCamers}>
                                  {/* <i class="fa-solid fa-camera-rotate"></i> */}
                                  <div className="rotate-camera">
                                    <RotateCameraSvg />
                                  </div>
                                </a>
                              </li>
                            )}
                            <li class="call-item call-end">
                              <a href title="Call End" data-placement="top" data-toggle="tooltip" onClick={callDisConnect}>
                                <i class="fas fa-phone"></i>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default React.memo(VideoCall);
