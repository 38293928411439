import { authenticationAction, patientAction } from "../../action";
import initialState from "../initialState";

export default function authenticationReducer(
  state = initialState.common,
  action
) {
  switch (action.type) {
    case authenticationAction.PATIENT_SUBSCRIPTION_TAX:
      return {
        ...state,
        patientSubscriptionTax: action.patientSubscriptionTax,
      };
      case authenticationAction.DOCTOR_SUBSCRIPTION_TAX:
        return {
          ...state,
          doctorSubscriptionTax: action.doctorSubscriptionTax,
        };
    case authenticationAction.DOCTOR_SUBSCRIPTION_LIST:
      return {
        ...state,
        doctorSubscriptionList: action.doctorSubscriptionList,
      };
    case authenticationAction.PATIENT_SUBSCRIPTION_LIST:
      return {
        ...state,
        patientSubscriptionList: action.patientSubscriptionList,
      };
    case authenticationAction.COUPON_LIST:
      return {
        ...state,
        couponList: action.couponList,
      };
    case authenticationAction.PRIMARY_SPECIALITY_LIST:
      return {
        ...state,
        primarySpecialityList: action.primarySpecialityList,
      };
    case authenticationAction.SECONDARY_SPECIALITY_LIST:
      return {
        ...state,
        secondarySpecialityList: action.secondarySpecialityList,
        secondarySpecialityMaxLength: action.secondarySpecialityMaxLength || 0,
      };
    default:
      return state;
  }
}
