import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../constants";
import Chatting from "./Chatting/Chatting";
import Calling from "./Calling/Calling";
import { useState } from "react";
import Topbar from "../common/Topbar/Topbar";
import IncomingCall from "../common/VideoCall/IncomingCall";
import OutgoingCall from "../common/VideoCall/OutgoingCall";
import {ReactComponent as BackArrow } from '../../assets/img/return-up-back.svg'
import { useHistory } from "react-router-dom";


const Chat = () => {
    let history = useHistory()
    const [isChatOpen, setisChatOpen] = useState(true)
    const [isCallOpen, setisCallOpen] = useState(false)
    const handleChats = () => {
        setisChatOpen(true)
        setisCallOpen(false)
    }
    const handleCalls = () => {
        setisCallOpen(true)
        setisChatOpen(false)
    }
    console.log("isCallOpen", isCallOpen);
    const handleNavigation = () => {
		history.push(ROUTES_CONSTANTS.DASHBOARD);
	};
    return (
        <>
            <div className="col-xl-12">
                <Topbar />
            </div>
            <div className="col-xl-12">
                {/* <!--chat and calls Tab Menu --> */}
                <nav className="user-tabs newChat-tabs ">
                    <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                        <span className="back__arrow" title="Go back" onClick={handleNavigation}><BackArrow/></span>
                        <li className="nav-item" onClick={handleChats}>
                            <a className={isChatOpen ? "nav-link active" : "nav-link"}>Chats</a>
                        </li>
                        {/* <li className="nav-item" onClick={handleCalls}>
                            <a className={isCallOpen ? "nav-link active" : "nav-link"}>Calls</a>
                        </li> */}
                    </ul>
                </nav>
                <Chatting />
                {/* {isChatOpen ? <Chatting /> : isCallOpen ? <Calling /> : null} */}
            </div>
        </>
    )
}

export default Chat;
