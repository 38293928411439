import { Modal, ModalBody } from "reactstrap";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../constants";
import { useEffect, useState, useRef } from "react";
import { ResetPassword } from "..";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { authentication } from "../../../redux/action";
import ToasterFunc from "../../common/toaster/Toaster";
import { getSessionStorageOrDefault } from "../../../utils";
var timerIntervel;

const VerifyOtp = ({ setVerifyOtpModal, VerifyOtpModal, Open, isSignUpOpen, setSignInModal, DocId, PatId, LateSignUpToggle }) => {
  let history = useHistory();
  const dispatch = useDispatch();
  const [timer, setTimer] = useState("00:00");
  const [openState, setOpenState] = useState(Open);
  const [otpErr, setOtpErr] = useState("");

  const otp1 = useRef(null);
  const otp2 = useRef(null);
  const otp3 = useRef(null);
  const otp4 = useRef(null);
  useEffect(() => {
    runTimer();
    return () => {
      setTimer(`00:00`);
      clearInterval(timerIntervel);
    };
  }, [Open]);

  // useEffect(() => {

  // }, [LateSignUpToggle]);

  const runTimer = () => {
    var seconds = 60;
    timerIntervel = setInterval(() => {
      if (seconds > 0) {
        setTimer(`00:${seconds--}`);
      } else {
        setTimer(`00:00`);
        clearInterval(timerIntervel);
      }
    }, 1000);
  };

  const verifyOTPDoctor = (payload) => dispatch(authentication.verifyOTPAction(payload));
  const verifyOTPPatient = (payload) => dispatch(authentication.verifyOTPPatientAction(payload));
  const resetOTPDoctor = (payload) => dispatch(authentication.resentOTPAction(payload));
  const resetOTPPatient = (payload) => dispatch(authentication.resentOTPPatientAction(payload));

  const [ResetPwdModal, setResetPwdModal] = useState(false);
  const [state, setState] = useState({
    pass1: "",
    pass2: "",
    pass3: "",
    pass4: "",
  });

  const handleSubmit = () => {
    if (state.pass1 !== "" && state.pass2 !== "" && state.pass3 !== "" && state.pass4 !== "") {
      setOtpErr("");
      console.log("otp=>", state.pass1);
      if (Open) {
        if (getSessionStorageOrDefault("userType") === "1") {
          verifyOtpDocAfterForgetPwd();
        } else if (getSessionStorageOrDefault("userType") === "2") {
          verifyOtpPatAfterForgetPwd();
        }
      } else if (isSignUpOpen) {
        if (getSessionStorageOrDefault("userType") === "1") {
          VerifyOtpDoctor();
        } else if (getSessionStorageOrDefault("userType") === "2") {
          VerifyOtpPatient();
          // history.push(ROUTES_CONSTANTS.PAT_PERSONAL_SIGNUP);
        }
      }

      if (LateSignUpToggle) {
        console.log("coming here 76");
        if (getSessionStorageOrDefault("userType") === "1") {
          console.log("coming here 78");
          VerifyOtpDoctor();
        }
        if (getSessionStorageOrDefault("userType") === "2") {
          console.log("coming here 82");
          VerifyOtpPatient();
          // history.push(ROUTES_CONSTANTS.PAT_PERSONAL_SIGNUP);
        }
      }
    } else {
      setOtpErr("Please enter otp");
    }
  };

  console.log("LateSignUpToggle", LateSignUpToggle);

  const verifyOtpDocAfterForgetPwd = async () => {
    if (DocId) {
      const payload = {
        userId: DocId ? DocId : "",
        otp: `${state.pass1}${state.pass2}${state.pass3}${state.pass4}`,
      };
      const response = await verifyOTPDoctor(payload);
      if (response.status == 200) {
        ToasterFunc("success", response.data.responseMessage);
        setResetPwdModal(!ResetPwdModal);
        setVerifyOtpModal(false);
        setState({
          pass1: "",
          pass2: "",
          pass3: "",
          pass4: "",
        });
      } else {
        ToasterFunc("error", response.error.responseMessage);
      }
      if (response.status == 404) {
        ToasterFunc("error", "Please enter Otp ");
        // history.push(ROUTES_CONSTANTS.PAT_PERSONAL_SIGNUP);
      }
    }
  };

  const verifyOtpPatAfterForgetPwd = async () => {
    if (PatId) {
      const payload = {
        userId: PatId ? PatId : "",
        otp: `${state.pass1}${state.pass2}${state.pass3}${state.pass4}`,
      };
      const response = await verifyOTPDoctor(payload);
      if (response.status == 200) {
        ToasterFunc("success", response.data.responseMessage);
        setResetPwdModal(!ResetPwdModal);
        setVerifyOtpModal(false);
        setState({
          pass1: "",
          pass2: "",
          pass3: "",
          pass4: "",
        });
      }
      if (response.status == 404) {
        ToasterFunc("error", "Please enter Otp ");
      }
    }
  };

  const resendOTP = async () => {
    setState({
      pass1: "",
      pass2: "",
      pass3: "",
      pass4: "",
    });
    const UserDetails = (await getSessionStorageOrDefault("UserData")) ? JSON.parse(getSessionStorageOrDefault("UserData")) : null;
    if (UserDetails) {
      const payload = {
        userId: UserDetails?._id,
      };
      runTimer();
      if (getSessionStorageOrDefault("userType") === "1") {
        const response = await resetOTPDoctor(payload);
      } else if (getSessionStorageOrDefault("userType") === "2") {
        const response = await resetOTPPatient(payload);
      }
    }
  };

  const VerifyOtpDoctor = async () => {
    console.log("coming here doctor");
    let UserDetails = null;
    let DocUserId = null;
    if (getSessionStorageOrDefault("UserData") != "undefined") {
      UserDetails = getSessionStorageOrDefault("UserData") ? JSON.parse(getSessionStorageOrDefault("UserData")) : null;
    }
    if (getSessionStorageOrDefault("DocUserId") !== "undefined") {
      DocUserId = getSessionStorageOrDefault("DocUserId");
    }
    let payload = {};
    if (UserDetails) {
      payload = {
        userId: UserDetails?._id,
        otp: `${state.pass1}${state.pass2}${state.pass3}${state.pass4}`,
      };
    } else if (DocUserId) {
      payload = {
        userId: DocUserId,
        otp: `${state.pass1}${state.pass2}${state.pass3}${state.pass4}`,
      };
    }
    const response = await verifyOTPDoctor(payload);
    if (response.status == 200) {
      setVerifyOtpModal(false);
      setState({
        pass1: "",
        pass2: "",
        pass3: "",
        pass4: "",
      });
      ToasterFunc("success", response.data.responseMessage);
      history.push(ROUTES_CONSTANTS.DOC_PERSONAL_SIGNUP);
    }
    if (response.status == 404) {
      ToasterFunc("error", "Please enter Otp ");
    }
  };

  const VerifyOtpPatient = async () => {
    console.log("coming here patient", !getSessionStorageOrDefault("UserData"), !getSessionStorageOrDefault("PatUserId"));
    let UserDetails = null;
    let PatUserId = null;
    if (getSessionStorageOrDefault("UserData") != "undefined") {
      UserDetails = getSessionStorageOrDefault("UserData") ? JSON.parse(getSessionStorageOrDefault("UserData")) : null;
    }
    if (getSessionStorageOrDefault("PatUserId") !== "undefined") {
      PatUserId = getSessionStorageOrDefault("PatUserId");
    }
    if (UserDetails) {
      const payload = {
        userId: UserDetails?._id,
        otp: `${state.pass1}${state.pass2}${state.pass3}${state.pass4}`,
      };
      const response = await verifyOTPPatient(payload);

      if (response.status == 200) {
        setVerifyOtpModal(false);
        ToasterFunc("success", response.data.responseMessage);
        setState({
          pass1: "",
          pass2: "",
          pass3: "",
          pass4: "",
        });
        history.push(ROUTES_CONSTANTS.PAT_PERSONAL_SIGNUP);
      }
      if (response.status == 404) {
        ToasterFunc("error", "Please enter Otp ");
      }
    }
    if (PatUserId) {
      console.log("coming here 211");
      const payload = {
        userId: PatUserId,
        otp: `${state.pass1}${state.pass2}${state.pass3}${state.pass4}`,
      };
      const response = await verifyOTPPatient(payload);

      if (response.status == 200) {
        setVerifyOtpModal(false);
        ToasterFunc("success", response.data.responseMessage);
        setState({
          pass1: "",
          pass2: "",
          pass3: "",
          pass4: "",
        });
        history.push(ROUTES_CONSTANTS.PAT_PERSONAL_SIGNUP);
      }
      if (response.status == 404) {
        ToasterFunc("error", "Please enter Otp ");
      }
    }
  };

  const handleClose = async () => {
    await clearInterval(timerIntervel);
    setTimer(`00:00`);
    setVerifyOtpModal(false);
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.id]: e.target.value,
    });
  };

  return (
    <div>
      <Modal isOpen={VerifyOtpModal} className="sign-modal modal-dialog-centered index-modalBody">
        <div class="modal-content">
          <button type="button" class="close custom-model-close" aria-label="Close" data-dismiss="modal" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </button>
          <ModalBody>
            <div className="">
              <div class="container-fluid">
                <div class="account-content">
                  <div class="row">
                    <div class="col-md-5 login-left m-auto">
                      <img src={IMAGE_CONSTANT.LOGIN_BANNER} class="img-fluid" alt="Doccure Login" />
                    </div>
                    <div class="col-md-7 login-right">
                      <div class="login-header">
                        <h3>Verify OTP</h3>
                        <p class="small text-muted">We will send you an one time password on your registered email.</p>
                      </div>
                      <form>
                        <div className={`d-flex justify-content-around ${otpErr ? "otp-required" : ""}`}>
                          <div>
                            <input
                              id="pass1"
                              ref={otp1}
                              value={state.pass1}
                              type="text"
                              maxlength="1"
                              class="form-control floating  otp-input-width"
                              onChange={(e) => {
                                if (e.target.value.length === 1) otp2.current.focus();
                                handleChange(e);
                              }}
                            />
                          </div>
                          <div>
                            <input
                              id="pass2"
                              ref={otp2}
                              value={state.pass2}
                              type="text"
                              maxlength="1"
                              class="form-control floating  otp-input-width"
                              onChange={(e) => {
                                if (e.target.value.length === 1) otp3.current.focus();
                                else otp1.current.focus();
                                handleChange(e);
                              }}
                            />
                          </div>
                          <div>
                            <input
                              id="pass3"
                              ref={otp3}
                              value={state.pass3}
                              type="text"
                              maxlength="1"
                              class="form-control floating  otp-input-width"
                              onChange={(e) => {
                                if (e.target.value.length === 1) otp4.current.focus();
                                else otp2.current.focus();
                                handleChange(e);
                              }}
                            />
                          </div>
                          <div>
                            <input
                              id="pass4"
                              ref={otp4}
                              value={state.pass4}
                              type="text"
                              maxlength="1"
                              class="form-control floating otp-input-width"
                              onChange={(e) => {
                                if (e.target.value.length === 0) otp3.current.focus();
                                handleChange(e);
                              }}
                              onKeyPress={(e) => e.key === "Enter" && handleSubmit()}
                            />
                          </div>
                        </div>
                        <span>{otpErr}</span>
                        <div class="d-flex justify-content-between padding-otp-resend">
                          <div>
                            <span class="small text-muted ">{timer}</span>
                          </div>
                          <div>
                            <span class="small color-grey mr-1">Didnt Recieve the OTP?</span>
                            <a>
                              <span
                                class={timer.toString() == "00:00" ? "small" : "small color-grey"}
                                onClick={() => timer.toString() == "00:00" && resendOTP()}
                              >
                                <b>Resend OTP</b>
                              </span>
                            </a>
                          </div>
                        </div>
                        <a class="btn btn-primary btn-block btn-lg login-btn mt-4" type="submit" onClick={handleSubmit}>
                          Submit
                        </a>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
      <ResetPassword
        ResetPwdModal={ResetPwdModal}
        setResetPwdModal={setResetPwdModal}
        setSignInModal={setSignInModal}
        DocId={DocId}
        PatId={PatId}
      />
    </div>
  );
};

export default VerifyOtp;
