import { BehaviorSubject } from "rxjs";
import { deviceToken } from "../../../getDeviceToken";
import authenticationService from "../../../services/authenticationService/authenticationService";
import { getSessionStorageOrDefault, saveToSessionStorage } from "../../../utils";
import { commonAction } from "../commonAction";
import authenticationAction from "./authenticationAction";

export const loginData = new BehaviorSubject(sessionStorage.getItem('loggedUserInfo'));

export const signUpAction = (payload, code) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    let devicetoken = "";
    deviceToken.subscribe((res) => {
      console.log("doctor deviceToken 1", res);
      devicetoken = res;
    });
    if (getSessionStorageOrDefault("deviceToken")) {
      devicetoken = getSessionStorageOrDefault("deviceToken");
    }
    const payloadData = {
      deviceType: "WEB",
      deviceToken: devicetoken,
      email: payload.email,
      countryCode: "+1",
      mobileNumber: payload.mobile,
      password: payload.NewPassword,
      referralCode: code ? code : null,
    };
    try {
      const response = await authenticationService.signUpUser(payloadData);
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      sessionStorage.setItem("isLoggedIn", true);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());

      reject(error.response);
    }
  });
};

export const signUpDoctorPersonalDetailsAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await authenticationService.signUpDoctorPersonalDetails(payload);
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const signUpPatientAction = (payload, code) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    console.log("code", code);
    let devicetoken = "";
    deviceToken.subscribe((res) => {
      console.log("doctor deviceToken 1", res);
      devicetoken = res;
    });
    if (getSessionStorageOrDefault("deviceToken")) {
      devicetoken = getSessionStorageOrDefault("deviceToken");
    }
    const payloadData = {
      deviceType: "WEB",
      deviceToken: devicetoken,
      email: payload.email,
      countryCode: "+1",
      mobileNumber: payload.mobile,
      password: payload.NewPassword,
      referralCode: code ? code : null,
    };
    try {
      const response = await authenticationService.signUpPatientUser(payloadData);

      dispatch(commonAction.hideLoader());
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const verifyOTPAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await authenticationService.verifyOtp(payload);
      saveToSessionStorage("authToken", response.data.result.token);

      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const verifyOTPPatientAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await authenticationService.verifyOtpPatient(payload);
      saveToSessionStorage("authToken", response.data.result.token);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const resentOTPAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await authenticationService.resendOtp(payload);
      // saveToSessionStorage("authToken", response.data.userData.token)
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const resentOTPPatientAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await authenticationService.resendOtpPatient(payload);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const signInAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    let payloadData = {};
    let devicetoken = "";
    deviceToken.subscribe((res) => {
      console.log("doctor deviceToken 1", res);
      devicetoken = res;
    });
    if (getSessionStorageOrDefault("deviceToken")) {
      devicetoken = getSessionStorageOrDefault("deviceToken");
    }
    if (sessionStorage.getItem("SignInToggle") === "True") {
      payloadData = {
        deviceType: "WEB",
        deviceToken: devicetoken,
        offset: 440,
        mobileNumber: payload.mobile,
        password: payload.password,
      };
    } else {
      payloadData = {
        deviceType: "WEB",
        deviceToken: devicetoken,
        offset: 440,
        email: payload.email,
        password: payload.password,
      };
    }

    try {
      const response = await authenticationService.signInUser(payloadData);
      const strResponse = window.btoa(JSON.stringify(response));
      console.log("strResponse ===>>>",strResponse)
      loginData.next(strResponse);
      sessionStorage.setItem('loggedUserInfo',strResponse);
      // sessionStorage.setItem("isLoggedIn", true);
      // console.log("response", response);
      // if(response)
      // saveToSessionStorage("UserData", JSON.stringify(response.data.result));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const signInPatientAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    let payloadData = {};
    let devicetoken = "";
    deviceToken.subscribe((res) => {
      console.log("patient deviceToken 1", res);
      devicetoken = res;
    });
    if (getSessionStorageOrDefault("deviceToken")) {
      devicetoken = getSessionStorageOrDefault("deviceToken");
    }
    if (sessionStorage.getItem("SignInToggle") === "True") {
      payloadData = {
        deviceType: "WEB",
        deviceToken: devicetoken,
        offset: 440,
        mobileNumber: payload.mobile,
        password: payload.password,
      };
    } else {
      payloadData = {
        deviceType: "WEB",
        deviceToken: devicetoken,
        offset: 440,
        email: payload.email,
        password: payload.password,
      };
    }
    try {
      const response = await authenticationService.signInPatientUser(payloadData);
      console.log("response==", response);
      // saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const forgetPwdDocAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    const payloadData = {
      email: payload.email,
    };
    try {
      const response = await authenticationService.forgetPwdDocUser(payloadData);
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const forgetPwdPatAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    const payloadData = {
      email: payload.email,
    };
    try {
      const response = await authenticationService.forgetPwdPatUser(payloadData);
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const resetPwdDocAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    const payloadData = {
      userId: payload.userId,
      password: payload.password,
    };
    try {
      const response = await authenticationService.resetPwdDocUser(payloadData);
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const resetPwdPatAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    const payloadData = {
      userId: payload.userId,
      password: payload.password,
    };
    try {
      const response = await authenticationService.resetPwdPatUser(payloadData);
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const personalPatAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    const payloadData = {
      firstName: payload.firstName,
      lastName: payload.lastName,
      profilePic: payload.profilePic,
      gender: payload.gender,
      date_of_birth: payload.date_of_birth,
      marital_status: payload.marital_status,
      bio: payload.bio,
      lat: payload.lat,
      long: payload.long,
      address: payload.address,
    };

    try {
      const response = await authenticationService.personalPatUser(payloadData);
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const subscriptionListDoctorAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await authenticationService.subscriptionListDoctor();

      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: authenticationAction.DOCTOR_SUBSCRIPTION_LIST,
          doctorSubscriptionList: response?.data?.documentList || [],
        });
      }
      dispatch(commonAction.hideLoader());
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const subscriptionListPatientAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await authenticationService.subscriptionListPatient();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: authenticationAction.PATIENT_SUBSCRIPTION_LIST,
          patientSubscriptionList: response?.data?.documentList,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const getPatientTaxAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await authenticationService.getPatientTax();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: authenticationAction.PATIENT_SUBSCRIPTION_TAX,
          patientSubscriptionTax : response?.data?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const getDoctorTaxAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await authenticationService.getDoctorTax();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: authenticationAction.DOCTOR_SUBSCRIPTION_TAX,
          doctorSubscriptionTax : response?.data?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};
export const paymentAccountPatientAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await authenticationService.paymentAccountPatient(payload);
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const paymentAccountPatientUpgradeAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await authenticationService.paymentAccountPatientUpgrade(payload);
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const paymentAccountDoctorAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await authenticationService.paymentAccountDoctor(payload);
      dispatch(commonAction.hideLoader());
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const medicalProfilePatientAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    const payloadData = {
      height: payload.height,
      weight: payload.weight,
      bloodGroup: payload.bloodGroup,
      iSDiabetes: payload.iSDiabetes,
      diabetesDetails: payload.diabetesDetails,
      allergyDeatils: payload.allergyDeatils,
      familyNotes: payload.familyNotes,
      medicalFile: payload.medicalFile,
      weightUnit: payload.weightUnit && payload.weightUnit,
      hightUnit: payload.hightUnit && payload.hightUnit,
    };

    try {
      const response = await authenticationService.medicalProfilePatient(payloadData);
      dispatch(commonAction.hideLoader());
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const couponListAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await authenticationService.couponList();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: authenticationAction.COUPON_LIST,
          couponList: response?.data?.documentList,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const primarySpecialityListAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await authenticationService.doctorPrimarySpecialityList();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: authenticationAction.PRIMARY_SPECIALITY_LIST,
          primarySpecialityList: response?.data?.documentList,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const secondarySpecialityListAction = (id) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await authenticationService.doctorSecondarySpecialityList(id);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: authenticationAction.SECONDARY_SPECIALITY_LIST,
          secondarySpecialityList: response?.data?.documentList,
          secondarySpecialityMaxLength: response?.data?.documentList.length,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const signUpProfessional = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await authenticationService.signUpForProfessional(payload);
      dispatch(commonAction.hideLoader());
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const paymentAccountDoctorUpgradeAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await authenticationService.paymentAccountDoctorUpgrade(payload);
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};
export default {
  signUpAction,
  signUpPatientAction,
  signInAction,
  signInPatientAction,
  verifyOTPAction,
  resentOTPAction,
  verifyOTPPatientAction,
  resentOTPPatientAction,
  subscriptionListDoctorAction,
  forgetPwdDocAction,
  forgetPwdPatAction,
  resetPwdDocAction,
  resetPwdPatAction,
  personalPatAction,
  subscriptionListPatientAction,
  signUpDoctorPersonalDetailsAction,
  paymentAccountPatientAction,
  medicalProfilePatientAction,
  couponListAction,
  paymentAccountDoctorAction,
  primarySpecialityListAction,
  secondarySpecialityListAction,
  signUpProfessional,
  paymentAccountPatientUpgradeAction,
  paymentAccountDoctorUpgradeAction,
  getPatientTaxAction,
  getDoctorTaxAction
};
