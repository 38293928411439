export default {
  SERVICE_PROVIDER_TYPE_IN_PATIENT: "SERVICE_PROVIDER_TYPE_IN_PATIENT",
  PATIENT_PROFILE_DATA: "PATIENT_PROFILE_DATA",
  FIND_NEAR_BY_PHYSICIANS: "FIND_NEAR_BY_PHYSICIANS",
  FIND_DOC_FOR_HOME_DATA_IN_PATIENT: "FIND_DOC_FOR_HOME_DATA_IN_PATIENT",
  FIND_DOC_FOR_HOME_DATA_IN_PATIENT1: "FIND_DOC_FOR_HOME_DATA_IN_PATIENT1",
  HOME_DATA_OF_LABORATORY_PATIENT: "HOME_DATA_OF_LABORATORY_PATIENT",
  PATIENT_UPCOMING_APPOINTMENT: "PATIENT_UPCOMING_APPOINTMENT",
  PATIENT_PREVIOUS_APPOINTMENT: "PATIENT_PREVIOUS_APPOINTMENT",
  PATIENT_VIEW_APPOINTMENT: "PATIENT_VIEW_APPOINTMENT",
  PATIENT_CARD_LIST_SETTINGS: "PATIENT_CARD_LIST_SETTINGS",
  PATIENT_CARD_LIST: "PATIENT_CARD_LIST",
  PATIENT_CARD_DETAILS: "PATIENT_CARD_DETAILS",
  PATIENT_PRIVACY_POLICY: "PATIENT_PRIVACY_POLICY",
  PATIENT_TERMS_AND_CONDITIONS: "PATIENT_TERMS_AND_CONDITIONS",
  PATIENT_SUBSCRIPTION_INFO_IN_WEB: "PATIENT_SUBSCRIPTION_INFO_IN_WEB",
  PATIENT_WALLET_INFO: "PATIENT_WALLET_INFO",
  PATIENT_RESCHEDULED_APPOINTMENT: "PATIENT_RESCHEDULED_APPOINTMENT",
  PATIENT_SECONDARY_SPECIALITY_LIST: "PATIENT_SECONDARY_SPECIALITY_LIST",
  PATIENT_MEDICAL_RECORDS_LIST: "PATIENT_MEDICAL_RECORDS_LIST",
  PATIENT_PRESCRIPTION_LIST: "PATIENT_PRESCRIPTION_LIST",
  PATIENT_PRESCRIPTION_VIEW: "PATIENT_PRESCRIPTION_VIEW",
  PATIENT_FAV_LIST: "PATIENT_FAV_LIST",
  STATIC_PAGE_CMS: "STATIC_PAGE_CMS",
  PATIENT_LABORATORY_DETAIL: "PATIENT_LABORATORY_DETAIL",
  PATIENT_UPCOMING_LABORATORY_APPOINTMENT: "PATIENT_UPCOMING_LABORATORY_APPOINTMENT",
  PATIENT_PREVIOUS_LABORATORY_APPOINTMENT: "PATIENT_PREVIOUS_LABORATORY_APPOINTMENT",
  PATIENT_REPORT_LIST: "PATIENT_REPORT_LIST",

  PATIENT_MEDICALRECORD_LIST: "PATIENT_MEDICALRECORD_LIST",
  INVOICE_LIST: "INVOICE_LIST",
  INVOICE_VIEW: "INVOICE_VIEW",
  INVOICE_LIST_BILL: "INVOICE_LIST_BILL",
  HELP_LIST: "HELP_LIST",
  VIEW_HELP: "VIEW_HELP",
  REPLY_ON_REQUEST: "REPLY_ON_REQUEST",
};
