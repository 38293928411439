import Loader from "react-loader-spinner";
import { useSelector } from "react-redux";
import { Modal } from "reactstrap";
import "./style.css";
function Spinner() {
  const loadingIndicator = useSelector(
    (state) => state?.common?.loadingIndicator
  );

  return loadingIndicator.isLoading ? (
    <Modal
      className="modal-class"
      //   isOpen={true}
      isOpen={loadingIndicator.isLoading}
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <div
        style={{
          position: "absolute",
          zIndex: 9999999,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          width: "100%",
          backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <Loader
          //   visible={true}
          visible={loadingIndicator.isLoading}
          type="Circles"
          color="#e74c3c"
          height={100}
          width={100}
          // timeout={3000} //3 secs
        />
      </div>
    </Modal>
  ) : null;
}

export default Spinner;
