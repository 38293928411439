import React, { useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { doctor } from "../../../redux/action";
import { ROUTES_CONSTANTS } from "../../../constants";
import { useHistory } from "react-router-dom";

const NewRequest = () => {
  const dispatch = useDispatch();
  let history = useHistory();
  console.log(history);

  const helpSubmitRequest = async (payload) =>
    dispatch(doctor.helpSubmitRequestAction(payload));
  const formik = useFormik({
    initialValues: {
      subject: "",
      message: "",
    },
    validationSchema: Yup.object({
      subject: Yup.string().trim().required("*Required."),
      message: Yup.string().trim().required("*Required"),
    }),
  });

  const submitNewRequest = async (e) => {
    console.log(formik.values);
    if (formik.values.subject.trim() && formik.values.message.trim()) {
      console.log("Success");
      const payload = {
        subject: formik.values.subject,
        message: formik.values.message,
      };
      const res = await helpSubmitRequest(payload);
      if (res.status == 200) {
        history.push(ROUTES_CONSTANTS.HELP);
      }
    }
  };

  return (
    <div className=" col-lg-8 col-xl-9 help">
      <div className="card">
        <div className="card-header">
          <h4>Submit New Request</h4>
        </div>
        <div className="card-body">
          <form onSubmit={formik.handleSubmit}>
            <div className="subject mb-3">
              <h6>
                <b>Subject</b>
              </h6>
              <input
                type="text"
                maxLength="32"
                name="subject"
                {...formik.getFieldProps("subject")}
                className={`${"form-control"} ${
                  formik.touched.subject && formik.errors.subject
                    ? "is-invalid"
                    : ""
                }`}
              />
              {formik.touched.subject && formik.errors.subject ? (
                <span className="validation_error">
                  {formik.errors.subject}
                </span>
              ) : null}
            </div>
            <div className="subject mb-3">
              <h6>
                <b>Description</b>
              </h6>
              <textarea
                className={`${"form-control"} ${
                  formik.touched.subject && formik.errors.subject
                    ? "is-invalid"
                    : ""
                }`}
                name="message"
                maxLength="180"
                {...formik.getFieldProps("message")}
                rows="4"
              ></textarea>
              {formik.touched.message && formik.errors.message ? (
                <span className="validation_error">
                  {formik.errors.message}
                </span>
              ) : null}
            </div>
            <div className="submit-section text-center">
              <button
                type="submit"
                onClick={() => submitNewRequest()}
                className="btn btn-primary w-25 mt-2 mb-3"
              >
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default NewRequest;
