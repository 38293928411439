import { useDispatch, useSelector } from "react-redux";
import { patient } from "../../../../redux/action";
import { useEffect, useState } from "react";
import PaginationComponent from "react-reactstrap-pagination";
import moment from "moment";
import SocialShareModal from "../../../common/SocialShareModal/SocialShareModal";

function MyReports() {
  let dispatch = useDispatch();
  const [selectedPage, setselectedPage] = useState(1);
  const [DataLimit, setDataLimit] = useState(8);
  const [SocialModal, setSocialModal] = useState(false);
  const [getRefferalLink, setgetRefferalLink] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    if (DataLimit) {
      patientReportList(DataLimit, selectedPage);
    }
  }, [DataLimit, selectedPage]);

  const patientReportList = async (DataLimit, selectedPage) => dispatch(patient.patientReportListAction(DataLimit, selectedPage));
  const patientReportInfo = useSelector((state) => state?.patient?.reportListdata);
  console.log("patientReportInfo", patientReportInfo);

  const handleSelected = (data) => {
    console.log("clicked page", data);
    setselectedPage(data);
  };

  const handlePageLimit = (e) => {
    console.log("page data", e.target.value);
    setDataLimit(e.target.value);
  };

  const handleShare = (link) => {
    setSocialModal(!SocialModal);
    setgetRefferalLink(link);
  };
  return (
    <div class=" col-lg-8 col-xl-9 content_ipad_p ">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">My Reports</h4>
        </div>
        <div class="card-body">
          <div class="row">
            {patientReportInfo?.reportData ? (
              patientReportInfo?.reportData.map((item) => (
                <div class="col-sm-6 col-md-6 col-lg-6 ">
                  <div class="card">
                    <div class="card-body">
                      <div class="text-right color-grey">
                        <span>{item?.reportStatus}</span>
                      </div>
                      <div class="d-flex justify-content-between">
                        <div>
                          <div>
                            <span>
                              <b>{item?.packageId?.name}</b>
                            </span>
                          </div>
                          <div>
                            <span class="color-grey">Booking Id : {item?._id}</span>
                          </div>
                          <div>
                            <span class="color-grey">Patient Name : {`${item?.patientId?.firstName} ${item?.patientId?.lastName}`}</span>
                          </div>
                          <div>
                            <span class="color-grey">Date : {moment(item?.reportDateTime).format("MM/DD/YYYY")}</span>
                          </div>
                        </div>
                      </div>
                      <div class="text-right">
                        <span class="c-pointer mr-2" onClick={() => handleShare(item?.reportFile)}>
                          <i class="fas fa-share-alt" data-toggle="modal" data-target="#pat_share-report" data-dismiss="modal"></i>
                        </span>
                        <span class="c-pointer">
                          <a href={item?.reportFile}>
                            <i class="fas fa-download"></i>
                          </a>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-md-12">
                <p className="text-center">There are no reports available.</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {patientReportInfo && patientReportInfo?.totalList && patientReportInfo?.totalList > DataLimit ? (
        <div class="row">
          <div className="col-sm-3 col-md-3">
            <div className="dataTables_length" id="DataTables_Table_0_length">
              <label>
                Show{" "}
                <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" onChange={(e) => handlePageLimit(e)}>
                  {/* <option value="4">4</option> */}
                  <option value="8">8</option>
                  <option value="10">10</option>
                </select>{" "}
                entries
              </label>
            </div>
          </div>
          <div class="col-sm-5 col-md-5">
            <div class="dataTables_info showing_pagination" id="DataTables_Table_0_info" role="status" aria-live="polite">
              Showing {(selectedPage - 1) * DataLimit + 1} to {(selectedPage - 1) * DataLimit + (patientReportInfo && patientReportInfo.reportData && patientReportInfo.reportData.length)} of {patientReportInfo && patientReportInfo.totalList} entries
            </div>
          </div>
          <div class="col-sm-4 col-md-4">
            <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
              <PaginationComponent totalItems={patientReportInfo && patientReportInfo.totalList} pageSize={DataLimit} maxPaginationNumbers={3} defaultActivePage={1} onSelect={(e) => handleSelected(e)} />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <SocialShareModal SocialModal={SocialModal} setSocialModal={setSocialModal} getRefferalLink={getRefferalLink} />
    </div>
  );
}

export default MyReports;
