import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { doctor } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import ToasterFunc from "../../common/toaster/Toaster";
import dayjs from "dayjs";
const AddNewCard = ({ addNewCardModal, setAddNewCardModal, editCardModal, setEditCardModal, editCardId }) => {
  const dispatch = useDispatch();
  const [CardnumErr, setCardnumErr] = useState("");
  const [CardErr, setCardErr] = useState("");
  const [isSaved, setisSaved] = useState(false);
  const [ExpiryMonthErr, setExpiryMonthErr] = useState("");
  const [CardType, setCardType] = useState({
    credit: true,
    debit: false,
  });
  const [viewCardData, setViewCardData] = useState({});
  // const [editCardData, setEditCardData] = useState([])

  const cardListData = async () => dispatch(doctor.docCardListAction());
  const addCard = async (payload) => dispatch(doctor.docAddPaymentCardAction(payload));
  const editCard = async (payload) => dispatch(doctor.docEditCardAction(payload));

  const cardList = useSelector((state) => state?.doctor?.doctorCardList?.documentList);

  const formik = useFormik({
    initialValues: {
      cardHolderName: "",
      CardNum: "",
      cardexpiry: "",
    },
    validationSchema: Yup.object({
      cardHolderName: Yup.string()
        .required("*Required.")
        .trim()
        .matches(/^[^-\s][\w\s-]+$/, "Please Enter required value"),
      CardNum: Yup.number().required("*Required.").typeError("*Please enter only digits"),
      cardexpiry: Yup.string().required("*Required.").trim().typeError("*Please enter only digits"),
    }),
  });
  let currentYear = dayjs().year().toString();
  let modifiedcurrentYear = `${currentYear.split("")[2]}${currentYear.split("")[3]}`;
  const handleCard = (e) => {
    let name = e.target.value;
    let value = e.target.checked;
    if (name === "Credit") {
      setCardType({
        credit: value,
      });
    } else if (name === "Debit") {
      setCardType({
        debit: value,
      });
    }
  };

  React.useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.target.name === "CardNum"){
        event.target.value = event.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
      }
      if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
        var form = event.target.form;
        var index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + 1].focus();
        event.preventDefault();
      }
    });
  }, []);

  const handleCardexpiry = () => {
    const inp = document.querySelector("#input");
    inp.onkeydown = function (e) {
      const key = e.keyCode || e.charCode;
      if (key !== 8 && key !== 46) {
        if (inp.value.length == 2) {
          inp.value = inp.value + "/";
        }
      }

      if ((key == 8 || key == 46) && inp.value.length === 4) {
        inp.value = inp.value.slice(0, 3);
      }
    };
  };
  useEffect(() => {
    if (CardType.credit === true || CardType.debit === true) {
      setCardErr("");
    }
    if (formik.values.CardNum.replaceAll(" " , "").length === 16) {
      setCardnumErr("");
    }
    if (formik.values.cardexpiry.split("/")[0] <= 12) {
      setExpiryMonthErr("");
    }
    if (formik.values.cardexpiry.split("/")[1] > modifiedcurrentYear) {
      setExpiryMonthErr("");
    }
    if (formik.values.cardexpiry.length === 5) {
      setExpiryMonthErr("");
    }
  }, [CardType, formik.values.CardNum, formik.values.cardexpiry]);

  const handleSubmit = async () => {
    if (CardType.credit === false && CardType.debit === false) {
      setCardErr("*Please Choose any one");
    }
    if (CardType.credit === true || CardType.debit === true) {
      setCardErr("");
    }
    if (formik.values.cardexpiry !== "" && formik.values.cardexpiry.length < 5) {
      setExpiryMonthErr("*Invalid Expiry Date");
    }
    if (formik.values.CardNum !== "" && formik.values.CardNum.replaceAll(" " , "").length < 16) {
      setCardnumErr("*Invalid Card Number");
    }
    if (formik.values.cardexpiry.split("/")[0] > 12) {
      setExpiryMonthErr("*Invalid Expiry Month");
    }

    if (formik.values.cardexpiry.split("/")[1] < modifiedcurrentYear) {
      setExpiryMonthErr("*Invalid Expiry Year");
    }
    const cardPayload = {
      cardType: CardType.credit ? "CREDIT" : "DEBIT",
      cardNumber: formik.values.CardNum.replaceAll(" " , ""),
      cardHolderName: formik.values.cardHolderName.trim(),
      expMonth: formik.values.cardexpiry?.split("/")[0].trim(),
      expYear: formik.values.cardexpiry?.split("/")[1],
    };
    console.log(cardPayload);
    if (CardType.credit !== false || CardType.debit !== false) {
      if (
        formik.values.CardNum !== "" &&
        formik.values.CardNum.replaceAll(" " , "").length === 16 &&
        formik.values.fullname !== "" &&
        formik.values.cardexpiry !== "" &&
        formik.values.cardexpiry.length <= 5 &&
        formik.values.cardexpiry.split("/")[0] <= 12 &&
        formik.values.cardexpiry.split("/")[1] > modifiedcurrentYear
      ) {
        const res = await addCard(cardPayload);
        console.log("add card response",res);
        if (res.status == 200) {
          ToasterFunc("success", res.data.responseMessage);
          cardListData();
          setAddNewCardModal(false);
          formik.resetForm();
        } else {
          ToasterFunc("errors", res.data.responseMessage);
        }
      }
    }
  };

  useEffect(() => {
    const findItem = cardList?.filter((itemInArray) => itemInArray._id === editCardId);
    setViewCardData(findItem);
    console.log(findItem);
  }, [editCardId, cardList]);

  useEffect(() => {
    if (editCardModal) {
      formik.setFieldValue("cardHolderName", viewCardData && viewCardData[0]?.cardHolderName ? viewCardData[0]?.cardHolderName : "");
      formik.setFieldValue("CardNum", viewCardData && viewCardData[0]?.cardNumber ? viewCardData[0]?.cardNumber?.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim() : "");
      formik.setFieldValue(
        "cardexpiry",
        `${`${viewCardData && viewCardData[0]?.expMonth ? viewCardData[0]?.expMonth : ""}/${
          viewCardData && viewCardData[0]?.expYear ? viewCardData[0].expYear : ""
        }`}`
      );
    } else {
      formik.setFieldValue("cardHolderName", "");
      formik.setFieldValue("CardNum", "");
      formik.setFieldValue("cardexpiry", "");
    }
  }, [editCardModal, viewCardData]);

  const handleUpdate = async () => {
    if (CardType.credit === false && CardType.debit === false) {
      setCardErr("*Please Choose any one");
    }
    if (CardType.credit === true || CardType.debit === true) {
      setCardErr("");
    }
    if (formik.values.cardexpiry !== "" && formik.values.cardexpiry.length < 5) {
      setExpiryMonthErr("*Invalid Expiry Date");
    }
    if (formik.values.CardNum !== "" && formik.values.CardNum.replaceAll(" " , "").length < 16) {
      setCardnumErr("*Invalid Card Number");
    }
    if (formik.values.cardexpiry.split("/")[0] > 12) {
      setExpiryMonthErr("*Invalid Expiry Month");
    }

    if (formik.values.cardexpiry.split("/")[1] < modifiedcurrentYear) {
      setExpiryMonthErr("*Invalid Expiry Year");
    }
    console.log("viewCardData",viewCardData);
    const cardPayload = {
      cardId: editCardId,
      cardType: viewCardData && viewCardData[0]?.cardType,
      cardHolderName: formik.values.cardHolderName ? formik.values.cardHolderName : viewCardData[0]?.cardHolderName,
      expMonth: formik.values.cardexpiry?.split("/")[0] ? formik.values.cardexpiry?.split("/")[0] : viewCardData[0]?.expMonth,
      expYear: formik.values.cardexpiry?.split("/")[1] ? formik.values.cardexpiry?.split("/")[1] : viewCardData[0].expYear,
    };

    if (viewCardData[0]?.cardNumber != formik.values.CardNum.replaceAll(" " , "")){
      cardPayload['cardNumber']=  formik.values.CardNum.replaceAll(" " , "");

    }

    console.log("cardPayload",cardPayload);

    if (formik.values.CardNum !== "" && formik.values.CardNum.replaceAll(" " , "").length < 16) {
      setCardnumErr("*Invalid Card Number");
    }
    if (
      formik.values.CardNum !== "" &&
      formik.values.CardNum.replaceAll(" " , "").length === 16 &&
      formik.values.fullname !== "" &&
      formik.values.cardexpiry !== "" &&
      formik.values.cardexpiry.length <= 5 &&
      formik.values.cardexpiry.split("/")[0] <= 12 &&
      formik.values.cardexpiry.split("/")[1] > modifiedcurrentYear
    ) {
      const res = await editCard(cardPayload);
      if (res.status == 200) {
        ToasterFunc("success", res.data.responseMessage);
        cardListData();
        setEditCardModal(false);
        formik.resetForm();
      } else {
        ToasterFunc("errors", res.data.responseMessage);
      }
    }

    console.log(cardPayload);
  };
  const handleClose = () => {
    setAddNewCardModal(false);
    setEditCardModal(false);
    if (!editCardModal) {
      formik.resetForm();
    }
  };

  console.log(editCardModal);
  return (
    <Modal isOpen={addNewCardModal || editCardModal} className=" modal-dialog-centered">
      <div className="modal-content">
        <button type="button" className="close custom-model-close" aria-label="Close" data-dismiss="modal" onClick={handleClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <div className="modal-body doc_add_card text-center p-4">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="card mb-0">
                <form onSubmit={formik.handleSubmit}>
                  <div className="card-body">
                    <h4 className="card-title">{editCardModal ? <>Edit</> : <>Add</>} Card Detail</h4>
                    {!editCardModal ? (
                      <>
                        <div className="row mb-2">
                          <div className="col-md-12">
                            <div className="form-check form-check-inline mb-2">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="Card"
                                id="credit"
                                value="Credit"
                                defaultChecked
                                onClick={(e) => handleCard(e)}
                              />
                              <label className="form-check-label" for="credit">
                                Credit
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input
                                className="form-check-input"
                                type="radio"
                                name="Card"
                                id="debit"
                                value="Debit"
                                onClick={(e) => handleCard(e)}
                              />
                              <label className="form-check-label" for="debit">
                                Debit
                              </label>
                            </div>
                          </div>
                        </div>
                        {CardErr ? <div className="validation_error card">{CardErr}</div> : null}
                      </>
                    ) : (
                      ""
                    )}
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group text-left">
                          <label for="Cardholder name">Full Name on Card</label>
                          <input
                            type="text"
                            className="form-control"
                            name="cardHolderName"
                            maxLength="16"
                            {...formik.getFieldProps("cardHolderName")}
                          />
                          {formik.touched.cardHolderName && formik.errors.cardHolderName ? (
                            <span className="validation_error">{formik.errors.cardHolderName}</span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group text-left">
                          <label for="Cardholder name">Card Number</label>
                          <input type="text" className="form-control" name="CardNum"
                           onPaste={(e)=>{
                            e.preventDefault()
                            return false;
                          }}
                          maxLength="19" {...formik.getFieldProps("CardNum")} />
                          {formik.touched.CardNum && formik.errors.CardNum ? (
                            <span className="validation_error">{formik.errors.CardNum}</span>
                          ) : null}
                          {formik.errors.CardNum ? "" : CardnumErr ? <span className="validation_error">{CardnumErr}</span> : null}
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group text-left">
                          <label for="Cardholder name">Card Expiry</label>
                          <input
                            type="text"
                            id="input"
                            className="form-control"
                            placeholder="MM/YY"
                            name="cardexpiry"
                            maxLength="5"
                            {...formik.getFieldProps("cardexpiry")}
                            onFocus={handleCardexpiry}
                          />
                          {formik.touched.cardexpiry && formik.errors.cardexpiry ? (
                            <span className="validation_error">{formik.errors.cardexpiry}</span>
                          ) : null}
                          {formik.errors.cardexpiry ? (
                            ""
                          ) : ExpiryMonthErr ? (
                            <span className="validation_error">{ExpiryMonthErr}</span>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="btn-p">
                      {editCardModal ? (
                        <button type="submit" className="btn btn-primary mt-2" onClick={handleUpdate}>
                          {" "}
                          Update{" "}
                        </button>
                      ) : (
                        <button type="submit" className="btn btn-primary mt-2 ml-2" onClick={handleSubmit}>
                          {" "}
                          Done{" "}
                        </button>
                      )}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default AddNewCard;
