import React, { useState, useEffect } from "react";
import { patient } from "../../../../redux/action";
import ViewPrescription from "./ViewPrescription";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PaginationComponent from "react-reactstrap-pagination";

function Patientrescription() {
  let dispatch = useDispatch();
  const url = useLocation();
  const [selectedPage, setselectedPage] = useState(1);
  const [DataLimit, setDataLimit] = useState(4);
  const [issueDate, setissueDate] = useState("");
  const [dueDate, setdueDate] = useState("");
  const [PresId, setPresId] = useState("");
  const [DocName, setDocName] = useState("");
  const [viewPresModal, setviewPresModal] = useState(false);
  const handlePrescription = () => {
    setviewPresModal(!viewPresModal);
  };

  console.log("pathname", url);

  const patientPrescriptionInfo = useSelector((state) => state?.patient?.patientPrescriptionList);
  console.log("patientPrescriptionInfo", patientPrescriptionInfo);

  console.log("patientPrescriptionInfo", patientPrescriptionInfo);

  useEffect(() => {
    if (DataLimit) {
      patientPrescriptionData(url?.state?.Id, DataLimit, selectedPage);
    }
    setDocName(url?.state?.Name);
  }, [url, DataLimit, selectedPage]);

  const handleSelected = (data) => {
    console.log("clicked page", data);
    setselectedPage(data);
  };

  const handlePageLimit = (e) => {
    console.log("page data", e.target.value);
    setDataLimit(e.target.value);
  };

  useEffect(() => {
    if (patientPrescriptionInfo && patientPrescriptionInfo.prescriptionData && patientPrescriptionInfo.prescriptionData.length) {
      patientPrescriptionInfo.prescriptionData.map((item) => {
        let issueDay = new Date(item.issueDate).toLocaleDateString().split("/")[1];
        let issueMonth = new Date(item.issueDate).toLocaleDateString().split("/")[0];
        let issueYear = new Date(item.issueDate).toLocaleDateString().split("/")[2];
        let issueDayformat = `${issueDay}/${issueMonth}/${issueYear}`;
        setissueDate(issueDayformat);
        let dueDay = new Date(item.dueDate).toLocaleDateString().split("/")[1];
        let dueMonth = new Date(item.dueDate).toLocaleDateString().split("/")[0];
        let dueYear = new Date(item.dueDate).toLocaleDateString().split("/")[2];
        let dueDayformat = `${dueDay}/${dueMonth}/${dueYear}`;
        setdueDate(dueDayformat);
      });
    }
  }, [patientPrescriptionInfo]);

  console.log("PresId", PresId);
  const patientPrescriptionData = async (payload, DataLimit, selectedPage) => dispatch(patient.patientPrescriptionListAction(payload, DataLimit, selectedPage));
  return (
    <div className=" col-lg-8 col-xl-9">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Prescription</h4>
        </div>
        <div className="card-body">
          <div className="row">
            {patientPrescriptionInfo?.prescriptionData?.length ? (
              patientPrescriptionInfo.prescriptionData.map((item) => (
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div>
                        <div>
                          <span>
                            <b>Prescription :</b> {item && item.name}
                          </span>
                        </div>
                        <div>
                          <span className="color-grey">Issued Date : {issueDate}</span>
                        </div>
                        <div>
                          <span className="color-grey">Due Date : {dueDate}</span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center mt-4">
                        <button className="btn btn-primary mr-1 w-50" onClick={() => handlePrescription(setPresId(item._id))}>
                          <i className="fas fa-eye mr-1"></i>View
                        </button>
                        {/* <a href="" className="btn btn-primary  w-50" data-toggle="modal" data-target="#" data-dismiss="modal">
                          <i className="fas fa-print mr-1"></i>Print
                        </a> */}
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-md-12">
                <p className="text-center">No Prescriptions available.</p>
              </div>
            )}
          </div>
          {patientPrescriptionInfo && patientPrescriptionInfo.totalList > DataLimit ? (
            <div class="row">
              <div className="col-sm-3 col-md-3">
                <div className="dataTables_length" id="DataTables_Table_0_length">
                  <label>
                    Show{" "}
                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" onChange={(e) => handlePageLimit(e)}>
                      <option value="4">4</option>
                      <option value="8">8</option>
                      <option value="10">10</option>
                    </select>{" "}
                    entries
                  </label>
                </div>
              </div>
              <div class="col-sm-5 col-md-5">
                <div class="dataTables_info showing_pagination" id="DataTables_Table_0_info" role="status" aria-live="polite">
                  Showing {(selectedPage - 1) * DataLimit + 1} to {(selectedPage - 1) * DataLimit + (patientPrescriptionInfo && patientPrescriptionInfo.prescriptionData && patientPrescriptionInfo.prescriptionData.length)} of{" "}
                  {patientPrescriptionInfo && patientPrescriptionInfo.totalList} entries
                </div>
              </div>
              <div class="col-sm-4 col-md-4">
                <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                  <PaginationComponent
                    totalItems={patientPrescriptionInfo && patientPrescriptionInfo.totalList}
                    pageSize={DataLimit}
                    maxPaginationNumbers={Math.ceil(patientPrescriptionInfo && patientPrescriptionInfo.totalList / DataLimit)}
                    defaultActivePage={1}
                    onSelect={(e) => handleSelected(e)}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <ViewPrescription viewPresModal={viewPresModal} setviewPresModal={setviewPresModal} PresId={PresId} DocName={DocName} />
    </div>
  );
}

export default Patientrescription;
