import React,{useEffect} from 'react';
import { useDispatch } from 'react-redux';
import { Modal } from 'reactstrap'
import { doctor } from '../../../redux/action';
import ToasterFunc from '../../common/toaster/Toaster';

const DeleteCard = ({ deleteModal, setDeleteModal, cardData }) => {
    let  dispatch = useDispatch()
    const id = cardData.cardId
    console.log(cardData);
    const handleClose = () => {
        setDeleteModal(false)
    }
    const deleteCard = async (payload) => dispatch(doctor.docDeleteCardAction(payload));
    const cardListData = async () => dispatch(doctor.docCardListAction());


    const handleConfirm = async () => {
        console.log(id);
        const res = await deleteCard(id);
        if (res.status == 200) {
            ToasterFunc("success", res.data.responseMessage);
            cardListData()
            setDeleteModal(false)
        }


    }


    return (
        <Modal isOpen={deleteModal} className="card-modal modal-dialog-centered">
            <div className="modal-content">
                <button
                    type="button"
                    className="close custom-model-close"
                    aria-label="Close"
                    data-dismiss="modal"
                    onClick={handleClose}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                <div class="modal-body text-center">
                    <div class="form-content p-2">
                        <h4 class="modal-title text-center mb-2">Remove Card</h4>
                        <p class="mb-3 default-content-clr text-center"><b>Card Number </b><br />{cardData.cardNumber.replace(/.(?=.{4})/g, "X")}</p>
                        <p class="default-content-clr text-center">Are you sure want to remove this card?</p>
                        <button type="button" class="btn btn-primary modal-confirm-btn" data-dismiss="modal">Cancel</button>
                        <button type="button" class="btn btn-primary" onClick={handleConfirm}>Confirm</button>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default DeleteCard
