import React,{useEffect} from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Slider from "react-slick";
import { IMAGE_CONSTANT } from '../../../constants';
import { doctor } from '../../../redux/action';

const OurTeam = () => {
  let dispatch = useDispatch()
  useEffect(() => {
    ourTeamList()
  }, [])

  const ourTeamList = async (payload) => dispatch(doctor.ourTeamListAction(payload))
  const ourTeamData = useSelector(state => state?.doctor?.ourTeamListData)
  console.log(ourTeamData);
  const team = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1.5,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className=" col-lg-12 col-xl-12 content_ipad_p contact-us">
        <div className="row">
          <div className="col-sm-12">
            <div className="about-gym-doc">
              <div className="container">
                <div className="card-body">
                  <div className='text-center'>
                    <h1 className='clr-white font-size-main'>Meet Our Team Members</h1>
                    {/* <p className='clr-white'>vitae, ducimus labore iusto rerum! Explicabo, illo quae suscipit incidunt soluta dolore odit tempore,</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          {ourTeamData && ourTeamData.length?
          <div className="team-row">
            <Slider {...team} className="doctor-slider">
              {ourTeamData && ourTeamData.length && ourTeamData.map((item, i)=>
              <div className="team-bx" key={i}>
              <figure>
                <img src={item && item.image} className='img-fluid' alt="Team" />
              </figure>
              <figcaption>
                <span>{item && item.name}</span>
                <p>{item && item.designation}</p>
              </figcaption>
            </div>
              )}
            </Slider>
          </div>
        :<p className='text-center p-3'>Data not found!</p>}
        </div>
      </div>
    </>
  )
}

export default OurTeam
