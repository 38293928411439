import { ratingActionType } from "../../action";
import initialState from "../initialState";

export default function ratingReducer(state = initialState.common, action) {
  switch (action.type) {
    case ratingActionType.GET_RATING_LIST:
      return {
        ...state,
        ratingList: action.ratingList,
      };
    case ratingActionType.CHALLANGE_REVIEWS:
      return {
        ...state,
        challangeData: action.challangeData,
      };
    default:
      return state;
  }
}
