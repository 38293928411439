import React, { useEffect, useState } from "react";
import videoCallImg from "../../../assets/img/video-call.jpg";
import Patient from "../../../assets/img/patients/patient1.jpg";
import { useDispatch } from "react-redux";
import { commonAction } from "../../../redux/action";
import { useHistory, useParams } from "react-router-dom";
import { connect } from "twilio-video";
import Room from "./Room";
import Participant from "./Participant";
import { getSessionStorageOrDefault } from "../../../utils";

function OutgoingCall({ callDisConnect }) {
  const [callerDetails, setCallerData] = useState();

  useEffect(() => {
    setCallerData(JSON.parse(getSessionStorageOrDefault("callerDetails")));
  }, []);

  return (
    <div className="col-xl-12 mt-4 mb-4">
      <div class="outgoing_call incoming-call">
        <div class="call-contents">
          <div class="call-content-wrap">
            <div class="user-details">
              <div class="user-info text-center">
                <a>
                  {console.log(callerDetails)}
                  <h2>{`Calling ${callerDetails?.fullName}`}</h2>
                </a>
              </div>
              <div className="profile">
                <figure>
                  <img src={callerDetails?.profilePic} alt="" />
                </figure>
              </div>
            </div>
            <div class="user-video"></div>
            <div class="call-footer">
              <div class="call-icons">
                <ul class="call-items">
                  <li class="call-item call-end">
                    <a href title="Call End" data-placement="top" data-toggle="tooltip" onClick={callDisConnect}>
                      <i class="fas fa-phone"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OutgoingCall;
