import moment from "moment";
import React, { useEffect, useState } from "react";
import PaginationComponent from "react-reactstrap-pagination";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { doctor, patient } from "../../../../redux/action";
import PatientBilldetail from "../Appointment/CompletedViewApp.js/PatientBilldetail";

function ViewBills() {
  let dispatch = useDispatch();
  let location = useLocation();
  const [selectedPage, setselectedPage] = useState(1);
  const [DataLimit, setDataLimit] = useState(4);
  const [BillModal, setBillModal] = useState(false);
  const [BillId, setBillId] = useState("");
  const patientinvoicelist = async (payload) => dispatch(patient.patientinvoicelistAction(payload));
  useEffect(() => {
    if (DataLimit) {
      if (location?.state) {
        patientinvoicelist(location?.state, DataLimit, selectedPage);
      }
    }
  }, [location, DataLimit, selectedPage]);
  console.log("DocData", location?.state);

  const patientinvoicedata = useSelector((state) => state?.patient?.invoiceListInfo);

  console.log("patientinvoicedata", patientinvoicedata);

  const handleView = (billId) => {
    setBillModal(!BillModal);
    setBillId(billId);
  };

  const handleSelected = (data) => {
    console.log("clicked page", data);
    setselectedPage(data);
  };

  const handlePageLimit = (e) => {
    console.log("page data", e.target.value);
    setDataLimit(e.target.value);
  };
  return (
    <div className=" col-lg-8 col-xl-9 content_ipad_p">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Billings</h4>
        </div>
        <div className="card-body ">
          <div class="row">
            {patientinvoicedata?.invoiceData?.length ? (
              patientinvoicedata?.invoiceData.map((item) => (
                <div class="col-md-6">
                  <div class="card">
                    <div class="card-body">
                      <div class="d-flex justify-content-between">
                        <div>
                          <div>
                            <span>
                              <b>Invoice Number :</b>
                              {item?.invoiceNumber}
                            </span>
                          </div>
                          <div>
                            <span class="color-grey">
                              <b>Doctor's Name :</b> Dr. {item?.serviceProviderId?.fullName}
                            </span>
                          </div>
                          <div>
                            <span class="color-grey">
                              <b>Amount Paid :</b>
                              {item?.amount}
                            </span>
                          </div>
                          <div>
                            <span class="color-grey">Paid On : {moment(item?.createdAt).format("DD/MM/YY")}</span>
                          </div>
                        </div>
                        <div>
                          <span class="c-pointer" onClick={() => handleView(item._id)}>
                            <i class="fa fa-eye" aria-hidden="true"></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-md-12">
                <p className="text-center">No bills available.</p>
              </div>
            )}
          </div>
          {patientinvoicedata && patientinvoicedata?.totalList && patientinvoicedata?.totalList > DataLimit ? (
            <div class="row">
              <div className="col-sm-3 col-md-3">
                <div className="dataTables_length" id="DataTables_Table_0_length">
                  <label>
                    Show{" "}
                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" onChange={(e) => handlePageLimit(e)}>
                      <option value="4">4</option>
                      <option value="8">8</option>
                      <option value="10">10</option>
                    </select>{" "}
                    entries
                  </label>
                </div>
              </div>
              <div class="col-sm-5 col-md-5">
                <div class="dataTables_info showing_pagination" id="DataTables_Table_0_info" role="status" aria-live="polite">
                  Showing {(selectedPage - 1) * DataLimit + 1} to {(selectedPage - 1) * DataLimit + (patientinvoicedata && patientinvoicedata.invoiceData && patientinvoicedata.invoiceData.length)} of{" "}
                  {patientinvoicedata && patientinvoicedata.totalList} entries
                </div>
              </div>
              <div class="col-sm-4 col-md-4">
                <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                  <PaginationComponent totalItems={patientinvoicedata && patientinvoicedata.totalList} pageSize={DataLimit} maxPaginationNumbers={3} defaultActivePage={1} onSelect={(e) => handleSelected(e)} />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
          <PatientBilldetail BillModal={BillModal} setBillModal={setBillModal} BillId={BillId} />
        </div>
      </div>
    </div>
  );
}

export default ViewBills;
