import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import Slider from "react-slick";
import { IMAGE_CONSTANT } from '../../../constants';
import { doctor } from '../../../redux/action';

const Testimonials = () => {
  let dispatch = useDispatch()
  useEffect(() => {
    testimonialData()
  }, [])

  const testimonialData = async (payload) => dispatch(doctor.testimonialsListAction(payload))
  const testmonialsList = useSelector(state => state?.doctor?.testimonialsListData)

  console.log(testmonialsList);
  const testimonia = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1.5,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <>
      <div className=" col-lg-12 col-xl-12 content_ipad_p testimonial">
        <div className="row">
          <div className="col-sm-12">
            <div className="about-gym-doc">
              <div className="container">
                <div className="card-body">
                  <h1 className='clr-white font-size-main pad-left-10'>Testimonials</h1>
                  {/* <p className='clr-white'>These are the inspiring stories of people living with mental health issues and disability, their carers, workers and our staff.</p> */}
                </div>
              </div>
            </div>
            <div className="container">
              <div className='text-center section-header'>
                <h2 className='mt-5'>What our users have to say </h2>
                <p className='color-grey'>These are the inspiring stories of people living with mental health issues and disability, their carers, workers and our staff.</p>
              </div>
              <div className="card-body mb-5">
                {/* carousal  */}
                <Slider {...testimonia} className="doctor-slider">
                  {testmonialsList && testmonialsList.length && testmonialsList.map((item, i) =>
                    <div className='Testimonial-card'>
                      <div className="card">
                        <div className="card-body">
                          <div className='d-flex  align-items-center'>
                            <figure className='mr-2 mb-0'>
                              {item && item.image ? <img src={item && item.image} className='img-fluid' alt="img" /> : ''}
                            </figure>
                            <div className='w-75'>
                              <label htmlFor="" className='mb-0 '><b>{item && item.name}</b></label>
                              <label htmlFor="" className='color-grey mb-0'>{item && item.designation}</label>
                            </div>
                          </div>
                          <p className="testi-content-clr">
                            {item && item.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  )}




                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default Testimonials
