import axios from "axios";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation , useHistory} from "react-router-dom";
import ReactStars from "react-stars";
import Sticky from "react-sticky-el";
import DocImg from "../../../assets/img/doctors/doctor-01.jpg";
import { ROUTES_CONSTANTS } from "../../../constants";
import { commonAction } from "../../../redux/action";
import { saveToSessionStorage } from "../../../utils";
import { ForgotPassword, Login, LoginWithNumber } from "../../authentication";
import { SignUp } from "../../authentication/signup";
import getSessionStorageValue from '../'
function HomeSearch() {
  let dispatch = useDispatch();
  let location = useLocation();
  console.log("location", location);

  const [GenderValue, setGenderValue] = useState("");
  const [SearchDocLits, setSearchDocLits] = useState([]);
  const [FilteredToggle, setFilteredToggle] = useState(false);
  const [ServiceProviderType, setServiceProviderType] = useState([]);
  const [ServiceProviderId, setServiceProviderId] = useState("");
  const [AlldataToggle, setAlldataToggle] = useState(false);
  const [resvisedId, setresvisedId] = useState("");
  const [resvisedGender, setresvisedGender] = useState("");
  const [revisedAlldataToggle, setrevisedAlldataToggle] = useState(false);
  const [DocID, setDocID] = useState("");
  const [SignUpModal, setSignUpModal] = useState(false);
  const [SignInModal, setSignInModal] = useState(false);
  const [ForgetPwdModal, setForgetPwdModal] = useState(false);
  const [LoginWithNumModal, setLoginWithNumModal] = useState(false);

  let history = useHistory();
  const getSessionStorageValue = (key) => {
    const stored = sessionStorage.getItem(key);
    console.log(stored);
    return JSON.parse(stored);
  };
  //   const getSearchDoclist = dispatch();
  useEffect(() => {
    if (location?.state?.push[0]) {
      let payload = {};
      if (FilteredToggle && resvisedId && resvisedGender) {
        payload = {
          serviceProviderTypeId: resvisedId ? resvisedId : "",
          search: location?.state?.push[0]?.DoctorName ? location?.state?.push[0]?.DoctorName : "",
          lat: location?.state?.push[0]?.SearchLat,
          long: location?.state?.push[0]?.SearchLng,
          gender: resvisedGender ? resvisedGender : "",
        };
      } else if (FilteredToggle && resvisedId) {
        payload = {
          serviceProviderTypeId: resvisedId ? resvisedId : "",
          search: location?.state?.push[0]?.DoctorName ? location?.state?.push[0]?.DoctorName : "",
          lat: location?.state?.push[0]?.SearchLat,
          long: location?.state?.push[0]?.SearchLng,
          // gender: resvisedGender ? resvisedGender : "",
        };
      } else if (FilteredToggle && resvisedGender) {
        payload = {
          // serviceProviderTypeId: resvisedId ? resvisedId : "",
          search: location?.state?.push[0]?.DoctorName ? location?.state?.push[0]?.DoctorName : "",
          lat: location?.state?.push[0]?.SearchLat,
          long: location?.state?.push[0]?.SearchLng,
          gender: resvisedGender ? resvisedGender : "",
        };
      } else if (location?.state?.push[0]?.DoctorName) {
        payload = {
          // serviceProviderTypeId: location?.state?.push[0]?.serviceProviderId ? location?.state?.push[0]?.serviceProviderId : "",
          search: location?.state?.push[0]?.DoctorName ? location?.state?.push[0]?.DoctorName : "",
          lat: location?.state?.push[0]?.SearchLat,
          long: location?.state?.push[0]?.SearchLng,
        };
      } else {
        payload = {
          // serviceProviderTypeId: location?.state?.push[0]?.serviceProviderId ? location?.state?.push[0]?.serviceProviderId : "",
          // search: location?.state?.push[0]?.DoctorName ? location?.state?.push[0]?.DoctorName : "",
          lat: location?.state?.push[0]?.SearchLat,
          long: location?.state?.push[0]?.SearchLng,
        };
      }
      getSearchDoclist(payload);
    }
  }, [location, FilteredToggle, resvisedId, resvisedGender]);
  useEffect(() => {
    console.log('sessionStorage.getItem("userType")',sessionStorage.getItem("userType"));
    if (getSessionStorageValue("userType") === "1") {
      console.log("Coming here");
      history.push(ROUTES_CONSTANTS.DASHBOARD);
    }
    if (getSessionStorageValue("userType") === "2") {
      console.log("Coming here");
      history.push(ROUTES_CONSTANTS.PATIENT_DASHBOARD);
    }
  }, [])
  
  useEffect(() => {
    if (revisedAlldataToggle) {
      const payload = {
        search: location?.state?.push[0]?.DoctorName ? location?.state?.push[0]?.DoctorName : "",
        lat: location?.state?.push[0]?.SearchLat,
        long: location?.state?.push[0]?.SearchLng,
      };
      getSearchDoclist(payload);
    }
  }, [revisedAlldataToggle]);

  //   const getSearchDoclist = async (payload) => dispatch(commonAction.getSearchDoclistAction(payload));
  //   const getSearchDocdata = useSelector((state) => state?.authentication?.patientSubscriptionList);
  //   console.log("getSearchDocdata", getSearchDocdata);
  const getSearchDoclist = async (payload) => {
    await axios.post(`${process.env.REACT_APP_GYM_DOCTOR_API_BASE_URL}patient/webHomeSearch`, payload).then((res) => {
      // dispatch(commonAction.showLoader());
      console.log(res);
      if (res?.status === 200) {
        // dispatch(commonAction.hideLoader());
        let result = res?.data?.userData;
        setSearchDocLits(result);
        // setFilteredToggle(false);
      }
    });
  };

  useEffect(() => {
    getServiceProviderType();
  }, []);

  const getServiceProviderType = async () => {
    await axios.get(`${process.env.REACT_APP_GYM_DOCTOR_API_BASE_URL}patient/serviceProviderTypeList`).then((res) => {
      // dispatch(commonAction.showLoader());
      console.log("typeresult", res);
      if (res?.status === 200) {
        setServiceProviderType(res?.data?.documentList);
      }
    });
  };

  console.log("SearchDocLits", SearchDocLits);

  const handleViewAndBook = (id) => {
    setSignInModal(!SignInModal);
    console.log(id, "idddddddddddddddd");
    setDocID(id);
    sessionStorage.removeItem("termCondi");
    saveToSessionStorage("isLoggedIn", "true");
    saveToSessionStorage("userType", "2");
    // setFindDocToggle(true);
  };

  const handleGender = (e) => {
    let value = e.target.value;
    if (value === "Male") {
      setGenderValue(value);
    } else if (value === "Female") {
      setGenderValue(value);
    } else if (value === "All") {
      setGenderValue(value);
    }
  };

  const handleSearch = () => {
    setresvisedGender(GenderValue);
    setresvisedId(ServiceProviderId);
    setrevisedAlldataToggle(AlldataToggle);
    setFilteredToggle(true);
  };

  const handleServiceProviderId = (Id) => {
    setServiceProviderId(Id);
  };
  const handleAll = () => {
    setAlldataToggle(true);
  };

  const handleSignUpToggle = () => {
    setSignUpModal(!SignUpModal);
    setSignInModal(false);
  };
  const handleSignInToggle = () => {
    setSignInModal(!SignInModal);
    setSignUpModal(false);
  };
  const handleLoginWithMobiToggle = () => {
    setLoginWithNumModal(!LoginWithNumModal);
    setSignInModal(false);
  };
  const handleForgetPasswordToggle = () => {
    console.log("CLICKED");
    setLoginWithNumModal(false);
    setForgetPwdModal(!ForgetPwdModal);
    setSignInModal(false);
  };
  console.log("ServiceProviderId", ServiceProviderId);

  return (
    <>
      <div class="col-md-12 col-lg-4 col-xl-3 theiaStickySidebar">
        <Sticky boundaryElement=".theiaStickySidebar">
          {/* <!-- Search Filter --> */}
          <div class="card search-filter">
            <div class="card-header">
              <h4 class="card-title mb-0">Search Filter</h4>
            </div>
            <div class="card-body">
              {/* <div class="filter-widget">
                <div class="cal-icon">
                  <input type="text" class="form-control datetimepicker" placeholder="Select Date" />
                </div>
              </div> */}
              <div class="filter-widget">
                <h4>Gender</h4>
                <div>
                  <label class="custom_check">
                    <input type="radio" name="gender_type" value="Male" onChange={(e) => handleGender(e)} />
                    <span class="checkmark"></span> Male Doctor
                  </label>
                </div>
                <div>
                  <label class="custom_check">
                    <input type="radio" name="gender_type" value="Female" onChange={(e) => handleGender(e)} />
                    <span class="checkmark"></span> Female Doctor
                  </label>
                </div>
                <div>
                  <label class="custom_check">
                    <input type="radio" name="gender_type" value="All" defaultChecked onChange={(e) => handleGender(e)} />
                    <span class="checkmark"></span> All
                  </label>
                </div>
              </div>
              <div class="filter-widget">
                <h4>Select Specialist</h4>
                {ServiceProviderType?.length
                  ? ServiceProviderType.map((item) => (
                      <div>
                        <label class="custom_check">
                          <input type="radio" name="select_specialist" onChange={() => handleServiceProviderId(item?._id)} />
                          <span class="checkmark"></span> {item?.primary_specialty}
                        </label>
                      </div>
                    ))
                  : ""}
                <div>
                  <label class="custom_check">
                    <input type="radio" name="select_specialist" defaultChecked onChange={() => handleAll(true)} />
                    <span class="checkmark"></span> All
                  </label>
                </div>
              </div>
              <div class="btn-search">
                <button type="button" class="btn btn-block" onClick={handleSearch}>
                  Search
                </button>
              </div>
            </div>
          </div>
          {/* <!-- /Search Filter --> */}
        </Sticky>
      </div>

      <div class="col-md-12 col-lg-8 col-xl-9">
        {/* <!-- Doctor Widget --> */}

        {SearchDocLits?.length ? (
          SearchDocLits.map((item) => (
            <div class="card">
              <div class="card-body">
                <div class="doctor-widget">
                  <div class="doc-info-left">
                    <div class="doctor-img">
                      <a>
                        <img src={item?.profilePic} class="img-fluid" alt="User Image" />
                      </a>
                    </div>
                    <div class="doc-info-cont">
                      <h4 class="doc-name">
                        <a>Dr. {item?.fullName}</a>
                      </h4>
                      <p class="doc-speciality">{item?.serviceProviderType[0]?.primary_specialty}</p>
                      <h5 class="doc-department">
                        <img src={DocImg} class="img-fluid" alt="Speciality" />
                        {item?.specialties ? item?.specialties.map((item) => `${item?.secondry_specialty},`) : ""}
                      </h5>
                      <ReactStars edit={false} count={5} value={item?.rating} size={16} style={{ selfAlign: "center" }} color2={"#ffd700"} />
                      <div class="clinic-details">
                        <p class="doc-location">
                          <i class="fas fa-map-marker-alt"></i> {item?.location?.address}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="doc-info-right">
                    <div class="clini-infos">
                      <ul>
                        <li>
                          <i class="fas fa-map-marker-alt"></i>
                          {item?.location?.address}
                        </li>
                        <li>
                          <i class="far fa-money-bill-alt"></i> ${item?.serviceCharge}{" "}
                        </li>
                      </ul>
                    </div>
                    <div class="clinic-booking">
                      <a class="view-pro-btn" onClick={() => handleViewAndBook(item?._id)}>
                        View Profile
                      </a>
                      <a class="apt-btn" onClick={() => handleViewAndBook(item?._id)}>
                        Book Appointment
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-md-12">
            <p className="text-center">There are currently no doctors available.</p>
          </div>
        )}
        {/* <!-- /Doctor Widget --> */}

        {/* <div class="load-more text-center">
          <a class="btn btn-primary btn-sm" href="javascript:void(0);">
            Load More
          </a>
        </div> */}
      </div>
      <Login 
      SignInModal={SignInModal} 
      handleSignUpToggle={handleSignUpToggle} 
      setSignInModal={setSignInModal} 
      handleForgetPasswordToggle={handleForgetPasswordToggle}
      DocID={DocID} />
      <SignUp 
      SignUpModal={SignUpModal} 
      setSignUpModal={handleSignUpToggle} 
      SignInModal={SignInModal} 
      handleSignInToggle={handleSignInToggle}
      />

      <ForgotPassword 
      ForgetPwdModal={ForgetPwdModal} 
      setForgetPwdModal={setForgetPwdModal} 
      setSignInModal={setSignInModal} />
        
      <LoginWithNumber
      LoginWithNumModal={LoginWithNumModal}
      setLoginWithNumModal={setLoginWithNumModal}
      handleLoginWithMobiToggle={handleLoginWithMobiToggle}
      handleForgetPasswordToggle={handleForgetPasswordToggle}
      setSignInModal={setSignInModal}
    />
      

    </>
  );
}

export default HomeSearch;
