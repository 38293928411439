import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { ROUTES_CONSTANTS } from '../../constants';
import { doctor, staticPageAction } from '../../redux/action';

const CommonPage = () => {
  const dispatch = useDispatch()
  const [payload, setPayload] = useState('')
  let url = useLocation()

  console.log(url);

  useEffect(() => {
    if (url.pathname === `${ROUTES_CONSTANTS.HOW_IT_WORKS}`) {
      setPayload('HOW_IT_WORKS')
    } else if (url.pathname === `${ROUTES_CONSTANTS.WHAT_WE_TREAT}`) {
      setPayload('WHAT_WE_TREAT')
    } else if (url.pathname === `${ROUTES_CONSTANTS.METABOLIC_CENTERS}`) {
      setPayload('METABOLIC_CENTER')
    } else if (url.pathname === `${ROUTES_CONSTANTS.PARTICIPATING_CENTERS}`) {
      setPayload('PARTICIPATING_CENTER')
    } else if (url.pathname === `${ROUTES_CONSTANTS.REFER_CENTER}`) {
      setPayload('REFER_CENTER')
    } else if (url.pathname === `${ROUTES_CONSTANTS.BECOME_PARTICIPATING_CENTERS}`) {
      setPayload('BECOME_PARTICIPATING_CENTER')
    } else if (url.pathname === `${ROUTES_CONSTANTS.ONLINE_HEALTHBLOG}`) {
      setPayload('HEALTH_BLOG')
    } else if (url.pathname === `${ROUTES_CONSTANTS.OUR_MISSION}`) {
      setPayload('OUR_MISSION')
    }

  }, [url.pathname])
  console.log(url.pathname);
  useEffect(() => {
    if (payload) {
      staticData(payload)
    }
  }, [payload])

  const staticData = async (payload) => dispatch(doctor.getStaticPageAction(payload))

  const cmsData = useSelector(state => state?.doctor?.staticPageData?.data)
  console.log("data ===>", cmsData);
  const createMarkup = () => {
    if (cmsData) {
      return { __html: cmsData && cmsData.description && cmsData.description };
    }
  }
  return (
    <div className=" col-lg-12 col-xl-12 content_ipad_p">
      <div className="row">
        <div className="col-sm-12 ">
          <div className="about-gym-doc">
            <div className="container">
              <div className="card-body">
                <h1 className='clr-white font-size-main pad-left-10'>{cmsData && cmsData.title}</h1>
                {/* <p className='clr-white'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsum velit vitae, ducimus labore iusto rerum! Explicabo, illo quae suscipit incidunt soluta dolore odit tempore, reiciendis possimus temporibus corrupti unde voluptatum!</p> */}
              </div>
            </div>
          </div>
          <div className="container spacing-top">
            <div className="card-body">
              {cmsData ?
                <div className="cms_box" dangerouslySetInnerHTML={createMarkup()} /> : <p className='text-center'>Data not found!</p>}

            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CommonPage
