/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import defaultImg from "../../../assets/img/default-img.png";
import { useHistory } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../../constants";
import CancelAppointment from "../../common/CancelModal/CancelModal";
import moment from "moment";
import { saveToSessionStorage } from "../../../utils";
import { useDispatch, useSelector } from "react-redux";
import { doctor,commonAction } from "../../../redux/action";
import ToasterFunc from "../../common/toaster/Toaster";
import { IsCallerDetails } from "../../../getDeviceToken";
import { getSessionStorageOrDefault } from "../../../utils";

function AboutViewAppointment({ UpcomingAppointsList, About, PreviousAppToggole , roomId }) {
  const [CancelModal, setCancelModal] = useState(false);
  let history = useHistory();
  let dispatch = useDispatch();
  let today = new Date();
  const year = today.getFullYear();
  const getRemainingMinute = async () => dispatch(commonAction.getRemainingMinute());
  const handleReschudule = () => {
    history.push(`${ROUTES_CONSTANTS.DOCTOR_SIDE_RESCHEDULE_APPOINTMENT}/${UpcomingAppointsList._id}`);
  };
  

  const handleCancel = () => {
    setCancelModal(!CancelModal);
  };

  const handleCall = async(data) => {
    // checkingMinutes(data)
    if (getSessionStorageOrDefault("userType") === "1") {
          const getRemainingMinuteResponse = await getRemainingMinute();
          if (getRemainingMinuteResponse?.status === 200) {
            let result = getRemainingMinuteResponse?.data?.result;
            console.log("caling minutes result",result);
            let remainingVideoMin = result?.minutesOfVideoCalls ? result?.minutesOfVideoCalls : 0 ; 
            let remainAudioMin  = result?.minutesOfAudioCalls ? result?.minutesOfAudioCalls  :  0 ;
            if (data === "video") {
              if(remainingVideoMin===0){
                ToasterFunc("error", `You have left ${remainingVideoMin} video minute, please upgrade Subscription.`);
                return false
              }else{
                  history.push(`${ROUTES_CONSTANTS.VIDEO_CALL}/${UpcomingAppointsList?._id}/${UpcomingAppointsList?.patientId?._id}/${data}/outgoing` , {'path' : "WFCM"});
                  IsCallerDetails.next(true);
                  saveToSessionStorage(
                    "callerDetails",
                    JSON.stringify({
                      fullName: `${UpcomingAppointsList?.patientId?.firstName} ${UpcomingAppointsList?.patientId?.lastName}`,
                      profilePic: UpcomingAppointsList?.patientId?.profilePic,
                    })
                  );
              }
            }
            else if (data === "audio") {
              if(remainAudioMin===0){
                ToasterFunc("error", `You have left ${remainAudioMin} audio min, please upgrade Subscription`);
                 return false
              }else{
                history.push(`${ROUTES_CONSTANTS.VIDEO_CALL}/${UpcomingAppointsList?._id}/${UpcomingAppointsList?.patientId?._id}/${data}/outgoing` , {'path' : "WFCM"});
                IsCallerDetails.next(true);
                saveToSessionStorage(
                  "callerDetails",
                  JSON.stringify({
                    fullName: `${UpcomingAppointsList?.patientId?.firstName} ${UpcomingAppointsList?.patientId?.lastName}`,
                    profilePic: UpcomingAppointsList?.patientId?.profilePic,
                  })
                );
              }
            }
          }
        }
    console.log(data, UpcomingAppointsList);
    // if (data === "video") {
    //   history.push(`${ROUTES_CONSTANTS.VIDEO_CALL}/${UpcomingAppointsList?._id}/${UpcomingAppointsList?.patientId?._id}/${data}/outgoing` , {'path' : "WFCM"});
    //   IsCallerDetails.next(true);
    //   saveToSessionStorage(
    //     "callerDetails",
    //     JSON.stringify({
    //       fullName: `${UpcomingAppointsList?.patientId?.firstName} ${UpcomingAppointsList?.patientId?.lastName}`,
    //       profilePic: UpcomingAppointsList?.patientId?.profilePic,
    //     })
    //   );
    // } else if (data === "audio") {
    //   history.push(`${ROUTES_CONSTANTS.VIDEO_CALL}/${UpcomingAppointsList?._id}/${UpcomingAppointsList?.patientId?._id}/${data}/outgoing` , {'path' : "WFCM"});
    //   IsCallerDetails.next(true);
    //   saveToSessionStorage(
    //     "callerDetails",
    //     JSON.stringify({
    //       fullName: `${UpcomingAppointsList?.patientId?.firstName} ${UpcomingAppointsList?.patientId?.lastName}`,
    //       profilePic: UpcomingAppointsList?.patientId?.profilePic,
    //     })
    //   );
    // }
  };

  const handleToInbox = (patId) => {
    console.log("DoctorData", DoctorData);
    const idData = {
      senderid: DoctorData?.result[0]?._id,
      receiverid: patId,
      roomId : roomId , 
      userType: "Doctor",
      path: "Through_appointmentdetails",
      doctorData: DoctorData?.result[0],
      patientData : UpcomingAppointsList ? UpcomingAppointsList?.patientId : null
    };
    history.push(ROUTES_CONSTANTS.INBOX, idData);
    // ROUTES_CONSTANTS.INBOX
  };

  useEffect(() => {
    DoctorProfileInfo();
  }, []);

  const DoctorData = useSelector(
    // (state) => state?.patient?.patientProdileInfo
    (state) => state?.doctor?.doctorProdileInfo
  );
  console.log("DoctorData", DoctorData);

  const DoctorProfileInfo = async () => dispatch(doctor.doctorProfileDetailsAction());

  return (
    <>
      <div className="tab-pane fade show active" id="per_details_tab">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="appointment-list">
                  <div className="profile-info-widget">
                    <a className="booking-doc-img">
                      <img src={UpcomingAppointsList && UpcomingAppointsList.patientId && UpcomingAppointsList.patientId.profilePic ? UpcomingAppointsList.patientId.profilePic : defaultImg} alt="User Image" />
                    </a>
                    <div className="profile-det-info">
                      <div className="profile-det-info">
                        <h3>
                          <a>{`${UpcomingAppointsList && UpcomingAppointsList.patientId && UpcomingAppointsList.patientId.firstName ? UpcomingAppointsList.patientId.firstName : ""}
                        ${UpcomingAppointsList && UpcomingAppointsList.patientId && UpcomingAppointsList.patientId.lastName ? UpcomingAppointsList.patientId.lastName : ""}`}</a>
                        </h3>
                        <div className="patient-details">
                          <h5>
                            <b>Email :</b> {UpcomingAppointsList && UpcomingAppointsList.patientId && UpcomingAppointsList.patientId.email ? UpcomingAppointsList.patientId.email : "--"}
                          </h5>
                          <h5>
                            <b>Phone Number:</b>
                            {UpcomingAppointsList && UpcomingAppointsList.patientId && UpcomingAppointsList.patientId.mobileNumber ? UpcomingAppointsList.patientId.mobileNumber : "--"}
                          </h5>
                          <h5>
                            <b>Age Group:</b>{" "}
                            {UpcomingAppointsList && UpcomingAppointsList.patientId && UpcomingAppointsList.patientId.date_of_birth
                              ? year - (UpcomingAppointsList.patientId.date_of_birth ? UpcomingAppointsList.patientId.date_of_birth.split("/")[2] : " ")
                              : "--"}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                {UpcomingAppointsList && UpcomingAppointsList?.status !== 'cancelled' && <div className="appointment-action">
                    <a onClick={() => handleToInbox(UpcomingAppointsList?.patientId?._id)} className="btn btn-sm bg-info-light">
                      Send Message
                    </a>
                  </div>}
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <span>
                      <b>Appointments Details</b>
                    </span>
                    <p className="color-grey">
                      Date:
                      {UpcomingAppointsList && UpcomingAppointsList.appointmentStartTime ? new Date(UpcomingAppointsList.appointmentStartTime).toLocaleDateString() : "--"}
                      <br />
                      Time: {moment(UpcomingAppointsList && UpcomingAppointsList.appointmentStartTime).format("LT")} - {moment(UpcomingAppointsList && UpcomingAppointsList.appointmentEndTime).format("LT")}
                    </p>
                  </div>
                  <div className="col-md-12">
                    <span>
                      <b>Reason For Appointment</b>
                    </span>
                    <p className="color-grey">{UpcomingAppointsList && UpcomingAppointsList.reason ? UpcomingAppointsList.reason : "--"}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {UpcomingAppointsList && (UpcomingAppointsList.status === "cancelled" || UpcomingAppointsList.newStatus === "completed" || PreviousAppToggole === "PreVApp") ? (
          ""
        ) : (
          <div className="row">
            <div className="col-md-6 col-lg-3 col-sm-6 col-6 mb-2" onClick={handleReschudule}>
              <a className="btn book-btn">Reschedule</a>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-6 col-6 mb-2">
              <div onClick={() => handleCall("video")} className="btn book-btn">
                Video
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-6 col-6 mb-2">
              <div onClick={() => handleCall("audio")} className="btn book-btn">
                Audio
              </div>
            </div>
            <div className="col-md-6 col-lg-3 col-sm-6 col-6" onClick={handleCancel}>
              <a className="btn view-btn">Cancel</a>
            </div>
          </div>
        )}
      </div>
      <CancelAppointment UpcomingAppointsList={UpcomingAppointsList} CancelModal={CancelModal} setCancelModal={setCancelModal} />
    </>
  );
}

export default AboutViewAppointment;
