import { commonAction } from ".";
import commonService from "../../../services/commonService/commonService";
import { saveToSessionStorage } from "../../../utils";
import commonActionType from "./commonActionType";

function showLoader() {
  return {
    type: commonActionType.SHOW_LOADER,
    loadingIndicator: { isLoading: true },
  };
}

function hideLoader() {
  return {
    type: commonActionType.HIDE_LOADER,
    loadingIndicator: { isLoading: false },
  };
}

export const commonProfilePicAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await commonService.commonProfilePic(payload);
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const commonUploadedFileAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
    
      const response = await commonService.commonUploadedFile(payload);
      dispatch(commonAction.hideLoader());
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};
export const createRoomApi = (payload , type ) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
    
      const response = await commonService.createRoomApi(payload ,type);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const setPrimaryCardAction = (cardId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      dispatch(commonAction.showLoader());
      const response = await commonService.setPrimaryCard(cardId);
      dispatch(commonAction.hideLoader());
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const getTwilioTokenAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await commonService.getTwilioToken(payload);
      // saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      resolve(response);
    } catch (error) {
      reject(error.response);
    }
  });
};

export const disconnectCall = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    try {
      const response = await commonService.disconnectCall(payload);
      console.log('response',response);
      resolve(response);
    } catch (error) {
      console.log('error',error);
      reject(error.response);
    }
  });
};

export const getRemainingMinute = ()=> (dispatch) =>{
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await commonService.getRemainingMinute();

      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
}

export const updateRemainingMinute = (payload)=> (dispatch) =>{
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await commonService.updateRemainingMinute(payload);

      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
}


export const getRefferalCodeAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await commonService.getRefferalCode();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: commonActionType.GET_REFFERAL_CODE,
          refferalCodeInfo: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const getRefferalReportListAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await commonService.getRefferalReportList(payload.pageNumber, payload.limit);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: commonActionType.GET_REFFERAL_REPORT_LIST,
          refferalReportListInfo: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const getSearchDoclistAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await commonService.getSearchDoclist(payload);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: commonActionType.SEARCHED_DOC_LIST,
          getSearchDocdata: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export default {
  showLoader,
  hideLoader,
  commonProfilePicAction,
  commonUploadedFileAction,
  setPrimaryCardAction,
  getTwilioTokenAction,
  getRefferalCodeAction,
  getRefferalReportListAction,
  disconnectCall,
  getSearchDoclistAction,
  getRemainingMinute , 
  updateRemainingMinute,
  createRoomApi
};
