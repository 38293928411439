import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import { useHistory } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../../constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { authentication, doctor, patient } from "../../../redux/action";
import ToasterFunc from "../../common/toaster/Toaster";
import UpgradeBill from "./UpgradeBill";
import UpgradePaymentDetail from "./UpgradePaymentDetail";
import ConfirmationModal from "./ConfirmationModal";
import { getSessionStorageOrDefault } from "../../../utils";
import stripeService from "../../../services/stripeService/stripeService";

function UpgradePayment({ setPaymentModal, PaymentModal, isOpenDocSide, SubscriptionPrice, SubscriptionId, Image, DataSub, PatUpgradeSub, callFrom }) {
  let dispatch = useDispatch();
  let history = useHistory();
  const cardListInPat = async () => dispatch(patient.cardListInPatAction());

  const cardListData = async (DataLimit, selectPage) => dispatch(doctor.docCardListAction(DataLimit, selectPage));

  const [ConfirmModal, setConfirmModal] = useState(false);
  const [couponId, setCouponId] = useState(null);
  const [CardErr, setCardErr] = useState("");
  const [priceValue, setPriceValue] = useState("");
  const [CardType, setCardType] = useState({
    credit: true,
    debit: false,
  });
  const [bothCardDetails, setCardDetails] = useState({
    credit: {},
    debit: {},
  });
  const [UpgradeConfirmToggle, setUpgradeConfirmToggle] = useState(false);
  const [showBill, setShowBill] = useState(false);
  const [isClicked, setIsClicked] = useState(false);
  const [isSaved, setisSaved] = useState(false);
  const [CardnumErr, setCardnumErr] = useState("");
  const handleClose = () => {
    setPaymentModal(false);
    setShowBill(false);
  };

  const paymentAccountPatientUpgrade = async (payload) => dispatch(authentication.paymentAccountPatientUpgradeAction(payload));

  const paymentAccountDoctorUpgrade = async (payload) => dispatch(authentication.paymentAccountDoctorUpgradeAction(payload));
  const couponList = async () => dispatch(authentication.couponListAction());

  useEffect(() => {
    couponList();
    if (getSessionStorageOrDefault("UserType") == "1") cardListData(100, 1);
    else cardListInPat();
  }, []);

  const formik = useFormik({
    initialValues: {
      fullname: "",
      CardNum: "",
      cvv: "",
      // cardexpiry: "",
    },
    validationSchema: Yup.object({
      fullname: Yup.string()
        .required("*Required.")
        .matches(/^[^-\s][\w\s-]+$/, "Please Enter required value"),
      CardNum: Yup.number().required("*Required.").typeError("*Please enter only digits"),
      cvv: Yup.number().required("*Required.").typeError("*Please enter only digits"),
      cardexpiry: Yup.string().required("*Required.").typeError("*Please enter only digits"),
    }),
  });

  useEffect(() => {
    if (CardType.credit === true || CardType.debit === true) {
      setCardErr("");
    }
    if (formik.values.CardNum.replaceAll(" ", "").length === 16) {
      setCardnumErr("");
    }
  }, [CardType, formik.values.CardNum]);

  const handlePayment = async () => {
    setUpgradeConfirmToggle(true);
    console.log("PRICEEE", priceValue);
    const paymentPayload = {
      cardHolderName: formik.values.fullname,
      subscriptionId: SubscriptionId,
      cardType: CardType.credit ? "CREDIT" : "DEBIT",
      cardNumber: formik.values.CardNum.replaceAll(" ", ""),
      exp_month: formik.values.cardexpiry.split("/")[0],
      exp_year: formik.values.cardexpiry.split("/")[1],
      cvc: formik.values.cvv,
      price: Number(priceValue) ? Number(priceValue) : SubscriptionPrice ? SubscriptionPrice : "",
      cardSave: isSaved ? true : false,
    };

    if (couponId) {
      paymentPayload.couponId = couponId?._id;
    }
    handlePaymentApi(paymentPayload);
  };

  const handleSelectedCardPayment = async (selectedCard, cvvValue) => {
    setUpgradeConfirmToggle(true);
    console.log("PRICEEE", priceValue);
    const paymentPayload = {
      cardHolderName: selectedCard.cardHolderName,
      subscriptionId: SubscriptionId,
      cardType: selectedCard.cardType,
      cardNumber: selectedCard.cardNumber,
      exp_month: selectedCard.expMonth,
      exp_year: selectedCard.expYear,
      cvc: cvvValue,
      price: Number(priceValue) ? Number(priceValue) : SubscriptionPrice ? SubscriptionPrice : "",
      cardSave: isSaved ? true : false,
    };

    if (couponId) {
      paymentPayload.couponId = couponId?._id;
    }
    const userType = await getSessionStorageOrDefault("userType");
    let res = null;
    if (DataSub && userType == "1") {
      res = await paymentAccountDoctorUpgrade(paymentPayload);
    } else if (PatUpgradeSub && userType == "2") {
      console.log("Coming here");
      res = await paymentAccountPatientUpgrade(paymentPayload);
    }
    if (res && res.status == 200) {
      console.log("paymenttttttttt", res);
      if (userType == "1") {
        ToasterFunc("success", "Subcription upgraded succesfully");
        setPaymentModal(false);
        setConfirmModal(true);
      }
      if (userType == "2") {
        ToasterFunc("success", "Subcription upgraded succesfully");
        setPaymentModal(false);
        setConfirmModal(true);
      }
    }
  };

  const handlePaymentApi = async (paymentPayload) => {
    console.log(CardType.credit, CardType.debit);
    if (CardType.credit !== false || CardType.debit !== false) {
      if (formik.values.CardNum !== "" && formik.values.CardNum.replaceAll(" ", "").length === 16 && formik.values.fullname !== "" && formik.values.cardexpiry !== "" && formik.values.cvv !== "") {
        const userType = await getSessionStorageOrDefault("userType");
        let res = null;
        if (DataSub && userType == "1") {
          res = await paymentAccountDoctorUpgrade(paymentPayload);
        } else if (PatUpgradeSub && userType == "2") {
          console.log("Coming here");
          res = await paymentAccountPatientUpgrade(paymentPayload);
        }
        if (res && res.status == 200) {
          console.log("paymenttttttttt", res);
          if (userType == "1") {
            ToasterFunc("success", "Subcription upgraded succesfully");
            setPaymentModal(false);
            setConfirmModal(true);
          }
          if (userType == "2") {
            ToasterFunc("success", "Subcription upgraded succesfully");
            setPaymentModal(false);
            setConfirmModal(true);
          }
        }
      }
    }
  };
  const handleConfirm = async (e) => {
    console.log("PRICE", e);
    if (CardType.credit === false && CardType.debit === false) {
      setCardErr("*Please Choose any one");
    }
    if (CardType.credit === true || CardType.debit === true) {
      setCardErr("");
    }
    if (formik.values.CardNum !== "" && formik.values.CardNum.replaceAll(" ", "").length < 16) {
      setCardnumErr("*Invalid Card Number");
    }
    if ((CardType.credit == true || CardType.debit == true) && formik.values.CardNum !== "" && formik.values.CardNum.replaceAll(" ", "").length === 16 && formik.values.fullname !== "" && formik.values.cardexpiry !== "" && formik.values.cvv !== "") {
      handlePayment();
    }
  };

  const handleCard = async (e) => {
    let name = e.target.value;
    let value = e.target.checked;
    if (name === "Credit") {
      setCardType({
        credit: value,
      });
      setCardDetails({
        ...bothCardDetails,
        debit: {
          CardNum: formik.values.CardNum.replaceAll(" ", ""),
          fullname: formik.values.fullname,
          cardexpiry: formik.values.cardexpiry,
          cvv: formik.values.cvv,
        },
      });
      formik.setFieldValue("CardNum", bothCardDetails?.credit?.CardNum.replaceAll(" ", "") || "");

      formik.setFieldValue("fullname", bothCardDetails?.credit?.fullname || "");

      formik.setFieldValue("cardexpiry", bothCardDetails?.credit?.cardexpiry || "");

      formik.setFieldValue("cvv", bothCardDetails?.credit?.cvv || "");
    } else if (name === "Debit") {
      setCardType({
        debit: value,
      });
      setCardDetails({
        ...bothCardDetails,
        credit: {
          CardNum: formik.values.CardNum?.replaceAll(" ", ""),
          fullname: formik.values.fullname,
          cardexpiry: formik.values.cardexpiry,
          cvv: formik.values.cvv,
        },
      });
      formik.setFieldValue("CardNum", bothCardDetails?.debit?.CardNum ? bothCardDetails?.debit?.CardNum?.replaceAll(" ", "") : "");
      formik.setFieldValue("fullname", bothCardDetails?.debit?.fullname ? bothCardDetails?.debit?.fullname : "");
      formik.setFieldValue("cardexpiry", bothCardDetails?.debit?.cardexpiry ? bothCardDetails?.debit?.cardexpiry : "");
      formik.setFieldValue("cvv", bothCardDetails?.debit?.cvv ? bothCardDetails?.debit?.cvv : "");
    }
    if (name === "SaveForLater") {
      setisSaved(true);
    }
  };

  const createSession = async (val) => {
    // handlePayment(val)
    console.log({ val })
    // note//
    let paymentData = {
      subscriptionId: SubscriptionId,
      price: Number(val),
      sessionType: 'UPGRADE_SUBSCRIPTION',
      callFrom: callFrom ? callFrom : ''
      // subscriptionId : SubscriptionType
    }
    if (paymentData) {
      paymentData.couponId = couponId?._id;
    }

    // create checkout session return url
    let res = await stripeService.createSessionCheckout(paymentData)
    if (res && res.status == 200) {
      if (res.data && res.data.url) {
        window.open(res.data?.url, "_self");
      } else {
        ToasterFunc("info", res.data?.message);
      }
    } else {
      ToasterFunc("error", res.err);
    }
  };

  return (
    <>
      <Modal isOpen={PaymentModal} className=" modal-dialog-centered">
        <div className="modal-content">
          <button type="button" className="close custom-model-close" aria-label="Close" data-dismiss="modal" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </button>
          {showBill ? (
            // <UpgradePaymentDetail
            //   formik={formik}
            //   handleCard={handleCard}
            //   CardErr={CardErr}
            //   CardnumErr={CardnumErr}
            //   handleConfirm={handleConfirm}
            //   handleSelectedCardPayment={handleSelectedCardPayment}
            //   SubscriptionId={SubscriptionId}
            //   SubscriptionPrice={SubscriptionPrice}
            //   setPaymentModal={setPaymentModal}
            //   setConfirmModal={setConfirmModal}
            // />
            <>
              {
                createSession(priceValue)
              }
            </>
          ) : (
            <UpgradeBill
              Image={Image}
              SubscriptionId={SubscriptionId}
              couponId={couponId}
              setCouponId={setCouponId}
              SubscriptionPrice={SubscriptionPrice}
              isClicked={isClicked}
              handlePayment={(val) => {
                // setShowBill(true);
                setIsClicked(true)
                setPriceValue(val);
                createSession(val)
              }}
            />
          )}
        </div>
      </Modal>
      <ConfirmationModal setConfirmModal={setConfirmModal} ConfirmModal={ConfirmModal} UpgradeConfirmToggle={UpgradeConfirmToggle} DataSub={DataSub} />
    </>
  );
}

export default UpgradePayment;
