import { bookingActionType } from "../../action";
import initialState from "../initialState";

export default function bookingReducer(state = initialState.common, action) {
  switch (action.type) {
    case bookingActionType.VIEW_DOCTOR_DETAILS:
      return { ...state, doctorDetails: action.doctorDetails };
    case bookingActionType.VIEW_DOCTOR_DETAILS_FOR_SHOWING:
      return { ...state, doctorDetailsforShowing: action.doctorDetailsforShowing };
    case bookingActionType.GET_SLOT:
      return { ...state, slotList: action.slotList };
    case bookingActionType.GET_SLOT_FOR_RESCHEDULE:
      return { ...state, slotListForReschedule: action.slotListForReschedule };
    case bookingActionType.GET_SLOT_FOR_LABORATORY:
      return { ...state, slotListForLaboratory: action.slotListForLaboratory };
    default:
      return state;
  }
}
