import React from "react";
import { Modal } from "reactstrap";

function ViewMedicalRecords({
  ViewMedicalModal,
  setViewMedicalModal,
  slectedRecord,
}) {
  console.log('slectedRecord',slectedRecord)
  return (
    <Modal
      isOpen={ViewMedicalModal}
      className="modal-dialog modal-dialog-scrollable modal-dialog-centered"
    >
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">View Medical Records</h4>
          <button
            type="button"
            class="close"
            onClick={() => setViewMedicalModal(false)}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12 ">
                <div class="invoice-content">
                  <div class="row form-row">
                    <div class="col-12 col-md-12">
                      <div class="form-group">
                        <label>Name</label>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Dental Billing"
                          value={slectedRecord?.name}
                          readonly
                        />
                      </div>
                    </div>
                    <div class="col-12 col-md-12">
                      <div class="form-group">
                        <label for="description">Descriptions</label>
                        <textarea
                          id="description"
                          class="form-control"
                          rows="3"
                          placeholder="Type here"
                          value={slectedRecord?.description}
                          readonly
                        ></textarea>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    {slectedRecord?.medicalRecordFile && (
                      <div class="col-md-12 text-center">
                        <div class="signature flex-column">
                          <label for="files">
                            <i class="fa fa-file-pdf fa-2x"></i>
                            <a  href={slectedRecord?.medicalRecordFile} class="mb-0">Medical record pdf</a>
                          </label>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default ViewMedicalRecords;
