import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../constants";
import { patient } from "../../../redux/action";
import FilterModal from "../FilterModal";
import { useLocation } from "react-router-dom";
import ReactStars from "react-stars";
import MultipleSpeciality from "./MultipleSpeciality";
import InputRangeSlider from "../../common/InputRangeSlider/InputRangeSlider";
// import { Scrollbars } from "react-custom-scrollbars";

function FindDoctorsNearBy() {
  let { DocId } = useParams();
  let pathname = useLocation();
  const dispatch = useDispatch();
  const history = useHistory();
  const [FilterModals, setFilterModals] = useState(false);
  const [nearByDoctor, setNearByDoctor] = useState([]);
  const [ViewMultiSpe, setViewMultiSpe] = useState(false);
  const [PrimarySpeId, setPrimarySpeId] = useState("");
  const [DocFilterData, setDocFilterData] = useState([]);
  const [MultiSpeData, setMultiSpeData] = useState([]);

  const nearByPhysician = async (payload) => dispatch(patient.nearByPhysicialAction(payload));

  const nearByPhysicianList = useSelector((state) => state?.patient?.nearByPhysicianList);

  console.log("nearByDoctor", nearByDoctor);

  console.log("pathnameeeeeeeeee", pathname);

  useEffect(() => {
    if (nearByPhysicianList?.userData) {
      setNearByDoctor(nearByPhysicianList?.userData);
    }
  }, [nearByPhysicianList]);

  const handleFilter = () => {
    setFilterModals(!FilterModals);
  };

  useEffect(() => {
    getLocation();
  }, []);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(function (position) {
        callApi(position);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const callApi = (position) => {
    let payload = {};
    if (DocFilterData && DocFilterData.length) {
      if (sessionStorage.getItem("lat") && sessionStorage.getItem("lng")) {
        payload = {
          serviceProviderTypeId: DocId,
          lat: sessionStorage.getItem("lat"),
          long: sessionStorage.getItem("lng"),
        };
      } else if (pathname?.state?.push?.lng && pathname?.state?.push?.lat) {
        payload = {
          serviceProviderTypeId: DocId,
          lat: pathname?.state?.push?.lat,
          long: pathname?.state?.push?.lng,
        };
      } else {
        payload = {
          serviceProviderTypeId: DocId,
          lat: position.coords.latitude,
          long: position.coords.longitude,
        };
      }

      if (DocFilterData[0]?.ratingData) {
        payload.startRating = DocFilterData[0].ratingData ? DocFilterData[0].ratingData.min : "";
        payload.endRating = DocFilterData[0].ratingData ? DocFilterData[0].ratingData.max : "";
      }
      if (DocFilterData[0]?.costData) {
        payload.costFrom = DocFilterData[0].costData ? DocFilterData[0].costData.min : "";
        payload.costTo = DocFilterData[0].costData ? DocFilterData[0].costData.max : "";
      }
      if (DocFilterData[0]?.distanceData) {
        payload.distance = DocFilterData[0].distanceData ? DocFilterData[0].distanceData : "";
      }
      if (DocFilterData[0]?.secondarySpeId) {
        payload.specialtyId = DocFilterData[0]?.secondarySpeId;
      }
    } else {
      if (sessionStorage.getItem("lat") && sessionStorage.getItem("lng")) {
        payload = {
          serviceProviderTypeId: DocId,
          lat: sessionStorage.getItem("lat"),
          long: sessionStorage.getItem("lng"),
        };
      } else if (pathname?.state?.push?.lng && pathname?.state?.push?.lat) {
        payload = {
          serviceProviderTypeId: DocId,
          lat: pathname?.state?.push?.lat,
          long: pathname?.state?.push?.lng,
        };
      } else {
        payload = {
          serviceProviderTypeId: DocId,
          lat: position.coords.latitude,
          long: position.coords.longitude,
        };
      }
    }
    nearByPhysician(payload);
  };

  const handleSearch = (e) => {
    if (e.target.value === "") setNearByDoctor(nearByPhysicianList.userData);
    else setNearByDoctor(nearByPhysicianList.userData.filter((data) => data.fullName.toLowerCase().search(e.target.value.toLowerCase()) != -1));
  };

  const handleBookAppointment = (id) => {
    history.push(`${ROUTES_CONSTANTS.PATIENT_BOOK_APPOINTMENT}/${id}`);
  };

  useEffect(() => {
    getLocation();
  }, [DocFilterData]);

  console.log("DocFilterData", DocFilterData);

  const arrData = (data) => {
    if (data && data.length) {
      setDocFilterData([...data]);
    }
  };

  const handleDocNavigation = (item) => {
    history.push(`${ROUTES_CONSTANTS.PATIENT_VIEW_DOCTOR_DETAILS}/${item?._id}`);
  };

  console.log("nearByDoctor", nearByDoctor);

  return (
    <div class=" col-lg-8 col-xl-9 content_ipad_p">
      {/* <InputRangeSlider/> */}
      <div class="card">
        <div class="card-header">
          <h4>Find {pathname && pathname?.state?.push?.PrimarySpeId} </h4>
        </div>
        <div class="card-body ">
          <div class="input-social-media">
            <input type="text" class="form-control  mb-2" style={{ paddingLeft: "42px" }} onChange={handleSearch} />
            <span>
              <i class="fas fa-search appt-search-icon"></i>
            </span>
            <span onClick={handleFilter}>
              <i class="fas fa-filter appt-filter-icon pointer"></i>
            </span>
          </div>
          {/* <Scrollbars className="find-doctors-cutom-scroll" autoHide> */}
          <div class="card ">
            <div class="row">
              {nearByDoctor && nearByDoctor.length ? (
                nearByDoctor.map((data) => {
                  return (
                    <div class="col-md-6 col-lg-4">
                      <div class="card-body">
                        <div class="card widget-profile pat-widget-profile">
                          <div class="card-body li-listing-profile">
                            <div class="profile-info-widget" onClick={() => handleDocNavigation(data)}>
                              <a href class="booking-doc-img">
                                <img src={data.profilePic || IMAGE_CONSTANT.PLACEHOLDER} alt="" />
                              </a>
                            </div>
                            <div class="profile-det-info text-center" onClick={() => handleDocNavigation(data)}>
                              <h3>{`Dr. ${data.fullName}`}</h3>
                            </div>
                            <div class="patient-info color-grey ">
                              <div class="color-grey">
                                <b>{`$${data.serviceCharge} Consultation Fee`}</b>
                              </div>
                              <div class="color-gray">{`${data.experience_in_years} years ${data.experience_in_months} months Of Experience`}</div>
                              <div class="color-gray">
                                {data && data.specialties && data.specialties.length === 1 ? (
                                  data.specialties[0].secondry_specialty
                                ) : (
                                  <span
                                    onClick={() => {
                                      setViewMultiSpe(!ViewMultiSpe);
                                      setPrimarySpeId(data.primary_specialtyId);
                                      setMultiSpeData(data?.specialties);
                                    }}
                                  >
                                    {" "}
                                    Multi Speciality
                                  </span>
                                )}
                              </div>

                              <div className=" rating ">
                                <ReactStars edit={false} count={5} value={data?.rating} size={16} style={{ selfAlign: "center" }} color2={"#ffd700"} />
                              </div>
                            </div>
                            {/* <div class="row">
                              <div class="col-md-12 mb-2 mt-3">
                                <div
                                  onClick={() =>
                                    handleBookAppointment(data._id)
                                  }
                                  class="btn book-btn"
                                >
                                  Book Appointment
                                </div>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className="col-md-12">
                  <p className="text-center">Data Not Found</p>
                </div>
              )}
            </div>
          </div>
          {/* </Scrollbars> */}
        </div>
      </div>
      <MultipleSpeciality ViewMultiSpe={ViewMultiSpe} setViewMultiSpe={setViewMultiSpe} PrimarySpeId={PrimarySpeId} MultiSpeData={MultiSpeData} />
      <FilterModal DocFilterData={DocFilterData} FilterModals={FilterModals} setFilterModals={setFilterModals} nearByPhysicianList={nearByPhysicianList} arrData={arrData} getLocation={getLocation} />
    </div>
  );
}

export default FindDoctorsNearBy;
