import React, { useState, useEffect } from "react";
import PatViewMedical from "./PatViewMedical";
import PatViewPersonal from "./PatViewPersonal";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { patient } from "../../../redux/action";

function PatViewProfile() {
  let dispatch = useDispatch();
  const [isPersoanlOpen, setisPersoanlOpen] = useState(true);
  const [isMedicalOpen, setisMedicalOpen] = useState(false);
  const [ProfileData, setProfileData] = useState({});
  const handlePersonalDetail = () => {
    setisPersoanlOpen(true);
    setisMedicalOpen(false);
  };
  const handleMedicalDetail = () => {
    setisMedicalOpen(true);
    setisPersoanlOpen(false);
  };
  const PatientProfileInfo = async () =>
    dispatch(patient.patientProfileDetailsAction());
  useEffect(() => {
    window.scrollTo(0, 0);
    PatientProfileInfo();
  }, []);

  const PatientData = useSelector(
    (state) => state?.patient?.patientProdileInfo
    // (state)=>console.log(state)
  );
  useEffect(() => {
    setProfileData(PatientData ? PatientData : "");
  }, [PatientData]);

  console.log("PatientData", PatientData);

  return (
    <div className="col-lg-8 col-xl-9 content_ipad_p">
      <div className="card">
        <div className="card-body pt-0">
          <div className="row">
            <div className="col-md-12">
              <div className="profile-header p-3">
                <nav className="user-tabs">
                  <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                    <li className="nav-item" onClick={handlePersonalDetail}>
                      <a
                        className={
                          isPersoanlOpen
                            ? "nav-link active text-nowrap"
                            : "nav-link  text-nowrap"
                        }
                        data-toggle="tab"
                      >
                        Personal Details
                      </a>
                    </li>
                    <li className="nav-item" onClick={handleMedicalDetail}>
                      <a
                        className={
                          isMedicalOpen
                            ? "nav-link active text-nowrap"
                            : "nav-link  text-nowrap"
                        }
                        data-toggle="tab"
                      >
                        Medical Details
                      </a>
                    </li>
                  </ul>
                </nav>
                <div className="tab-content profile-tab-cont">
                  {isPersoanlOpen ? (
                    <PatViewPersonal ProfileData={ProfileData} />
                  ) : isMedicalOpen ? (
                    <PatViewMedical ProfileData={ProfileData} />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatViewProfile;
