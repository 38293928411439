import React, { useState, useEffect, useRef, useContext } from 'react'
import { IMAGE_CONSTANT } from '../../../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { commonAction, patient } from '../../../../../redux/action';
import { io } from "socket.io-client";
import moment from 'moment';
import { useLocation } from 'react-router-dom';
import { timeAgo } from '../../../../../utils';
import { SocketContext } from '../../../../..';
import ToasterFunc from '../../../../common/toaster/Toaster';
import pdfIcon from '../../../../../assets/img/pdfIcon.png';
import userIcon from "../../../../../assets/img/UserIcon.png"

// import socket from '../../../../../socket/socket';

function Chatting({RoomId}) {

    const socket = useContext(SocketContext);
    const dispatch = useDispatch();
    const chatContainer = useRef(null);

    const [ChatlistOfDoc, setChatlistOfDoc] = useState([]);
    const [chatHistoryList, setchatHistoryList] = useState([]);
    const [msgText, msgTextChange] = useState('');
    const PatientData = useSelector((state) => state?.patient?.patientProdileInfo);
    const [UserStatus, setUserStatus] = useState(false);
    const [IsMessageShow, setIsMessageShow] = useState(false);
    const [Isloader, setIsloader] = useState(false);
    const [fileUrl, setFileUrl] = useState('');
    const [FileData, setFileData] = useState({});

    const commonUploadedFile = async (payload) => dispatch(commonAction.commonUploadedFileAction(payload));
    const createRoomApi = async (payload) => dispatch(commonAction.createRoomApi(payload, "patient"));

    const location = useLocation();
    // console.log('location', location);

    let patientId = PatientData?.userData?._id;
    if (patientId) {
        localStorage.setItem('patientId', patientId);
    } else {
        patientId = localStorage.getItem('patientId');
    }
    const [ChatID, setChatID] = useState('');
    const [ReceiverProfile, setReceiverProfile] = useState(location.state?.patientData?.serviceProviderId
        ? location.state?.patientData?.serviceProviderId : {});

    const createRoomFun  = async ()=>{
        const payload  = {
              "receiverId" : location?.state?.receiverid ? location?.state?.receiverid : ReceiverProfile?._id
        } 
       const res  =  await createRoomApi(payload);
       if (res && res?.data?.roomID){
        let roomId  = res?.data?.roomID ;
        console.log("roomId", roomId )
    }
    console.log("Create room response", res )

    }
    // useEffect(() => {
    //     createRoomFun()
    // }, [])
    

    useEffect(() => {
        console.log('patientId', patientId);
        if (patientId) {
            getSocketConnection();
            getUserOnlineStatus();
            var interval = setInterval(() => {
                getChatList();
            }, 2000);
                
            getChatHistory();
            getChats()
        }

        return () => {
            clearInterval(interval)
            socket.on("disconnect", () => {
                console.log(socket.id);
                console.log("socket disconnected");
            });
        }
    }, [socket]);

    useEffect(() => {
        console.log('chatHistoryList', chatHistoryList);
        console.log('ReceiverProfile', ReceiverProfile);
        console.log('ChatlistOfDoc', ChatlistOfDoc);
        console.log("location?.state?.path ===>>",location?.state?.path);
        if (chatHistoryList.length) {
            scrollToMyRef()
        }
    }, [ChatlistOfDoc, chatHistoryList, ReceiverProfile])

    useEffect(() => {
        console.log("ChatID===>>",ChatID);
        if (ChatID) {
            setchatHistoryList([]);
            connectRoom();
            getChatDetails();
        }
    }, [ChatID])

    const getChatDetails = () => {
        const payload = {
            "chatId": ChatID
        }
        socket.emit('chatDetails', payload);
        getChatHistory();
    }

    const getChatHistory = () => {
        setFileUrl('');
        socket.on('msgReceived', (data) => {
            console.log('msgReceived data', data);
            if (data.length && typeof data === 'object') {
                let chatList = [...data];
                let reverseChat = chatList.reverse();
                setchatHistoryList(reverseChat);
                getChatList();
            } else {
                if (data && typeof data === 'string') {
                    setIsMessageShow(data);
                }
            }
        });
        if (IsMessageShow) {
            ToasterFunc('error', IsMessageShow);
        }

    }
    const scrollToMyRef = () => {
        const scroll =
            chatContainer.current?.scrollHeight -
            chatContainer.current?.clientHeight;
        chatContainer.current?.scrollTo(0, scroll);
    };

    const getSocketConnection = () => {
        // socket.connect();
        // client-side
        socket.on("connect", () => {
            const payload = {
                senderId: patientId
            };
            console.log('socket id', socket.id);
            console.log("socket connected", 'payload', payload); // x8WIv7-mJelg7on_ALbx
            socket.emit("connectUser", payload);
            console.log('socket id', socket.id);
        });
    };

    const connectRoom = () => {
        console.log('ChatID ====> ', ChatID);
        socket.emit('join_room', ChatID);
    }

    const getUserOnlineStatus = () => {
        socket.on("userOnlineStatus", (arg) => {
            console.log('userOnlineStatus', arg); // world
            if (arg?.status === 1) {
                setUserStatus(true);
            }
        });
    };

    const getChatList = () => {
        const payload = {
            senderId: patientId,
        };
        socket.emit("chatList", payload);
        getChats()
    };
    const getChats = ()=>{
        socket.on("chatLists", (data) => {
            //    console.log("chatLists", data);
            setChatlistOfDoc(data);
        });
    }

    const sendMessage = () => {
        if (msgText.trim()) {

            const payload = {
                senderId: patientId,
                receiverId: location?.state?.receiverid ? location?.state?.receiverid : ReceiverProfile?._id,
                type: 0,
                message: msgText,
                'room': ChatID
            };
            if (fileUrl) {
                payload['type'] = 1;
            }
            console.log('payload', payload);
            socket.emit("sendMessage", payload);
            if (!ChatlistOfDoc.length) {
                getChatList();
            }
            getChatHistory();
            msgTextChange('');
        }
    };

    const onkeyupFun = (event) => {
        if (event.key === "Enter") {
            sendMessage();
        }
    }

    // Function declare and define for remove selected file 
    const removeFile = () => {
        setFileUrl('');
        msgTextChange('');
    }

    const handleChange = (event) => {
        setFileUrl('');
        msgTextChange('');
        const file = event.target.files[0];
        console.log(file);
        let formData = new FormData();
        if (file) {
            const fileSize = file.size / 1024 / 1024;
            if (fileSize > 2) {
                ToasterFunc("error", "Please upload a file smaller than 2 MB");
            } else {
                setFileData(file);
                formData.append("file", file);
                fileUpload(formData);
            }
        }
    }
    const fileUpload = async (image) => {
        setIsloader(true);
        const res = await commonUploadedFile(image);
        if (res && res.status == 200) {
            const file = res?.data?.result?.url;
            setFileUrl(file);
            setIsloader(false);
            msgTextChange(file);
        }
    };

    useEffect(() => {
        console.log('patientId', patientId);
        if (patientId) {
            getSocketConnection();
            getUserOnlineStatus();
            var interval = setInterval(() => {
                getChatList();
            }, 2000);
            getChatHistory();
        }

        return () => {
            clearInterval(interval);
            socket.on("disconnect", () => {
                console.log(socket.id);
                console.log("socket disconnected");
            });
        }
    }, [socket]);

    return (
        <>
            {
                <div>
                    <div className="tab-content pt-0">
                        {/* <!-- chats Content --> */}
                        <div role="tabpanel" id="chats" className="tab-pane fade show active">
                            {/* <div className="content"> */}
                            {/* <div className="container-fluid"> */}
                            <div className="row">
                                <div className="col-xl-12">
                                    <div className="chat-window">

                                        {/* <!-- Chat Left --> */}
                                        <div className="chat-cont-left">

                                            <form className="chat-search">
                                                <div className="input-group">
                                                    {/* <div className="input-group-prepend">
                                                        <i className="fas fa-search"></i>
                                                    </div> */}
                                                    {/* <input type="text" className="form-control" placeholder="Search" /> */}
                                                </div>
                                            </form>
                                            <div className="chat-users-list">
                                                <div className="chat-scroll">

                                                    {ChatlistOfDoc?.length ? ChatlistOfDoc.map(item => {
                                                        return (
                                                            <a onClick={() => { setChatID(item?._id); setReceiverProfile(item?.lastmsgId?.receiverId) }} key={item?._id} className="media">
                                                                <div className="media-img-wrap">
                                                                    {/* className={UserStatus ? "avatar avatar-online" : "avatar avatar-away"} */}
                                                                    <div className={UserStatus ? "avatar" : "avatar"}>
                                                                        <img src={item?.lastmsgId?.receiverId?.profilePic ? item?.lastmsgId?.receiverId?.profilePic : userIcon}
                                                                            alt="" className="avatar-img rounded-circle mb-1" />
                                                                    </div>
                                                                </div>
                                                                <div className="media-body">
                                                                    <div>
                                                                        <div className="user-name">{item?.lastmsgId?.receiverId?.fullName}</div>
                                                                        <div className="user-last-chat">{item?.type === 0 ?
                                                                            item?.lastmsgId?.receiverId?.message : "File"}</div>
                                                                    </div>
                                                                    <div>
                                                                        <div className="last-chat-time block">{timeAgo(item.updatedAt)}</div>
                                                                        {/* <!-- <div className="badge badge-success badge-pill">15</div> --> */}
                                                                    </div>
                                                                </div>
                                                            </a>
                                                        )
                                                    }
                                                    ) : null

                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        {/* <!-- /Chat Left --> */}

                                        {/* <!-- Chat Right --> */}
                                        {(ChatID || (location?.state?.path === "Through_appointmentdetails")) ?
                                        // {(!ChatID && (location?.state?.path === "Through_appointmentdetails")) ?
                                            <div className="chat-cont-right">
                                                <div className="chat-header justify-content-between">
                                                    <div className="media">
                                                        <div className="media-img-wrap">
                                                            {/* {UserStatus ? "avatar avatar-online" : "avatar avatar-away */}
                                                            <div className={UserStatus ? "avatar" : "avatar"}>
                                                                <img src={ReceiverProfile?.profilePic ? ReceiverProfile?.profilePic : IMAGE_CONSTANT.DOC_PROFILE}
                                                                    alt="" className="avatar-img rounded-circle mb-1" />
                                                            </div>
                                                        </div>
                                                        <div className="media-body">
                                                            <div className="user-name">{ReceiverProfile?.fullName}</div>
                                                            {/* <div className="user-status">{UserStatus ? 'online' : "offline"}</div> */}
                                                        </div>
                                                    </div>
                                                    {/* <div>
                                                    <a href="video-call.html"> <i className="fas fa-video fa-lg mr-3"></i></a>
                                                    <a href="voice-call.html"> <i className="fa fa-phone fa-lg"></i></a>
                                                </div> */}
                                                </div>
                                                <div className="chat-body">
                                                    <div className="chat-scroll" ref={chatContainer}>
                                                        <ul className="list-unstyled">
                                                            {chatHistoryList?.length ? chatHistoryList.map((item, i) => {
                                                                return (
                                                                    <React.Fragment key={i}>
                                                                        {item?.senderId?._id === patientId ?
                                                                            <li className="media sent">
                                                                                <div className="media-body">
                                                                                    <div className="msg-box">
                                                                                        <div>
                                                                                            {item?.type === 0 ? <p>{item?.message}</p> :
                                                                                                item?.message.includes('pdf') ?
                                                                                                    <a href={item?.message} target="_blank">
                                                                                                        <img src={pdfIcon} alt="pdfIcon" className="pdfIcon" />
                                                                                                    </a> :
                                                                                                    <a href={item?.message} target="_blank">
                                                                                                        <img src={item?.message} alt="chatImage" />
                                                                                                    </a>
                                                                                            }
                                                                                            <ul className="chat-msg-info">
                                                                                                <li>
                                                                                                    <div className="chat-time">
                                                                                                        <span>{item?.updatedAt.slice(0, 10) + ' ' + moment(item?.updatedAt).format('LT')} </span>
                                                                                                    </div>
                                                                                                </li>
                                                                                            </ul>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </li> : (
                                                                                <li className="media received">
                                                                                    <div className="avatar">
                                                                                        <img src={item?.senderId?.profilePic ? item?.senderId?.profilePic : IMAGE_CONSTANT.DOC_PROFILE}
                                                                                            alt="" className="avatar-img rounded-circle mb-1" />
                                                                                    </div>
                                                                                    <div className="media-body">
                                                                                        <div className="msg-box">
                                                                                            <div>
                                                                                                {item?.type === 0 ? <p>{item?.message}</p> :
                                                                                                    item?.message.includes('pdf') ?
                                                                                                        <a href={item?.message} target="_blank">
                                                                                                            <img src={pdfIcon} alt="pdfIcon" className="pdfIcon" />
                                                                                                        </a> :
                                                                                                        <a href={item?.message} target="_blank">
                                                                                                            <img src={item?.message} alt="chatImage" />
                                                                                                        </a>
                                                                                                }
                                                                                                <ul className="chat-msg-info">
                                                                                                    <li>
                                                                                                        <div className="chat-time">
                                                                                                            <span>{item?.updatedAt.slice(0, 10) + ' ' + moment(item?.updatedAt).format('LT')}</span>
                                                                                                        </div>
                                                                                                    </li>
                                                                                                </ul>
                                                                                            </div>
                                                                                        </div>

                                                                                        {/* <div className="msg-box">
                                                                                <div>
                                                                                    <div className="chat-msg-attachments">
                                                                                        <div className="chat-attachment">
                                                                                            <img src={IMAGE_CONSTANT.PLACE_HOLDERPIC}
                                                                                                alt="Attachment"/>
                                                                                            <div className="chat-attach-caption">
                                                                                                placeholder.jpg</div>
                                                                                            <a href="#" className="chat-attach-download">
                                                                                                <i className="fas fa-download"></i>
                                                                                            </a>
                                                                                        </div>
                                                                                        <div className="chat-attachment">
                                                                                            <img src={IMAGE_CONSTANT.PLACE_HOLDERPIC}
                                                                                                alt="Attachment"/>
                                                                                            <div className="chat-attach-caption">
                                                                                                placeholder.jpg</div>
                                                                                            <a href="#" className="chat-attach-download">
                                                                                                <i className="fas fa-download"></i>
                                                                                            </a>
                                                                                        </div>
                                                                                    </div>
                                                                                    <ul className="chat-msg-info">
                                                                                        <li>
                                                                                            <div className="chat-time">
                                                                                                <span>8:41 AM</span>
                                                                                            </div>
                                                                                        </li>
                                                                                    </ul>
                                                                                </div>
                                                                            </div> */}
                                                                                    </div>
                                                                                </li>
                                                                            )
                                                                        }

                                                                    </React.Fragment>
                                                                )
                                                            })

                                                                : null}


                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="chat-footer">
                                                    <div className="input-group">
                                                        <div className="input-group-prepend">
                                                            <div className="btn-file btn">
                                                                <i className="fa fa-paperclip"></i>
                                                                <input type="file" onChange={handleChange} accept='image/jpg, image/jpeg , image/png  , application/pdf' />
                                                            </div>
                                                        </div>
                                                        <div className='chat_prev_box'>

                                                            {
                                                                <div className='imagePreview'>
                                                                    {fileUrl ?
                                                                        <span onClick={removeFile} className='remove' aria-hidden="true">&times;</span>
                                                                        : null
                                                                    }
                                                                    {fileUrl ? FileData.type === 'application/pdf' ?
                                                                        <span> <img src={pdfIcon} alt="chatImage" className="img-fluid pdfIcon" /> </span>
                                                                        : <span> <img src={fileUrl} className="img-fluid" alt="chatImage" /> </span> :
                                                                        <input type="text" onKeyUp={onkeyupFun} value={msgText} className="input-msg-send form-control"
                                                                            placeholder="Type something" onChange={(e) => msgTextChange(e.target.value)} />
                                                                    }
                                                                </div>


                                                            }
                                                            {msgText && <div className="input-group-append">
                                                                <button type="button" onClick={sendMessage} className="btn msg-send-btn"><i
                                                                    className="fab fa-telegram-plane"></i></button>

                                                            </div>}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            : 
                                            // (location?.state?.path !== "Through_appointmentdetails") ?
                                                <div class="chat-cont-right">
                                                    <div class="card calls-box-card">
                                                        <div>
                                                            <h3>Click on the chat to check message received from recipients</h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                // : ChatID ?
                                                //     <div className="chat-cont-right">
                                                //         <div className="chat-header justify-content-between">

                                                //             <div className="media">
                                                //                 <div className="media-img-wrap">
                                                //                     {/* {UserStatus ? "avatar avatar-online" : "avatar avatar-away */}
                                                //                     <div className={UserStatus ? "avatar" : "avatar"}>
                                                //                         <img src={ReceiverProfile?.profilePic ? ReceiverProfile?.profilePic : IMAGE_CONSTANT.DOC_PROFILE}
                                                //                             alt="" className="avatar-img rounded-circle mb-1" />
                                                //                     </div>
                                                //                 </div>
                                                //                 <div className="media-body">
                                                //                     <div className="user-name">{ReceiverProfile?.fullName}</div>
                                                //                     {/* <div className="user-status">{UserStatus ? 'online' : "offline"}</div> */}
                                                //                 </div>
                                                //             </div>
                                                //             {/* <div>
                                                //     <a href="video-call.html"> <i className="fas fa-video fa-lg mr-3"></i></a>
                                                //     <a href="voice-call.html"> <i className="fa fa-phone fa-lg"></i></a>
                                                // </div> */}
                                                //         </div>
                                                //         <div className="chat-body">
                                                //             <div className="chat-scroll" ref={chatContainer}>
                                                //                 <ul className="list-unstyled">
                                                //                     {chatHistoryList?.length ? chatHistoryList.map((item, i) => {
                                                //                         return (
                                                //                             <React.Fragment key={i}>
                                                //                                 {item?.senderId?._id === patientId ?
                                                //                                     <li className="media sent">
                                                //                                         <div className="media-body">
                                                //                                             <div className="msg-box">
                                                //                                                 <div>
                                                //                                                     {item?.type === 0 ? <p>{item?.message}</p> :
                                                //                                                         item?.message.includes('pdf') ?
                                                //                                                             <a href={item?.message} target="_blank">
                                                //                                                                 <img src={pdfIcon} alt="pdfIcon" className="pdfIcon" />
                                                //                                                             </a> :
                                                //                                                             <a href={item?.message} target="_blank">
                                                //                                                                 <img src={item?.message} alt="chatImage" />
                                                //                                                             </a>
                                                //                                                     }
                                                //                                                     <ul className="chat-msg-info">
                                                //                                                         <li>
                                                //                                                             <div className="chat-time">
                                                //                                                                 <span>{item?.updatedAt.slice(0, 10) + ' ' + moment(item?.updatedAt).format('LT')} </span>
                                                //                                                             </div>
                                                //                                                         </li>
                                                //                                                     </ul>
                                                //                                                 </div>
                                                //                                             </div>
                                                //                                         </div>
                                                //                                     </li> : (
                                                //                                         <li className="media received">
                                                //                                             <div className="avatar">
                                                //                                                 <img src={item?.senderId?.profilePic ? item?.senderId?.profilePic : IMAGE_CONSTANT.DOC_PROFILE}
                                                //                                                     alt="" className="avatar-img rounded-circle mb-1" />
                                                //                                             </div>
                                                //                                             <div className="media-body">
                                                //                                                 <div className="msg-box">
                                                //                                                     <div>
                                                //                                                         {item?.type === 0 ? <p>{item?.message}</p> :
                                                //                                                             item?.message.includes('pdf') ?
                                                //                                                                 <a href={item?.message} target="_blank">
                                                //                                                                     <img src={pdfIcon} alt="pdfIcon" className="pdfIcon" />
                                                //                                                                 </a> :
                                                //                                                                 <a href={item?.message} target="_blank">
                                                //                                                                     <img src={item?.message} alt="chatImage" />
                                                //                                                                 </a>
                                                //                                                         }
                                                //                                                         <ul className="chat-msg-info">
                                                //                                                             <li>
                                                //                                                                 <div className="chat-time">
                                                //                                                                     <span>{item?.updatedAt.slice(0, 10) + ' ' + moment(item?.updatedAt).format('LT')}</span>
                                                //                                                                 </div>
                                                //                                                             </li>
                                                //                                                         </ul>
                                                //                                                     </div>
                                                //                                                 </div>

                                                //                                                 {/* <div className="msg-box">
                                                //                                 <div>
                                                //                                     <div className="chat-msg-attachments">
                                                //                                         <div className="chat-attachment">
                                                //                                             <img src={IMAGE_CONSTANT.PLACE_HOLDERPIC}
                                                //                                                 alt="Attachment"/>
                                                //                                             <div className="chat-attach-caption">
                                                //                                                 placeholder.jpg</div>
                                                //                                             <a href="#" className="chat-attach-download">
                                                //                                                 <i className="fas fa-download"></i>
                                                //                                             </a>
                                                //                                         </div>
                                                //                                         <div className="chat-attachment">
                                                //                                             <img src={IMAGE_CONSTANT.PLACE_HOLDERPIC}
                                                //                                                 alt="Attachment"/>
                                                //                                             <div className="chat-attach-caption">
                                                //                                                 placeholder.jpg</div>
                                                //                                             <a href="#" className="chat-attach-download">
                                                //                                                 <i className="fas fa-download"></i>
                                                //                                             </a>
                                                //                                         </div>
                                                //                                     </div>
                                                //                                     <ul className="chat-msg-info">
                                                //                                         <li>
                                                //                                             <div className="chat-time">
                                                //                                                 <span>8:41 AM</span>
                                                //                                             </div>
                                                //                                         </li>
                                                //                                     </ul>
                                                //                                 </div>
                                                //                             </div> */}
                                                //                                             </div>
                                                //                                         </li>
                                                //                                     )
                                                //                                 }

                                                //                             </React.Fragment>
                                                //                         )
                                                //                     })

                                                //                         : null}


                                                //                 </ul>
                                                //             </div>
                                                //         </div>
                                                //         <div className="chat-footer">
                                                //             <div className="input-group">
                                                //                 <div className="input-group-prepend">
                                                //                     <div className="btn-file btn">
                                                //                         <i className="fa fa-paperclip"></i>
                                                //                         <input type="file" onChange={handleChange} accept='image/jpg, image/jpeg , image/png  , application/pdf' />
                                                //                     </div>
                                                //                 </div>
                                                //                 <div className='chat_prev_box'>

                                                //                     {
                                                //                         <div className='imagePreview'>
                                                //                             {fileUrl ?
                                                //                                 <span onClick={removeFile} className='remove' aria-hidden="true">&times;</span>
                                                //                                 : null
                                                //                             }
                                                //                             {fileUrl ? FileData.type === 'application/pdf' ?
                                                //                                 <span> <img src={pdfIcon} alt="chatImage" className="img-fluid pdfIcon" /> </span>
                                                //                                 : <span> <img src={fileUrl} className="img-fluid" alt="chatImage" /> </span> :
                                                //                                 <input type="text" onKeyUp={onkeyupFun} value={msgText} className="input-msg-send form-control"
                                                //                                     placeholder="Type something" onChange={(e) => msgTextChange(e.target.value)} />
                                                //                             }
                                                //                         </div>


                                                //                     }
                                                //                     {msgText && <div className="input-group-append">
                                                //                         <button type="button" onClick={sendMessage} className="btn msg-send-btn"><i
                                                //                             className="fab fa-telegram-plane"></i></button>

                                                //                     </div>}
                                                //                 </div>
                                                //             </div>
                                                //         </div>
                                                //     </div> : null
                                        }
                                        
                                        {/* <!-- /Chat Right --> */}

                                    </div>
                                </div>
                            </div>
                            {/* <!-- /Row --> */}

                            {/* </div> */}

                            {/* </div> */}
                        </div>
                    </div>

                </div>


            }
        </>
    )
}

export default React.memo(Chatting)
