import React, { useState, useEffect } from "react";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../constants";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { authentication, commonAction } from "../../../redux/action";
import { useLocation } from "react-router-dom";
import PdfIc from "../../../assets/img/pdf.svg";
import ToasterFunc from "../../common/toaster/Toaster";

function PatientMedical() {
  let dispatch = useDispatch();
  let pathname = useLocation();
  let history = useHistory();
  const [Image, setImage] = useState("");
  const [ImageErr, setImageErr] = useState("");
  const [DiabeticError, setDiabeticError] = useState("");
  const [DiabeticDetailErr, setDiabeticDetailErr] = useState("");
  const [DiabeticDetail, setDiabeticDetail] = useState("");
  const [Diabetic, setDiabetic] = useState({
    Yes: false,
    No: false,
  });
  const [medicalFile, setmedicalFile] = useState("");

  console.log("pathname");

  const CommonProfilePic = async (payload) => dispatch(commonAction.commonProfilePicAction(payload));

  const commonUploadedFile = async (payload) => dispatch(commonAction.commonUploadedFileAction(payload));

  const medicalProfilePat = async (payload) => dispatch(authentication.medicalProfilePatientAction(payload));

  useEffect(() => {
    if (Image !== "") {
      setImageErr("");
    }
    if (Diabetic.Yes !== false || Diabetic.No !== false) {
      setDiabeticError("");
    }
    if (DiabeticDetail !== "") {
      setDiabeticDetailErr("");
    }
  }, [Image, Diabetic, DiabeticDetail]);

  const formik = useFormik({
    initialValues: {
      height: "",
      weight: "",
      bloodGroup: "",
      // DiabeteDetail:'',
      AllergiesDetail: "",
      FamilyNote: "",
      // MedicalFiles:''
      weightUnit: "lbs",
      heightUnit: "Feet",
    },
    validationSchema: Yup.object({
      // profilePic: Yup.string()
      // .required("*Image is required."),
      height: Yup.number().required("*Required.").typeError("*Please enter only digits"),
      weight: Yup.number().required("*Required.").typeError("*Please enter only digits"),
      bloodGroup: Yup.string().required("*Required"),
      // DiabeteDetail: Yup.string().required("*Required"),
      AllergiesDetail: Yup.string().required("*Required"),
      FamilyNote: Yup.string().required("*Required"),
      // MedicalFiles:  Yup.string().required("*Required"),
    }),
    onSubmit: (values) => {
      console.log("values", values);

      //
    },
  });

  console.log("DiabeticDetailErr", DiabeticDetail, DiabeticDetailErr);

  const handleNext = async () => {
    if (Diabetic.Yes === false && Diabetic.No === false) {
      setDiabeticError("*Please Choose any one");
    }
    if (Diabetic.Yes === true || Diabetic.No === true) {
      setDiabeticError("");
    }
    if (Diabetic.Yes) {
      if (DiabeticDetail === "") {
        setDiabeticDetailErr("*Required");
      }
    }
    if (Image === "") {
      setImageErr("*Please choose image");
    }
    if (Image !== "") {
      setImageErr("");
    }
    if (Diabetic.Yes === true || Diabetic.No === true) {
      if (Diabetic.Yes) {
        if (
          formik.values.height !== "" &&
          formik.values.weight !== "" &&
          formik.values.bloodGroup !== "" &&
          DiabeticDetail !== "" &&
          formik.values.AllergiesDetail !== "" &&
          formik.values.FamilyNote !== ""
        ) {
          const payload = {
            height: formik.values.height,
            weight: formik.values.weight,
            bloodGroup: formik.values.bloodGroup,
            iSDiabetes: Diabetic.Yes ? true : false,
            diabetesDetails: DiabeticDetail ? DiabeticDetail : "",
            allergyDeatils: formik.values.AllergiesDetail,
            familyNotes: formik.values.FamilyNote,
            medicalFile: medicalFile ? medicalFile : "",
            weightUnit: formik.values.weightUnit,
            hightUnit: formik.values.heightUnit,
          };
          const res = await medicalProfilePat(payload);
          if (res && res.status == 200) {
            console.log("Complete profile", res);
            history.push(ROUTES_CONSTANTS.PATIENT_DASHBOARD);
          }
        }
      } else if (Diabetic.No) {
        if (
          formik.values.height !== "" &&
          formik.values.weight !== "" &&
          formik.values.bloodGroup !== "" &&
          formik.values.AllergiesDetail !== "" &&
          formik.values.FamilyNote !== ""
        ) {
          const payload = {
            height: formik.values.height,
            weight: formik.values.weight,
            bloodGroup: formik.values.bloodGroup,
            iSDiabetes: Diabetic.Yes ? true : false,
            diabetesDetails: DiabeticDetail ? DiabeticDetail : "",
            allergyDeatils: formik.values.AllergiesDetail,
            familyNotes: formik.values.FamilyNote,
            medicalFile: medicalFile ? medicalFile : "",
            weightUnit: formik.values.weightUnit,
            hightUnit: formik.values.heightUnit,
          };
          const res = await medicalProfilePat(payload);
          if (res && res.status == 200) {
            console.log("Complete profile", res);
            history.push("/");
          }
        }
      }
    }
  };
  console.log(formik.errors);

  const handleDiabetic = (e) => {
    let checked = e.target.checked;
    let value = e.target.value;
    if (value === "1") {
      setDiabetic({
        Yes: checked,
      });
    } else if (value === "2") {
      setDiabetic({
        No: checked,
      });
    }
  };

  const handleDiabeticDetail = (e) => {
    // console.log(e.target.value);
    setDiabeticDetail(e.target.value);
  };

  const handleImage = (e) => {
    let result = e.target.value;
    setImage(result);
    const file = e.target.files[0];
    console.log(file);
    let formData = new FormData();
    console.log("file changed");

    formData.append("file", file);
    fileUpload(formData);

    // if (file.type.split("/")[0] !== "image") {
    //   // toast("Only image files are accepted");
    // } else {
    //   setImage(
    //     Object.assign(file, {
    //       preview: URL.createObjectURL(file),
    //     })
    //   );
    // }
  };

  const fileUpload = async (image) => {
    const res = await CommonProfilePic(image);
    if (res && res.status == 200) {
      console.log("Profile pIccccccccc", res.data.result.url);
      let picdata = res && res.data && res.data.result.url;
      setImage(picdata);
    }
  };

  const handleUploadFile = (e) => {
    let result = e.target.value;
    console.log(e.target.value);
    // setmedicalFile(result);
    const file = e.target.files[0];
    console.log(file);
    let formData = new FormData();
    console.log("file changed");

    if (file) {
      let fileSize = file.size / 1e6;
      console.log("fileSize", fileSize);
      if (fileSize > 10) {
        ToasterFunc("error", "Please upload a file smaller than 10 MB");
      } else {
        formData.append("file", file);
        medicalFileUpload(formData);
      }
    }
  };

  const medicalFileUpload = async (image) => {
    const res = await commonUploadedFile(image);
    if (res && res.status == 200) {
      console.log("medical reportttttt", res.data.result.url);
      //   let picdata= res &&  res.data && res.data.result.url
      setmedicalFile(res && res.data && res.data.result && res.data.result.url);
    }
  };

  return (
    <div class="index-home-padding">
      <div class="container-fluid">
        <div class="row">
          <div class="row align-items-center justify-content-center">
            <div class="col-xl-6 col-lg-6 col-md-12 login-left">
              <img src={IMAGE_CONSTANT.LOGIN_BANNER} class="img-fluid" alt="Doccure Register" />
            </div>
            <div class="col-xl-6 col-lg-6 col-md-12 login-right">
              <div class="container">
                <div class="row  mb-5">
                  <div class="col-md-12">
                    <div class="stepPregresbar">
                      <div class="round-stepCircle ">1</div>
                      <div class="round-stepCircle active">2</div>
                    </div>
                    <div class="d-flex justify-content-around">
                      <label for="">Personal Detail</label>
                      <label for="">Medical Detail</label>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row form-row mb-4">
                <div class="col-md-12">
                  <div class="change-avatar justify-content-center">
                    <div class="profile-round-img">
                      <figure className="mb-0">
                        <img src={pathname && pathname.state} alt="" />
                      </figure>
                      {/* <label for="camera">
                        <input
                          type="file"
                          id="camera"
                          accept="image/*"
                          hidden
                          onChange={(e) => handleImage(e)}
                        />
                        <i class="fas fa-camera icon-camera"></i>
                      </label> */}
                    </div>
                  </div>
                </div>
              </div>

              {/* <!-- Register Form --> */}
              <form onSubmit={formik.handleSubmit}>
                <div class="row ">
                  <div class="col-md-6">
                    <label class="focus-label" for="Height">
                      Height
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        maxLength="3"
                        id="Height"
                        name="height"
                        placeholder="Enter Height"
                        {...formik.getFieldProps("height")}
                      />
                      <div className="input-group-append">
                        {/* <span class="input-group-text">Cm</span> */}
                        <select class="form-control" name="heightUnit" {...formik.getFieldProps("heightUnit")}>
                          <option value="Feet">Feet</option>
                          <option value="CM">CM</option>
                        </select>
                      </div>
                    </div>

                    {formik.touched.height && formik.errors.height ? (
                      <span className="validation_error mt--12">{formik.errors.height}</span>
                    ) : null}
                  </div>
                  <div class="col-md-6">
                    <label class="focus-label" for="Weight">
                      Weight
                    </label>
                    <div class="input-group mb-3">
                      <input
                        type="text"
                        class="form-control"
                        maxLength="3"
                        id="Weight"
                        name="weight"
                        placeholder="Enter Weight"
                        {...formik.getFieldProps("weight")}
                      />
                      <div className="input-group-append">
                        {/* <span class="input-group-text">Kgs</span> */}
                        <select class="form-control" name="weightUnit" {...formik.getFieldProps("weightUnit")}>
                          <option value="lbs">lbs</option>
                          <option value="KG">KG</option>
                        </select>
                      </div>
                    </div>
                    {formik.touched.weight && formik.errors.weight ? (
                      <span className="validation_error mt--12">{formik.errors.weight}</span>
                    ) : null}
                  </div>
                </div>

                <div class="form-group">
                  <label for="">Blood Group*</label>
                  <select class="form-control" name="bloodGroup" {...formik.getFieldProps("bloodGroup")}>
                    <option hidden>Select</option>
                    <option value="A+">A+</option>
                    <option value="O+">O+</option>
                    <option value="B+">B+</option>
                    <option value="AB+">AB+</option>
                    <option value="A-">A-</option>
                    <option value="O-">O-</option>
                    <option value="B-">B-</option>
                    <option value="AB-">AB-</option>
                  </select>
                  {formik.touched.bloodGroup && formik.errors.bloodGroup ? (
                    <span className="validation_error">{formik.errors.bloodGroup}</span>
                  ) : null}
                </div>
                <div class="row form-group">
                  <div class="col-md-6">
                    <label class="form-check-label" for="exampleCheck1">
                      Are You diagonsed with diabetes?
                    </label>
                  </div>
                  <div class="col-md-3">
                    <div class="form-check">
                      <input type="radio" class="form-check-input" id="yes" name="diabetic" value="1" onClick={(e) => handleDiabetic(e)} />
                      <label class="form-check-label color-grey" for="yes">
                        Yes
                      </label>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-check ">
                      <input type="radio" class="form-check-input" id="no" name="diabetic" value="2" onClick={(e) => handleDiabetic(e)} />
                      <label class="form-check-label color-grey" for="no">
                        No
                      </label>
                    </div>
                  </div>
                  {DiabeticError ? <div className="validation_error Image">{DiabeticError}</div> : null}
                </div>
                <div class={Diabetic.Yes ? "form-group" : "form-group diabetic_hide"}>
                  <label for="descriptionpersonalName">Diabetes Details</label>
                  <input
                    class="form-control"
                    id="descriptionpersonalName"
                    maxLength="180"
                    placeholder="Type here...."
                    name="DiabeteDetail"
                    onChange={(e) => handleDiabeticDetail(e)}
                  ></input>
                  {DiabeticDetailErr ? <div className="validation_error">{DiabeticDetailErr}</div> : null}
                </div>

                <div class="form-group">
                  <label for="descriptionpersonalName">Do You Have Any Allergies?</label>
                  <textarea
                    class="form-control"
                    id="descriptionpersonalName"
                    rows="3"
                    maxLength="180"
                    placeholder="Type here...."
                    name="AllergiesDetail"
                    {...formik.getFieldProps("AllergiesDetail")}
                  ></textarea>
                  {formik.touched.AllergiesDetail && formik.errors.AllergiesDetail ? (
                    <span className="validation_error">{formik.errors.AllergiesDetail}</span>
                  ) : null}
                </div>
                <div class="form-group">
                  <label for="descriptionpersonalName">Familiy Notes</label>
                  <textarea
                    class="form-control"
                    id="descriptionpersonalName"
                    rows="3"
                    minlength="70"
                    maxLength="180"
                    placeholder="Type here...."
                    name="FamilyNote"
                    {...formik.getFieldProps("FamilyNote")}
                  ></textarea>
                  {formik.touched.FamilyNote && formik.errors.FamilyNote ? (
                    <span className="validation_error">{formik.errors.FamilyNote}</span>
                  ) : null}
                </div>
                <div class="form-group">
                  <label for="uploadPdf">Upload Medical Files</label>
                  <div class="upload-pdf-border">
                    {/* <img className="uploaded_file" src={PdfIc} alt="User pdf"/> */}
                    <label class="upload-reports-pdf ">
                      <input type="file" accept="application/pdf,.doc,.docx" hidden onChange={(e) => handleUploadFile(e)} />
                      {medicalFile ? (
                        <div className="d-flex">
                          <div className="uploaded_view">
                            <span
                              className="file_remove shadow"
                              // onClick={() => setImage("")}
                            ></span>
                            <img style={{ width: "40px", height: "40px" }} src={PdfIc} alt="img" />
                          </div>
                        </div>
                      ) : (
                        <i class="fas fa-file-pdf color-grey" name="MedicalFiles" />
                      )}
                    </label>
                  </div>
                </div>
                <button class="btn btn-primary btn-block btn-lg login-btn" type="submit" onClick={handleNext}>
                  Submit
                </button>
              </form>
              {/* <!-- /Register Form --> */}
            </div>
          </div>
        </div>
        {/* <!-- /Register Content --> */}
      </div>
    </div>
  );
}

export default PatientMedical;
