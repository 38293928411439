import { httpService } from "..";
import ToasterFunc from "../../components/common/toaster/Toaster";
import { API_CONSTANTS } from "../../constants";
import { getSessionStorageOrDefault } from "../../utils";

class NotificationServices {
  async notificationList(pageNumber = 1, Limit = 10) {
    try {
      const userType = await getSessionStorageOrDefault("userType");
      const response = await httpService.get(
        `${userType == "1" ? "doctor" : "patient"}/${
          API_CONSTANTS.NOTIFICATION_LIST
        }?pageNumber=${pageNumber}&limit=${Limit}`
      );
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async deleteNotification(id) {
    try {
      const userType = await getSessionStorageOrDefault("userType");
      const response = await httpService.delete(
        `${userType == "1" ? "doctor" : "patient"}/${
          API_CONSTANTS.DELETE_NOTIFICATION
        }/${id}`
      );
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
}

export default new NotificationServices();
