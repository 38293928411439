import React, { useEffect, useState } from "react";
import { IMAGE_CONSTANT } from "../../../constants";
import DocSubcription from "./DocSubcription";
import { useFormik } from "formik";
import * as Yup from "yup";
// import AutoComplete from "./AutoComplete";
import Autocomplete from "react-google-autocomplete";
import { useDispatch } from "react-redux";
import { authentication, commonAction } from "../../../redux/action";
import ToasterFunc from "../../common/toaster/Toaster";
import moment from "moment";
import DatePicker from "react-datepicker";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import { PlacesAutocomplete } from "./AutoComplete";

function DocPersonal() {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });
  const dispatch = useDispatch();
  const subscriptionList = () => dispatch(authentication.subscriptionListDoctorAction());

  const CommonProfilePic = async (payload) => dispatch(commonAction.commonProfilePicAction(payload));

  const signUpDoctorPersonalDetails = async (payload) => dispatch(authentication.signUpDoctorPersonalDetailsAction(payload));

  const [DocSubcriptionModal, setDocSubcriptionModal] = useState(false);
  const [Address, setAddress] = useState("");
  const [AddressEr, setAddressEr] = useState("");
  const [Lat, setLat] = useState("");
  const [Lng, setLng] = useState("");
  const [Image, setImage] = useState("");
  const [ImageErr, setImageErr] = useState("");
  const [genderError, setgenderError] = useState("");
  const [UpperlttrErr, setUpperlttrErr] = useState("");
  const [gender, setgender] = useState({
    male: false,
    female: false,
    notToSay: false,
  });
  const [PinCode, setPinCode] = useState("");
  const [ModifiedDate, setModifiedDate] = useState("");
  const [UploadedFile, setUploadedFile] = useState("");
  const [nameValue, setnameValue] = useState("");
  const [BirthDateError, setBirthDateError] = useState("");
  const [BirthDate, setBirthDate] = useState("");

  useEffect(() => {
    const res = subscriptionList();
  }, []);

  useEffect(() => {
    if (Image !== "") {
      setImageErr("");
    }
    if (gender.male || gender.female || gender.notToSay) {
      setgenderError("");
    }
  }, [Image, gender]);

  const formik = useFormik({
    initialValues: {
      profilename: "",
      DOB: "",
      description: "",
      // address: "",
      // pincode: "",
    },
    validationSchema: Yup.object({
      profilename: Yup.string().required("*Required.").matches(/^[aA-zZ\s]+$/, "Only alphabets are allowed"),
      DOB: Yup.string().required("*Required."),
      description: Yup.string().required("*Required."),
      // address: Yup.string().required("*Required"),
      // pincode: Yup.string().required("*Required"),
    }),
  });

  useEffect(() => {
    if (Image !== "") {
      setImageErr("");
    }
    if (gender.male || gender.female || gender.notToSay) {
      setgenderError("");
    }
    if (BirthDate !== "") {
      setBirthDateError("");
    }
    if (Address) {
      setAddressEr("");
    }
    if (formik.values.profilename.charAt(0) != formik.values.profilename.charAt(0).toUpperCase()) {
      setnameValue(formik.values.profilename.charAt(0).toUpperCase() + formik.values.profilename.slice(1));
    } else {
      setUpperlttrErr("");
    }
  }, [Image, gender, formik.values.profilename, BirthDate, Address]);

  useEffect(() => {
    formik.setFieldValue("profilename", nameValue);
  }, [nameValue]);

  const handleNext = async () => {
    if (gender.male === false && gender.female === false && gender.notToSay === false) {
      setgenderError("*Please Choose any one");
    }
    if (gender.male || gender.female || gender.notToSay) {
      setgenderError("");
    }
    if (Image === "") {
      setImageErr("*Please choose image");
    }
    if (Image !== "") {
      setImageErr("");
    }
    if (BirthDate === "") {
      setBirthDateError("*Required");
    } else {
      setBirthDateError("");
    }

    if (Address === "") {
      setAddressEr("*Required");
    }
    console.log(gender.male || gender.female || gender.notToSay, !!formik.values.profilename, !!formik.values.description, !!Address);
    if ((gender.male || gender.female || gender.notToSay) && !!BirthDate && !!formik.values.profilename && !!formik.values.description && !!Address && Image !== "") {
      const payload = {
        fullName: formik.values.profilename,
        profilePic: Image ? Image : null,
        gender: gender.male ? "Male" : gender.female ? "Female" : gender.notToSay ? "Other" : "",
        bio: formik.values.description,
        lat: Lat ? Lat : "",
        long: Lng ? Lng : "",
        address: Address ? Address : "",
        date_of_birth: moment(BirthDate).format("MM/DD/YYYY"),
      };
      const res = await signUpDoctorPersonalDetails(payload);
      if (res.status == 200) setDocSubcriptionModal(!DocSubcriptionModal);
    }
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    console.log(file);
    setUploadedFile(file);
    // let formData = new FormData();
    // formData.append("file", file);
    // fileUpload(formData);

    if (file) {
      let fileSize = file.size / 1e6;
      console.log("fileSize", fileSize);
      if (fileSize > 1) {
        ToasterFunc("error", "Please upload a file smaller than 1 MB");
      } else {
        let formData = new FormData();
        formData.append("file", file);
        fileUpload(formData);
      }
    }
  };

  const fileUpload = async (image) => {
    const res = await CommonProfilePic(image);
    if (res && res.status == 200) {
      let picdata = res && res.data && res.data.result.url;
      setImage(picdata);
    }
  };

  const handlegender = (e) => {
    console.log(e.target.value, e.target.checked);
    let checked = e.target.checked;
    let value = e.target.id;
    setgenderError("");
    if (value === "Male") {
      setgender({
        male: checked,
      });
    } else if (value === "Female") {
      setgender({
        female: checked,
      });
    } else if (value === "not-to-say") {
      setgender({
        notToSay: checked,
      });
    }
  };

  console.log("PinCode", PinCode);

  // const minDateModifier = () => {
  //   var dtToday = new Date();

  //   var month = dtToday.getMonth() + 1;
  //   var day = dtToday.getDate();
  //   var year = dtToday.getFullYear();

  //   if (month < 10) month = "0" + month.toString();
  //   if (day < 10) day = "0" + day.toString();
  //   var maxDate = year + "-" + month + "-" + day;
  //   setModifiedDate(maxDate);
  // };

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
    setAddress(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();
    console.log("description", description);
    setAddress(description);

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log("📍 Coordinates: ", { lat, lng });
        setLat(lat);
        setLng(lng);
      })
      .catch((error) => {
        console.log("😱 Error: ", error);
      });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div class="container-fluid">
      <div class="row align-items-center justify-content-center">
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 login-left">
          <img src={IMAGE_CONSTANT.LOGIN_BANNER} class="img-fluid" alt="Doccure Register" />
        </div>
        <div class="col-xl-6 col-lg-12 col-md-12 col-sm-12 login-right">
          <div class="container">
            <div class="row mb-5">
              <div class="col-md-12 ">
                <div class="stepPregresbar ">
                  <div class="round-stepCircle active">1</div>
                  <div class="round-stepCircle">2</div>
                </div>

                <div class="d-flex justify-content-around">
                  <label for="">Personal </label>
                  <label for="">Professional </label>
                </div>
              </div>
            </div>
          </div>
          <div class="row form-row mb-4">
            <div class="col-md-12">
              <div class="change-avatar justify-content-center">
                <div class="profile-round-img">
                  <figure className="mb-0">
                    <img src={Image || IMAGE_CONSTANT.USER_ICON} alt="" />
                  </figure>
                  <label for="camera">
                    <input type="file" id="camera" name="profilePic" accept="image/*" hidden onChange={(e) => handleImage(e)} />
                    <i class="fas fa-camera icon-camera"></i>
                  </label>
                </div>
              </div>
              {!ImageErr ? <span className="suggestion">Only JPG,JPEG,PNG files with max size of 1MB.</span> : ""}
              {ImageErr ? <div className="validation_error Image">{ImageErr}</div> : null}
            </div>
          </div>

          {/* <!-- Register Form --> */}
          <form onSubmit={formik.handleSubmit}>
            <div class="form-group ">
              <label class="focus-label">Your Name</label>
              <input type="text" class="form-control" name="profilename" placeholder="Enter Name" {...formik.getFieldProps("profilename")} />
              {formik.touched.profilename && formik.errors.profilename ? (
                <span className="validation_error">{formik.errors.profilename}</span>
              ) : formik.values.profilename && UpperlttrErr ? (
                <span className="validation_error">{UpperlttrErr}</span>
              ) : null}
            </div>
            <div class="form-group ">
              <label class="focus-label">Date of Birth</label>
              <DatePicker
                className="form-control"
                id="my_date_picker1"
                autoComplete="off"
                maxDate={moment().toDate()}
                placeholderText="MM/DD/YYYY"
                selected={BirthDate}
                onChange={(date) => setBirthDate(date)}
                showMonthDropdown
                showYearDropdown
                scrollableMonthYearDropdown
                yearDropdownItemNumber={72}
                scrollableYearDropdown={true}
              />
              {/* <input type="date" class="form-control" name="DOB" maxLength="16" max={ModifiedDate} {...formik.getFieldProps("DOB")} onClick={minDateModifier} /> */}
              {BirthDateError ? <span className="validation_error">{BirthDateError}</span> : null}
            </div>
            <div class="row form-group">
              <div class="col-md-2 col-12">
                <div>
                  <label class="form-check-label" for="GenderCheck1">
                    Gender
                  </label>
                </div>
              </div>
              <div class="col-md-3 col-3">
                <div class="form-check">
                  <input type="radio" class="form-check-input" id="Male" name="gender" onChange={(e) => handlegender(e)} />
                  <label class="form-check-label" for="Male">
                    Male
                  </label>
                </div>
              </div>
              <div class="col-md-3 col-4">
                <div class="form-check">
                  <input type="radio" class="form-check-input" id="Female" name="gender" onChange={(e) => handlegender(e)} />
                  <label class="form-check-label" for="Female">
                    Female
                  </label>
                </div>
              </div>
              <div class="col-md-4 col-5">
                <div class="form-check">
                  <input type="radio" class="form-check-input" id="not-to-say" name="gender" onChange={(e) => handlegender(e)} />
                  <label class="form-check-label" for="not-to-say">
                    I prefer not to Say
                  </label>
                </div>
              </div>
              {genderError ? <div className="validation_error gender">{genderError}</div> : null}
            </div>
            <div class="form-group">
              <label for="descriptionpersonalName">Tell us about yourself</label>
              <textarea class="form-control" id="descriptionpersonalName" name="description" maxLength="180" rows="3" placeholder="Type here...." {...formik.getFieldProps("description")}></textarea>
              {formik.touched.description && formik.errors.description ? <span className="validation_error">{formik.errors.description}</span> : null}
            </div>
            <div class="form-group address-suggesstion">
              <label class="focus-label">Address</label>
              <input class="form-control" autoComplete="off" name="address" value={value} maxLength="60" onChange={handleInput} disabled={!ready} placeholder="Address" />
              {status === "OK" && <ul>{renderSuggestions()}</ul>}
              {!Address ? <span className="validation_error">{AddressEr}</span> : null}
            </div>
            {/* <div class="form-group ">
              <label class="focus-label">Pincode</label>
              <input
                type="text"
                class="form-control"
                name="pincode"
                maxLength="6"
                // value={PinCode? PinCode :''}
                placeholder="Enter Pin Code"
                {...formik.getFieldProps("pincode")}
              />
              {formik.touched.pincode && formik.errors.pincode ? <span className="validation_error">{formik.errors.pincode}</span> : null}
            </div> */}
            <div className="btn-p">
              <button type="submit" class="btn btn-primary btn-new" onClick={handleNext}>
                Next
              </button>
            </div>
          </form>
        </div>
      </div>
      <DocSubcription Image={Image} DocSubcriptionModal={DocSubcriptionModal} setDocSubcriptionModal={setDocSubcriptionModal} />
    </div>
  );
}

export default DocPersonal;
