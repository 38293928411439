import React from "react";
import { useDispatch } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { patient } from "../../../../../redux/action";

function DeleteCard({DeleteModal,setDeleteModal, DeleteId, CardListPatientwithPagination}) {
    let dispatch =useDispatch()
    const deleteCardInPatsettings = async(payload)=> dispatch(patient.deleteCardInPatsettingsAction(payload))

    const handleConfirm= async ()=>{
        let res = await deleteCardInPatsettings(DeleteId)
        if(res && res.status===200){
            setDeleteModal(false)
            CardListPatientwithPagination()
        }
    }
  return (
    // <div className="modal fade" id="delete_staticModel" data-backdrop="static" aria-hidden="true" role="dialog">
    //   <div className="modal-dialog modal-dialog-centered " role="document">
    <Modal isOpen={DeleteModal} className='modal-dialog modal-dialog-centered'>
        <div className="modal-content ">
            <ModalBody>
          <div className="modal-body text-center">
            <div className="form-content p-2">
              <h4 className="modal-title text-center mb-2">Remove</h4>
              <p className="mb-3 default-content-clr text-center">
                <b>Card Number</b>
                <br />
                9876XXXXXXXXXXXX
              </p>
              <p className="default-content-clr text-center">Lorem idipisicing elit. Aabo animi magni corporis ipsam.</p>
              <button type="button" className="btn btn-primary button-margin" onClick={()=>setDeleteModal(false)}>
                Cancel
              </button>
              <button type="button" className="btn btn-primary modal-confirm-btn button-margin" onClick={handleConfirm}>
                Confirm
              </button>
            </div>
          </div>
          </ModalBody>
        </div>
    </Modal>
    //   </div>
    // </div>
  );
}

export default DeleteCard;
