
import Chatting from "./Chatting/Chatting";
import Calling from "./Calling/Calling";
import { useState } from "react";
import { Topbar } from "../../../common";
import {ReactComponent as BackArrow } from '../../../../assets/img/return-up-back.svg'
import { ROUTES_CONSTANTS } from "../../../../constants";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";

function Messages() {
    let history = useHistory();
    const location = useLocation();
    const roomId  = location?.state?.roomId ; 
    const [isChatOpen, setisChatOpen] = useState(true)
    const [isCallOpen, setisCallOpen] = useState(false)
    const handleChats=()=>{
        setisChatOpen(true)
        setisCallOpen(false)
    }
    const handleCalls=()=>{
        setisCallOpen(true)
        setisChatOpen(false)
    }
    console.log("isCallOpen",isCallOpen);
    const handleNavigation = () => {
		history.push(ROUTES_CONSTANTS.PATIENT_DASHBOARD);
	};
    return (
        <div  className="col-xl-12">
                {/* <Topbar /> */}
        {/* <!--chat and calls Tab Menu --> */}
          <nav className="user-tabs newChat-tabs">
              <span className="back__arrow" title="Go back" onClick={handleNavigation}><BackArrow/></span>
              <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                  <li className="nav-item"  onClick={handleChats}>
                      <a className={isChatOpen ? "nav-link active" : "nav-link"}>Chats</a>
                  </li>
                  {/* <li className="nav-item"  onClick={handleCalls}>
                      <a className={isCallOpen ? "nav-link active" : "nav-link"}>Calls</a>
                  </li> */}
  
              </ul>
          </nav>
          <Chatting RoomId = {roomId}/> 
           {/* {isChatOpen? <Chatting /> : isCallOpen ? <Calling /> : null} */}
         
      </div>
    )
}

export default Messages
