const initialState = {
  common: {
    loadingIndicator: {
      isLoading: false,
    },
    notificationList: {},
  },
};

export default initialState;
