import DoctorService from "../../../services/doctorService/DoctorService";
import { doctorAction } from ".";
import { commonAction } from "../commonAction";
import { saveToSessionStorage } from "../../../utils";
import ToasterFunc from "../../../components/common/toaster/Toaster";

export const doctorProfileDetailsAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.doctorProfileDetails();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.DOCTOR_PROFILE_DATA,
          doctorProdileInfo: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const docDashboardDataAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.docDashboardData();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.DOCTOR_DASHBOARD_DATA,
          doctorDasboardInfo: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const docDashboardupcomingAppointmentsAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.docDashboardupcomingAppointments();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.DOCTOR_UPCOMING_APPOINTMENTS,
          doctorDasboardUpcomingAppointment: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};
export const docDashboardPreviousAppointmentsAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.docDashboardPreviousAppointments();

      dispatch(commonAction.hideLoader());

      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.DOCTOR_PREVIOUS_APPOINTMENTS,
          doctorDashboardPreviousAppointment: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const docViewAppointmentAction = (DocId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.docViewAppointment(DocId);

      dispatch(commonAction.hideLoader());

      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.DOCTOR_VIEW_APPOINTMENT,
          doctorViewAppointmentData: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const docHelpRequestListAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.docHelpRequestList();
      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.DOCTOR_HELP_LIST,
          doctorHelpRequestListData: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const helpSubmitRequestAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    const payloadData = {
      subject: payload.subject,
      message: payload.message,
    };
    try {
      const response = await DoctorService.helpSubmitNewRequest(payloadData);
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const docViewHelpRequestAction = (HelpId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.docViewHelpRequest(HelpId);

      dispatch(commonAction.hideLoader());

      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.DOCTOR_VIEW_HELP_REQUEST,
          doctorViewHelpRequestData: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};
export const changePwdDocAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());

    const payloadData = {
      oldPassword: payload.oldPassword,
      newPassword: payload.newPassword,
      confirmPassword: payload.confirmPassword,
    };
    try {
      const response = await DoctorService.changePwdDocUser(payloadData);
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};
export const docEditProfileAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());

    const payloadData = {
      fullName: payload.fullName,
      profilePic: payload.profilePic,
      gender: payload.gender,
      bio: payload.bio,
      lat: payload.lat,
      long: payload.long,
      address: payload.address,
      pincode: payload.pincode,
      businessLogo: payload.businessLogo,
      secondry_specialty: payload.secondry_specialty,
      licenceNumber: payload.licenceNumber,
      experience_in_years: payload.experience_in_years,
      experience_in_months: payload.experience_in_months,
      serviceCharge: payload.serviceCharge,
      date_of_birth: payload.date_of_birth,
      degreeFile: payload.degreeFile,
    };
    try {
      const response = await DoctorService.docEditProfile(payloadData);
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const docCardListAction = (DataLimit, selectPage) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.docCardList(DataLimit, selectPage);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.DOCTOR_CARD_LIST,
          doctorCardList: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const docAddPaymentCardAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    const payloadData = {
      cardType: payload.cardType,
      cardNumber: payload.cardNumber,
      cardHolderName: payload.cardHolderName,
      expMonth: payload.expMonth,
      expYear: payload.expYear,
    };
    try {
      const response = await DoctorService.docAddPaymentCard(payloadData);
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      ToasterFunc("error", error.response.data.responseMessage);

      reject(error.response);
    }
  });
};

export const docEditCardAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    const payloadData = {
      cardId: payload.cardId,
      cardType: payload.cardType,
      cardNumber: payload.cardNumber,
      cardHolderName: payload.cardHolderName,
      expMonth: payload.expMonth,
      expYear: payload.expYear,
    };
    try {
      const response = await DoctorService.docEditCard(payloadData);
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      ToasterFunc("error", error.response.data.responseMessage);
      reject(error.response);
    }
  });
};

export const docDeleteCardAction = (deleteId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.docDeleteCard(deleteId);
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const docTermsAndConditionAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.docTermsAndConditions();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.DOCTOR_TERMS_AND_CONDITIONS,
          doctorTermsAndConditions: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};
export const docAboutUsAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.docAboutus();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.DOCTOR_ABOUT_US,
          doctorAboutUs: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const docLogoutAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.docLogOut();
      // saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const docWalletInWebAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.docWalletInWeb();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.DOCTOR_WALLET_INFO,
          doctorWalletData: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const docPresciptionListAction = (PatId, DataLimit, selectPage) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.docPresciptionList(PatId, selectPage, DataLimit);
      dispatch(commonAction.hideLoader());

      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.DOCTOR_PRESCRIPTION_LIST,
          doctorPrescriptionData: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const docSubscriptionInWebAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.docSubscriptionInWeb();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.DOCTOR_SUBSCRIBE_INFO,
          doctorSubscribeData: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};
export const docAddPrescriptionAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    const payloadData = {
      patientId: payload.patientId,
      prescriptionArray: payload.prescriptionArray,
      signature: payload.signature,
    };
    try {
      const response = await DoctorService.docAddPrescription(payloadData);
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const docDeletePrescriptionAction = (deleteId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.docDeletePrescription(deleteId);
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const docViewPrescriptionAction = (PresId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.docViewPrescription(PresId);

      dispatch(commonAction.hideLoader());

      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.DOCTOR_VIEW_PRESCRIPTION,
          doctorViewPrescription: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const docEditPrescriptionAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    const payloadData = {
      name: payload.name,
      quantitty: payload.quantitty,
      number_of_days: payload.number_of_days,
      description: payload.description,
      timing: payload.timing,
      signature: payload.signature,
    };
    try {
      const response = await DoctorService.docEditPrescription(payload.id, payloadData);
      console.log(response);
      dispatch(commonAction.hideLoader());

      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.DOCTOR_EDIT_PRESCRIPTION,
          doctorEditPrescription: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};
export const getStaticPageAction = (Type) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.staticPage(Type);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.STATIC_PAGE_CMS,
          staticPageData: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};
export const testimonialsListAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.testimonialsList();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.TESTIMONIAL_LIST,
          testimonialsListData: response?.data?.documentList,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const contactUsFormAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    const payloadData = {
      name: payload.name,
      mobileNumber: payload.mobileNumber,
      email: payload.email,
      message: payload.message,
    };
    try {
      const response = await DoctorService.contactUs(payloadData);
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};
export const newsLetterSubscribeAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    const payloadData = {
      name: payload.name,
      email: payload.email,
    };
    try {
      const response = await DoctorService.newsLetter(payloadData);
      saveToSessionStorage("UserData", JSON.stringify(response.data.userData));
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};
export const ourTeamListAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.ourTeamList();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.OUR_TEAM_LIST,
          ourTeamListData: response?.data?.documentList,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const editAvailabilityAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.editAvailability(payload);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const addMedicalRecordAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.addMedicalRecord(payload);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const editMedicalRecordAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.editMedicalRecord(payload);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const getMedicalRecordAction = (patientId, pageNumber) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.getMedicalRecord(patientId, pageNumber);
      dispatch(commonAction.hideLoader());
      if (response.status == 200) {
        dispatch({
          type: doctorAction.MEDICAL_RECORD_LIST,
          listData: response?.data,
        });
      }
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const recommendationDataAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.recommendationData(payload);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.RECOMMENDATION_LIST,
          recommendationListData: response?.data?.documentList,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const doctorinvoicelistAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.doctorinvoicelist(payload);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.INVOICE_LIST,
          invoiceListInfo: response?.data?.prescriptionData,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const doctorinvoiceViewAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.doctorinvoiceView(payload);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.INVOICE_VIEW,
          invoiceViewInfo: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const doctorinvoicelistFullAction = (DataLimit, selectedPage) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.doctorinvoicelistFull(DataLimit, selectedPage);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.INVOICE_LIST_BILL,
          invoiceListBill: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const doctorReplyOnRequestAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.doctorReplyOnRequest(payload);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const doctorUpgradeSubscriptionFromWalletAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.doctorUpgradeSubscriptionFromWallet(payload);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const doctorPrivacyPolicyAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.doctorPrivacyPolicy();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.PRIVACY_POLICY,
          docPrivacyPolicy: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const doctorwithdrawRequestAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.doctorwithdrawRequest(payload);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: doctorAction.DOCTOR_WITHDRAW_REQUEST,
          withdrawAmtViewInfo: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const docWalletHistoryAction = (DataLimit, selectedPage) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await DoctorService.docWalletHistory(DataLimit, selectedPage);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        console.log('mmmmmmm----->',response?.data);
        dispatch({
          type: doctorAction.DOCTOR_WITHDRAW_TRANSACTION_HISTORY,
          doctorWithdrawTransactionHistory: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export default {
  doctorProfileDetailsAction,
  docDashboardDataAction,
  docDashboardupcomingAppointmentsAction,
  docDashboardPreviousAppointmentsAction,
  docViewAppointmentAction,
  docHelpRequestListAction,
  helpSubmitRequestAction,
  docViewHelpRequestAction,
  changePwdDocAction,
  docEditProfileAction,
  docCardListAction,
  docAddPaymentCardAction,
  docDeleteCardAction,
  docEditCardAction,
  docTermsAndConditionAction,
  docAboutUsAction,
  docLogoutAction,
  docWalletInWebAction,
  docSubscriptionInWebAction,
  docPresciptionListAction,
  docAddPrescriptionAction,
  docDeletePrescriptionAction,
  docViewPrescriptionAction,
  docEditPrescriptionAction,
  getStaticPageAction,
  testimonialsListAction,
  contactUsFormAction,
  newsLetterSubscribeAction,
  ourTeamListAction,
  editAvailabilityAction,
  addMedicalRecordAction,
  editMedicalRecordAction,
  getMedicalRecordAction,
  recommendationDataAction,
  doctorinvoicelistAction,
  doctorinvoiceViewAction,
  doctorinvoicelistFullAction,
  doctorReplyOnRequestAction,
  doctorUpgradeSubscriptionFromWalletAction,
  doctorPrivacyPolicyAction,
  doctorwithdrawRequestAction,
  docWalletHistoryAction,
};
