import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { commonAction, doctor } from "../../../../../redux/action";
import ToasterFunc from "../../../../common/toaster/Toaster";

function EditPerscription({ editPrescription, seteditPrescription, presId, docPrescriptionList }) {
  let dispatch = useDispatch();
  const [image, setImage] = useState("");
  const [imageErr, setimageErr] = useState("");
  const [Timetable, setTimetable] = useState({
    morning: false,
    night: false,
    afternoon: false,
    evening: false,
  });
  const [TimeTableErr, setTimeTableErr] = useState("");

  const CommonProfilePic = async (payload) => dispatch(commonAction.commonProfilePicAction(payload));
  const ViewPrescriptionInfo = async (payload) => dispatch(doctor.docViewPrescriptionAction(payload));
  const docEditPrescription = async (payload) => dispatch(doctor.docEditPrescriptionAction(payload));

  const PresViewData = useSelector((state) => state?.doctor?.doctorViewPrescription?.data);

  console.log("PresViewData", PresViewData);

  const formik = useFormik({
    initialValues: {
      name: "",
      quantity: "",
      number_of_days: "",
      description: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("*Required."),
      quantity: Yup.string().required("*Required."),
      number_of_days: Yup.string().required("*Required."),
      description: Yup.string().required("*Required."),
    }),
  });
  useEffect(() => {
    if (presId) {
      ViewPrescriptionInfo(presId);
    }
  }, [presId]);

  useEffect(() => {
    if (PresViewData) {
      formik.setFieldValue("name", PresViewData.name);
      formik.setFieldValue("quantity", PresViewData.quantity);
      formik.setFieldValue("number_of_days", PresViewData.number_of_days);
      formik.setFieldValue("description", PresViewData.description);
    }
    if (PresViewData && PresViewData.timing.length) {
      setTimetable({
        morning: PresViewData.timing.includes("Morning") ? true : false,
        afternoon: PresViewData.timing.includes("Afternoon") ? true : false,
        evening: PresViewData.timing.includes("Evening") ? true : false,
        night: PresViewData.timing.includes("Night") ? true : false,
      });
    }
  }, [PresViewData]);

  useEffect(() => {
    if (Timetable.morning || Timetable.afternoon || Timetable.evening || Timetable.night) {
      setTimeTableErr("");
    }
    if (image || PresViewData?.signature != "") {
      setimageErr("");
    }
  }, [Timetable, image, PresViewData]);

  const handleTimeChange = (e) => {
    let name = e.target.name;
    let checked = e.target.checked;
    if (name === "morning") {
      setTimetable({
        ...Timetable,
        morning: true,
      });
    }
    if (name === "afternoon") {
      setTimetable({
        ...Timetable,
        afternoon: true,
      });
    }
    if (name === "evening") {
      setTimetable({
        ...Timetable,
        evening: true,
      });
    }
    if (name === "night") {
      setTimetable({
        ...Timetable,
        night: true,
      });
    }
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    console.log(file);
    let formData = new FormData();
    formData.append("file", file);
    fileUpload(formData);
  };

  const fileUpload = async (image) => {
    const res = await CommonProfilePic(image);
    if (res && res.status == 200) {
      let picdata = res && res.data && res.data.result.url;
      setImage(picdata);
    }
  };

  const handleSubmit = async () => {
    if (!Timetable.morning && !Timetable.afternoon && !Timetable.evening && !Timetable.night) {
      setTimeTableErr("*Please Select atleast one");
    } else {
      setTimeTableErr("");
    }

    if (image === "") {
      setimageErr("*Required");
    } else {
      setimageErr("");
    }

    if (!formik.errors.name && !formik.errors.quantity && !formik.errors.number_of_days && !formik.errors.description) {
      if (Timetable.morning || Timetable.afternoon || Timetable.evening || Timetable.night) {
        formik.handleSubmit();
        let payload = {
          id: presId,
          name: formik.values.name,
          quantitty: formik.values.quantity,
          number_of_days: formik.values.number_of_days,
          description: formik.values.description,
          timing: [Timetable.morning ? "Morning" : "", Timetable.afternoon ? "Afternoon" : "", Timetable.night ? "Night" : "", Timetable.evening ? "Evening" : ""],
          signature: image ? image : PresViewData?.signature,
        };
        const res = await docEditPrescription(payload);
        if (res && res.status === 200) {
          console.log("res===>", res);
          ToasterFunc("success", res.data.responseMessage);
          seteditPrescription(false);
          // docPrescriptionList();
        }
      }
    }
  };

  console.log(Timetable.morning, Timetable.afternoon, Timetable.evening, Timetable.night);

  return (
    <Modal isOpen={editPrescription} className="modal-dialog-scrollable modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Edit Prescription</h4>
          <button type="button" onClick={() => seteditPrescription(false)} className="close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body add_prescription">
          <form onSubmit={formik.handleSubmit}>
            <div className="row">
              <div className="col-lg-12 ">
                <div className="invoice-content mb-2">
                  <div className="row form-row">
                    <div className="col-12 col-md-12">
                      <div className="form-group">
                        <label> Name</label>
                        <input type="text" maxLength="62" name="name" className={formik.errors.name ? "form-control is-invalid" : "form-control"} {...formik.getFieldProps("name")} />
                        {formik.touched.name && formik.errors.name ? <span className="error">{formik.errors.name}</span> : null}
                      </div>
                    </div>
                    <div className="col-6 col-md-6">
                      <div className="form-group">
                        <label>Quantity</label>
                        <input type="number" name="quantity" maxLength={3} className={formik.errors.quantity ? "form-control is-invalid" : "form-control"} {...formik.getFieldProps("quantity")} />
                        {formik.touched.quantity && formik.errors.quantity ? <span className="error">{formik.errors.quantity}</span> : null}
                      </div>
                    </div>
                    <div className="col-6 col-md-6">
                      <div className="form-group">
                        <label>Days</label>
                        <input type="number" maxLength={3} name="number_of_days" className={formik.errors.number_of_days ? "form-control is-invalid" : "form-control"} {...formik.getFieldProps("number_of_days")} />
                        {formik.touched.number_of_days && formik.errors.number_of_days ? <span className="error">{formik.errors.number_of_days}</span> : null}
                      </div>
                    </div>
                    <div className="col-12 col-md-12">
                      <div className="form-group">
                        <label for="description">Description</label>
                        <textarea id="description" maxLength={120} name="description" className={formik.errors.description ? "form-control is-invalid" : "form-control"} rows="3" {...formik.getFieldProps("description")}></textarea>
                        {formik.touched.description && formik.errors.description ? <span className="error">{formik.errors.description}</span> : null}
                      </div>
                    </div>

                    <div className=" col-md-12">
                      <div className="form-group mb-0">
                        <label>Time</label>
                        <div className="time_bx">
                          <label className="form-check-label" htmlFor="morning">
                            <input className="form-check-input" checked={Timetable.morning ? true : false} value="morning" id="morning" type="checkbox" name="morning" onChange={(e) => handleTimeChange(e)} />
                            Morning
                          </label>
                          <label className="form-check-label" htmlFor="afternoon">
                            <input className="form-check-input" checked={Timetable.afternoon ? true : false} type="checkbox" value="afternoon" id="afternoon" name="afternoon" onChange={(e) => handleTimeChange(e)} />
                            Afternoon
                          </label>
                          <label className="form-check-label" htmlFor="evening">
                            <input className="form-check-input" type="checkbox" checked={Timetable.evening ? true : false} id="evening" value="evening" name="evening" onChange={(e) => handleTimeChange(e)} /> Evening
                          </label>
                          <label className="form-check-label" htmlFor="night">
                            <input className="form-check-input" checked={Timetable.night ? true : false} type="checkbox" value="night" id="night" name="night" onChange={(e) => handleTimeChange(e)} /> Night
                          </label>
                        </div>
                        {TimeTableErr ? <div className="error">{TimeTableErr}</div> : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
          <div className="row">
            <div className="col-md-12">
              <div className={`signature mt-3 mb-0 ${imageErr ? "required" : ""}`}>
                <label htmlFor="signature">
                  <figure>
                    {image || PresViewData?.signature ? <img src={image ? image : PresViewData?.signature} className="img-fluid" alt="" /> : <>Click here to add your signature.</>}
                    <input type="file" id="signature" name="signature" accept="image/*" hidden onChange={(e) => handleImage(e)} />
                  </figure>
                </label>
              </div>
              {imageErr ? <span className="error">{imageErr}</span> : ""}
            </div>
          </div>
        </div>
        <div className="modal-footer text-center">
          <button className="btn book-btn w-25 mr-1" type="submit" onClick={handleSubmit}>
            Submit
          </button>
          <button className="btn view-btn w-25" onClick={() => seteditPrescription(false)}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
}

export default EditPerscription;
