import React, { useEffect, useState ,useRef, useContext } from "react";
import { IMAGE_CONSTANT } from "../../../constants";
import moment from "moment";
import { SocketContext } from "../../..";
import ToasterFunc from "../../common/toaster/Toaster";
import { useDispatch } from "react-redux";
import { commonAction } from "../../../redux/action";
import pdfIcon from '../../../assets/img/pdfIcon.png';
// import socket from "../../../socket/socket";

function ChatRight({ ReceiverDetails, IdData , doctorId , userDetails , ChatlistOfDoc , getChatList}) {
  const [RceiverStatus, setRceiverStatus] = useState({});
  const [Msgtext, setMsgtext] = useState("");
  const dispatch  = useDispatch();
  const [chatHistoryList, setchatHistoryList] = useState([]);
  const [IsMessageShow, setIsMessageShow] = useState('');
  const [Isloader, setIsloader] = useState(false);
  const [fileUrl , setFileUrl ] = useState('');
  const [FileData, setFileData] = useState({});
  const commonUploadedFile = async (payload) => dispatch(commonAction.commonUploadedFileAction(payload));

  // const [socket, setSocket] = useState(socketData);
  const socket  = useContext(SocketContext)

  const chatContainer = useRef(null);

  useEffect(() => {
    getMessage();
    return ()=>{
      socket.on("disconnect", () => {
        console.log("socket disconnected");
      });
    }
  }, [socket])

  const connectRoom =  ()=>{
    console.log('ReceiverDetails?.chatId' ,ReceiverDetails?.chatId);
    if (ReceiverDetails?.chatId){
      socket.emit('join_room' ,ReceiverDetails?.chatId);
    }
 }

 useEffect(() => {
  console.log('FileData',FileData);
 }, [FileData]);
 
  

  const sendMessage = () => {
    if (ReceiverDetails?._id || userDetails?.state?.receiverid ){
      if (Msgtext.trim()){
    const payload = {
      senderId: doctorId ,
      // receiverId: userDetails?.state?.receiverid ? userDetails?.state?.receiverid : ReceiverDetails?._id,

      receiverId: ReceiverDetails?._id ? ReceiverDetails?._id : userDetails?.state?.receiverid,
      type: 0,
      message: Msgtext,
      'room' : ReceiverDetails?.chatId
    };
    if (fileUrl){
      payload['type'] = 1;
  }
    console.log('payload',payload);
    socket.emit("sendMessage", payload);
    getChatList()
    setMsgtext('');
    setFileUrl('');
    getMessage();
     }
   }
  };

  // Function declare and define for remove selected file 
  const removeFile = ()=>{
    setFileUrl('');
    setMsgtext('');
  }
  const handleChange = (event)=>{
    setFileUrl('');
    setMsgtext('');
    const file  = event.target.files[0];
    let formData = new FormData();     
    if (file) {
      const fileSize = file.size / 1024 / 1024;
      if (fileSize > 2) {
        ToasterFunc("error", "Please upload a file smaller than 2 MB");
      } else {
        setFileData(file);
        formData.append("file", file);
        fileUpload(formData);
      }
    }
}
const fileUpload = async (image) => {
   setIsloader(true);
   const res = await commonUploadedFile(image);
   if (res && res.status == 200) {
    const file  = res?.data?.result?.url;
    setFileUrl(file);
    setIsloader(false);
    setMsgtext(file);
   }
 };
  const getMessage =  () => {
    connectRoom();
    socket.on("msgReceived", (data) => {
      console.log('msgReceived data',data);
      if (data.length && typeof data === 'object'){
        let chatList = [...data];
        let reverseChat = chatList.reverse();
        setchatHistoryList(reverseChat);
    }else{
      if (data && typeof data === 'string' ){
          setIsMessageShow(data);
      }
    }
    });
    if (IsMessageShow){
      ToasterFunc('error',IsMessageShow);
    }
  
  };
  const scrollToMyRef = () => {
    const scroll =
      chatContainer.current.scrollHeight -
      chatContainer.current.clientHeight;
    chatContainer.current.scrollTo(0, scroll);
  };
  useEffect(() => {
    getReceiverStatus();
  }, []);
  useEffect(() => {
     if (ReceiverDetails?.chatId){
         setchatHistoryList([])
         getChatHistory()
     }
  }, [ReceiverDetails?.chatId]);

  useEffect(() => {
     console.log('chatHistoryList',chatHistoryList);
     if (chatHistoryList.length){
       scrollToMyRef()
     }
  }, [chatHistoryList])
  
  

  const getReceiverStatus = () => {
    const payload = {
      senderId: ReceiverDetails?._id,
    };
    socket.emit("chatList", payload);
    getUserOnlineStatus();
  };

  const getUserOnlineStatus = () => {
    socket.on("userOnlineStatus", (arg) => {
      console.log(arg); // world
      setRceiverStatus(arg);
    });
  };

  const getChatHistory = () => {
    let chatID = '';
    if (ReceiverDetails?.chatId){
      chatID = ReceiverDetails?.chatId
    }
    const payload = {
      chatId: chatID
    };
    console.log('chatDetails payload',payload);
    socket.emit("chatDetails", payload);
    getMessage();
  };

  const onkeyupFun = (event)=>{
    if (event.key === "Enter") {
        sendMessage();
    }
  }

  useEffect(() => {
    var interval = setInterval(() => {
      getChatList();
      getMessage();
    }, 2000);
    return ()=>{
      clearInterval(interval);
      socket.on("disconnect", () => {
        console.log("socket disconnected");
      });
    }
  }, [socket])

  return (
    <>
    { ReceiverDetails?.chatId || (userDetails?.state?.path === "Through_appointmentdetails") ?
    <div className="chat-cont-right">
      <div className="chat-header justify-content-between">
     
        <div className="media">
          <div className="media-img-wrap">
          {/* RceiverStatus?.status === 1 ? "avatar avatar-online" : "avatar avatar-away" */}
            <div className={RceiverStatus?.status === 1 ? "avatar" : "avatar "}>
              <img src={ReceiverDetails?.profilePic ? ReceiverDetails?.profilePic : 
                userDetails?.state?.patientData?.profilePic  ? userDetails?.state?.patientData?.profilePic  : IMAGE_CONSTANT.DOC_PROFILE     } alt="" className="avatar-img rounded-circle mb-1" />
            </div>
          </div>
        {   <div className="media-body">
            <div className="user-name">{`${ReceiverDetails?.firstName || userDetails?.state?.patientData?.firstName ? userDetails?.state?.patientData?.firstName ? userDetails?.state?.patientData?.firstName : ReceiverDetails?.firstName : ""} ${ReceiverDetails?.lastName ? ReceiverDetails?.lastName : ""}`}</div>
            {/* <div className="user-status">{RceiverStatus?.status === 1 ? "Online" : "Offline"}</div> */}
          </div>}
        </div>
        {/* <div>
          <a href="video-call.html">
            {" "}
            <i className="fas fa-video fa-lg mr-3"></i>
          </a>
          <a href="voice-call.html">
            {" "}
            <i className="fa fa-phone fa-lg"></i>
          </a>
        </div> */}
      </div>
      <div className="chat-body">
        <div className="chat-scroll" ref={chatContainer}>
          <ul className="list-unstyled" >
          {chatHistoryList?.length ? chatHistoryList.map((item , idx )=>{
            return (
              <React.Fragment key={idx}>
               { item?.receiverId?._id === ReceiverDetails?._id ? 
            <li key={idx} className="media sent">
              <div className="media-body">
                <div className="msg-box">
                  <div>
                      {item?.type === 0 ? <p>{item?.message}</p> :
                      item?.message.includes('pdf') ? 
                        <a href={item?.message} target = "_blank">
                            <img src={pdfIcon} alt = "pdfIcon" className="pdfIcon"/>
                       
                        </a> :
                        <a href={item?.message} target = "_blank"> 
                           <img src={item?.message} alt="chatImage" /> 
                        </a>
                      }
                    <ul className="chat-msg-info">
                      <li>
                        <div className="chat-time">
                        <span>{item?.updatedAt.slice(0 , 10 ) + ' ' + moment(item?.updatedAt).format('LT')} </span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </li> : 
             <li className="media received">
              <div className="avatar">
                <img src={item?.senderId?.profilePic ? item?.senderId?.profilePic : IMAGE_CONSTANT.DOC_PROFILE} alt="" className="avatar-img rounded-circle mb-1" />
              </div>
              <div className="media-body">
                <div className="msg-box">
                  <div>
                  {item?.type === 0 ? <p>{item?.message}</p> :
                      item?.message.includes('pdf') ? 
                        <a href={item?.message} target = "_blank">
                            <img src={pdfIcon} alt = "pdfIcon" className="pdfIcon"/>
                       
                        </a> :
                        <a href={item?.message} target = "_blank"> 
                           <img src={item?.message} alt="chatImage" /> 
                        </a>
                      }
                    <ul className="chat-msg-info">
                      <li>
                        <div className="chat-time">
                        <span>{item?.updatedAt && item?.updatedAt.slice(0 , 10 ) + ' ' + item?.updatedAt &&  moment(item?.updatedAt).format('LT')}</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                
                {/* <div className="msg-box">
                  <div>
                    <div className="chat-msg-attachments">
                      <div className="chat-attachment">
                        <img src={IMAGE_CONSTANT.PLACE_HOLDERPIC} alt="Attachment" />
                        <div className="chat-attach-caption">placeholder.jpg</div>
                        <a href="#" className="chat-attach-download">
                          <i className="fas fa-download"></i>
                        </a>
                      </div>
                      <div className="chat-attachment">
                        <img src={IMAGE_CONSTANT.PLACE_HOLDERPIC} alt="Attachment" />
                        <div className="chat-attach-caption">placeholder.jpg</div>
                        <a href="#" className="chat-attach-download">
                          <i className="fas fa-download"></i>
                        </a>
                      </div>
                    </div>
                    <ul className="chat-msg-info">
                      <li>
                        <div className="chat-time">
                          <span>8:41 AM</span>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div> */}
              </div>
            </li> }
              
               </React.Fragment>
            )
          }) : null }
           
           
           
          </ul>
        </div>
      </div>
      <div className="chat-footer">
        <div className="input-group">
          <div className="input-group-prepend">
            <div className="btn-file btn">
              <i className="fa fa-paperclip"></i>
              <input type="file"  onChange={handleChange} 
              accept='image/jpg, image/jpeg , image/png  , application/pdf' />
            </div>
          </div>
          <div className="chat_prev_box">
            <div className='imagePreview'>
              { fileUrl ? 
              <span className='remove' onClick={removeFile} aria-hidden="true">&times;</span>
              : null   
            }
            
                {fileUrl ? FileData.type === 'application/pdf' ?
                 <span> <img src={pdfIcon} alt="chatImage" className="img-fluid pdfIcon" /> </span>
                  :<span> <img src={fileUrl} className="img-fluid" alt="chatImage" /> </span>:
                  <input type="text" value={Msgtext} onKeyUp={onkeyupFun}
                    className="input-msg-send form-control" placeholder="Type something" onChange={(e) => setMsgtext(e.target.value)} />
                }
              
            </div>
         
       
          
          {Msgtext ? (
            <div className="input-group-append">
              <button type="button" className="btn msg-send-btn" onClick={sendMessage}>
                <i className="fab fa-telegram-plane"></i>
              </button>
            </div>
          ) : (
            ""
          )}
           </div>
        </div>
      </div>
    </div> :
    
        <div className="chat-cont-right">
          <div className="card calls-box-card">
            <div>
              <h3>Click on the chat to check message received from recipients</h3>
            </div>
          </div>
        </div>
                        
                        }
    </>
   
  );
}

export default ChatRight;
