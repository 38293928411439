import moment from "moment";

export const DAYS = [
  {
    fullname: "Sun",
    day: "Sun",
    isChecked: false,
    startTime: null,
    endTime: null,
    slots: [],
  },
  {
    fullname: "Mon",
    day: "Mon",
    isChecked: false,
    startTime: null,
    endTime: null,
    slots: [],
  },
  {
    fullname: "Tue",
    day: "Tue",
    isChecked: false,
    startTime: null,
    endTime: null,
    slots: [],
  },
  {
    fullname: "Wed",
    day: "Wed",
    isChecked: false,
    startTime: null,
    endTime: null,
    slots: [],
  },
  {
    fullname: "Thu",
    day: "Thu",
    isChecked: false,
    startTime: null,
    endTime: null,
    slots: [],
  },
  {
    fullname: "Fri",
    day: "Fri",
    isChecked: false,
    startTime: null,
    endTime: null,
    slots: [],
  },
  {
    fullname: "Sat",
    day: "Sat",
    isChecked: false,
    startTime: null,
    endTime: null,
    slots: [],
  },
];

export const TIME_INTERVEL = [
  { time: "15 Mins", value: 15 },
  { time: "30 Mins", value: 30 },
  { time: "45 Mins", value: 45 },
  { time: "1 Hour", value: 60 },
];

export const convertToUTC = (time) => {
  return moment.utc(new Date(moment(time, ["HH,mm"]))).format("hh:mm A");
};

export const convertUTCToLocal = (time) => {
  return new moment.utc(time, ["HH:mm A"]).local().format("hh:mm A");
};

export const convertUTCToLocalForInput = (time) => {
  return new moment.utc(time, ["HH:mm A"]).local().format("HH:mm");
};

export const getTime = (time) => {
  return new moment(time, ["hh:mm A"]).format("HH:mm");
};

export const getTime2 = (time) => {
  return new moment(time, ["HH:mm"]).format("hh:mm A");
};

export const checkTime = (time) => {
  return moment() > moment(time, ["hh:mm A"]);
};
