export default {
  SIGN_UP: "SIGN_UP",
  SIGN_IN: "SIGN_IN",
  DOCTOR_SUBSCRIPTION_LIST: "DOCTOR_SUBSCRIPTION_LIST",
  PATIENT_SUBSCRIPTION_LIST: "PATIENT_SUBSCRIPTION_LIST",
  COUPON_LIST: "COUPON_LIST",
  PRIMARY_SPECIALITY_LIST: "PRIMARY_SPECIALITY_LIST",
  SECONDARY_SPECIALITY_LIST: "SECONDARY_SPECIALITY_LIST",
  PATIENT_SUBSCRIPTION_TAX : "PATIENT_SUBSCRIPTION_TAX",
  DOCTOR_SUBSCRIPTION_TAX : "DOCTOR_SUBSCRIPTION_TAX",

};
