import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import PaymentOptionModal from "./PaymentOptionModal";
import UpgradeCoupon from "./UpgradeCoupon";
import UpgradePayment from "./UpgradePayment";
import Payment from "../../authentication/paymentModal/Payment";
import BookAppWithCardPayment from "./BookAppWithCard.js/BookAppWithCardPayment";
import moment from "moment";
import { useDispatch } from "react-redux";
import { patient } from "../../../redux/action";
import ReactStars from "react-stars";
import stripeService from "../../../services/stripeService/stripeService";
import ToasterFunc from "../../common/toaster/Toaster";

function AppointmentDetail({ }) {
  
  const [CouponModal, setCouponModal] = useState(false);
  // const [PaymentOption, setPaymentOption] = useState(false)
  const dispatch = useDispatch();
  
  const [PaymentModal, setPaymentModal] = useState(false);
  const [couponAmount, setCouponAmount] = useState(null);
  let pathname = useLocation();
  const [AppPrice, setAppPrice] = useState(pathname?.state?.docDetail?.serviceCharge || 0);
  console.log("pathname-docdata", pathname);

  const cardListInPat = async () => dispatch(patient.cardListInPatAction());

  useEffect(() => {
    cardListInPat();
  }, []);

  const handleCheckOut = async() => {
    // setPaymentOption(!PaymentOption)
    
    console.log("Comingggggggggggggggggggggggggggg");
    // setPaymentModal(!PaymentModal);
    setAppPrice(couponAmount ? `${Number(pathname?.state?.docDetail?.serviceCharge) - couponAmount}` : Number(pathname?.state?.docDetail?.serviceCharge));
    // handlePayment(val)
    // console.log({ val })
    // note//
    // let paymentData = {
    //   // subscriptionId: SubscriptionId,
    //   price: AppPrice ? AppPrice : "",
    //   sessionType: 'UPGRADE_SUBSCRIPTION'
    //   // subscriptionId : SubscriptionType
    // }
    let paymentPayload = {
      // cardHolderName: formik.values.fullname,
      // cardType: CardType.credit ? "CREDIT" : "DEBIT",
      // cardNumber: formik.values.CardNum.replaceAll(" ", ""),
      // exp_month: formik.values && formik.values.cardexpiry ? formik.values.cardexpiry.split("/")[0] : "",
      // exp_year: formik.values && formik.values.cardexpiry ? formik.values.cardexpiry.split("/")[1] : "",
      // cvc: formik.values.cvv,
      price: AppPrice ? AppPrice : "",
      serviceProviderId: pathname?.state?.otherDetail?.serviceProviderId,
      slotTime: pathname?.state?.otherDetail?.slotTime,
      dayId: pathname?.state?.otherDetail?.dayId,
      slotId: pathname?.state?.otherDetail?.slotId,
      bookingDate: pathname?.state?.otherDetail?.bookingDate,
      appointmentStartTime: pathname?.state?.otherDetail?.appointmentStartTime,
      appointmentEndTime: pathname?.state?.otherDetail?.appointmentEndTime,
      reason: pathname?.state?.otherDetail?.reason,
      document: pathname?.state?.otherDetail?.document,
      // cardSave: isSaved ? true : false,
    };
    // if (paymentData) {
    //   paymentData.couponId = couponId?._id;
    // }

    // create checkout session return url
    let res = await stripeService.createSessionCheckoutForAppointment(paymentPayload)
    if (res && res.status == 200) {
      if (res.data && res.data.url) {
        window.open(res.data?.url, "_self");
      } else {
        ToasterFunc("info", res.data?.message);
      }
    } else {
      ToasterFunc("error", res.err);
    }
  };

  // const createSession = async (val) => {
    
  // };
  console.log("pathnameData", pathname && pathname.state);
  return (
    <div class="col-lg-8 col-xl-9 appointments pat-appointment-detail">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Appointment Details </h4>
        </div>
        <div class="card-body ">
          <div class="tab-content pt-0">
            <div role="tabpanel" id="Appointments-d" class="tab-pane fade show active appointments">
              <div class="appointment-list">
                <div class="profile-info-widget">
                  <a class="booking-doc-img">
                    <img src={pathname && pathname.state && pathname.state?.docDetail && pathname.state?.docDetail && pathname.state?.docDetail?.profilePic} alt="User Image" />
                  </a>
                  <div class="profile-det-info">
                    <h3>
                      <a>{pathname && pathname.state && pathname.state?.docDetail && pathname.state?.docDetail?.fullName}</a>
                    </h3>
                    <div class="patient-details">
                      <h5>
                        ${pathname && pathname.state && pathname.state?.docDetail && pathname.state?.docDetail && pathname.state?.docDetail?.serviceCharge}
                        Consultation Fee
                      </h5>
                      <h5>
                        {pathname && pathname.state && pathname.state.docDetail && pathname.state.docDetail && pathname.state.docDetail.experience_in_years ? `${pathname.state.docDetail.experience_in_years} Years  ` : ""}
                        {pathname && pathname.state && pathname.state.docDetail && pathname.state.docDetail && pathname.state.docDetail.experience_in_months ? `${pathname.state.docDetail.experience_in_months} Months` : ""}
                        of Experience
                      </h5>
                      <a href="#" data-toggle="modal" data-target="#speciality-popup" data-dismiss="modal">
                        <h6>{pathname && pathname.state && pathname.state.docDetail && pathname.state.docDetail && pathname.state.docDetail.serviceProviderType[0]?.primary_specialty}</h6>
                      </a>
                      <div class=" rating ">
                        <ReactStars edit={false} count={pathname?.state?.serviceProviderId?.rating} value={pathname?.state?.serviceProviderId?.rating} size={16} color2={"#ffd700"} />
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <i className="fa fa-bookmark"></i>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h4>
                    <b>Appointment details</b>
                  </h4>
                  <div>
                    <span class="color-grey ">
                      <b>Date:</b> {new Date(pathname?.state?.otherDetail && pathname?.state?.otherDetail?.appointmentStartTime).toLocaleDateString()}
                    </span>
                  </div>
                  <div>
                    <span class="color-grey">
                      <b>Time:</b>
                      {moment(pathname && pathname.state && pathname.state.otherDetail && pathname.state.otherDetail.appointmentStartTime).format("LT")} -{" "}
                      {moment(pathname && pathname.state && pathname.state.otherDetail && pathname.state.otherDetail.appointmentEndTime).format("LT")}
                      {/* {`${
                        pathname && pathname.state && pathname.state.otherDetail && pathname.state.otherDetail.appointmentStartTime
                          ? `${new Date(pathname.state.otherDetail.appointmentStartTime).toLocaleTimeString().split(":")[0]}:${new Date(pathname.state.otherDetail.appointmentStartTime).toLocaleTimeString().split(":")[1]} ${
                              new Date(pathname.state.otherDetail.appointmentStartTime).toLocaleTimeString().split(" ")[1]
                            }`
                          : ""
                      } -
                                ${
                                  pathname && pathname.state && pathname.state.otherDetail && pathname.state.otherDetail.appointmentEndTime
                                    ? `${new Date(pathname.state.otherDetail.appointmentEndTime).toLocaleTimeString().split(":")[0]}:${new Date(pathname.state.otherDetail.appointmentEndTime).toLocaleTimeString().split(":")[1]} ${
                                        new Date(pathname.state.otherDetail.appointmentEndTime).toLocaleTimeString().split(" ")[1]
                                      }`
                                    : ""
                                }`} */}
                    </span>
                  </div>
                  <h4 class="mt-4 ">
                    <b>Reason for Appointment</b>
                  </h4>
                  <div class="mb-4">
                    <span class="color-grey ">{pathname && pathname.state && pathname.state.otherDetail && pathname.state.otherDetail.reason}.</span>
                  </div>
                  <h4 class="mt-4 ">
                    <b>Medical History</b>
                  </h4>
                  <div class="card">
                    <div class="card-body">
                      {pathname && pathname.state && pathname.state.otherDetail && pathname.state.otherDetail.document ? (
                        <React.Fragment>
                          <a href={pathname.state.otherDetail.document}>
                            <div>
                              <span>
                                <i class="fa fa-file-pdf fa-3x"></i>
                              </span>
                            </div>
                          </a>
                        </React.Fragment>
                      ) : (
                        <div>
                          <span>No attachments found.</span>
                        </div>
                      )}
                    </div>
                  </div>
                  <h4 class="mt-4 ">
                    <b>Bill Details</b>
                  </h4>
                  <div class="invoice-item invoice-table-wrap">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="invoice-table table table-bordered">
                            <thead>
                              <tr>
                                <th>Description</th>
                                <th class="text-right">Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Consultation Fee</td>
                                <td class="text-right">${pathname && pathname.state && pathname.state.docDetail && pathname.state.docDetail.serviceCharge}</td>
                              </tr>
                              <tr>
                                <td>Tax </td>
                                <td class="text-right">0</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="col-md-6 col-xl-4 ml-auto">
                        <div class="table-responsive">
                          <table class="invoice-table-two table">
                            <tbody>
                              <tr>
                                <th>Subtotal:</th>
                                <td>
                                  <span>${`${pathname && pathname.state && pathname.state.docDetail && Number(pathname.state.docDetail.serviceCharge)}`}</span>
                                </td>
                              </tr>
                              {/* <tr>
                                <th>Coupon:</th>
                                <td>
                                  <a onClick={() => !couponAmount && setCouponModal(!CouponModal)}>{!couponAmount ? "Apply Coupon" : `$${couponAmount}`}</a>
                                </td>
                              </tr> */}
                              <tr>
                                <th>Total :</th>
                                <td>
                                  <span>{`$${Number(pathname?.state?.docDetail?.serviceCharge) - couponAmount}`}</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center mb-2 mt-5">
                    <button type="button" class="btn btn-primary" onClick={handleCheckOut}>
                      Checkout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpgradeCoupon CouponModal={CouponModal} setCouponModal={setCouponModal} amount={pathname?.state?.docDetail?.serviceCharge} setCouponAmount={setCouponAmount} couponAmount={couponAmount} />
      {PaymentModal ? <BookAppWithCardPayment PaymentModal={PaymentModal} setPaymentModal={setPaymentModal} AppAndDocInfo={pathname && pathname.state} AppPrice={AppPrice} /> : null}
    </div>
  );
}

export default AppointmentDetail;
