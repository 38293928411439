import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../../../../constants";
import { patient } from "../../../../../redux/action";
import ToasterFunc from "../../../../common/toaster/Toaster";

function ChangePwd() {
  const dispatch = useDispatch();

  const history = useHistory();

  const changePass = async (payload) =>
    dispatch(patient.changePasswordAction(payload));
  const [state, setState] = useState({
    oldPass: "",
    newPass: "",
    confirmPass: "",
  });

  const [showError, setShowError] = useState(false);
  const handleSubmit = async () => {
    console.log(state);
    if (
      !state.oldPass &&
      !state.newPass &&
      !state.confirmPass &&
      state.newPass === state.confirmPass
    ) {
      setShowError(true);
    } else {
      setShowError(false);
      const payload = {
        oldPassword: state.oldPass,
        newPassword: state.newPass,
        confirmPassword: state.confirmPass,
      };

      const res = await changePass(payload);
      console.log("@@@res", res);
      if (res.status == 200) {
        ToasterFunc("success", "Password change successfully.");
        goBack();
      }
    }
  };

  const goBack = () => {
    history.push(ROUTES_CONSTANTS.PATIENT_DASHBOARD);
  };

  const handleChange = (e) => {
    setState({
      ...state,
      [e.target.id]: e.target.value,
    });
  };

  return (
    <div role="tabpanel" id="Change_password" class="tab-pane fade show active">
      <div class="card">
        <div class="card-body">
          <form>
            <div class="form-group">
              <label>Old Password</label>
              <input
                value={state.oldPass}
                id="oldPass"
                type="password"
                class="form-control"
                onChange={handleChange}
              />
              {showError && !state.oldPass && (
                <label className='validation_error'>*Please enter password.</label>
              )}
            </div>
            <div class="form-group">
              <label>New Password</label>
              <input
                value={state.newPass}
                id="newPass"
                type="password"
                class="form-control"
                onChange={handleChange}
              />
              {showError && !state.newPass && (
                <label className='validation_error'>*Please enter password.</label>
              )}
            </div>
            <div class="form-group">
              <label>Confirm Password</label>
              <input
                value={state.confirmPass}
                type="password"
                id="confirmPass"
                class="form-control"
                onChange={handleChange}
              />
              {showError && !state.confirmPass && (
                <label className='validation_error'>*Please enter password.</label>
              )}
              {state.confirmPass && state.confirmPass !== state.newPass && (
                <label className='validation_error'>*Confirm password and new password must me same.</label>
              )}
            </div>
            <div class="submit-section text-center">
              <div
                onClick={handleSubmit}
                class="btn btn-primary mb-bottm"
              >
                Save
              </div>
              <div onClick={goBack} class="btn btn-primary">
                Cancel
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default ChangePwd;
