import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../../constants";
import { useEffect } from "react";
import { patient } from "../../../../redux/action";
import moment from "moment";

function PatientHelp() {
  let dispatch = useDispatch();
  let history = useHistory();
  useEffect(() => {
    window.scrollTo(0, 0);
    patientHelplist();
  }, []);
  const patientHelplist = async () => dispatch(patient.patientHelplistAction());
  const patientHelplistData = useSelector((state) => state?.patient?.helplistInfo?.helpData);
  console.log("patientHelplistData", patientHelplistData);

  const handleView = (item) => {
    history.push(ROUTES_CONSTANTS.PATIENT_VIEW_HELP_REQUEST, item);
  };
  return (
    <div class=" col-lg-8 col-xl-9 content_ipad_p">
      <div class="card footer-gap">
        <div class="card-header align-button">
          <h4>Help</h4>
          <button onClick={() => history.push(ROUTES_CONSTANTS.PATIENT_ADD_NEW_HELP_REQUEST)} class="add-new-btn ">
            Submit New request
          </button>
        </div>
        <div class="card-body">
          {patientHelplistData?.length ? (
            patientHelplistData.map((item, index) => (
              <a onClick={() => handleView(item)}>
                <div class="card">
                  <div class="card-body">
                    <div class="help-query d-flex justify-content-between align-items-center">
                      <div class="d-flex align-items-center">
                        {" "}
                        <img class="avatar rounded-circle" alt="User Image" src={item?.userId?.profilePic} />
                        <span class="ml-2">
                          <b>{`${item?.userId?.firstName} ${item?.userId?.lastName}`}</b>
                        </span>
                      </div>
                      <div>
                        <span class="default-content-clr">{moment(item?.createdAt).format("LT")}</span>
                      </div>
                    </div>
                    <span class="default-content-clr">{item?.message}</span>
                  </div>
                </div>
              </a>
            ))
          ) : (
            <div className="col-md-12">
              <p className="text-center">No help requests available.</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default PatientHelp;
