import moment from "moment";
import React, { useEffect, useState } from "react";
import PaginationComponent from "react-reactstrap-pagination";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { patient } from "../../../../redux/action";

function ViewMedicalHistory() {
  const dispatch = useDispatch();
  let { DocId } = useParams();
  const [pageNumber, setPageNumber] = useState(1);
  const medicalRecordListApi = async (payload) => dispatch(patient.medicalRecordList(payload));

  const medicalRecordListData = useSelector((state) => state?.patient?.patientMedicalRecordData);

  useEffect(() => {
    callApi();
  }, []);

  useEffect(() => {
    callApi();
  }, [pageNumber]);

  const callApi = () => {
    const payload = {
      id: DocId,
      pageNumber: pageNumber,
      limit: 10,
    };
    medicalRecordListApi(payload);
  };

  const handleSelected = (e) => {
    setPageNumber(e);
  };

  return (
    <div class=" col-lg-8 col-xl-9">
      {console.log(medicalRecordListData)}
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Medical Records</h4>
        </div>

        <div class="card-body">
          <div class="row">
            {medicalRecordListData?.data?.length ? (
              medicalRecordListData?.data.map((item) => {
                return (
                  <div class="col-md-6 col-lg-6">
                    <div class="card">
                      <div class="card-body">
                        <div class="d-flex justify-content-between align-items-center">
                          <div>
                            <div>
                              <span>
                                <b>{`Dr. ${item?.serviceProviderId?.fullName}`}</b>
                              </span>
                            </div>
                            <div>
                              <span class="color-grey">
                                <b>File Name :</b>
                                {item.name}
                              </span>
                            </div>
                            <div>
                              <span class="color-grey">{`Date : ${moment(item.createdAt).format("DD/MM/YYYY")}`}</span>
                            </div>
                          </div>
                          <div>
                            <span class="c-pointer">
                              <i class="fa fa-file-pdf fa-3x"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="text-center">
                      <a href={item.medicalRecordFile} class="btn btn-primary ">
                        <i class="fas fa-download  mr-1"></i>Download
                      </a>
                    </div>
                  </div>
                );
              })
            ) : (
              <div className="col-md-12">
                <p className="text-center">No medical records available.</p>
              </div>
            )}
          </div>
        </div>
      </div>
      {medicalRecordListData?.totalList > 10 && (
        <PaginationComponent
          totalItems={medicalRecordListData && medicalRecordListData.totalList}
          pageSize={10}
          maxPaginationNumbers={Math.ceil(medicalRecordListData && medicalRecordListData.totalList / 10)}
          defaultActivePage={1}
          onSelect={(e) => handleSelected(e)}
        />
      )}
    </div>
  );
}

export default ViewMedicalHistory;
