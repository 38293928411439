import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE_CONSTANT } from "../../../constants";
import { authentication, commonAction, doctor } from "../../../redux/action";
import * as Yup from "yup";
import ToasterFunc from "../../common/toaster/Toaster";
import SecondarySpecialityModule from "../../authentication/sign-up-as-doctor/SecondarySpecialityModule";
import defaultPic from "../../../assets/img/default-img.png";

function DocEditProfessional() {
  const dispatch = useDispatch();
  const [Image, setImage] = useState("");
  const [specialityArray, setSpecialityArray] = useState([]);
  const [secondaryError, setSecondaryError] = useState("");
  const [SecondSpeModule, setSecondSpeModule] = useState(false);

  const secondaryListCall = async (id) => dispatch(authentication.secondarySpecialityListAction(id));

  const CommonProfilePic = async (payload) => dispatch(commonAction.commonProfilePicAction(payload));
  const DoctorEditProfile = async (payload) => dispatch(doctor?.docEditProfileAction(payload));

  const DoctorData = useSelector((state) => state?.doctor?.doctorProdileInfo?.result);
  console.log("View Data", DoctorData);

  useEffect(() => {
    console.log("DoctorData", DoctorData);
    formik.setFieldValue("primarySpeciality", DoctorData && DoctorData[0].serviceProviderType && DoctorData[0].serviceProviderType[0].primary_specialty ? DoctorData[0].serviceProviderType[0].primary_specialty : "");
    formik.setFieldValue("licenceNumber", DoctorData && DoctorData[0].licenceNumber ? DoctorData[0].licenceNumber : "");
    formik.setFieldValue("year", DoctorData && DoctorData[0].experience_in_years ? DoctorData[0].experience_in_years : "");
    formik.setFieldValue("month", DoctorData && DoctorData[0].experience_in_months ? DoctorData[0].experience_in_months : "");
    formik.setFieldValue("serviceCharge", DoctorData && DoctorData[0].serviceCharge ? DoctorData[0].serviceCharge : "");
  }, [DoctorData]);

  const formik = useFormik({
    initialValues: {
      businessLogo: "",
      secondarySpeciality: "",
      licenceNumber: "",
      year: "",
      month: "",
      serviceCharge: "",
    },

    validationSchema: Yup.object({
      businessLogo: Yup.string().required("*Required"),
      primarySpeciality: Yup.string().required("*Required."),
      secondarySpeciality: Yup.string().required("*Required."),
      licenceNumber: Yup.number().required("*Required").typeError("*Please enter only digit"),
      year: Yup.number().required("*Required").typeError("*Please enter only digit"),
      month: Yup.number().required("*Required").typeError("*Please enter only digit"),
      serviceCharge: Yup.number().required("*Required").typeError("*Please enter only digit"),
    }),
  });

  const handleImage = (e) => {
    const file = e.target.files[0];
    console.log(file);
    let formData = new FormData();
    formData.append("file", file);
    fileUpload(formData);

    if (file.type.split("/")[0] !== "image") {
      ToasterFunc("success", "Only image files are accepted");
    } else {
      setImage(
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
    }
  };
  const fileUpload = async (image) => {
    const res = await CommonProfilePic(image);
    if (res && res.status == 200) {
      let picdata = res && res.data && res.data.result.url;
      setImage(picdata);
    }
  };
  const handleSecSpe = () => {
    if (formik.values.primarySpeciality) {
      setSecondSpeModule(!SecondSpeModule);
      setSecondaryError("");
    } else setSecondaryError("*Please select primary speciality first.");
  };

  useEffect(() => {
    secondaryListCall(DoctorData && DoctorData[0].primary_specialtyId);
  }, [DoctorData]);

  const handleUpdate = async () => {
    console.log(formik);
    if (specialityArray.length == 0) {
      setSecondaryError("*required.");
    } else {
      setSecondaryError("");
    }

    const payload = {
      businessLogo: Image ? Image : DoctorData[0].businessLogo,
      secondry_specialty: specialityArray ? specialityArray : DoctorData[0].specialityArray,
      licenceNumber: formik.values.licenceNumber ? formik.values.licenceNumber : DoctorData[0].licenceNumber,
      experience_in_years: formik.values.year ? formik.values.year : DoctorData[0].experience_in_years,
      experience_in_months: formik.values.month ? formik.values.month : DoctorData[0].experience_in_months,
      serviceCharge: formik.values.serviceCharge ? formik.values.serviceCharge : DoctorData[0].serviceCharge,
    };

    if ((Image && specialityArray && formik.values.licenceNumber !== "", formik.values.year !== "", formik.values.month !== "", formik.values.serviceCharge !== "")) {
      const res = await DoctorEditProfile(payload);
      if (res.status == 200) {
        ToasterFunc("success", res.data.responseMessage);
        console.log("res==>", res);
      }
    }
  };

  return (
    <div class=" col-lg-8 col-xl-9 ">
      <div class="card profes">
        <div class="card-header">
          <h4>Edit Professional Details</h4>
        </div>
        <div class="card-body">
          <form onSubmit={formik.handleSubmit}>
            <div class="row form-row">
              <div class="col-md-12">
                <div class="form-group">
                  <div class="change-avatar justify-content-center">
                    <div class="profile-round-img">
                    <figure className="mb-0">
                      <img src={Image ? Image : DoctorData && DoctorData[0]?.profilePic ? DoctorData[0]?.profilePic : defaultPic} alt="" />
                    </figure>
                      {/* <label for="camera">
                        <input type="file" id="camera" name="profilePic" accept="image/*" hidden onChange={(e) => handleImage(e)} />
                        <i class="fas fa-camera icon-camera"></i>
                      </label> */}
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Primary Speciality</label>
                  <input disabled type="text" {...formik.getFieldProps("primarySpeciality")} className="form-control" />
                  {/* <select
                    className="form-control"
                    name="primarySpeciality"

                    {...formik.getFieldProps("primarySpeciality")}
                    defaultValue={DoctorData && DoctorData[0].serviceProviderType && DoctorData[0].serviceProviderType[0].primary_specialty}
                  >
                    <option hidden>{DoctorData && DoctorData[0].serviceProviderType && DoctorData[0].serviceProviderType[0].primary_specialty ? DoctorData[0].serviceProviderType[0].primary_specialty : ''}</option>
                    
                  </select>
                  {formik.touched.primarySpeciality &&
                    formik.errors.primarySpeciality ? (
                    <span className="validation_error">
                      {formik.errors.primarySpeciality}
                    </span>
                  ) : null} */}
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <label>Secondry Speciality</label>
                  <input
                    type="text"
                    className="form-control "
                    // value="Select"
                    data-toggle="modal"
                    data-target="#secSpeciality_Modal"
                    data-dismiss="modal"
                    name="secondarySpeciality"
                    readOnly
                    hidden
                    value={specialityArray.length > 0 ? "Secondary Speciality" : "Select"}
                    onClick={handleSecSpe}
                  />
                  <div className="view_secondry_specialty" onClick={handleSecSpe}>
                    {DoctorData && DoctorData[0].specialties ? DoctorData[0].specialties.map((spec, i) => <span key={i}>{spec.secondry_specialty} </span>) : ""}
                  </div>
                  {/* <input type="text" class="form-control" placeholder="Orthodentist" /> */}
                  <span className="validation_error">{secondaryError}</span>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <input type="text" className="form-control" name="licenceNumber" maxLength="10" placeholder="Enter License Number" {...formik.getFieldProps("licenceNumber")} />
                  {formik.touched.licenceNumber && formik.errors.licenceNumber ? <span className="validation_error">{formik.errors.licenceNumber}</span> : null}
                </div>
              </div>
            </div>
            <div class="row form-group align-items-center">
              <div class="col-md-4 ">
                <label for="">Experience</label>
              </div>
              <div class="col-md-4 col-6">
                <div className="form-group">
                  <input type="text" className="form-control" name="year" maxLength="3" placeholder="Year" {...formik.getFieldProps("year")} />
                  {formik.touched.year && formik.errors.year ? <span className="validation_error">{formik.errors.year}</span> : null}
                </div>
              </div>
              <div class="col-md-4 col-6">
                <div className="form-group">
                  <input type="text" className="form-control" name="month" maxLength="3" placeholder="Month" {...formik.getFieldProps("month")} />
                  {formik.touched.month && formik.errors.month ? <span className="validation_error">{formik.errors.month}</span> : null}
                </div>
              </div>
            </div>
            <div className="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label> Enter service Charge </label>
                  <input type="text" className="form-control" name="serviceCharge" maxLength="4" placeholder="Service Charge" {...formik.getFieldProps("serviceCharge")} />
                  <div className="row">
                    <div className="col-md-7">{formik.touched.serviceCharge && formik.errors.serviceCharge ? <span className="validation_error">{formik.errors.serviceCharge}</span> : null}</div>
                    {/* <div className="col-md-5">
                      <span>
                        <i className="fa fa-info-circle mr-1"></i> Platform fee will be 20% of amount you entered
                      </span>
                    </div> */}
                  </div>
                </div>
              </div>
              <div class="btn-p">
                <button class="btn btn-primary " type="submit" onClick={handleUpdate}>
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <SecondarySpecialityModule SecondSpeModule={SecondSpeModule} setSecondSpeModule={setSecondSpeModule} specialityArray={specialityArray} setSpecialityArray={setSpecialityArray} />
    </div>
  );
}

export default DocEditProfessional;
