import { httpService } from "..";
import ToasterFunc from "../../components/common/toaster/Toaster";
import { API_CONSTANTS } from "../../constants";
import { getSessionStorageOrDefault } from "../../utils";
class RatingService {
  async getReviewList(pageNumber) {
    try {
      const userType = await getSessionStorageOrDefault("userType");
      const response = await httpService.get(
        `${
          userType == 1
            ? API_CONSTANTS.RATING_LIST
            : API_CONSTANTS.RATING_PAT_LIST
        }?pageNumber=${pageNumber}&limit=10`
      );
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async challangeReviews(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.DOCTOR_CHALLANGE_REVIEWS,
        data
      );
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
}

export default new RatingService();
