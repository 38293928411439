import Slider from "react-slick";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../constants";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import { toast, ToastContainer } from "react-toastify";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { doctor } from "../../redux/action";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";

function DashBoard() {
  let dispatch = useDispatch();
  let history = useHistory();
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    centerMode: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const DocDashboardData = useSelector((state) => state?.doctor?.doctorDasboardInfo?.result);
  // console.log("DocDashboardData",DocDashboardData);
  const recommendationdataa = useSelector((state) => state?.doctor?.recommendationListData);
  console.log("recommendationdataa", recommendationdataa);
  const recommendationDataList = async (payload) => dispatch(doctor.recommendationDataAction(payload));
  const upcomingAppointments = async () => dispatch(doctor.docDashboardupcomingAppointmentsAction());
  useEffect(() => {
    upcomingAppointments();
    console.log("DDD");
  }, []);

  useEffect(() => {
    DocDashboardInfo();
  }, []);

  const DocDashboardInfo = async () => {
    dispatch(doctor.docDashboardDataAction());
  };

  console.log("DocDashboardData.totalAppointment", DocDashboardData && DocDashboardData.totalAppointment);

  let UpcomingAppointsList = useSelector((state) => state?.doctor?.doctorDasboardUpcomingAppointment?.appointmentData);
  const [UpcomingAppoints, setUpcomingAppoints] = useState([]);
  useEffect(() => {
    getLocation();
  }, []);
  useEffect(async () => {
    console.log("UpcomingAppointsList", UpcomingAppointsList);
    if (UpcomingAppointsList && UpcomingAppointsList.length) {
      const filter = UpcomingAppointsList.filter((item) => item.status === "accepted");
      setUpcomingAppoints(filter);
    }
  }, [UpcomingAppointsList]);

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(async function (position) {
        callApi(position);
        console.log("position", position);
        console.log("Latitude is :", position.coords.latitude);
        console.log("Longitude is :", position.coords.longitude);

        console.log("accuracy", position.coords.accuracy);
      });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const callApi = (position) => {
    const payload = {
      lat: position.coords.latitude,
      long: position.coords.longitude,
    };
    recommendationData(payload);
  };

  const recommendationData = async (payload) => {
    const res = await recommendationDataList(payload);
    if (res && res.status == 200) {
      console.log("Got dataaaaaaaaaaaaaa", res);
    }
  };

  let today = new Date();
  const year = today.getFullYear();

  return (
    <div class="col-lg-8 col-xl-9 content_ipad_p">
      <div className="row">
        <div className="col-md-12">
          <div className="card dash-card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-6 col-lg-3 col-sm-6">
                  <div className="dash-widget dct-border-rht">
                    <div className="circle-bar circle-bar1">
                      <div className="circle-graph1" data-percent="100" style={{ width: 90, height: 90 }}>
                        {/* <h3>1500</h3> */}
                        <CircularProgressbar
                          text={DocDashboardData && DocDashboardData.totalAppointment ? DocDashboardData.totalAppointment : "0"}
                          styles={buildStyles({
                            // Rotation of path and trail, in number of turns (0-1)
                            rotation: 0.25,
                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: "butt",
                            // Text size
                            textSize: "26px",
                            // How long animation takes to go from one percentage to another, in seconds
                            pathTransitionDuration: 0.5,
                            // Can specify path transition in more detail, or remove it entirely
                            // pathTransition: 'none',

                            // Colors
                            pathColor: `#DA3F81`,
                            textColor: "#BE141B",
                            trailColor: "#d6d6d6",
                            backgroundColor: "#3e98c7",
                            counterClockwise: true,
                          })}
                        />
                      </div>
                    </div>
                    <div className="dash-widget-info">
                      <h6>Total Appoinments</h6>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-3 col-sm-6">
                  <div className="dash-widget dct-border-rht">
                    <div className="circle-bar circle-bar2">
                      <div className="circle-graph2" data-percent="100" style={{ width: 90, height: 90 }}>
                        {/* <h3>160</h3> */}
                        <CircularProgressbar
                          text={
                            DocDashboardData && DocDashboardData.totalUpcomingAppointment ? DocDashboardData.totalUpcomingAppointment : "0"
                          }
                          styles={buildStyles({
                            // Rotation of path and trail, in number of turns (0-1)
                            rotation: 0.25,

                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: "butt",

                            // Text size
                            textSize: "26px",

                            // How long animation takes to go from one percentage to another, in seconds
                            pathTransitionDuration: 0.5,

                            // Can specify path transition in more detail, or remove it entirely
                            // pathTransition: 'none',

                            // Colors
                            pathColor: `#68DDA9`,
                            textColor: "#BE141B",
                            trailColor: "#d6d6d6",
                            backgroundColor: "#3e98c7",
                          })}
                        />
                      </div>
                    </div>
                    <div className="dash-widget-info">
                      <h6>Upcoming Appoinments</h6>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-3 col-sm-6">
                  <div className="dash-widget dct-border-rht">
                    <div className="circle-bar circle-bar3">
                      <div className="circle-graph3" data-percent="100" style={{ width: 90, height: 90 }}>
                        {/* <h3>85</h3> */}
                        <CircularProgressbar
                          text={DocDashboardData && DocDashboardData.totalEarning ? DocDashboardData.totalEarning : "0"}
                          styles={buildStyles({
                            // Rotation of path and trail, in number of turns (0-1)
                            rotation: 0.25,

                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: "butt",

                            // Text size
                            textSize: "26px",

                            // How long animation takes to go from one percentage to another, in seconds
                            pathTransitionDuration: 0.5,

                            // Can specify path transition in more detail, or remove it entirely
                            // pathTransition: 'none',

                            // Colors
                            pathColor: `#1B5A90`,
                            textColor: "#BE141B",
                            trailColor: "#d6d6d6",
                            backgroundColor: "#3e98c7",
                          })}
                        />
                      </div>
                    </div>
                    <div className="dash-widget-info">
                      <h6>Total Earnings</h6>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-lg-3 col-sm-6">
                  <div className="dash-widget">
                    <div className="circle-bar circle-bar3">
                      <div className="circle-graph3" data-percent="100" style={{ width: 90, height: 90 }}>
                        {/* <h3>85</h3> */}
                        <CircularProgressbar
                          text={DocDashboardData && DocDashboardData.todayEarning ? DocDashboardData.todayEarning : "0"}
                          styles={buildStyles({
                            // Rotation of path and trail, in number of turns (0-1)
                            rotation: 0.25,

                            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
                            strokeLinecap: "butt",

                            // Text size
                            textSize: "26px",

                            // How long animation takes to go from one percentage to another, in seconds
                            pathTransitionDuration: 0.5,

                            // Can specify path transition in more detail, or remove it entirely
                            // pathTransition: 'none',

                            // Colors
                            pathColor: `#1B5A90`,
                            textColor: "#BE141B",
                            trailColor: "#d6d6d6",
                            backgroundColor: "#3e98c7",
                          })}
                        />
                      </div>
                    </div>
                    <div className="dash-widget-info">
                      <h6>Today's Earnings</h6>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- carousal  --> */}
      <div className="row">
        <div className="col-md-12">
          <h4 className="mb-4">{recommendationdataa?.length ? "Recommendations" : ""}</h4>
          <section className="section mb-2 custom-card">
            <div className="row">
              <div className="col-lg-12">
                <div className="doctor-slider recommendationSlide slider">
                  <Slider {...settings}>
                    {/* <!-- Doctor Widget --> */}
                    {recommendationdataa?.length
                      ? recommendationdataa.map((item, ind) => (
                          <div className="card widget-profile pat-widget-profile" key={ind}>
                            <div className="card-body">
                              <div className="pro-widget-content">
                                <div className="profile-info-widget">
                                  <div className="booking-doc-img">
                                    <img src={item?.userId?.profilePic} alt="" />
                                  </div>
                                  <div className="profile-det-info">
                                    <h3>{`${item?.userId?.firstName} ${item?.userId?.lastName}`}</h3>
                                    <div className="patient-details">
                                      <h5>Looking For {item?.specialtyId?.secondry_specialty}</h5>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="patient-info">
                                <ul>
                                  <li>
                                    <i className="fas fa-map-marker-alt"></i>{" "}
                                    <span>{item?.userId?.location?.address.split(",").slice(1).join(",")}</span>
                                  </li>
                                  <li>
                                    <i className="far fa-address-book"></i>
                                    <span>
                                      {year - (item?.userId?.date_of_birth ? item?.userId?.date_of_birth.split("/")[2] : " ")} Years,{" "}
                                      {item?.userId?.gender}
                                    </span>
                                  </li>
                                  <li>
                                    <i className="fas fa-burn"></i> <span>{item?.userId?.bloodGroup}</span>
                                  </li>
                                  <li>
                                    <i className="fas fa-mobile-alt"></i>
                                    <span>{item?.userId?.mobileNumber}</span>
                                  </li>
                                </ul>
                              </div>
                              <a onClick={() => history.push(ROUTES_CONSTANTS.INBOX)} className="btn book-btn mt-4">
                                Send Message
                              </a>
                            </div>
                          </div>
                        ))
                      : ""}
                    {/* <!-- /Doctor Widget --> */}
                  </Slider>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
      {/* <!-- table  --> */}
      <div className="row">
        <div className="col-md-12">
          <h4 className="mb-4">Upcoming Appointments</h4>
          <div className="card card-table table-data-color">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover table-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-center">Patient Name</th>
                      <th>Appointment Date</th>
                      <th>Appointment Time</th>
                      {/* <!-- <th>Status</th> --> */}
                      <th className="text-center">Paid Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    {UpcomingAppoints && UpcomingAppoints.length ? (
                      UpcomingAppoints.map((item, ind) => (
                        <tr key={ind}>
                          <td className="text-center">
                            {item && item?.patientId?.firstName} {item && item?.patientId?.lastName}
                          </td>
                          <td>{item && new Date(item?.appointmentStartTime).toLocaleDateString()}</td>
                          <td>{item && new Date(item?.appointmentStartTime).toLocaleTimeString()}</td>
                          <td className="text-center">${item?.amount ? item?.amount : 0}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" className="text-center">
                          Data Not Found
                        </td>
                      </tr>
                    )}

                    {/* <tr>
                      <td className="text-center">Richard Wilson</td>
                      <td>11 Nov 2021</td>
                      <td>12:30-1:00 P.M</td>
                      <td className="text-center">$100</td>
                    </tr> */}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DashBoard;
