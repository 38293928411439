import { useDispatch, useSelector } from "react-redux";
import { doctor } from "../../redux/action";
import { useEffect, useState } from "react";
import UpgradeSubscription from "../PatientDashboard/findDoctorsNearBy/UpgradeSubscription";
import ConfirmationModal from "../PatientDashboard/findDoctorsNearBy/ConfirmationModal";

function Subscription() {
  let dispatch = useDispatch();
  const [PatSubcriptionModal, setPatSubcriptionModal] = useState(false);
  const [DataSub, setDataSub] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('')
  const [ConfirmModal, setConfirmModal] = useState(false);
  const [UpgradeConfirmToggle, setUpgradeConfirmToggle] = useState(false);
  const docSubscriptionInfo = useSelector((state) => state?.doctor?.doctorSubscribeData?.result?.subscriptionId);
  console.log("docSubscriptionInfo", docSubscriptionInfo);
  useEffect(() => {
    window.scrollTo(0, 0);
    docSubscriptionInWeb();
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramValue = urlParams.get('payment');
    setPaymentStatus(paramValue)
    console.log(paramValue);
  }, []);

  useEffect(() => {
    if (paymentStatus && paymentStatus === 'success') {
      // alert("Subscription Done!")
      setConfirmModal(true)
      setUpgradeConfirmToggle(true)
    }
  }, [paymentStatus])


  const docSubscriptionInWeb = async () => dispatch(doctor.docSubscriptionInWebAction());
  return (
    <>
      <div className=" col-lg-8 col-xl-9 content_ipad_p">
        <div className="card">
          <div className="card-header">
            <h4> Subscription</h4>
          </div>
          <div className="card-body ">
            <div className="account-content">
              <div className="row">
                <div className="col-md-12 col-lg-6">
                  <div className="card subscription-packages">
                    <div className="card-body">
                      <div className="d-flex justify-content-between mb-1">
                        <div>
                          <label className="form-check-label" for="Subscription1">
                            Subscription
                          </label>
                        </div>
                        <div>
                          <label className="color-black">
                            <b>${docSubscriptionInfo && docSubscriptionInfo.price}/month</b>
                          </label>
                        </div>
                      </div>

                      <div className="card p-2 mt-1 package-description">
                        <div>
                          <label className="color-grey">Number of Secondary Speciality: {docSubscriptionInfo && docSubscriptionInfo.numberOfSecondrySpecialty}</label>
                        </div>
                        <div>
                          <label className="color-grey">Number of Leads: {docSubscriptionInfo && docSubscriptionInfo.numberOfLeads}</label>
                        </div>
                        <div>
                          <label className="color-grey">Minutes of Audio calls: {docSubscriptionInfo && docSubscriptionInfo.minutesOfAudioCalls}</label>
                        </div>
                        <div>
                          <label className="color-grey">Minutes of Video calls: {docSubscriptionInfo && docSubscriptionInfo.minutesOfVideoCalls}</label>
                        </div>
                        <div>
                          <label className="color-grey">Subscription Validity(days): {docSubscriptionInfo && docSubscriptionInfo.days_duration}</label>
                        </div>
                        <div>
                          <label className="color-grey">Number of Messages: {docSubscriptionInfo && docSubscriptionInfo.numberOfMessages}</label>
                        </div>
                      </div>
                      <button
                        onClick={() => {
                          setPatSubcriptionModal(!PatSubcriptionModal);
                          setDataSub(true);
                        }}
                        className="btn btn-primary w-100"
                      >
                        Upgrade/Downgrade
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpgradeSubscription PatSubcriptionModal={PatSubcriptionModal} docSubscriptionInfo={docSubscriptionInfo} setPatSubcriptionModal={setPatSubcriptionModal} DataSub={DataSub} />
      <ConfirmationModal ConfirmModal={ConfirmModal} setConfirmModal={setConfirmModal} UpgradeConfirmToggle={UpgradeConfirmToggle}/>
    </>
  );
}

export default Subscription;
