import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import { useHistory } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../../../constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { authentication, bookingAction, patient } from "../../../../redux/action";
import ToasterFunc from "../../../common/toaster/Toaster";
import ConfirmationModal from "../ConfirmationModal";

function BookAppWithCardPayment({ setPaymentModal, PaymentModal, Image, DocImage, AppAndDocInfo, AppPrice, labBookingToggle, LabPrice }) {
  let dispatch = useDispatch();
  let history = useHistory();

  const [ConfirmModal, setConfirmModal] = useState(false);
  const [selectedCard, setSelectedCard] = useState(null);
  const [addCard, setAddCard] = useState(false);
  const [couponId, setCouponId] = useState(null);
  const [cvvValue, setCvvValue] = useState("");
  const [cvvValueErr, setCvvValueErr] = useState(false);
  const [CardErr, setCardErr] = useState("");
  const [priceValue, setPriceValue] = useState("");
  const [CardType, setCardType] = useState({
    credit: true,
    debit: false,
  });
  const [successToggle, setsuccessToggle] = useState(false);
  const [showBill, setShowBill] = useState(false);
  const [isSaved, setisSaved] = useState(false);
  const [Labdata, setLabdata] = useState({});
  const [CardnumErr, setCardnumErr] = useState("");
  const handleClose = () => {
    setPaymentModal(false);
    setShowBill(false);
  };

  console.log("AppAndDocInfo", AppAndDocInfo);

  const cardListInPat = async () => dispatch(patient.cardListInPatAction());
  const paymentWithCard = async (payload) => dispatch(patient.bookAppointmentWithCardAction(payload));
  const couponList = async () => dispatch(authentication.couponListAction());
  const [bothCardDetails, setCardDetails] = useState({
    credit: {},
    debit: {},
  });

  const PatientData = useSelector((state) => state?.patient?.patientProdileInfo?.userData);

  const cardListData = useSelector((state) => state.patient?.patientCardListinPayment?.documentList);

  console.log("PatientDataaaaaa", PatientData);

  const PatientProfileInfo = async () => dispatch(patient.patientProfileDetailsAction());

  const bookLabAppointment = async (payload) => dispatch(bookingAction.bookLabAppointmentAction(payload));

  console.log("cardListData", cardListData);
  console.log("Labdata", Labdata, labBookingToggle);
  useEffect(() => {
    setLabdata(sessionStorage.getItem("LabBookingData") && JSON.parse(sessionStorage.getItem("LabBookingData")));
  }, [sessionStorage.getItem("LabBookingData")]);

  useEffect(() => {
    cardListInPat();
    couponList();
    PatientProfileInfo();
  }, []);

  const formik = useFormik({
    initialValues: {
      fullname: "",
      CardNum: "",
      cvv: "",
      // cardexpiry: "",
    },
    validationSchema: Yup.object({
      fullname: Yup.string()
        .required("*Required.")
        .matches(/^[^-\s][\w\s-]+$/, "Please Enter required value"),
      CardNum: Yup.number().required("*Required.").typeError("*Please enter only digits"),
      cvv: Yup.number().required("*Required.").typeError("*Please enter only digits"),
      cardexpiry: Yup.string().required("*Required.").typeError("*Please enter only digits"),
    }),
  });

  useEffect(() => {
    if (CardType.credit === true || CardType.debit === true) {
      setCardErr("");
    }
    console.log("formik.values.CardNum",formik.values.CardNum);
    if (formik.values.CardNum.replaceAll(" " , "").length === 16) {
      setCardnumErr("");
    }
  }, [CardType, formik.values.CardNum]);

  const handleConfirm = async (e) => {
    console.log("PRICE", e);
    if (CardType.credit === false && CardType.debit === false) {
      setCardErr("*Please Choose any one");
    }
    if (CardType.credit === true || CardType.debit === true) {
      setCardErr("");
    }
    if (formik.values.CardNum !== "" && formik.values.CardNum.replaceAll(" " , "").length < 16) {
      setCardnumErr("*Invalid Card Number");
    }

    let paymentPayload = {};

    if (labBookingToggle) {
      paymentPayload = {
        cardHolderName: formik.values.fullname,
        cardType: CardType.credit ? "CREDIT" : "DEBIT",
        cardNumber: formik.values.CardNum.replaceAll(" " , ""),
        exp_month: formik.values && formik.values.cardexpiry ? formik.values.cardexpiry.split("/")[0] : "",
        exp_year: formik.values && formik.values.cardexpiry ? formik.values.cardexpiry.split("/")[1] : "",
        cvc: formik.values.cvv,
        price: LabPrice ? LabPrice : "",
        laboratoryId: Labdata?.laboratoryId,
        packageId: Labdata?.packageId,
        slotTime: Labdata?.slotTime,
        dayId: Labdata?.dayId,
        slotId: Labdata?.slotId,
        bookingDate: Labdata?.bookingDate,
        appointmentStartTime: Labdata?.appointmentStartTime,
        appointmentEndTime: Labdata?.appointmentEndTime,
        sample_collection_type: Labdata && Labdata[0] && Labdata[0][0]?.collectionCategory,
        name: Labdata && Labdata[0] && Labdata[0][0]?.name,
        date_of_birth: Labdata && Labdata[0] && Labdata[0][0]?.dob,
        email: Labdata && Labdata[0] && Labdata[0][0]?.email,
        mobileNumber: Labdata && Labdata[0] && Labdata[0][0]?.number,
        gender: Labdata && Labdata[0] && Labdata[0][0]?.gender,
        address: Labdata && Labdata[0]?.searchData,
        lat: Labdata && Labdata[0]?.lat,
        long: Labdata && Labdata[0]?.long,
        pincode: Labdata && Labdata[0]?.pincodeData,
        landmark: Labdata && Labdata[0]?.landmarkData,
      };
    } else {
      paymentPayload = {
        cardHolderName: formik.values.fullname,
        cardType: CardType.credit ? "CREDIT" : "DEBIT",
        cardNumber: formik.values.CardNum.replaceAll(" " , ""),
        exp_month: formik.values && formik.values.cardexpiry ? formik.values.cardexpiry.split("/")[0] : "",
        exp_year: formik.values && formik.values.cardexpiry ? formik.values.cardexpiry.split("/")[1] : "",
        cvc: formik.values.cvv,
        price: AppPrice ? AppPrice : "",
        serviceProviderId: AppAndDocInfo?.otherDetail?.serviceProviderId,
        slotTime: AppAndDocInfo?.otherDetail?.slotTime,
        dayId: AppAndDocInfo?.otherDetail?.dayId,
        slotId: AppAndDocInfo?.otherDetail?.slotId,
        bookingDate: AppAndDocInfo?.otherDetail?.bookingDate,
        appointmentStartTime: AppAndDocInfo?.otherDetail?.appointmentStartTime,
        appointmentEndTime: AppAndDocInfo?.otherDetail?.appointmentEndTime,
        reason: AppAndDocInfo?.otherDetail?.reason,
        document: AppAndDocInfo?.otherDetail?.document,
        cardSave: isSaved ? true : false,
      };
    }

    console.log("paymentPayload", paymentPayload);
    console.log("Coming hereeeeeeeeeeee");
    console.log(CardType.credit, CardType.debit);
    if (CardType.credit !== false || CardType.debit !== false) {
      console.log("Coming hereeeeeeeeeeee");
      if (formik.values.CardNum !== "" && formik.values.CardNum.replaceAll(" " , "").length === 16 && formik.values.fullname !== "" && formik.values.cardexpiry !== "" && formik.values.cvv !== "") {
        console.log("Coming hereeeeeeeeeeee");
        let res = null;
        if (labBookingToggle) {
          res = await bookLabAppointment(paymentPayload);
          if (res && res.status == 200) {
            ToasterFunc("success", res.data.responseMessage);
            console.log("paymenttttttttt", res);
            handleClose();
            setConfirmModal(!ConfirmModal);
            if (sessionStorage.getItem("LabBookingData")) {
              sessionStorage.removeItem("LabBookingData");
            }
            sessionStorage.removeItem("PatientData");
            sessionStorage.removeItem("Deliverylocationdata");
          }
        } else {
          res = await paymentWithCard(paymentPayload);
          if (res && res.status == 200) {
            ToasterFunc("success", res.data.responseMessage);
            // setsuccessToggle(true);
            console.log("paymenttttttttt", res);
            history.push(history.push(ROUTES_CONSTANTS.PATIENT_DASHBOARD));
            // setPaymentModal(false);
            // setConfirmModal(!ConfirmModal);
          }
        }
      }
    }
  };

  const handleCard = async (e) => {
    let name = e.target.value;
    let value = e.target.checked;
    if (name === "Credit") {
      setCardType({
        credit: value,
      });
      setCardDetails({
        ...bothCardDetails,
        debit: {
          CardNum: formik.values.CardNum.replaceAll(" " , ""),
          fullname: formik.values.fullname,
          cardexpiry: formik.values.cardexpiry,
          cvv: formik.values.cvv,
        },
      });
      formik.setFieldValue("CardNum", bothCardDetails?.credit?.CardNum || "");

      formik.setFieldValue("fullname", bothCardDetails?.credit?.fullname || "");

      formik.setFieldValue("cardexpiry", bothCardDetails?.credit?.cardexpiry || "");

      formik.setFieldValue("cvv", bothCardDetails?.credit?.cvv || "");
    } else if (name === "Debit") {
      setCardType({
        debit: value,
      });
      setCardDetails({
        ...bothCardDetails,
        credit: {
          CardNum: formik.values.CardNum.replaceAll(" " , ""),
          fullname: formik.values.fullname,
          cardexpiry: formik.values.cardexpiry,
          cvv: formik.values.cvv,
        },
      });
      formik.setFieldValue("CardNum", bothCardDetails?.debit?.CardNum ? bothCardDetails?.debit?.CardNum : "");
      formik.setFieldValue("fullname", bothCardDetails?.debit?.fullname ? bothCardDetails?.debit?.fullname : "");
      formik.setFieldValue("cardexpiry", bothCardDetails?.debit?.cardexpiry ? bothCardDetails?.debit?.cardexpiry : "");
      formik.setFieldValue("cvv", bothCardDetails?.debit?.cvv ? bothCardDetails?.debit?.cvv : "");
    }
    if (name === "SaveForLater") {
      setisSaved(true);
    }
  };

  const handleExpiryDate = () => {
    const inp = document.querySelector("#input");

    inp.onkeydown = function (e) {
      const key = e.keyCode || e.charCode;

      if (key !== 8 && key !== 46) {
        if (inp.value.length == 2) {
          inp.value = inp.value + "/";
        }
      }

      if ((key == 8 || key == 46) && inp.value.length === 4) {
        inp.value = inp.value.slice(0, 3);
      }
    };
  };
  useEffect(() => {
    if (successToggle) {
      setConfirmModal(!ConfirmModal);
    }
  }, [successToggle, ConfirmModal]);

  React.useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.target.name === "CardNum"){
        event.target.value = event.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
      }
      if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
        var form = event.target.form;
        var index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + 1].focus();
        event.preventDefault();
      }
    });
  }, []);

  const handleCardPayment = async () => {
    let paymentPayload = {};
    if (cvvValue.length < 3) {
      setCvvValueErr(true);
    } else if (labBookingToggle) {
      paymentPayload = {
        cardHolderName: selectedCard.cardHolderName,
        cardType: selectedCard.cardType,
        cardNumber: selectedCard.cardNumber,
        exp_month: selectedCard.expMonth,
        exp_year: selectedCard.expYear,
        cvc: cvvValue,
        price: LabPrice ? LabPrice : "",
        laboratoryId: Labdata?.laboratoryId,
        packageId: Labdata?.packageId,
        slotTime: Labdata?.slotTime,
        dayId: Labdata?.dayId,
        slotId: Labdata?.slotId,
        bookingDate: Labdata?.bookingDate,
        appointmentStartTime: Labdata?.appointmentStartTime,
        appointmentEndTime: Labdata?.appointmentEndTime,
        sample_collection_type: Labdata && Labdata[0] && Labdata[0][0]?.collectionCategory,
        name: Labdata && Labdata[0] && Labdata[0][0]?.name,
        date_of_birth: Labdata && Labdata[0] && Labdata[0][0]?.dob,
        email: Labdata && Labdata[0] && Labdata[0][0]?.email,
        mobileNumber: Labdata && Labdata[0] && Labdata[0][0]?.number,
        gender: Labdata && Labdata[0] && Labdata[0][0]?.gender,
        address: Labdata && Labdata[0]?.searchData,
        lat: Labdata && Labdata[0]?.lat,
        long: Labdata && Labdata[0]?.long,
        pincode: Labdata && Labdata[0]?.pincodeData,
        landmark: Labdata && Labdata[0]?.landmarkData,
      };
    } else {
      paymentPayload = {
        cardHolderName: selectedCard.cardHolderName,
        cardType: selectedCard.cardType,
        cardNumber: selectedCard.cardNumber,
        exp_month: selectedCard.expMonth,
        exp_year: selectedCard.expYear,
        cvc: cvvValue,
        price: AppPrice ? AppPrice : "",
        serviceProviderId: AppAndDocInfo?.otherDetail?.serviceProviderId,
        slotTime: AppAndDocInfo?.otherDetail?.slotTime,
        dayId: AppAndDocInfo?.otherDetail?.dayId,
        slotId: AppAndDocInfo?.otherDetail?.slotId,
        bookingDate: AppAndDocInfo?.otherDetail?.bookingDate,
        appointmentStartTime: AppAndDocInfo?.otherDetail?.appointmentStartTime,
        appointmentEndTime: AppAndDocInfo?.otherDetail?.appointmentEndTime,
        reason: AppAndDocInfo?.otherDetail?.reason,
        document: AppAndDocInfo?.otherDetail?.document,
        cardSave: false,
      };
    }

    let res = null;
    if (labBookingToggle) {
      res = await bookLabAppointment(paymentPayload);
      if (res && res.status == 200) {
        ToasterFunc("success", res.data.responseMessage);
        // setsuccessToggle(true);
        console.log("paymenttttttttt", res);
        setPaymentModal(false);
        setConfirmModal(!ConfirmModal);
        sessionStorage.removeItem("LabBookingData");
      }
    } else {
      res = await paymentWithCard(paymentPayload);
      if (res && res.status == 200) {
        ToasterFunc("success", res.data.responseMessage);
        // setsuccessToggle(true);
        console.log("paymenttttttttt", res);
        history.push(history.push(ROUTES_CONSTANTS.PATIENT_DASHBOARD));
        // setPaymentModal(false);
        // setConfirmModal(!ConfirmModal);
      }
    }
  };
  console.log("successToggle", successToggle);
  return (
    <>
      <Modal isOpen={PaymentModal} className=" modal-dialog-centered bill-details">
        <div className="modal-content">
          <button type="button" className="close custom-model-close" aria-label="Close" data-dismiss="modal" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </button>
          <div className="modal-body p-4">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="card mb-0">
                  <div className="card-header">
                    <h4 className="card-title">Payment Details</h4>
                  </div>
                  {!addCard && cardListData?.filter((data) => data.isPrimary).length > 0 && (
                    <div className="card-body">
                      <div className="card-list">
                        {console.log("cardListData", cardListData)}
                        {cardListData
                          ?.filter((data) => data.isPrimary)
                          ?.map((cardData) => {
                            return (
                              <div className="card">
                                <div className="card-header">
                                  <div className="row">
                                    <div className="col-md-12">
                                      <div className="d-flex justify-content-between">
                                        <div className="form-check form-check-inline">
                                          <input className="form-check-input" type="radio" name="Card" id="added-card-1" value="Credit" checked={cardData?._id === selectedCard?._id ? true : false} onChange={() => setSelectedCard(cardData)} />
                                          <label className="form-check-label" for="added-card-1">
                                            {cardData.cardType === "CREDIT" ? "Credit Card" : "Debit Card"}
                                          </label>
                                        </div>
                                      </div>
                                      <label className="card-number ">{`${cardData?.cardNumber?.substring(0, 4)}XXXXXXXX${cardData?.cardNumber?.substring(11, 15)}`}</label>
                                    </div>
                                    {cardData?._id === selectedCard?._id && (
                                      <div className="col-md-12">
                                        <div className="card-list-cvv">
                                          <input
                                            className="form-control w-25"
                                            type="password"
                                            name="CVV"
                                            id="added-card-1"
                                            placeholder="CVV"
                                            maxLength={3}
                                            onFocus={() => setCvvValueErr(false)}
                                            value={cvvValue}
                                            onChange={(e) => setCvvValue(e.target.value.replace(/[^0-9]/g, ""))}
                                          />
                                          {cvvValueErr && <span className="validation_error">*Please enter correct cvv.</span>}
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        <div className="btn-p mt-3">
                          <button type="submit" className="btn btn-primary btn-new mt-2" onClick={() => setAddCard(true)}>
                            Add New
                          </button>
                          <button disabled={!selectedCard || !cvvValue} type="submit" className="btn btn-primary btn-new ml-2 mt-2" onClick={handleCardPayment}>
                            Continue
                          </button>
                        </div>
                      </div>
                    </div>
                  )}
                  {(cardListData?.filter((data) => data.isPrimary).length === 0 || addCard) && (
                    <form onSubmit={formik.handleSubmit}>
                      <div className="card-body">
                        <div className="row mb-2">
                          <div className="col-md-12">
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" name="Card" id="inlineRadio1" value="Credit" defaultChecked onClick={(e) => handleCard(e)} />
                              <label className="form-check-label" for="inlineRadio1">
                                Credit
                              </label>
                            </div>
                            <div className="form-check form-check-inline">
                              <input className="form-check-input" type="radio" name="Card" id="inlineRadio1" value="Debit" onClick={(e) => handleCard(e)} />
                              <label className="form-check-label" for="inlineRadio2">
                                Debit
                              </label>
                            </div>
                          </div>
                        </div>
                        {CardErr ? <div className="validation_error card">{CardErr}</div> : null}

                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group text-left">
                              <label for="Cardholder name">Full Name on Card</label>
                              <input type="text" className="form-control" name="fullname" maxLength="20" {...formik.getFieldProps("fullname")} />
                              {formik.touched.fullname && formik.errors.fullname && formik.values.fullname == "" ? <span className="validation_error">{formik.errors.fullname}</span> : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group text-left">
                              <label for="Cardholder name">Card Number</label>
                              <input type="text" className="form-control"  name="CardNum" maxLength="19" {...formik.getFieldProps("CardNum")} />
                              {formik.touched.CardNum && formik.errors.CardNum && !formik.values.CardNum ? <span className="validation_error">{formik.errors.CardNum}</span> : null}
                              {CardnumErr ? <span className="validation_error">{CardnumErr}</span> : null}
                            </div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-md-6">
                            <div className="form-group  text-left">
                              <label for="Cardholder name">CVV</label>
                              <input type="text" className="form-control" placeholder="CVV" maxLength="3" name="cvv" {...formik.getFieldProps("cvv")} />
                              {formik.touched.cvv && formik.errors.cvv && !formik.values.cvv ? <span className="validation_error">{formik.errors.cvv}</span> : null}
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group text-left">
                              <label for="Cardholder name">Card Expiry</label>
                              <input type="text" id="input" className="form-control" placeholder="MM/YY" name="cardexpiry" maxLength="5" {...formik.getFieldProps("cardexpiry")} onFocus={handleExpiryDate} />
                              {formik.touched.cardexpiry && formik.errors.cardexpiry && !formik.values.cardexpiry ? <span className="validation_error">{formik.errors.cardexpiry}</span> : null}
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-check">
                              <input type="checkbox" className="form-check-input" id="payments" value="SaveForLater" onChange={(e) => handleCard(e)} />
                              <label className="savemefor" value="SaveForLater" name="SaveForLater" for="payments">
                                Save card for future payments
                              </label>
                            </div>
                          </div>
                        </div>

                        <div className="btn-p">
                          {cardListData?.filter((data) => data.isPrimary).length > 0 && (
                            <button type="submit" className="btn btn-primary mt-2" onClick={() => setAddCard(false)}>
                              BACK
                            </button>
                          )}
                          <button type="submit" className="btn btn-primary mt-2 ml-2" onClick={(e) => handleConfirm(e)}>
                            Confirm
                          </button>
                        </div>
                      </div>
                    </form>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <ConfirmationModal ConfirmModal={ConfirmModal} setConfirmModal={setConfirmModal} />
    </>
  );
}

export default BookAppWithCardPayment;
