import {useEffect} from 'react'
import { doctor } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { getSessionStorageOrDefault } from '../../utils';

function AboutUs() {
  const dispatch = useDispatch()
  const token = getSessionStorageOrDefault("authToken");


  const docAboutUs = async () => dispatch(doctor.docAboutUsAction())
  useEffect(() => {
    window.scrollTo(0, 0);
    docAboutUs()
    console.log("UseEffect");
  }, [])

  const aboutusData = useSelector(state => state?.doctor?.doctorAboutUs?.data)

  const createMarkup = () => {
    if (aboutusData) {
        return { __html: aboutusData && aboutusData.description && aboutusData.description };
    }
}
  return (
    <>
      <div class={token?'col-lg-8 col-xl-9 content_ipad_p':'col-lg-12 col-xl-12 content_ipad_p'}>
        <div class="row">
          <div class="col-sm-12">
            <div class="card">
              <div class="card-header">
                <h4>{aboutusData && aboutusData.title}</h4>
              </div>
              <div class="card-body">
                {aboutusData? <div className="cms_box" dangerouslySetInnerHTML={createMarkup()} />:<p className='text-center'>Data not found!</p>}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AboutUs;
