import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { doctor } from "../../../redux/action";
import { useFormik } from "formik";
import * as Yup from "yup";
import ToasterFunc from "../../common/toaster/Toaster";

const ViewHelpRequest = () => {
  let { HelpId } = useParams();
  let dispatch = useDispatch();

  console.log(HelpId);
  const [ReplyToggle, setReplyToggle] = useState(false);

  useEffect(() => {
    docViewHelpRequest(HelpId);
    if (ReplyToggle && HelpId) {
      docViewHelpRequest(HelpId);
    }
  }, [HelpId, ReplyToggle]);
  const docViewHelpRequest = async (payload) => dispatch(doctor.docViewHelpRequestAction(payload));
  const doctorReplyOnRequest = async (payload) => dispatch(doctor.doctorReplyOnRequestAction(payload));

  const formik = useFormik({
    initialValues: {
      subject: "",
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("*Required."),
    }),
  });

  const ViewHelpRequestData = useSelector((state) => state?.doctor?.doctorViewHelpRequestData);
  console.log("ViewD", HelpId, ViewHelpRequestData);

  const handleSend = async () => {
    if (formik.values.subject !== "") {
      const payload = {
        _id: ViewHelpRequestData?.data?._id,
        message: formik.values.subject,
      };
      const res = await doctorReplyOnRequest(payload);
      if (res?.status === 200) {
        ToasterFunc("success", "Message has been sent");
        formik.values.subject = "";
        setReplyToggle(true);
      }
    }
  };

  return (
    <div className=" col-lg-8 col-xl-9 content_ipad_p help">
      <div className="card">
        <div className="card-header">
          <h4>View Request</h4>
        </div>
        <div className="card-body">
          <div class="card ">
            <div class="card-body mb-0">
              <div class="subject">
                <h6>
                  <b>Subject</b>
                </h6>
                <label for="subject" class="default-content-clr">
                  {ViewHelpRequestData?.data?.subject}
                </label>
              </div>
            </div>
          </div>
          <div className="card">
            <div class="card-body">
              <div class="d-flex ">
                <div>
                  {" "}
                  <img class="avatar rounded-circle" alt="User Image" src={ViewHelpRequestData?.data?.userId?.profilePic} />
                </div>
                <div class="ml-2">
                  {" "}
                  <span>
                    {" "}
                    <b>{ViewHelpRequestData?.data?.userId?.fullName}</b>
                  </span>
                  <br />
                  <label class=" default-content-clr"> {ViewHelpRequestData && ViewHelpRequestData?.data?.message}</label>
                </div>
              </div>
            </div>
          </div>
          {ViewHelpRequestData?.documentList?.length
            ? ViewHelpRequestData?.documentList.map((item) => (
                <div class="card ">
                  <div class="card-body">
                    <div class="d-flex ">
                      <div>
                        {" "}
                        <img class="avatar rounded-circle" alt="User Image" src={item?.replyBy === "ADMIN" ? item?.adminId?.profilePic : item?.userId?.profilePic} />
                      </div>
                      <div class="ml-2">
                        {" "}
                        <span>
                          <b>{item?.replyBy === "ADMIN" ? `${item?.adminId?.firstName} ${item?.adminId?.lastName}` : item?.userId?.fullName}</b>
                        </span>
                        <br />
                        <label class="mb-0 default-content-clr">{item?.message}</label>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            : ""}
          <form onSubmit={formik.handleSubmit}>
            <div class="subject mb-3">
              <textarea class="form-control" name="subject" id="subject" rows="6" maxLength="180" {...formik.getFieldProps("subject")}></textarea>
              {formik.touched.subject && formik.errors.subject ? <span className="validation_error">{formik.errors.subject}</span> : null}
            </div>

            <div class="submit-section text-center">
              <button type="submit" class="btn btn-primary w-25 mt-2 mb-3" onClick={handleSend}>
                Send
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ViewHelpRequest;
