import React from "react";
import { useHistory } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../../constants";

function DocViewPersonal({ ProfileData }) {
  let history = useHistory();
  console.log("DocProfileData", ProfileData);
  return (
    <div className="tab-pane fade show active" id="per_details_tab">
      {/* <!-- Personal Details --> */}
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <p
                  className="
                        col-sm-2
                        text-muted text-sm-right
                        mb-0 mb-sm-3
                      "
                >
                  Name
                </p>
                <p className="col-sm-10">{ProfileData?.fullName}</p>
              </div>
              {/* <div className="row">
                <p
                  className="
                        col-sm-2
                        text-muted text-sm-right
                        mb-0 mb-sm-3
                      "
                >
                  Date of Birth
                </p>
                <p className="col-sm-10">24 Jul 1983</p>
              </div> */}
              <div className="row">
                <p
                  className="
                        col-sm-2
                        text-muted text-sm-right
                        mb-0 mb-sm-3
                      "
                >
                  Gender
                </p>
                <p className="col-sm-10">{ProfileData?.gender === "Male" ? "Male" : ProfileData?.gender === "Female" ? "Female" : "I prefer not to say"}</p>
              </div>
              {/* <div className="row">
                <p
                  className="
                        col-sm-2
                        text-muted text-sm-right
                        mb-0 mb-sm-3
                      "
                >
                  Marital Status
                </p>
                <p className="col-sm-10">Single</p>
              </div> */}
              <div className="row">
                <p
                  className="
                        col-sm-2
                        text-muted text-sm-right
                        mb-0 mb-sm-3
                      "
                >
                  Email ID
                </p>
                <p className="col-sm-10">{ProfileData ? ProfileData.email : ""}</p>
              </div>
              <div className="row">
                <p
                  className="
                        col-sm-2
                        text-muted text-sm-right
                        mb-0 mb-sm-3
                      "
                >
                  Mobile
                </p>
                <p className="col-sm-10">{ProfileData ? ProfileData.mobileNumber : ""}</p>
              </div>
              <div className="row">
                <p
                  className="
                        col-sm-2
                        text-muted text-sm-right
                        mb-0
                      "
                >
                  Address
                </p>
                <p className="col-sm-10 mb-0">{ProfileData && ProfileData ? ProfileData.location && ProfileData.location.address : ""}</p>
              </div>
              <div className="d-flex justify-content-center mt-5">
                {" "}
                <button onClick={() => history.push(ROUTES_CONSTANTS.DOCTOR_EDIT_PERSONAL_DETAIL)} className="btn btn-primary">
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- /Personal Details --> */}
    </div>
  );
}

export default DocViewPersonal;
