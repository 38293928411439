import React, { useEffect, useState } from "react";
// import { IMAGE_CONSTANT } from "../../../../../constants";
import { useFormik } from "formik";
import * as Yup from "yup";
// import AutoComplete from "./AutoComplete";
import { useDispatch, useSelector } from "react-redux";
import { commonAction, patient, patientAction } from "../../../redux/action";
import defaultPic from "../../../assets/img/default-img.png";
import moment from "moment";
import AutoComplete from "../../authentication/sign-up-as-doctor/AutoComplete";
import ToasterFunc from "../../common/toaster/Toaster";
import { useHistory } from "react-router-dom";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import DatePicker from "react-datepicker";
import { ROUTES_CONSTANTS } from "../../../constants";

function PatEditPersonal() {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });
  let history = useHistory();
  let dispatch = useDispatch();
  const [Address, setAddress] = useState("");
  const [lastName, setlastName] = useState("");
  const [AddressErr, setAddressErr] = useState("");
  const [LastName, setLastName] = useState("");
  const [Lat, setLat] = useState("");
  const [Lng, setLng] = useState("");
  const [Image, setImage] = useState("");
  const [MaleGender, setMaleGender] = useState(false);
  const [FemaleGender, setFemaleGender] = useState(false);
  const [OtherGender, setOtherGender] = useState(false);
  const [BirthDateError, setBirthDateError] = useState("");
  const [BirthDate, setBirthDate] = useState("");

  const formik = useFormik({
    initialValues: {
      firstName: "",
      DOB: "",
      maritalStatus: "",
      lifestyle: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string()
        .required("*Required.")
        .trim()
        .matches(/[A-Za-z]/, "Only Characters are allowed"),
      DOB: Yup.string().required("*Required."),
      maritalStatus: Yup.string().required("*Required"),
      lifestyle: Yup.string()
        .required("*Required")
        .trim()
        .matches(/[A-Za-z]/, "Only Characters are allowed"),
    }),
    onSubmit: (values) => {
      const payload = {
        // firstName: values.firstName,
        // lastName: "",
        // profilePic: Image ? Image : null,
        // gender: gender.male ? "Male" : "Female",
        // date_of_birth: values.DOB,
        // marital_status: values.maritalStatus,
        // bio: values.lifestyle,
        // address: values.Address,
      };
      //
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    PatientProfileInfo();
  }, []);

  const PatientData = useSelector((state) => state?.patient?.patientProdileInfo);
  const CommonProfilePic = async (payload) => dispatch(commonAction.commonProfilePicAction(payload));

  const editProfileInPatsetting = async (payload) => dispatch(patient.editProfileInPatsettingsAction(payload));

  console.log("PatientDataaaaaaaaaaa", PatientData);

  const PatientProfileInfo = async () => dispatch(patient.patientProfileDetailsAction());

  useEffect(() => {
    formik.setFieldValue("firstName", PatientData && PatientData.userData && PatientData.userData.firstName ? PatientData.userData.firstName : "");
    formik.setFieldValue("email", PatientData && PatientData.userData && PatientData.userData.email ? PatientData.userData.email : "");
    formik.setFieldValue("mobile", PatientData && PatientData.userData && PatientData.userData.mobileNumber ? PatientData.userData.mobileNumber : "");
    formik.setFieldValue("DOB", PatientData && PatientData.userData && PatientData.userData.date_of_birth ? PatientData.userData.date_of_birth : "");
    formik.setFieldValue("maritalStatus", PatientData && PatientData.userData && PatientData.userData.marital_status ? PatientData.userData.marital_status : "");
    formik.setFieldValue("lifestyle", PatientData && PatientData.userData && PatientData.userData.bio ? PatientData.userData.bio : "");
    if (PatientData && PatientData.userData && PatientData.userData.location && PatientData.userData.location.address) {
      setAddress(PatientData.userData.location.address);
    }
    if (PatientData && PatientData.userData && PatientData.userData.profilePic) {
      setImage(PatientData.userData.profilePic);
    }
    if (PatientData && PatientData.userData && PatientData.userData.gender === "Male") {
      setMaleGender(true);
    }
    if (PatientData && PatientData.userData && PatientData.userData.gender === "Female") {
      setFemaleGender(true);
    }
    if (PatientData && PatientData.userData && PatientData.userData.gender === "Other") {
      setOtherGender(true);
    }
    if (PatientData && PatientData.userData && PatientData.userData.lastName) {
      setlastName(PatientData.userData.lastName);
    }
    if (Address !== "") {
      setAddressErr("");
    }
    if ((BirthDate || PatientData?.userData?.date_of_birth) !== "") {
      setBirthDateError("");
    }
    console.log("Address", Address);
  }, [PatientData, BirthDate]);

  // useEffect(() => {
  //   document.getElementById("datePickerId").max = new Date().toISOString().split("T")[0];
  // }, []);

  // const handlePlaceChanged = (place) => {
  //   let location = place?.geometry?.location;
  //   let address = place?.formatted_address;
  //   let lat = location?.lat();
  //   let lng = location?.lng();

  //   console.log("place", address);
  //   if (address) {
  //     setAddress(address);
  //     setLat(lat);
  //     setLng(lng);
  //   }
  // };

  const handlegender = (e) => {
    let checked = e.target.checked;
    let value = e.target.value;
    if (value === "1") {
      setMaleGender(checked);
      setFemaleGender(!checked);
      setOtherGender(!checked);
    } else if (value === "2") {
      setFemaleGender(checked);
      setMaleGender(!checked);
      setOtherGender(!checked);
    } else if (value === "3") {
      setOtherGender(checked);
      setMaleGender(!checked);
      setFemaleGender(!checked);
    }
  };

  const handleImage = (e) => {
    let result = e.target.value;

    const file = e.target.files[0];
    console.log(file);
    let formData = new FormData();
    console.log("file changed");

    formData.append("file", file);
    fileUpload(formData);

    // if (file.type.split("/")[0] !== "image") {
    //   // toast("Only image files are accepted");
    // } else {
    //   setImage(
    //     Object.assign(file, {
    //       preview: URL.createObjectURL(file),
    //     })
    //   );
    // }
  };

  const fileUpload = async (image) => {
    const res = await CommonProfilePic(image);
    if (res && res.status == 200) {
      console.log("Profile pIccccccccc", res.data.result.url);
      let picdata = res && res.data && res.data.result.url;
      setImage(picdata);
    }
  };

  const payloadInfo = {
    firstName: formik.values.firstName ? formik.values.firstName : "",
    lastName: lastName ? lastName : "",
    profilePic: Image ? Image : "",
    gender: MaleGender ? "Male" : FemaleGender ? "Female" : "Other",
    date_of_birth: BirthDate ? BirthDate : PatientData?.userData?.date_of_birth,
    marital_status: formik.values.maritalStatus ? formik.values.maritalStatus : "",
    bio: formik.values.lifestyle ? formik.values.lifestyle : "",
    lat: PatientData && PatientData.userData && PatientData.userData.location && PatientData.userData.location.coordinates[1] ? PatientData.userData.location.coordinates[1] : Lat ? Lat : "",
    long: PatientData && PatientData.userData && PatientData.userData.location && PatientData.userData.location.coordinates[0] ? PatientData.userData.location.coordinates[0] : Lng ? Lng : "",
    address: Address ? Address : "",
  };
  console.log("payloadInfo", payloadInfo);

  const handleUpdate = async (e) => {
    if (Address === "") {
      setAddressErr("*Required");
    }

    console.log(MaleGender, FemaleGender, formik.values.firstName);
    console.log("formik.errors", formik.errors);

    if (
      // Address !== "" &&
      ((PatientData && PatientData.userData && PatientData.userData.location && PatientData.userData.location.address !== "") || Address !== "") &&
      !formik.errors.firstName &&
      (BirthDate || PatientData?.userData?.date_of_birth) != "" &&
      !formik.errors.maritalStatus &&
      !formik.errors.lifestyle
    ) {
      const res = await editProfileInPatsetting(payloadInfo);
      if (res && res.status == 200) {
        ToasterFunc("success", res.data.responseMessage);
        history.push(ROUTES_CONSTANTS.PAT_VIEW_PROFILE)
        console.log("editpersonalProfile", res);
      }
    } else {
      e.preventDefault();
    }
  };
  const handleLastname = (e) => {
    setlastName(e.target.value);
  };

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
    setAddress(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();
    console.log("description", description);
    setAddress(description);

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log("📍 Coordinates: ", { lat, lng });
        setLat(lat);
        setLng(lng);
      })
      .catch((error) => {
        console.log("😱 Error: ", error);
      });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div class=" col-lg-8 col-xl-9 ">
      {/* <!-- Basic Information --> */}
      <div className="card">
        <div className="card-header">
          <h4>Edit Personal Profile</h4>
        </div>
        <div className="card-body">
          {/* <!-- <h4 className="card-title">Edit Personal Details</h4> --> */}
          <form onSubmit={formik.handleSubmit}>
            <div className="row form-row">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="change-avatar justify-content-center">
                    <div className="profile-round-img">
                      <figure className="mb-0">
                        <img src={Image ? Image : PatientData && PatientData.userData && PatientData.userData.profilePic ? PatientData.userData.profilePic : defaultPic} alt="" />
                      </figure>
                      <label for="camera">
                        <input hidden type="file" id="camera" accept="image/*"  onChange={(e) => handleImage(e)} />
                        <i className="fas fa-camera icon-camera"></i>
                      </label>{" "}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  {/* <!-- <label> Name </label> --> */}
                  <input type="text" className="form-control" name="firstName" maxLength="16" placeholder="First Name" {...formik.getFieldProps("firstName")} />
                  {formik.touched.firstName && formik.errors.firstName ? <span className="validation_error">{formik.errors.firstName}</span> : null}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  {/* <!-- <label> Name </label> --> */}
                  <input type="text" className="form-control" value={lastName} placeholder="Last Name" maxLength="16" onChange={(e) => handleLastname(e)} />
                  {/* {formik.touched.firstName && formik.errors.firstName ? (
                <span className="validation_error">
                  {formik.errors.firstName}
                </span>
              ) : null} */}
                </div>
              </div>
              <div className="col-md-12">
                <div className="row form-group">
                  <div className="col-md-3 col-12">
                    <label for="">Gender</label>
                  </div>
                  <div className="col-md-3 col-3">
                    <div className="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="Male" name="gender" value="1" className="custom-control-input" checked={MaleGender ? true : false} onChange={(e) => handlegender(e)} />
                      <label className="custom-control-label" for="Male">
                        Male
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-4">
                    <div className="custom-control custom-radio custom-control-inline">
                      <input type="radio" id="Female" name="gender" value="2" className="custom-control-input" checked={FemaleGender ? true : false} onChange={(e) => handlegender(e)} />
                      <label className="custom-control-label" for="Female">
                        Female
                      </label>
                    </div>
                  </div>
                  <div className="col-md-3 col-5">
                    <div className="form-check ">
                      <input type="radio" className="form-check-input" id="not-to-say" name="gender" value="3" checked={OtherGender ? true : false} onChange={(e) => handlegender(e)} />
                      <label className="form-check-label" for="not-to-say">
                        I prefer not to Say
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  {/* <!-- <label> Email </label> --> */}
                  <input type="email" className="form-control" name="email" placeholder="Email" {...formik.getFieldProps("email")} disabled />
                  {formik.touched.email && formik.errors.email ? <span className="validation_error">{formik.errors.email}</span> : null}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  {/* <!-- <label> Email </label> --> */}
                  <input type="tel" className="form-control" placeholder="Phone Number" name="mobile" {...formik.getFieldProps("mobile")} disabled />
                  {formik.touched.mobile && formik.errors.mobile ? <span className="validation_error">{formik.errors.mobile}</span> : null}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  {/* <!-- <label> Email </label> --> */}
                  <DatePicker
                    className="form-control"
                    id="my_date_picker1"
                    autoComplete="off"
                    maxDate={moment().toDate()}
                    // data={PatientData?.userData?.date_of_birth}
                    value={BirthDate ? BirthDate : moment(PatientData?.userData?.date_of_birth).format("MM/DD/YYYY")}
                    selected={BirthDate}
                    onChange={(date) => setBirthDate(date)}
                    placeholderText="MM/DD/YYYY"
                    dateFormat="MM/dd/yyyy"
                    showMonthDropdown
                    showYearDropdown
                    scrollableMonthYearDropdown
                  />
                  {/* <input type="date" className="form-control" id="datePickerId" name="DOB" placeholder="DOB" {...formik.getFieldProps("DOB")} /> */}
                  {/* {formik.touched.DOB && formik.errors.DOB ? <span className="validation_error">{formik.errors.DOB}</span> : null} */}
                  {BirthDateError ? <span className="validation_error">{BirthDateError}</span> : null}
                  {/* <input type="date" className="form-control" id="datePickerId" name="DOB" placeholder="DOB" {...formik.getFieldProps("DOB")} />
                  {formik.touched.DOB && formik.errors.DOB ? <span className="validation_error">{formik.errors.DOB}</span> : null} */}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group">
                  <select className="form-control select" name="maritalStatus" value={PatientData && PatientData.userData && PatientData.userData.marital_status ? PatientData.userData.marital_status : ""} {...formik.getFieldProps("maritalStatus")}>
                    <option hidden>Marital Status</option>
                    <option value="Married">Married</option>
                    <option value="Single">Single</option>
                    <option value="Divorced">Divorced</option>
                    <option value="Widowed">Widowed</option>
                  </select>
                  {formik.touched.maritalStatus && formik.errors.maritalStatus ? <span className="validation_error">{formik.errors.maritalStatus}</span> : null}
                </div>
              </div>

              <div className="col-md-12">
                <div className="form-group address-suggesstion">
                  <input class="form-control" name="address" value={Address ? Address : value} maxLength="60" onChange={handleInput} disabled={!ready} placeholder="Address" />
                  {status === "OK" && <ul>{renderSuggestions()}</ul>}
                  {AddressErr ? <div className="validation_error">{AddressErr}</div> : null}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <label for="lifestyle">Tell me about your Lifestyle</label>
                  <textarea id="lifestyle" rows="4" className="form-control" maxLength="180" name="lifestyle" {...formik.getFieldProps("lifestyle")} />
                  {formik.touched.lifestyle && formik.errors.lifestyle ? <span className="validation_error">{formik.errors.lifestyle}</span> : null}
                </div>
              </div>
              <div className="submit-section btn-p">
                <button className="btn btn-primary submit-btn w-25" type="button" onClick={(e) => handleUpdate(e)}>
                  Update
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}

export default PatEditPersonal;
