import React, { useEffect, useState } from "react";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../constants";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import SecondarySpecialityModule from "./SecondarySpecialityModule";
import DocAvailabiltyModal from "./DocAvailabiltyModal";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { authentication, commonAction } from "../../../redux/action";
import ToasterFunc from "../../common/toaster/Toaster";
import PdfIc from "../../../assets/img/pdf.svg";
import Select from "react-dropdown-select";
import ConfirmationModal from "../../PatientDashboard/findDoctorsNearBy/ConfirmationModal";

function DocProfessional() {
  let location = useLocation();
  const dispatch = useDispatch();
  let history = useHistory();

  const primaryListCall = async () => dispatch(authentication.primarySpecialityListAction());
  const secondaryListCall = async (id) => dispatch(authentication.secondarySpecialityListAction(id));
  const CommonProfilePic = async (payload) => dispatch(commonAction.commonProfilePicAction(payload));

  const professionalSignup = async (payload) => dispatch(authentication.signUpProfessional(payload));

  const commonUploadedFile = async (payload) => dispatch(commonAction.commonUploadedFileAction(payload));

  const primaryList = useSelector((state) => state?.authentication?.primarySpecialityList);

  const secondaryList = useSelector((state) => state?.authentication?.secondarySpecialityList);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [timeSlotDay, setTimeSlotDay] = useState("");
  const [intervel, setIntervel] = useState(30);
  const [SecondSpeModule, setSecondSpeModule] = useState(false);
  const [Image, setImage] = useState("");
  const [ImageErr, setImageErr] = useState("");
  const [AvaialabiltyModal, setAvaialabiltyModal] = useState(false);
  const [specialityArray, setSpecialityArray] = useState([]);
  const [secondaryError, setSecondaryError] = useState("");
  const [availabilityError, setAvailabilityError] = useState("");
  const [availabilty, setAvailability] = useState([]);
  const [medicalFile, setmedicalFile] = useState("");
  const [MedicalFileErr, setMedicalFileErr] = useState("");
  // const [ToggleTermAndCondi, setToggleTermAndCondi] = useState(false);
  const [TermsAndCondi, setTermsAndCondi] = useState(false);
  const [ConfirmModal, setConfirmModal] = useState(false);
  const [isAdminNotVarifired, setisAdminNotVarifired] = useState(false);
  const [SelectedSpecialityArray, setSelectedSpecialityArray] = useState([]);

  const SpeciolityArr = (data) => {
    console.log("SpeciolityArr", data);
    if (data.length) {
      setSelectedSpecialityArray(data);
    }
  };

  console.log("pathname", location.state);

  const formik = useFormik({
    initialValues: {
      primarySpeciality: "",
      secondarySpeciality: "",
      // licenceNumber: "",
      year: "",
      month: "",
      availabilty: "",
      ServiceChrge: "",
    },
    validationSchema: Yup.object({
      primarySpeciality: Yup.string().required("*Required."),
      secondarySpeciality: Yup.string().required("*Required."),
      // licenceNumber: Yup.string().required("*Required").trim(),
      year: Yup.number().required("*Required").typeError("*Please enter only digit"),
      month: Yup.number().required("*Required").typeError("*Please enter only digit"),
      availabilty: Yup.string().required("*Required"),
      ServiceChrge: Yup.number().required("*Required").typeError("*Please enter only digit"),
    }),
  });

  useEffect(() => {
    primaryListCall();
  }, []);

  useEffect(() => {
    if (formik.values.primarySpeciality) {
      setSpecialityArray([]);
      secondaryListCall(formik.values.primarySpeciality);
    }
  }, [formik.values.primarySpeciality]);

  useEffect(() => {
    if (medicalFile) {
      setMedicalFileErr("");
    }
  }, [medicalFile]);

  const handleNext = async () => {
    console.log(
      "11111111111111",
      !!formik.values.primarySpeciality,
      !!specialityArray.length,
      !!formik.values.licenceNumber,
      !!formik.values.month,
      !!formik.values.year,
      !!formik.values.ServiceChrge,
      !!availabilty,
      !!medicalFile
    );
    if (availabilty.length == 0) {
      setAvailabilityError("*Please select availability.");
    } else {
      setAvailabilityError("");
    }
    if (Image !== "") {
      setImageErr("");
    }
    if (medicalFile === "") {
      setMedicalFileErr("*Required");
    } else {
      setMedicalFileErr("");
    }
    if (specialityArray.length == 0) {
      setSecondaryError("*Required.");
    } else {
      setSecondaryError("");
    }
    console.log(formik.values);

    if (
      !!formik.values.primarySpeciality &&
      !!specialityArray.length &&
      !!formik.values.month &&
      !!formik.values.year &&
      !!formik.values.ServiceChrge &&
      !!availabilty &&
      !!medicalFile
    ) {
      console.log("coming here");
      if (TermsAndCondi === false) {
        ToasterFunc("error", "Please accept the term and Condition");
      }
    }
    if (
      !!formik.values.primarySpeciality &&
      !!specialityArray.length &&
      !!formik.values.month &&
      !!formik.values.year &&
      !!formik.values.ServiceChrge &&
      !!availabilty &&
      !!medicalFile &&
      !!TermsAndCondi
    ) {
      console.log("Success");
      const payloadData = {
        // businessLogo: Image,
        primary_specialtyId: formik.values.primarySpeciality,
        secondry_specialty: specialityArray,
        licenceNumber: formik.values.licenceNumber,
        experience_in_years: formik.values.year,
        experience_in_months: formik.values.month,
        serviceCharge: formik.values.ServiceChrge,
        availability: availabilty,
        degreeFile: medicalFile ? medicalFile : "",
      };
      console.log("paylooasd", payloadData);
      const res = await professionalSignup(payloadData);
      if (res.status == 200) {
        // history.push(ROUTES_CONSTANTS.DASHBOARD);
        sessionStorage.removeItem("termCondi");
        sessionStorage.removeItem("authToken");
        setisAdminNotVarifired(true);
        setConfirmModal(!ConfirmModal);
      }
    }
  };

  console.log("TermsAndCondi", TermsAndCondi);

  const handleSecSpe = () => {
    if (formik.values.primarySpeciality) {
      setSecondSpeModule(!SecondSpeModule);
      setSecondaryError("");
    } else setSecondaryError("*Please select primary speciality first.");
  };
  const handleAvailabiltty = () => {
    setAvailabilityError("");
    setAvaialabiltyModal(!AvaialabiltyModal);
  };

  const handleImage = (e) => {
    const file = e.target.files[0];
    console.log(file);
    let formData = new FormData();
    formData.append("file", file);
    fileUpload(formData);

    if (file.type.split("/")[0] !== "image") {
      ToasterFunc("success", "Only image files are accepted");
    } else {
      setImage(
        Object.assign(file, {
          preview: URL.createObjectURL(file),
        })
      );
    }
  };

  const fileUpload = async (image) => {
    const res = await CommonProfilePic(image);
    if (res && res.status == 200) {
      let picdata = res && res.data && res.data.result.url;
      setImage(picdata);
    }
  };

  const getSecondaryList = () => {
    return specialityArray.map((speciality) => secondaryList.filter((data) => data._id === speciality)?.[0]?.secondry_specialty);
  };

  const getAvailabilityList = () => {
    return availabilty
      .filter((filterDay) => filterDay.slots?.length > 0)
      .map((availableDay) => availableDay.slots?.length > 0 && availableDay.day);
  };

  const handleUploadFile = (e) => {
    let result = e.target.value;
    console.log(e.target.value);
    const file = e.target.files[0];
    console.log(file);
    let formData = new FormData();
    console.log("file changed");

    if (file) {
      let fileSize = file.size / 1e6;
      console.log("fileSize", fileSize);
      if (fileSize > 10) {
        ToasterFunc("error", "Please upload a file smaller than 10 MB");
      } else {
        formData.append("file", file);
        medicalFileUpload(formData);
      }
    }
  };

  const medicalFileUpload = async (image) => {
    const res = await commonUploadedFile(image);
    if (res && res.status == 200) {
      setmedicalFile(res && res.data && res.data.result && res.data.result.url);
    }
  };

  // useEffect(() => {
  let yearArr = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
    37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 62, 63, 64, 65, 66, 67, 68, 69,
    70,
  ];

  let monthArr = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

  // }, [])

  const handleTermAndCondi = (e) => {
    let result = e.target.checked;
    console.log("result", result);
    setTermsAndCondi(result);
  };

  const options = [
    { value: "Heart Specialist", label: "Heart Specialist" },
    { value: "Gastrology", label: "Gastrology" },
    { value: "Dentist", label: "Dentist" },
    { value: "Neorologist", label: "Neorologist" },
    { value: "Physician", label: "Physician" },
    { value: "Metabolic", label: "Metabolic" },
    { value: "Coach", label: "Coach" },
    { value: "Surgery Expert", label: "Surgery Expert" },
  ];

  const handleTermAndCondition = () => {};
  return (
    <div className="index-home-padding">
      <div className="container-fluid">
        <div className="row align-items-center justify-content-center">
          <div className="col-md-6 login-left">
            <img src={IMAGE_CONSTANT.LOGIN_BANNER} className="img-fluid" alt="Doccure Register" />
          </div>
          <div className="col-xl-6 col-lg-12 col-md-12 login-right">
            <div className="container">
              <div className="row mb-5">
                <div className="col-md-12">
                  <div className="stepPregresbar ">
                    <div className="round-stepCircle ">1</div>
                    <div className="round-stepCircle active">2</div>
                  </div>
                  <div className="d-flex justify-content-around">
                    <label for="">Personal</label>
                    <label for="">Professional</label>
                  </div>
                </div>
              </div>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="row form-row">
                <div className="col-md-12">
                  <div className="form-group">
                    <div className="change-avatar justify-content-center">
                      <div className="profile-round-img">
                        <figure>
                          <img src={location?.state} alt="" />
                        </figure>
                        {/* <label for="camera">
                          <input type="file" id="camera" name="profilePic" accept="image/*" hidden onChange={(e) => handleImage(e)} />
                          <i class="fas fa-camera icon-camera"></i>
                        </label> */}
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                  {/*custom select */}
                  {/* <label for="">Select Primary Speciality*</label>
                  <div className="custm-select form-group">
                    <Select options={options} className="form-control" />
                  </div> */}
                  {/*custom  select */}

                  <div className="form-group position-rel">
                    <label for="">Select Primary Speciality*</label>
                    <select className="form-control" name="primarySpeciality" {...formik.getFieldProps("primarySpeciality")}>
                      <option hidden>Select</option>
                      {primaryList?.map((item) => {
                        return <option value={item._id}>{item.primary_specialty}</option>;
                      })}
                    </select>
                    {formik.touched.primarySpeciality && formik.errors.primarySpeciality ? (
                      <span className="validation_error">{formik.errors.primarySpeciality}</span>
                    ) : null}
                  </div>
                </div>
                <div className="col-md-6">
                  <label for="">Select Secondary Speciality*</label>
                  <div className="form-group position-rel">
                    {console.log("specialityArray", specialityArray)}
                    <input
                      type="text"
                      className="form-control "
                      readOnly
                      value={specialityArray.length > 0 ? getSecondaryList() : "Select"}
                      onClick={handleSecSpe}
                    />
                    {console.log("specialityArray", specialityArray, secondaryList)}
                    <span className="arrow-position">
                      <i className="fas fa-angle-right"></i>
                    </span>
                    <span className="validation_error">{secondaryError}</span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label for="">License Number*</label>
                    <input
                      type="text"
                      className="form-control"
                      name="licenceNumber"
                      maxLength="9"
                      placeholder="Enter License Number"
                      {...formik.getFieldProps("licenceNumber")}
                    />
                    {/* {formik.touched.licenceNumber && formik.errors.licenceNumber ? <span className="validation_error">{formik.errors.licenceNumber}</span> : null} */}
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="form-group">
                    <label for="">Experience</label>
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="form-group">
                    {/* <input type="text" className="form-control" name="year" maxLength="3" placeholder="Year" {...formik.getFieldProps("year")} /> */}
                    <select className="form-control" {...formik.getFieldProps("year")}>
                      <option value="0" hidden>
                        Select Year
                      </option>
                      {yearArr?.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                    {formik.touched.year && formik.errors.year ? <span className="validation_error">{formik.errors.year}</span> : null}
                  </div>
                </div>
                <div className="col-md-4 col-6">
                  <div className="form-group">
                    {/* <input type="text" className="form-control" name="month" maxLength="3" placeholder="Month" {...formik.getFieldProps("month")} /> */}
                    <select className="form-control" {...formik.getFieldProps("month")}>
                      <option value="0" hidden>
                        Select Month
                      </option>
                      {monthArr?.map((item) => (
                        <option value={item}>{item}</option>
                      ))}
                    </select>
                    {formik.touched.month && formik.errors.month ? <span className="validation_error">{formik.errors.month}</span> : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <label for="">Schedule Availability</label>
                  <div className="form-group position-rel">
                    <input
                      type="text"
                      className="form-control "
                      // value="Select"
                      data-toggle="modal"
                      data-target="#selectAvailbility_Modal"
                      data-dismiss="modal"
                      name="availabilty"
                      value={availabilty.length > 0 ? getAvailabilityList() : "Select"}
                      onClick={handleAvailabiltty}
                    />
                    {<span className="validation_error">{availabilityError}</span>}
                    <span className="arrow-position">
                      <i className="fas fa-angle-right"></i>
                    </span>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Enter Service Charge($) </label>
                    <input
                      type="text"
                      className="form-control"
                      name="ServiceChrge"
                      maxLength="4"
                      placeholder="Service Charge"
                      {...formik.getFieldProps("ServiceChrge")}
                    />

                    {/* <span>
                      <i className="fa fa-info-circle mr-1"></i> Platform fee will be 20% of amount you entered
                    </span> */}
                    {formik.touched.ServiceChrge && formik.errors.ServiceChrge ? (
                      <span className="validation_error">{formik.errors.ServiceChrge}</span>
                    ) : null}
                  </div>
                </div>
              </div>

              <div class="form-group">
                <label for="uploadPdf">Degree Proof</label>
                <div class="upload-pdf-border">
                  {/* <img className="uploaded_file" src={PdfIc} alt="User pdf"/> */}
                  <label class="upload-reports-pdf mb-0">
                    <input type="file" accept="application/pdf" hidden onChange={(e) => handleUploadFile(e)} />
                    {medicalFile ? (
                      <div className="d-flex">
                        <div className="uploaded_view">
                          <span
                            className="file_remove shadow"
                            // onClick={() => setImage("")}
                          ></span>
                          <img style={{ width: "40px", height: "40px" }} src={PdfIc} alt="img" />
                        </div>
                      </div>
                    ) : (
                      <i class="fas fa-file-pdf color-grey" name="MedicalFiles" />
                    )}
                  </label>
                  <div>
                    <span>Upload Document</span>
                  </div>
                </div>
                {MedicalFileErr ? <span className="validation_error">{MedicalFileErr}</span> : null}
              </div>
              <div className="col-md-12 pl-0 mt-4">
                <div className="form-group">
                  <input type="checkbox" id="privacy" onChange={(e) => handleTermAndCondi(e)} />
                  <label for="privacy" className="ml-2 privacy">
                    <span className="color-grey"> Please accept the </span>{" "}
                    <a
                      onClick={() => {
                        sessionStorage.setItem("termCondi", true);
                        history.push(ROUTES_CONSTANTS.TERMS_AND_CONDITIONS);
                      }}
                      // href={ROUTES_CONSTANTS.TERMS_AND_CONDITIONS}
                      // target="_blank"
                    >
                      {" "}
                      Term and Condition.{" "}
                    </a>
                  </label>
                </div>
              </div>

              <div className=" btn-p">
                <button type="submit" className="btn btn-primary btn-new" onClick={handleNext}>
                  Next
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ConfirmationModal ConfirmModal={ConfirmModal} setConfirmModal={setConfirmModal} isAdminNotVarifired={isAdminNotVarifired} />
      <SecondarySpecialityModule
        SecondSpeModule={SecondSpeModule}
        setSecondSpeModule={setSecondSpeModule}
        specialityArray={specialityArray}
        setSpecialityArray={setSpecialityArray}
        SpeciolityArrData={SpeciolityArr}
      />
      <DocAvailabiltyModal
        AvaialabiltyModal={AvaialabiltyModal}
        setAvaialabiltyModal={setAvaialabiltyModal}
        startDate={startDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        endDate={endDate}
        timeSlotDay={timeSlotDay}
        setTimeSlotDay={setTimeSlotDay}
        intervel={intervel}
        setIntervel={setIntervel}
        setAvailability={setAvailability}
      />
    </div>
  );
}

export default DocProfessional;
