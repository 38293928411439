import NotificationServices from "../../../services/notificationServices/NotificationServices";
import { commonAction } from "../commonAction";
import notificationActionType from "./notificationActionType";

export const getNotificationList = (pageNumber, limit) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await NotificationServices.notificationList(
        pageNumber,
        limit
      );

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: notificationActionType.SET_NOTIFICATION_LIST,
          notificationList: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const deleteNotificationAction = (id) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await NotificationServices.deleteNotification(id);

      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export default {
  getNotificationList,
  deleteNotificationAction,
};
