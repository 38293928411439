import React, { useState } from "react";
import defaultImg from "../../../assets/img/default-img.png";
import { useHistory } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../../constants";
import CancelAppointment from "../../common/CancelModal/CancelModal";

function MedicalViewAppointment({ UpcomingAppointsList, PreviousAppToggole }) {
  const [CancelModal, setCancelModal] = useState(false);
  let today = new Date();
  let history = useHistory();
  const year = today.getFullYear();
  const handleReschudule = () => {
    history.push(`${ROUTES_CONSTANTS.DOCTOR_SIDE_RESCHEDULE_APPOINTMENT}/${UpcomingAppointsList._id}`);
  };
  const handleCancel = () => {
    setCancelModal(!CancelModal);
  };
  return (
    <>
      <div id="password_tab" className="tab-pane fade show active">
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-body">
                <div className="appointment-list">
                  <div className="profile-info-widget">
                    <a className="booking-doc-img">
                      <img src={UpcomingAppointsList && UpcomingAppointsList.patientId && UpcomingAppointsList.patientId.profilePic ? UpcomingAppointsList.patientId.profilePic : defaultImg} alt="User Image" />
                    </a>
                    <div className="profile-det-info">
                      <h3>
                        <a>{`${UpcomingAppointsList && UpcomingAppointsList.patientId && UpcomingAppointsList.patientId.firstName ? UpcomingAppointsList.patientId.firstName : ""}
                        ${UpcomingAppointsList && UpcomingAppointsList.patientId && UpcomingAppointsList.patientId.lastName ? UpcomingAppointsList.patientId.lastName : ""}`}</a>
                      </h3>
                      <div className="patient-details">
                        <h5>
                          <b>Email:</b> {UpcomingAppointsList && UpcomingAppointsList.patientId && UpcomingAppointsList.patientId.email ? UpcomingAppointsList.patientId.email : "--"}
                        </h5>
                        <h5>
                          <b>Phone Number:</b>
                          &nbsp;
                          {UpcomingAppointsList && UpcomingAppointsList.patientId && UpcomingAppointsList.patientId.mobileNumber ? UpcomingAppointsList.patientId.mobileNumber : "--"}
                        </h5>
                        <h5>
                          <b>Age Group:</b>{" "}
                          {UpcomingAppointsList && UpcomingAppointsList.patientId && UpcomingAppointsList.patientId.date_of_birth
                            ? year - (UpcomingAppointsList.patientId.date_of_birth ? UpcomingAppointsList.patientId.date_of_birth.split("/")[2] : " ")
                            : "--"}
                        </h5>
                      </div>
                    </div>
                  </div>
                  <div className="appointment-action">
                    <a  className="btn btn-sm bg-info-light" data-toggle="modal" data-target="#appt_details">
                      Send Message
                    </a>
                  </div>
                </div>
                <div className="row">
                  <p className="col-sm-2 text-muted text-sm-right mb-0 mb-sm-3">Height</p>
                  <p className="col-sm-10">{UpcomingAppointsList && UpcomingAppointsList.patientId && UpcomingAppointsList.patientId.height ? UpcomingAppointsList.patientId.height : "--"} cm</p>
                </div>
                <div className="row">
                  <p className="col-sm-2 text-muted text-sm-right mb-0 mb-sm-3">Weight</p>
                  <p className="col-sm-10">{UpcomingAppointsList && UpcomingAppointsList.patientId && UpcomingAppointsList.patientId.weight ? UpcomingAppointsList.patientId.weight : "--"} kg</p>
                </div>
                <div className="row">
                  <p className="col-sm-2 text-muted text-sm-right mb-0 mb-sm-3">Blood Group </p>
                  <p className="col-sm-10">{UpcomingAppointsList && UpcomingAppointsList.patientId && UpcomingAppointsList.patientId.bloodGroup ? UpcomingAppointsList.patientId.bloodGroup : "--"}</p>
                </div>
                <div className="row">
                  <p className="col-sm-2 text-muted text-sm-right mb-0 mb-sm-3">Diabetes</p>
                  <p className="col-sm-10">{UpcomingAppointsList && UpcomingAppointsList.patientId && UpcomingAppointsList.patientId.iSDiabetes ? "Yes" : "No"}</p>
                </div>
                <div className="row">
                  <p className="col-sm-2 text-muted text-sm-right mb-0 mb-sm-3">
                    <b>Diabetes Details</b>
                  </p>
                  <p className="col-sm-10">{UpcomingAppointsList && UpcomingAppointsList.patientId && UpcomingAppointsList.patientId.diabetesDetails ? UpcomingAppointsList.patientId.diabetesDetails : "--"}</p>
                </div>
                <div className="row">
                  <p className="col-sm-2 text-muted text-sm-right mb-0 mb-sm-3">
                    <b> Allergies Details</b>
                  </p>
                  <p className="col-sm-10">{UpcomingAppointsList && UpcomingAppointsList.patientId && UpcomingAppointsList.patientId.allergyDeatils ? UpcomingAppointsList.patientId.allergyDeatils : "--"}</p>
                </div>
                <div className="row">
                  <p className="col-sm-2 text-muted text-sm-right mb-0 mb-sm-3">
                    <b> Family Notes</b>
                  </p>
                  <p className="col-sm-10 mb-0">{UpcomingAppointsList && UpcomingAppointsList.patientId && UpcomingAppointsList.patientId.familyNotes ? UpcomingAppointsList.patientId.familyNotes : "--"}</p>
                </div>
                <div className="row">
                  <p className="col-sm-2 text-muted text-sm-right mb-0 mb-sm-3">
                    <b>Medical details</b>
                  </p>
                  <p className="col-sm-10 mb-0">
                    {/* <label className="upload-reports-pdf">
                    <input type="file" hidden />
                    <span>
                    <i className="fa fa-file-pdf fa-3x"></i>
                    </span>
                  </label> */}
                    <span>
                      <a href={UpcomingAppointsList?.patientId?.medicalFile}>
                        <i className="fa fa-file-pdf fa-3x"></i>
                      </a>
                    </span>
                  </p>
                </div>
              </div>
            </div>
            {/* <!-- button bootom  --> */}
            {UpcomingAppointsList && (UpcomingAppointsList.status === "cancelled" || UpcomingAppointsList.newStatus === "completed" || PreviousAppToggole === "PreVApp") ? (
              ""
            ) : (
              <div className="row">
                <div className="col-md-6 col-lg-3 col-sm-6 col-6 mb-2" onClick={handleReschudule}>
                  <a className="btn book-btn">Reschedule</a>
                </div>
                <div className="col-md-6 col-lg-3 col-sm-6 col-6 mb-2">
                  <a href="video-call.html" className="btn book-btn">
                    Video
                  </a>
                </div>
                <div className="col-md-6 col-lg-3 col-sm-6 col-6 mb-2">
                  <a href="voice-call.html" className="btn book-btn">
                    Audio
                  </a>
                </div>
                <div className="col-md-6 col-lg-3 col-sm-6 col-6 ">
                  <a className="btn view-btn" onClick={handleCancel}>
                    Cancel
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <CancelAppointment UpcomingAppointsList={UpcomingAppointsList} CancelModal={CancelModal} setCancelModal={setCancelModal} />
    </>
  );
}

export default MedicalViewAppointment;
