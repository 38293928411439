import { httpService } from "..";
import ToasterFunc from "../../components/common/toaster/Toaster";
import { API_CONSTANTS } from "../../constants";

class BookingServices {
  async getDoctorDetails(DocId) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.DOCTOR_DETAILS}/${DocId}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async getSlotsList(id, date) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.SLOTS_LIST}serviceProviderId=${id}&bookingDate=${date}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async bookAppoitment(payload) {
    try {
      const response = await httpService.post(`${API_CONSTANTS.BOOK_APPOITMENTS}`, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async getSlotsListForReschedule(date) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.DOCTOR_GET_SLOTS_FOR_RESCHEDULE}bookingDate=${date}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async rescheduleAppoitment(payload) {
    try {
      const response = await httpService.post(`${API_CONSTANTS.DOCTOR_RESCHEDULE_APPOINTMENT}`, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async cancelAppointment(payload) {
    try {
      const response = await httpService.post(`${API_CONSTANTS.DOCTOR_CANCEL_APPOINTMENT}`, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async rescheduleAppoitmentPatient(payload) {
    try {
      const response = await httpService.post(`${API_CONSTANTS.PATIENT_RESCHEDULE_APPOINTMENT}`, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async cancelAppointmentPatient(payload) {
    try {
      const response = await httpService.post(`${API_CONSTANTS.PATIENT_CANCEL_APPOINTMENT}`, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async getLabSlotsList(id, date) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.PATIENT_LAB_SLOT_LIST}?laboratoryId=${id}&bookingDate=${date}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async bookLabAppoitment(payload) {
    try {
      const response = await httpService.post(`${API_CONSTANTS.PATIENT_BOOK_LABORATORY}`, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async rescheduleLabAppoitment(payload) {
    try {
      const response = await httpService.post(`${API_CONSTANTS.PATIENT_RESCHEDULED_LABORATORY}`, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async cancelLabAppointment(payload) {
    try {
      const response = await httpService.post(`${API_CONSTANTS.PATIENT_CANCEL_LABORATORY}`, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
}

export default new BookingServices();
