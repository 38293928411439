import React, { useState, useEffect } from "react";
import { Modal } from "reactstrap";
import { useHistory } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../../constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { authentication } from "../../../redux/action";
import ToasterFunc from "../../common/toaster/Toaster";
import PaymentDetails from "./PaymentDetails";
import BillDetails from "./BillDetails";
import { getSessionStorageOrDefault } from "../../../utils";

function Payment({ setPaymentModal, PaymentModal, isOpenDocSide, SubscriptionPrice, SubscriptionId, Image, DocImage }) {
  let dispatch = useDispatch();
  let history = useHistory();

  const [couponId, setCouponId] = useState(null);
  const [CardErr, setCardErr] = useState("");
  const [priceValue, setPriceValue] = useState("");
  const [CardType, setCardType] = useState({
    credit: true,
    debit: false,
  });
  const [bothCardDetails, setCardDetails] = useState({
    credit: {},
    debit: {},
  });
  const [showBill, setShowBill] = useState(false);
  const [isSaved, setisSaved] = useState(false);
  const [CardnumErr, setCardnumErr] = useState("");

  const handleClose = () => {
    formik.values.fullname = "";
    formik.values.CardNum = "";
    formik.values.cvv = "";
    formik.values.cardexpiry = "";
    setPaymentModal(false);
    setShowBill(false);
  };

  const paymentAccountPatient = async (payload) => dispatch(authentication.paymentAccountPatientAction(payload));

  const paymentAccountDoctor = async (payload) => dispatch(authentication.paymentAccountDoctorAction(payload));
  const couponList = async () => dispatch(authentication.couponListAction());

  useEffect(() => {
    couponList();
  }, []);

  const formik = useFormik({
    initialValues: {
      fullname: "",
      CardNum: "",
      cvv: "",
      cardexpiry: "",
    },
    validationSchema: Yup.object({
      fullname: Yup.string()
        .required("*Required.")
        .matches(/^[^-\s][\w\s-]+$/, "Please Enter required value"),
      CardNum: Yup.number().required("*Required.").typeError("*Please enter only digits"),
      cvv: Yup.number().required("*Required.").typeError("*Please enter only digits"),
      cardexpiry: Yup.string().required("*Required.").typeError("*Please enter only digits"),
    }),
  });

  useEffect(() => {
    if (CardType.credit === true || CardType.debit === true) {
      setCardErr("");
    }
    if (formik.values.CardNum.replaceAll(" " , "").length === 16) {
      setCardnumErr("");
    }
  }, [CardType, formik.values.CardNum]);

  // console.log("fullname", fullname);

  const handlePayment = async () => {
    console.log("PRICEEE", priceValue);
    const paymentPayload = {
      subscriptionId: SubscriptionId,
      cardType: CardType.credit ? "CREDIT" : "DEBIT",
      cardHolderName: formik.values.fullname,
      cardNumber: formik.values.CardNum.replaceAll(" " , ""),
      exp_month: formik.values && formik.values.cardexpiry ? formik.values.cardexpiry.split("/")[0] : "",
      exp_year: formik.values && formik.values.cardexpiry ? formik.values.cardexpiry.split("/")[1] : "",
      cvc: formik.values.cvv,
      price: Number(priceValue),
      cardSave: isSaved ? true : false,
    };

    if (couponId) {
      paymentPayload.couponId = couponId?._id;
    }

    console.log(CardType.credit, CardType.debit);
    if (CardType.credit !== false || CardType.debit !== false) {
      if (formik.values.CardNum !== "" && formik.values.CardNum.replaceAll(" " , "").length === 16 && formik.values.fullname !== "" && formik.values.cardexpiry !== "" && formik.values.cvv !== "") {
        const userType = await getSessionStorageOrDefault("userType");
        let res = null;
        if (userType == "1") {
          res = await paymentAccountDoctor(paymentPayload);
        } else {
          res = await paymentAccountPatient(paymentPayload);
        }
        if (res && res.status == 200) {
          ToasterFunc("success", res.data.responseMessage);
          console.log("paymenttttttttt", res);
          if (userType == "1") {
            history.push(ROUTES_CONSTANTS.DOC_PROFESSIONAL_SIGNUP, DocImage);
          } else if (userType == "2") {
            history.push(ROUTES_CONSTANTS.PAT_MEDICAL_SIGNUP, Image);
          }
        }
      }
    }
  };

  const handleConfirm = async (e) => {
    console.log("PRICE", e);
    if (CardType.credit === false && CardType.debit === false) {
      setCardErr("*Please Choose any one");
    }
    if (CardType.credit === true || CardType.debit === true) {
      setCardErr("");
    }
    if (formik.values.CardNum !== "" && formik.values.CardNum.replaceAll(" " , "").length < 16) {
      setCardnumErr("*Invalid Card Number");
    }
    if ((CardType.credit == true || CardType.debit == true) && formik.values.CardNum !== "" && formik.values.CardNum.replaceAll(" " , "").length === 16 && formik.values.fullname !== "" && formik.values.cardexpiry !== "" && formik.values.cvv !== "") {
      handlePayment();
    }
  };

  const handleCard = async (e) => {
    let name = e.target.value;
    let value = e.target.checked;
    if (name === "Credit") {
      setCardType({
        credit: value,
      });
      setCardDetails({
        ...bothCardDetails,
        debit: {
          CardNum: formik.values.CardNum.replaceAll(" " , ""),
          fullname: formik.values.fullname,
          cardexpiry: formik.values.cardexpiry,
          cvv: formik.values.cvv,
        },
      });
      formik.setFieldValue("CardNum", bothCardDetails?.credit?.CardNum || "");

      formik.setFieldValue("fullname", bothCardDetails?.credit?.fullname || "");

      formik.setFieldValue("cardexpiry", bothCardDetails?.credit?.cardexpiry || "");

      formik.setFieldValue("cvv", bothCardDetails?.credit?.cvv || "");
    } else if (name === "Debit") {
      setCardType({
        debit: value,
      });
      setCardDetails({
        ...bothCardDetails,
        credit: {
          CardNum: formik.values.CardNum.replaceAll(" " , ""),
          fullname: formik.values.fullname,
          cardexpiry: formik.values.cardexpiry,
          cvv: formik.values.cvv,
        },
      });
      formik.setFieldValue("CardNum", bothCardDetails?.debit?.CardNum ? bothCardDetails?.debit?.CardNum : "");
      formik.setFieldValue("fullname", bothCardDetails?.debit?.fullname ? bothCardDetails?.debit?.fullname : "");
      formik.setFieldValue("cardexpiry", bothCardDetails?.debit?.cardexpiry ? bothCardDetails?.debit?.cardexpiry : "");
      formik.setFieldValue("cvv", bothCardDetails?.debit?.cvv ? bothCardDetails?.debit?.cvv : "");
    }
    if (name === "SaveForLater") {
      setisSaved(true);
    }
  };

  return (
    <Modal isOpen={PaymentModal} className=" modal-dialog-centered payment-detail">
      <div className="modal-content">
        <button type="button" className="close custom-model-close" aria-label="Close" data-dismiss="modal" onClick={handleClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        {showBill ? (
          <PaymentDetails formik={formik} handleCard={handleCard} CardErr={CardErr} CardnumErr={CardnumErr} handleConfirm={handleConfirm} />
        ) : (
          <BillDetails
            Image={Image}
            SubscriptionId={SubscriptionId}
            couponId={couponId}
            setCouponId={setCouponId}
            handlePayment={(val) => {
              setShowBill(true);
              setPriceValue(val);
            }}
          />
        )}
      </div>
    </Modal>
  );
}

export default Payment;
