import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { bookingAction, patient } from "../../../../redux/action";
import Laboratory from "./laboratory/Laboratory";
import PreviousApp from "./PreviousApp/PreviousApp";
import UpcomingApp from "./UpcomingApp/UpcomingApp";
import ConfirmationModal from "../../findDoctorsNearBy/ConfirmationModal";
// import BookAppointment from "../../findDoctorsNearBy/BookAppointment";

function PatientAppointment() {
  let dispatch = useDispatch();
  const [isUpcoming, setisUpcoming] = useState(true);
  const [isPrevious, setisPrevious] = useState(false);
  const [isLaboratory, setisLaboratory] = useState(false);
  const [paymentStatus, setPaymentStatus] = useState('');
  const [ConfirmModal, setConfirmModal] = useState(false);
  const bookAppointment = async (payload) => dispatch(bookingAction.bookAppointmentAction(payload));

  const handleUpcoming = () => {
    setisUpcoming(true);
    setisPrevious(false);
    setisLaboratory(false);
  };
  const handlePrevious = () => {
    setisPrevious(true);
    setisUpcoming(false);
    setisLaboratory(false);
  };
  const handleLaboratory = () => {
    setisLaboratory(!isLaboratory);
    setisUpcoming(false);
    setisPrevious(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    upcomingAppointmentPatient();
    previousAppointmentPatient();
  }, []);

  useEffect(() => {
    async function bookAppointmentAction(payload) {
      const res = await bookAppointment(payload);
      if (res && res.status === 200) {
        // setConfirmModal(true);
        setPaymentStatus(paramValue)
        localStorage.removeItem('bookAppontmentPayload')
      } else if (res.status == 402) {
        console.log("Coming here");
        // setConfirmModal(true);
        localStorage.removeItem('bookAppontmentPayload')
        alert("Error in Appointment Booking!")
        // const payloadWithPic = {
        //   docDetail: doctorDetails,
        //   otherDetail: payload,
        //   // upgradeDetail : UpgradeToggle
        // };
        // setDocData(payloadWithPic);
        // setPaymentOption(!PaymentOption);
      }
    }
    async function getquerystring() {
      if (callfrom === "Appointment") {
        const AppointmentPayload = JSON.parse(localStorage.getItem('bookAppontmentPayload'));
        await bookAppointmentAction(AppointmentPayload)
      }
      // setPaymentStatus(paramValue)
      // console.log(paramValue);
    }
    const queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const paramValue = urlParams.get('payment');
    const callfrom = urlParams.get("callFrom");
    getquerystring();
  }, []);

  useEffect(() => {
    if (paymentStatus && paymentStatus === 'success') {
      // alert("Subscription Done!")
      setConfirmModal(true)

    }
  }, [paymentStatus])

  const upcomingAppointmentList = useSelector((state) => state?.patient?.patientUpcomingAppointmentList?.appointmentData);
  const previousAppointmentList = useSelector((state) => state?.patient?.patientPreviousAppointmentList?.appointmentData);

  console.log("previousAppointmentList", previousAppointmentList);

  const upcomingAppointmentPatient = async () => dispatch(patient.upcomingAppointmentPatientAction());
  const previousAppointmentPatient = async () => dispatch(patient.previousAppointmentPatientAction());
  return (
    <div class=" col-lg-8 col-xl-9 content_ipad_p">
      <div class="card">
        <div class="card-body pt-3">
          {/* <!-- Tab Menu --> */}
          <nav class="user-tabs">
            <ul class="nav nav-tabs nav-tabs-bottom nav-justified mobile-tabs">
              <li class="nav-item">
                <a class={isUpcoming ? "nav-link active text-nowrap" : "nav-link  text-nowrap"} onClick={handleUpcoming}>
                  Upcoming Appointments
                </a>
              </li>
              <li class="nav-item">
                <a class={isPrevious ? "nav-link active text-nowrap" : "nav-link  text-nowrap"} onClick={handlePrevious}>
                  Previous Appointment
                </a>
              </li>
              <li class="nav-item">
                <a class={isLaboratory ? "nav-link active text-nowrap" : "nav-link  text-nowrap"} onClick={handleLaboratory}>
                  Laboratories
                </a>
              </li>
            </ul>
          </nav>
          <div class="laboratory-appnt tab-content">
            {isUpcoming ? <UpcomingApp upcomingAppointmentList={upcomingAppointmentList} /> : isPrevious ? <PreviousApp previousAppointmentList={previousAppointmentList} /> : isLaboratory ? <Laboratory /> : null}
          </div>
        </div>
      </div>
      <ConfirmationModal ConfirmModal={ConfirmModal} setConfirmModal={setConfirmModal} />
    </div>
  );
}

export default PatientAppointment;
