import React from "react";
import { useDispatch } from "react-redux";
import { Modal } from "reactstrap";
import { doctor } from "../../../../redux/action";
import ToasterFunc from "../../../common/toaster/Toaster";

const DeleteModal = ({ deletePrescription, setDeletePrescription, presId, Patid }) => {
  const dispatch = useDispatch();
  const handleClose = () => {
    setDeletePrescription(false);
  };
  const deletePrescriptionItem = async (payload) => dispatch(doctor.docDeletePrescriptionAction(payload));
  const docPrescriptionList = async (Patid) => dispatch(doctor.docPresciptionListAction(Patid));

  const handleConfirm = () => {
    deletePrescriptionItem(presId);
    setDeletePrescription(false);
    docPrescriptionList(Patid);
  };

  console.log(presId);
  return (
    <Modal isOpen={deletePrescription} className="delete-modal modal-dialog-centered">
      <div class="modal-content p-3">
        <button type="button" class="close" onClick={() => setDeletePrescription(false)}>
          <span aria-hidden="true">×</span>
        </button>
        <div class="modal-body">
          <div class="card mb-0">
            <div class="card-body">
              <h4 class="text-center justify-content-center mb-2">Delete</h4>
              <p class="color-grey text-center">Are you sure want to delete this?</p>
              <div class="d-flex justify-content-center">
                <button class="btn view-btn w-25 mr-2" onClick={handleClose}>
                  No{" "}
                </button>
                <button class="btn book-btn w-25" onClick={handleConfirm}>
                  Yes
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteModal;
