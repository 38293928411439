import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { doctor } from "../../../../redux/action";
import ViewBill from "./ViewBill";

const Billing = ({ Patid }) => {
  let dispatch = useDispatch();
  const [ViewModal, setViewModal] = useState(false);
  const [Invoiceid, setInvoiceid] = useState("");

  const doctorinvoicelist = async (payload) => dispatch(doctor.doctorinvoicelistAction(payload));
  useEffect(() => {
    window.scrollTo(0, 0);
    console.log('window after scroll');
  }, [])

  useEffect(() => {
    if (Patid) {
      doctorinvoicelist(Patid);
    }
  }, [Patid]);

  
  

  const doctorinvoicedata = useSelector((state) => state?.doctor?.invoiceListInfo);

  console.log("doctorinvoicedata", doctorinvoicedata);
  const handleView = (InvoiceId) => {
    setViewModal(!ViewModal);
    setInvoiceid(InvoiceId);
  };
  return (
    // <!-- Billing Content -->
    <div role="tabpanel" id="Billing" className="tab-pane fade show active">
      <div className="row">
        <div className="col-md-12">
          <div className="card card-table mb-0">
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover table-center mb-0">
                  <thead>
                    <tr>
                      <th className="text-left">Invoice Number</th>
                      <th> Date </th>
                      <th>Amount($)</th>
                      <th className="text-right">Action </th>
                    </tr>
                  </thead>
                  <tbody>
                    {doctorinvoicedata?.length ? (
                      doctorinvoicedata.map((item, index) => (
                        <tr key={index}>
                          <td className="text-left">{item?.invoiceNumber}</td>
                          <td>{moment(item?.createdAt).format("DD-MM-YYYY")}</td>
                          <td>{"$"} {item?.amount}</td>
                          <td className="text-right">
                            <div className="table-action-btn">
                              <button onClick={() => handleView(item._id)} className="btn btn-sm bg-info-light">
                                <i className="far fa-eye"></i> View
                              </button>

                              {/* <button href="javascript:void(0);" className="btn btn-sm bg-success-light">
                                <i className="fas fa-print"></i> Print
                              </button> */}
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="col-md-12">
                          <p className="text-center">No bills available.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ViewBill ViewModal={ViewModal} setViewModal={setViewModal} Invoiceid={Invoiceid} />
    </div>
    // <!-- /Billing  Content -->
  );
};

export default Billing;
