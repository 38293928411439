import { Modal, ModalBody } from "reactstrap";
import Slider, { Range } from "rc-slider";
import "rc-slider/assets/index.css";
import { useState, useEffect } from "react";
import { patient } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ToasterFunc from "../common/toaster/Toaster";
import InputRange from "react-input-range";
import "../../../node_modules/react-input-range/lib/css/index.css";
import InputRangeSlider from "../common/InputRangeSlider/InputRangeSlider";
import { useFormik } from "formik";
import * as Yup from "yup";

function FilterModal({ FilterModals, setFilterModals, nearByPhysicianList, arrData, getLocation, DocFilterData }) {
  let history = useHistory();
  let dispatch = useDispatch();
  const { createSliderWithTooltip } = Slider;
  const Range = createSliderWithTooltip(Slider.Range);
  const [SpeId, setSpeId] = useState({
    PrimarySpeId: "",
    SecondarySpeId: "",
  });
  const [FilterData, setFilterData] = useState({});
  const [FilterToggle, setFilterToggle] = useState(false);
  const handleClose = () => {
    setFilterModals(false);
  };

  const formik = useFormik({
    initialValues: {
      SpeName: "",
    },
    validationSchema: Yup.object({
      SpeName: Yup.string().required("*Email is required."),
    }),
  });

  useEffect(() => {
    if (nearByPhysicianList && nearByPhysicianList.userData) {
      nearByPhysicianList.userData.map((item) => {
        setSpeId({
          PrimarySpeId: item && item.serviceProviderType && item.serviceProviderType[0]._id,
        });
      });
    }
  }, [nearByPhysicianList]);

  useEffect(() => {
    if (SpeId.PrimarySpeId) {
      patientSecondarySpecialityData(SpeId.PrimarySpeId);
    }
  }, [SpeId.PrimarySpeId]);

  // useEffect(() => {
  //   if (FilterToggle) {
  //     setSpeId({
  //       SecondarySpeId: DocFilterData[0]?.SecondarySpeId,
  //     });
  //   }
  // }, [FilterToggle, DocFilterData]);

  const patientSecondarySpecialityList = useSelector((state) => state?.patient?.patientSecondarySpecialityList?.documentList);

  const patientSecondarySpecialityData = async (payloadId) => dispatch(patient.patientSecondarySpecialityListAction(payloadId));
  // const handleSpecialities = (e) => {
  //   console.log("e.target.value", e.target.value);
  //   setSpeId({
  //     SecondarySpeId: e.target.value,
  //   });
  // };

  // console.log(formik.values.SpeName);

  useEffect(() => {
    if (patientSecondarySpecialityList?.length) {
      patientSecondarySpecialityList.filter((item) => {
        if (item.secondry_specialty === formik.values.SpeName) {
          setSpeId({
            SecondarySpeId: item._id,
          });
        }
      });
    }
  }, [patientSecondarySpecialityList, formik.values.SpeName]);

  // useEffect(() => {
  //   if (formik.values.SpeName) {
  //     setSpeId({
  //       SecondarySpeId: formik.values.SpeName,
  //     });
  //   }
  // }, [formik.values.SpeName]);

  useEffect(() => {
    if (FilterToggle) {
      if (patientSecondarySpecialityList?.length) {
        patientSecondarySpecialityList.filter((item) => {
          if (item._id === DocFilterData[0]?.SecondarySpeId) {
            formik.setFieldValue("SpeName", item.secondry_specialty);
          }
        });
      }
    }
  }, [FilterToggle, patientSecondarySpecialityList, DocFilterData]);

  const getRangeData = (range) => {
    console.log("Working.........!", range);
    setFilterData(range);
  };

  console.log("FilterData", FilterData);

  const handleApply = async () => {
    // if (SpeId.SecondarySpeId != "") {

    setFilterToggle(true);
    let arr = [];
    // if (SpeId.SecondarySpeId) {
    await arr.push({
      secondarySpeId: SpeId.SecondarySpeId,
      ratingData: FilterData.rating,
      costData: FilterData?.cost,
      distanceData: FilterData.distance,
    });

    await arrData([...arr]);
    await setFilterModals(false);
    // }
    // } else {
    //   ToasterFunc("error", "Please Select Secondary Speciality");
    // }
    // getLocation();
  };

  console.log("DocFilterData", DocFilterData);

  return (
    <Modal isOpen={FilterModals} className="modal-dialog modal-dialog-centered filter_by_modal">
      <div className="modal-content p-3">
        <div className="text-right">
          <button type="button" className="dr_Modalclose payment-method-modal" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <div className="card mb-0">
            <div className="card-body">
              <h4 className="text-center justify-content-center mb-4">Filter By</h4>
              <div className="form-group">
                <select className="form-control" id="selectspaciality" name="SpeName" {...formik.getFieldProps("SpeName")}>
                  <option hidden>Select Speciality</option>
                  {patientSecondarySpecialityList && patientSecondarySpecialityList.length && patientSecondarySpecialityList.map((item) => <option value={item?.secondry_specialty}>{item && item.secondry_specialty}</option>)}
                </select>
              </div>

              <InputRangeSlider getRangeData={getRangeData} FilterToggle={FilterToggle} FilterData={FilterData} />

              {/* <div className="row form-group">
                <div className="col-md-4">
                  <label htmlFor="Rating">
                    <span>
                      <i className="fa fa-star mr-2"></i>
                      <span className="color-grey">Rating</span>
                    </span>
                  </label>
                </div>
                <div className="col-md-8">
                  <Range min={0} max={5} defaultValue={RatingSlider} tipFormatter={(value) => `${value}`} onChange={handleRating} />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-4">
                  <label htmlFor="Cost">
                    <span>
                      <i className="fas fa-money-bill mr-2"></i>
                      <span className="color-grey"> Cost</span>
                    </span>
                  </label>
                </div>
                <div className="col-md-8">
                  <Range min={0} max={1000} defaultValue={CostSlider} tipFormatter={(value) => `${value}$`} onChange={handleCost} />
                </div>
              </div>
              <div className="row form-group">
                <div className="col-md-4">
                  <label htmlFor="Distance">
                    <span>
                      <i className="fa fa-road mr-2"></i>
                      <span className="color-grey"> Distance</span>
                    </span>
                  </label>
                </div>
                <div className="col-md-8">
                 
                  <Slider defaultValue={DistanceSlider} onChange={handleDistance} />
                </div>
              </div> */}

              <div className="d-flex justify-content-center mt-5">
                <button className="btn view-btn mr-2" onClick={() => setFilterModals(false)}>
                  Cancel{" "}
                </button>
                <button className="btn book-btn" onClick={handleApply}>
                  Apply
                </button>
              </div>
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default FilterModal;
