import {
  Home,
  DashBoard,
  PatientDashboard,
  Chat,
  Appoitments,
  ScheduleAvailability,
  Review,
  Billings,
  Subscription,
  Notification,
  Help,
  Wallet,
  ProfileSettings,
  AboutUs,
  TermsAndConditions,
  PrivacyPolicy,
} from "../components";
import Prescription from "../components/appointments/viewAppointment/prescription/Prescription";
import RescheduleAppointment from "../components/appointments/viewAppointment/RescheduleAppointment";
import ViewAppointment from "../components/appointments/viewAppointment/ViewAppointment";
import DocPersonal from "../components/authentication/sign-up-as-doctor/DocPersonal";
import DocProfessional from "../components/authentication/sign-up-as-doctor/DocProfessional";
import PatientMedical from "../components/authentication/sign-up-as-patient/PatientMedical";
import PatientPersonal from "../components/authentication/sign-up-as-patient/PatientPersonal";
import ContactUs from "../components/cms/about-us/ContactUs";
import OurTeam from "../components/cms/about-us/OurTeam";
import CommonPage from "../components/cms/CommonPage";
import Testimonials from "../components/cms/members/Testimonials";
import { NewRequest } from "../components/help/newRequest";
import { ViewHelpRequest } from "../components/help/view";
import AppointmentDetail from "../components/PatientDashboard/findDoctorsNearBy/AppointmentDetail";
import BookAppointment from "../components/PatientDashboard/findDoctorsNearBy/BookAppointment";
import FindDoctorsNearBy from "../components/PatientDashboard/findDoctorsNearBy/FindDoctorsNearBy";
import PatientAppointmentDetail from "../components/PatientDashboard/findDoctorsNearBy/PatientAppointmentDetail";
import BookLaboratory from "../components/PatientDashboard/Laboartories/BookLaboratory";
import DeliveryLocation from "../components/PatientDashboard/Laboartories/DeliveryLocation";
import LaboratiesPackages from "../components/PatientDashboard/Laboartories/LaboratiesPackages";
import LaboratoryBill from "../components/PatientDashboard/Laboartories/LaboratoryBill";
import PatientDetail from "../components/PatientDashboard/Laboartories/PatientDetail";
import {
  MedicalHistory,
  Messages,
  MyReports,
  PatientBilling,
  PatientHelp,
  PatientReviews,
  PatientSettings,
  PatientSubscription,
  PatientWallet,
  Requests,
} from "../components/PatientDashboard/PatientComponents";
import { PatientAppointment } from "../components/PatientDashboard/PatientComponents/Appointment";
import CompletedViewApp from "../components/PatientDashboard/PatientComponents/Appointment/CompletedViewApp.js/CompletedViewApp";
import PatientRescheduleApp from "../components/PatientDashboard/PatientComponents/Appointment/ViewApp/PatientRescheduleApp";
import ViewAppointmentOfPatient from "../components/PatientDashboard/PatientComponents/Appointment/ViewApp/ViewAppointment";
import { PatientFevouriteSpecialist } from "../components/PatientDashboard/PatientComponents/FavouriteSpecialist";
import AddReqest from "../components/PatientDashboard/PatientComponents/Help/AddReqest";
import Patientrescription from "../components/PatientDashboard/PatientComponents/MedicalHistory/Prescription";
import ViewMedicalHistory from "../components/PatientDashboard/PatientComponents/MedicalHistory/ViewMedicalHistory";
import { PatientNotfications } from "../components/PatientDashboard/PatientComponents/Notifications";
import EditCard from "../components/PatientDashboard/PatientComponents/Settings/PaymentDetail/EditCard";
import ViewDoctorDetails from "../components/PatientDashboard/ViewDoctorDetails";
import DocEditPersonal from "../components/profileSettings/DocViewProfile/DocEditPersonal";
import DocEditProfessional from "../components/profileSettings/DocViewProfile/DocEditProfessional";
import DocViewProfile from "../components/profileSettings/DocViewProfile/DocViewProfile";
import PatEditPersonal from "../components/profileSettings/PatViewProfile/PatEditPersonal";
import PatViewProfile from "../components/profileSettings/PatViewProfile/PatViewProfile";
import PetEditMedical from "../components/profileSettings/PatViewProfile/PetEditMedical";
import { PageNotFount } from "../components/common/pageNotFound";

import { ROUTES_CONSTANTS } from "../constants";
import RescheduleLaboratory from "../components/PatientDashboard/Laboartories/RescheduleLaboratory";
import VideoCall from "../components/common/VideoCall/VideoCall";
import VoiceCall from "../components/common/VoiceCall/VoiceCall";
import ViewBills from "../components/PatientDashboard/PatientComponents/MedicalHistory/ViewBills";
import ViewHelpRequestInPatient from "../components/PatientDashboard/PatientComponents/Help/ViewHelpRequest";
import OutgoingCall from "../components/common/VideoCall/OutgoingCall";
import IncomingCall from "../components/common/VideoCall/IncomingCall";
import HomeSearch from "../components/common/HomeSearch/HomeSearch";
import DoctorProfile from "../components/doctor-profile/DoctorProfile";

const appRoutes = [
  {
    path: '/',
    exact: true,
    component: Home,
    authorized: true,
    name: "Home",
  },
  {
    path: ROUTES_CONSTANTS.HOME,
    exact: true,
    component: Home,
    authorized: true,
    name: "Home",
  },
  {
    path: ROUTES_CONSTANTS.HOME_SEARCH,
    exact: true,
    component: HomeSearch,
    authorized: false,
    name: "Home",
  },
  {
    path: `${ROUTES_CONSTANTS.HOME_REFFRAL}/:referralCode`,
    exact: true,
    component: Home,
    authorized: false,
    name: "Home",
  },
  {
    path: ROUTES_CONSTANTS.DOC_PERSONAL_SIGNUP,
    exact: true,
    component: DocPersonal,
    authorized: true,
    name: "Sign up",
  },
  {
    path: ROUTES_CONSTANTS.DOC_PROFESSIONAL_SIGNUP,
    exact: true,
    component: DocProfessional,
    authorized: true,
    name: "Sign up",
  },
  {
    path: ROUTES_CONSTANTS.PAT_PERSONAL_SIGNUP,
    exact: true,
    component: PatientPersonal,
    authorized: true,
    name: "Sign up",
  },
  {
    path: ROUTES_CONSTANTS.PAT_MEDICAL_SIGNUP,
    exact: true,
    component: PatientMedical,
    authorized: true,
    name: "Sign up",
  },
  {
    path: ROUTES_CONSTANTS.DASHBOARD,
    exact: true,
    component: DashBoard,
    authorized: true,
    name: "DASHBOARD",
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_DASHBOARD,
    exact: true,
    component: PatientDashboard,
    authorized: true,
    name: "DASHBOARD",
  },
  {
    path: ROUTES_CONSTANTS.INBOX,
    exact: true,
    component: Chat,
    authorized: true,
    name: "INBOX",
  },
  {
    path: ROUTES_CONSTANTS.APPOINTMENTS,
    exact: true,
    component: Appoitments,
    authorized: true,
    name: "APPOINTMENTS",
  },
  {
    path: ROUTES_CONSTANTS.SCHEDULE_AVAILABILITY,
    exact: true,
    component: ScheduleAvailability,
    authorized: true,
    name: "SCHEDULE_AVAILABILITY",
  },
  {
    path: ROUTES_CONSTANTS.REVIEW,
    exact: true,
    component: Review,
    authorized: true,
    name: "REVIEW",
  },
  {
    path: ROUTES_CONSTANTS.BILLINGS,
    exact: true,
    component: Billings,
    authorized: true,
    name: "BILLINGS",
  },
  {
    path: ROUTES_CONSTANTS.SUBSCRIPTION,
    exact: true,
    component: Subscription,
    authorized: true,
    name: "SUBSCRIPTION",
  },
  {
    path: ROUTES_CONSTANTS.NOTIFICATIONS,
    exact: true,
    component: Notification,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.HELP,
    exact: true,
    component: Help,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.DOCTOR_HELP_NEW,
    exact: true,
    component: NewRequest,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: `${ROUTES_CONSTANTS.DOCTOR_VIEW_HELP_REQUEST}/:HelpId`,
    exact: true,
    component: ViewHelpRequest,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.WALLETS,
    exact: true,
    component: Wallet,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.PROFILE_SETTINGS,
    exact: true,
    component: ProfileSettings,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.ABOUT_US,
    exact: true,
    component: AboutUs,
    authorized: false,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.TERMS_AND_CONDITIONS,
    exact: true,
    component: TermsAndConditions,
    authorized: false,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.DOC_VIEW_PROFILE,
    exact: true,
    component: DocViewProfile,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.PAT_VIEW_PROFILE,
    exact: true,
    component: PatViewProfile,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: `${ROUTES_CONSTANTS.FIND_PHYSICIANS_NEAR}/:DocId`,
    exact: true,
    component: FindDoctorsNearBy,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_APPOINTMENT,
    exact: true,
    component: PatientAppointment,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_MESSAGES,
    exact: true,
    component: Messages,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_FAVOURITE_SPECIALIST,
    exact: true,
    component: PatientFevouriteSpecialist,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_REQUEST,
    exact: true,
    component: Requests,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_REPORTS,
    exact: true,
    component: MyReports,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_MEDICAL_HISTORY,
    exact: true,
    component: MedicalHistory,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_HELP,
    exact: true,
    component: PatientHelp,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_REVIEW,
    exact: true,
    component: PatientReviews,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_WALLET,
    exact: true,
    component: PatientWallet,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_SUBSCRIPTION,
    exact: true,
    component: PatientSubscription,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_BILLING,
    exact: true,
    component: PatientBilling,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_SETTINGS,
    exact: true,
    component: PatientSettings,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: `${ROUTES_CONSTANTS.PATIENT_BOOK_APPOINTMENT}/:DocId`,
    exact: true,
    component: BookAppointment,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_APPOINTMENT_DETAIL,
    exact: true,
    component: PatientAppointmentDetail,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_NOTIFICATIONS,
    exact: true,
    component: PatientNotfications,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: `${ROUTES_CONSTANTS.DOCTOR_VIEW_UPCOMING_APPOINTMENT}/:DocId`,
    exact: true,
    component: ViewAppointment,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: `${ROUTES_CONSTANTS.DOCTOR_SIDE_RESCHEDULE_APPOINTMENT}/:AppointmentId`,
    exact: true,
    component: RescheduleAppointment,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: `${ROUTES_CONSTANTS.PATIENT_VIEW_APPOINTMENT}/:PatAppointmentId`,
    exact: true,
    component: ViewAppointmentOfPatient,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: `${ROUTES_CONSTANTS.PATIENT_EDIT_CARD_SETTING}/:CardId`,
    exact: true,
    component: EditCard,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_NEW_APPOINTMENT_DETAIL,
    exact: true,
    component: AppointmentDetail,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_PRESCRIPTION_LIST,
    exact: true,
    component: Patientrescription,
    authorized: true,
    name: "NOTIFICATIONS",
  },
  {
    path: ROUTES_CONSTANTS.CONTACT_US,
    exact: true,
    component: ContactUs,
    authorized: false,
  },
  {
    path: ROUTES_CONSTANTS.OUR_MISSION,
    exact: true,
    component: CommonPage,
    authorized: false,
  },
  {
    path: ROUTES_CONSTANTS.OUR_TEAM,
    exact: true,
    component: OurTeam,
    authorized: false,
  },
  {
    path: ROUTES_CONSTANTS.ONLINE_HEALTHBLOG,
    exact: true,
    component: CommonPage,
    authorized: false,
  },

  {
    path: ROUTES_CONSTANTS.REFER_CENTER,
    exact: true,
    component: CommonPage,
    authorized: false,
  },
  {
    path: ROUTES_CONSTANTS.PARTICIPATING_CENTERS,
    exact: true,
    component: CommonPage,
    authorized: false,
  },
  {
    path: ROUTES_CONSTANTS.BECOME_PARTICIPATING_CENTERS,
    exact: true,
    component: CommonPage,
    authorized: false,
  },
  {
    path: `${ROUTES_CONSTANTS.HOW_IT_WORKS}`,
    exact: true,
    component: CommonPage,
    authorized: false,
  },

  {
    path: ROUTES_CONSTANTS.METABOLIC_CENTERS,
    exact: true,
    component: CommonPage,
    authorized: false,
  },
  {
    path: ROUTES_CONSTANTS.TESTOMONIALS,
    exact: true,
    component: Testimonials,
    authorized: false,
  },
  {
    path: ROUTES_CONSTANTS.WHAT_WE_TREAT,
    exact: true,
    component: CommonPage,
    authorized: false,
  },
  {
    path: `${ROUTES_CONSTANTS.PATIENT_SIDE_RESCHEDULE_APPOINTMENT}/:DocAppointmentId`,
    exact: true,
    component: PatientRescheduleApp,
    authorized: true,
  },
  {
    path: `${ROUTES_CONSTANTS.PATIENT_COMPLETED_VIEW_APPOINTMENT}/:DocAppointmentId`,
    exact: true,
    component: CompletedViewApp,
    authorized: true,
  },
  {
    path: `${ROUTES_CONSTANTS.PATIENT_LABORATORY_PACKAGES}/:LabId`,
    exact: true,
    component: LaboratiesPackages,
    authorized: true,
  },

  {
    path: ROUTES_CONSTANTS.PATIENT_PATIENT_DETAIL_LAB,
    exact: true,
    component: PatientDetail,
    authorized: true,
  },

  {
    path: ROUTES_CONSTANTS.PATIENT_DELIVERY_LOCATION_LAB,
    exact: true,
    component: DeliveryLocation,
    authorized: true,
  },

  {
    path: ROUTES_CONSTANTS.PATIENT_BOOK_LABORATORY,
    exact: true,
    component: BookLaboratory,
    authorized: true,
  },

  {
    path: ROUTES_CONSTANTS.PATIENT_BILL_DETAIL_LAB,
    exact: true,
    component: LaboratoryBill,
    authorized: true,
  },
  {
    path: `${ROUTES_CONSTANTS.PATIENT_VIEW_MEDICAL_RECORDS}/:DocId`,
    exact: true,
    component: ViewMedicalHistory,
    authorized: true,
  },
  {
    path: `${ROUTES_CONSTANTS.PATIENT_VIEW_DOCTOR_DETAILS}/:DocId`,
    exact: true,
    component: ViewDoctorDetails,
    authorized: true,
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_EDIT_PERSONAL_INFO,
    exact: true,
    component: PatEditPersonal,
    authorized: true,
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_EDIT_MEDICAL_INFO,
    exact: true,
    component: PetEditMedical,
    authorized: true,
  },

  {
    path: ROUTES_CONSTANTS.PATIENT_ADD_NEW_HELP_REQUEST,
    exact: true,
    component: AddReqest,
    authorized: true,
  },
  {
    path: ROUTES_CONSTANTS.DOCTOR_EDIT_PERSONAL_DETAIL,
    exact: true,
    component: DocEditPersonal,
    authorized: true,
  },
  {
    path: ROUTES_CONSTANTS.DOCTOR_EDIT_PROFESSIONAL_DETAIL,
    exact: true,
    component: DocEditProfessional,
    authorized: true,
  },
  {
    path: ROUTES_CONSTANTS.PRIVACY_POLICY,
    exact: true,
    component: PrivacyPolicy,
    authorized: false,
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_RESCHEDULE_LABORATORY,
    exact: true,
    component: RescheduleLaboratory,
    authorized: true,
  },
  {
    path: `${ROUTES_CONSTANTS.VIDEO_CALL}/:appointmentId/:receiverId/:pushType/:userType`,
    exact: true,
    component: VideoCall,
    authorized: true,
  },
  {
    path: ROUTES_CONSTANTS.VOICE_CALL,
    exact: true,
    component: VoiceCall,
    authorized: true,
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_BILL_IN_MEDICAL_HISTORY,
    exact: true,
    component: ViewBills,
    authorized: true,
  },
  {
    path: ROUTES_CONSTANTS.PATIENT_VIEW_HELP_REQUEST,
    exact: true,
    component: ViewHelpRequestInPatient,
    authorized: true,
  },
  {
    path: ROUTES_CONSTANTS.INCOMING_CALL,
    exact: true,
    component: IncomingCall,
    authorized: true,
  },
  {
    path: ROUTES_CONSTANTS.OUTGOING_CALL,
    exact: true,
    component: OutgoingCall,
    authorized: true,
  },
  {
    path: `${ROUTES_CONSTANTS.DOCTOR_PROFILE}/:DocId`,
    exact: true,
    component: DoctorProfile,
    authorized: true,
  },
  {
    // path: ROUTES_CONSTANTS.PAGE_NOT_FOUND_PAGE,
    exact: true,
    component: PageNotFount,
    authorized: false,
  },
];

export default appRoutes;
