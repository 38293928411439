import React, { useState, useEffect } from "react";
import Scrollbars from "react-custom-scrollbars";
import PaginationComponent from "react-reactstrap-pagination";
import { useDispatch, useSelector } from "react-redux";
import { commonAction } from "../../../redux/action";
import SocialShareModal from "../../common/SocialShareModal/SocialShareModal";

function InviteFriends() {
  let dispatch = useDispatch();
  const [accordion, setAccordion] = useState(false);
  const [SocialModal, setSocialModal] = useState(false);
  const [pageNumber, setpageNumber] = useState(1);

  useEffect(() => {
    callApi();
    console.log("list api==>",pageNumber);
  }, [pageNumber]);

  const callApi = () => {
    getRefferalReportList({
      pageNumber: pageNumber,
      limit: 10,
    });
    
  };

  const toggle = () => {
    setAccordion(!accordion);
  };
  useEffect(() => {
    getRefferalCode();
  }, []);
  const getRefferalReportList = async (payload) => dispatch(commonAction.getRefferalReportListAction(payload));

  const getRefferalCode = async () => dispatch(commonAction.getRefferalCodeAction());
  const getRefferalLink = useSelector((state) => state?.common?.refferalCodeInfo);
  const getRefferalReportdata = useSelector((state) => state?.common?.refferalReportListInfo);
  console.log("getRefferalLink", getRefferalLink);
  console.log(" getRefferalReportdata", getRefferalReportdata);

  const handleShare = () => {
    setSocialModal(!SocialModal);
  };
  const handleChange = (e) => {
    setpageNumber(e);
  };

  console.log("SocialModal", SocialModal);
  return (
    <div role="tabpanel" id="invite" className="tab-pane fade show active">
      <div className="card">
        <div className="card-header">
          <h4>Invite Friends</h4>
        </div>
        <div className="card-body">
          <h6 className="mb-4 text-center">
            <b>Share the link to your friends and families and earn points</b>
          </h6>
          <div className="card">
            <div className="card-body">
              <div>
                <div className="d-flex justify-content-between">
                  <span className="default-content-clr">{getRefferalLink?.url}</span>
                  <span
                    className="text-right c-pointer"
                    onClick={() => {
                      navigator.clipboard.writeText(getRefferalLink?.url);
                    }}
                  >
                    <i className="fas fa-copy"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          {/* <FacebookShareCount url={getRefferalLink?.url} /> */}
          <div className="submit-section text-center">
            {/* <FacebookShareCount url={getRefferalLink?.url}> */}
            <button type="submit" className="btn  btn-primary w-50 mb-4" onClick={handleShare}>
              <span>
                <i className="fas fa-share-alt mr-1"></i>
              </span>
              Share
            </button>
            {/* </FacebookShareCount> */}
          </div>
          {/* <div className="card">
            <div className="card-body">
              <div>
                <div className="d-flex justify-content-between">
                  <span className="default-content-clr">
                    <b>Generate</b>{" "}
                  </span>
                  <span className="text-right c-pointer">
                    <i className="fas fa-download"></i>
                  </span>
                </div>
              </div>
            </div>
          </div> */}
          {/* <!-- accordian starts  --> */}
          <div className="card">
            <button className="btn  card-header text-left" onClick={toggle}>
              <span className=" accordian-btn-heading"> Check Report</span>
            </button>
            {accordion ? (
              <div>
                <div className="card-body p-2">
                {getRefferalReportdata && getRefferalReportdata?.documentList?.length ? (
                    <Scrollbars className="rating-reviews-height report_bx_height" autoHide>
                        <div className="card mb-2">
                         <table className="table report_table responsive">
                              <tr>
                                <th>Name </th>
                                <th>Email</th>
                                <th>Profile Pic</th>
                                <th>UserType</th>
                                <th>Referral Code</th>
                                <th>Status</th>
                              </tr>
                              {getRefferalReportdata && getRefferalReportdata?.documentList && getRefferalReportdata?.documentList.map((item) => (
                              <tr>
                                <td>{item && item.joinerId && item.joinerId?.fullName} {item && item.joinerId && item.joinerId?.firstName} {item && item.joinerId && item.joinerId?.lastName && item.joinerId?.lastName}</td>
                                <td>{item && item.joinerId && item.joinerId?.email && item.joinerId?.email}</td>
                                <td><span className="doctor-img">
                                {item && item.joinerId && item.joinerId?.profilePic?
                                  <img src={item && item.joinerId && item.joinerId?.profilePic && item.joinerId?.profilePic} className="img-fluid" alt="" /> 
                                  :''}
                                  </span> </td>
                                <td>{item && item.joinerId && item.joinerId?.userType && item.joinerId?.userType}</td>
                                <td>{item && item.referralCode && item.referralCode}</td>
                                <td>Joined</td>
                              </tr>
                                ))}
                            </table>

                            {getRefferalReportdata?.totalList > 8 && (
                            <div className="report_pagination_box p-2">
                            <PaginationComponent
                                totalItems={getRefferalReportdata && getRefferalReportdata?.totalList}
                                pageSize={8}
                                maxPaginationNumbers={Math.ceil(getRefferalReportdata && getRefferalReportdata?.totalList / 10)}
                                defaultActivePage={1}
                                onSelect={(e) => handleChange(e)}
                              />
                              </div>
                             )}
                            {/* <div className="help-query d-flex justify-content-between align-items-center">
                              <div className="d-flex align-items-center"></div>
                              <div>
                                <span className="default-content-clr">
                                  Joined
                                </span>
                              </div>
                            </div> */}
                          </div>
                    </Scrollbars>
                  ) : (
                    <div className="col-md-12">
                      <p className="text-center">No reports available.</p>
                    </div>
                  )}
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
      <SocialShareModal SocialModal={SocialModal} setSocialModal={setSocialModal} getRefferalLink={getRefferalLink} />
    </div>
  );
}

export default InviteFriends;
