import React, { useState, useEffect } from "react";
import { isElementOfType } from "react-dom/test-utils";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// import Payment from '../paymentModal/Payment';
import { useDispatch, useSelector } from "react-redux";
// import Bill from '../billModal/Bill';
import { useHistory } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../../constants";
import UpgradePayment from "./UpgradePayment";
import { authentication } from "../../../redux/action";

function UpgradeSubscription({ PatSubcriptionModal, setPatSubcriptionModal, DataSub, PatUpgradeSub , patientSubscriptionData,docSubscriptionInfo, callFrom }) {
  let dispatch = useDispatch();
  let history = useHistory();
  const [SubscriptionId, setSubscriptionId] = useState("");
  const [SubscriptionPrice, setSubscriptionPrice] = useState("");
  const [PaymentModal, setPaymentModal] = useState(false);
  const [isSubcriptionToggle, setisSubcriptionToggle] = useState(false);
  const [SubscriptionType, setSubscriptionType] = useState(false);
  const [SubscriptionErr, setSubscriptionErr] = useState("");
  const [billModal, setbillModal] = useState(false);
  const [SubPrice, setSubPrice] = useState("");
  const [FreeSubToggle, setFreeSubToggle] = useState(false);
  const [ToggleSubList, setToggleSubList] = useState([]);

  const SubscriptionList = useSelector((state) => state?.authentication?.patientSubscriptionList);
  const DocsubscriptionList = useSelector((state) => state?.authentication?.doctorSubscriptionList);
  const DocsubscriptionData = async () => dispatch(authentication.subscriptionListDoctorAction());
  const SubscriptionData = async () => dispatch(authentication.subscriptionListPatientAction());

  useEffect(() => {
    SubscriptionData();
    DocsubscriptionData();
  }, []);

  console.log("DataSub", DataSub, DocsubscriptionList);
  useEffect(() => {
    if (DataSub === true) {
      console.log("cominggggggggggggggg");
      setToggleSubList(DocsubscriptionList.filter(item=>item._id !== docSubscriptionInfo?._id));
    }
    if (PatUpgradeSub) {
      console.log("cominggggggggggggggg here");
      setToggleSubList(SubscriptionList.filter(item=>item._id !== patientSubscriptionData?._id));
    }
  }, [DataSub, PatUpgradeSub]);

  console.log("SubscriptionList", SubscriptionList);

  const handleClose = () => {
    setPatSubcriptionModal(false);
  };

  console.log("SubscriptionType", SubscriptionType);
  const handlePay = () => {
    // if(FreeSubToggle){
    //     history.push(ROUTES_CONSTANTS.PATIENT_DASHBOARD)
    // }else{
    if (SubscriptionType !== true) {
      setSubscriptionErr("Please choose atleast one");
    } else {
      setSubscriptionErr("");
      setPaymentModal(!PaymentModal);
      setPatSubcriptionModal(!PatSubcriptionModal);
    }
    // }
  };
  useEffect(() => {
    if (SubscriptionType === true) {
      setSubscriptionErr("");
    }
    if (SubPrice === 0) {
      setFreeSubToggle(true);
    } else {
      setFreeSubToggle(false);
    }

    if (SubscriptionList) {
      let index = SubscriptionList.findIndex((item) => item.price == "0" || item.price == null);
      console.log("index", index);
      if (index > -1) {
        SubscriptionList.splice(index, 1);
      }
    }
  }, [SubscriptionType, SubPrice, SubscriptionList]);

  const handleSubscription = (e, id, item) => {
    // let name= e.target.name
    let value = e.target.checked;
    console.log("iddddddddddddddddd", id, item.price);
    // console.log("SubscriptionPrice");
    // if(item.price === "0"){
    setSubPrice(item.price);
    // }
    if (value) {
      setSubscriptionType(value);
    }
    if (id) {
      setSubscriptionId(id);
    }
    if (item && item.price) {
      setSubscriptionPrice(item.price);
    }
    setbillModal(!billModal);
  };

  console.log("SubscriptionPrice", SubscriptionPrice);
  return (
    //     <div className="modal fade subscription-modal-p" id="subscription_Modal" tabindex="-1" role="dialog">
    //     <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
    <>
      <Modal isOpen={PatSubcriptionModal} className="modal-dialog-centered modal-dialog-scrollable upgrade-subsrcription">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="staticBackdropLabel">
              Select Subscription
            </h4>
            <button type="button" className="close " aria-label="Close" data-dismiss="modal" onClick={handleClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
         
          {SubscriptionErr ? <div className="validation_error">{SubscriptionErr}</div> : null}
        
          <div class="modal-body pt-0">
            {ToggleSubList &&
              ToggleSubList.length &&
              ToggleSubList.map((item, index) => (
                <div class="account-content">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card subscription-packages">
                        <div class="card-body">
                          <div class="d-flex justify-content-between mb-1">
                            <div class="form-check">
                              <input
                                type="radio"
                                class="form-check-input"
                                id={`Subscription${index}`}
                                name="Subscription1"
                                checked={SubscriptionId && SubscriptionId === item._id ? true : false}
                                onClick={(e) => handleSubscription(e, item._id, item)}
                              />
                              <label class="form-check-label" for={`Subscription${index}`}>
                                {item && item.name}
                              </label>
                            </div>
                            <div>
                              <span class="color-grey">
                                <b>${item.price}/month</b>
                              </span>
                            </div>
                          </div>
                          <div class="mb-1">
                            <b>Description</b>
                          </div>
                          {DataSub ? (
                            <div className="card mt-1 p-2 package-description">
                              <div>
                                <label className="color-grey">{`Number of Secondary Speciality:${item?.numberOfSecondrySpecialty}`}</label>
                              </div>
                              <div>
                                <label className="color-grey">{`Minutes of Audio Calls:${item?.minutesOfAudioCalls}`}</label>
                              </div>
                              <div>
                                <label className="color-grey">{`Minutes of Video Calls:${item?.minutesOfVideoCalls}`}</label>
                              </div>
                              <div>
                                <label className="color-grey">{`Number Of Leads:${item?.numberOfLeads}`}</label>
                              </div>
                              <div>
                                <label className="color-grey">{`Number Of Messages:${item?.numberOfMessages}`}</label>
                              </div>
                              <div>
                                <label className="color-grey">{`Subscription Validity:${item?.days_duration}`}</label>
                              </div>
                            </div>
                          ) : (
                            <div class="card mb-0 p-2">
                              <div>
                                <span class="color-grey">Number of Sessions:{item.session_numbers}</span>
                              </div>
                              <div>
                                <span class="color-grey">Number of Messages:{item.numberOfMessages}</span>
                              </div>
                              <div>
                                <span class="color-grey">Subscription Validity(days):{item.days_duration}</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}           
          </div>
          <div className="modal-footer">
            {FreeSubToggle ? (
              <button className="btn btn-primary btn-block " type="submit" onClick={handlePay}>
                Confirm
              </button>
            ) : (
              <button className="btn btn-primary btn-block " type="submit" onClick={handlePay}>
                Pay
              </button>
            )}
          </div>
        </div>
        {/* <Bill 
            billModal={billModal} 
            setbillModal={setbillModal}
            SubscriptionId={SubscriptionId} 
            SubscriptionPrice={SubscriptionPrice}
            /> */}
      </Modal>
      <UpgradePayment
        PaymentModal={PaymentModal}
        setPaymentModal={setPaymentModal}
        // isOpenDocSide={isOpenDocSide}
        SubscriptionId={SubscriptionId}
        SubscriptionPrice={SubscriptionPrice}
        DataSub={DataSub}
        PatUpgradeSub={PatUpgradeSub}
        callFrom={callFrom}
      />
    </>
  );
}

export default UpgradeSubscription;
