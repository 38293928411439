import React from 'react'
import { useHistory } from 'react-router-dom'
import { Modal, ModalBody } from 'reactstrap'
import { ROUTES_CONSTANTS } from '../../../constants'

function ConfirmationModal({ConfirmModal, setConfirmModal}) {
    let history= useHistory()
    const handleCheck =()=>{
        setConfirmModal(false)
        history.push(ROUTES_CONSTANTS.APPOINTMENTS)
    }
    return (
        <Modal isOpen={ConfirmModal} className='modal-dialog modal-dialog-centered'>
        {/* <div className="modal fade" id="confirmation-to-patient" data-backdrop="static" aria-hidden="true" role="dialog">
        <div className="modal-dialog modal-dialog-centered " role="document"> */}
        <ModalBody>
            {/* <div className="modal-content"> */}
                <div className=" text-center">
                    <h5 className="color-grey">Your request to reschedule appointment has been sent to the customer. Now the customer will accept or reject the new date & time. You will be notified for same.</h5>
                <a onClick={handleCheck}>Check Appointments</a>
                </div>
            {/* </div> */}
        </ModalBody>
        {/* </div>
       </div> */}
       </Modal>
    )
}

export default ConfirmationModal
