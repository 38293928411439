import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { ROUTES_CONSTANTS } from "../../../constants";
import PatientSubcription from "../../authentication/sign-up-as-patient/PatientSubcription";
import UpgradePayment from "./UpgradePayment";
import UpgradeSubscription from "./UpgradeSubscription";
import ToasterFunc from "../../common/toaster/Toaster";
import { useDispatch, useSelector } from "react-redux";
import { patient } from "../../../redux/action";

function PaymentOptionModal({ PaymentOption, setPaymentOption, DocData }) {
  let history = useHistory();
  const dispatch = useDispatch();
  const [PatSubcriptionModal, setPatSubcriptionModal] = useState(false);
  const [PaymentModal, setPaymentModal] = useState(false);
  const [PatUpgradeSub, setPatUpgradeSub] = useState(false);
  const patientSubscriptionData = useSelector((state) => state?.patient?.patientSubscriptionData?.result?.subscriptionId);
  useEffect(() => {
    window.scrollTo(0, 0);
    patientSubscriptionInfo();
  }, []);
  const patientSubscriptionInfo = async () => dispatch(patient.patientSubscriptionInWebAction());
  const [PayFlow, setPayFlow] = useState({
    upgradeSub: false,
    cardPay: false,
  });
  const handlePayment = (e) => {
    if (e.target.value === "Upgrade") {
      setPayFlow({
        upgradeSub: true,
      });
      setPatUpgradeSub(true);
    } else if (e.target.value === "payment") {
      setPayFlow({
        cardPay: true,
      });
    }
  };

  const handleConfirm = () => {
    console.log(PayFlow.upgradeSub, PayFlow.cardPay);
    if(PayFlow.upgradeSub || PayFlow.cardPay){

      if (PayFlow.upgradeSub) {
        localStorage.setItem("bookAppontmentPayload",  JSON.stringify(DocData.otherDetail))
        setPatSubcriptionModal(!PatSubcriptionModal);
      } else if (PayFlow.cardPay) {
        // setPaymentModal(!PaymentModal)
        history.push(ROUTES_CONSTANTS.PATIENT_NEW_APPOINTMENT_DETAIL, DocData);
      }
    }else{
      ToasterFunc("error", "Please choose payment method.")
    }
  };

  return (
    // <div className="modal fade modal-delete" id="couponCode2" tabindex="-1" role="dialog">
    // <div className="modal-dialog  modal-dialog-centered " role="document">
    <Modal isOpen={PaymentOption} className="modal-dialog  modal-dialog-centered">
      <div className="text-right">
        <button type="button" className="dr_Modalclose payment-method-modal" onClick={() => setPaymentOption(false)}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-content p-3">
        <ModalBody>
          {/* <div className="modal-body"> */}
          <div className="row">
            <div className="col-lg-12 ">
              <div className="card mb-0">
                <div className="card-header ">
                  <h4 className="mb-0">Choose Payment Method</h4>
                </div>
                <div className="card-body">
                  <div className="form-check form-group">
                    <input className="form-check-input" type="radio" name="Coupon" id="Coupon-1" value="Upgrade" onChange={(e) => handlePayment(e)} />
                    <div className="d-flex justify-content-between">
                      <label className="form-check-label " for="exampleRadios1">
                        Upgrade Subscription
                      </label>
                    </div>
                  </div>
                  <div className="form-check form-group">
                    <input className="form-check-input" type="radio" name="Coupon" id="Coupon-2" value="payment" onChange={(e) => handlePayment(e)} />
                    <div className="d-flex justify-content-between">
                      <label className="form-check-label " for="Coupon-2">
                        Credit/Debit Card
                      </label>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center mt-5">
                    <a href="" className="btn btn-primary mr-1" data-dismiss="modal">
                      Cancel
                    </a>
                    <button className="btn btn-primary mr-1 modal-confirm-btn" onClick={handleConfirm}>
                      Confirm
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </ModalBody>
      </div>
      <UpgradeSubscription patientSubscriptionData = {patientSubscriptionData} PatSubcriptionModal={PatSubcriptionModal} setPatSubcriptionModal={setPatSubcriptionModal} PatUpgradeSub={PatUpgradeSub} callFrom={"Appointment"}/>
      <UpgradePayment  PaymentModal={PaymentModal} setPaymentModal={setPaymentModal} />
    </Modal>
    //     </div>
    // </div>
  );
}

export default PaymentOptionModal;
