import React from 'react';
import { Modal } from 'reactstrap';
import { useFormik } from "formik";
import * as Yup from "yup";
import ToasterFunc from '../common/toaster/Toaster';
import { ratingAction } from '../../redux/action';
import { useDispatch } from 'react-redux';

const ChallangeModal = ({ challangeModal, setChallangeModal, challangeId, ratingListApi }) => {
    const dispatch = useDispatch()
    const challangeData = async (payload) => dispatch(ratingAction.challangeReviewsAction(payload));

    const handleClose = () => {
        setChallangeModal(false)
        formik.resetForm()
    }
    const formik = useFormik({
        initialValues: {
            challenge: "",
        },
        validationSchema: Yup.object({
            challenge: Yup.string().required("*Message is required.").trim(),
        }),
    });
    const handleSubmit = async () => {
        if (formik.values.challenge.trim()) {
            const payload = {
                challenge: formik.values.challenge,
                reviewId: challangeId
            };
            const res = await challangeData(payload);
            if (res.status == 200) {
                // ToasterFunc("success", res.data.responseMessage);
                formik.resetForm()
                setChallangeModal(false)
                ratingListApi()
            }
        }

    }
    return (
        <Modal isOpen={challangeModal} className="modal-dialog-centered">
            <div className="modal-content">
                <button
                    type="button"
                    className="close custom-model-close"
                    aria-label="Close"
                    data-dismiss="modal"
                    onClick={handleClose}
                >
                    <span aria-hidden="true">&times;</span>
                </button>
                <div className="modal-body p-4">
                    <div class="row">
                        <div class="col-lg-12 ">
                            <div class="card mb-0">
                                <div class="card-body">
                                    <h4 class="card-title">Challange</h4>
                                    <form onSubmit={formik.handleSubmit}>
                                        <div class="form-group row">
                                            <div class="col-lg-12 ">
                                                <textarea name="challenge" maxLength="180" rows="3" class={`form-control ${formik.touched.challenge && formik.errors.challenge ? 'is-invalid' : ''}`} placeholder="Type here" {...formik.getFieldProps("challenge")}></textarea>
                                                {formik.touched.challenge && formik.errors.challenge ? <span className="validation_error">{formik.errors.challenge}</span> : null}
                                            </div>
                                        </div>
                                        <div class="mt-4 d-flex justify-content-center">
                                            <button type='submit' class="btn btn-primary w-25" onClick={handleSubmit}>Send</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Modal>
    )
}

export default ChallangeModal
