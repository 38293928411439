import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Modal, ModalBody } from "reactstrap";
import { ROUTES_CONSTANTS } from "../../../constants";
import { bookingAction } from "../../../redux/action";
import { useFormik } from "formik";
import * as Yup from "yup";
import { getSessionStorageOrDefault } from "../../../utils";

function ReasonAppointment({ ReasonModal, setReasonModal, UpcomingAppointsList, CancelAppId, CancelLabId, PatSideAppId, upcomingAppointmentList }) {
  let history = useHistory();
  let dispatch = useDispatch();

  const formik = useFormik({
    initialValues: {
      reasonfield: "",
    },
    validationSchema: Yup.object({
      reasonfield: Yup.string().required("*Reason is required."),
    }),
  });

  console.log("PatSideAppId", PatSideAppId);

  const cancelAppointment = async (payload) => dispatch(bookingAction.cancelAppointmentAction(payload));
  const cancelAppointmentPatient = async (payload) => dispatch(bookingAction.cancelAppointmentActionPatient(payload));
  const cancelLabAppointment = async (payload) => dispatch(bookingAction.cancelLabAppointmentAction(payload));

  console.log("App Id", UpcomingAppointsList ? UpcomingAppointsList._id : "", CancelAppId);

  const handleSubmit = async () => {
    let payload = {};
    if (PatSideAppId) {
      payload = {
        appointmentId: PatSideAppId ? PatSideAppId : "",
        reason: formik.values.reasonfield,
      };
    } else if (CancelLabId) {
      payload = {
        appointmentId: CancelLabId ? CancelLabId : "",
        reason: formik.values.reasonfield,
      };
    } else {
      payload = {
        appointmentId: UpcomingAppointsList ? UpcomingAppointsList._id : CancelAppId ? CancelAppId : "",
        reason: formik.values.reasonfield,
      };
    }

    console.log("payload", payload);

    if ((UpcomingAppointsList && UpcomingAppointsList._id !== "") || (CancelAppId && CancelAppId !== "" && formik.values.reasonfield !== "")) {
      const res = await cancelAppointment(payload);
      if (res && res.status === 200) {
        if (getSessionStorageOrDefault("userType") === "1") {
          setReasonModal(false);
          history.goBack();
          // upcomingAppointmentList();
        }
      }
    } else if (PatSideAppId && formik.values.reasonfield !== "") {
      console.log("Coming");
      const res = await cancelAppointmentPatient(payload);
      console.log("Coming here");
      if (res && res.status === 200) {
        if (getSessionStorageOrDefault("userType") === "2") {
          console.log("Coming here again");
          setReasonModal(false);
          history.goBack();
          // upcomingAppointmentList();
        }
      }
    } else if (CancelLabId && formik.values.reasonfield !== "") {
      console.log("Coming");
      const res = await cancelLabAppointment(payload);
      console.log("Coming here");
      if (res && res.status === 200) {
        if (getSessionStorageOrDefault("userType") === "2") {
          console.log("Coming here again");
          setReasonModal(false);
          history.goBack();
          // upcomingAppointmentList();
        }
      }
    }
  };
  return (
    <Modal isOpen={ReasonModal} className="modal-dialog modal-dialog-centered">
      {/* <div className="modal fade modal-delete " id="pat-appnt-cancelReason" tabindex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered " role="document"> */}
      <div className="modal-content">
        <div className="text-right">
          <button type="button" className="dr_Modalclose " onClick={() => setReasonModal(false)}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          {/* <div className="modal-body"> */}
          <form onSubmit={formik.handleSubmit}>
            <div className="card mb-0">
              <div className="card-body">
                <h4 className="text-center justify-content-center mb-2 mt-3">Reason to Cancel</h4>
                <textarea className="form-control" rows="3" name="reasonfield" {...formik.getFieldProps("reasonfield")}></textarea>
                {formik.touched.reasonfield && formik.errors.reasonfield ? <span className="validation_error">{formik.errors.reasonfield}</span> : null}
                <div className="d-flex justify-content-center mt-3">
                  <button className="btn book-btn mr-2" onClick={() => setReasonModal(false)}>
                    Cancel
                  </button>
                  <button className="btn view-btn" type="submit" onClick={handleSubmit}>
                    Submit
                  </button>
                </div>
              </div>
            </div>
          </form>
          {/* </div> */}
        </ModalBody>
      </div>
      {/* </div>
    </div> */}
    </Modal>
  );
}

export default ReasonAppointment;
