import React, { useState ,useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { authentication } from "../../../redux/action";
import "../../authentication/billModal/style.css";

function UpgradeBill(props) {
  const { SubscriptionId, setCouponId, couponId, handlePayment, SubscriptionPrice, isClicked } = props;
  console.log("couponId ===>",couponId);
  const [tax, setTax] = useState(0);
  const [taxData, setTaxData] = useState({});
  const [showCoupon, setShowCoupon] = useState(false);
  const [taxPrice, setTaxPrice] = useState(0);
  const dispatch = useDispatch();
  const subscriptionList = useSelector((state) => {
    if (state?.authentication?.doctorSubscriptionList) {
      return state?.authentication?.doctorSubscriptionList;
    } else if (state?.authentication?.patientSubscriptionList) {
      return state?.authentication?.patientSubscriptionList;
    }
  });

  const getSessionStorageValue = (key) => {
    const stored = sessionStorage.getItem(key);
    console.log(stored);
    return JSON.parse(stored);
  };

  const getPatientTax = async ()=> dispatch(authentication.getPatientTaxAction());
  const getDoctorTax = async ()=> dispatch(authentication.getDoctorTaxAction());

  useEffect(() => {
    getPatientDocTaxApi();

  }, []);
  useEffect(() => {
    console.log("taxData ===>> ",taxData)
  }, [taxData])

  const getPatientDocTaxApi = async ()=>{
          var taxRes;
          if (getSessionStorageValue('userType') == '1'){
            taxRes =  await getDoctorTax();
          }else{
              taxRes =  await getPatientTax();

          }
        console.log("taxRes ===>>> ",taxRes);
        let result = taxRes?.data?.data;
        if ((taxRes.status === 200 )&& result && result?.value && subscriptionList.length){
          setTaxData(result);
          setTax(result?.value);
         let taxPrice =  (result?.taxType != "PERCENTAGE" ? result?.value : (SubscriptionPrice * result?.value/100)) 
         setTaxPrice(taxPrice); 
         console.log("taxPrice",taxPrice);
        }
  }

  // const subscriptionList = useSelector(
  //   (state) => console.log('state===>',state)
  // );

  console.log("SubscriptionId", SubscriptionId);

  const couponList = useSelector((state) => state?.authentication?.couponList);
  console.log("subscriptionList", subscriptionList, SubscriptionId);

  return (
    <>
      {!showCoupon ? (
        <div className="modal-content bill-detail-new">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="staticBackdropLabel">
              Bill Details
            </h4>
          </div>
          <div className="modal-body">
            <div className="account-content">
              <div className="row">
                <div className="col-md-12">
                  {/* <div className="card"> */}
                  <div className="row">
                    <div className="card-body">
                      <div className="d-flex justify-content-between mb-1">
                        <div>
                          <span>Subscription Fee</span>
                        </div>
                        <div>
                          <span className="color-grey">{`$${subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price || SubscriptionPrice}`}</span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-between mb-1">
                        <div>
                          <div>
                            {/* <input type="checkbox" id="status_72" /> */}
                            <label for="status_72" className="ml-2">
                              Tax
                            </label>
                          </div>
                        </div>
                        <div>
                          <span className="color-grey">{taxData?.taxType != "PERCENTAGE" ? `$${tax}` : `$${(SubscriptionPrice * (tax/100)).toFixed(2)}`}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row table-bill">
                    <div className="card-body">
                      <div className="d-flex justify-content-between mb-1">
                        <div>
                          <span>Subtotal</span>
                        </div>
                        <div>
                          <span className="color-grey">{`$${SubscriptionPrice + taxPrice }`}</span>
                        </div>
                      </div>
                      {/* <div className="d-flex justify-content-between mb-1">
                        <div>
                          <span>Coupon</span>
                        </div>
                        <div>
                          {console.log(couponId)}
                          {couponId ? (
                            <span className="color-grey pointer">{couponId.couponType == "PERCENTAGE" ? `$${(couponId.value / 100) * (subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price + tax || 0)}` : couponId.value}</span>
                          ) : (
                            <span className="color-grey pointer" onClick={() => setShowCoupon(!showCoupon)}>
                              Apply Coupon
                            </span>
                          )}
                        </div>
                      </div> */}
                    </div>
                  </div>
                  <div className="row pt-1">
                    <div className="card-body ">
                      <div className="d-flex justify-content-between mb-1">
                        <div>
                          <b>Total</b>
                        </div>
                        <div>
                          <span className="color-grey">
                            <b>
                              {!couponId
                                ? `$${SubscriptionPrice + taxPrice }`
                                : `$${
                                    (SubscriptionPrice + taxPrice ) -
                                    (couponId.couponType == "PERCENTAGE" ? `${(couponId.value / 100) * (SubscriptionPrice + taxPrice || 0)}` : couponId.value)
                                  }`}
                            </b>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* </div> */}
                  <div className="btn-p">
                    <button
                      type="submit"
                      className="btn btn-primary mt-2 btn-new"
                      disabled = {isClicked ? true : false}
                      onClick={(e) =>
                        handlePayment(
                          !couponId
                            ? (SubscriptionPrice + taxPrice) ? (SubscriptionPrice + taxPrice) : 0
                            : `${
                                (SubscriptionPrice + taxPrice ) -
                                (couponId.couponType == "PERCENTAGE" ? `${(couponId.value / 100) * (SubscriptionPrice + taxPrice || 0)}` : couponId.value)
                              }`
                        )
                      }
                    >
                      Pay
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="modal-content p-3 ">
          <div className="modal-body">
            {console.log(couponList)}
            <div className="row">
              <div className="col-lg-12 ">
                <div className="card mb-0">
                  <div className="card-body">
                    {couponList.map((item) => {
                      return (
                        <div className="form-check form-group">
                          <input className="form-check-input" type="radio" name="Coupon" id="Coupon-1" onClick={() => setCouponId(item)} />
                          <div className="d-flex justify-content-between">
                            <label className="form-check-label " for="exampleRadios1">
                              {item.code}
                            </label>
                            <label for="" className="color-grey">
                              {item.couponType == "PERCENTAGE" ? `$${(item.value / 100) * (SubscriptionPrice + taxPrice )}` : item.value}
                            </label>
                          </div>
                          <p className="color-grey">
                            {item.couponType == "PERCENTAGE"
                              ? `${item.value}% Discount on $${SubscriptionPrice + taxPrice || 0}`
                              : `$${item.value} on $${SubscriptionPrice + taxPrice || 0}`}
                          </p>
                        </div>
                      );
                    })}
                    <div className="d-flex justify-content-center">
                      <button className="btn btn-primary mr-1" data-dismiss="modal" onClick={() => setShowCoupon(!showCoupon)}>
                        Cancel
                      </button>
                      <button onClick={() => couponId && setShowCoupon(!showCoupon)} className="btn btn-primary mr-1" disabled={couponId ? false : true}>
                        Apply
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default UpgradeBill;
