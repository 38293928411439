import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "reactstrap";
import { commonAction, doctor } from "../../../../../redux/action";
import ToasterFunc from "../../../../common/toaster/Toaster";

const AddPrescription = ({ addPrescription, setAddPrescription, Patid }) => {
  const dispatch = useDispatch();
  const [image, setImage] = useState("");
  const [Submit, setSubmit] = useState(false);
  const [imageError, setImageError] = useState("");
  const handleClose = () => {
    setAddPrescription(false);
  };
  const CommonProfilePic = async (payload) => dispatch(commonAction.commonProfilePicAction(payload));

  const addPrescriptionInfo = async (payload) => dispatch(doctor.docAddPrescriptionAction(payload));
  const docPrescriptionList = async (Patid) => dispatch(doctor.docPresciptionListAction(Patid));
  const [AddPrescriptionArry, setAddPrescriptionArry] = useState([
    {
      name: "",
      quantity: "",
      number_of_days: "",
      description: "",
      timing: [],
    },
  ]);
  const [handlePreError, setHandlePreError] = useState([
    {
      name: "",
      quantity: "",
      number_of_days: "",
      description: "",
      timing: [],
    },
  ]);

  const formValidation = () => {
    let isValid = true;
    let error = [...handlePreError];
    AddPrescriptionArry.forEach((item, i) => {
      if (!item.name) {
        isValid = false;
        error[i]["name"] = "*Required";
        setHandlePreError(error);
      } else if (!item.quantity) {
        isValid = false;
        error[i]["quantity"] = "*Required";
        setHandlePreError(error);
      } else if (!item.number_of_days) {
        isValid = false;
        error[i]["number_of_days"] = "*Required";
        setHandlePreError(error);
      } else if (!item.description) {
        isValid = false;
        error[i]["description"] = "*Required";
        setHandlePreError(error);
      } else if (item.timing.length == 0) {
        isValid = false;
        error[i]["timing"] = "*Required";
        setHandlePreError(error);
      }
    });
    return isValid;
  };

  const handleChange = (event, ind, type) => {
    console.log(ind);
    const name = event.target.name;
    const value = event.target.value;
    console.log(name, ":", value);

    let arry = [...AddPrescriptionArry];

    if (type === "quantity") {
      arry[ind][name] = Number(value.trim());
    } else if (type === "days") {
      arry[ind][name] = Number(value.trim());
    } else {
      arry[ind][name] = value.trim();
    }
    setAddPrescriptionArry(arry);

    let errorList = [...handlePreError];
    console.log("errorList", errorList);
    errorList[ind][name] = "";
    setHandlePreError(errorList);
  };
  console.log(AddPrescriptionArry)


  const handleTimeChange = (event, ind,ind1) => {
    console.log(ind)
    if(event.target.checked){
      const name = event.target.name;
      const value = event.target.value;
      let arry = [...AddPrescriptionArry];
      console.log(arry)
      arry[ind]["timing"][ind1]=value;
      console.log(arry)
      setAddPrescriptionArry(arry);
      let errorList = [...handlePreError];
      console.log("errorList", errorList);
      errorList[ind]["timing"] = "";
      setHandlePreError(errorList);
    }else{
      const name = event.target.name;
      console.log(name)
      const value = event.target.value;
      let arry = [...AddPrescriptionArry];
      let a=arry[ind]["timing"].filter((item)=>{  return item !=name });
      console.log(a)
      arry[ind]["timing"]=[...a]
      setAddPrescriptionArry(arry);
      let errorList = [...handlePreError];
      console.log("errorList", errorList);
      errorList[ind]["timing"] = "";
      setHandlePreError(errorList);
    }
   
  };
  console.log(handlePreError);
  const addMorePrescription = () => {
    if (formValidation()) {
      setAddPrescriptionArry([
        ...AddPrescriptionArry,
        {
          name: "",
          quantity: "",
          number_of_days: "",
          description: "",
          timing: [],
        },
      ]);

      setHandlePreError([
        ...handlePreError,
        {
          name: "",
          quantity: "",
          number_of_days: "",
          description: "",
          timing: [],
          signature: "",
        },
      ]);
    }
  };
  const handleImage = (e) => {
    const file = e.target.files[0];
    console.log(file);
    let formData = new FormData();
    formData.append("file", file);
    fileUpload(formData);
  };

  const fileUpload = async (image) => {
    const res = await CommonProfilePic(image);
    if (res && res.status == 200) {
      let picdata = res && res.data && res.data.result.url;
      setImage(picdata);
    }
  };

  // formValidation() &&
  const handleSubmit = async () => {
    let arr = [...AddPrescriptionArry];
    if (formValidation() && image) {
      const payload = {
        patientId: Patid,
        prescriptionArray: arr,
        signature: image,
      };

      const res = await addPrescriptionInfo(payload);
      if (res.status == 200) {
        console.log("res===>", res);
        ToasterFunc("success", res.data.responseMessage);
        setAddPrescription(false);
        docPrescriptionList(Patid);
        setImage("");
        // arr.pop();
        setAddPrescriptionArry([
          {
            name: "",
            quantity: "",
            number_of_days: "",
            description: "",
            timing: [],
          },
        ]);
        console.log("empty arr==>", arr);
      } else {
        ToasterFunc("error", res.data.responseMessage);
      }
    }
    setSubmit(true);
  };

  return (
    <Modal isOpen={addPrescription} className="modal-dialog-scrollable modal-dialog-centered">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">Add Prescription</h4>
          <button type="button" onClick={handleClose} className="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body add_prescription">
          <div className="row">
            <div className="col-lg-12 ">
              {AddPrescriptionArry &&
                AddPrescriptionArry.map((item, i) => (
                  <div className="invoice-content mb-2" key={i}>
                    <div className="row form-row">
                      <div className="col-12 col-md-12">
                        <div className="form-group">
                          <label> Name</label>
                          <input type="text" maxLength="62" name="name" onChange={(e) => handleChange(e, i)} className={`form-control ${handlePreError && handlePreError[i].name && handlePreError[i].name ? "is-invalid" : ""}`} />
                          <span className="error">{handlePreError && handlePreError[i].name && handlePreError[i].name}</span>
                        </div>
                      </div>
                      <div className="col-6 col-md-6">
                        <div className="form-group">
                          <label>Quantity</label>
                          <input
                            type="number"
                            name="quantity"
                            maxLength={3}
                            onChange={(e) => handleChange(e, i, "quantity")}
                            className={`form-control ${handlePreError && handlePreError[i].quantity && handlePreError[i].quantity ? "is-invalid" : ""}`}
                          />
                          <span className="error">{handlePreError && handlePreError[i].quantity && handlePreError[i].quantity}</span>
                        </div>
                      </div>
                      <div className="col-6 col-md-6">
                        <div className="form-group">
                          <label>Days</label>
                          <input type="number" maxLength={3} name="number_of_days" onChange={(e) => handleChange(e, i, "days")} className={`form-control ${handlePreError && handlePreError[i].days && handlePreError[i].days ? "is-invalid" : ""}`} />
                          <span className="error">{handlePreError && handlePreError[i].number_of_days && handlePreError[i].number_of_days}</span>
                        </div>
                      </div>
                      <div className="col-12 col-md-12">
                        <div className="form-group">
                          <label for="description">Description</label>
                          <textarea
                            id="description"
                            maxLength={120}
                            name="description"
                            onChange={(e) => handleChange(e, i)}
                            className={`form-control ${handlePreError && handlePreError[i].description && handlePreError[i].description ? "is-invalid" : ""}`}
                            rows="3"
                          ></textarea>
                          <span className="error">{handlePreError && handlePreError[i].description && handlePreError[i].description}</span>
                        </div>
                      </div>

                      <div className=" col-md-12">
                        <div className="form-group mb-0">
                          <label>Time</label>
                          <div className="time_bx">
                            <label className="form-check-label" htmlFor={`morning${i}`}>
                              <input className="form-check-input" value="Morning" id={`morning${i}`} type="checkbox" name="Morning" onChange={(e) => handleTimeChange(e,i, 0)} />
                              Morning
                            </label>
                            <label className="form-check-label" htmlFor={`afternoon${i}`}>
                              <input className="form-check-input" value="Afternoon" type="checkbox" id={`afternoon${i}`} name="Afternoon" onChange={(e) => handleTimeChange(e,i, 1)} />
                              Afternoon
                            </label>
                            <label className="form-check-label" htmlFor={`evening${i}`}>
                              <input className="form-check-input" type="checkbox" value="Evening" name="Evening" onChange={(e) => handleTimeChange(e, i,2)} id={`evening${i}`} /> Evening
                            </label>
                            <label className="form-check-label" htmlFor={`night${i}`}>
                              <input className="form-check-input" value="Night" type="checkbox" id={`night${i}`} name="Night" onChange={(e) => handleTimeChange(e,i, 3)} /> Night
                            </label>
                          </div>
                          <span className="error">{handlePreError && handlePreError[i].timing && handlePreError[i].timing}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}

              <div className="text-right" onClick={addMorePrescription}>
                <a>Add more</a>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className={`signature mt-3 mb-0 ${!image && Submit ? "required" : ""}`}>
                <label htmlFor="signature">
                  <figure>
                    {image ? <img src={image} className="img-fluid" alt="" /> : <>Click here to add your signature.</>}
                    <input type="file" id="signature" name="signature" accept="image/*" hidden onChange={(e) => handleImage(e)} />
                  </figure>
                </label>
              </div>
              {!image && Submit ? <span className="error">*Required</span> : ""}
            </div>
          </div>
        </div>
        <div className="modal-footer text-center">
          <button className="btn book-btn w-25 mr-1" onClick={handleSubmit}>
            Submit
          </button>
          <button className="btn view-btn w-25" onClick={handleClose}>
            Cancel
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default AddPrescription;
