import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { useHistory } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../../constants";
import { getSessionStorageOrDefault, getSessionStorageValue } from "../../../utils";
// import { getSessionStorageOrDefault } from "../../../utils";

function ConfirmationModal({
  ConfirmModal,
  setConfirmModal,
  UpgradeConfirmToggle,
  RescheduleToggle,
  DataSub,
  RecheduleLabtoggle,
  isAdminNotVarifired,
}) {
  let history = useHistory();

  const getSessionStorageValue = (key) => {
    const stored = sessionStorage.getItem(key);
    console.log(stored);
    return JSON.parse(stored);
  };

  const handleClose = () => {
    if (getSessionStorageValue("userType") === "1") {
      if (isAdminNotVarifired) {
        history.push(ROUTES_CONSTANTS.HOME);
      } else {
        history.push(ROUTES_CONSTANTS.DASHBOARD);
      }
    }
    if (getSessionStorageValue("userType") === "2") {
      history.push(ROUTES_CONSTANTS.PATIENT_DASHBOARD);
    }
  };

  const handleGoHome = () => {
    // console.log("Coming here");
    // history.goBack();

    if (getSessionStorageValue("userType") === "1") {
      // console.log("Coming here");
      
      if (getSessionStorageValue("authToken")){
        history.push(ROUTES_CONSTANTS.DASHBOARD);
      }else{
        sessionStorage.clear();
        history.push(ROUTES_CONSTANTS.HOME);
      }
    }
    if (getSessionStorageValue("userType") === "2") {
      // console.log("Coming here");
      history.push(ROUTES_CONSTANTS.PATIENT_DASHBOARD);
    }
  };
  return (
    <Modal isOpen={ConfirmModal} className="modal-dialog  modal-dialog-centered ">
      <div class="modal-content p-3">
        <div class="text-right">
          {/* <button type="button" class="dr_Modalclose congrats-close-btn" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </button> */}
        </div>
        <ModalBody>
          <div class="row">
            <div class="col-lg-12 ">
              <div class="card mb-0">
                <div class="card-body">
                  <div class="text-center">
                    <span>
                      <i class="fa fa-check-square fa-2x text-center mb-2"></i>
                    </span>
                    <h3>Congrats</h3>
                    <p class=" color-grey">
                      {UpgradeConfirmToggle
                        ? "Your Subscription has been Upgraded."
                        : DataSub
                        ? "Your Subscription has been Upgraded"
                        : isAdminNotVarifired
                        ? "Your Application has been sent for Approval.Please wait until it get verified. You will be notified for the same"
                        : RescheduleToggle || RecheduleLabtoggle
                        ? "Your appointment has been rescheduled."
                        : "Your appointment has been scheduled"}
                    </p>
                    {<a onClick={handleGoHome}>Go to Home</a>}

                    {/* {isAdminNotVarifired ? "" : <a onClick={handleGoHome}>Go to Home</a>} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default ConfirmationModal;
