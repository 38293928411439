export default {
  BOOK_APPOITMENT: "BOOK_APPOITMENT",
  VIEW_DOCTOR_DETAILS: "VIEW_DOCTOR_DETAILS",
  VIEW_DOCTOR_DETAILS_FOR_SHOWING: "VIEW_DOCTOR_DETAILS_FOR_SHOWING",
  GET_SLOT: "GET_SLOT",
  UPCOMING_APPOINTMENTS: "UPCOMING_APPOIMENTS",
  PREVIOUS_APPOINTMENTS: "PREVIOUS_APPOINTMENTS",
  GET_SLOT_FOR_RESCHEDULE: "GET_SLOT_FOR_RESCHEDULE",
  GET_SLOT_FOR_LABORATORY: "GET_SLOT_FOR_LABORATORY",
};
