import React, { useState, useEffect } from "react";
import { ReactComponent as CardLogoIc } from "../../../assets/img/card-logo.svg";

function PaymentDetails({ formik, handleCard, CardErr, CardnumErr, handleConfirm }) {
  const handleExpiryDate = () => {
    const inp = document.querySelector("#input");

    inp.onkeydown = function (e) {
      const key = e.keyCode || e.charCode;

      if (key !== 8 && key !== 46) {
        if (inp.value.length == 2) {
          inp.value = inp.value + "/";
        }
      }

      if ((key == 8 || key == 46) && inp.value.length === 4) {
        inp.value = inp.value.slice(0, 3);
      }
    };
  };

  React.useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.target.name === "CardNum"){
        event.target.value = event.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
      }
      if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
        var form = event.target.form;
        var index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + 1].focus();
        event.preventDefault();
      }
    });
  }, []);
  
  return (
    <div className="modal-body p-4">
      <div className="row">
        <div className="col-lg-12 ">
          <div className="card mb-0">
            <div className="card-header align-button">
              <h4 className="card-title">Payment Details1</h4>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="card-body">
                <div className="row mb-2">
                  <div className="col-md-12">
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="Card" id="inlineRadio1" value="Credit" defaultChecked onClick={(e) => handleCard(e)} />
                      <label className="form-check-label" for="inlineRadio1">
                        Credit
                      </label>
                    </div>
                    <div className="form-check form-check-inline">
                      <input className="form-check-input" type="radio" name="Card" id="inlineRadio1" value="Debit" onClick={(e) => handleCard(e)} />
                      <label className="form-check-label" for="inlineRadio2">
                        Debit
                      </label>
                    </div>
                  </div>
                </div>
                {CardErr ? <div className="validation_error card">{CardErr}</div> : null}

                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group text-left">
                      <label for="Cardholder name">Full Name on Card</label>
                      <input type="text" className="form-control" name="fullname" maxLength="16" {...formik.getFieldProps("fullname")} />
                      {formik.touched.fullname && formik.errors.fullname ? <span className="validation_error">{formik.errors.fullname}</span> : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group text-left">
                      <label for="Cardholder name">Card Number</label>
                      <input type="text" className="form-control" name="CardNum" maxLength="19" {...formik.getFieldProps("CardNum")} />
                      {formik.touched.CardNum && formik.errors.CardNum && formik.values.CardNum === "" ? <span className="validation_error">{formik.errors.CardNum}</span> : null}
                      {CardnumErr ? <span className="validation_error">{CardnumErr}</span> : null}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group  text-left">
                      <label for="Cardholder name">CVV</label>
                      <input type="password" className="form-control" placeholder="CVV" maxLength="3" name="cvv" {...formik.getFieldProps("cvv")} />
                      {formik.touched.cvv && formik.errors.cvv ? <span className="validation_error">{formik.errors.cvv}</span> : null}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group text-left">
                      <label for="Cardholder name">Card Expiry</label>
                      <input type="text" id="input" className="form-control" placeholder="MM/YY" name="cardexpiry" maxLength="5" {...formik.getFieldProps("cardexpiry")} onFocus={handleExpiryDate} />
                      {formik.touched.cardexpiry && formik.errors.cardexpiry ? <span className="validation_error">{formik.errors.cardexpiry}</span> : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-check">
                      <input type="checkbox" className="form-check-input" id="payments" value="SaveForLater" onChange={(e) => handleCard(e)} />
                      <label className="savemefor" value="SaveForLater" name="SaveForLater" for="payments">
                        Save card for future payments
                      </label>
                    </div>
                  </div>
                </div>

                <div className="btn-p">
                  <button type="submit" className="btn btn-primary mt-2" onClick={(e) => handleConfirm(e)}>
                    Confirm
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PaymentDetails;
