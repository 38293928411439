import React from 'react'
import {Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { doctor } from '../../../redux/action';
import { useDispatch } from 'react-redux';
import ToasterFunc from '../../common/toaster/Toaster';

const SignUpNewsletterModal = ({ NewsletterModals, setNewsletterModals }) => {
  const dispatch = useDispatch()

  const newsLetter = async (payload) => dispatch(doctor.newsLetterSubscribeAction(payload));


  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
    },
    validationSchema: Yup.object({
      name: Yup.string().required("*Name is required.").trim(),
      email: Yup.string().email("Invalid email address").required("*Email is required.").trim(),
    }),
  });
  const handleSubmit  = async () => {
    if(formik.values.name && formik.values.email){
      const payload = {
        name: formik.values.name,
        email: formik.values.email,
      };
      const res = await newsLetter(payload);
      if (res.status == 200) {
        ToasterFunc("success", res.data.responseMessage);
        setNewsletterModals(false)
        formik.resetForm()
        
      }
    }

  }

  return (
    <>
      <Modal isOpen={NewsletterModals} className="modal-dialog modal-dialog-centered filter_by_modal">
        <div className="modal-content p-3">
          <div className="text-right">
            <button type="button" className="dr_Modalclose payment-method-modal" onClick={() => setNewsletterModals(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <ModalBody>
            <div className="card mb-0">
              <div className="card-body newsletter">
                <h1 className="text-center justify-content-center mb-2 color-grey">Subscribe Now</h1>
                <p className='mb-4 color-grey text-center'>Subscribe to our newsletter so we can spam you with  offers and discounts.</p>
                <form onSubmit={formik.handleSubmit}>
                <div className="row">
                  <div className="col-md-12">
                    <div className='form-group'>
                      <input type="text" name='name' placeholder='Name' className={`form-control ${formik.touched.name && formik.errors.name ? 'is-invalid' : ''}`} {...formik.getFieldProps("name")}/>
                      {formik.touched.name && formik.errors.name ? <span className="validation_error">{formik.errors.name}</span> : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className='position-rel form-group'>
                      <input type="text" name='email' placeholder='Email' className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`} {...formik.getFieldProps("email")}/>
                      {formik.touched.email && formik.errors.email ? <span className="validation_error">{formik.errors.email}</span> : null}
                    </div>
                  </div>
                </div>
                <div className="d-flex justify-content-center mt-3">
                  <button type='submit' className="btn book-btn w-100" onClick={handleSubmit}>
                    Subscribe
                  </button>
                </div>
                </form>
              </div>
            </div>
          </ModalBody>
        </div>
      </Modal>
    </>
  )
}

export default SignUpNewsletterModal

