import React from "react";
import { Modal, ModalBody } from "reactstrap";
import {
  EmailShareButton,
  FacebookShareButton,
  FacebookShareCount,
  HatenaShareCount,
  InstapaperShareButton,
  LinkedinShareButton,
  OKShareCount,
  PinterestShareCount,
  RedditShareCount,
  TumblrShareCount,
  TwitterShareButton,
  VKShareCount,
} from "react-share";
import { ReactComponent as LinkedinIC } from "../../../assets/img/linkedin.svg";

function SocialShareModal({ SocialModal, setSocialModal, getRefferalLink }) {
  const handleClose = () => {
    setSocialModal(false);
  };
  console.log("getRefferalLink", getRefferalLink);
  return (
    // <div className="modal fade pat-reports" id="pat_share-report" tabindex="-1" role="dialog">
    //   <div className="modal-dialog modal-dialog-centered " role="document">
    <Modal isOpen={SocialModal} className="pat-reports modal-dialog modal-dialog-centered">
      <div className="modal-content p-3">
        <div className="text-right">
          <button type="button" className="dr_Modalclose" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          {/* <div className="modal-body"> */}

          <div className="row">
            <div className="col-lg-12 ">
              <div className="card mb-0">
                <div className="card-body">
                  <h4 className="text-center mb-2">Share Link</h4>
                  <div className="popup-social-media-icon text-center mt-2">
                    <FacebookShareButton url={getRefferalLink?.url ? getRefferalLink?.url : getRefferalLink}>
                      <span>
                        <i className="fab fa-facebook-f"></i>
                      </span>
                    </FacebookShareButton>
                    <TwitterShareButton url={getRefferalLink?.url ? getRefferalLink?.url : getRefferalLink}>
                      <span>
                        <i className="fab fa-twitter"></i>
                      </span>
                    </TwitterShareButton>
                    {/* <LinkedinShareButton url={getRefferalLink?.url ? getRefferalLink?.url : getRefferalLink}>
                      <span>
                        <i className="fab fa-linkdin-in"></i>
                      </span>
                    </LinkedinShareButton> */}
                    {/* <InstapaperShareButton url={getRefferalLink?.url ? getRefferalLink?.url : getRefferalLink}>
                      <span>
                        <i className="fab fa-instagram"></i>
                      </span>
                    </InstapaperShareButton> */}
                    <LinkedinShareButton className="linkedin_ic" url={getRefferalLink?.url ? getRefferalLink?.url : getRefferalLink}>
                      <span>
                        <LinkedinIC />
                      </span>
                    </LinkedinShareButton>
                    <EmailShareButton url={getRefferalLink?.url ? getRefferalLink?.url : getRefferalLink}>
                      <span>
                        <i class="fa fa-envelope" aria-hidden="true"></i>
                      </span>
                    </EmailShareButton>
                  </div>
                  <div className="input-social-media mt-4 ">
                    <input type="text" className="form-control" placeholder={getRefferalLink?.url ? getRefferalLink?.url : getRefferalLink} />
                    <span
                      className="copy-input"
                      onClick={() => {
                        navigator.clipboard.writeText(getRefferalLink?.url ? getRefferalLink?.url : getRefferalLink);
                      }}
                    >
                      <b>Copy</b>{" "}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </ModalBody>
      </div>
    </Modal>
    //   </div>
    // </div>
  );
}

export default SocialShareModal;
