import React from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { patient } from "../../../../redux/action";
import { useDispatch } from "react-redux";
import ToasterFunc from "../../../common/toaster/Toaster";
import { useHistory } from "react-router-dom";

function AddReqest() {
  let history = useHistory();
  let dispatch = useDispatch();
  const formik = useFormik({
    initialValues: {
      subject: "",
      description: "",
    },
    validationSchema: Yup.object({
      subject: Yup.string().required("*Required.").trim(),
      description: Yup.string().required("*Required.").trim(),
    }),
  });

  const patientSubmitHelpRequest = async (payload) => dispatch(patient.patientSubmitHelpRequestAction(payload));

  const handleSend = async () => {
    if (formik.values.subject !== "" && formik.values.description !== "") {
      const payload = {
        subject: formik.values.subject,
        message: formik.values.description,
      };

      const res = await patientSubmitHelpRequest(payload);
      if (res?.status === 200) {
        ToasterFunc("success", res.data.responseMessage);
        history.goBack();
      }
    }
  };
  return (
    <div className=" col-lg-8 col-xl-9">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Submit New Request</h4>
            </div>

            <div className="card-body">
              <form onSubmit={formik.handleSubmit}>
                <div className="subject mb-3">
                  <h6>
                    <b>Subject</b>
                  </h6>
                  <input type="text" className="form-control" name="subject" maxLength="32" {...formik.getFieldProps("subject")} />
                  {formik.touched.subject && formik.errors.subject ? <span classNameName="validation_error">{formik.errors.subject}</span> : null}
                </div>
                <div className="subject mb-3">
                  <h6>
                    <b>Description</b>
                  </h6>
                  <textarea className="form-control" name="description" id="subject" rows="4" maxLength="180" {...formik.getFieldProps("description")}></textarea>
                  {formik.touched.description && formik.errors.description ? <span classNameName="validation_error">{formik.errors.description}</span> : null}
                </div>

                <div className="submit-section text-center">
                  <button type="submit" className="btn btn-primary w-25 mt-2 mb-3" onClick={handleSend}>
                    Send
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddReqest;
