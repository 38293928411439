import React, { useState } from "react";
import { useLocation } from "react-router-dom";
import { PatientBilling } from "../..";
import CompletedAppDetails from "./CompletedAppDetails";
import PatientBill from "./PatientBill";
import PatientMedicalRecords from "./PatientMedicalRecords";
import PatientPrescription from "./PatientPrescription";

function CompletedViewApp() {
  let location = useLocation();
  const [Deatils, setDeatils] = useState({
    appointmentDetails: true,
    prescription: false,
    medical: false,
    billing: false,
  });
  const handleDetails = (data) => {
    if (data === "AppointmentDetail") {
      setDeatils({
        // ...Deatils,
        appointmentDetails: true,
        prescription: false,
        medical: false,
        billing: false,
      });
    } else if (data === "Prescription") {
      setDeatils({
        // ...Deatils,
        prescription: true,
        appointmentDetails: false,
        medical: false,
        billing: false,
      });
    } else if (data === "MedicalRecord") {
      setDeatils({
        // ...Deatils,
        medical: true,
        prescription: false,
        appointmentDetails: false,
        billing: false,
      });
    } else if (data === "Billing") {
      setDeatils({
        // ...Deatils,
        billing: true,
        medical: false,
        prescription: false,
        appointmentDetails: false,
      });
    }
  };
  


  console.log("location", location);
  return (
    <div className=" col-lg-8 col-xl-9 appointments pat-appointment-detail">
      <div className="card">
        <div className="card-body pt-0">
          <nav className="user-tabs mb-4">
            <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
              <li className="nav-item">
                <a className={Deatils.appointmentDetails ? "nav-link active" : "nav-link"} onClick={() => handleDetails("AppointmentDetail")}>
                  Appointments Details
                </a>
              </li>
              <li className="nav-item">
                <a className={Deatils.prescription ? "nav-link active" : "nav-link"} onClick={() => handleDetails("Prescription")}>
                  Prescription
                </a>
              </li>
              <li className="nav-item">
                <a className={Deatils.medical ? "nav-link active" : "nav-link"} onClick={() => handleDetails("MedicalRecord")}>
                  Medical Records
                </a>
              </li>
              <li className="nav-item">
                <a className={Deatils.billing ? "nav-link active" : "nav-link"} onClick={() => handleDetails("Billing")}>
                  Billing
                </a>
              </li>
            </ul>
          </nav>
          {Deatils.appointmentDetails ? (
            <CompletedAppDetails DocData={location.state} />
          ) : Deatils.prescription ? (
            <PatientPrescription DocData={location.state} />
          ) : Deatils.medical ? (
            <PatientMedicalRecords DocData={location.state} />
          ) : Deatils.billing ? (
            <PatientBill DocData={location.state} />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default CompletedViewApp;
