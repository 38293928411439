import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../../constants";
import { patient } from "../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import defaultImg from "../../../../assets/img/default-img.png";
import { useHistory } from "react-router-dom";
import MultipleSpeciality from "../../findDoctorsNearBy/MultipleSpeciality";
import ReactStars from "react-stars";
import PaginationComponent from "react-reactstrap-pagination";

function MedicalHistory() {
  let dispatch = useDispatch();
  let history = useHistory();
  const [ViewMultiSpe, setViewMultiSpe] = useState(false);
  const [pageNumber, setpageNumber] = useState(1);
  const [PrimarySpeId, setPrimarySpeId] = useState("");
  const [MultiSpeData, setMultiSpeData] = useState([]);

  const patientMedicalRecordsData = async (payload) => dispatch(patient.patientMedicalRecordsListAction(payload));

  const patientMedicalRecordsList = useSelector((state) => state?.patient?.patientMedicalRecordsList);

  useEffect(() => {
    callApi();
  }, []);

  useEffect(() => {
    callApi();
  }, [pageNumber]);

  const callApi = () => {
    patientMedicalRecordsData({
      pageNumber: pageNumber,
      limit: 10,
    });
  };

  const handleChange = (e) => {
    setpageNumber(e);
  };
  const handlePrescription = (id, name) => {
    history.push({
      pathname: ROUTES_CONSTANTS.PATIENT_PRESCRIPTION_LIST,
      state: { Id: id, Name: name },
    });
  };
  const handleMedical = (DocId, name) => {
    console.log(DocId);
    history.push(`${ROUTES_CONSTANTS.PATIENT_VIEW_MEDICAL_RECORDS}/${DocId}`, name);
  };
  const handleBilling = (DocId) => {
    history.push(ROUTES_CONSTANTS.PATIENT_BILL_IN_MEDICAL_HISTORY, DocId);
  };
  return (
    <div className=" col-lg-8 col-xl-9 content_ipad_p">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Medical History</h4>
        </div>
        {console.log("patientMedicalRecordsList", patientMedicalRecordsList)}
        <div className="card-body ">
          <div className="row">
            {patientMedicalRecordsList &&
              patientMedicalRecordsList?.documentList?.length ?
              patientMedicalRecordsList?.documentList?.map((item) => (
                <div className="col-sm-6 col-md-6 col-lg-4">
                  <a>
                    <div className="card widget-profile pat-widget-profile">
                      <div className="card-body li-listing-profile">
                        <div className="profile-info-widget">
                          <div className="booking-doc-img">
                            <img src={item && item.profilePic ? item.profilePic : defaultImg} alt="" />
                          </div>
                        </div>
                        <div className="profile-det-info text-center">
                          <h3>Dr. {item && item.fullName ? item.fullName : ""}</h3>
                        </div>
                        <div className="patient-info color-grey ">
                          <div className="color-grey">
                            <b>
                              ${item && item.serviceCharge ? item.serviceCharge : ""}
                              Consultation Fee
                            </b>
                          </div>
                          <div className="color-gray">
                            {item && item.experience_in_years ? `${item.experience_in_years} Years  ` : ""}
                            {item && item.experience_in_months ? `${item.experience_in_months} Months` : ""}
                            Of Experience
                          </div>
                          <div className="color-gray">
                            {item && item.secondry_specialty && item.secondry_specialty.length === 1 ? (
                              item?.secondry_specialty[0]?.secondry_specialty
                            ) : (
                              <span
                                onClick={() => {
                                  setViewMultiSpe(!ViewMultiSpe);
                                  setPrimarySpeId(item?.primary_specialtyId?._id);
                                  setMultiSpeData(item?.specialties);
                                }}
                              >
                                Multi Speciality
                              </span>
                            )}
                          </div>
                          <div className="color-gray">{item && item.primary_specialtyId && item.primary_specialtyId.primary_specialty ? item.primary_specialtyId.primary_specialty : "--"}</div>
                          <div className=" rating ">
                            <ReactStars edit={false} count={5} value={item?.rating} size={16} style={{ selfAlign: "center" }} color2={"#ffd700"} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12 mb-2 mt-3 d-flex justify-content-around">
                            <a className="btn book-btn mr-1 btn-new-2" onClick={() => handlePrescription(item._id, item.fullName)}>
                              Prescription
                            </a>
                            <a onClick={() => handleMedical(item?._id, item?.fullName)} className="btn book-btn mr-1 btn-new-2">
                              Medical
                            </a>
                            <a onClick={() => handleBilling(item?._id)} className="btn book-btn btn-new-2">
                              Billing
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              )) : (
                <div style={ {paddingLeft : "16px" }}> 
                   <label>
                      No History Found !
                   </label>
                </div>
              )}
          </div>
        </div>
      </div>
      <MultipleSpeciality ViewMultiSpe={ViewMultiSpe} setViewMultiSpe={setViewMultiSpe} PrimarySpeId={PrimarySpeId} MultiSpeData={MultiSpeData} />
      {patientMedicalRecordsList?.totalList > 10 && (
        <PaginationComponent
          totalItems={patientMedicalRecordsList && patientMedicalRecordsList?.totalList}
          pageSize={10}
          maxPaginationNumbers={Math.ceil(patientMedicalRecordsList && patientMedicalRecordsList?.totalList / 10)}
          defaultActivePage={1}
          onSelect={(e) => handleChange(e)}
        />
      )}
    </div>
  );
}

export default MedicalHistory;
