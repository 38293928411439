import React, { Component } from "react";
import InputRange from "react-input-range";
import "../../../../node_modules/react-input-range/lib/css/index.css";

export class InputRangeSlider extends Component {
  constructor(props) {
    super(props);
    this.state = {
      rating: {
        min: 0,
        max: 5,
      },
      distance: 30,
      cost: {
        min: 0,
        max: 1000,
      },
      revisedDistance: 0,
    };
  }
  componentDidMount = () => {
    if (this.props.FilterToggle) {
      this.setState({
        rating: {
          min: this.props?.FilterData?.rating?.min,
          max: this.props?.FilterData?.rating?.max,
        },
        distance: this.props.FilterData.distance,
        cost: {
          min: this.props?.FilterData?.cost?.min,
          max: this.props?.FilterData?.cost?.max,
        },
      });
    }
  };

  componentDidUpdate = () => {
    //   console.log(this.props.getRangeData);
    this.props.getRangeData(this.state);
    console.log(this.state.revisedDistance);
    console.log("props", this.props);
  };

  render() {
    return (
      <div className="rangSlide_bx">
        <form className="form">
          <div className="row mb-2">
            <div className="col-md-4">
              <label htmlFor="Rating">
                <span>
                  <i className="fa fa-star mr-2"></i>
                  <span className="color-grey">Rating</span>
                </span>
              </label>
            </div>
            <div className="col-md-8">
              <InputRange
                // draggableTrack
                maxValue={5}
                minValue={0}
                onChange={(value) => this.setState({ rating: value })}
                onChangeComplete={(value) => console.log(value)}
                value={this.state.rating}
              />
              <div className="d-flex justify-content-between range_value__bx">
                <span>{this.state.rating.min}</span>
                <span>{this.state.rating.max}</span>
              </div>
            </div>
          </div>

          <div className="row mb-2">
            <div className="col-md-4">
              <label htmlFor="Cost">
                <span>
                  <i className="fas fa-money-bill mr-2"></i>
                  <span className="color-grey"> Cost</span>
                </span>
              </label>
            </div>
            <div className="col-md-8">
              <InputRange maxValue={1000} minValue={0} formatLabel={(value) => `${value} $`} value={this.state.cost} onChange={(value) => this.setState({ cost: value })} onChangeComplete={(value) => console.log(value)} />
              <div className="d-flex justify-content-between range_value__bx">
                <span>{this.state.cost.min}$</span>
                <span>{this.state.cost.max}$</span>
              </div>
            </div>
          </div>
          <div className="row mb-2">
            <div className="col-md-4">
              <label htmlFor="Distance">
                <span>
                  <i className="fa fa-road mr-2"></i>
                  <span className="color-grey"> Distance</span>
                </span>
              </label>
            </div>
            <div className="col-md-8">
              <InputRange
                // draggableTrack
                maxValue={30}
                minValue={0}
                formatLabel={(value) => `${value} $`}
                onChange={(value) => {
                  this.setState({ distance: value });
                }}
                onChangeComplete={(value) => console.log(value)}
                value={this.state.distance}
              />
              <div className="d-flex justify-content-between range_value__bx">
                <span>0 km</span>
                <span>{this.state.distance} km</span>
              </div>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default InputRangeSlider;
