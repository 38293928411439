import axios from "axios";
import { APP_CONSTANTS } from "../../../constants/appConstants";
import { getSessionStorageOrDefault } from "../../../utils";
export const APIURL = `${process.env.REACT_APP_GYM_DOCTOR_API_BASE_URL}`;
// export const APIURL = "http://localhost:4000/api/v1/"


// Add a response interceptor
axios.interceptors.response.use(function (response) {
  // Any status code that lie within the range of 2xx cause this function to trigger
  // Do something with response data; 
  if(response && response.data.responseCode === 401){
    window.localStorage.clear();
    window.sessionStorage.clear();
    if (getSessionStorageOrDefault('userType') == 1){
      window.location.pathname = '/';
    }else{
      window.location.pathname = '/patient-dashboard';
    }
    
    window.location.reload();
  }
  return response;
}, function (error) {
  console.log('error',error.response);
  if(error && error.response && error.response.status === 401){
    window.localStorage.clear();
    window.sessionStorage.clear();
    if (getSessionStorageOrDefault('userType') == 1){
      window.location.pathname = '/';
    }else{
      window.location.pathname = '/patient-dashboard';
    }
    window.location.reload();
  }
  // Do something with response error
  return Promise.reject(error);
});

class HttpService {
  baseConfiguration = {
    url: "",
    method: APP_CONSTANTS.HTTP.METHOD.GET,
    baseURL: APIURL,
    headers: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
    },
  };

  get(url, params = {}, option = {}) {
    const settings = {
      method: APP_CONSTANTS.HTTP.METHOD.GET,
      url,
      params,
      ...option,
    };
    return this.prepareRequest(settings);
  }

  post(url, data, option = {}) {
    const settings = {
      method: APP_CONSTANTS.HTTP.METHOD.POST,
      url,
      data,
      ...option,
    };
    return this.prepareRequest(settings);
  }

  put(url, data, option = {}) {
    const settings = {
      method: APP_CONSTANTS.HTTP.METHOD.PUT,
      url,
      data,
      ...option,
    };
    return this.prepareRequest(settings);
  }

  delete(url, params = {}, option = {}) {
    const settings = {
      method: APP_CONSTANTS.HTTP.METHOD.DELETE,
      url,
      params,
      ...option,
    };
    return this.prepareRequest(settings);
  }

  patch(url, data, option = {}) {
    const settings = {
      method: APP_CONSTANTS.HTTP.METHOD.PATCH,
      url,
      data,
      ...option,
    };
    return this.prepareRequest(settings);
  }

  prepareRequest(settings) {
    const config = { ...this.baseConfiguration, ...settings };
    const token = getSessionStorageOrDefault("authToken");
    if (token) {
      config.headers["token"] = token;
    }
    return this.makeRequest({ ...config });
  }

  makeRequest(request) {
    return axios({ ...request });
  }
}

export default new HttpService();
