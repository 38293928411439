export const ROUTES_CONSTANTS = {
  HOME: "/",
  HOME_REFFRAL: "/referral",
  HOME_SEARCH: "/home-search",
  DOC_PERSONAL_SIGNUP: "/doc-personal",
  DOC_PROFESSIONAL_SIGNUP: "/doc-professional",
  PAT_PERSONAL_SIGNUP: "/patient-personal",
  PAT_MEDICAL_SIGNUP: "/patient-medical",
  DOC_VIEW_PROFILE: "/doc-view-profile",
  PAT_VIEW_PROFILE: "/pat-view-profile",
  DASHBOARD: "/dashboard",
  PATIENT_DASHBOARD: "/patient-dashboard",
  INBOX: "/inbox",
  APPOINTMENTS: "/appointments",
  SCHEDULE_AVAILABILITY: "/scheduleavailability",
  REVIEW: "/review",
  BILLINGS: "/billings",
  SUBSCRIPTION: "/subscription",
  NOTIFICATIONS: "/notifications",
  HELP: "/help",
  DOCTOR_HELP_NEW: "/help-new-request",
  DOCTOR_VIEW_HELP_REQUEST: "/help-view-request",
  WALLETS: "/wallets",
  PROFILE_SETTINGS: "/profile-settings",
  ABOUT_US: "/aboutus",
  TERMS_AND_CONDITIONS: "/termsandconditions",
  PRIVACY_POLICY: "/privacypolicy",

  FIND_PHYSICIANS_NEAR: "/find-physicians-nearby",
  PATIENT_APPOINTMENT: "/patient-appointment-details",
  PATIENT_MESSAGES: "/patient-messages",
  PATIENT_FAVOURITE_SPECIALIST: "/patient-favourite-specialist",
  PATIENT_REQUEST: "/patient-request",
  PATIENT_REPORTS: "/patient-reports",
  PATIENT_MEDICAL_HISTORY: "/patient-medical-history",
  PATIENT_HELP: "/patient-help",
  PATIENT_REVIEW: "/patient-review-rating",
  PATIENT_WALLET: "/patient-wallet",
  PATIENT_SUBSCRIPTION: "/patient-subscription",
  PATIENT_BILLING: "/patient-billing",
  PATIENT_SETTINGS: "/patient-settings",
  PATIENT_BOOK_APPOINTMENT: "/patient-book-appointment",
  PATIENT_APPOINTMENT_DETAIL: "/patient-appointment-detail",
  PATIENT_NOTIFICATIONS: "/patient-notifications",
  PATIENT_VIEW_APPOINTMENT: "/patient-view-appointment",
  PATIENT_EDIT_CARD_SETTING: "/patient-edit-card-detail",
  PATIENT_NEW_APPOINTMENT_DETAIL: "/patient-new-appointment-detail",
  PATIENT_PRESCRIPTION_LIST: "/patient-prescription-list",
  PATIENT_SIDE_RESCHEDULE_APPOINTMENT: "/patient-reschedule-appointment",
  PATIENT_COMPLETED_VIEW_APPOINTMENT: "/patient-completed-view",
  PATIENT_LABORATORY_PACKAGES: "/patient-laboratory-packages",
  PATIENT_PATIENT_DETAIL_LAB: "/patient-detail-laboratory",
  PATIENT_DELIVERY_LOCATION_LAB: "/patient-delivery-location",
  PATIENT_BOOK_LABORATORY: "/patient-book-laboratory",
  PATIENT_BILL_DETAIL_LAB: "/patient-bill-detail",
  PATIENT_VIEW_MEDICAL_RECORDS: "/patient-medical-records",
  PATIENT_VIEW_DOCTOR_DETAILS: "/view-doctor-details",
  PATIENT_EDIT_PERSONAL_INFO: "/edit-personal-info",
  PATIENT_EDIT_MEDICAL_INFO: "/edit-medical-info",
  PATIENT_ADD_NEW_HELP_REQUEST: "/add-new-help-request",
  PATIENT_VIEW_HELP_REQUEST: "/view-help-request",
  PATIENT_RESCHEDULE_LABORATORY: "/reschedule-laboratory",
  VIDEO_CALL: "/video-call",
  VOICE_CALL: "/voice-call",
  PATIENT_BILL_IN_MEDICAL_HISTORY: "/patient-bill-medical-history",

  DOCTOR_VIEW_UPCOMING_APPOINTMENT: "/doctor-view-appointment",
  DOCTOR_SIDE_RESCHEDULE_APPOINTMENT: "/doctor-reschedule-appointment",
  DOCTOR_EDIT_PERSONAL_DETAIL: "/doctor-edit-personal-detail",
  DOCTOR_EDIT_PROFESSIONAL_DETAIL: "/doctor-edit-professional-detail",

  CONTACT_US: "/contact-us",
  OUR_MISSION: "/our-mission",
  OUR_TEAM: "/our-team",
  HOW_IT_WORKS: "/how-it-works",
  METABOLIC_CENTERS: "/metabolic-centers",
  TESTOMONIALS: "/testomonials",
  WHAT_WE_TREAT: "/what-we-treat",
  BECOME_PARTICIPATING_CENTERS: "/become-participating-centers",
  PARTICIPATING_CENTERS: "/participating-centers",
  REFER_CENTER: "/refer-centers",
  ONLINE_HEALTHBLOG: "/online-healthblog",
  //  ONLINE_HEALTHBLOG: "/online-healthblog",
  FIND_PROVIDER: "/find-provider",
  INCOMING_CALL: "/incoming-call",
  OUTGOING_CALL: "/outgoing-call",
  DOCTOR_PROFILE: "/doctor-profile",
};

export const ROUTES_CONSTANTS_NAME = {
  [ROUTES_CONSTANTS.DOC_VIEW_PROFILE]: [{ name: "Profile" }],
  [ROUTES_CONSTANTS.PAT_VIEW_PROFILE]: [{ name: "Profile" }],
  [ROUTES_CONSTANTS.DASHBOARD]: [{ name: "Dashboard" }],
  [ROUTES_CONSTANTS.PATIENT_DASHBOARD]: [{ name: "Dashboard" }],
  [ROUTES_CONSTANTS.INBOX]: [{ name: "Dashboard", path: "dashboard" }, { name: "Chat" }],
  [ROUTES_CONSTANTS.APPOINTMENTS]: [{ name: "Appointments" }],
  [ROUTES_CONSTANTS.SCHEDULE_AVAILABILITY]: [{ name: "Schedule Availability" }],
  [ROUTES_CONSTANTS.REVIEW]: [{ name: "Review" }],
  [ROUTES_CONSTANTS.BILLINGS]: [{ name: "Billings" }],
  [ROUTES_CONSTANTS.SUBSCRIPTION]: [{ name: "Subscription" }],
  [ROUTES_CONSTANTS.NOTIFICATIONS]: [{ name: "Notifications" }],
  [ROUTES_CONSTANTS.HELP]: [{ name: "Help" }],
  [ROUTES_CONSTANTS.DOCTOR_HELP_NEW]: [{ name: "Help Request" }],
  [ROUTES_CONSTANTS.DOCTOR_VIEW_HELP_REQUEST]: [{ name: "Help Request" }],
  [ROUTES_CONSTANTS.WALLETS]: [{ name: "Wallets" }],
  [ROUTES_CONSTANTS.PROFILE_SETTINGS]: [{ name: "Profile Settings" }],
  [ROUTES_CONSTANTS.ABOUT_US]: [{ name: "About Us" }],
  [ROUTES_CONSTANTS.TERMS_AND_CONDITIONS]: [{ name: "Terms and Conditions" }],
  [ROUTES_CONSTANTS.FIND_PHYSICIANS_NEAR]: [{ name: "Dashboard", path: "patient-dashboard" }, { name: "Doctors Nerar By" }],
  [ROUTES_CONSTANTS.PATIENT_APPOINTMENT]: [{ name: "Appointment" }],
  [ROUTES_CONSTANTS.PATIENT_MESSAGES]: [{ name: "Patient Messages" }],
  [ROUTES_CONSTANTS.PATIENT_COMPLETED_VIEW_APPOINTMENT]: [
    { name: "Appointment", path: "patient-appointment-details" },
    { name: "Appointment Details" },
  ],
  [ROUTES_CONSTANTS.PATIENT_VIEW_DOCTOR_DETAILS]: [{ name: "Dashboard", path: "patient-dashboard" }, { name: "View details" }],
  [ROUTES_CONSTANTS.PATIENT_LABORATORY_PACKAGES]: [{ name: "Dashboard", path: "patient-dashboard" }, { name: "View Laboratory" }],
  [ROUTES_CONSTANTS.PATIENT_BOOK_LABORATORY]: [{ name: "Dashboard", path: "patient-dashboard" }, { name: "Book Laboratory" }],
  [ROUTES_CONSTANTS.PATIENT_DELIVERY_LOCATION_LAB]: [{ name: "Dashboard", path: "patient-dashboard" }, { name: "Book Laboratory" }],
  [ROUTES_CONSTANTS.PATIENT_PATIENT_DETAIL_LAB]: [{ name: "Dashboard", path: "patient-dashboard" }, { name: "Book Laboratory" }],
  [ROUTES_CONSTANTS.PATIENT_BILL_DETAIL_LAB]: [{ name: "Dashboard", path: "patient-dashboard" }, { name: "Book Laboratory" }],
  [ROUTES_CONSTANTS.PATIENT_VIEW_MEDICAL_RECORDS]: [{ name: "Dashboard", path: "patient-dashboard" }, { name: "View medical record" }],
  [ROUTES_CONSTANTS.PATIENT_RESCHEDULE_LABORATORY]: [
    { name: "Appointment", path: "patient-appointment-details" },
    { name: "Reschedule laboratory appointment" },
  ],
  [ROUTES_CONSTANTS.PATIENT_BILL_IN_MEDICAL_HISTORY]: [{ name: "Dashboard", path: "patient-dashboard" }, { name: "View billings" }],
  [ROUTES_CONSTANTS.PATIENT_ADD_NEW_HELP_REQUEST]: [{ name: "Dashboard", path: "patient-dashboard" }, { name: "Submit New request" }],
  [ROUTES_CONSTANTS.DOCTOR_EDIT_PERSONAL_DETAIL]: [{ name: "Dashboard", path: "patient-dashboard" }, { name: "Edit Personal detail" }],
  [ROUTES_CONSTANTS.DOCTOR_EDIT_PROFESSIONAL_DETAIL]: [
    { name: "Dashboard", path: "patient-dashboard" },
    { name: "Edit Professional detail" },
  ],
  [ROUTES_CONSTANTS.PATIENT_VIEW_HELP_REQUEST]: [{ name: "Dashboard", path: "patient-dashboard" }, { name: "View help request" }],
  [ROUTES_CONSTANTS.PATIENT_EDIT_PERSONAL_INFO]: [{ name: "Dashboard", path: "patient-dashboard" }, { name: "Edit Personal details" }],
  [ROUTES_CONSTANTS.PATIENT_EDIT_MEDICAL_INFO]: [{ name: "Dashboard", path: "patient-dashboard" }, { name: "Edit Medical details" }],

  [ROUTES_CONSTANTS.PATIENT_FAVOURITE_SPECIALIST]: [{ name: "Favourite Specialists" }],
  [ROUTES_CONSTANTS.PATIENT_REQUEST]: [{ name: "Requests" }],
  [ROUTES_CONSTANTS.PATIENT_REPORTS]: [{ name: "My Reports" }],
  [ROUTES_CONSTANTS.PATIENT_MEDICAL_HISTORY]: [{ name: "Medical History" }],
  [ROUTES_CONSTANTS.PATIENT_HELP]: [{ name: "Help" }],
  [ROUTES_CONSTANTS.PATIENT_REVIEW]: [{ name: "Reviews" }],
  [ROUTES_CONSTANTS.PATIENT_WALLET]: [{ name: "Wallet" }],
  [ROUTES_CONSTANTS.PATIENT_SUBSCRIPTION]: [{ name: "Subscription" }],
  [ROUTES_CONSTANTS.PATIENT_BILLING]: [{ name: "Billing" }],
  [ROUTES_CONSTANTS.PATIENT_SETTINGS]: [{ name: "Settings" }],
  [ROUTES_CONSTANTS.PATIENT_BOOK_APPOINTMENT]: [{ name: "Dashboard", path: "patient-dashboard" }, { name: "Book appointment" }],
  [ROUTES_CONSTANTS.PATIENT_APPOINTMENT_DETAIL]: [{ name: "patient appointment detail" }],
  [ROUTES_CONSTANTS.PATIENT_NOTIFICATIONS]: [{ name: "Notifications" }],
  [ROUTES_CONSTANTS.PATIENT_VIEW_APPOINTMENT]: [
    { name: "Appointment", path: "patient-appointment-details" },
    { name: "Appointment Details" },
  ],
  [ROUTES_CONSTANTS.PATIENT_EDIT_CARD_SETTING]: [{ name: "Patient edit card detail" }],
  [ROUTES_CONSTANTS.PATIENT_NEW_APPOINTMENT_DETAIL]: [{ name: "Patient new appointment detail" }],
  [ROUTES_CONSTANTS.PATIENT_PRESCRIPTION_LIST]: [{ name: "Patient prescription list" }],

  [ROUTES_CONSTANTS.DOCTOR_VIEW_UPCOMING_APPOINTMENT]: [{ name: "Doctor view appointment" }],
  [ROUTES_CONSTANTS.DOCTOR_SIDE_RESCHEDULE_APPOINTMENT]: [{ name: "Doctor reschedule appointment" }],
  [ROUTES_CONSTANTS.DOCTOR_PROFILE]: [{ name: "Doctor Profile" }],
};
