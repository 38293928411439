import { useDispatch, useSelector } from "react-redux";
import { patient } from "../../../../redux/action";
import { useEffect, useState } from "react";
import UpgradeSubscription from "../../findDoctorsNearBy/UpgradeSubscription";
import moment from "moment";

function PatientWallet() {
  let dispatch = useDispatch();
  const [PatSubcriptionModal, setPatSubcriptionModal] = useState(false);
  const [PatUpgradeSub, setPatUpgradeSub] = useState(false);
  const [ValidityDay, setValidityDay] = useState("");
  const patientWalletData = useSelector((state) => state?.patient?.patientWalletData?.walletData);
  const patientSubscriptionData = useSelector((state) => state?.patient?.patientSubscriptionData?.result?.subscriptionId);
  console.log("patientSubscriptionData", patientSubscriptionData);
  useEffect(() => {
    window.scrollTo(0, 0);
    patientSubscriptionInfo();
  }, []);
  const patientSubscriptionInfo = async () => dispatch(patient.patientSubscriptionInWebAction());
  console.log("patientWalletData", patientWalletData);
  
  useEffect(() => {
    window.scrollTo(0, 0);
    patientWalletInfo();
  }, []);
  
  const patientWalletInfo = async () => dispatch(patient.patientWalletInWebAction());
  const handleUpgrade = () => {
    setPatSubcriptionModal(!PatSubcriptionModal);
    setPatUpgradeSub(true);
  };

  // console.log(moment(patientWalletData.createdAt).format("MM-DD-YYYY"));
  // console.log(moment(patientWalletData.expiryDate).format("MM-DD-YYYY"));

  useEffect(() => {
    const date1 = new Date(moment(patientWalletData?.createdAt).format("MM/DD/YYYY"));
    const date2 = new Date(moment(patientWalletData?.expiryDate).format("MM/DD/YYYY"));

    function getDifferenceInDays(date1, date2) {
      const diffInMs = Math.abs(date2 - date1);
      return diffInMs / (1000 * 60 * 60 * 24);
    }
    console.log(getDifferenceInDays(date1, date2));
    setValidityDay(getDifferenceInDays(date1, date2));
  }, [patientWalletData]);

  return (
    <div class=" col-lg-8 col-xl-9 content_ipad_p">
      <div class="card">
        <div class="card-header">
          <h4>Wallet</h4>
        </div>
        <div class="card-body">
          <div class="d-flex justify-content-end mb-2">
            <button class="btn btn-primary" onClick={handleUpgrade}>
              <span>
                <i class="fas fa-plus-square"></i>
              </span>{" "}
              Upgrade/Downgrade Subscription
            </button>
          </div>
          <div class="row">
            <div class="col-md-6">
              <div class="card">
                <div class="card-body">
                  <div class="text-center mb-4">
                    <h4 class="color-grey">Available Balance</h4>
                    <h3 class="mb-0">${patientWalletData && patientWalletData.totalBalance ? patientWalletData.totalBalance : 0}</h3>
                    <span for="" class="color-grey">
                      Total Balance
                    </span>
                  </div>
                  <div>
                    <span class="color-grey">Number of Sessions: {patientWalletData && patientWalletData.sessions ? patientWalletData.sessions : 0}</span>
                  </div>
                  <div>
                    <span class="color-grey">Number of Messages: {patientWalletData && patientWalletData.numberOfMessages ? patientWalletData.numberOfMessages : 0}</span>
                  </div>
                  <div>
                    <span class="color-grey"> Subscription Expiry Date : {patientWalletData && new Date(patientWalletData?.expiryDate).toDateString().split(' ').slice(1).join(' ')}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* <h4 className="mb-2">Recent Transactions</h4>
          <div class="card mb-2">
            <div class="card-body p-3 d-flex justify-content-between">
              <span> Apollo Pharmacy </span>
              <span>$20</span>
            </div>
          </div>
          <div class="card mb-2">
            <div class="card-body p-3 d-flex justify-content-between">
              <span> Apollo Pharmacy </span>
              <span>$20</span>
            </div>
          </div>
          <div class="card mb-2">
            <div class="card-body p-3 d-flex justify-content-between">
              <span> Apollo Pharmacy </span>
              <span>$20</span>
            </div>
          </div>
          <div class="card mb-2">
            <div class="card-body p-3 d-flex justify-content-between">
              <span> Apollo Pharmacy </span>
              <span>$20</span>
            </div>
          </div>
          <div class="card mb-2">
            <div class="card-body p-3 d-flex justify-content-between">
              <span> Apollo Pharmacy </span>
              <span>$20</span>
            </div>
          </div>
          <div class="card mb-2">
            <div class="card-body p-3 d-flex justify-content-between">
              <span> Apollo Pharmacy </span>
              <span>$20</span>
            </div>
          </div>
          <div class="card mb-2">
            <div class="card-body p-3 d-flex justify-content-between">
              <span> Apollo Pharmacy </span>
              <span>$20</span>
            </div>
          </div>
          <div class="card mb-2">
            <div class="card-body p-3 d-flex justify-content-between">
              <span> Apollo Pharmacy </span>
              <span>$20</span>
            </div>
          </div> */}
        </div>
      </div>
      <UpgradeSubscription PatSubcriptionModal={PatSubcriptionModal} patientSubscriptionData = {patientSubscriptionData} setPatSubcriptionModal={setPatSubcriptionModal} PatUpgradeSub={PatUpgradeSub} />
    </div>
  );
}

export default PatientWallet;
