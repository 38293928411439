import moment from "moment";
import React, { useState, useEffect } from "react";
import PaginationComponent from "react-reactstrap-pagination";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { doctor } from "../../../../redux/action";
import AddPrescription from "./add-prescription/AddPrescription";
import DeleteModal from "./DeleteModal";
import EditPrescription from "./edit-prescription/EditPerscription";
import ViewModal from "./ViewModal";

const Prescription = ({ Patid }) => {
  const dispatch = useDispatch();
  console.log("PatId", Patid);
  const [addPrescription, setAddPrescription] = useState(false);
  const [editPrescription, seteditPrescription] = useState(false);
  const [viewPrescription, setViewPrescription] = useState(false);
  const [deletePrescription, setDeletePrescription] = useState(false);
  const [selectPage, setselectPage] = useState(1);
  const [DataLimit, setDataLimit] = useState(8);
  const [presId, setPresId] = useState("");
  const [viewId, setViewId] = useState("");
  const handleAddPrescription = () => {
    setAddPrescription(true);
  };
  useEffect(() => {
    docPrescriptionList(Patid, selectPage, DataLimit);
  }, [DataLimit, selectPage]);

  const docPrescriptionList = async (Patid, selectPage, DataLimit) => dispatch(doctor.docPresciptionListAction(Patid, selectPage, DataLimit));
  const prescriptionListData = useSelector((state) => state?.doctor?.doctorPrescriptionData);

  console.log("PPPPPP", prescriptionListData);

  const hendleDelete = (id) => {
    setDeletePrescription(true);
    setPresId(id);
  };
  const handleView = (id) => {
    setViewId(id);
    setViewPrescription(true);
  };
  const handleSelected = (data) => {
    console.log("clicked page", data);
    setselectPage(data);
  };
  const handlePageLimit = (e) => {
    console.log("page data", e.target.value);
    setDataLimit(e.target.value);
  };
  const handleEdit = (id) => {
    seteditPrescription(!editPrescription);
    setPresId(id);
  };
  return (
    <div>
      {/*  Prescription Content  */}
      <div role="tabpanel" id="Prescription" className="tab-pane fade show active">
        <div className="row mb-1">
          <div className="col-md-12">
            <div className="text-right">
              <button className="add-new-btn" onClick={handleAddPrescription}>
                <i className="fas fa-plus-square"></i> Add Prescription
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-table mb-0">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-center mb-0">
                    <thead>
                      <tr>
                        <th className="text-left">Prescription Name</th>
                        <th>Issued Date</th>
                        <th>Date Time</th>
                        <th className="text-right">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {prescriptionListData && prescriptionListData.prescriptionData ? (
                        prescriptionListData.prescriptionData.map((item, i) => (
                          <tr>
                            <td className="text-left">{item.name}</td>
                            <td>{moment(item.issueDate).format("DD-MM-YYYY")} </td>
                            <td>{moment(item.dueDate).format("DD-MM-YYYY, h:mm a")}{}</td>
                            <td className="text-right">
                              <div className="table-action-btn">
                                <button className="btn btn-sm bg-info-light" title="View" onClick={() => handleView(item._id)}>
                                  <i className="far fa-eye"></i>
                                </button>
                                <button title="Edit" className="btn btn-sm bg-success-light" onClick={() => handleEdit(item._id)}>
                                  <i className="fas fa-pencil-alt"></i>
                                </button>
                                <button title="Delete" onClick={() => hendleDelete(item._id)} className="btn btn-sm bg-danger-light">
                                  <i className="fas fa-trash-alt"></i>
                                </button>
                                {/* <button title="Print" className="btn btn-sm bg-primary-light">
                                  <i className="fas fa-print"></i>
                                </button> */}
                              </div>
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td colSpan="4">Data not found!</td>
                        </tr>
                      )}

                      {/* <tr>
                                                <td className="text-left">
                                                    Paracetamol
                                                </td>
                                                <td>22-02-21
                                                </td>
                                                <td>22-02-21</td>
                                                <td className="text-right">
                                                    <a href=""
                                                        data-target="#viewprescription_Modal"
                                                        data-toggle="modal"
                                                        data-backdrop="static"
                                                        className="btn btn-sm bg-info-light">
                                                        <i className="far fa-eye"></i> View
                                                    </a>

                                                    <a href="javascript:void(0);"
                                                        className="btn btn-sm bg-primary-light">
                                                        <i className="fas fa-print"></i> Print
                                                    </a>
                                                </td>
                                            </tr> */}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="doc_card_list mt-3">
              <div class="row">
                <div className="col-sm-3 col-md-3">
                  <div className="dataTables_length" id="DataTables_Table_0_length">
                    <label>
                      Show{" "}
                      <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" onChange={(e) => handlePageLimit(e)}>
                        <option value="8">8</option>
                        <option value="25">25</option>
                        <option value="50">50</option>
                      </select>{" "}
                      entries
                    </label>
                  </div>
                </div>
                <div class="col-sm-5 col-md-5">
                  <div class="dataTables_info showing_pagination" id="DataTables_Table_0_info" role="status" aria-live="polite">
                    Showing {(selectPage - 1) * DataLimit + 1} to {(selectPage - 1) * DataLimit + (prescriptionListData && prescriptionListData.prescriptionData && prescriptionListData.prescriptionData.length)} of{" "}
                    {prescriptionListData && prescriptionListData.totalList} entries
                  </div>
                </div>
                <div class="col-sm-4 col-md-4">
                  <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                    <PaginationComponent
                      totalItems={prescriptionListData && prescriptionListData.totalList}
                      pageSize={DataLimit}
                      maxPaginationNumbers={Math.ceil(prescriptionListData && prescriptionListData.totalList / DataLimit)}
                      defaultActivePage={1}
                      onSelect={(e) => handleSelected(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <AddPrescription addPrescription={addPrescription} setAddPrescription={setAddPrescription} Patid={Patid} />
        <EditPrescription seteditPrescription={seteditPrescription} editPrescription={editPrescription} presId={presId} Patid={Patid} docPrescriptionList={() => docPrescriptionList()} />
        <DeleteModal deletePrescription={deletePrescription} setDeletePrescription={setDeletePrescription} presId={presId} Patid={Patid} />
        <ViewModal setViewPrescription={setViewPrescription} viewPrescription={viewPrescription} viewId={viewId} />
      </div>
    </div>
  );
};

export default Prescription;
