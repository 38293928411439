import { useEffect, useState } from "react";
import { Route, Switch, useHistory, useLocation } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../constants";
import appRoutes from "../../routes";
import authenticationService from "../../services/authenticationService/authenticationService";
import "react-datepicker/dist/react-datepicker.css";
import { Header, Footer, DrawerMenu, Topbar } from "../common";
import "../../assets/css/bootstrap.min.css";
import "../../assets/css/bootstrap-datetimepicker.min.css";
import "../../assets/css/morris.css";
import "../../assets/plugins/fontawesome/css/fontawesome.min.css";
import "../../assets/plugins/fontawesome/css/all.min.css";
// import './assets/css/font-awesome.min.css'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "../../assets/css/style.css";
import "react-modern-calendar-datepicker/lib/DatePicker.css";
import { getSessionStorageOrDefault } from "../../utils";
import getDeviceToken, { disconnectCallRequest, IsCallerDetails } from "../../getDeviceToken";
import { useDispatch } from "react-redux";
import { commonAction } from "../../redux/action";

function App() {
  const location = useLocation();
  const history = useHistory();
  const dispatch = useDispatch();
  const { pathname } = location;
  const disConnectCall = async (payload) => dispatch(commonAction.disconnectCall(payload));

  const [showDrawer, setShowDrawer] = useState(false);
  const [showTopbar, setshowTopbar] = useState(false);
  const [StaticPage, setStaticPage] = useState(true)

  useEffect(async () => {
    getDeviceToken(history);
    await checkNotificationPermission();
    checkLocationPermission();
  }, []);

  const checkNotificationPermission = async ()=>{
    return navigator.permissions
            .query({name:'notifications'})
            .then(permissionQuery)
            .catch((error)=>{
              console.log("Error info", error);
            });
  }
  function permissionQuery(result) {
    console.debug( 'permissions query===>>', {result});
    var newPrompt;

    if (result.state == 'granted') {
        // notifications allowed, go wild

    } else if (result.state == 'prompt') {
        // we can ask the user

        newPrompt = Notification.requestPermission();

    } else if (result.state == 'denied') {
        // notifications were disabled;
        window.confirm("Please enable notfication to use all features of web")
    }

    result.onchange = () => console.debug({updatedPermission: result});

    return newPrompt || result;
}

 // check geolocation permission 
 const checkLocationPermission = ()=>{
    navigator.permissions && navigator.permissions.query({name: 'geolocation'})
      .then(function(PermissionStatus) {
          console.log(PermissionStatus);
          if (PermissionStatus.state == 'granted') {
                //allowed
          } else if (PermissionStatus.state == 'prompt') {
                // prompt - not yet grated or denied
          } else {
              //denied
              window.confirm("Please allow location to use all features of web");
          }
      })
 }

  const renderRoutes = () => {
    return appRoutes.map((data) => {
      return <Route {...data} />;
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("pathname=>", pathname);
    const DocId = pathname.split("/")[2];
    const token = getSessionStorageOrDefault("authToken");
    if (
      pathname === ROUTES_CONSTANTS.HOME ||
      pathname === ROUTES_CONSTANTS.INBOX ||
      pathname === ROUTES_CONSTANTS.DOC_PERSONAL_SIGNUP ||
      pathname === ROUTES_CONSTANTS.DOC_PROFESSIONAL_SIGNUP ||
      pathname === ROUTES_CONSTANTS.PAT_PERSONAL_SIGNUP ||
      pathname === ROUTES_CONSTANTS.PAT_MEDICAL_SIGNUP ||
      pathname === ROUTES_CONSTANTS.PATIENT_MESSAGES ||
      pathname === ROUTES_CONSTANTS.HOW_IT_WORKS ||
      pathname === ROUTES_CONSTANTS.CONTACT_US ||
      pathname === ROUTES_CONSTANTS.OUR_MISSION ||
      pathname === ROUTES_CONSTANTS.OUR_TEAM ||
      pathname === ROUTES_CONSTANTS.METABOLIC_CENTERS ||
      pathname === ROUTES_CONSTANTS.WHAT_WE_TREAT ||
      pathname === ROUTES_CONSTANTS.BECOME_PARTICIPATING_CENTERS ||
      pathname === ROUTES_CONSTANTS.PARTICIPATING_CENTERS ||
      pathname === ROUTES_CONSTANTS.REFER_CENTER ||
      pathname === ROUTES_CONSTANTS.TESTOMONIALS ||
      pathname === ROUTES_CONSTANTS.ONLINE_HEALTHBLOG ||
      pathname === ROUTES_CONSTANTS.PRIVACY_POLICY ||
      (sessionStorage.getItem("termCondi") && pathname === ROUTES_CONSTANTS.TERMS_AND_CONDITIONS) ||
      pathname?.split("/")?.[1] === ROUTES_CONSTANTS.VIDEO_CALL?.split("/")?.[1] ||
      pathname?.split("/")?.[1] === ROUTES_CONSTANTS.VOICE_CALL?.split("/")?.[1] ||
      pathname === ROUTES_CONSTANTS.HOME_SEARCH ||
      pathname === `${ROUTES_CONSTANTS.DOCTOR_PROFILE}/${DocId}`
    ) {
      setShowDrawer(false);
      setshowTopbar(false);
    } else {
      if (!token) {
        if (
          appRoutes.filter((route) => route.path == location.pathname).length > 0 &&
          appRoutes.filter((route) => route.path == location.pathname)?.[0].authorized
        )
          history.push("/");
      } else {
        if (appRoutes.filter((route) => route.path?.split("/")?.[1] == location.pathname?.split("/")?.[1]).length > 0) {
          setShowDrawer(true);
          setshowTopbar(true);
        }
      }
    }
  }, [location]);

  useEffect(async () => {
    const token = getSessionStorageOrDefault("authToken");
    if (appRoutes.filter((route) => route.path == pathname)?.[0]?.authorized && !token) {
      history.push("/");
    }
    // const splitPathName = pathname.split("/")[1];
    // console.log("split path name", splitPathName);

    // if (
    //   getSessionStorageOrDefault("userType") === "1" &&
    //   splitPathName !== "video-call" &&
    //   (localStorage.getItem("incomingcall") == "true" || localStorage.getItem("outgoingcall") == "true")
    // ) {
    //   let payload = {};
    //   disconnectCallRequest.subscribe(async (data) => {
    //     if (data) {
    //       payload = {
    //         receiverId: data?.receiverId,
    //         pushType: data?.pushType,
    //       };
    //       console.log("disconnect call request", payload);
    //     }
    //   });
    //   if (payload.receiverId){
    //   const response = await disConnectCall(payload);
    //   if (response?.data?.responseCode == 200) {
    //     IsCallerDetails.next(false);
    //     sessionStorage.removeItem("callerDetails");
    //     localStorage.removeItem("incomingcall");
    //     localStorage.removeItem("outgoingcall");
    //     window.close();
    //     disconnectCallRequest.next({});
    //     //  data.returnToLobby();
    //     history.push(ROUTES_CONSTANTS.APPOINTMENTS);
    //     sessionStorage.removeItem("callerDetails");
    //     IsCallerDetails.next(false);
    //     window.location.reload();
    //   }
    // }
    // } else {
    //   if (
    //     splitPathName !== "video-call" &&
    //     (localStorage.getItem("incomingcall") == "true" || localStorage.getItem("outgoingcall") == "true")
    //   ) {
    //     let payload = {};
    //     disconnectCallRequest.subscribe(async (data) => {
    //       if (data) {
    //         payload = {
    //           receiverId: data?.receiverId,
    //           pushType: data?.pushType,
    //         };
    //         console.log("disconnect call request", payload);
    //         if (payload.receiverId){
    //         const response = await disConnectCall(payload);
    //         if (response?.data?.responseCode == 200) {
    //           sessionStorage.removeItem("callerDetails");
    //           IsCallerDetails.next(false);
    //           localStorage.removeItem("incomingcall");
    //           localStorage.removeItem("outgoingcall");
    //           window.close();
    //           disconnectCallRequest.next({});

    //           // data.returnToLobby();

    //           history.push(ROUTES_CONSTANTS.PATIENT_APPOINTMENT);
    //           sessionStorage.removeItem("callerDetails");
    //           IsCallerDetails.next(false);
    //           window.location.reload();
    //         }
    //        }
    //       }
    //     });
    //   }
    // }
  }, [pathname]);
  useEffect(() => {
    if((!sessionStorage.getItem("isLoggedIn") && pathname === ROUTES_CONSTANTS.PRIVACY_POLICY) ||
    (!sessionStorage.getItem("isLoggedIn") && pathname === ROUTES_CONSTANTS.TERMS_AND_CONDITIONS) ||
    (!sessionStorage.getItem("isLoggedIn") && pathname === ROUTES_CONSTANTS.ABOUT_US)){
      setStaticPage(false)
    }else{
      setStaticPage(true)
    }

  }, [pathname])
  

  return (
    <div>
    {StaticPage?<Header />:''}
      {showTopbar && <Topbar />}
      <div className={showDrawer ? "content" : ""}>
        <div className={showDrawer ? "container-fluid" : ""}>
          <div className="row">
            {showDrawer && <DrawerMenu />}
            <Switch>{renderRoutes()}</Switch>
          </div>
        </div>
      </div>
      {StaticPage? <Footer />:''}
    </div>
  );
}

export default App;
