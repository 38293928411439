import { Link, useHistory, useLocation } from "react-router-dom";
import { ROUTES_CONSTANTS_NAME } from "../../../constants/Routes";
function Topbar() {
  const location = useLocation();
  const history = useHistory();

  console.log("@@@@@@@@@@@@@@@", location, history);

  const menuToggleOpen = () => {
    document.body.classList.add("menu-opened2");
  };
  return (
    <div>
      <div class="breadcrumb-bar">
        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-12 col-md-6 col-2 pl-0 pr-0">
              <div>
                <a id="mobile_btn2" onClick={menuToggleOpen}>
                  <span class="bar-icon2">
                    <span></span>
                    <span></span>
                    <span></span>
                  </span>
                </a>
              </div>
            </div>
            <div class="col-lg-12 col-md-6 col-10 ">
              <nav aria-label="breadcrumb" class="page-breadcrumb ">
                <ol class="breadcrumb">
                  <li> </li>
                  <li class="breadcrumb-item">
                    <Link to="/">Home</Link>
                  </li>
                  {ROUTES_CONSTANTS_NAME[`/${location.pathname.split("/")[1]}`]?.map((data, i) => {
                    return (
                      <li class="breadcrumb-item active" style={{ cursor: "pointer" }} aria-current="page" onClick={() => data.path && history.push(`/${data.path}`)}>
                        {i == 0 ? `${data.name} ` : ` ${data.name}`}
                      </li>
                    );
                  })}
                </ol>
              </nav>
              <h2 class="breadcrumb-title">
                {ROUTES_CONSTANTS_NAME[`/${location.pathname.split("/")[1]}`]?.map((data, i) => {
                  return i == ROUTES_CONSTANTS_NAME[`/${location.pathname.split("/")[1]}`].length - 1 && ` ${data.name}`;
                })}
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Topbar;
