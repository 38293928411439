import React, { useState, useEffect } from "react";
import { isElementOfType } from "react-dom/test-utils";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Payment from "../paymentModal/Payment";
import { useSelector } from "react-redux";
import Bill from "../billModal/Bill";
import { useHistory } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../../constants";

function PatientSubcription({ PatSubcriptionModal, setPatSubcriptionModal, Image }) {
  let history = useHistory();
  const [SubscriptionId, setSubscriptionId] = useState("");
  const [SubscriptionPrice, setSubscriptionPrice] = useState("");
  const [PaymentModal, setPaymentModal] = useState(false);
  const [isSubcriptionToggle, setisSubcriptionToggle] = useState(false);
  const [SubscriptionType, setSubscriptionType] = useState(false);
  const [SubscriptionErr, setSubscriptionErr] = useState("");
  const [billModal, setbillModal] = useState(false);
  const [SubPrice, setSubPrice] = useState("");
  const [FreeSubToggle, setFreeSubToggle] = useState(false);

  const SubscriptionList = useSelector((state) => state?.authentication?.patientSubscriptionList);

  console.log("SubscriptionList", SubscriptionList);

  const handleClose = () => {
    setPatSubcriptionModal(false);
  };

  console.log("SubscriptionType", SubscriptionType);
  const handlePay = () => {
    if (FreeSubToggle) {
      history.push(ROUTES_CONSTANTS.PAT_MEDICAL_SIGNUP, Image);
    } else {
      if (SubscriptionType !== true) {
        setSubscriptionErr("Please choose at least one");
      } else {
        setSubscriptionErr("");
        setPaymentModal(!PaymentModal);
        setPatSubcriptionModal(!PatSubcriptionModal);
      }
    }
  };
  useEffect(() => {
    if (SubscriptionType === true) {
      setSubscriptionErr("");
    }
    if (SubPrice === 0) {
      setFreeSubToggle(true);
    } else {
      setFreeSubToggle(false);
    }
  }, [SubscriptionType, SubPrice]);

  const handleSubscription = (e, id, item) => {
    // let name= e.target.name
    let value = e.target.checked;
    console.log("iddddddddddddddddd", id, item.price);
    // console.log("SubscriptionPrice");
    // if(item.price === "0"){
    setSubPrice(item.price);
    // }
    if (value) {
      setSubscriptionType(value);
    }
    if (id) {
      setSubscriptionId(id);
    }
    if (item && item.price) {
      setSubscriptionPrice(item.price);
    }
    setbillModal(!billModal);
  };

  console.log("SubscriptionPrice", SubscriptionPrice);
  return (
    //     <div className="modal fade subscription-modal-p" id="subscription_Modal" tabindex="-1" role="dialog">
    //     <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
    <>
      <Modal isOpen={PatSubcriptionModal} className="modal-dialog-centered modal-dialog-scrollable subscription upgrade-subsrcription">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="staticBackdropLabel">
              Select Subscription
            </h4>
            <button type="button" className="close " aria-label="Close" data-dismiss="modal" onClick={handleClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {SubscriptionErr ? <div className="validation_error">{SubscriptionErr}</div> : null}
          <div class="modal-body pt-0">
            {SubscriptionList &&
              SubscriptionList.length &&
              SubscriptionList.map((item, index) => (
                <div class="account-content">
                  <div class="row">
                    <div class="col-md-12">
                      <div class="card subscription-packages">
                        <div class="card-body">
                          <div class="d-flex justify-content-between mb-1">
                            <div class="form-check">
                              <input type="radio" class="form-check-input" id={`Subscription${index}`} name="Subscription" checked={SubscriptionId && SubscriptionId === item._id ? true : false} onClick={(e) => handleSubscription(e, item._id, item)} />
                              <label class="form-check-label" for="Subscription1">
                                {item && item.name}
                              </label>
                            </div>
                            <div>
                              <span class="color-black">
                                <b>${item.price}/month</b>
                              </span>
                            </div>
                          </div>
                          <div class="color-black">Description</div>
                          <div class="card mb-0 p-2 mt-1 package-description">
                            <div>
                              <label class="color-grey">Number of Sessions:{item.session_numbers}</label>
                            </div>
                            <div>
                              <label class="color-grey">Number of Messages:{item.numberOfMessages}</label>
                            </div>
                            <div>
                              <label class="color-grey">Subscription Validity(days):{item.days_duration}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>
          <div className="modal-footer">
            {FreeSubToggle ? (
              <button className="btn btn-primary btn-block " type="submit" onClick={handlePay}>
                Confirm
              </button>
            ) : (
              <button className="btn btn-primary btn-block " type="submit" onClick={handlePay}>
                Pay
              </button>
            )}
          </div>
        </div>
        {/* <Bill 
            billModal={billModal} 
            setbillModal={setbillModal}
            SubscriptionId={SubscriptionId} 
            SubscriptionPrice={SubscriptionPrice}
            /> */}  
      </Modal>
      <Payment
        PaymentModal={PaymentModal}
        Image={Image}
        setPaymentModal={setPaymentModal}
        // isOpenDocSide={isOpenDocSide}
        SubscriptionId={SubscriptionId}
        SubscriptionPrice={SubscriptionPrice}
      />
    </>
  );
}

export default PatientSubcription;
