import moment from "moment";
import { useEffect, useState } from "react";
import PaginationComponent from "react-reactstrap-pagination";
import { useSelector, useDispatch } from "react-redux";
import { doctor } from "../../redux/action";
import ViewBill from "../appointments/viewAppointment/billing/ViewBill";
import "./Styles.css";

function Billings() {
  let dispatch = useDispatch();
  const [selectedPage, setselectedPage] = useState(1);
  const [DataLimit, setDataLimit] = useState(4);
  const [ViewModal, setViewModal] = useState(false);
  const [Invoiceid, setInvoiceid] = useState("");
  const doctorinvoicelistFull = async (DataLimit, selectedPage) => dispatch(doctor.doctorinvoicelistFullAction(DataLimit, selectedPage));

  useEffect(() => {
    window.scrollTo(0, 0);
    if (DataLimit) {
      doctorinvoicelistFull(DataLimit, selectedPage);
    }
  }, [DataLimit, selectedPage]);
  const doctorinvoicelist = useSelector((state) => state?.doctor?.invoiceListBill);
  console.log("doctorinvoicelist", doctorinvoicelist);
  const handleView = (InvoiceId) => {
    setViewModal(!ViewModal);
    setInvoiceid(InvoiceId);
  };

  const handleSelected = (data) => {
    console.log("clicked page", data);
    setselectedPage(data);
  };

  const handlePageLimit = (e) => {
    console.log("page data", e.target.value);
    setDataLimit(e.target.value);
  };
  return (
    <>
      <div className=" col-lg-8 col-xl-9 content_ipad_p">
        <div className="card">
          <div className="card-header">
            <h4>Billings</h4>
          </div>
          <div className="card-body">
            <div className="row">
              {doctorinvoicelist?.invoiceData?.length ? (
                doctorinvoicelist?.invoiceData.map((item) => (
                  <div className="col-lg-6 col-md-6 col-sm-6">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex justify-content-between">
                          <div>
                            <div>
                              <span>
                                <b>Invoice Number :</b>
                                {item?.invoiceNumber}
                              </span>
                            </div>
                            <div>
                              <span className="color-grey">
                                <b> Name :</b> {item?.invoiceType === "SUBSCRIPTION" ? item?.subscriptionId?.name : item?.invoiceType === "APPOINTMENT" ? `${item?.patientId?.firstName} ${item?.patientId?.lastName}` : ""}
                              </span>
                            </div>
                            <div>
                              <span className="color-grey">
                                <b>Amount Received :</b>
                                  {"$ "}{item?.amount}
                              </span>
                            </div>
                            <div>
                              <span className="color-grey">Paid On  : {moment(item?.createdAt).format("DD/MM/YY")}</span>
                            </div>
                          </div>
                          <div>
                            <span className="c-pointer" onClick={() => handleView(item._id)}>
                              <i class="fa fa-eye" aria-hidden="true"></i>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              ) : (
                <div className="col-md-12">
                  <p className="text-center">No billings available.</p>
                </div>
              )}
            </div>
          </div>
          {doctorinvoicelist && doctorinvoicelist?.totalList && doctorinvoicelist?.totalList > DataLimit ? (
            <div className="card-body pt-0">
             <div class="row">
              <div className="col-sm-3 col-md-3">
                <div className="dataTables_length billing-pag" id="DataTables_Table_0_length">
                  <label>
                    Show{" "}
                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" onChange={(e) => handlePageLimit(e)}>
                      <option value="4">4</option>
                      <option value="8">8</option>
                      <option value="10">10</option>
                    </select>{" "}
                    entries
                  </label>
                </div>
              </div>
              <div class="col-sm-5 col-md-5">
                <div class="dataTables_info showing_pagination" id="DataTables_Table_0_info" role="status" aria-live="polite">
                  Showing {(selectedPage - 1) * DataLimit + 1} to {(selectedPage - 1) * DataLimit + (doctorinvoicelist && doctorinvoicelist.invoiceData && doctorinvoicelist.invoiceData.length)} of {doctorinvoicelist && doctorinvoicelist.totalList}{" "}
                  entries
                </div>
              </div>
              <div class="col-sm-4 col-md-4">
                <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                  <PaginationComponent totalItems={doctorinvoicelist && doctorinvoicelist.totalList} pageSize={DataLimit} maxPaginationNumbers={3} defaultActivePage={1} onSelect={(e) => handleSelected(e)} />
                </div>
              </div>
            </div>
            </div>
          ) : (
            ""
          )}
        </div>
        <ViewBill ViewModal={ViewModal} setViewModal={setViewModal} Invoiceid={Invoiceid} />
      </div>
    </>
  );
}

export default Billings;
