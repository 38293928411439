import { patientAction } from "../../action";
import initialState from "../initialState";

export default function authenticationReducer(state = initialState.common, action) {
  switch (action.type) {
    case patientAction.SERVICE_PROVIDER_TYPE_IN_PATIENT:
      return {
        ...state,
        serviceProviderListInPatient: action.serviceProviderListInPatient,
      };

    case patientAction.PATIENT_PROFILE_DATA:
      return {
        ...state,
        patientProdileInfo: action.patientProdileInfo,
      };
    case patientAction.FIND_NEAR_BY_PHYSICIANS:
      return {
        ...state,
        nearByPhysicianList: action.nearByPhysicianList,
      };
    case patientAction.PATIENT_FAV_LIST:
      return {
        ...state,
        patientFavList: action.patientFavList,
      };
    case patientAction.FIND_DOC_FOR_HOME_DATA_IN_PATIENT:
      return {
        ...state,
        homeDataOfPatient: action.homeDataOfPatient,
      };
    case patientAction.HOME_DATA_OF_LABORATORY_PATIENT:
      return {
        ...state,
        homeDataOfLaboratoryPatient: action.homeDataOfLaboratoryPatient,
      };
    case patientAction.PATIENT_UPCOMING_APPOINTMENT:
      return {
        ...state,
        patientUpcomingAppointmentList: action.patientUpcomingAppointmentList,
      };
    case patientAction.PATIENT_PREVIOUS_APPOINTMENT:
      return {
        ...state,
        patientPreviousAppointmentList: action.patientPreviousAppointmentList,
      };
    case patientAction.PATIENT_VIEW_APPOINTMENT:
      return {
        ...state,
        patientViewAppointmentData: action.patientViewAppointmentData,
      };
    case patientAction.PATIENT_CARD_LIST_SETTINGS:
      return {
        ...state,
        patientCardList: action.patientCardList,
      };

    case patientAction.PATIENT_CARD_LIST:
      return {
        ...state,
        patientCardListinPayment: action.patientCardList,
      };
    case patientAction.PATIENT_PRIVACY_POLICY:
      return {
        ...state,
        patientSidePrivacyPolicy: action.patientSidePrivacyPolicy,
      };
    case patientAction.PATIENT_TERMS_AND_CONDITIONS:
      return {
        ...state,
        patientSideTermsAndCondition: action.patientSideTermsAndCondition,
      };
    case patientAction.PATIENT_SUBSCRIPTION_INFO_IN_WEB:
      return {
        ...state,
        patientSubscriptionData: action.patientSubscriptionData,
      };
    case patientAction.PATIENT_WALLET_INFO:
      return {
        ...state,
        patientWalletData: action.patientWalletData,
      };
    case patientAction.PATIENT_RESCHEDULED_APPOINTMENT:
      return {
        ...state,
        patientRescheduledAppointment: action.patientRescheduledAppointment,
      };
    case patientAction.PATIENT_SECONDARY_SPECIALITY_LIST:
      return {
        ...state,
        patientSecondarySpecialityList: action.patientSecondarySpecialityList,
      };
    case patientAction.PATIENT_MEDICAL_RECORDS_LIST:
      return {
        ...state,
        patientMedicalRecordsList: action.patientMedicalRecordsList,
      };
    case patientAction.PATIENT_PRESCRIPTION_LIST:
      return {
        ...state,
        patientPrescriptionList: action.patientPrescriptionList,
      };
    case patientAction.PATIENT_PRESCRIPTION_VIEW:
      return {
        ...state,
        patientPrescriptionView: action.patientPrescriptionView,
      };
    case patientAction.PATIENT_LABORATORY_DETAIL:
      return {
        ...state,
        patientLaboratoryDetail: action.patientLaboratoryDetail,
      };
    case patientAction.PATIENT_UPCOMING_LABORATORY_APPOINTMENT:
      return {
        ...state,
        patientLabUpcomingList: action.patientLabUpcomingList,
      };
    case patientAction.PATIENT_PREVIOUS_LABORATORY_APPOINTMENT:
      return {
        ...state,
        patientLabPreviousList: action.patientLabPreviousList,
      };
    case patientAction.PATIENT_MEDICALRECORD_LIST:
      return {
        ...state,
        patientMedicalRecordData: action.patientMedicalRecordData,
      };
    case patientAction.INVOICE_LIST:
      return {
        ...state,
        invoiceListInfo: action.invoiceListInfo,
      };
    case patientAction.INVOICE_VIEW:
      return {
        ...state,
        invoiceViewInfo: action.invoiceViewInfo,
      };
    case patientAction.INVOICE_LIST_BILL:
      return {
        ...state,
        invoiceListBill: action.invoiceListBill,
      };
    case patientAction.HELP_LIST:
      return {
        ...state,
        helplistInfo: action.helplistInfo,
      };
    case patientAction.VIEW_HELP:
      return {
        ...state,
        viewhelpInfo: action.viewhelpInfo,
      };
    case patientAction.REPLY_ON_REQUEST:
      return {
        ...state,
        replyonrequest: action.replyonrequest,
      };
    case patientAction.PATIENT_REPORT_LIST:
      return {
        ...state,
        reportListdata: action.reportListdata,
      };
    default:
      return state;
  }
}
