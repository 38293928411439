import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactStars from "react-stars";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../../constants";
import { patient } from "../../../../redux/action";

function PatientFevouriteSpecialist() {
  const history = useHistory();
  const [pageNumber, setPageNumber] = useState(1);
  const [limit, setLimit] = useState(8);
  const [favData, setFavData] = useState([]);
  const dispatch = useDispatch();
  const getList = async (payload) => dispatch(patient.favListAction(payload));

  const favListData = useSelector((state) => state?.patient?.patientFavList);
  console.log("favListData", favListData);

  useEffect(() => {
    getList({ number: pageNumber, limit: limit });
  }, []);

  useEffect(() => {
    setFavData(favListData?.userList);
  }, [favListData]);

  useEffect(() => {
    getList({ number: pageNumber, limit: limit });
  }, [pageNumber]);

  const renderPagination = () => {
    const pagination = [];
    for (let i = 0; i < Math.ceil(favListData?.totalList / limit); i++) {
      pagination.push(
        <li onClick={() => setPageNumber(i + 1)} className={`paginate_button page-item ${pageNumber === i + 1 && "active"}`}>
          <div aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0" className="page-link">
            {i + 1}
          </div>
        </li>
      );
    }
    return pagination;
  };

  const handleSearch = (e) => {
    if (e.target.value === "") setFavData(favListData?.userList);
    else setFavData(favListData?.userList.filter((data) => data.fullName.toLowerCase().search(e.target.value.toLowerCase()) != -1));
  };

  const handleNavigation = (data) => {
    console.log(data);
    history.push(`${ROUTES_CONSTANTS.PATIENT_BOOK_APPOINTMENT}/${data._id}`);
  };

  return (
    <div class=" col-lg-8 col-xl-9">
      <div class="card">
        <div class="card-header">
          <h4>Favourite Specialist</h4>
        </div>
        <div class="card-body">
          <div class="input-social-media">
            <input type="text" class="form-control  mb-2" style={{ paddingLeft: "42px" }} onChange={handleSearch} />
            <span>
              <i class="fas fa-search appt-search-icon"></i>
            </span>
          </div>
          <div class="row">
            {favData?.map((data) => {
              return (
                <div class="col-lg-3 col-md-6 col-sm-6">
                  <div onClick={() => handleNavigation(data)}>
                    <div class="card widget-profile pat-widget-profile">
                      <div class="card-body li-listing-profile">
                        <div class="profile-info-widget">
                          <div class="booking-doc-img">
                            <img src={data.profilePic || IMAGE_CONSTANT.DOC_PROFILE} alt="" />
                          </div>
                        </div>
                        <div class="profile-det-info text-center">
                          <h3>{`Dr. ${data.fullName}`}</h3>
                        </div>
                        <div class="patient-info color-grey">
                          <div class="color-grey">
                            <b>{`$${data.serviceCharge} Consultation Fee`}</b>
                          </div>
                          <div class="color-gray">{`${data.experience_in_years} year and ${data.experience_in_months} months  Of Experience`}</div>

                          {/* <div class="color-gray">{`${data.fullName}`}</div> */}
                          <div class=" rating ">
                            <ReactStars count={5} value={data?.rating} size={16} color2={"#ffd700"} edit={false} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          {favListData && favListData.totalList > limit ? (
            <div className="col-sm-12 col-md-7 favourite-specialist">
              <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                <ul className="pagination">
                  <li
                    onClick={() => {
                      if (pageNumber !== 1) {
                        setPageNumber(pageNumber - 1);
                      }
                    }}
                    className="paginate_button page-item previous disabled"
                    id="DataTables_Table_0_previous"
                  >
                    <a href aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0" className="page-link">
                      Previous
                    </a>
                  </li>
                  {renderPagination()}
                  <li
                    onClick={() => {
                      if (pageNumber !== Math.ceil(favListData?.totalList / limit)) {
                        setPageNumber(pageNumber + 1);
                      }
                    }}
                    className="paginate_button page-item next disabled"
                    id="DataTables_Table_0_next"
                  >
                    <a href aria-controls="DataTables_Table_0" data-dt-idx="2" tabindex="0" className="page-link">
                      Next
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
}

export default PatientFevouriteSpecialist;
