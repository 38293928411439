import React from "react";
import { Modal, ModalBody } from "reactstrap";
import { useDispatch } from "react-redux";
import { patient } from "../../../../redux/action";
import ToasterFunc from "../../../common/toaster/Toaster";

function AcceptAndRejectRequest({ AcceptModal, setAcceptModal, ApptId, patientRescheduleRequestList, RejToggle, AccToggle }) {
  let dispatch = useDispatch();
  const patientAcceptRescheduleRequestAction = async (payload) => dispatch(patient.patientAcceptRescheduleRequestAction(payload));
  const patientRejectRescheduleRequestAction = async (payload) => dispatch(patient.patientRejectRescheduleRequestAction(payload));

  console.log("ApptId", ApptId);
  const handleConfirm = async () => {
    let payload = {
      appointmentId: ApptId,
    };
    if (AccToggle) {
      const res = await patientAcceptRescheduleRequestAction(payload);
      if (res && res.status === 200) {
        ToasterFunc("success", res.data.responseMessage);
        setAcceptModal(false);
        patientRescheduleRequestList();
      } else {
        ToasterFunc("error", res.data.responseMessage);
      }
    } else if (RejToggle) {
      const res = await patientRejectRescheduleRequestAction(payload);
      if (res && res.status === 200) {
        ToasterFunc("success", "Rejected");
        setAcceptModal(false);
        patientRescheduleRequestList();
      } else {
        ToasterFunc("error", res.data.responseMessage);
      }
    }
  };
  return (
    <Modal isOpen={AcceptModal} className="modal-dialog  modal-dialog-centered ">
      <div class="modal-content p-3">
        <div class="text-right">
          <button type="button" class="dr_Modalclose payment-method-modal" onClick={() => setAcceptModal(false)}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          {/* <div class="modal-body"> */}

          <div class="row">
            <div class="col-lg-12 ">
              <div class="card mb-0">
                <div class="card-body">
                  <div class="text-center">
                    <span>
                      <i class="fas fa-exclamation-triangle fa-2x mb-3"></i>
                    </span>
                    <h4>Are you sure?</h4>
                    <p class=" color-grey mt-2">{AccToggle ? "Your appointment will be rescheduled to the new date." : "You won't be able to revert this!"}</p>
                    {/* <a onClick={handleGoHome}>Go to Home</a> */}
                  </div>
                  <div className="d-flex justify-content-center mt-4">
                    <button className="btn btn-primary mr-1 modal-confirm-btn" onClick={handleConfirm}>
                      {AccToggle ? "Accept" : "Reject"}
                    </button>
                    <button className="btn btn-primary mr-1" data-dismiss="modal" onClick={() => setAcceptModal(false)}>
                      Cancel
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </ModalBody>
      </div>
    </Modal>
  );
}

export default AcceptAndRejectRequest;
