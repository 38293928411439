import React, { useState, useEffect } from "react";
import { IMAGE_CONSTANT } from "../../../constants";
import PatientSubcription from "./PatientSubcription";
import { useFormik } from "formik";
import * as Yup from "yup";
import AutoComplete from "./AutoComplete";
import { useDispatch } from "react-redux";
import { authentication, commonAction } from "../../../redux/action";
import ToasterFunc from "../../common/toaster/Toaster";
import Select from "react-dropdown-select";
import moment from "moment";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import DatePicker from "react-datepicker";

function PatientPersonal() {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const dispatch = useDispatch();
  const [BirthDateError, setBirthDateError] = useState("");
  const [BirthDate, setBirthDate] = useState("");
  const [nameValue, setnameValue] = useState("");
  const [ModifiedDate, setModifiedDate] = useState("");
  const [OptionValue, setOptionValue] = useState("");
  const [OptionValueErr, setOptionValueErr] = useState("");
  const [Address, setAddress] = useState("");
  const [AddressEr, setAddressEr] = useState("");
  const [LastName, setLastName] = useState("");
  const [Lat, setLat] = useState("");
  const [Lng, setLng] = useState("");
  const [PatSubcriptionModal, setPatSubcriptionModal] = useState(false);
  const [Image, setImage] = useState("");
  const [ImageErr, setImageErr] = useState("");
  const [genderError, setgenderError] = useState("");
  const [UpperlttrErr, setUpperlttrErr] = useState("");
  const [gender, setgender] = useState({
    male: true,
    female: false,
    notToSay: false,
  });
  const PersonalPatUser = async (payload) => dispatch(authentication.personalPatAction(payload));

  const CommonProfilePic = async (payload) => dispatch(commonAction.commonProfilePicAction(payload));

  const SubscriptionList = async () => dispatch(authentication.subscriptionListPatientAction());
  console.log(BirthDate);
  const formik = useFormik({
    initialValues: {
      firstName: "",
      // DOB: "",
      // maritalStatus: "",
      // Address: "",
      lifestyle: "",
    },
    validationSchema: Yup.object({
      firstName: Yup.string().required("*Required."),
      // DOB: Yup.string().required("*Required."),
      // maritalStatus: Yup.string().required("*Required"),
      // Address: Yup.string().required("*Required"),
      lifestyle: Yup.string().required("*Required"),
    }),
    onSubmit: (values) => {
      const payload = {
        firstName: values.firstName,
        lastName: "",
        profilePic: Image ? Image : null,
        gender: gender.male ? "Male" : gender.female ? "Female" : gender.notToSay ? "Other" : "",
        date_of_birth: values.DOB,
        marital_status: OptionValue[0]?.value,
        bio: values.lifestyle,
        address: values.Address,
      };
    },
  });

  useEffect(() => {
    const res = SubscriptionList();
  }, []);

  useEffect(() => {
    if (Image !== "") {
      setImageErr("");
    }
    if (gender.male === true || gender.female === true || gender.notToSay === true) {
      setgenderError("");
    }
    // if (Address !== "") {
    //   setAddressErr("");
    // }
    if (BirthDate !== "") {
      setBirthDateError("");
    }
    if (Address) {
      setAddressEr("");
    }

    if (formik.values.firstName.charAt(0) != formik.values.firstName.charAt(0).toUpperCase()) {
      setnameValue(formik.values.firstName.charAt(0).toUpperCase() + formik.values.firstName.slice(1));
    } else {
      setUpperlttrErr("");
    }
    if (OptionValue[0]?.value !== "") {
      setOptionValueErr("");
    }
  }, [Image, gender, Address, formik.values.firstName, OptionValue, BirthDate, Address]);

  useEffect(() => {
    formik.setFieldValue("firstName", nameValue);
  }, [nameValue]);

  const payloadInfo = {
    firstName: formik.values.firstName,
    lastName: LastName ? LastName : "",
    profilePic: Image ? Image : null,
    gender: gender.male ? "Male" : gender.female ? "Female" : gender.notToSay ? "Other" : "",
    date_of_birth: moment(BirthDate).format("MM/DD/YYYY"),
    marital_status: OptionValue[0]?.value,
    bio: formik.values.lifestyle,
    lat: Lat ? Lat : "",
    long: Lng ? Lng : "",
    address: Address ? Address : "",
  };

  const handleImage = (e) => {
    let result = e.target.value;

    const file = e.target.files[0];
    console.log(file);
    let formData = new FormData();
    console.log("file changed");
    if (file) {
      let fileSize = file.size / 1e6;
      console.log("fileSize", fileSize);
      if (fileSize > 1) {
        setImageErr("Please upload a file smaller than 1 MB");
      } else {
        formData.append("file", file);
        fileUpload(formData);
      }
    }
  };

  const fileUpload = async (image) => {
    const res = await CommonProfilePic(image);
    if (res && res.status == 200) {
      console.log("Profile pIccccccccc", res.data.result.url);
      let picdata = res && res.data && res.data.result.url;
      setImage(picdata);
    }
  };

  const handlegender = (e) => {
    let checked = e.target.checked;
    let value = e.target.value;
    if (value === "1") {
      setgender({
        male: checked,
      });
    } else if (value === "2") {
      setgender({
        female: checked,
      });
    } else if (value === "3") {
      setgender({
        notToSay: checked,
      });
    }
  };

  // const handlePlaceChanged = (place) => {
  //   let location = place?.geometry?.location;
  //   let address = place?.formatted_address;
  //   let lat = location?.lat();
  //   let lng = location?.lng();

  //   console.log("place", place);

  //   setAddress(address);
  //   setLat(lat);
  //   setLng(lng);

  //   if (place && place.length) {
  //     //   place.address_components.forEach((item, index) => {
  //     //     if (item.types[0] === "administrative_area_level_1") {
  //     //     }
  //     //     if (item.types[0] === "locality" || item.types[0] === "postal_town") {
  //     //       console.log("yes, it is coming ===> 92", item.long_name);
  //     //     }
  //     //     if (item.types[0] === "postal_code") {
  //     //       console.log("yes, it is coming ===> 92", item.long_name);
  //     //     }
  //     //   });
  //     let address = `${place && place.name && place.name}${place && place.formatted_address && place.formatted_address}`;
  //     setAddress(address);
  //   }
  // };

  console.log("Lat", Lat, Lng, Address);

  const handleNext = async (e) => {
    if (gender.male === false && gender.female === false) {
      setgenderError("*Please Choose any one");
    }
    // if (Address === "") {
    //   setAddressErr("*Required");
    // }
    if (BirthDate === "") {
      setBirthDateError("*Required");
    } else {
      setBirthDateError("");
    }
    if (gender.male === true || gender.female === true) {
      setgenderError("");
    }

    if (Address === "") {
      setAddressEr("*Required");
    }
    if (Image === "") {
      setImageErr("*Please choose image");
    }
    if (Image !== "") {
      setImageErr("");
    }
    if ((OptionValue && OptionValue[0]?.value === "") || OptionValue[0]?.value === undefined) {
      setOptionValueErr("*Required");
    } else {
      setOptionValueErr("");
    }
    // setPatSubcriptionModal(!PatSubcriptionModal)

    if (
      (gender.male === true || gender.female === true || gender.notToSay === true) &&
      !!Address &&
      formik.values.firstName != "" &&
      BirthDate != "" &&
      (OptionValue[0]?.value != "" || OptionValue[0]?.value != undefined) &&
      formik.values.lifestyle != "" &&
      Image !== ""
    ) {
      const res = await PersonalPatUser(payloadInfo);
      if (res && res.status == 200) {
        console.log("personalProfile", res);
        setPatSubcriptionModal(!PatSubcriptionModal);
      }
    }
  };
  console.log("OptionValue", OptionValue);

  // const disablePastDate = () => {
  //   const today = new Date();
  //   const dd = String(today.getDate() + 1).padStart(2, "0");
  //   const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
  //   const yyyy = today.getFullYear();
  //   return yyyy + "-" + mm + "-" + dd;
  // };

  const handleLastName = (e) => {
    setLastName(e.target.value);
  };

  // const minDateModifier = () => {
  //   var dtToday = new Date();

  //   var month = dtToday.getMonth() + 1;
  //   var day = dtToday.getDate();
  //   var year = dtToday.getFullYear();

  //   if (month < 10) month = "0" + month.toString();
  //   if (day < 10) day = "0" + day.toString();
  //   var maxDate = year + "-" + month + "-" + day;
  //   setModifiedDate(maxDate);
  // };

  const options = [
    { value: "Married", label: "Married" },
    { value: "Single", label: "Single" },
    { value: "Divorced", label: "Divorced" },
    { value: "Widowed", label: "Widowed" },
  ];

  console.log("OptionValue", OptionValue[0]?.value);

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
    setAddress(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();
    console.log("description", description);
    setAddress(description);

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log("📍 Coordinates: ", { lat, lng });
        setLat(lat);
        setLng(lng);
      })
      .catch((error) => {
        console.log("😱 Error: ", error);
      });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    // <div className="index-home-padding">
    <div className="container-fluid">
      <div className="row align-items-center justify-content-center">
        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 login-left">
          <img src={IMAGE_CONSTANT.LOGIN_BANNER} className="img-fluid" alt="Doccure Register" />
        </div>
        <div className="col-xl-6 col-lg-12 col-md-12 col-sm-12 login-right">
          <div className="container">
            <div className="row mb-5 margin-mobile">
              <div className="col-md-12 mb-5">
                <div className="stepPregresbar ">
                  <div className="round-stepCircle active">1</div>
                  <div className="round-stepCircle">2</div>
                </div>
                <div className="d-flex justify-content-around">
                  <label htmlFor="">Personal Detail</label>
                  <label htmlFor="">Medical Detail</label>
                </div>
              </div>
            </div>
          </div>
          <div className="row form-row mb-4">
            <div className="col-md-12">
              <div className="change-avatar justify-content-center">
                <div className="profile-round-img">
                  <figure className="mb-0">
                    <img src={Image ? Image : IMAGE_CONSTANT.USER_ICON} alt="" />
                  </figure>
                  <label for="camera">
                    <input type="file" id="camera" accept="image/*" hidden onChange={(e) => handleImage(e)} />
                    <i className="fas fa-camera icon-camera"></i>
                  </label>
                </div>
              </div>
              {!ImageErr ? <span className="suggestion">Only JPG,JPEG,PNG files with max size of 1MB.</span> : ""}

              {ImageErr ? <div className="validation_error Image">{ImageErr}</div> : null}
            </div>
          </div>
          {/* <!-- Register Form --> */}
          <form onSubmit={formik.handleSubmit}>
            <div className=" row form-group ">
              <div className="col-md-6">
                <label htmlFor="">First Name*</label>
                <input type="text" className="form-control" name="firstName" placeholder="Enter First Name" maxLength="16" {...formik.getFieldProps("firstName")} />
                {formik.touched.firstName && formik.errors.firstName ? <span className="validation_error">{formik.errors.firstName}</span> : formik.values.firstName && UpperlttrErr ? <span className="validation_error">{UpperlttrErr}</span> : null}
              </div>
              <div className="col-md-6">
                <label htmlFor="">Last Name*</label>
                <input type="text" className="form-control" name="lastName" maxLength="16" placeholder="Enter Last Name" onChange={(e) => handleLastName(e)} />
              </div>
            </div>
            <div className="row form-group">
              <div className="col-md-3 col-12">
                <div>
                  <label className="form-check-label" for="exampleCheck1">
                    Gender
                  </label>
                </div>
              </div>
              <div className="col-md-3 col-3">
                <div className="form-check">
                  <input type="radio" className="form-check-input" id="Male" name="gender" value="1" checked={gender && gender.male ? gender.male : false} onChange={(e) => handlegender(e)} />
                  <label className="form-check-label" for="Male">
                    Male
                  </label>
                </div>
              </div>
              <div className="col-md-3 col-4">
                <div className="form-check ">
                  <input type="radio" className="form-check-input" id="Female" name="gender" value="2" onChange={(e) => handlegender(e)} />
                  <label className="form-check-label" for="Female">
                    Female
                  </label>
                </div>
              </div>
              <div className="col-md-3 col-5">
                <div className="form-check ">
                  <input type="radio" className="form-check-input" id="not-to-say" name="gender" value="3" onChange={(e) => handlegender(e)} />
                  <label className="form-check-label" for="not-to-say">
                    I prefer not to Say
                  </label>
                </div>
              </div>
              {genderError ? <div className="validation_error gender">{genderError}</div> : null}
            </div>
            <div className="row form-group">
              <div className="col-md-6">
                <label htmlFor="">Date of Birth*</label>
                {/* <div className="Custom-datepicker-input">
                  <DatePickerCustom />
                </div> */}
                <DatePicker
                  className="form-control"
                  id="my_date_picker1"
                  autoComplete="off"
                  maxDate={moment().toDate()}
                  selected={BirthDate}
                  onChange={(date) => setBirthDate(date)}
                  placeholderText="MM/DD/YYYY"
                  dateFormat="MM/dd/yyyy"
                  showMonthDropdown
                  showYearDropdown
                  scrollableMonthYearDropdown
                  yearDropdownItemNumber={72}
                  scrollableYearDropdown={true}
                />
                {/* <input type="date" className="form-control " max={ModifiedDate} name="DOB" {...formik.getFieldProps("DOB")} onClick={minDateModifier} /> */}
                <label className="focus-label"></label>
                {BirthDateError ? <span className="validation_error">{BirthDateError}</span> : null}
              </div>
              <div className="col-md-6">
                <label htmlFor="">Marital Status</label>
                {/*custom select */}
                <div className="custm-select">
                  <Select options={options} onChange={(values) => setOptionValue(values)} className="form-control" />
                  {OptionValueErr ? <span className="validation_error">{OptionValueErr}</span> : null}
                </div>
                {/*custom  select */}

                {/* <select className="form-control" name="maritalStatus" {...formik.getFieldProps("maritalStatus")}>
                  <option hidden>Select</option>
                  <option value="Married">Married</option>
                  <option value="Single">Single</option>
                </select> */}
                {/* {formik.touched.maritalStatus && formik.errors.maritalStatus ? <span className="validation_error">{formik.errors.maritalStatus}</span> : null} */}
              </div>
            </div>
            <div className="form-group address-suggesstion">
              <label for="Address">Address*</label>
              <input class="form-control" autoComplete="off" name="address" value={value} maxLength="60" onChange={handleInput} disabled={!ready} placeholder="Address" />
              {status === "OK" && <ul>{renderSuggestions()}</ul>}
              {!Address ? <span className="validation_error">{AddressEr}</span> : null}
            </div>
            <div className="form-group">
              <label for="Lifestyle">Tell Us About Your Lifestyle*</label>
              <textarea className="form-control" id="Lifestyle" rows="3" name="lifestyle" maxLength="180" placeholder="Type here....." {...formik.getFieldProps("lifestyle")}></textarea>
              {formik.touched.lifestyle && formik.errors.lifestyle ? <span className="validation_error">{formik.errors.lifestyle}</span> : null}
            </div>

            <button type="submit" className="btn btn-primary btn-block btn-lg login-btn" onClick={(e) => handleNext(e)}>
              Submit
            </button>
          </form>
          {/* <!-- /Register Form --> */}
        </div>
      </div>

      {/* <!-- /Register Content --> */}

      <PatientSubcription Image={Image} PatSubcriptionModal={PatSubcriptionModal} setPatSubcriptionModal={setPatSubcriptionModal} />
    </div>

    // </div>
  );
}

export default PatientPersonal;
