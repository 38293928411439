import React, { useEffect, useState } from "react";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../../../constants";
import DatePicker, { Calendar, utils } from "react-modern-calendar-datepicker";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bookingAction } from "../../../../../redux/action/bookingAction";
import ReactStars from "react-stars";
import { checkTime, convertUTCToLocal, convertUTCToLocalForInput, getTime, getTime2 } from "../../../../authentication/sign-up-as-doctor/availability";
import ToasterFunc from "../../../../common/toaster/Toaster";
import { commonAction, doctor, patient } from "../../../../../redux/action";
import moment from "moment";
import ConfirmationModal from "../../../findDoctorsNearBy/ConfirmationModal";
import { useLocation } from "react-router-dom";

// import ConfirmationModal from "./ConfirmationModal";

function PatientRescheduleApp() {
  let location = useLocation();
  let history = useHistory();
  const [ConfirmModal, setConfirmModal] = useState(false);
  const [RescheduleToggle, setRescheduleToggle] = useState(false);
  const dispatch = useDispatch();

  const rescheduleAppointmentPatient = async (payload) => dispatch(bookingAction.rescheuduleAppointmentPatientAction(payload));

  const getSlotsForReschedulePatient = async (id, date) => dispatch(bookingAction.getSlots(id, date));

  const slotListForReschedule = useSelector((state) => state?.booking?.slotList);

  const setFav = async (payload) => dispatch(patient.setFavourite(payload));
  const [SlotObject, setSlotObject] = useState({});

  console.log("slotListForReschedule", slotListForReschedule);

  const { DocAppointmentId } = useParams();
  const [selectedDay, setSelectedDay] = useState(null);
  const [slotSlected, setSLotSelected] = useState(null);
  const [SlotVisible, setSlotVisible] = useState(false);

  console.log("location", location);

  useEffect(() => {
    console.log("@@@", DocAppointmentId);
    DoctorProfileInfo();
  }, []);
  useEffect(() => {
    if (slotListForReschedule && slotListForReschedule.slots) {
      const currentTime = convertTime12to24(new Date().toLocaleTimeString());
      slotListForReschedule.slots.length &&
        slotListForReschedule.slots.map((item) => {
          item["localSlotTime"] = convertUTCToLocalForInput(item.slotTime);
          if (selectedDay?.day === new Date().getDate() && currentTime > item.localSlotTime) {
            item["show"] = false;
          } else {
            item["show"] = true;
            if (!selectedDay?.day) {
              item["show"] = false;
            }
          }

          return item;
        });
      setSlotObject(slotListForReschedule);
    }
  }, [slotListForReschedule, selectedDay?.day]);

  //----------Format 12 to 24-----------------
  const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }
    console.log(`${hours}:${minutes}`);

    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    if (selectedDay) callSlots();
  }, [selectedDay]);
  //   console.log("slotSlected", slotSlected);
  console.log("selectedDay", selectedDay);

  const callSlots = () => {
    if (selectedDay) {
      getSlotsForReschedulePatient(`${location?.state}`, `${selectedDay.year}-${selectedDay.month}-${selectedDay.day}`);
    } else {
      getSlotsForReschedulePatient(`${location?.state}`, `${moment().format("YYYY-MM-DD")}`);
    }
  };
  console.log("AppointmentId", DocAppointmentId);

  //   console.log(selectedDay ? ` ${location?.state} , ${selectedDay.year}-${selectedDay.month}-${selectedDay.day}` : moment().format("YYYY-MM-DD"));

  const DoctorDataa = useSelector((state) => state?.doctor?.doctorProdileInfo);

  console.log("DoctorDataaa", DoctorDataa);

  const DoctorProfileInfo = async () => dispatch(doctor.doctorProfileDetailsAction());

  const handleSet = async () => {
    setRescheduleToggle(true);
    if (!slotSlected) {
      ToasterFunc("error", "Please select slots.");
    }
    if (!!slotSlected) {
      // let fullSelectedDate = `${selectedDay.year}-${selectedDay.month < 10 ? "0" + selectedDay.month : selectedDay.month}-${selectedDay.day < 10 ? "0" + selectedDay.day : selectedDay.day}`;
      let fullSelectedDate = `${selectedDay.year}/${selectedDay.month < 10 ? "0" + selectedDay.month : selectedDay.month}/${selectedDay.day < 10 ? "0" + selectedDay.day : selectedDay.day}`;

      console.log("fullSelectedDate", fullSelectedDate);
      let endTime = new moment.utc(slotSlected.slotTime, "HH:mm A").local();
      // endTime.add(30, "minutes");
      let localStarttime  = convertUTCToLocal(slotSlected.slotTime) ;
      let localEndttime  = convertUTCToLocalForInput(slotSlected.slotTime) ;
      let endMin  = Number(localEndttime.split(":")[1]) + slotListForReschedule?.interval ; 
      let endHour = localEndttime.split(":")[0];
      if (endMin === 60 ){
        endHour =  Number(endHour) + 1 ; 
        endMin = 0
      }else if (endMin > 60){
        endHour =  Number(endHour) + 1 ; 
        endMin = endMin % 60 ;
      }
      let finalEndTime  = `${endHour}:${String(endMin).length === 1 ? '00' : endMin}`
      let localFinalEndTime = getTime2(finalEndTime);

      const payload = {
        appointmentId: DocAppointmentId,
        slotTime: slotSlected.slotTime,
        dayId: slotListForReschedule._id,
        slotId: slotSlected._id,
        bookingDate: `${selectedDay.year}-${selectedDay.month < 10 ? "0" + selectedDay.month : selectedDay.month}-${selectedDay.day < 10 ? "0" + selectedDay.day : selectedDay.day}`,
        appointmentStartTime : new Date(`${fullSelectedDate} ${localStarttime}`).toISOString(),
        appointmentEndTime : new Date(`${fullSelectedDate} ${localFinalEndTime}`).toISOString()
        // appointmentStartTime: fullSelectedDate + "T" + getTime(slotSlected.slotTime) + ":00Z",
        // appointmentEndTime: fullSelectedDate + "T" + new moment(endTime, ["HH.mm"]).utc().format("HH:mm") + ":00Z",
      };

      console.log('payload',payload);

      const res = await rescheduleAppointmentPatient(payload);

      if (res && res.status == 200) {
        setConfirmModal(true);
      }
    }
  };

  const handleBookmarked = async () => {
    const payload = {
      userId: DoctorDataa?.result[0]?._id,
      favourite: !DoctorDataa?.result[0]?.isFavourite,
    };
    console.log(payload);
    const res = await setFav(payload);
    if (res.status === 200) DoctorProfileInfo();
  };

  return (
    <div className=" col-lg-8 col-xl-9 content_ipad_p ">
      {console.log("@@@@@@", selectedDay)}
      <div className="card">
        <div className="card-header">
          <h4>Reschedule Appointments</h4>
        </div>


        <div className="card-body appointments">
          {/* <div className="appointment-list"> */}
          {/* <div className="profile-info-widget">
              <a  className="booking-doc-img">
                <img src={DoctorDataa?.result[0]?.profilePic || IMAGE_CONSTANT.PLACEHOLDER} alt="" />
              </a>
              <div className="profile-det-info">
                <h3>
                  <a >{DoctorDataa?.result[0]?.fullName}</a>
                </h3>
                <div className="patient-details">
                  <h5>{`$${DoctorDataa?.result[0]?.serviceCharge} Consultation Fee`}</h5>
                  <h5>{`${DoctorDataa?.result[0]?.experience_in_years} Years ${DoctorDataa?.result[0]?.experience_in_months} months of Experience`}</h5>
                  <a href data-toggle="modal" data-target="#speciality-popup" data-dismiss="modal">
                    <h6>{DoctorDataa?.result[0]?.serviceProviderType[0]?.primary_specialty}</h6>
                  </a>
                  <div className=" rating ">
                    <ReactStars edit={false} count={5} value={DoctorDataa?.result[0]?.rating} size={16} color2={"#ffd700"} />
                  </div>
                </div>
              </div>
            </div> */}
          {/* <div onClick={handleBookmarked}>
              <i
                className="fa fa-bookmark"
                style={{
                  color: !DoctorDataa?.result[0]?.isFavourite ? "gray" : "red",
                }}
              ></i>
            </div> */}
          {/* </div> */}
          <div className="row">
            <div className="col-md-12 col-lg-8">
              <div className="card">
                <div className="card-body custom-calender">
                  
                  <Calendar value={selectedDay} onChange={setSelectedDay} shouldHighlightWeekends className="fc fc-unthemed fc-ltr" minimumDate={utils().getToday()} />
                </div>
              </div>
            </div>
          </div>
          <div className={SlotObject && SlotObject.slots ? "row" : "row hide_Slotlist"}>
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="time-slot">
                    <ul className="clearfix">
                      {console.log(SlotObject)}
                      {   console.log('moment time format',moment().format("DD/MM/YYYY"))}
                      {SlotObject && SlotObject.slots
                        ? SlotObject.slots.map((data) => {
                          return moment().format("DD/MM/YYYY") == `${selectedDay?.day < 10 ? "0" + selectedDay?.day : selectedDay?.day}/${selectedDay?.month < 10 ? "0" + selectedDay?.month : selectedDay?.month}/${selectedDay?.year}` ? (
                            <>
                              {!checkTime(convertUTCToLocal(data.slotTime)) && (
                                <li onClick={() => setSLotSelected(data)}>
                                  <a
                                    className="timing"
                                    href
                                    style={{
                                      backgroundColor: slotSlected?.slotTime === data.slotTime ? " #be141b" : " #eeeeee",
                                      color: slotSlected?.slotTime === data.slotTime ? "white" : " #be141b",
                                    }}
                                  >
                                    <span>{convertUTCToLocal(data.slotTime)}</span>
                                  </a>
                                </li>
                              )}
                            </>
                          ) : (
                            <li onClick={() => setSLotSelected(data)}>
                              <a
                                className="timing"
                                href
                                style={{
                                  backgroundColor: slotSlected?.slotTime === data.slotTime ? " #be141b" : " #eeeeee",
                                  color: slotSlected?.slotTime === data.slotTime ? "white" : " #be141b",
                                }}
                              >
                                <span>{convertUTCToLocal(data.slotTime)}</span>
                              </a>
                            </li>
                          );
                          })
                        : ""}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="submit-section proceed-btn text-center">
          <button className="btn btn-primary  w-25" onClick={handleSet}>
            Next
          </button>
        </div>
      </div>
      <ConfirmationModal RescheduleToggle={RescheduleToggle} ConfirmModal={ConfirmModal} setConfirmModal={setConfirmModal} />
    </div>
  );
}

export default PatientRescheduleApp;
