import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Button } from "reactstrap";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../constants";
import { saveToSessionStorage } from "../../../utils";
import { ForgotPassword, Login, LoginWithNumber } from "../../authentication";
import { SignUp } from "../../authentication/signup";
import SignUpNewsletterModal from "../../cms/news/SignUpNewsletterModal";
import "./style.css";

function Footer() {
  let history = useHistory();
  let location = useLocation();
  const [SignUpModal, setSignUpModal] = useState(false);
  const [signInModal, setSignInModal] = useState(false);

  const [LoginWithNumModal, setLoginWithNumModal] = useState(false);
  const [ForgetPwdModal, setForgetPwdModal] = useState(false);
  const [SignInButton, setSignInButton] = useState(false);
  const handlePatientLogin = () => {
    setSignInModal(!signInModal);
    saveToSessionStorage("userType", "2");
  };
  const handleDoctorLogin = () => {
    setSignInModal(!signInModal);
    saveToSessionStorage("userType", "1");
  };
  const handleDoctorRegister = () => {
    setSignUpModal(!SignUpModal);
    saveToSessionStorage("userType", "1");
  };
  const handlePatientRegister = () => {
    setSignUpModal(!SignUpModal);
    saveToSessionStorage("userType", "2");
  };
  const handleSearchForDoc = () => {
    setSignUpModal(!SignUpModal);
  };

  const handleForgetPasswordToggle = () => {
    setForgetPwdModal(!ForgetPwdModal);
    setSignInModal(false);
    setLoginWithNumModal(false);
  };
  const handleSignUpToggle = () => {
    setSignUpModal(!SignUpModal);
    setSignInModal(false);
  };

  const handleLoginWithMobiToggle = () => {
    setLoginWithNumModal(!LoginWithNumModal);
    setSignInModal(false);
  };

  const handleSignInToggle = () => {
    setSignInModal(!signInModal);
    setSignUpModal(false);
  };

  console.log("location", location);

  const handleTermAndCondi = () => {
    sessionStorage.setItem("termCondi", true);
    history.push(ROUTES_CONSTANTS.TERMS_AND_CONDITIONS);
  };

  const handleMail = () => {
    window.open("mailto:info@gymdoctornow.com");
  };

  const handlePolicy = () => {
    history.push(ROUTES_CONSTANTS.PRIVACY_POLICY);
  };

  const [show, setShow] = useState(true);
  useEffect(() => {
    if (window.location.pathname === "/patient-messages" || window.location.pathname === "/inbox") {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [window.location.pathname]);

  return (
    <>
      {show && (
        <footer class="footer">
          <div class="footer-top">
            <div class="container-fluid">
              <div class="row">
                <div class="col-lg-3 col-md-6">
                  ``
                  <div class="footer-widget footer-about">
                    <div class="footer-logo">
                      <img src={IMAGE_CONSTANT.FOOTER_LOGON} alt="logo" />
                    </div>
                    <div class="footer-about-content">
                    <p>
                      {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua.{" "} */}
                    </p>
                      <div class="social-icon">
                        <ul>
                          <li>
                            <a href="appsums.com" target="_blank">
                              <i class="fab fa-facebook-f"></i>{" "}
                            </a>
                          </li>
                          <li>
                            <a href="appsums.com" target="_blank">
                              <i class="fab fa-twitter"></i>{" "}
                            </a>
                          </li>
                          <li>
                            <a href="appsums.com" target="_blank">
                              <i class="fab fa-linkedin-in"></i>
                            </a>
                          </li>
                          <li>
                            <a href="appsums.com" target="_blank">
                              <i class="fab fa-instagram"></i>
                            </a>
                          </li>
                          <li>
                            <a href="appsums.com" target="_blank">
                              <i class="fab fa-dribbble"></i>{" "}
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="footer-widget footer-menu">
                    {location.pathname === "/patient-dashboard" ||
                    location.pathname === "/dashboard" ||
                    sessionStorage.getItem("isLoggedIn") ? (
                      ""
                    ) : (
                      <>
                        <h2 class="footer-title">For Patients</h2>
                        <ul>
                          <li>
                            <a onClick={handleSearchForDoc}>Search for Doctors</a>
                          </li>
                          <li>
                            <a onClick={handlePatientLogin}>Login</a>
                          </li>
                          <li>
                            <a onClick={handlePatientRegister}>Register</a>
                          </li>
                        </ul>
                      </>
                    )}
                  </div>
                </div>
                <div class="col-lg-3 col-md-6">
                  <div class="footer-widget footer-menu">
                    {location.pathname === "/patient-dashboard" ||
                    location.pathname === "/dashboard" ||
                    sessionStorage.getItem("isLoggedIn") ? (
                      ""
                    ) : (
                      <>
                        <h2 class="footer-title">For Doctors</h2>
                        <ul>
                          <li>
                            <a onClick={handleDoctorLogin}>Login</a>
                          </li>
                          <li>
                            <a onClick={handleDoctorRegister}>Register</a>
                          </li>
                        </ul>
                      </>
                    )}
                  </div>
                </div>

                <div class="col-lg-3 col-md-6">
                  <div class="footer-widget footer-contact">
                    <h2 class="footer-title">Contact Us</h2>
                    <div class="footer-contact-info">
                      <div class="footer-address">
                        <span>
                          <i class="fas fa-map-marker-alt"></i>
                        </span>
                        <p>81 Goffe Street, Manchester, NH 03102</p>
                      </div>
                      <p>
                        <i class="fas fa-phone-alt"></i>
                        +1 (855) 550-2348
                      </p>
                      <p class="mb-0">
                        {/* <Button onPress={() => Linking.openURL("mailto:info@gymdoctornow.com")} title="info@gymdoctornow.com"> */}
                        <i class="fas fa-envelope" onClick={handleMail}></i>
                        {/* </Button> */}
                        info@gymdoctornow.com
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-bottom">
            <div class="container-fluid">
              <div class="copyright">
                <div class="row">
                  <div class="col-md-6 col-lg-6">
                    <div class="copyright-text">
                      <p class="mb-0">&copy; 2024 Gym Doctors LLC. All Rights reserved.</p>
                    </div>
                  </div>
                  <div class="col-md-6 col-lg-6">
                    <div class="copyright-menu">
                      <ul class="policy-menu">
                        <li>
                          <a onClick={handleTermAndCondi}>Terms and Conditions</a>
                        </li>
                        <li>
                          <a onClick={handlePolicy}>Policy</a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </footer>
      )}
      <Login
        SignInModal={signInModal}
        setSignInModal={setSignInModal}
        handleSignUpToggle={handleSignUpToggle}
        handleLoginWithMobiToggle={handleLoginWithMobiToggle}
        handleForgetPasswordToggle={handleForgetPasswordToggle}
      />
      <SignUp SignUpModal={SignUpModal} setSignUpModal={handleSignUpToggle} handleSignInToggle={handleSignInToggle} />
      <ForgotPassword ForgetPwdModal={ForgetPwdModal} setForgetPwdModal={setForgetPwdModal} setSignInModal={setSignInModal} />
      <LoginWithNumber
        LoginWithNumModal={LoginWithNumModal}
        setLoginWithNumModal={setLoginWithNumModal}
        handleLoginWithMobiToggle={handleLoginWithMobiToggle}
        handleForgetPasswordToggle={handleForgetPasswordToggle}
      />
    </>
  );
}

export default Footer;
