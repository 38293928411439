import { toast } from "react-toastify";
import { getSessionStorageOrDefault } from "../../../utils";

function ToasterFunc(type, message) {
  const token = getSessionStorageOrDefault("authToken");
  if (type == "success") {
    toast.success(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else if (type == "error") {
    token &&
      toast.error(message, {
        position: toast.POSITION.TOP_RIGHT,
      });
  } else if (type == "warn") {
    toast.warn(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else if ((type = "info")) {
    toast.info(message, {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
}

export default ToasterFunc;
