import moment from "moment";
import React, { useState, memo, useEffect } from "react";
import { Modal, ModalBody } from "reactstrap";
import { convertToUTC, convertUTCToLocal, convertUTCToLocalForInput, DAYS, TIME_INTERVEL } from "./availability";
import "./style.css";
import "rc-time-picker/assets/index.css";
import TimePicker from "rc-time-picker";
import { IMAGE_CONSTANT } from "../../../constants";
import ToasterFunc from "../../common/toaster/Toaster";
import { Scrollbars } from "react-custom-scrollbars";

const format = "h:mm A";
function DocAvailabiltyModal({ AvaialabiltyModal, setAvaialabiltyModal, setEndDate, endDate, startDate, setStartDate, timeSlotDay, setTimeSlotDay, intervel, setIntervel, setAvailability }) {
  const handleClose = () => {
    setAvaialabiltyModal(false);
  };
  const [Days, setDays] = useState(DAYS);
  const [allDaysChecked, setAllDaysChecked] = useState(false);
  const [sameTimeForAll, setSameTimeForAll] = useState(false);
  const [slots, setSlots] = useState([]);
  const [open, setPickerOpen] = useState(false);
  const [openEnd, setPickerOpenEnd] = useState(false);

  useEffect(() => {
    if (startDate && endDate) handleInterval(startDate, endDate);
  }, [intervel]);

  useEffect(() => {
    if (allDaysChecked && !sameTimeForAll) {
      setTimeSlotDay(Days.filter((day) => day.isChecked)?.[0].fullname);
    } else if (!allDaysChecked && !sameTimeForAll) {
      if (Days.filter((day) => day.isChecked).length > 0) setTimeSlotDay(Days.filter((day) => day.isChecked)?.[0].fullname);
      else setTimeSlotDay("");
    } else if (sameTimeForAll) {
      setTimeSlotDay("ALL DAYS");
    }
  }, [allDaysChecked, sameTimeForAll]);

  useEffect(() => {
    console.log(startDate);
    SetData();
  }, [slots, endDate, startDate, sameTimeForAll, allDaysChecked]);

  const SetData = () => {
    if (sameTimeForAll) {
      setDays((Days) => [
        ...Days.map((item) => {
          if (item.isChecked) {
            return {
              fullname: item.fullname,
              day: item.day,
              endTime: endDate,
              interval: intervel,
              isChecked: item.isChecked,
              slots: slots,
              startTime: startDate,
            };
          } else {
            return {
              ...item,
            };
          }
        }),
      ]);
    } else {
      setDays((Days) => [
        ...Days.map((item) => {
          if (item.fullname === timeSlotDay) {
            return {
              fullname: item.fullname,
              day: item.day,
              endTime: endDate,
              interval: intervel,
              isChecked: item.isChecked,
              slots: slots,
              startTime: startDate,
            };
          } else {
            return {
              ...item,
            };
          }
        }),
      ]);
    }
  };

  const handleDaysAllDayChecked = (e) => {
    setDays(
      Days.map((item) => {
        return {
          ...item,
          isChecked: e.target.checked,
        };
      })
    );
    setAllDaysChecked(e.target.checked);
  };

  const handleAddDays = (e) => {
    const updatedArray = Days;
    updatedArray[e.target.id].isChecked = true;
    setDays(() => [...updatedArray]);
    console.log(sameTimeForAll);
    if (!sameTimeForAll) {
      setStartDate(updatedArray[e.target.id].startTime);
      setEndDate(updatedArray[e.target.id].endTime);
      setSlots(updatedArray[e.target.id].slots);
    } else {
      SetData();
    }

    if (!sameTimeForAll) setTimeSlotDay(`${Days[e.target.id].fullname}`);
  };

  const handleDaysRemove = (e) => {
    const updatedArray = Days;
    updatedArray[e.target.id].isChecked = false;
    updatedArray[e.target.id].endTime = null;
    updatedArray[e.target.id].startTime = null;
    updatedArray[e.target.id].slots = [];
    setAllDaysChecked(false);
    setDays(() => [...updatedArray]);

    if (Days.filter((day) => day.isChecked).length > 0) {
      setTimeSlotDay(`${Days.filter((day) => day.isChecked)?.[0].fullname}`);
      setStartDate(Days.filter((day) => day.isChecked)?.[0].startTime);
      setEndDate(Days.filter((day) => day.isChecked)?.[0].endTime);
      setSlots(Days.filter((day) => day.isChecked)?.[0].slots);
    } else {
      setTimeSlotDay("");
      setSlots([]);
      setEndDate(null);
      setStartDate(null);
    }
  };

  const handleEndState = (e) => {
    setEndDate(e);
    handleInterval(startDate, e);
  };

  const handleStartDate = (e) => {
    setStartDate(e);
    if (endDate) {
      handleInterval(e, endDate);
    }
  };

  const handleInterval = (start, end) => {
    start = start ? new moment.utc(start, "HH:mm A").local() : null;
    end = end ? new moment.utc(end, "HH:mm A").local() : null;
    const timeStops = [];
    // if (end - start < 0) {
    //   start.subtract(1, "days");
    // }
    if( end.isBefore(start) ){
      end.add(1, 'day');
    }
    console.log("====>", end - start);
    while (start && end && start < end) {
      // if (!isGreater(new moment(start, ["HH.mm"]).utc().format("hh:mm A")))
        timeStops.push({
          slotTime: new moment(start, ["HH.mm"]).utc().format("hh:mm A"),
        });

      start.add(intervel, "minutes");
    }
    setSlots(timeStops);
  };

  const addIntervel = (time) => {
    time = time ? new moment.utc(time, "HH:mm A").local() : null;
    time.add(intervel, "minutes");
    return new moment(time, ["HH.mm"]).utc().format("hh:mm A");
  };

  const deleteSlot = (e) => {
    setSlots((slots) => slots.filter((slotsData) => slotsData.slotTime !== e.target.id));
  };

  const handleSubmit = () => {
    if (!Days.some((day) => day.isChecked)) ToasterFunc("error", "Please select day.");
    else {
      console.log("before return");
      const IsCheckedArray = Days.filter((day) => day.isChecked);
      for (let i = 0; i < IsCheckedArray.length; i++) {
        console.log("@@@@@@@)))))", IsCheckedArray[i].slots.length);
        if (IsCheckedArray[i].slots.length === 0) {
          ToasterFunc("error", "Please select slots.");
          return;
        }
      }
      setAvailability(
        Days.map((item) => {
          return {
            day: item.day,
            startTime: item.startTime,
            endTime: item.endTime,
            interval: intervel,
            slots: item.slots,
          };
        })
      );
      handleClose();
    }
    console.log("after return");
  };

  const isGreater = (time) => {
    time = time ? new moment.utc(time, "HH:mm A").local() : null;
    const end = time ? new moment.utc(endDate, "HH:mm A").local() : null;
    return time.add(intervel, "minutes") > end;
  };

  return (
    <Modal isOpen={AvaialabiltyModal} className="sign-modal modal-dialog-centered avail_modal">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title" id="exampleModalLongTitle">
            Select Schedule Availability
          </h4>
          <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <div className="modal-body signup-availability">
            <div className="row">
              <div className="col-sm-12">
                <div className="card">
                  <div className="card-body">
                    <div className="profile-box">
                      <div className="row">
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Timing Slot Duration</label>
                            <select className="select form-control" onChange={(e) => setIntervel(e.target.value)}>
                              {TIME_INTERVEL.map((item) => {
                                return (
                                  <option selected={item.time === "30 Mins" && "selected"} value={item.value}>
                                    {item.time}
                                  </option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="card schedule-widget mb-0">
                            <div className="schedule-header">
                              <div className="schedule-nav">
                                <ul className="nav nav-tabs nav-justified">
                                  {Days?.map((item, index) => {
                                    return (
                                      <li className={`nav-item ${item.isChecked ? "blog-content" : ""}`}>
                                        <a className={`nav-link ${item.isChecked ? "active" : ""}`} id={index} data-toggle="tab" href onClick={handleAddDays}>
                                          {item.fullname}
                                        </a>
                                        {item.isChecked && (
                                          <span className="cross-btn" onClick={handleDaysRemove}>
                                            <i id={index} className="fa fa-times"></i>
                                          </span>
                                        )}
                                      </li>
                                    );
                                  })}
                                  <li className="nav-item flex-align">
                                    <div className="mr-1 select-allDays">
                                      <input className="mr-1" type="checkbox" id="status_72" checked={allDaysChecked} onChange={handleDaysAllDayChecked} />
                                      <label for="status_72">Select All Days</label>
                                    </div>
                                  </li>
                                </ul>
                                <div className="row align-items-center">
                                  <div className="col-md-3 col-6">
                                    <div className="form-group ">
                                      <label for="Start Time">Start Time </label>
                                      <div className="custom-time-picker">
                                        <TimePicker
                                          disabled={!timeSlotDay}
                                          showSecond={false}
                                          value={startDate ? new moment.utc(startDate, "HH:mm A").local() : null}
                                          className="timePickerClass"
                                          onChange={(e) => {
                                            !e ? handleStartDate(e) : handleStartDate(convertToUTC(e));
                                          }}
                                          open={open}
                                          onAmPmChange={() => setPickerOpen(!open)}
                                          onOpen={() => setPickerOpen(true)}
                                          format={format}
                                          use12Hours
                                          allowEmpty
                                          placeholder="--:-- --"
                                          inputIcon={!startDate && <img src={IMAGE_CONSTANT.CLOCK_IMG} alt="clock" className="clockClass" />}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3 col-6">
                                    <div className="form-group ">
                                      <label for="end Time">End Time</label>
                                      <div className="custom-time-picker">
                                        <TimePicker
                                          disabled={!startDate}
                                          showSecond={false}
                                          value={endDate ? new moment.utc(endDate, "HH:mm A").local() : null}
                                          className="timePickerClass"
                                          onChange={(e) => {
                                            !e ? handleEndState(e) : handleEndState(convertToUTC(e));
                                          }}
                                          open={openEnd}
                                          onAmPmChange={() => setPickerOpenEnd(!openEnd)}
                                          onOpen={() => setPickerOpenEnd(true)}
                                          format={format}
                                          use12Hours
                                          allowEmpty
                                          placeholder="--:-- --"
                                          inputIcon={!endDate && <img src={IMAGE_CONSTANT.CLOCK_IMG} alt="clock" className="clockClass" />}
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3  d-flex flex-align">
                                    <div className="status-toggle all-days">
                                      <input
                                        type="checkbox"
                                        id="status"
                                        className="check mr-1"
                                        checked={sameTimeForAll}
                                        onClick={(e) => {
                                          setSameTimeForAll(!sameTimeForAll);
                                          if (!e.target.checked) setTimeSlotDay("");
                                          else if (!allDaysChecked) setTimeSlotDay("ALL DAYS");
                                        }}
                                      />
                                      <label for="status" className="checktoggle">
                                        Same For All Days
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="card-body pt-0">
                              <div className="tab-content schedule-cont">
                                <div id="slot_monday" className="tab-pane fade show active">
                                  <h4 className="card-title d-flex justify-content-between">
                                    <span>{timeSlotDay ? `Time Slots (FOR ${timeSlotDay})` : `Time Slots`}</span>
                                  </h4>
                                  <Scrollbars className="time-slot-scroll" autoHide>
                                    <div className="doc-times">
                                      {slots?.map((item, index) => {
                                        // !isGreater(item.slotTime) && (
                                        return (
                                            <div className="doc-slot-list">
                                              {`${convertUTCToLocal(item.slotTime)} - ${convertUTCToLocal(addIntervel(item.slotTime))}`}
                                              <a href className="delete_schedule" onClick={deleteSlot}>
                                                <i id={item.slotTime} className="fa fa-times"></i>
                                              </a>
                                            </div>
                                          // )
                                        );
                                      })}
                                    </div>
                                  </Scrollbars>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="mt-4 d-flex justify-content-center">
                            <button onClick={handleSubmit} data-toggle="modal" className="btn btn-primary w-25">
                              Done
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default memo(DocAvailabiltyModal);
