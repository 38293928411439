import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Modal } from "reactstrap";
import { commonAction, doctor } from "../../../../redux/action";

function AddMedicalRecords({ MedicalModal, setMedicalModal, Patid, callApi, slectedRecord }) {
  const dispatch = useDispatch();
  const [medicalData, setMedicalData] = useState({
    name: "",
    desciption: "",
  });

  const [Image, setImage] = useState("");
  const [fileName, setFileName] = useState("");
  const [ImageErr, setImageErr] = useState("");
  const [showErr, setShowErr] = useState(false);

  useEffect(() => {
    if (slectedRecord !== null) {
      setImage(slectedRecord?.medicalRecordFile);
      setMedicalData({
        name: slectedRecord?.name,
        desciption: slectedRecord?.description,
      });
      setFileName("Medical record pdf");
    } else {
      setImage("");
      setMedicalData({
        name: "",
        desciption: "",
      });
      setFileName("");
    }
  }, [slectedRecord]);
  const CommonProfilePic = async (payload) => dispatch(commonAction.commonProfilePicAction(payload));

  const addMedicalRecord = async (payload) => dispatch(doctor.addMedicalRecordAction(payload));

  const editMedicalRecord = async (payload) => dispatch(doctor.editMedicalRecordAction(payload));

  const handleImage = (e) => {
    const file = e.target.files[0];
    console.log(file);
    let formData = new FormData();
    console.log("file changed");
    if (file) {
      setFileName(file.name);
      let fileSize = file.size / 1e6;
      console.log("fileSize", fileSize);
      console.log(fileSize);
      if (fileSize > 1) {
        setImageErr("Please upload a file smaller than 1 MB");
      } else {
        formData.append("file", file);
        fileUpload(formData);
      }
    }
  };

  const fileUpload = async (image) => {
    const res = await CommonProfilePic(image);
    if (res && res.status == 200) {
      let picdata = res && res.data && res.data.result.url;
      console.log("res1111111", res);
      setImage(picdata);
    }
  };

  const handleChange = (e) => {
    setMedicalData({ ...medicalData, [e.target.id]: e.target.value });
  };

  const handleSubmit = async () => {
    if (medicalData.name && medicalData.desciption && Image) {
      console.log(medicalData.name, medicalData.desciption, Image, Patid);
      const payload = {
        name: medicalData.name,
        description: medicalData.desciption,
        medicalRecordFile: Image,
      };
      if (slectedRecord == null) payload.patientId = Patid;
      else payload.medicalrecordId = slectedRecord?._id;
      let res = null;

      console.log(slectedRecord, payload);
      if (slectedRecord == null) res = await addMedicalRecord(payload);
      else res = await editMedicalRecord(payload);

      if (res.status == 200) {
        setMedicalModal(false);
        callApi();
      }
    } else {
      setShowErr(true);
    }
  };

  const cancelModal = () => {
    if (slectedRecord === null) {
      setMedicalModal();
      setImage("");
      setMedicalData({
        name: "",
        desciption: "",
      });
      setFileName("");
    } else {
      setMedicalModal();
    }
  };
  return (
    <Modal isOpen={MedicalModal} className="modal-dialog modal-dialog-scrollable modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title">{slectedRecord == null ? "Add Medical Records" : "Edit Medical Records"}</h4>
          <button type="button" class="close" onClick={() => cancelModal()}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="container-fluid">
            <div class="row">
              <div class="col-lg-12 ">
                <div class="invoice-content">
                  <div class="row form-row">
                    <div class="col-12 col-md-12">
                      <div class="form-group">
                        <label>Name</label>
                        <input
                          id="name"
                          type="text"
                          class="form-control"
                          placeholder="File Name"
                          value={medicalData.name}
                          onChange={handleChange}
                        />
                      </div>
                      {showErr && !medicalData.name && <span className="validation_error">Please enter name.</span>}
                    </div>
                    <div class="col-12 col-md-12">
                      <div class="form-group">
                        <label for="description">Description</label>
                        <textarea
                          id="desciption"
                          class="form-control"
                          rows="3"
                          placeholder="Type here"
                          value={medicalData.desciption}
                          onChange={handleChange}
                        ></textarea>
                        {showErr && !medicalData.desciption && <span className="validation_error">Please enter description.</span>}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <div class="signature flex-column">
                        <label for="files">
                          <input type="file" id="files" accept="application/pdf" hidden onChange={(e) => handleImage(e)} />
                          <i class="fa fa-file-pdf fa-2x"></i>
                          <p class="mb-0">{!Image ? "Upload File" : fileName}</p>
                        </label>
                      </div>

                      {!ImageErr ? (
                        <span className="suggestion">Only PDF file with max size of 10MB.</span>
                      ) : (
                        <div className="validation_error Image">{ImageErr}</div>
                      )}

                      {showErr && !Image && <span className="validation_error">Please upload file.</span>}
                    </div>
                  </div>
                  <div class="d-flex justify-content-center">
                    <a href class="btn book-btn w-25 mr-1" onClick={handleSubmit}>
                      Submit
                    </a>
                    <a href="#" class="btn view-btn w-25">
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddMedicalRecords;
