import React, { useState, useEffect } from "react";
import AboutViewAppointment from "./../AboutViewAppointment";
import MedicalViewAppointment from "./../MedicalViewAppointment";
import { useDispatch, useSelector } from "react-redux";
import { doctor } from "../../../../redux/action";
import { useParams } from "react-router-dom";

const AppointmentsDetails = ({ UpcomingAppointsList, PreviousAppToggole , roomId }) => {
  const [Toggle, setToggle] = useState({
    aboutToggle: true,
    MedicalToggle: false,
  });
  const [About, setAbout] = useState(false);
  const [Medical, setMedical] = useState(false);

  const handleToogle = (data) => {
    if (data === "About") {
      setToggle({
        aboutToggle: true,
        MedicalToggle: false,
      });
      setAbout(true);
    } else if (data === "Medical") {
      setToggle({
        MedicalToggle: true,
        aboutToggle: false,
      });
      setMedical(true);
    }
  };

  console.log("UpcomingAppointsList", UpcomingAppointsList);
  return (
    <div>
      {/* <!-- Appointments Content --> */}
      <div role="tabpanel" id="Appointments-d" className="tab-pane fade show active">
        <div className="row">
          <div className="col-lg-12">
            <nav className="user-tabs">
              <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                <li className="nav-item">
                  <a class={Toggle.aboutToggle ? "nav-link active" : "nav-link "} onClick={() => handleToogle("About")}>
                    About
                  </a>
                </li>
                <li className="nav-item">
                  <a class={Toggle.MedicalToggle ? "nav-link active" : "nav-link "} onClick={() => handleToogle("Medical")}>
                    Medical Details
                  </a>
                </li>
              </ul>
            </nav>
            <div className="tab-content profile-tab-cont appointments">
              {Toggle.aboutToggle ? (
                <AboutViewAppointment UpcomingAppointsList={UpcomingAppointsList} roomId = {roomId} About={About} PreviousAppToggole={PreviousAppToggole} />
              ) : Toggle.MedicalToggle ? (
                <MedicalViewAppointment UpcomingAppointsList={UpcomingAppointsList} Medical={Medical} PreviousAppToggole={PreviousAppToggole} />
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppointmentsDetails;
