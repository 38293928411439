import moment from "moment";
import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import ReactStars from "react-stars";
import { ROUTES_CONSTANTS } from "../../../../../constants";
import { patient } from "../../../../../redux/action";
import CancelAppointment from "../../../../common/CancelModal/CancelModal";

const LabUpcomingApp = () => {
  let history = useHistory();
  let dispatch = useDispatch();
  const [CancelModal, setCancelModal] = useState(false);
  const [CancelLabId, setCancelLabId] = useState("");
  const [list, setList] = useState("");
  useEffect(() => {
    labUpcomingData();
  }, []);

  const labUpcomingData = async () => dispatch(patient.labUpcomingListAction());
  const labUpcomingAppointmentList = useSelector((state) => state?.patient?.patientLabUpcomingList?.appointmentData);
  useEffect(() => {
    setList(labUpcomingAppointmentList);
  }, [labUpcomingAppointmentList]);

  console.log("Up===>", labUpcomingAppointmentList);
  const handleSearch = (e) => {
    if (e.target.value === "") setList(labUpcomingAppointmentList);
    else setList(labUpcomingAppointmentList.filter((data) => data.laboratoryId.name.toLowerCase().search(e.target.value.toLowerCase()) != -1));
  };

  const handleReschedule = (AppData) => {
    history.push(ROUTES_CONSTANTS.PATIENT_RESCHEDULE_LABORATORY, AppData);
  };

  return (
    <>
      <div className="tab-pane active" id="pat-previous-appnt">
        <div className="input-social-media">
          <input type="text" className="form-control  mb-2" onChange={handleSearch} style={{ paddingLeft: "40px" }} />
          <span>
            <i className="fas fa-search appt-search-icon"></i>
          </span>
        </div>
        <Scrollbars className="appointment-height" autoHide>
          <div className="row">
            {list && list.length ? (
              list.map((item, i) =>
                item?.status === "accepted" ? (
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="card widget-profile pat-widget-profile">
                      <div class="card-body li-listing-profile">
                        <div className="row">
                          <div className="col-md-3">
                            <div class="profile-info-widget">
                              <div class="booking-doc-img">{item && item.laboratoryId && item.laboratoryId.image ? <img src={item && item.laboratoryId && item.laboratoryId.image} alt="" className="img-fluid" /> : ""}</div>
                            </div>
                          </div>
                          <div className="col-md-9 pl-0">
                            <div class="profile-det-info">
                              <h3>{item && item.laboratoryId && item.laboratoryId.name}</h3>
                              <p>Date : {moment(item && item.appointmentStartTime).format("MM/DD/YYYY")}</p>
                              <p>
                                Time : {moment(item && item.appointmentStartTime).format("LT")} - {moment(item && item.appointmentEndTime).format("LT")}
                              </p>
                            </div>
                            <div class="color-grey">
                              <b>Package :</b> {item && item.packageId && item.packageId.name}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <p>
                              <b>Description :</b> {item && item.packageId && item.packageId.description}
                            </p>
                          </div>
                          {/* <div className="col-md-12">
                            <div className="lab_button">
                              <div className="row">
                                <div className="col-6 pr-1">
                                  <a className="btn book-btn" onClick={() => handleReschedule(item)}>
                                    Reschedule
                                  </a>
                                </div>
                                <div className="col-6 pl-1">
                                  <a
                                    className="btn view-btn"
                                    onClick={() => {
                                      setCancelLabId(item?._id);
                                      setCancelModal(!CancelModal);
                                    }}
                                  >
                                    Cancel
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div> */}
                        </div>

                        {/* <div class="row">
                               <div class="col-md-12 mb-2 ">
                                   <div class="completedAppoint">
                                       <span>
                                           <i class="fa fa-check-circle mr-1"></i>
                                       </span>
                                       Completed
                                   </div>
                                   <a class="btn book-btn" >
                                       Schedule Appointment
                                   </a>
                               </div>
                           </div> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div class="col-lg-6 col-md-6 col-sm-6">
                    <div class="card widget-profile pat-widget-profile">
                      <div class="card-body li-listing-profile">
                        <div className="row">
                          <div className="col-md-3">
                            <div class="profile-info-widget">
                              <div class="booking-doc-img">{item && item.laboratoryId && item.laboratoryId.image ? <img src={item && item.laboratoryId && item.laboratoryId.image} alt="" className="img-fluid" /> : ""}</div>
                            </div>
                          </div>
                          <div className="col-md-9 pl-0">
                            <div class="profile-det-info">
                              <h3>{item && item.laboratoryId && item.laboratoryId.name}</h3>
                              <p>
                                Time : {moment(item && item.appointmentStartTime).format("LT")} - {moment(item && item.appointmentEndTime).format("LT")}
                              </p>
                            </div>
                            <div class="color-grey">
                              <b>Package :</b> {item && item.packageId && item.packageId.name}
                            </div>
                          </div>
                          <div className="col-md-12">
                            <p>
                              <b>Description :</b> {item && item.packageId && item.packageId.description}
                            </p>
                          </div>
                          <div className="cancelledAppoint offset-4 col-4">
                            <span>
                              <i class="fa fa-times-circle mr-1"></i>
                            </span>
                            Cancelled
                          </div>
                          {/* </div> */}
                        </div>

                        {/* <div class="row">
                               <div class="col-md-12 mb-2 ">
                                   <div class="completedAppoint">
                                       <span>
                                           <i class="fa fa-check-circle mr-1"></i>
                                       </span>
                                       Completed
                                   </div>
                                   <a class="btn book-btn" >
                                       Schedule Appointment
                                   </a>
                               </div>
                           </div> */}
                      </div>
                    </div>
                  </div>
                )
              )
            ) : (
              <div className="col-md-12">
                <p className="text-center">There are currently no appointments available.</p>
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
      <CancelAppointment CancelModal={CancelModal} setCancelModal={setCancelModal} CancelLabId={CancelLabId} />
    </>
  );
};

export default LabUpcomingApp;
