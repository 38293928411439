import { notificationActionType } from "../../action";
import initialState from "../initialState";

export default function notificationReducer(
  state = initialState.common,
  action
) {
  switch (action.type) {
    case notificationActionType.SET_NOTIFICATION_LIST:
      return {
        ...state,
        notificationList: action.notificationList,
      };
    default:
      return state;
  }
}
