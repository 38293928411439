import { useState } from "react";
import ChangePwd from "./ChangePwd/ChangePwd";
import InviteFriends from "./InviteFriends/InviteFriends";
import MedicalDetail from "./MedicalDetail.js/MedicalDetail";
import PaymentDetail from "./PaymentDetail/PaymentDetail";
import PersonalDetail from "./PersonalDetail/PersonalDetail";
import PrivacyPolicy from "./PrivacyPolicy/PrivacyPolicy";
import TermAndCondition from "./Terms&Condition/TermAndCondition";
import { Scrollbars } from "react-custom-scrollbars";

function PatientSettings() {
  const [isPersonalProfile, setisPersonalProfile] = useState(false);
  const [isMedicalProfile, setisMedicalProfile] = useState(false);
  const [isChngePwd, setisChngePwd] = useState(true);
  const [isPayment, setisPayment] = useState(false);
  const [isPrivacyPolicy, setisPrivacyPolicy] = useState(false);
  const [isTermsAndCon, setisTermsAndCon] = useState(false);
  // const [isCancellation, setisCancellation] = useState(false)
  const [isInvite, setisInvite] = useState(false);

  const handlePwd = () => {
    setisChngePwd(true);
    setisMedicalProfile(false);
    setisPersonalProfile(false);
    setisPayment(false);
    setisPrivacyPolicy(false);
    setisTermsAndCon(false);
    // setisCancellation(false)
    setisInvite(false);
  };
  const handleMedicalProfile = () => {
    setisMedicalProfile(true);
    setisChngePwd(false);
    setisPersonalProfile(false);
    setisPayment(false);
    setisPrivacyPolicy(false);
    setisTermsAndCon(false);
    // setisCancellation(false)
    setisInvite(false);
  };
  const handlePersonalProfile = () => {
    setisPersonalProfile(true);
    setisChngePwd(false);
    setisMedicalProfile(false);
    setisPayment(false);
    setisPrivacyPolicy(false);
    setisTermsAndCon(false);
    // setisCancellation(false)
    setisInvite(false);
  };
  const handlePayments = () => {
    setisPayment(true);
    setisPrivacyPolicy(false);
    setisTermsAndCon(false);
    setisPersonalProfile(false);
    setisChngePwd(false);
    setisMedicalProfile(false);
    // setisCancellation(false)
    setisInvite(false);
  };
  const handlePrivacyPolicy = () => {
    setisPrivacyPolicy(true);
    setisTermsAndCon(false);
    setisPersonalProfile(false);
    setisChngePwd(false);
    setisMedicalProfile(false);
    setisPayment(false);
    // setisCancellation(false)
    setisInvite(false);
  };
  const handleTermsAndCon = () => {
    setisTermsAndCon(true);
    setisPersonalProfile(false);
    setisChngePwd(false);
    setisMedicalProfile(false);
    setisPayment(false);
    setisPrivacyPolicy(false);
    // setisCancellation(false)
    setisInvite(false);
  };
  // const handleCancellation = () => {
  //     setisCancellation(!isCancellation)
  //     setisTermsAndCon(false)
  //     setisPersonalProfile(false)
  //     setisChngePwd(false)
  //     setisMedicalProfile(false)
  //     setisPayment(false)
  //     setisPrivacyPolicy(false)
  //     setisInvite(false)
  // }
  const handleInvite = () => {
    setisInvite(true);
    setisTermsAndCon(false);
    setisPersonalProfile(false);
    setisChngePwd(false);
    setisMedicalProfile(false);
    setisPayment(false);
    setisPrivacyPolicy(false);
    // setisCancellation(false)
    console.log("clicked");
  };

  return (
    <div className=" col-lg-8 col-xl-9 content_ipad_p">
      <div className="card">
        <div className="card-body pt-0">
          {/* <!-- setting sidebar tab menu  --> */}
          <nav className="user-tabs mb-4">
            <Scrollbars className="setting-c-scroll" autoHide>
              <ul className="nav nav-tabs nav-tabs-bottom nav-justified text-nowrap">
                <li className="nav-item">
                  <a className={isPersonalProfile ? "nav-link active" : "nav-link"} onClick={handlePersonalProfile}>
                    Edit Profile
                  </a>
                </li>
                <li className="nav-item">
                  <a className={isMedicalProfile ? "nav-link active" : "nav-link"} onClick={handleMedicalProfile}>
                    Edit Medical Profile
                  </a>
                </li>
                <li className="nav-item">
                  <a className={isChngePwd ? "nav-link active" : "nav-link"} onClick={handlePwd}>
                    Change Password
                  </a>
                </li>
                <li className="nav-item">
                  <a className={isPayment ? "nav-link active" : "nav-link"} onClick={handlePayments}>
                    Payments Info
                  </a>
                </li>
                {/* <li className="nav-item">
                  <a className={isPrivacyPolicy ? "nav-link active" : "nav-link"} onClick={handlePrivacyPolicy}>
                    Privacy Policy
                  </a>
                </li>
                <li className="nav-item">
                  <a className={isTermsAndCon ? "nav-link active" : "nav-link"} onClick={handleTermsAndCon}>
                    Terms and Conditions
                  </a>
                </li> */}
                {/* <!-- <li className="nav-item">
                            <a className="nav-link" href="#Help" data-toggle="tab">Help</a>
                        </li> --> */}
                {/* <li className="nav-item">
                                    <a className={isCancellation ? "nav-link active" : "nav-link"} onClick={handleCancellation}>Cancellation and Refund Policy</a>
                                </li> */}
                <li className="nav-item">
                  <a className={isInvite ? "nav-link active" : "nav-link"} onClick={handleInvite}>
                    Invite Friends
                  </a>
                </li>
              </ul>
            </Scrollbars>
          </nav>

          {/* <!-- setting tab content menu  --> */}
          <div className="tab-content pt-0">
            {isChngePwd ? (
              <ChangePwd />
            ) : isPersonalProfile ? (
              <PersonalDetail />
            ) : isMedicalProfile ? (
              <MedicalDetail />
            ) : isPayment ? (
              <PaymentDetail setisPayment={setisPayment} />
            ) : isPrivacyPolicy ? (
              <PrivacyPolicy />
            ) : isInvite ? (
              <InviteFriends />
            ) : isTermsAndCon ? (
              <TermAndCondition />
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientSettings;
