import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import { patient } from "../../../../redux/action";
import { useDispatch } from "react-redux";

function ViewPrescription({ viewPresModal, setviewPresModal, PresId, DocName }) {
  let dispatch = useDispatch();
  const patientPrescriptionDetail = useSelector((state) => state?.patient?.patientPrescriptionView?.data);

  console.log("patientPrescriptionDetail", patientPrescriptionDetail);

  useEffect(() => {
    patientPrescriptionView(PresId);
  }, [PresId]);

  const patientPrescriptionView = async (payload) => dispatch(patient.patientPrescriptionViewAction(payload));
  return (
    // <div className="modal fade" id="pat_view_prescription" tabindex="-1" role="dialog" aria-labelledby="billing_invoiceModal" aria-hidden="true">
    //   <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document">
    <Modal isOpen={viewPresModal} className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title">View Prescription</h4>
          <button type="button" className="close" onClick={() => setviewPresModal(false)}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          {/* <div className="modal-body"> */}
          <div className="container-fluid">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="invoice-content">
                  <p>
                    <b>Name :</b>
                    <span className="color-grey"> {patientPrescriptionDetail && patientPrescriptionDetail.name}</span>{" "}
                  </p>
                  <p>
                    <b>Quantity :</b>
                    <span className="color-grey"> {patientPrescriptionDetail && patientPrescriptionDetail.quantity}</span>{" "}
                  </p>
                  <p>
                    <b>Days :</b>
                    <span className="color-grey"> {patientPrescriptionDetail && patientPrescriptionDetail.number_of_days}</span>{" "}
                  </p>
                  <div className="form-group">
                    <label for="">
                      <b>Timing : </b>{" "}
                      <span className="color-grey">
                        {" "}
                        {patientPrescriptionDetail && patientPrescriptionDetail.timing && patientPrescriptionDetail.timing.length && patientPrescriptionDetail.timing.map((item, i, arr) => (i < arr.length - 1 ? ` ${item},` : ` ${item}.`))}
                      </span>
                    </label>

                    {/* {patientPrescriptionDetail &&
                      patientPrescriptionDetail.timing &&
                      patientPrescriptionDetail.timing.length &&
                      patientPrescriptionDetail.timing.map((item) => (
                        <div className=" form-check">
                          <input type="checkbox" className="form-check-input" id="Morning" checked={Timing && Timing.morning ? true : false} />
                          <label className="form-check-label" for="Morning">
                            {item}
                          </label>
                        </div>
                      ))} */}
                    {/* <div className=" form-check">
                      <input type="checkbox" className="form-check-input" id="Afternoon" checked={patientPrescriptionDetail && patientPrescriptionDetail.timing.map((item) => item === "Afternoon") ? true : false} />
                      <label className="form-check-label" for="Afternoon">
                        Afternoon
                      </label>
                    </div> */}
                    {/* <div className=" form-check">
                      <input type="checkbox" className="form-check-input" id="Night" checked={patientPrescriptionDetail && patientPrescriptionDetail.timing.map((item) => item === "Night") ? true : false} />
                      <label className="form-check-label" for="Night">
                        Night
                      </label>
                    </div> */}
                    {/* <div className=" form-check">
                      <input type="checkbox" className="form-check-input" id="Evening" checked={patientPrescriptionDetail && patientPrescriptionDetail.timing.map((item) => item === "Evening") ? true : false} />
                      <label className="form-check-label" for="Evening">
                        Evening
                      </label>
                    </div> */}
                  </div>

                  {/* <!-- Invoice Information --> */}
                  <div className="other-info mb-3">
                    <label for="">
                      <b>Description : </b>{" "}
                    </label>
                    <p className="text-muted mb-0">{patientPrescriptionDetail?.description}</p>
                  </div>
                  {/* <!-- /Invoice Information --> */}
                  {/* <!-- Signature --> */}
                  <div className="row">
                    <div className="col-md-12 text-center">
                      <img src={patientPrescriptionDetail?.signature} alt="Signature-Image" className="signature" />
                      <div>
                        <p className="mb-0">( Dr. {DocName} )</p>
                        <span className="text-muted">Signature</span>
                      </div>
                    </div>
                  </div>
                  {/* <!-- /Signature --> */}
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </ModalBody>
      </div>
    </Modal>
    //   </div>
    // </div>
  );
}

export default ViewPrescription;
