import { useEffect } from "react";
import { doctor } from "../../redux/action";
import { useDispatch, useSelector } from "react-redux";

function TermsAndConditions() {
  const dispatch = useDispatch();

  const docTermsAndCondition = async () => dispatch(doctor.docTermsAndConditionAction());
  useEffect(() => {
    window.scrollTo(0, 0);
    docTermsAndCondition();
  }, []);

  const termAndConditionData = useSelector((state) => state?.doctor?.doctorTermsAndConditions?.data);

  console.log("T&C==>", termAndConditionData);
  const createMarkup = () => {
    if (termAndConditionData) {
      return { __html: termAndConditionData && termAndConditionData.description && termAndConditionData.description };
    }
  };

  return (
    <>
      <div className={sessionStorage.getItem("termCondi") ? " col-lg-8 col-xl-12 content_ipad_p" : " col-lg-12 content_ipad_p"}>
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header">
                <h4>{termAndConditionData && termAndConditionData.title}</h4>
              </div>
              <div className="card-body">
                {termAndConditionData ? (
                  <div className="cms_box" dangerouslySetInnerHTML={createMarkup()} />
                ) : (
                  <p className="text-center">Data not found!</p>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsAndConditions;
