// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from "firebase/app";
import { BehaviorSubject } from 'rxjs';
import "@firebase/messaging";
import { saveToSessionStorage ,getSessionStorageOrDefault} from "./utils";
import { ROUTES_CONSTANTS } from "./constants";
import { toast } from "react-toastify";
import Ring from "../src/assets/ring.wav";

var firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENTID
};

console.log('firebaseConfig',firebaseConfig);
if (!firebase.apps.length) {
  firebase.initializeApp(firebaseConfig);
} else {
  firebase.app(); // if already initialized, use that one
}
export const deviceToken = new BehaviorSubject(``);
export const getDisconnectFun = new BehaviorSubject(``);
export const IsPathChange = new BehaviorSubject(localStorage.getItem("pathchange") 
                                ? localStorage.getItem("pathchange") == 'true' ? true : false  : "");
export const isCallStarted = new BehaviorSubject(false);
export const disconnectCallRequest = new BehaviorSubject({});
export const IsCallerDetails  = new BehaviorSubject(false);

export default function getDeviceToken(history) {
 
  if (!firebase.messaging.isSupported()) {
    return;
  }
  const FirebaseMessaging = firebase.messaging();

  Notification.requestPermission()
    .then(() => {
      return FirebaseMessaging.getToken({
        vapidKey:
          "BJYfYnQGnkw0hyDp3Dh5syXmaFpxRnX9FB7sU32yolNFcRJKgW39mfk3VQE71l3sAnBZHLCLg0MeE6oJYdthtEo",
      });
    })
    .then((token) => {
      console.log("HAVEE token", token);
      deviceToken.next(token);
      saveToSessionStorage("deviceToken", token);
    })
    .catch((err) => {
      console.log("@@@@@@@@@@@@@@@@@ messaging error", err);
    });

  FirebaseMessaging.onMessage((msg) => {
    console.log("@@@@@1234555", msg);
    //  toast(msg?.notification?.body);
    if (msg?.data?.twilioToken) {
      isCallStarted.next(true);
      history.push(
        `${ROUTES_CONSTANTS.VIDEO_CALL}/${msg.data?.twilioToken}/${msg.data?.room}/${msg.data?.pushType}/incoming`
      );
      saveToSessionStorage("callerDetails", JSON.stringify(msg.data));
      IsCallerDetails.next(true);
     
      //   history.push("/my-appointments");
      //   // history.push(
      //   //   `/video-call/${JSON.stringify(msg.data)}/${msg.data.pushType}`
      //   // );
      //   // window.location.reload();
      // } else if (msg?.data?.type === "call_reject") {
      //   history.push("/my-appointments");
      //   // window.location.reload();
    }

    if (msg?.data?.type === "call_reject") {
    // let audio = new Audio(Ring);
    //  if (audio?.pause){
    //   audio?.pause()
    //  }
      if (getSessionStorageOrDefault("userType") === "1" ){
        sessionStorage.removeItem("callerDetails");
        IsCallerDetails.next(false);
        window.close();
        //  history.push(ROUTES_CONSTANTS.APPOINTMENTS);
        window.history.go(-1);
         IsPathChange.next(true);
      }else{
        sessionStorage.removeItem("callerDetails");
        IsCallerDetails.next(false);
        window.close();
        // history.push(ROUTES_CONSTANTS.PATIENT_APPOINTMENT);
        window.history.go(-1);
        IsPathChange.next(true);
      }
    }
  });

 
}
