import React, { useState , useEffect } from "react";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import { authentication } from "../../../redux/action";
import BookAppWithCardPayment from "../findDoctorsNearBy/BookAppWithCard.js/BookAppWithCardPayment";
import UpgradePayment from "../findDoctorsNearBy/UpgradePayment";

function LaboratoryBill() {
  let location = useLocation();
  const dispatch = useDispatch();
  const [Tax, setTax] = useState(0);
  const [PaymentModal, setPaymentModal] = useState(false);
  const [taxData, setTaxData] = useState({});
  const [taxPrice, setTaxPrice] = useState(0);
  // const [LabBookingData, setLabBookingData] = useState({});
  const [LabPrice, setLabPrice] = useState("");
  const [labBookingToggle, setlabBookingToggle] = useState(false);
  const getPatientTax = async ()=> dispatch(authentication.getPatientTaxAction());
  const getDoctorTax = async ()=> dispatch(authentication.getDoctorTaxAction());
  console.log("location ====>>> ",location)

  useEffect(() => {
    getPatientDocTaxApi();

  }, []);

  const getSessionStorageValue = (key) => {
    const stored = sessionStorage.getItem(key);
    console.log(stored);
    return JSON.parse(stored);
  };

  const getPatientDocTaxApi = async ()=>{
    var taxRes;
    if (getSessionStorageValue('userType') == '1'){
      taxRes =  await getDoctorTax();
    }else{
        taxRes =  await getPatientTax();

    }
  console.log("taxRes ===>>> ",taxRes);
  let result = taxRes?.data?.data;
  if ((taxRes.status === 200 )&& result && result?.value ){
    setTaxData(result);
    setTax(result?.value);
   let taxpriceVal =   result?.taxType != "PERCENTAGE" ? result?.value : (location?.state[0][0]?.LabId?.packageDetail?.price * (result?.value/100)).toFixed(2)
   setTaxPrice(taxpriceVal)
   console.log("taxPrice",taxpriceVal);
  }
}
  const handleProceed = () => {
    setLabPrice(location?.state[0][0]?.LabId?.packageDetail?.price + Number(taxPrice) - location?.state[0][0]?.LabId?.packageDetail?.discounted_price);
    setlabBookingToggle(true);
    sessionStorage.setItem("LabBookingData", JSON.stringify(location?.state));
    // setLabBookingData(location?.state);
    setPaymentModal(!PaymentModal);
  };
  return (
    <div className="col-md-7 col-lg-8 col-xl-9 bill-details">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Bill Details</h4>
        </div>
        <div className="card-body">
          <div className="card ">
            <div className="card-body">
              <div className="profile-info-widget">
                <a href="#">
                  <img src={location?.state[0][0]?.LabId?.packageDetail?.image} className="img-fluid" alt="User Image" />
                </a>
                <div className="patient-info color-grey d-flex align-items-center ml-5">
                  <div>
                    <h4>
                      ${location?.state[0][0]?.LabId?.packageDetail?.price}&nbsp;
                      {location?.state[0][0]?.LabId?.packageDetail?.name}
                    </h4>
                    <span>${location?.state[0][0]?.LabId?.packageDetail?.discounted_price} Discounted Price</span>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="invoice-item invoice-table-wrap">
            <div className="row">
              <div className="col-md-12">
                <div className="table-responsive">
                  <table className="invoice-table table table-bordered">
                    <thead>
                      <tr>
                        <th>Description</th>
                        {/* <th className="text-center">Quantity</th> */}
                        <th className="text-right">Total</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>{location?.state[0][0]?.LabId?.packageDetail?.name}</td>
                        {/* <td className="text-center">2</td> */}
                        <td className="text-right">{`${location?.state[0][0]?.LabId?.packageDetail?.price - location?.state[0][0]?.LabId?.packageDetail?.discounted_price}`}</td>
                      </tr>

                      <tr>
                        <td>Tax </td>
                        {/* <td className="text-center"></td> */}
                        <td className="text-right">{taxPrice}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-6 col-xl-4 ml-auto">
                <div className="table-responsive">
                  <table className="invoice-table-two table">
                    <tbody>
                      <tr>
                        <th>Subtotal:</th>
                        <td>
                          <span>${location?.state[0][0]?.LabId?.packageDetail?.price + Number(taxPrice)}</span>
                        </td>
                      </tr>
                      {/* <tr>
                        <th>Coupon:</th>
                        <td>
                          <span>$2</span>
                        </td>
                      </tr> */}
                      <tr>
                        <th>Total :</th>
                        <td>
                          <span>${location?.state[0][0]?.LabId?.packageDetail?.price + Number(taxPrice) - location?.state[0][0]?.LabId?.packageDetail?.discounted_price}</span>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <div className="text-center">
            {" "}
            <button onClick={handleProceed} className="btn btn-primary w-50 mt-5 ">
              Proceed
            </button>
          </div>
        </div>
      </div>
      <BookAppWithCardPayment PaymentModal={PaymentModal} setPaymentModal={setPaymentModal} labBookingToggle={labBookingToggle} LabPrice={LabPrice} />
    </div>
  );
}

export default LaboratoryBill;
