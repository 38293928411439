import React, { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import DoctorService from "../../../services/doctorService/DoctorService";
import { doctor } from "../../../redux/action";
import ToasterFunc from "../../common/toaster/Toaster";

function ChangePassword() {
  let dispatch = useDispatch();
  const [showPwd, setShowPwd] = useState({
    oldPwd: false,
    newPwd: false,
    ConPwd: false,
  });

  const ForgotPwd = async (payload) => dispatch(doctor.changePwdDocAction(payload));

  const formik = useFormik({
    initialValues: {
      OldPwd: "",
      NewPwd: "",
      ConPwd: "",
    },
    validationSchema: Yup.object({
      OldPwd: Yup.string()
        .required("*Required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
      NewPwd: Yup.string()
        .required("*Required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
      ConPwd: Yup.string()
        .required("*Required")
        .matches(
          /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
        .when("NewPwd", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf([Yup.ref("NewPwd")], "New password and Confirm password should be same"),
        }),
    }),
    onSubmit: (values) => {},
  });

  const sendData = {
    oldPassword: formik.values.OldPwd,
    newPassword: formik.values.NewPwd,
    confirmPassword: formik.values.ConPwd,
  };

  const handleSubmit = async (e, resetForm) => {
    if (
      formik.values.OldPwd !== "" &&
      formik.values.NewPwd !== "" &&
      formik.values.ConPwd !== "" &&
      !formik.errors.OldPwd &&
      !formik.errors.NewPwd &&
      !formik.errors.ConPwd
    ) {
      const res = await ForgotPwd(sendData);
      if (res && res.status === 200) {
        ToasterFunc("success", res.data.responseMessage);
        formik.resetForm();
      } else if (res && res.status === 400) {
        console.log("hdfhfkhdjhaj");
        ToasterFunc("error", res.data.responseMessage);
      }
    }
  };

  const handleReset = () => {
    formik.resetForm();
  };

  return (
    <div role="tabpanel" id="Change_password" className="tab-pane fade show active">
      <div className="card doc_profile">
        <div className="card-body">
          {/* <!-- Change Password Form --> */}
          <form onSubmit={formik.handleSubmit}>
            <div className="form-group pharmacy-home-slider">
              <label>Old Password</label>
              <input
                type={showPwd && showPwd.oldPwd ? "text" : "password"}
                className="form-control"
                name="OldPwd"
                {...formik.getFieldProps("OldPwd")}
              />
              <span class="eye-icon">
                <i class="fa fa-eye" onMouseUp={() => setShowPwd({ oldPwd: false })} onMouseDown={() => setShowPwd({ oldPwd: true })}></i>
              </span>
              {formik.touched.OldPwd && formik.errors.OldPwd ? <span className="validation_error">{formik.errors.OldPwd}</span> : null}
            </div>
            <div className="form-group pharmacy-home-slider">
              <label>New Password</label>
              <input
                type={showPwd && showPwd.newPwd ? "text" : "password"}
                name="NewPwd"
                {...formik.getFieldProps("NewPwd")}
                className="form-control"
              />
              <span class="eye-icon">
                <i class="fa fa-eye" onMouseUp={() => setShowPwd({ newPwd: false })} onMouseDown={() => setShowPwd({ newPwd: true })}></i>
              </span>
              {formik.touched.NewPwd && formik.errors.NewPwd ? <span className="validation_error">{formik.errors.NewPwd}</span> : null}
            </div>
            <div className="form-group pharmacy-home-slider">
              <label>Confirm Password</label>
              <input
                type={showPwd && showPwd.ConPwd ? "text" : "password"}
                name="ConPwd"
                {...formik.getFieldProps("ConPwd")}
                className="form-control"
              />
              <span class="eye-icon">
                <i class="fa fa-eye" onMouseUp={() => setShowPwd({ ConPwd: false })} onMouseDown={() => setShowPwd({ ConPwd: true })}></i>
              </span>
              {formik.touched.ConPwd && formik.errors.ConPwd ? <span className="validation_error">{formik.errors.ConPwd}</span> : null}
            </div>
            <div className="text-center btn-margin">
              <button type="submit" onClick={handleSubmit} className="btn btn-primary btn-new">
                Save
              </button>
              <button type="button" onClick={handleReset} className="btn btn-primary btn-new">
                Cancel
              </button>
            </div>
          </form>
          {/* <!-- /Change Password Form --> */}
        </div>
      </div>
    </div>
  );
}

export default ChangePassword;
