import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "reactstrap";
import { doctor } from "../../../redux/action";
import ToasterFunc from "../../common/toaster/Toaster";

const ViewBankDetail = ({ viewBankDetails, setViewBankDetails, viewData, setreloadApi }) => {
  let dispatch = useDispatch();
  const handleClose = () => {
    setViewBankDetails(false);
  };
  //   console.log(viewData);

  const withdrawAmt = async (payload) => dispatch(doctor.doctorwithdrawRequestAction(payload));
  const doctorWalletHistory = useSelector((state) => state?.doctor?.doctorWithdrawTransactionHistory);
  //   console.log("doctorWalletHistory===>", doctorWalletHistory);
  const handleSubmit = async () => {
    const res = await withdrawAmt(viewData);
    if (res && res.status == 200) {
      ToasterFunc("success", res.data.responseMessage);
      setViewBankDetails(false);
      //   window.location.reload();
      setreloadApi(true);
      console.log("200==>");
    }
  };

  return (
    <Modal isOpen={viewBankDetails} className="modal-dialog-centered modal-dialog-scrollable ">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="modal-title text-center" id="staticBackdropLabel">
            Bank Details
          </h4>
          <button type="button" className="close " aria-label="Close" data-dismiss="modal" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body p-4">
          <div class="row">
            <div class="col-lg-12 ">
              <div class="card mb-0">
                <div class="card-body">
                  <div class=" row">
                    <div class="col-lg-12 ">
                      <div class="form-group">
                        <label for="bnkname" class="mb-0">
                          Bank Name{" "}
                        </label>
                        <h4 class="color-grey">{viewData.bankName}</h4>
                      </div>
                    </div>
                    <div class="col-lg-12 ">
                      <div class="form-group">
                        <label for="bnkname" class="mb-0">
                          Account Holder Name{" "}
                        </label>
                        <h4 class="color-grey">{viewData.accountHolderName}</h4>
                      </div>
                    </div>
                    <div class="col-lg-12 ">
                      <div class="form-group">
                        <label for="bnkname" class="mb-0">
                          Account Number
                        </label>
                        <h4 class="color-grey">{viewData.accountNumber}</h4>
                        {/* ?.replace(/.(?=.{4,}$)/g, 'X') */}
                      </div>
                    </div>
                    <div class="col-lg-12 ">
                      <div class="form-group">
                        <label for="bnkname" class="mb-0">
                          Ifsc Code
                        </label>
                        <h4 class="color-grey">{viewData.ifscCode}</h4>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer justify-content-center">
          <button className="btn btn-primary" onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default ViewBankDetail;
