import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import ReasonAppointment from "./ReasonModal";

function CancelAppointment({ CancelModal, setCancelModal, UpcomingAppointsList, CancelAppId, PatSideAppId, upcomingAppointmentList, CancelLabId }) {
  const [ReasonModal, setReasonModal] = useState(false);

  const handleConfirm = () => {
    // setCancelModal(false);
    setReasonModal(!ReasonModal);
  };
  return (
    <>
      <Modal isOpen={CancelModal} className=" modal-dialog-centered">
        {/* <div className="modal fade modal-delete " id="pat-appnt-cancel" tabindex="-1" role="dialog">
      <div className="modal-dialog modal-dialog-centered " role="document"> */}
        <div className="modal-content cancel-appointment-modal p-3">
          <div className="text-right">
            <button type="button" className="dr_Modalclose payment-method-modal" onClick={() => setCancelModal(false)}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <ModalBody>
            {/* <div className="modal-body"> */}
            <div className="card mb-0">
              <div className="card-body ">
                <h4 className="text-center justify-content-center mb-2">Cancel Appointment</h4>
                <p className="color-grey text-center">are you sure want to cancel this appointment ?</p>
                <div className="d-flex justify-content-center">
                  <a className="btn view-btn  mr-2" onClick={() => setCancelModal(false)}>
                    No{" "}
                  </a>
                  <a className="btn book-btn" onClick={handleConfirm}>
                    Yes
                  </a>
                </div>
              </div>
            </div>
            {/* </div> */}
          </ModalBody>
        </div>
        {/* </div>
    </div> */}
      </Modal>
      <ReasonAppointment
        PatSideAppId={PatSideAppId}
        CancelAppId={CancelAppId}
        CancelLabId={CancelLabId}
        UpcomingAppointsList={UpcomingAppointsList}
        ReasonModal={ReasonModal}
        setReasonModal={setReasonModal}
        upcomingAppointmentList={upcomingAppointmentList}
      />
    </>
  );
}

export default CancelAppointment;
