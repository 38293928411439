import { httpService } from "..";
import ToasterFunc from "../../components/common/toaster/Toaster";
import { API_CONSTANTS } from "../../constants";

class PatientService {
  async serviceProviderTypeInPatient() {
    try {
      const response = await httpService.get(API_CONSTANTS.PATIENT_HOME_SERVICE_PROVIDER_TYPE);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async patientProfileDetails() {
    try {
      const response = await httpService.get(API_CONSTANTS.PATIENT_PROFILE_DATA);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async nearByPhysician(data) {
    try {
      const response = await httpService.post(API_CONSTANTS.NEAR_BY_PHISICIAN, data);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async homeDataPatient(data) {
    try {
      const response = await httpService.post(API_CONSTANTS.PATIENT_HOME_DATA, data);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async homeDataLaboratoryPatient(data) {
    try {
      const response = await httpService.post(API_CONSTANTS.PATIENT_LABORATORY_HOME_DATA, data);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async upcomingAppointmentPatient() {
    try {
      const response = await httpService.get(API_CONSTANTS.PATIENT_UPCOMING_APPOINTMENT);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async previousAppointmentPatient() {
    try {
      const response = await httpService.get(API_CONSTANTS.PATIENT_PREVIOUS_APPOINTMENT);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async viewAppointmentPatient(PatId) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.PATIENT_VIEW_APPOINTMENT}/${PatId}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async changePassword(payload) {
    try {
      const response = await httpService.post(API_CONSTANTS.PATIENT_CHANGE_PASSWORD, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async editProfileInPatsettings(payload) {
    try {
      const response = await httpService.post(API_CONSTANTS.PATIENT_EDIT_PROFILE_IN_SETTINGS, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async addCardInPatsettings(payload) {
    try {
      const response = await httpService.post(API_CONSTANTS.PATIENT_ADD_CARD_IN_SETTINGS, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async cardListInPatsettings(DataLimit, selectedPage) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.PATIENT_CARDLIST_IN_SETTINGS}?pageNumber=${selectedPage}&limit=${DataLimit}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async cardListInPat() {
    try {
      const response = await httpService.get(API_CONSTANTS.PATIENT_CARDLIST_IN_SETTINGS);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async deleteCardInPatsettings(deleteId) {
    try {
      const response = await httpService.delete(`${API_CONSTANTS.PATIENT_DELETE_CARD_IN_SETTINGS}/${deleteId}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async editCardInPatsettings(payload) {
    try {
      const response = await httpService.post(API_CONSTANTS.PATIENT_EDIT_CARD_IN_SETTINGS, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async patientPrivacyPolicy() {
    try {
      const response = await httpService.get(API_CONSTANTS.PATIENT_PRIVACY_POLICY);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async patientTermsAndCondition() {
    try {
      const response = await httpService.get(API_CONSTANTS.PATIENT_TERMS_CONDITIONS);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async patientLogOut() {
    try {
      const response = await httpService.post(API_CONSTANTS.PATIENT_LOGOUT);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async patientSubscriptionInWeb() {
    try {
      const response = await httpService.get(API_CONSTANTS.PATIENT_SUBSCRIPTION_IN_WEB);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async patientWalletInWeb() {
    try {
      const response = await httpService.get(API_CONSTANTS.PATIENT_WALLET_DETAIL);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async patientRescheduleRequestListInWeb() {
    try {
      const response = await httpService.get(API_CONSTANTS.PATIENT_RESCHEDULED_LIST);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async patientAcceptRescheduleRequest(payload) {
    try {
      const response = await httpService.post(API_CONSTANTS.PATIENT_ACCEPT_RESCHEDULED_REQUEST, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async patientRejectRescheduleRequest(payload) {
    try {
      const response = await httpService.post(API_CONSTANTS.PATIENT_REJECT_RESCHEDULED_REQUEST, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async patientSecondarySpecialityList(payloadId) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.PATIENT_SECONDARY_SPE_LIST}?primary_specialtyId=${payloadId}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async patientMedicalRecordsList(selectedPage = 1, DataLimit = 10) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.PATIENT_MEDICAL_RECORDS}?pageNumber=${selectedPage}&limit=${DataLimit}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async patientPrescriptionList(Id, DataLimit, selectedPage) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.PATIENT_PRESCRIPTION_LIST}?serviceProviderId=${Id}&pageNumber=${selectedPage}&limit=${DataLimit}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async patientPrescriptionView(Id) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.PATIENT_PRESCRIPTION_VIEW}/${Id}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async setFav(payload) {
    try {
      const response = await httpService.post(API_CONSTANTS.SET_FAV, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async getFavList(payload) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.PATIENT_GET_FAV_LIST}pageNumber=${payload.number}&limit=${payload.limit}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async bookAppointmentWithCard(payload) {
    try {
      const response = await httpService.post(API_CONSTANTS.PATIENT_BOOK_APPONTMENT_WITH_CARD, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async addReview(payload, isEdit) {
    try {
      const response = await httpService.post(isEdit ? API_CONSTANTS.PATIENT_EDIT_RATINGS : API_CONSTANTS.PATIENT_ADD_RATINGS, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async viewLaboratoryDetail(Id) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.PATIENT_VIEW_LABORATORY_DETAIL}/${Id}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async getLabUpcomingList() {
    try {
      const response = await httpService.get(`${API_CONSTANTS.PATIENT_UPCOMING_LABORATORY_APPOINTMENT}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async getLabPreviousList() {
    try {
      const response = await httpService.get(`${API_CONSTANTS.PATIENT_PREVIOUS_LABORATORY_APPOINTMENT}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async getMedicalRecordList(payload) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.PATIENT_MEDICAL_RECORD_LIST}?serviceProviderId=${payload.id}&pageNumber=${payload.pageNumber}&limit=${payload.limit}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async patientMedicalRecordList(DocId) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.PATIENT_MEDICAL_RECORDS_LIST}?serviceProviderId=${DocId}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async patientinvoicelist(DocId, DataLimit, selectedPage) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.PATIENT_INVOICE_LIST}?serviceProviderId=${DocId}&pageNumber=${DataLimit}&limit=${selectedPage}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async patientinvoiceView(InvoiceId) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.PATIENT_INVOICE_VIEW}/${InvoiceId}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async patientinvoicelistFull(DataLimit, selectedPage) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.PATIENT_INVOICE_BILL_LIST}?pageNumber=${selectedPage}&limit=${DataLimit}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async patientHelplist() {
    try {
      const response = await httpService.get(API_CONSTANTS.PATIENT_HELP_LIST);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async patientSubmitHelpRequest(payload) {
    try {
      const response = await httpService.post(API_CONSTANTS.PATIENT_SUBMIT_HELP_REQUEST, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async patientViewHelp(HelpId) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.PATIENT_VIEW_HELP}/${HelpId}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async patientViewHelp(HelpId) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.PATIENT_VIEW_HELP}/${HelpId}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async patientReplyOnRequest(payload) {
    try {
      const response = await httpService.post(API_CONSTANTS.PATIENT_REPLY_ON_REQUEST, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async patientReportList(DataLimit, selectedPage) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.PATIENT_REPORT_LIST}?pageNumber=${selectedPage}&limit=${DataLimit}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async patientUpgradeSubscriptionFromWallet(payload) {
    try {
      const response = await httpService.post(API_CONSTANTS.PATIENT_WALLET_PAYMENT, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
}

export default new PatientService();
