import { useEffect, useState } from "react";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { notificationAction, patient } from "../../../../redux/action";

function PatientNotfications() {
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const getNotificationList = async (pageNumber, limit) => dispatch(notificationAction.getNotificationList(pageNumber, limit));

  const notificationList = useSelector((state) => state.notification.notificationList);
  console.log("notificationList", notificationList);

  const deleteNoti = async (id) => dispatch(notificationAction.deleteNotificationAction(id));
  const PatientProfileInfo = async () => dispatch(patient.patientProfileDetailsAction());

  useEffect(() => {
    window.scrollTo(0, 0);
    callNotification();

  }, [pageNumber]);

  

  const callNotification = async () => {
   await getNotificationList(pageNumber, limit);
   await PatientProfileInfo();
  };

  const handleDeleteNotification = async (item) => {
    const res = await deleteNoti(item.target.id);

    if (res.status == 200) callNotification();
  };

  const renderPagination = () => {
    const pagination = [];
    for (let i = 0; i < Math.ceil(notificationList?.totalList / limit); i++) {
      pagination.push(
        <li onClick={() => setPageNumber(i + 1)} className={`paginate_button page-item ${pageNumber === i + 1 && "active"}`}>
          <div aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0" className="page-link">
            {i + 1}
          </div>
        </li>
      );
    }
    return pagination;
  };

  // console.log("notificationList", moment(notificationList.notificationData[0].createdAt).format("HH:MM A"));
  // console.log(new Date(notificationList && notificationList.notificationData && notificationList.notificationData[0].createdAt).toLocalTimeString());
  // const localDate = console.log("localDate", localDate);
  return (
    <div class=" col-lg-8 col-xl-9 content_ipad_p" onScroll={(e) => console.log(e)}>
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Notifications</h4>
        </div>
        <div class="card-body" onScroll={(e) => console.log(e)}>
          {notificationList?.notificationData?.length > 0 ? (
            notificationList?.notificationData?.map((item) => {
              return (
                <div class="card">
                  <div class="card-body">
                    <div class="row">
                      <div class="col-md-1 notification-delete-icon">
                        <div>
                          <span>
                            <i onClick={handleDeleteNotification} id={item._id} class="fa fa-trash fa-lg" data-toggle="modal" data-target="#delete_staticModel"></i>
                          </span>
                        </div>
                      </div>
                      <div class="col-md-11">
                        <span class="color-grey">{item.body}</span>
                        <div class="row mt-1">
                          <div class="col-md-6 col-6">
                            <span>{moment(item.createdAt).format("MM-DD-YYYY")}</span>
                          </div>
                          <div class="col-md-6 col-6 text-right">
                            <span>
                            {moment(item.createdAt).format("LT")}

                              {/* {new Date(notificationList.notificationData[0].createdAt).toLocaleTimeString().split(":")
                                ? `${new Date(notificationList.notificationData[0].createdAt).toLocaleTimeString().split(":")[0]}:${new Date(notificationList.notificationData[0].createdAt).toLocaleTimeString().split(":")[1]} ${
                                    new Date(notificationList.notificationData[0].createdAt).toLocaleTimeString().split(" ")[1]
                                  }`
                                : ""} */}
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })
          ) : (
            <>No Notification Available</>
          )}
        </div>
        {notificationList?.notificationData?.length > 4 && (
          <div style={{ padding: "25px" }}>
            <div className="row">
              <div className="col-sm-12 col-md-5">
                <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                  {`Showing 1 to ${notificationList?.notificationData?.length} of ${notificationList?.totalList} entries`}
                </div>
              </div>
              {notificationList && notificationList.totalList > 1 ? (
                <div className="col-sm-12 col-md-7">
                  <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                    <ul className="pagination">
                      <li
                        onClick={() => {
                          if (pageNumber !== 1) {
                            setPageNumber(pageNumber - 1);
                          }
                        }}
                        className="paginate_button page-item previous disabled"
                        id="DataTables_Table_0_previous"
                      >
                        <a href aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0" className="page-link">
                          Previous
                        </a>
                      </li>
                      {renderPagination()}
                      <li
                        onClick={() => {
                          if (pageNumber !== Math.ceil(notificationList?.totalList / limit)) {
                            setPageNumber(pageNumber + 1);
                          }
                        }}
                        className="paginate_button page-item next disabled"
                        id="DataTables_Table_0_next"
                      >
                        <a href aria-controls="DataTables_Table_0" data-dt-idx="2" tabindex="0" className="page-link">
                          Next
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default PatientNotfications;
