import React, { useEffect } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import "./style.css";
import Img from "../../../assets/img/login-banner.png";

function PageNotFount() {
  const PAGENOTFOUND = "/pagenotFound";
  const location = useLocation();
  // const { pathname } = location;
  const history = useHistory();
  useEffect(() => {
    const { pathname } = location;
    if (pathname !== PAGENOTFOUND) {
      history.push("/pagenotFound");
      window.location.reload();
    }
  }, [location]);
  return (
    <div className="error-page col-md-12">
      <div className="container">
        <div className="error-box">
          <div className="row">
            <div className="col-md-5">
              <figure>
                <img src={Img} className="img-fluid" alt="" />
              </figure>
            </div>
            <div className="col-md-7">
              <h1>404</h1>
              <h3 className="h2 mb-3">
                <i className="fas fa-exclamation-triangle"></i> Oops! Page not
                found!
              </h3>
              <p className="h4 font-weight-normal">
                The page you requested was not found.
              </p>
              <Link to="/" className="btn btn-primary">
                Back to Home
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageNotFount;
