import React, { useEffect, useState } from "react";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../../../constants";
import defaultPic from "../../../../../assets/img/default-img.png";
import ReactStars from "react-stars";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import FilterModal from "../../../FilterModal";
import CancelAppointment from "../../../../common/CancelModal/CancelModal";
import moment from "moment";
import { toast } from "react-toastify";

function UpcomingApp({ upcomingAppointmentList }) {
  const [list, setList] = useState([]);
  const [CancelModal, setCancelModal] = useState(false);
  const [PatSideAppId, setPatSideAppId] = useState("");

  let history = useHistory();

  const handleView = (id, item) => {
    history.push(`${ROUTES_CONSTANTS.PATIENT_VIEW_APPOINTMENT}/${id}`, item);
  };

  useEffect(() => {
    console.log("upcomingAppointmentList", upcomingAppointmentList);
    upcomingAppointmentList &&
      upcomingAppointmentList.map((item) => {
        let appTimestamp = new Date(item.appointmentStartTime).getTime();
        let diffwithCurrentTime = appTimestamp - new Date().getTime();
        let diffHour = diffwithCurrentTime / 1000 / 60 / 60;
        if (diffHour > 1) {
          item["rescheduleEligible"] = true;
        } else {
          item["rescheduleEligible"] = false;
        }
        return item;
      });
    setList(upcomingAppointmentList);
  }, [upcomingAppointmentList]);

  const handleSearch = (e) => {
    if (e.target.value === "") setList(upcomingAppointmentList);
    else setList(upcomingAppointmentList.filter((data) => data.serviceProviderId.fullName.toLowerCase().search(e.target.value.toLowerCase()) != -1));
  };

  const handleRescheduleApp = (Docid, AppId, eligible) => {
    console.log("eligible", eligible);
    if (eligible === true) {
      history.push(`${ROUTES_CONSTANTS.PATIENT_SIDE_RESCHEDULE_APPOINTMENT}/${AppId}`, Docid);
    } else {
      toast.error(`You have to reschedule appointment before 60 minute of appointment time`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleRescheduleApps = (Docid, AppId, eligible) => {
    console.log("eligible", eligible);
    if (eligible === true) {
    } else {
      toast.error(`You have to cancel appointment before 60 minute of appointment time`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <div className="tab-pane active" id="pat-current-appnt">
      <div className="input-social-media">
        <input type="text" className="form-control  mb-2" onChange={handleSearch} style={{ paddingLeft: "40px" }} />
        <span>
          <i className="fas fa-search appt-search-icon"></i>
        </span>
      </div>
      <Scrollbars className="appointment-height" autoHide>
        <div className="row">
          {list && list.length ? (
            list.map((item) =>
              item && item.status === "accepted" ? (
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <a>
                    <div className="card widget-profile pat-widget-profile">
                      <div className="card-body li-listing-profile">
                        <div onClick={() => handleView(item._id, item)}>
                          <div className="profile-info-widget">
                            <div className="booking-doc-img">
                              <img src={item && item.serviceProviderId && item.serviceProviderId.profilePic ? item.serviceProviderId.profilePic : defaultPic} alt="" />
                            </div>
                          </div>
                          <div className="profile-det-info text-center">
                            <h3>
                              Dr.
                              {item && item.serviceProviderId && item.serviceProviderId.fullName ? item.serviceProviderId.fullName : ""}
                            </h3>
                          </div>
                          <div className="patient-info color-grey">
                            <div className="color-grey">
                              <b>
                                {'$'}{item && item.amount ? item.amount : ""} {' '}
                                Consultation Fee
                              </b>
                            </div>
                            <div className="color-gray info__box">
                              <span>Date : {moment(item && item.appointmentStartTime).format("MM/DD/YY")} </span>
                              <span>
                                Time : {moment(item && item.appointmentStartTime).format("LT")} - {moment(item && item.appointmentEndTime).format("LT")}
                              </span>
                              {item && item.serviceProviderId && item.serviceProviderId.experience_in_years ? `${item.serviceProviderId.experience_in_years} Years  ` : ""}
                              {item && item.serviceProviderId && item.serviceProviderId.experience_in_months ? `${item.serviceProviderId.experience_in_months} Months` : ""} Of Experience
                            </div>

                            <div className="color-gray">{item?.serviceProviderId?.primary_specialtyId?.primary_specialty}</div>
                            <div className=" rating ">
                              <ReactStars edit={false} count={5} value={item?.serviceProviderId?.rating} size={16} style={{ selfAlign: "center" }} color2={"#ffd700"} />
                            </div>
                          </div>
                        </div>
                        <div className="row mt-3">
                          <div className="col-md-12 mb-2">
                            <a className="btn book-btn" onClick={() => handleRescheduleApp(item.serviceProviderId._id, item._id, item?.rescheduleEligible)}>
                              Reschedule Appointment
                            </a>
                          </div>
                          <div className="col-md-12 mb-2">
                            <a
                              className="btn view-btn"
                              onClick={() => {
                                item?.rescheduleEligible == false ? handleRescheduleApps(item.serviceProviderId._id, item._id, item?.rescheduleEligible) : setCancelModal(!CancelModal);
                                setPatSideAppId(item._id);
                              }}
                            >
                              Cancel Appointment
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              ) : (
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <a>
                    <div className="card widget-profile pat-widget-profile">
                      <div className="card-body li-listing-profile">
                        <div onClick={() => handleView(item._id, item)}>
                          <div className="profile-info-widget">
                            <div className="booking-doc-img">
                              <img src={item && item.serviceProviderId && item.serviceProviderId.profilePic ? item.serviceProviderId.profilePic : defaultPic} alt="" />
                            </div>
                          </div>
                          <div className="profile-det-info text-center">
                            <h3>
                              Dr.
                              {item && item.serviceProviderId && item.serviceProviderId.fullName ? item.serviceProviderId.fullName : ""}
                            </h3>
                          </div>
                          <div className="patient-info color-grey">
                            <div className="color-grey">
                              <b>
                                {'$'}{item && item.amount ? item.amount : ""}{' '}
                                Consultation Fee
                              </b>
                            </div>
                            <div className="color-gray info__box">
                              <span>Date : {moment(item && item.appointmentStartTime).format("MM/DD/YY")} </span>
                              <span>
                                Time : {moment(item && item.appointmentStartTime).format("LT")} - {moment(item && item.appointmentEndTime).format("LT")}
                              </span>
                              {item && item.serviceProviderId && item.serviceProviderId.experience_in_years ? `${item.serviceProviderId.experience_in_years} Years  ` : ""}
                              {item && item.serviceProviderId && item.serviceProviderId.experience_in_months ? `${item.serviceProviderId.experience_in_months} Months` : ""}
                              Of Experience
                            </div>

                            <div className="color-gray">Orthodentist</div>
                            <div className=" rating ">
                              <ReactStars
                                // edit={false}
                                // count={item && item.serviceProviderId && item.serviceProviderId.rating ? item.serviceProviderId.rating : ""}
                                // value={item && item.serviceProviderId && item.serviceProviderId.rating ? item.serviceProviderId.rating : ""}
                                // size={16}
                                // color2={"#ffd700"}
                                edit={false} count={5} value={item?.serviceProviderId?.rating} size={16} style={{ selfAlign: "center" }} color2={"#ffd700"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="cancelledAppoint">
                          <span>
                            <i class="fa fa-times-circle mr-1"></i>
                          </span>
                          Cancelled
                        </div>
                        {/* <div className="row mt-3">
                          <div className="col-md-12 mb-2">
                            <a className="btn book-btn" onClick={() => handleRescheduleApp(item.serviceProviderId._id, item._id)}>
                              Reschedule Appointment
                            </a>
                          </div>
                          <div className="col-md-12 mb-2">
                            <a
                              className="btn view-btn"
                              onClick={() => {
                                setCancelModal(!CancelModal);
                                setPatSideAppId(item._id);
                              }}
                            >
                              Cancel Appointment
                            </a>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </a>
                </div>
              )
            )
          ) : (
            <div className="col-md-12">
              <p className="text-center">There are currently no appointments available.</p>
            </div>
          )}
        </div>
      </Scrollbars>
      {CancelModal && <CancelAppointment CancelModal={CancelModal} setCancelModal={setCancelModal} PatSideAppId={PatSideAppId} upcomingAppointmentList={() => upcomingAppointmentList()} />}
    </div>
  );
}

export default UpcomingApp;
