import React, { useEffect, useState } from "react";
import AddMedicalRecords from "./AddMedicalRecords";
import ViewMedicalRecords from "./ViewMedicalRecords";
import DeleteMedicalRecord from "./DeleteMedicalRecord";
import { useDispatch, useSelector } from "react-redux";
import { doctor } from "../../../../redux/action";
import PaginationComponent from "react-reactstrap-pagination";
import moment from "moment";

const MedicalRecords = (props) => {
  const { Patid } = props;
  const dispatch = useDispatch();
  const getMedicalRecord = async (Patid, pageNumber) => dispatch(doctor.getMedicalRecordAction(Patid, pageNumber));

  const medicalListData = useSelector((state) => state?.doctor?.medicalListData);
  const [MedicalModal, setMedicalModal] = useState(false);
  const [ViewMedicalModal, setViewMedicalModal] = useState(false);
  const [DeleteModal, setDeleteModal] = useState(false);
  const [pageNumber, setPageNumber] = useState(1);
  const [slectedRecord, setSelectedRecord] = useState(null);

  useEffect(() => {
    callApi();
  }, []);

  useEffect(() => {
    callApi();
  }, [pageNumber]);

  const callApi = () => {
    getMedicalRecord(Patid, pageNumber);
  };
  return (
    <>
      <div role="tabpanel" id="Medica-r" className="tab-pane fade show active">
        {console.log(medicalListData)}
        <div className="row mb-1">
          <div className="col-md-12">
            <div className="text-right">
              <button
                className="add-new-btn"
                onClick={() => {
                  setSelectedRecord(null);
                  setMedicalModal(!MedicalModal);
                }}
              >
                <i className="fas fa-plus-square mr-1"></i> Add Medical Record
              </button>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12">
            <div className="card card-table mb-0">
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-center mb-0">
                    <thead>
                      <tr>
                        <th className="text-left">ID</th>
                        <th> Date</th>
                        <th>Name</th>
                        <th className="text-right">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {medicalListData?.data?.map((medicalData) => {
                        return (
                          <tr>
                            <td className="text-left">{medicalData._id}</td>
                            <td>{moment(medicalData.createdAt).format("DD-MM-YYYY")}</td>
                            <td>{medicalData.name}</td>
                            <td className="text-right">
                              <div className="table-action-btn">
                                <button
                                  href="javascript:void(0);"
                                  className="btn btn-sm bg-info-light"
                                  onClick={() => {
                                    setSelectedRecord(medicalData);
                                    setViewMedicalModal(!ViewMedicalModal);
                                  }}
                                >
                                  <i className="far fa-eye"></i>
                                </button>
                                <button
                                  href="javascript:void(0);"
                                  className="btn btn-sm bg-success-light"
                                  onClick={() => {
                                    setSelectedRecord(medicalData);
                                    setMedicalModal(!MedicalModal);
                                  }}
                                >
                                  <i className="fas fa-pencil-alt"></i>
                                </button>
                                {/* <button
                                  href="javascript:void(0);"
                                  className="btn btn-sm bg-danger-light"
                                  onClick={() => {
                                    setSelectedRecord(medicalData);
                                    setDeleteModal(!DeleteModal);
                                  }}
                                >
                                  <i className="fas fa-trash-alt"></i>
                                </button> */}
                                {/* <button
                                  href="javascript:void(0);"
                                  className="btn btn-sm bg-primary-light"
                                >
                                  <i className="fas fa-print"></i>
                                </button> */}
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>

        <AddMedicalRecords MedicalModal={MedicalModal} setMedicalModal={setMedicalModal} Patid={Patid} callApi={callApi} slectedRecord={slectedRecord} />
        <ViewMedicalRecords setViewMedicalModal={setViewMedicalModal} ViewMedicalModal={ViewMedicalModal} slectedRecord={slectedRecord} />
        <DeleteMedicalRecord DeleteModal={DeleteModal} setDeleteModal={setDeleteModal} Patid={Patid} callApi={callApi} slectedRecord={slectedRecord} />
      </div>
      {medicalListData?.totalList > 10 && (
        <PaginationComponent totalItems={medicalListData && medicalListData?.totalList} pageSize={10} maxPaginationNumbers={Math.ceil(medicalListData && medicalListData?.totalList / 10)} defaultActivePage={1} onSelect={(e) => setPageNumber(e)} />
      )}
    </>
  );
};

export default MedicalRecords;
