import React, { useEffect, useState } from "react";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../../../constants";
import FilterModal from "../../../FilterModal";
import { useDispatch, useSelector } from "react-redux";
import { patient } from "../../../../../redux/action";
import { Scrollbars } from "react-custom-scrollbars";
import ReactStars from "react-stars";
import { useHistory } from "react-router-dom";
import moment from "moment";

function PreviousApp({ previousAppointmentList }) {
  let history = useHistory();
  const [list, setList] = useState([]);

  useEffect(() => {
    setList(previousAppointmentList);
  }, [previousAppointmentList]);

  const handleSearch = (e) => {
    if (e.target.value === "") setList(previousAppointmentList);
    else setList(previousAppointmentList?.filter((data) => data?.serviceProviderId?.fullName?.toLowerCase().search(e.target.value.toLowerCase()) != -1));
  };

  const handleView = (id, item) => {
    history.push(`${ROUTES_CONSTANTS.PATIENT_COMPLETED_VIEW_APPOINTMENT}/${id}`, item);
  };

  const handleScheduleAppointment = (id) => {
    history.push(`${ROUTES_CONSTANTS.PATIENT_BOOK_APPOINTMENT}/${id}`);
  };
  return (
    <div class="tab-pane active" id="pat-previous-appnt">
      <div class="input-social-media">
        <input type="text" class="form-control  mb-2" style={{ paddingLeft: "40px" }} onChange={handleSearch} />
        <span>
          <i class="fas fa-search appt-search-icon"></i>
        </span>
      </div>
      <Scrollbars className="appointment-height" disableHorizontalScrolling autoHide>
        <div class="row">
          {list && list.length ? (
            list.map(
              (item) =>
                item &&
                item.status === "accepted" && (
                  <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="card widget-profile pat-widget-profile">
                      <div class="card-body li-listing-profile">
                        <a onClick={() => handleView(item._id, item)}>
                          <div class="profile-info-widget">
                            <div class="booking-doc-img">
                              <img src={item?.serviceProviderId?.profilePic} alt="" />
                            </div>
                          </div>

                          <div class="profile-det-info text-center">
                            <h3>Dr. {item?.serviceProviderId?.fullName}</h3>
                          </div>
                        </a>
                        <div class="patient-info color-grey ">
                          <div class="color-grey">
                            <b>${item?.amount} Consultation Fee</b>
                          </div>
                          <div class="color-gray info__box">
                            <span>Date : {moment(item && item.appointmentStartTime).format("MM/DD/YY")} </span>
                            <span>
                              Time : {moment(item && item.appointmentStartTime).format("LT")} - {moment(item && item.appointmentEndTime).format("LT")}
                            </span>
                            {item && item.serviceProviderId && item.serviceProviderId.experience_in_years ? `${item.serviceProviderId.experience_in_years} Years  ` : ""}
                            {item && item.serviceProviderId && item.serviceProviderId.experience_in_months ? `${item.serviceProviderId.experience_in_months} Months` : ""} Of Experience
                          </div>
                          <div class="color-gray">{item?.serviceProviderId?.primary_specialtyId?.primary_specialty}</div>
                          <div class=" rating ">
                            <ReactStars edit={false} count={5} value={item?.serviceProviderId?.rating} size={16} style={{ selfAlign: "center" }} color2={"#ffd700"} />
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-md-12 mb-2 ">
                            <div class="completedAppoint">
                              <span>
                                <i class="fa fa-check-circle mr-1"></i>
                              </span>
                              Completed
                            </div>
                            <a class="btn book-btn" onClick={() => handleScheduleAppointment(item.serviceProviderId._id)}>
                              Schedule Appointment
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
            )
          ) : (
            <div className="col-md-12">
              <p className="text-center">There are currently no appointments available.</p>
            </div>
          )}
        </div>
      </Scrollbars>
    </div>
  );
}
export default PreviousApp;
