import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import Slider from "react-slick";
import { ROUTES_CONSTANTS } from "../../../constants";
import { patient } from "../../../redux/action";
import { useHistory } from "react-router-dom";

function LaboratiesPackages() {
  let history = useHistory();
  let { LabId } = useParams();
  let dispatch = useDispatch();
  useEffect(() => {
    viewLaboratoryDetail(LabId);
  }, [LabId]);

  const viewLaboratorydata = useSelector((state) => state?.patient?.patientLaboratoryDetail);
  console.log("viewLaboratorydata", viewLaboratorydata);
  const viewLaboratoryDetail = async (LabId) => dispatch(patient.viewLaboratoryDetailAction(LabId));
  const spec = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    centerMode: false,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className=" col-lg-8 col-xl-9">
      <div className="card">
        {/* <!-- <div className="card-header">
                                <h4 >Find Out Laborateries For Health Checkups</h4>

                            </div> --> */}
        <div className="card-body ">
          {/* <!-- Doctor Widget --> */}
          <div className="card widget-profile pat-widget-profile bg-clr">
            <div className="card-body">
              <div className="row">
                <div className="col-md-7 col-lg-7 d-flex align-items-center justify-content-center">
                  <div className="patient-info color-grey">
                    <div>
                      <h2>
                        <b>{viewLaboratorydata?.laboratoryDetails?.name}</b>
                      </h2>
                    </div>
                    {/* <div className="color-gray"> Opening Time : 10:00am-10:00pm</div> */}
                  </div>
                </div>
                <div className="col-md-5 col-lg-5">
                  <div className="profile-info-widget ">
                    <div className="laboratory-img">
                      <img src={viewLaboratorydata?.laboratoryDetails?.image} className="img-fluid" alt="User Image" />
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- /Doctor Widget --> */}
            </div>
          </div>

          {/* <!-- carousal  --> */}
          <div className="row">
            <div className="col-md-12">
              <h4 className="mb-3 mt-3">Laborateries Packages</h4>

              <section className="section mb-2 custom-card">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="doctor-slider recommendationSlide slider">
                      <Slider {...spec}>
                        {viewLaboratorydata?.packageList?.length ? (
                          viewLaboratorydata.packageList.map((item) => (
                            <div className="doctor-slider slider">
                              {/* <!-- Doctor Widget --> */}
                              <div className="card widget-profile pat-widget-profile">
                                <div className="card-body">
                                  <div className="pro-widget-content">
                                    <div className="profile-info-widget">
                                      <a href="#" className="booking-doc-img">
                                        <img src={item?.image} alt="User Image" />
                                      </a>
                                      <div className="profile-det-info">
                                        <h3>{item?.name} </h3>
                                        <div>
                                          <h5 className="color-grey "> ${item?.discounted_price} Discounted Price</h5>
                                        </div>
                                        <div className="patient-details">
                                          <h5>${item?.price}-Charges</h5>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="color-grey desc-clamp">{item?.description}</div>
                                  <a onClick={() => history.push({ pathname: ROUTES_CONSTANTS.PATIENT_PATIENT_DETAIL_LAB, state: { labId: LabId, packageId: item._id, packageDetail: item } })} className="btn book-btn mt-4">
                                    Book Now
                                  </a>
                                </div>
                              </div>
                              {/* <!-- /Doctor Widget --> */}
                            </div>
                          ))
                        ) : (
                          <div className="col-md-12">
                            <p className="text-center">There are currently no Packages available.</p>
                          </div>
                        )}
                      </Slider>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

          {/* <!-- rating and reviews  --> */}
          {/* <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-header">
                  <h4>Ratings and Reviews</h4>
                </div>
                <div className="card-body doc-review review-listing">
                  <div className="card mb-2">
                    <div className="card-body">
                      <div className="comment d-flex">
                        <div className="text-center">
                          <img className="avatar rounded-circle mb-3" alt="User Image" src="assets/img/patients/patient5.jpg" />
                          <span className="comment-author text-nowrap">Gina Moore</span>
                        </div>
                        <div className="comment-body ml-3">
                          <div className="meta-data d-flex">
                            <div className=" rating">
                              <span className="mr-3">5</span>
                              <i className="fas fa-star filled"></i>
                              <i className="fas fa-star filled"></i>
                              <i className="fas fa-star filled"></i>
                              <i className="fas fa-star filled"></i>
                              <i className="fas fa-star filled"></i>
                            </div>
                            <div className="d-flex justify-content-end">
                              <span className="comment-date review-count color-grey">26/11/1995</span>
                            </div>
                          </div>
                          <p className="comment-content mb-0">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Curabitur non nulla sit amet nisl tempus
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="card mb-2">
                    <div className="card-body">
                      <div className="comment d-flex">
                        <div className="text-center">
                          <img className="avatar rounded-circle mb-3" alt="User Image" src="assets/img/patients/patient5.jpg" />
                          <span className="comment-author text-nowrap">Gina Moore</span>
                        </div>
                        <div className="comment-body ml-3">
                          <div className="meta-data d-flex">
                            <div className=" rating">
                              <span className="mr-3">4</span>
                              <i className="fas fa-star filled"></i>
                              <i className="fas fa-star filled"></i>
                              <i className="fas fa-star filled"></i>
                              <i className="fas fa-star filled"></i>
                            </div>
                            <div className="d-flex justify-content-end">
                              <span className="comment-date review-count color-grey">26/11/1995</span>
                            </div>
                          </div>
                          <p className="comment-content mb-0">
                            Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation. Curabitur non nulla sit amet nisl tempus
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex justify-content-center">
                    {" "}
                    <a href="">View all</a>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default LaboratiesPackages;
