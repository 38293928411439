import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../../constants";
import { useFormik } from "formik";
import * as Yup from "yup";
import { patient } from "../../../redux/action";
import { saveToSessionStorage } from "../../../utils";

function PatientDetail() {
  let history = useHistory();
  let location = useLocation();
  const [Collectionsample, setCollectionsample] = useState(false);
  const [CollectionlabLocation, setCollectionlabLocation] = useState(false);
  const [ModifiedDate, setModifiedDate] = useState("");
  const [CollectionErr, setCollectionErr] = useState("");
  const [Gender, setGender] = useState({
    male: false,
    female: false,
    other: false,
  });
  const [GenderErr, setGenderErr] = useState("");
  console.log("location", location);

  const formik = useFormik({
    initialValues: {
      PatientName: "",
      DOB: "",
      mobile: "",
      email: "",
    },
    validationSchema: Yup.object({
      PatientName: Yup.string().required("*Required.").trim(),
      DOB: Yup.string().required("*Required."),
      mobile: Yup.number().required("*Required").typeError("Only numbers are allowed"),
      email: Yup.string().email("Invalid email address").required("*Required."),
    }),
  });
  useEffect(() => {
    if (Collectionsample === true || CollectionlabLocation === true) {
      setCollectionErr("");
    }
    if (Gender.male === true || Gender.female === true || Gender.other === true) {
      setGenderErr("");
    }
  }, [Collectionsample, CollectionlabLocation, Gender]);

  const handleCollection = (e, data) => {
    let checked = e.target.checked;
    if (data === "SampleCollection") {
      setCollectionsample(checked);
    } else if (data === "LaboratoryLocation") {
      setCollectionlabLocation(checked);
    }
  };

  const handleGender = (e, data) => {
    let checked = e.target.checked;
    if (data === "male") {
      setGender({
        male: checked,
      });
    } else if (data === "female") {
      setGender({
        female: checked,
      });
    } else if (data === "others") {
      setGender({
        other: checked,
      });
    }
  };

  useEffect(() => {
    if (sessionStorage.getItem("PatientData")) {
      console.log(JSON.parse(sessionStorage.getItem("PatientData")));
      if (JSON.parse(sessionStorage.getItem("PatientData"))[0]?.gender === "Male") {
        setGender({
          male: true,
        });
      } else if (JSON.parse(sessionStorage.getItem("PatientData"))[0]?.gender === "Female") {
        setGender({
          female: true,
        });
      } else {
        setGender({
          other: true,
        });
      }

      formik.setFieldValue("PatientName", JSON.parse(sessionStorage.getItem("PatientData"))[0]?.name);
      formik.setFieldValue("DOB", JSON.parse(sessionStorage.getItem("PatientData"))[0]?.dob);
      formik.setFieldValue("mobile", JSON.parse(sessionStorage.getItem("PatientData"))[0]?.number);
      formik.setFieldValue("email", JSON.parse(sessionStorage.getItem("PatientData"))[0]?.email);

      if (JSON.parse(sessionStorage.getItem("PatientData"))[0]?.collectionCategory === "HOME") {
        setCollectionsample(true);
      } else {
        setCollectionlabLocation(true);
      }
    }
  }, [sessionStorage.getItem("PatientData")]);

  const handleSubmit = () => {
    if (Collectionsample === false && CollectionlabLocation === false) {
      setCollectionErr("*Please Select anyone");
    } else {
      setCollectionErr("");
    }
    if (Gender.male === false && Gender.female === false && Gender.other === false) {
      setGenderErr("*Please Select anyone");
    } else {
      setGenderErr("");
    }

    let patientdata = [];
    patientdata.push({
      LabId: location?.state,
      gender: Gender.male ? "Male" : Gender.female ? "Female" : Gender.other ? "Other" : "",
      name: formik.values.PatientName,
      dob: formik.values.DOB,
      number: formik.values.mobile,
      email: formik.values.email,
      collectionCategory: Collectionsample ? "HOME" : "LOCATION",
    });

    sessionStorage.setItem("PatientData", JSON.stringify(patientdata));

    if (
      (Collectionsample === true || CollectionlabLocation === true) &&
      (Gender.male === true || Gender.female === true || Gender.other === true) &&
      formik.values.PatientName != "" &&
      formik.values.DOB != "" &&
      formik.values.mobile != "" &&
      formik.values.email != ""
    ) {
      history.push(ROUTES_CONSTANTS.PATIENT_DELIVERY_LOCATION_LAB, patientdata);
    }
  };

  const minDateModifier = () => {
    var dtToday = new Date();

    var month = dtToday.getMonth() + 1;
    var day = dtToday.getDate();
    var year = dtToday.getFullYear();

    if (month < 10) month = "0" + month.toString();
    if (day < 10) day = "0" + day.toString();
    var maxDate = year + "-" + month + "-" + day;
    setModifiedDate(maxDate);
  };

  return (
    <div class="col-lg-8 col-xl-9">
      <div class="row">
        <div class="col-lg-12 ">
          <div class="card">
            <div class="card-header">
              <h4 class="card-title">Patient Details</h4>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div class="card-body">
                <div class="form-group row">
                  <div class="col-md-6 ">
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" checked={Collectionsample} id="Sample" onChange={(e) => handleCollection(e, "SampleCollection")} />
                      <label class="form-check-label" for="Sample">
                        At Sample Collection
                      </label>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="inlineRadioOptions" checked={CollectionlabLocation} id=" Laboratory" onChange={(e) => handleCollection(e, "LaboratoryLocation")} />
                      <label class="form-check-label" for=" Laboratory">
                        At the Laboratory Location
                      </label>
                    </div>
                  </div>
                  {CollectionErr ? <div className="validation_error vali_error">{CollectionErr}</div> : null}
                </div>
                <div class="form-group row">
                  <div class="col-lg-6 ">
                    <label for="s-name">Patient Name</label>
                    <input type="text" class="form-control" id="s-name" name="PatientName" {...formik.getFieldProps("PatientName")} />
                    {formik.touched.PatientName && formik.errors.PatientName ? <span className="validation_error">{formik.errors.PatientName}</span> : null}
                  </div>

                  <div class="col-lg-6 ">
                    <label for="p-name">Date Of Birth</label>
                    <input type="date" class="form-control" id="p-name" name="DOB" max={ModifiedDate} {...formik.getFieldProps("DOB")} onClick={minDateModifier} />
                    {formik.touched.DOB && formik.errors.DOB ? <span className="validation_error">{formik.errors.DOB}</span> : null}
                  </div>
                </div>

                <div class="form-group row">
                  <div class="col-lg-6 ">
                    <label for="s-name">Mobile Number</label>
                    <input type="text" class="form-control" id="s-name" name="mobile" maxLength="10" {...formik.getFieldProps("mobile")} />
                    {formik.touched.mobile && formik.errors.mobile ? <span className="validation_error">{formik.errors.mobile}</span> : null}
                  </div>

                  <div class="col-lg-6 ">
                    <label for="p-name">Email</label>
                    <input type="text" class="form-control" id="p-name" name="email" {...formik.getFieldProps("email")} />
                    {formik.touched.email && formik.errors.email ? <span className="validation_error">{formik.errors.email}</span> : null}
                  </div>
                </div>
                <div class="form-group row">
                  <div class="col-md-2 ">
                    <div class="form-check">
                      <label class="form-check-label" for="Male">
                        Gender
                      </label>
                    </div>
                  </div>
                  <div class="col-md-3 ">
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="Gender" checked={Gender?.male} id="Male" onChange={(e) => handleGender(e, "male")} />
                      <label class="form-check-label" for="Male">
                        Male
                      </label>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="Gender" checked={Gender?.female} id="Female" onChange={(e) => handleGender(e, "female")} />
                      <label class="form-check-label" for="Female">
                        Female
                      </label>
                    </div>
                  </div>
                  <div class="col-md-3">
                    <div class="form-check">
                      <input class="form-check-input" type="radio" name="Gender" checked={Gender?.other} id="Others" onChange={(e) => handleGender(e, "others")} />
                      <label class="form-check-label" for="Others">
                        Others
                      </label>
                    </div>
                  </div>
                  {GenderErr ? <span className="validation_error vali_error">{GenderErr}</span> : null}
                </div>

                <div class="mt-4 d-flex justify-content-center">
                  {/* <!-- <a href="#" data-toggle="modal" class="btn cancel-outline  mr-2">Cancel</a> --> */}
                  <button type="submit" class="btn btn-primary w-25 " onClick={handleSubmit}>
                    Next
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatientDetail;
