import moment from "moment";
import React, { useEffect, useState } from "react";
import { Scrollbars } from "react-custom-scrollbars";
import { useDispatch, useSelector } from "react-redux";
import ReactStars from "react-stars";
import { patient } from "../../../../../redux/action";

const LabPreviousApp = () => {
  let dispatch = useDispatch();
  const [list, setList] = useState("");
  useEffect(() => {
    labPreviousData();
  }, []);
  const labPreviousData = async () => dispatch(patient.labPreviousListAction());
  const labPreviousAppointmentList = useSelector((state) => state?.patient?.patientLabPreviousList?.appointmentData);
  useEffect(() => {
    setList(labPreviousAppointmentList);
  }, [labPreviousAppointmentList]);

  console.log("Previous==>", labPreviousAppointmentList);
  const handleSearch = (e) => {
    if (e.target.value === "") setList(labPreviousAppointmentList);
    else setList(labPreviousAppointmentList.filter((data) => data.laboratoryId.name.toLowerCase().search(e.target.value.toLowerCase()) != -1));
  };

  return (
    <>
      <div class="tab-pane active" id="pat-previous-appnt">
        <div class="input-social-media">
          <input type="text" class="form-control  mb-2" onChange={handleSearch} style={{ paddingLeft: "40px" }} />
          <span>
            <i class="fas fa-search appt-search-icon"></i>
          </span>
        </div>
        <Scrollbars className="appointment-height" autoHide>
          <div class="row">
            {list && list.length ? (
              list.map((item, i) => (
                <div class="col-lg-6 col-md-6 col-sm-6">
                  <div class="card widget-profile pat-widget-profile">
                    <div class="card-body li-listing-profile">
                      <div className="row">
                        <div className="col-md-3">
                          <div class="profile-info-widget">
                            <div class="booking-doc-img">{item && item.laboratoryId && item.laboratoryId.image ? <img src={item && item.laboratoryId && item.laboratoryId.image} alt="" className="img-fluid" /> : ""}</div>
                          </div>
                        </div>
                        <div className="col-md-9 pl-0">
                          <div class="profile-det-info">
                            <h3>{item && item.laboratoryId && item.laboratoryId.name}</h3>
                            <p>Date : {moment(item && item.appointmentStartTime).format("MM/DD/YYYY")}</p>
                            <p>
                              Time : {moment(item && item.appointmentStartTime).format("LT")} - {moment(item && item.appointmentEndTime).format("LT")}
                            </p>
                          </div>
                          <div class="color-grey">
                            <b>Package :</b> {item && item.packageId && item.packageId.name}
                          </div>
                        </div>
                        <div className="col-md-12">
                          <p>
                            <b>Description :</b> {item && item.packageId && item.packageId.description}
                          </p>
                        </div>
                        <div className="col-md-12">
                          <div className="lab_button">
                            {/* <div className="row">
                                              <div className="offset-4 col-4">
                                              <a className='btn book-btn'>Add Rating</a>
                                              </div>
                                              
                                          </div> */}
                          </div>
                        </div>
                      </div>

                      {/* <div class="row">
                                     <div class="col-md-12 mb-2 ">
                                         <div class="completedAppoint">
                                             <span>
                                                 <i class="fa fa-check-circle mr-1"></i>
                                             </span>
                                             Completed
                                         </div>
                                         <a class="btn book-btn" >
                                             Schedule Appointment
                                         </a>
                                     </div>
                                 </div> */}
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-md-12">
                <p className="text-center">There are currently no appointments available.</p>
              </div>
            )}
          </div>
        </Scrollbars>
      </div>
    </>
  );
};

export default LabPreviousApp;
