import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../constants";
import { doctor } from "../../redux/action";
import {Link,useHistory} from 'react-router-dom'
import moment from "moment";

function Help() {
    let dispatch = useDispatch()
    let history = useHistory()

    const helpRequestListData = async () => dispatch(doctor.docHelpRequestListAction())
    useEffect(() => {
        window.scrollTo(0, 0);
        helpRequestListData()
    }, [])
    const helpRequestList = useSelector(state => state?.doctor?.doctorHelpRequestListData?.helpData)
    console.log('Help List', helpRequestList);

    const handleViewRequest =(id) =>{
    history.push(`${ROUTES_CONSTANTS.DOCTOR_VIEW_HELP_REQUEST}/${id}`)

}


    return (
        <>
            <div className=" col-lg-8 col-xl-9 content_ipad_p help">
                <div className="card  footer-gap">
                    <div className="card-header">
                        <div className="row align-button">
                            <div className="col-lg-9 col-md-6">
                                <h4>Help</h4>
                            </div>
                            <div className="col-lg-3 col-md-6">
                                <div className="text-right">
                                    <Link to={ROUTES_CONSTANTS.DOCTOR_HELP_NEW} className="add-new-btn">
                                        Submit New request
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="card-body">
                        {helpRequestList && helpRequestList.length ? helpRequestList.map((item, i) =>
                            <div className="card pointer" onClick={()=>handleViewRequest(item._id)} key={i}>
                                <div className="card-body">
                                    <div className="help-query d-flex justify-content-between align-items-center">
                                        <div className="align-items-center">
                                            <span className=""><b>{item.subject}</b></span>
                                        </div>
                                        <div>
                                        
                                            <span className="default-content-clr">{moment(item.createdAt).format("h:mm A")}</span>
                                        </div>
                                    </div>
                                    <span className="default-content-clr">{item.message}</span>
                                </div>
                            </div>
                        ) : <p>Data not found!</p>}
                        {/* <div className="card">
                            <div className="card-body">
                                <div className="help-query d-flex justify-content-between align-items-center">
                                    <div className="d-flex align-items-center"> <img
                                            className="avatar rounded-circle" alt=""
                                            src={IMAGE_CONSTANT.PAT_PROFILE}/>
                                        <span className="ml-2"><b>Ashlers Gardner</b></span>
                                    </div>
                                    <div>
                                        <span className="default-content-clr">2:30 P.M</span>
                                    </div>
                                </div>
                                <span className="default-content-clr">Lorem ipsum dolor sit
                                    amet,
                                    consectetur adipisicing elit. Officiis eveniet ullam
                                    pariatur est?
                                    Labore eos, harum assumenda et rem illum quidem
                                    perferendis
                                    at
                                    ullam, debitis pariatur atque quae impedit ad!</span>
                            </div>
                        </div> */}
                    </div>
                </div>
            </div>
        </>
    );
}

export default Help;
