import { httpService } from "..";
import { API_CONSTANTS } from "../../constants";

class CommonServices {
  async commonProfilePic(data) {
    try {
      const response = await httpService.post(API_CONSTANTS.COMMON_PROFILE_PIC, data);
      return response;
    } catch (err) {
      return err.response;
    }
  }

  async commonUploadedFile(data) {
    try {
      const response = await httpService.post(API_CONSTANTS.COMMON_PROFILE_PIC, data);
      return response;
    } catch (err) {
      return err.response;
    }
  }

  async setPrimaryCard(cardId) {
    try {
      const response = await httpService.put(`${API_CONSTANTS.COMMON_PRIMARY_CARD}?cardId=${cardId}`);
      return response;
    } catch (err) {
      return err.response;
    }
  }

  async getTwilioToken(payload) {
    try {
      const response = await httpService.post(`${API_CONSTANTS.COMMON_GET_TWILIO_TOKEN}`, payload);
      return response;
    } catch (err) {
      return err.response;
    }
  }

  async disconnectCall(payload) {
    try {
      const response = await httpService.post(`${API_CONSTANTS.COMMON_DISCONNECT_CALL}`, payload);
      return response;
    } catch (err) {
      return err.response;
    }
  }
  async updateRemainingMinute(payload) {
    try {
      const response = await httpService.post(`${API_CONSTANTS.COMMON_UPDATE_REMAINING_MINUTE}`, payload);
      return response;
    } catch (err) {
      return err.response;
    }
  }
  async getRemainingMinute() {
    try {
      const response = await httpService.get(API_CONSTANTS.COMMON_GET_REMAINING_MINUTE);
      return response;
    } catch (err) {
      return err.response;
    }
  }


  async getRefferalCode() {
    try {
      const response = await httpService.get(API_CONSTANTS.COMMON_GET_REFFERAL_CODE);
      return response;
    } catch (err) {
      return err.response;
    }
  }
  async getRefferalReportList(selectedPage, DataLimit) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.COMMON_REFFERAL_REPORT_LIST}?pageNumber=${selectedPage}&limit=${DataLimit}`);
      return response;
    } catch (err) {
      return err.response;
    }
  }
  async getSearchDoclist(payload) {
    try {
      const response = await httpService.post(`${API_CONSTANTS.COMMON_DOCTOR_LIST}`, payload);
      return response;
    } catch (err) {
      return err.response;
    }
  }
  async createRoomApi (payload , type ){
    try {
      console.log("type :::::::::::::::::: 93 ",type);
      let url ; 
      if (type ==="patient"){
        url  = `${API_CONSTANTS.PATIENT_CREATE_ROOM}`
      }else{
        url  = `${API_CONSTANTS.DOCTOR_CREATE_ROOM}`
      }
      const response = await httpService.patch(url, payload);
      return response;
    } catch (err) {
      return err.response;
    }
  }
}

export default new CommonServices();
