import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE_CONSTANT } from "../../../constants";
import { doctor } from "../../../redux/action";
import AutoComplete from "../AutoComplete";
import { useFormik } from "formik";
import * as Yup from "yup";
import { commonAction } from "../../../redux/action";
import ToasterFunc from "../../common/toaster/Toaster";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import moment from "moment";
import DatePicker from "react-datepicker";

function PersonalDetail() {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });
  const dispatch = useDispatch();
  const [Image, setImage] = useState("");
  const [ImageErr, setImageErr] = useState("");
  const [Address, setAddress] = useState("");
  const [InputAddress, setInputAddress] = useState("");
  const [AddressErr, setAddressErr] = useState("");
  const [Lat, setLat] = useState("");
  const [Lng, setLng] = useState("");
  const [gender, setgender] = useState({
    male: false,
    female: false,
    other: false,
  });
  const [genderError, setgenderError] = useState("");
  const [BirthDateError, setBirthDateError] = useState("");
  const [BirthDate, setBirthDate] = useState("");

  useEffect(() => {
    if (Image !== "") {
      setImageErr("");
    }
    if (gender.male || gender.female || gender.other) {
      setgenderError("");
    }
    console.log(gender);
  }, [Image, gender]);

  const formik = useFormik({
    initialValues: {
      fullName: "",
      bio: "",
      // address: "",
      // pincode: "",
    },
    validationSchema: Yup.object({
      fullName: Yup.string().required("*Required.").trim(),
      bio: Yup.string().required("*Required.").trim(),
      // address: Yup.string().required("*Required.").trim(),
      // pincode: Yup.number().required("*Required"),
    }),
  });

  useEffect(() => {
    DoctorProfileInfo();
  }, []);
  const CommonProfilePic = async (payload) => dispatch(commonAction.commonProfilePicAction(payload));
  const DoctorData = useSelector((state) => state?.doctor?.doctorProdileInfo?.result);
  const DoctorProfileInfo = async () => dispatch(doctor.doctorProfileDetailsAction());

  const DoctorEditProfile = async (payload) => dispatch(doctor?.docEditProfileAction(payload));

  useEffect(() => {
    formik.setFieldValue("fullName", DoctorData && DoctorData[0].fullName ? DoctorData[0].fullName : "");
    formik.setFieldValue("bio", DoctorData && DoctorData[0].bio ? DoctorData[0].bio : "");
    // formik.setFieldValue("address", DoctorData && DoctorData[0].location && DoctorData[0].location.address ? DoctorData[0].location.address : formik.values.address);
    // formik.setFieldValue("pincode", DoctorData && DoctorData[0].pincode ? DoctorData[0].pincode : "");
    if (DoctorData && DoctorData[0].location && DoctorData[0].location.address) {
      console.log(DoctorData[0].location.address);
      setAddress(DoctorData[0].location.address);
    }
    if (DoctorData && DoctorData[0].gender === "Female") {
      setgender({ female: true });
    } else if (DoctorData && DoctorData[0].gender === "Male") {
      setgender({ male: true });
    } else if (DoctorData && DoctorData[0].gender === "Other") {
      setgender({ other: true });
    }
    if (BirthDate || DoctorData[0]?.date_of_birth !== "") {
      setBirthDateError("");
    }
    console.log("BirthDate", BirthDate);
  }, [DoctorData]);

  // const handlePlaceChanged = (place) => {
  //   let location = place?.geometry?.location;
  //   let address = place?.formatted_address;
  //   let lat = location?.lat();
  //   let lng = location?.lng();

  //   console.log("place", place);

  //   if (address) {
  //     setAddress(address);
  //     setLat(lat);
  //     setLng(lng);
  //   }

  //   if (place && place.length) {
  //     //   place.address_components.forEach((item, index) => {
  //     //     if (item.types[0] === "administrative_area_level_1") {
  //     //     }
  //     //     if (item.types[0] === "locality" || item.types[0] === "postal_town") {
  //     //       console.log("yes, it is coming ===> 92", item.long_name);
  //     //     }
  //     //     if (item.types[0] === "postal_code") {
  //     //       console.log("yes, it is coming ===> 92", item.long_name);
  //     //     }
  //     //   });
  //     // let address = `${place && place.name && place.name}${place && place.formatted_address && place.formatted_address}`;
  //   }
  // };
  const handleImage = (e) => {
    const file = e.target.files[0];
    console.log(file);
    let formData = new FormData();
    formData.append("file", file);
    fileUpload(formData);
  };

  const fileUpload = async (image) => {
    const res = await CommonProfilePic(image);
    if (res && res.status == 200) {
      let picdata = res && res.data && res.data.result.url;
      setImage(picdata);
    }
  };

  const handlegender = (e) => {
    let checked = e.target.checked;
    let value = e.target.value;
    if (value === "male") {
      setgender({
        male: checked,
      });
    } else if (value === "female") {
      setgender({
        female: checked,
      });
    } else if (value === "other") {
      setgender({
        other: checked,
      });
    }
  };
  useEffect(() => {
    if (Image !== "") {
      setImageErr("");
    }
    if (gender.male || gender.female || gender.other) {
      setgenderError("");
    }
    if (Address !== "") {
      setAddressErr("");
    }
  }, [Image, gender, Address]);

  const handleSubmit = async () => {
    if (Image === "") {
      setImageErr("*Please choose image");
    }
    if (Image !== "") {
      setImageErr("");
    }
    if (Address === "") {
      setAddressErr("*Required");
    }

    console.log("BirthDate", BirthDate);

    const payload = {
      fullName: formik.values.fullName ? formik.values.fullName : DoctorData[0].fullName,
      profilePic: Image ? Image : DoctorData[0].profilePic,
      gender: gender.male ? "Male" : gender.female ? "Female" : "Other",
      bio: formik.values.bio ? formik.values.bio : DoctorData[0].bio,
      lat: Lat ? Lat : DoctorData[0].location.coordinates[0],
      long: Lng ? Lng : DoctorData[0].location.coordinates[1],
      address: Address ? Address : "",
      date_of_birth: BirthDate ? moment(BirthDate).format("MM/DD/YYYY") : DoctorData[0]?.date_of_birth,
      // pincode: formik.values.pincode ? formik.values.pincode : "",
    };

    console.log(payload);

    console.log("Form error==>", formik.errors, formik.values.address);
    if (formik.values.fullName != "" && formik.values.bio != "" && Address !== "" && (BirthDate || DoctorData[0]?.date_of_birth !== "")) {
      const res = await DoctorEditProfile(payload);
      if (res.status == 200) {
        ToasterFunc("success", res.data.responseMessage);
        console.log("res==>", res);
        DoctorProfileInfo();
      }
    }
  };

  console.log("Address", Address, moment(BirthDate).format("MM/DD/YYYY"));

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
    setAddress(e.target.value);
  };

  const handleSelect = ({ description }) => () => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter to "false"
    setValue(description, false);
    clearSuggestions();
    console.log("description", description);
    setAddress(description);

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then((results) => getLatLng(results[0]))
      .then(({ lat, lng }) => {
        console.log("📍 Coordinates: ", { lat, lng });
        setLat(lat);
        setLng(lng);
      })
      .catch((error) => {
        console.log("😱 Error: ", error);
      });
  };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div role="tabpanel" id="p_Details" className="tab-pane fade show active">
      {/* <!-- Basic Information --> */}
      <div className="card">
        <div className="card-header">
          <h4>Edit Personal Details</h4>
        </div>
        <div className="card-body">
          <div className="row form-row">
            <div className="col-md-12">
              <div className="form-group">
                <div className="change-avatar justify-content-center">
                  <div className="profile-round-img">
                    <figure>{Image ? <img src={Image} className="img-fluid" alt="" /> : <img src={DoctorData && DoctorData[0]?.profilePic ? DoctorData[0]?.profilePic : IMAGE_CONSTANT.USER_ICON} alt="" />}</figure>
                    <label for="camera">
                      <input type="file" id="camera" name="profilePic" accept="image/*" hidden onChange={(e) => handleImage(e)} />
                      <i className="fas fa-camera icon-camera"></i>
                    </label>
                    {!DoctorData[0].profilePic && ImageErr ? <div className="validation_error Image">{ImageErr}</div> : null}
                  </div>
                </div>
              </div>
            </div>

            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <label> Name </label>
                    <input type="text" className="form-control" name="fullName" {...formik.getFieldProps("fullName")} placeholder="Name" />
                    {formik.touched.fullName && formik.errors.fullName ? <span className="validation_error">{formik.errors.fullName}</span> : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="row form-group">
                    <div className="col-md-2 col-12">
                      <label for="">Gender</label>
                    </div>
                    <div className="col-md-3 col-3">
                      <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="Male" name="gender" value="male" className="custom-control-input" checked={gender && gender.male ? gender.male : false} onChange={(e) => handlegender(e)} />

                        <label className="custom-control-label" for="Male">
                          Male
                        </label>
                      </div>
                    </div>
                    <div className="col-md-3 col-4">
                      <div className="custom-control custom-radio custom-control-inline">
                        <input type="radio" id="Female" name="gender" value="female" className="custom-control-input" checked={gender && gender.female ? gender.female : false} onChange={(e) => handlegender(e)} />

                        <label className="custom-control-label" for="Female">
                          Female
                        </label>
                      </div>
                    </div>
                    <div class="col-md-4 col-5">
                      <div class="form-check">
                        <input type="radio" class="form-check-input" id="not-to-say" name="gender" value="other" checked={gender && gender.other ? gender.other : false} onChange={(e) => handlegender(e)} />
                        <label class="form-check-label" for="not-to-say">
                          I prefer not to Say
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    {/* <!-- <label> Email </label> --> */}
                    <DatePicker
                      className="form-control"
                      id="my_date_picker1"
                      autoComplete="off"
                      maxDate={moment().toDate()}
                      value={BirthDate ? moment(BirthDate).format("MM/DD/YYYY") : DoctorData[0]?.date_of_birth}
                      selected={BirthDate}
                      onChange={(date) => {
                        setBirthDate(date);
                        console.log(date);
                      }}
                      placeholderText="MM/DD/YYYY"
                      dateFormat="MM/dd/yyyy"
                      showMonthDropdown
                      showYearDropdown
                      scrollableMonthYearDropdown
                      yearDropdownItemNumber={72}
                      scrollableYearDropdown={true}
                    />
                    {/* <input type="date" className="form-control" id="datePickerId" name="DOB" placeholder="DOB" {...formik.getFieldProps("DOB")} /> */}
                    {/* {formik.touched.DOB && formik.errors.DOB ? <span className="validation_error">{formik.errors.DOB}</span> : null} */}
                    {BirthDateError ? <span className="validation_error">{BirthDateError}</span> : null}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group ">
                    <label>Description</label>
                    <textarea className="form-control" name="bio" {...formik.getFieldProps("bio")} maxLength="180" rows="4">
                      {" "}
                    </textarea>
                    {formik.touched.bio && formik.errors.bio ? <span className="validation_error">{formik.errors.bio}</span> : null}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group address-suggesstion">
                    <label>Address</label>
                    <input class="form-control" name="address" value={Address ? Address : value} maxLength="60" onChange={handleInput} disabled={!ready} placeholder="Address" />
                    {status === "OK" && <ul>{renderSuggestions()}</ul>}
                    {AddressErr ? <div className="validation_error">{AddressErr}</div> : null}
                  </div>
                </div>
                {/* 
                <div className="col-md-12">
                  <div className="form-group">
                    <label>Pincode</label>
                    <input
                      type="text"
                      min="0"
                      maxLength="6"
                      className="form-control"
                      {...formik.getFieldProps("pincode")}
                      placeholder="Pincode"
                    />
                    {formik.touched.pincode && formik.errors.pincode ? (
                      <span className="validation_error">
                        {formik.errors.pincode}
                      </span>
                    ) : null}
                  </div>
                </div> */}
              </div>
              <div className=" btn-p">
                <button className="btn btn-primary " onClick={handleSubmit}>
                  Update
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PersonalDetail;
