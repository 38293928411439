import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Modal } from "reactstrap";
import AddBankDetail from "../bank-detail/AddBankDetail";

const WithdrawAmount = ({ withdrawAmt, setWithdrawAmt, setreloadApi }) => {
  const [addBankDetails, setAddBankDetails] = useState(false);
  const docWalletInfo = useSelector((state) => state?.doctor?.doctorWalletData?.walletData);
  const [amtErr, setAmtErr] = useState("");
  const [amount, setAmount] = useState("");
  console.log(docWalletInfo?.totalBalance);

  const handleClose = () => {
    setWithdrawAmt(false);
  };
  const handleAddBankDetails = () => {
    console.log("amount", amount);
    if (amount && !amtErr) {
      if (amount > 0) {
        setAddBankDetails(true);
        setWithdrawAmt(false);
      } else {
        setAmtErr("Withdraw amount must be greater than zero");
      }
    } else if (!amount) {
      setAmtErr("Withdraw amount is required");
    }
  };
  const handleChange = (e) => {
    console.log();
    setAmount(e.target.value);
    if (e.target.value > docWalletInfo?.totalBalance) {
      setAmtErr("insufficient balance");
    } else {
      setAmtErr("");
    }
  };
  return (
    <>
      <Modal isOpen={withdrawAmt} className="modal-dialog-centered modal-dialog-scrollable ">
        <div className="modal-content">
          <button type="button" className="close custom-model-close mr-3" aria-label="Close" data-dismiss="modal" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </button>
          <div class="modal-body p-4">
            <div class="row">
              <div class="col-lg-12 ">
                <div class="card mb-0">
                  <div class="card-header">
                    <h4 class="card-title">Withdraw Amount</h4>
                  </div>
                  <div class="card-body">
                    <div class=" row">
                      <div class="col-lg-12 ">
                        <p class="mb-2">Select Amount</p>
                        <div className="withDrowAmt">
                          <span>$</span> <input type="number" min={1} id="Select-Amount" onChange={(e) => handleChange(e)} placeholder="" />
                        </div>
                        <p>{amtErr}</p>
                        {/* <div><i class="fa fa-info-circle mr-1 mt-1 "></i><span
                                                    class="color-grey">Referral amounts are not open to withdraw</span>
                                                    <p>Maximum withdrawn amount : 10</p>
                                                </div> */}
                      </div>
                    </div>
                    <div class="mt-4 d-flex justify-content-center">
                      {/* <!-- <a href="#" data-toggle="modal" class="btn cancel-outline  mr-2">Cancel</a> --> */}
                      <button class="btn btn-primary w-25" disabled={amtErr && !amount} onClick={handleAddBankDetails}>
                        Proceed
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <AddBankDetail addBankDetails={addBankDetails} setAddBankDetails={setAddBankDetails} amount={amount} setreloadApi={setreloadApi} />
    </>
  );
};

export default WithdrawAmount;
