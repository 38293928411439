import moment from "moment";
import React, { useEffect, useState } from "react";
import PaginationComponent from "react-reactstrap-pagination";
import { useDispatch, useSelector } from "react-redux";
import { doctor, patient } from "../../../../../redux/action";
import PatientBilldetail from "./PatientBilldetail";

function PatientBill({ DocData }) {
  let dispatch = useDispatch();
  const [selectedPage, setselectedPage] = useState(1);
  const [DataLimit, setDataLimit] = useState(4);
  const [BillModal, setBillModal] = useState(false);
  const [BillId, setBillId] = useState("");
  const patientinvoicelist = async (payload) => dispatch(patient.patientinvoicelistAction(payload));
  useEffect(() => {
    if (DataLimit) {
      if (DocData) {
        patientinvoicelist(DocData?.serviceProviderId?._id, DataLimit, selectedPage);
      }
    }
  }, [DocData, DataLimit, selectedPage]);
  console.log("DocData", DocData);

  const patientinvoicedata = useSelector((state) => state?.patient?.invoiceListInfo);

  console.log("patientinvoicedata", patientinvoicedata);

  const handleView = (billId) => {
    setBillModal(!BillModal);
    setBillId(billId);
  };

  const handleSelected = (data) => {
    console.log("clicked page", data);
    setselectedPage(data);
  };

  const handlePageLimit = (e) => {
    console.log("page data", e.target.value);
    setDataLimit(e.target.value);
  };
  return (
    <div role="tabpanel" id="Billing" class="tab-pane fade show active appointments">
      <div class="row">
        {patientinvoicedata?.invoiceData?.length ? (
          patientinvoicedata?.invoiceData.map((item) => (
            <div class="col-md-6">
              <div class="card">
                <div class="card-body">
                  <div class="d-flex justify-content-between">
                    <div>
                      <div>
                        <span>
                          <b>Invoice Number :</b>
                          {item?.invoiceNumber}
                        </span>
                      </div>
                      <div>
                        <span class="color-grey">
                          <b>Doctor's Name :</b> Dr. {item?.serviceProviderId?.fullName}
                        </span>
                      </div>
                      <div>
                        <span class="color-grey">
                          <b>Amount Paid :</b>
                          {item?.amount}
                        </span>
                      </div>
                      <div>
                        <span class="color-grey">Paid On : {moment(item?.createdAt).format("DD/MM/YY")}</span>
                      </div>
                    </div>
                    <div>
                      <span class="c-pointer" onClick={() => handleView(item._id)}>
                        <i class="fas fa-print"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="col-md-12">
            <p className="text-center">No bills available.</p>
          </div>
        )}
      </div>
      {patientinvoicedata && patientinvoicedata?.totalList && patientinvoicedata?.totalList > DataLimit ? (
        <div class="row">
          <div className="col-sm-3 col-md-3">
            <div className="dataTables_length" id="DataTables_Table_0_length">
              <label>
                Show{" "}
                <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" onChange={(e) => handlePageLimit(e)}>
                  <option value="4">4</option>
                  <option value="8">8</option>
                  <option value="10">10</option>
                </select>{" "}
                entries
              </label>
            </div>
          </div>
          <div class="col-sm-5 col-md-5">
            <div class="dataTables_info showing_pagination" id="DataTables_Table_0_info" role="status" aria-live="polite">
              Showing {(selectedPage - 1) * DataLimit + 1} to {(selectedPage - 1) * DataLimit + (patientinvoicedata && patientinvoicedata.invoiceData && patientinvoicedata.invoiceData.length)} of {patientinvoicedata && patientinvoicedata.totalList}{" "}
              entries
            </div>
          </div>
          <div class="col-sm-4 col-md-4">
            <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
              <PaginationComponent totalItems={patientinvoicedata && patientinvoicedata.totalList} pageSize={DataLimit} maxPaginationNumbers={3} defaultActivePage={1} onSelect={(e) => handleSelected(e)} />
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
      <PatientBilldetail BillModal={BillModal} setBillModal={setBillModal} BillId={BillId} />
    </div>
  );
}

export default PatientBill;
