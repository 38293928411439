import React, { useState, useEffect } from "react";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../constants";
import { ForgotPassword, Login, LoginWithNumber } from "../../authentication";
import { SignUp } from "../../authentication/signup";
import "./style.css";
import { useHistory } from "react-router-dom";
import { Link, useLocation } from "react-router-dom";
import SignUpNewsletterModal from "../../cms/news/SignUpNewsletterModal";
import { saveToSessionStorage } from "../../../utils";
import VideoCall from "../VideoCall/VideoCall";

function Header() {
  let history = useHistory();
  const location = useLocation();
  const [FindDocToggle, setFindDocToggle] = useState(false);
  const [signInModal, setSignInModal] = useState(false);
  const [SignUpModal, setSignUpModal] = useState(false);
  const [LoginWithNumModal, setLoginWithNumModal] = useState(false);
  const [ForgetPwdModal, setForgetPwdModal] = useState(false);
  const [SignInButton, setSignInButton] = useState(false);
  const [NewsletterModals, setNewsletterModals] = useState(false);
  const [IsCallstarted, setIsCallstarted] = useState(false);

  const handleForgetPasswordToggle = () => {
    console.log("CLICKED");
    setForgetPwdModal(!ForgetPwdModal);
    setSignInModal(false);
    setLoginWithNumModal(false);
  };
  const handleSignUpToggle = () => {
    setSignUpModal(!SignUpModal);
    setSignInModal(false);
  };

  const handleLoginWithMobiToggle = () => {
    setLoginWithNumModal(!LoginWithNumModal);
    setSignInModal(false);
  };

  const handleSignInToggle = () => {
    setSignInModal(!signInModal);
    setSignUpModal(false);
  };

  const handleSignInAsDoc = () => {
    setSignInModal(!signInModal);
    sessionStorage.removeItem("termCondi");
    // saveToSessionStorage("isLoggedIn", "true");
    saveToSessionStorage("userType", "1");
    setFindDocToggle(true);
  };
  const handleSignInAsPat = () => {
    setSignInModal(!signInModal);
    sessionStorage.removeItem("termCondi");
    // saveToSessionStorage("isLoggedIn", "true");
    saveToSessionStorage("userType", "2");
    setFindDocToggle(true);
  };

  const handleHome = () => {
    if (!sessionStorage.getItem("authToken")) {
      sessionStorage.clear();
      history.push(ROUTES_CONSTANTS.HOME);
    }
  };

  useEffect(() => {
    const { pathname } = location;
    console.log("62", `/${pathname.split("/")[1]}`, ROUTES_CONSTANTS.HOME_REFFRAL);
    if (
      pathname === ROUTES_CONSTANTS.HOME ||
      (!sessionStorage.getItem("isLoggedIn") && pathname === ROUTES_CONSTANTS.PRIVACY_POLICY) ||
      (!sessionStorage.getItem("isLoggedIn") && pathname === ROUTES_CONSTANTS.TERMS_AND_CONDITIONS) ||
      (!sessionStorage.getItem("isLoggedIn") && pathname === ROUTES_CONSTANTS.HOW_IT_WORKS) ||
      (!sessionStorage.getItem("isLoggedIn") && pathname === ROUTES_CONSTANTS.CONTACT_US) ||
      (!sessionStorage.getItem("isLoggedIn") && pathname === ROUTES_CONSTANTS.OUR_MISSION) ||
      (!sessionStorage.getItem("isLoggedIn") && pathname === ROUTES_CONSTANTS.OUR_TEAM) ||
      (!sessionStorage.getItem("isLoggedIn") && pathname === ROUTES_CONSTANTS.ONLINE_HEALTHBLOG) ||
      (!sessionStorage.getItem("isLoggedIn") && pathname === ROUTES_CONSTANTS.REFER_CENTER) ||
      (!sessionStorage.getItem("isLoggedIn") && pathname === ROUTES_CONSTANTS.PARTICIPATING_CENTERS) ||
      (!sessionStorage.getItem("isLoggedIn") && pathname === ROUTES_CONSTANTS.BECOME_PARTICIPATING_CENTERS) ||
      (!sessionStorage.getItem("isLoggedIn") && pathname === ROUTES_CONSTANTS.METABOLIC_CENTERS) ||
      (!sessionStorage.getItem("isLoggedIn") && pathname === ROUTES_CONSTANTS.TESTOMONIALS) ||
      (!sessionStorage.getItem("isLoggedIn") && pathname === ROUTES_CONSTANTS.WHAT_WE_TREAT) ||
      (!sessionStorage.getItem("isLoggedIn") && pathname === ROUTES_CONSTANTS.ABOUT_US) ||
      (!sessionStorage.getItem("isLoggedIn") && `/${pathname.split("/")[1]}` === ROUTES_CONSTANTS.HOME_REFFRAL)
    ) {
      setSignInButton(true);
    } else {
      setSignInButton(false);
    }
  }, [location]);

  const getSessionStorageValue = (key) => {
    const stored = sessionStorage.getItem(key);
    console.log(stored);
    return JSON.parse(stored);
  };

  useEffect(() => {
    const { pathname } = location;
    if (pathname === ROUTES_CONSTANTS.HOME) {
      if (sessionStorage.getItem("isLoggedIn")) {
        if (getSessionStorageValue("userType") === "2") {
          history.push(ROUTES_CONSTANTS.PATIENT_DASHBOARD);
        }
      }
    }
  }, []);

  // useEffect(() => {
  //   if (location?.pathname?.split("/")[2]) {
  //     setSignUpModal(true);
  //   }
  // }, [location]);

  const menuToggleOpen = () => {
    document.body.classList.add("menu-opened");
  };
  const menuToggleClose = () => {
    document.body.classList.remove("menu-opened");
  };
  const handleNvigation = (route) => {
    console.log("pathname", window.location.pathname);
    setIsCallstarted(true);
    history.push(route);
    setIsCallstarted(false);
  };
  const handleNewsletter = () => {
    setNewsletterModals(!NewsletterModals);
  };

  console.log("locationnnnnnnn", location, location?.pathname?.split("/")[2]);
  const handleFindDoc = () => {
    setSignUpModal(!SignUpModal);

    sessionStorage.removeItem("termCondi");
    // saveToSessionStorage("isLoggedIn", "true");
    saveToSessionStorage("userType", "2");
    // setFindDocToggle(true);
  };

  const handleBecomeProvider = () => {
    setSignUpModal(!SignUpModal);
    sessionStorage.removeItem("termCondi");
    // saveToSessionStorage("isLoggedIn", "true");
    saveToSessionStorage("userType", "1");
    // setFindDocToggle(true);
  };

  const handleBecomeCoach = () => {
    setSignUpModal(!SignUpModal);
    sessionStorage.removeItem("termCondi");
    // saveToSessionStorage("isLoggedIn", "true");
    saveToSessionStorage("userType", "1");
    // setFindDocToggle(true);
  };

  return (
    <>
      <header className="header">
        <nav className="navbar navbar-expand-lg header-nav">
          <div className="navbar-header">
            <a id="mobile_btn" onClick={menuToggleOpen}>
              <span className="bar-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </a>
            <a className="navbar-brand logo" onClick={handleHome}>
              <img src={IMAGE_CONSTANT.HEADER_LOGO} className="img-fluid" alt="Logo" />
            </a>
          </div>
          <div className="main-menu-wrapper d-flex justify-content-center flex-fill">
            <div className="menu-header">
              <a href="index.html" className="menu-logo">
                <img src={IMAGE_CONSTANT.HEADER_LOGO} className="img-fluid" alt="Logo" />
              </a>
              <a id="menu_close" className="menu-close" onClick={menuToggleClose}>
                <i className="fas fa-times"></i>
              </a>
            </div>
            <ul className="main-nav header-nav-main">
              <li>
                <a
                  className={location.pathname === ROUTES_CONSTANTS.HOME ? "active" : ""}
                  onClick={() =>
                    handleNvigation(
                      getSessionStorageValue("userType") === "2"
                        ? ROUTES_CONSTANTS.PATIENT_DASHBOARD
                        : getSessionStorageValue("userType") === "1"
                        ? ROUTES_CONSTANTS.DASHBOARD
                        : ROUTES_CONSTANTS.HOME
                    )
                  }
                >
                  {SignInButton ? "Home" : "Dashboard"}
                </a>
              </li>
              <li className="has-submenu">
                <a
                  className={
                    location.pathname ===
                    (ROUTES_CONSTANTS.HOW_IT_WORKS ||
                      ROUTES_CONSTANTS.WHAT_WE_TREAT ||
                      ROUTES_CONSTANTS.METABOLIC_CENTERS ||
                      ROUTES_CONSTANTS.TESTOMONIALS)
                      ? "active"
                      : ""
                  }
                >
                  Members
                </a>

                <ul className="submenu" onClick={menuToggleClose}>
                  <li>
                    <a onClick={() => handleNvigation(ROUTES_CONSTANTS.HOW_IT_WORKS)}>How it works?</a>
                  </li>
                  <li>
                    <a onClick={() => handleNvigation(ROUTES_CONSTANTS.WHAT_WE_TREAT)}>What we treat</a>
                  </li>
                  <li>
                    <a onClick={() => handleNvigation(ROUTES_CONSTANTS.METABOLIC_CENTERS)}>Metabolic Centers</a>
                  </li>
                  <li>
                    <a onClick={() => handleNvigation(ROUTES_CONSTANTS.TESTOMONIALS)}>Testimonials</a>
                  </li>
                </ul>
              </li>
              {SignInButton ? (
                <li className="has-submenu">
                  <a>Providers</a>

                  <ul className="submenu" onClick={menuToggleClose}>
                    <li>
                      <a onClick={handleBecomeProvider}>Become a Provider</a>
                    </li>
                    {/* {FindDocToggle && !(location.pathname === ROUTES_CONSTANTS.HOME) ? (
                      ""
                    ) : ( */}
                    <li>
                      <a onClick={handleFindDoc}>Find a Provider</a>
                    </li>
                    {/* )} */}
                    <li>
                      <a onClick={handleBecomeCoach}>Become a Coach</a>
                    </li>
                  </ul>
                </li>
              ) : (
                ""
              )}
              <li className="has-submenu">
                <a
                  className={
                    location.pathname === ROUTES_CONSTANTS.PARTICIPATING_CENTERS ||
                    location.pathname === ROUTES_CONSTANTS.REFER_CENTER ||
                    location.pathname === ROUTES_CONSTANTS.BECOME_PARTICIPATING_CENTERS
                      ? "active"
                      : ""
                  }
                >
                  Metabolic Centers
                </a>

                <ul className="submenu" onClick={menuToggleClose}>
                  <li>
                    <a onClick={() => handleNvigation(ROUTES_CONSTANTS.PARTICIPATING_CENTERS)}>Participating Centers </a>
                  </li>
                  <li>
                    <a onClick={() => handleNvigation(ROUTES_CONSTANTS.REFER_CENTER)}>Refer a Center </a>
                  </li>
                  <li>
                    <a onClick={() => handleNvigation(ROUTES_CONSTANTS.BECOME_PARTICIPATING_CENTERS)}>Become a Participating Center</a>
                  </li>
                </ul>
              </li>
              <li className="has-submenu">
                <a className={location.pathname === ROUTES_CONSTANTS.ONLINE_HEALTHBLOG ? "active" : ""}>News</a>

                <ul className="submenu" onClick={menuToggleClose}>
                  <li>
                    <a onClick={handleNewsletter}>Sign Up for Newsletter</a>
                  </li>
                  <li>
                    <a onClick={() => handleNvigation(ROUTES_CONSTANTS.ONLINE_HEALTHBLOG)}>Online Healthblog </a>
                  </li>
                  {/* <li><a href="#">Refer a Center</a></li> */}
                </ul>
              </li>
              <li className="has-submenu">
                <a
                  className={
                    location.pathname === ROUTES_CONSTANTS.OUR_TEAM ||
                    location.pathname === ROUTES_CONSTANTS.OUR_MISSION ||
                    location.pathname === ROUTES_CONSTANTS.CONTACT_US
                      ? "active"
                      : ""
                  }
                >
                  About Us
                </a>

                <ul className="submenu" onClick={menuToggleClose}>
                  <li>
                    <a onClick={() => handleNvigation(ROUTES_CONSTANTS.OUR_TEAM)}>Our Team </a>
                  </li>
                  <li>
                    <a onClick={() => handleNvigation(ROUTES_CONSTANTS.OUR_MISSION)}>Our Mission </a>
                  </li>
                  <li>
                    <a onClick={() => handleNvigation(ROUTES_CONSTANTS.CONTACT_US)}>Contact Us </a>
                  </li>
                </ul>
              </li>
              {/* <li className="login-link">
                <a href="#">Login / Signup</a>
              </li> */}

              {SignInButton ? (
                <>
                  <li className="nav-item mob-screen-auth">
                    <a className="nav-link header-login" href onClick={handleSignInAsDoc}>
                      Login As Doctor{" "}
                    </a>
                  </li>
                  <li className="nav-item mob-screen-auth">
                    <a className="nav-link header-login" href onClick={handleSignInAsPat}>
                      Login As Patient
                    </a>
                  </li>
                </>
              ) : (
                ""
              )}
            </ul>
          </div>
          <ul className="nav header-navbar-rht">
            <li className="nav-item contact-item">
              <div className="header-contact-img">
                <i className="far fa-hospital"></i>
              </div>
              <div className="header-contact-detail">
                <p className="contact-header">Contact</p>
                <p className="contact-info-header"> +1 (603) 674-5209</p>
              </div>
            </li>
            {SignInButton ? (
              <>
                <li className="nav-item">
                  <a className="nav-link header-login" href onClick={handleSignInAsDoc}>
                    Login As Doctor{" "}
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link header-login" href onClick={handleSignInAsPat}>
                    Login As Patient
                  </a>
                </li>
              </>
            ) : (
              ""
            )}
          </ul>
        </nav>
        <Login
          SignInModal={signInModal}
          setSignInModal={setSignInModal}
          handleSignUpToggle={handleSignUpToggle}
          handleLoginWithMobiToggle={handleLoginWithMobiToggle}
          handleForgetPasswordToggle={handleForgetPasswordToggle}
        />

        <SignUp SignUpModal={SignUpModal} setSignUpModal={handleSignUpToggle} handleSignInToggle={handleSignInToggle} />

        <ForgotPassword ForgetPwdModal={ForgetPwdModal} setForgetPwdModal={setForgetPwdModal} setSignInModal={setSignInModal} />

        <LoginWithNumber
          LoginWithNumModal={LoginWithNumModal}
          setLoginWithNumModal={setLoginWithNumModal}
          handleLoginWithMobiToggle={handleLoginWithMobiToggle}
          handleForgetPasswordToggle={handleForgetPasswordToggle}
          setSignInModal={setSignInModal}
        />
        <SignUpNewsletterModal NewsletterModals={NewsletterModals} setNewsletterModals={setNewsletterModals} />

        {IsCallstarted && <VideoCall IsHeaderClicked={IsCallstarted} />}
      </header>
    </>
  );
}

export default Header;
