import { useState, useEffect } from "react";
import LabPreviousApp from './LabPreviousApp';
import LabUpcomingApp from './LabUpcomingApp';

const Laboratory = () => {
    const [isLabUpcoming, setisLabUpcoming] = useState(true);
    const [isLabPrevious, setisLabPrevious] = useState(false);

    const handleUpcoming = () => {
        setisLabUpcoming(!isLabUpcoming);
        setisLabPrevious(false);
    };
    const handlePrevious = () => {
        setisLabPrevious(!isLabPrevious);
        setisLabUpcoming(false);
    };

    return (
        <>
            <div class="col-lg-12 col-xl-12">
                <div class="card lab__apoitnt__bx">
                    <div class="card-body pt-1 px-2">
                        {/* <!-- Tab Menu --> */}
                        <nav class="user-tabs">
                            <ul class="nav nav-tabs nav-tabs-bottom nav-justified mobile-tabs">
                                <li class="nav-item">
                                    <a class={isLabUpcoming ? "nav-link active text-nowrap" : "nav-link  text-nowrap"} onClick={handleUpcoming}>
                                        Upcoming Appointments
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a class={isLabPrevious ? "nav-link active text-nowrap" : "nav-link  text-nowrap"} onClick={handlePrevious}>
                                        Previous Appointment
                                    </a>
                                </li>
                            </ul>
                        </nav>
                        <div class="tab-content">
                            {isLabUpcoming ? <LabUpcomingApp /> : isLabPrevious ? <LabPreviousApp /> : null}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Laboratory
