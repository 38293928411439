import { httpService } from "..";
import ToasterFunc from "../../components/common/toaster/Toaster";
import { API_CONSTANTS } from "../../constants";

class DoctorService {
  async doctorProfileDetails() {
    try {
      const response = await httpService.get(API_CONSTANTS.DOCTOR_PROFILE_DATA);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async docDashboardData() {
    try {
      const response = await httpService.get(API_CONSTANTS.DOCTOR_DASHBOARD_DATA);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async docDashboardupcomingAppointments() {
    try {
      const response = await httpService.get(API_CONSTANTS.DOCTOR_UPCOMING_APPOINTMENTS);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async docDashboardPreviousAppointments() {
    try {
      const response = await httpService.get(API_CONSTANTS.DOCTOR_PREVIOUS_APPOINTMENTS);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async docViewAppointment(DocId) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.DOCTOR_VIEW_APPOINTMENTS}/${DocId}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async docHelpRequestList() {
    try {
      const response = await httpService.get(`${API_CONSTANTS.DOCTOR_HELP_REQUEST_LIST}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async helpSubmitNewRequest(data) {
    try {
      const response = await httpService.post(API_CONSTANTS.DOCTOR_SUBMIT_HELP_REQUEST, data);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async docViewHelpRequest(HelpId) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.DOCTOR_VIEW_HELP_REQUEST}/${HelpId}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  changePwdDocUser(data) {
    try {
      const response = httpService.post(API_CONSTANTS.DOCTOR_CHANGE_PASSWORD, data);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  docEditProfile(data) {
    try {
      const response = httpService.post(API_CONSTANTS.DOCTOR_EDIT_PROFILE, data);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async docCardList(selectPage, DataLimit) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.DOCTOR_CARD_LIST}?pageNumber=${DataLimit}&limit=${selectPage}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async docAddPaymentCard(data) {
    try {
      const response = httpService.post(API_CONSTANTS.DOCTOR_ADD_CARD, data);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async docDeleteCard(data) {
    try {
      const response = httpService.delete(
        `${API_CONSTANTS.DOCTOR_DELETE_CARD}/${data}`
        // data
      );
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  docEditCard(data) {
    try {
      const response = httpService.post(API_CONSTANTS.DOCTOR_EDIT_CARD, data);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async docTermsAndConditions() {
    try {
      const response = await httpService.get(`${API_CONSTANTS.DOCTOR_TERMS_AND_CONDITIONS}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async docAboutus() {
    try {
      const response = await httpService.get(`${API_CONSTANTS.DOCTOR_ABOUT_US}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async docLogOut() {
    try {
      const response = httpService.post(API_CONSTANTS.DOCTOR_LOG_OUT);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async docPresciptionList(id, selectPage, DataLimit) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.DOCTOR_PRESCRIPTION_LIST}?patientId=${id}&pageNumber=${DataLimit}&limit=${selectPage}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async docAddPrescription(data) {
    try {
      const response = httpService.post(API_CONSTANTS.DOCTOR_ADD_PRESCRIPTION, data);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async docDeletePrescription(data) {
    try {
      const response = httpService.delete(
        `${API_CONSTANTS.DOCTOR_DELETE_PRESCRIPTION}/${data}`
        // data
      );
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async docViewPrescription(id) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.DOCTOR_VIEW_PRESCRIPTION}/${id}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async docEditPrescription(id, data) {
    try {
      const response = httpService.post(`${API_CONSTANTS.DOCTOR_EDIT_PRESCRIPTION}?_id=${id}`, data);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async docSubscriptionInWeb() {
    try {
      const response = await httpService.get(API_CONSTANTS.DOCTOR_SUBSCRIPTION_IN_WEB);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async docWalletInWeb() {
    try {
      const response = await httpService.get(API_CONSTANTS.DOCTOR_WALLET_IN_WEB);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async staticPage(Type) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.STATIC_PAGE_SMS}?Type=${Type}`);
      return response;
    } catch (err) {
      // ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async testimonialsList() {
    try {
      const response = await httpService.get(`${API_CONSTANTS.TESTIMONIAL_LIST}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async contactUs(data) {
    try {
      const response = httpService.post(API_CONSTANTS.CONTACT_US_FORM, data);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async newsLetter(data) {
    try {
      const response = httpService.post(API_CONSTANTS.SUBSCRIBE_NEWS_LETTER, data);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async ourTeamList() {
    try {
      const response = await httpService.get(`${API_CONSTANTS.OUR_TEAM_LIST}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async editAvailability(payload) {
    try {
      const response = await httpService.post(`${API_CONSTANTS.EDIT_AVAILABILITY}`, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async addMedicalRecord(payload) {
    try {
      const response = await httpService.post(`${API_CONSTANTS.ADD_MEDICAl_RECORD}`, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async editMedicalRecord(payload) {
    try {
      const response = await httpService.post(`${API_CONSTANTS.EDIT_MEDICAL_RECORD}`, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async getMedicalRecord(patientId, pageNumber) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.GET_MEDICAl_RECORD}?patientId=${patientId}&pageNumber=${pageNumber}&limit=10 `);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async recommendationData(payload) {
    try {
      const response = await httpService.post(`${API_CONSTANTS.DOCTOR_RECOMMENDATION_LIST}`, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async doctorinvoicelist(PatId) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.DOCTOR_INVOICE_LIST}?patientId=${PatId}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async doctorinvoiceView(InvoiceId) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.DOCTOR_INVOICE_VIEW}/${InvoiceId}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async doctorinvoicelistFull(DataLimit, selectedPage) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.DOCTOR_INVOICE_BILL_LIST}?pageNumber=${selectedPage}&limit=${DataLimit}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async doctorReplyOnRequest(payload) {
    try {
      const response = await httpService.post(API_CONSTANTS.DOCTOR_REPLY_ON_REQUEST, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async doctorUpgradeSubscriptionFromWallet(payload) {
    try {
      const response = await httpService.post(API_CONSTANTS.DOCTOR_WALLET_PAYMENT, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }

  async doctorPrivacyPolicy() {
    try {
      const response = await httpService.get(`${API_CONSTANTS.DOCTOR_PRIVACY_POLICY}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async doctorwithdrawRequest(payload) {
    try {
      const response = await httpService.post(API_CONSTANTS.DOCTOR_WITHDRAW_REQUEST, payload);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
  async docWalletHistory(DataLimit, selectedPage) {
    try {
      const response = await httpService.get(`${API_CONSTANTS.DOCTOR_WITHDRAW_TRANSACTION_HISTORY}?pageNumber=${selectedPage}&limit=${DataLimit}`);
      return response;
    } catch (err) {
      ToasterFunc("error", err.response.data.responseMessage);
      return err.response;
    }
  }
}

export default new DoctorService();
