import React from "react";
import { useHistory } from "react-router-dom";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../constants";

function PatViewMedical({ ProfileData }) {
  let history = useHistory();
  return (
    <div id="password_tab" className="tab-pane fade show active">
      <div className="row">
        <div className="col-lg-12">
          <div className="card borderless">
            <div className="card-body padding-zero">
              <div className="profile d-flex justify-content-center mt-3 mb-3 subscription">
                <div className="round-circle">
                  {" "}
                  <img
                    src={
                      ProfileData.userData && ProfileData.userData.profilePic ? ProfileData.userData.profilePic : IMAGE_CONSTANT.USER_ICON
                    }
                    className="img-fluid "
                    alt="..."
                  />
                  {/* <label className="position-camera">
                    <input type="file" hidden /> <i className="fa fa-camera "> </i>
                  </label> */}
                </div>
              </div>
              <div className="row">
                <p
                  className="
                        col-sm-3
                        text-muted text-sm-right
                        mb-0 mb-sm-3
                      "
                >
                  Height
                </p>
                <p className="col-sm-9">
                  {ProfileData?.userData?.height}
                  {ProfileData?.userData?.hightUnit}
                </p>
              </div>
              <div className="row">
                <p
                  className="
                        col-sm-3
                        text-muted text-sm-right
                        mb-0 mb-sm-3
                      "
                >
                  Weight
                </p>
                <p className="col-sm-9">
                  {ProfileData?.userData?.weight}
                  {ProfileData?.userData?.weightUnit}
                </p>
              </div>
              <div className="row">
                <p
                  className="
                        col-sm-3
                        text-muted text-sm-right
                        mb-0 mb-sm-3
                      "
                >
                  Blood Group
                </p>
                <p className="col-sm-9">{ProfileData?.userData?.bloodGroup}</p>
              </div>
              <div className="row">
                <p
                  className="
                        col-sm-3
                        text-muted text-sm-right
                        mb-0 mb-sm-3
                      "
                >
                  Diabetes
                </p>
                <p className="col-sm-9">{ProfileData && ProfileData.userData && ProfileData.userData.iSDiabetes ? "Yes" : "No"}</p>
              </div>
              <div className={ProfileData && ProfileData.userData && ProfileData.userData.iSDiabetes ? "row" : "row hide"}>
                <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">Diabetes Details</p>
                <p className="col-sm-9">
                  {ProfileData && ProfileData.userData && ProfileData.userData.iSDiabetes ? ProfileData.userData.diabetesDetails : ""}
                </p>
              </div>
              <div className="row">
                <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">Allergies Details</p>
                <p className="col-sm-9">
                  {ProfileData && ProfileData.userData && ProfileData.userData.allergyDeatils ? ProfileData.userData.allergyDeatils : ""}
                </p>
              </div>
              <div className="row">
                <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">Family Notes</p>
                <p className="col-sm-9">
                  {ProfileData && ProfileData.userData && ProfileData.userData.familyNotes ? ProfileData.userData.familyNotes : ""}
                </p>
              </div>
              <div className="row">
                <p className="col-sm-3 text-muted text-sm-right mb-0 mb-sm-3">Medical Records</p>

                <div className="card col-sm-9">
                  <div className="card-body p-2">
                    <div>
                      <span>
                        <i className="fa fa-file-pdf fa-3x"></i>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center mt-5">
                {" "}
                <button onClick={() => history.push(ROUTES_CONSTANTS.PATIENT_EDIT_MEDICAL_INFO)} className="btn btn-primary">
                  Edit
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PatViewMedical;
