import moment from "moment";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";
import HeaderLogo from "../../../../assets/img/header-logo.png";
import { doctor } from "../../../../redux/action";

function ViewBill({ ViewModal, setViewModal, Invoiceid }) {
  let dispatch = useDispatch();
  const [Tax, setTax] = useState(0);
  const [discount, setdiscount] = useState(0);
  const doctorinvoiceView = async (payload) => dispatch(doctor.doctorinvoiceViewAction(payload));
  useEffect(() => {
    if (Invoiceid) {
      doctorinvoiceView(Invoiceid);
    }
  }, [Invoiceid]);

  const doctorinvoice = useSelector((state) => state?.doctor?.invoiceViewInfo?.data);
  console.log("doctorinvoice", doctorinvoice);
  const handleClose = () => {
    setViewModal(false);
  };
  return (
    // <div className="modal fade" id="pat_invoiceModal" tabindex="-1" role="dialog" aria-labelledby="billing_invoiceModal" aria-hidden="true">
    //   <div className="modal-dialog  modal-lg modal-dialog-centered " role="document">
    <Modal isOpen={ViewModal} className=" modal-dialog  modal-lg modal-dialog-centered">
      {/* <div className="modal-content"> */}
      {/* <div className="modal-body">
       */}

      <div className="text-right">
        <button type="button" className="dr_Modalclose " onClick={handleClose}>
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <ModalBody>
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-12 ">
              <div className="invoice-content">
                <div className="invoice-item">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="invoice-logo">
                        <img src={HeaderLogo} alt="logo" />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <p className="invoice-details">
                        <strong>Order: </strong>
                        {doctorinvoice?.invoiceNumber}
                        <br />
                        <strong>Issued:</strong> {moment(doctorinvoice?.createdAt).format("DD/MM/YY")}
                      </p>
                    </div>
                  </div>
                </div>

                {/* <!-- Invoice Item --> */}
                <div className="invoice-item">
                  <div className="row">
                    <div className="col-md-6">
                      {doctorinvoice?.invoiceType === "SUBSCRIPTION" ? (
                        ""
                      ) : (
                        <div className="invoice-info">
                          <strong className="customer-text">Invoice From</strong>
                          <p className="invoice-details invoice-details-two">
                            {doctorinvoice?.serviceProviderId?.fullName},<br /> {doctorinvoice?.serviceProviderId?.location?.address}
                          </p>
                        </div>
                      )}
                    </div>
                    {doctorinvoice?.invoiceType === "SUBSCRIPTION" ? (
                      ""
                    ) : (
                      <div className="col-md-6">
                        <div className="invoice-info invoice-info2">
                          <strong className="customer-text">Invoice To</strong>
                          <p className="invoice-details">
                            {" "}
                            {`${doctorinvoice?.patientId?.firstName}  ${doctorinvoice?.patientId?.lastName}`},<br /> {doctorinvoice?.patientId?.location?.address}
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                {/* <!-- /Invoice Item --> */}

                {/* <!-- Invoice Item --> */}
                <div className="invoice-item">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="invoice-info">
                        {doctorinvoice?.invoiceType === "SUBSCRIPTION" ? (
                          <>
                            <strong className="customer-text">Payment Method</strong>
                            <p className="invoice-details invoice-details-two">
                              Subscription <br />
                              {doctorinvoice?.subscriptionId?.name}
                            </p>
                          </>
                        ) : doctorinvoice?.paymentType === "CREDIT" ? (
                          <>
                            <strong className="customer-text">Payment Method</strong>
                            <p className="invoice-details invoice-details-two">
                              Debit Card <br />
                              {doctorinvoice?.cardNumber.replace(/.(?=.{4})/g, "X")}
                            </p>
                          </>
                        ) : (
                          <strong className="customer-text">Subscription</strong>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /Invoice Item --> */}

                {/* <!-- Invoice Item --> */}
                <div className="invoice-item invoice-table-wrap">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="invoice-table table table-bordered">
                          <thead>
                            <tr>
                              <th>Description</th>
                              {/* <th className="text-center">Quantity</th> */}
                              <th className="text-right">Total</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>Video/Audio Consultation</td>
                              {/* <td className="text-center">2</td> */}
                              <td className="text-right">${doctorinvoice?.amount}</td>
                            </tr>
                            <tr>
                              {/* <td>Consultation Fee</td> */}
                              {/* <td className="text-center">3</td> */}
                              {/* <td className="text-right">$13</td> */}
                            </tr>
                            <tr>
                              <td>Tax </td>
                              {/* <td className="text-center">4</td> */}
                              <td className="text-right">${Tax}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-md-6 col-xl-4 ml-auto">
                      <div className="table-responsive">
                        <table className="invoice-table-two table">
                          <tbody>
                            <tr>
                              <th>Subtotal:</th>
                              <td>
                                <span>${doctorinvoice?.amount + Tax}</span>
                              </td>
                            </tr>
                            <tr>
                              <th>Discount:</th>
                              <td>
                                <span>${discount}</span>
                              </td>
                            </tr>
                            <tr>
                              <th>Total :</th>
                              <td>
                                <span>${doctorinvoice?.amount + Tax - discount}</span>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <!-- /Invoice Item --> */}

                {/* <!-- Invoice Information --> */}
                {/* <div className="other-info">
                  <h4>Other information</h4>
                  <p className="text-muted mb-0">
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus sed dictum ligula, cursus blandit risus. Maecenas eget metus non tellus dignissim aliquam ut a ex. Maecenas sed vehicula dui, ac suscipit lacus. Sed finibus leo
                    vitae lorem interdum, eu scelerisque tellus fermentum. Curabitur sit amet lacinia lorem. Nullam finibus pellentesque libero.
                  </p>
                </div> */}
                {/* <!-- /Invoice Information --> */}
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      {/* </div> */}
      {/* </div> */}
    </Modal>
    //   </div>
    // </div>
    // </div>
    // </div>
  );
}

export default ViewBill;
