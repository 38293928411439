import RatingService from "../../../services/ratingsService/ratingService";
import { commonAction } from "../commonAction";
import ratingActionType from "./ratingAtionType";

export const getReviewListAction = (pageNumber) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await RatingService.getReviewList(pageNumber);
      dispatch(commonAction.hideLoader());
      resolve(response);
      console.log("response", response);
      if (response.status == 200) {
        dispatch({
          type: ratingActionType.GET_RATING_LIST,
          ratingList: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const challangeReviewsAction = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(commonAction.showLoader());
    const payloadData = {
      reviewId: payload.reviewId,
      challenge: payload.challenge,
    };
    try {
      const response = RatingService.challangeReviews(payloadData);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export default {
  getReviewListAction,
  challangeReviewsAction,
};
