import React,{useEffect} from 'react'
import { patient } from '../../../../../redux/action'
import { useDispatch, useSelector } from 'react-redux'

function PrivacyPolicy() {
    let dispatch= useDispatch()

    const patientPrivacyData = useSelector((state)=>state?.patient?.patientSidePrivacyPolicy?.data)

    console.log("patientPrivacyData",patientPrivacyData);
    useEffect(() => {
        window.scrollTo(0, 0);
        patientPrivacyPolicy()
    }, [])
    const patientPrivacyPolicy = async ()=> dispatch(patient.patientPrivacyPolicyAction())

    const createMarkup = () => {
        if (patientPrivacyData) {
            return { __html: patientPrivacyData && patientPrivacyData.description && patientPrivacyData.description };
        }
    }
    return (
        <div role="tabpanel" id="privacy-policy" class="tab-pane fade show active">
        <div class="row">
            <div class="col-sm-12">
                <div class="card">
                    <div class="card-header">
                        <h4>Privacy Policy</h4>
                    </div>
                    <div class="card-body">
                    {patientPrivacyData? <div className="cms_box" dangerouslySetInnerHTML={createMarkup()} />:<p className='text-center'>Data not found!</p>}
                    </div>
                </div>
            </div>

        </div>
    </div>
    )
}

export default PrivacyPolicy
