import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import ReactStars from "react-stars";
import { IMAGE_CONSTANT } from "../../constants";
import { ratingAction } from "../../redux/action";
import ChallangeModal from "./ChallangeModal";

function Review() {
  const dispatch = useDispatch();
  const [challangeModal, setChallangeModal] = useState(false);
  const [challangeId, setchallangeId] = useState("");
  const ratingListApi = async () => dispatch(ratingAction.getReviewListAction());
  const ratingList = useSelector((state) => state.rating.ratingList);
  useEffect(() => {
    window.scrollTo(0, 0)
    ratingListApi();
  }, []);

  const addChallange = (id) => {
    setChallangeModal(true);
    setchallangeId(id);
  };
  return (
    <>
      <div className="col-lg-8 col-xl-9 content_ipad_p">
        {console.log("$$$$$$$$$$$$", ratingList)}
        <div className="card">
          <div className="card-header">
            <h4>Rating and Reviews</h4>
          </div>
          <div className="card-body">
            <div className="doc-review review-listing">
              <ul className="comments-list">
                {ratingList && ratingList.documentList && ratingList.documentList.length ? (
                  ratingList.documentList.map((item, i) => (
                    <li key={i}>
                      <div className="comment">
                        {item && item.userId && item.userId.profilePic ? <img className="avatar rounded-circle" alt="" src={item && item.userId && item.userId.profilePic} /> : ""}
                        <div className="comment-body">
                          <div className="meta-data">
                            <span className="comment-author">
                              {item && item.userId && item.userId.firstName} {item && item.userId && item.userId.lastName}
                            </span>
                            <span className="comment-date review-count">{moment(item && item.updatedAt).format("DD/MM/YY")}</span>
                            <div className=" rating ">
                              <ReactStars edit={false} count={5} value={item && item.rating} size={16} style={{ selfAlign: "center" }} color2={"#ffd700"} />
                            </div>
                          </div>

                          <p className="comment-content mb-0">{item && item.review}</p>
                          <div className="submit-section text-right">
                            <button type="button" className="btn  btn-primary" onClick={() => addChallange(item && item._id)}>
                              <span>
                                {" "}
                                <i className="fas fa-edit "></i>{" "}
                              </span>{" "}
                              Challange
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))
                ) : (
                  <p className="text-center">Data not found</p>
                )}
              </ul>
            </div>
          </div>
        </div>
        <ChallangeModal challangeModal={challangeModal} setChallangeModal={setChallangeModal} challangeId={challangeId} ratingListApi={ratingListApi} />
      </div>
    </>
  );
}

export default Review;
