import React, { useEffect, useState } from "react";
import moment from "moment";
import { ROUTES_CONSTANTS } from "../../../constants";
import { useHistory } from "react-router-dom";

function DocViewProfessional({ ProfileData }) {
  let history = useHistory();
  // const [SecondarySpe, setSecondarySpe] = useState('')
  console.log("PatientProfileData", ProfileData);
  const [intervel, setIntervel] = useState([]);
  const [Weekday, setWeekday] = useState("Sun");
  const [TimeSlots, setTimeSlots] = useState({
    SunTimeSlots: [],
    MonTimeSlots: [],
    TueTimeSlots: [],
    WedTimeSlots: [],
    ThursTimeSlots: [],
    FriTimeSlots: [],
    SatTimeSlots: [],
  });

  useEffect(() => {
    if (ProfileData) {
      ProfileData.availability.map((item) => {
        console.log("time", item.interval);
        setIntervel(item.interval);
      });
      // setIntervel(ProfileData && ProfileData.availability[0] && ProfileData.availability[0].interval)
      setTimeSlots({
        SunTimeSlots: ProfileData && ProfileData.availability[0] && ProfileData.availability[0].slots ? ProfileData.availability[0].slots : "",
        MonTimeSlots: ProfileData.availability[1] && ProfileData.availability[1].slots ? ProfileData.availability[1].slots : "",
        TueTimeSlots: ProfileData.availability[2] && ProfileData.availability[2].slots ? ProfileData.availability[2].slots : "",
        WedTimeSlots: ProfileData.availability[3] && ProfileData.availability[3].slots ? ProfileData.availability[3].slots : "",
        ThursTimeSlots: ProfileData.availability[4] && ProfileData.availability[4].slots ? ProfileData.availability[4].slots : "",
        FriTimeSlots: ProfileData.availability[5] && ProfileData.availability[5].slots ? ProfileData.availability[5].slots : "",
        SatTimeSlots: ProfileData.availability[6] && ProfileData.availability[6].slots ? ProfileData.availability[6].slots : "",
      });
    }
  }, [ProfileData]);

  console.log("intervel", intervel);

  const convertUTCToLocal = (time) => {
    return new moment.utc(time, ["HH:mm A"]).local().format("hh:mm A");
  };

  const addIntervel = (time) => {
    time = time ? new moment.utc(time, "HH:mm A").local() : null;
    time.add(intervel, "minutes");
    return new moment(time, ["HH.mm"]).utc().format("hh:mm A");
  };

  console.log("TueTimeSlots", TimeSlots.TueTimeSlots);

  const handleDay = (Day) => {
    if (ProfileData) {
      ProfileData.availability.map((item) => {
        console.log(item.day);
        if (item.day === Day) {
          setWeekday(item.day);
        }
      });
    }
  };
  return (
    <div id="password_tab" className="tab-pane fade show active">
      <div className="row">
        <div className="col-lg-12">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <p
                  className="
                        col-sm-3
                        text-muted text-sm-right
                        mb-0 mb-sm-3
                      "
                >
                  Primary Speciality
                </p>
                <p className="col-sm-9">{ProfileData ? ProfileData.serviceProviderType && ProfileData.serviceProviderType[0].primary_specialty : ""}</p>
              </div>

              <div className="row">
                <p
                  className="
                        col-sm-3
                        text-muted text-sm-right
                        mb-0 mb-sm-3
                      "
                >
                  Secondary Speciality
                </p>
                <p className="col-sm-9"> {ProfileData && ProfileData.specialties && ProfileData.specialties.map((item) => `${item.secondry_specialty} , `)}</p>
              </div>

              <div className="row">
                <p
                  className="
                        col-sm-3
                        text-muted text-sm-right
                        mb-0 mb-sm-3
                      "
                >
                  License Number
                </p>
                <p className="col-sm-9">{ProfileData?.licenceNumber ? ProfileData.licenceNumber : "--"}</p>
              </div>
              <div className="row">
                <p
                  className="
                        col-sm-3
                        text-muted text-sm-right
                        mb-0 mb-sm-3
                      "
                >
                  Experince
                </p>
                <p className="col-sm-9">
                  {ProfileData && ProfileData.experience_in_years ? `${ProfileData.experience_in_years} Years  ` : "--"}
                  {ProfileData && ProfileData.experience_in_months ? `${ProfileData.experience_in_months} Months` : ""}
                  {""}
                </p>
              </div>
              <div className="row">
                <p
                  className="
                        col-sm-3
                        text-muted text-sm-right
                        mb-0 mb-sm-3
                      "
                >
                  Service Charges
                </p>
                <p className="col-sm-9">$ {ProfileData && ProfileData.serviceCharge}</p>
              </div>
              {/* <!-- select availability --> */}

              <div className="row">
                <div className="col-sm-12">
                  <h4 className="card-title"> Availability</h4>
                  <div className="profile-box">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="card schedule-widget mb-0">
                          {/* <!-- Schedule Header --> */}
                          <div className="schedule-header">
                            {/* <!-- Schedule Nav --> */}
                            <div className="schedule-nav">
                              <ul
                                className="
                                          nav
                                          nav-tabs
                                          nav-justified
                                        "
                              >
                                <li className="nav-item">
                                  <a className={Weekday === "Sun" ? "nav-link active" : "nav-link"} onClick={() => handleDay("Sun")}>
                                    Sunday
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a className={Weekday === "Mon" ? "nav-link active" : "nav-link"} onClick={() => handleDay("Mon")}>
                                    Monday
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a className={Weekday === "Tue" ? "nav-link active" : "nav-link"} onClick={() => handleDay("Tue")}>
                                    Tuesday
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a className={Weekday === "Wed" ? "nav-link active" : "nav-link"} onClick={() => handleDay("Wed")}>
                                    Wednesday
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a className={Weekday === "Thu" ? "nav-link active" : "nav-link"} onClick={() => handleDay("Thu")}>
                                    Thursday
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a className={Weekday === "Fri" ? "nav-link active" : "nav-link"} onClick={() => handleDay("Fri")}>
                                    Friday
                                  </a>
                                </li>
                                <li className="nav-item">
                                  <a className={Weekday === "Sat" ? "nav-link active" : "nav-link"} onClick={() => handleDay("Sat")}>
                                    Saturday
                                  </a>
                                </li>
                              </ul>
                            </div>
                            {/* <!-- /Schedule Nav --> */}
                          </div>
                          {/* <!-- /Schedule Header --> */}

                          {/* <!-- Schedule Content --> */}
                          <div className="card-body pt-0">
                            <div className="tab-content schedule-cont">
                              {/* <!-- Sunday Slot --> */}
                              {Weekday === "Sun" ? (
                                <div id="slot_sunday" className="tab-pane fade show active">
                                  {TimeSlots && TimeSlots.SunTimeSlots && TimeSlots.SunTimeSlots.length ? (
                                    <>
                                      <h4 className="card-title d-flex justify-content-between">
                                        <span>Time Slots</span>
                                      </h4>
                                      {/* <p className="text-muted mb-0">Not Available</p>
                                       */}

                                      <div className="doc-times">
                                        {TimeSlots.SunTimeSlots ? (
                                          TimeSlots.SunTimeSlots.map((item) => {
                                            return <div className="doc-slot-list">{`${convertUTCToLocal(item.slotTime)} - ${convertUTCToLocal(addIntervel(item.slotTime))}`}</div>;
                                          })
                                        ) : (
                                          <p className="text-muted mb-0">Not Available</p>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <p className="text-muted mb-0">Not Available</p>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              {/* <!-- /Sunday Slot --> */}

                              {/* <!-- Monday Slot --> */}
                              {Weekday === "Mon" ? (
                                <div id="slot_monday" className="tab-pane fade show active">
                                  {TimeSlots && TimeSlots.MonTimeSlots && TimeSlots.MonTimeSlots.length ? (
                                    <>
                                      <h4 className="card-title d-flex justify-content-between">
                                        <span>Time Slots</span>
                                      </h4>

                                      <div className="doc-times">
                                        {TimeSlots.MonTimeSlots ? (
                                          TimeSlots.MonTimeSlots.map((item) => {
                                            return <div className="doc-slot-list">{`${convertUTCToLocal(item.slotTime)} - ${convertUTCToLocal(addIntervel(item.slotTime))}`}</div>;
                                          })
                                        ) : (
                                          <p className="text-muted mb-0">Not Available</p>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <p className="text-muted mb-0">Not Available</p>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              {/* <!-- /Monday Slot --> */}

                              {/* <!-- Tuesday Slot --> */}
                              {Weekday === "Tue" ? (
                                <div id="slot_tuesday" className="tab-pane fade show active">
                                  {TimeSlots && TimeSlots.TueTimeSlots && TimeSlots.TueTimeSlots.length ? (
                                    <>
                                      <h4 className="card-title d-flex justify-content-between">
                                        <span>Time Slots</span>
                                      </h4>

                                      <div className="doc-times">
                                        {TimeSlots.TueTimeSlots ? (
                                          TimeSlots.TueTimeSlots.map((item) => {
                                            return <div className="doc-slot-list">{`${convertUTCToLocal(item.slotTime)} - ${convertUTCToLocal(addIntervel(item.slotTime))}`}</div>;
                                          })
                                        ) : (
                                          <p className="text-muted mb-0">Not Available</p>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <p className="text-muted mb-0">Not Available</p>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}

                              {/* <!-- /Tuesday Slot --> */}

                              {/* <!-- Wednesday Slot --> */}
                              {Weekday === "Wed" ? (
                                <div id="slot_wednesday" className="tab-pane fade show active">
                                  {TimeSlots && TimeSlots.WedTimeSlots && TimeSlots.WedTimeSlots.length ? (
                                    <>
                                      <h4 className="card-title d-flex justify-content-between">
                                        <span>Time Slots</span>
                                      </h4>

                                      <div className="doc-times">
                                        {TimeSlots.WedTimeSlots ? (
                                          TimeSlots.WedTimeSlots.map((item) => {
                                            return <div className="doc-slot-list">{`${convertUTCToLocal(item.slotTime)} - ${convertUTCToLocal(addIntervel(item.slotTime))}`}</div>;
                                          })
                                        ) : (
                                          <p className="text-muted mb-0">Not Available</p>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <p className="text-muted mb-0">Not Available</p>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              {/* <!-- /Wednesday Slot --> */}

                              {/* <!-- Thursday Slot --> */}
                              {Weekday === "Thu" ? (
                                <div id="slot_thursday" className="tab-pane fade show active">
                                  {TimeSlots && TimeSlots.ThursTimeSlots && TimeSlots.ThursTimeSlots.length ? (
                                    <>
                                      <h4 className="card-title d-flex justify-content-between">
                                        <span>Time Slots</span>
                                      </h4>

                                      <div className="doc-times">
                                        {TimeSlots.ThursTimeSlots ? (
                                          TimeSlots.ThursTimeSlots.map((item) => {
                                            return <div className="doc-slot-list">{`${convertUTCToLocal(item.slotTime)} - ${convertUTCToLocal(addIntervel(item.slotTime))}`}</div>;
                                          })
                                        ) : (
                                          <p className="text-muted mb-0">Not Available</p>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <p className="text-muted mb-0">Not Available</p>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              {/* <!-- /Thursday Slot --> */}

                              {/* <!-- Friday Slot --> */}
                              {Weekday === "Fri" ? (
                                <div id="slot_friday" className="tab-pane fade show active">
                                  {TimeSlots && TimeSlots.FriTimeSlots && TimeSlots.FriTimeSlots.length ? (
                                    <>
                                      <h4 className="card-title d-flex justify-content-between">
                                        <span>Time Slots</span>
                                      </h4>

                                      <div className="doc-times">
                                        {TimeSlots.FriTimeSlots ? (
                                          TimeSlots.FriTimeSlots.map((item) => {
                                            return <div className="doc-slot-list">{`${convertUTCToLocal(item.slotTime)} - ${convertUTCToLocal(addIntervel(item.slotTime))}`}</div>;
                                          })
                                        ) : (
                                          <p className="text-muted mb-0">Not Available</p>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <p className="text-muted mb-0">Not Available</p>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              {/* <!-- /Friday Slot --> */}

                              {/* <!-- Saturday Slot --> */}
                              {Weekday === "Sat" ? (
                                <div id="slot_saturday" className="tab-pane fade show active">
                                  {TimeSlots && TimeSlots.SatTimeSlots && TimeSlots.SatTimeSlots.length ? (
                                    <>
                                      <h4 className="card-title d-flex justify-content-between">
                                        <span>Time Slots</span>
                                      </h4>

                                      <div className="doc-times">
                                        {TimeSlots.SatTimeSlots ? (
                                          TimeSlots.SatTimeSlots.map((item) => {
                                            return <div className="doc-slot-list">{`${convertUTCToLocal(item.slotTime)} - ${convertUTCToLocal(addIntervel(item.slotTime))}`}</div>;
                                          })
                                        ) : (
                                          <p className="text-muted mb-0">Not Available</p>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <p className="text-muted mb-0">Not Available</p>
                                  )}
                                </div>
                              ) : (
                                ""
                              )}
                              {/* <!-- /Saturday Slot --> */}
                            </div>
                            {/* <!-- /Schedule Content --> */}
                          </div>
                        </div>
                        <div className="d-flex justify-content-center mt-5">
                          {" "}
                          <button onClick={() => history.push(ROUTES_CONSTANTS.DOCTOR_EDIT_PROFESSIONAL_DETAIL)} className="btn btn-primary">
                            Edit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocViewProfessional;
