import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notificationAction } from "../../redux/action";
import { doctor } from "../../redux/action";
import { patient } from "../../redux/action";
import { getSessionStorageOrDefault } from '../../utils'

function Notification() {
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const getNotificationList = async (pageNumber, limit) => dispatch(notificationAction.getNotificationList(pageNumber, limit));

  const notificationList = useSelector((state) => state.notification.notificationList);

  const deleteNoti = async (id) => dispatch(notificationAction.deleteNotificationAction(id));
  const DoctorProfileInfo = async () => dispatch(doctor.doctorProfileDetailsAction());

  useEffect(() => {
    window.scrollTo(0, 0);
    callNotification();
  }, [pageNumber]);

  
  const callNotification = async () => {
   await getNotificationList(pageNumber, limit);
   await  DoctorProfileInfo()
  };

  const handleDeleteNotification = async (item) => {
    const res = await deleteNoti(item.target.id);

    if (res.status == 200) callNotification();
  };

  const renderPagination = () => {
    const pagination = [];
    for (let i = 0; i < Math.ceil(notificationList?.totalList / limit); i++) {
      pagination.push(
        <li onClick={() => setPageNumber(i + 1)} className={`paginate_button page-item ${pageNumber === i + 1 && "active"}`}>
          <div aria-controls="DataTables_Table_0" data-dt-idx="1" tabindex="0" className="page-link">
            {i + 1}
          </div>
        </li>
      );
    }
    return pagination;
  };

  return (
    <>
      <div className=" col-lg-8 col-xl-9 content_ipad_p">
        <div class="card">
          <div class="card-header">
            <h4 class="card-title">Notifications</h4>
          </div>
          <div class="card-body" onScroll={(e) => console.log(e)}>
            {notificationList?.notificationData?.length > 0 ? (
              notificationList?.notificationData?.map((item) => {
                return (
                  <div class="card">
                    <div class="card-body">
                      <div class="row">
                        <div class="col-md-1 notification-delete-icon">
                          <div>
                            <span>
                              <i onClick={handleDeleteNotification} id={item._id} class="fa fa-trash fa-lg" data-toggle="modal" data-target="#delete_staticModel"></i>
                            </span>
                          </div>
                        </div>
                        <div class="col-md-11">
                          <span class="color-grey">{item.body}</span>
                          <div class="row mt-1">
                            <div class="col-md-6 col-6">
                              <span>{moment(item.createdAt).format("MM-DD-YYYY")}</span>
                            </div>
                            <div class="col-md-6 col-6 text-right">
                              <span>{moment(item.createdAt).format("LT")}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <>No Notification Available</>
            )}
          </div>
          {notificationList?.notificationData?.length >4 && (
            <div style={{ padding: "25px" }}>
              <div className="row">
                <div className="col-sm-12 col-md-5">
                  <div className="dataTables_info" id="DataTables_Table_0_info" role="status" aria-live="polite">
                    {`Showing 1 to ${notificationList?.notificationData?.length} of ${notificationList?.totalList} entries`}
                  </div>
                </div>
                <div className="col-sm-12 col-md-7">
                  <div className="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                    <ul className="pagination">
                      <li
                        onClick={() => {
                          if (pageNumber !== 1) {
                            setPageNumber(pageNumber - 1);
                          }
                        }}
                        className="paginate_button page-item previous disabled"
                        id="DataTables_Table_0_previous"
                      >
                        <a href aria-controls="DataTables_Table_0" data-dt-idx="0" tabindex="0" className="page-link">
                          Previous
                        </a>
                      </li>
                      {renderPagination()}
                      <li
                        onClick={() => {
                          if (pageNumber !== Math.ceil(notificationList?.totalList / limit)) {
                            setPageNumber(pageNumber + 1);
                          }
                        }}
                        className="paginate_button page-item next disabled"
                        id="DataTables_Table_0_next"
                      >
                        <a href aria-controls="DataTables_Table_0" data-dt-idx="2" tabindex="0" className="page-link">
                          Next
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default Notification;
