import React, { useEffect, useState,useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { bookingAction, patient,commonAction } from "../../../../../redux/action";
import defaultImg from "../../../../../assets/img/default-img.png";
import { Tooltip } from "reactstrap";
import { useLocation } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../../../../constants";
import CancelAppointment from "../../../../common/CancelModal/CancelModal";
import moment from "moment";
import { saveToSessionStorage } from "../../../../../utils";
import ToasterFunc from "../../../../common/toaster/Toaster";
import ReactStars from "react-stars";
import { IsCallerDetails } from "../../../../../getDeviceToken";
import { SocketContext } from "../../../../..";
import { toast } from "react-toastify";
import ConfirmationModal from "../../../findDoctorsNearBy/ConfirmationModal";

function ViewAppointmentOfPatient() {
  let history = useHistory();
  let dispatch = useDispatch();
  let location = useLocation();
  let { PatAppointmentId } = useParams();
  const socket  = useContext(SocketContext);
  console.log("socket==",socket);
  const [CancelModal, setCancelModal] = useState(false);
  const [PatSideAppId, setPatSideAppId] = useState("");
  const [activeBookMarked, setActiveBookMarked] = useState(false);
  const [Bookmarktoggle, setBookmarktoggle] = useState(false);
  // const [DocID, setDocID] = useState("");
  const [roomId, setRoomId] = useState('');

  const PatAppointmentData = useSelector((state) => state?.patient?.patientViewAppointmentData?.appointmentDetails);
  const getDoctorDetailsAction = async (payload) => dispatch(bookingAction.getDoctorDetailsForShowingAction(payload));

  const getDoctorDetails = useSelector((state) => state?.booking?.doctorDetailsforShowing?.result?.[0]);

  // get patient details
  const PatientProfileInfo = async () => dispatch(patient.patientProfileDetailsAction());
  const PatientData = useSelector((state) => state?.patient?.patientProdileInfo);
  
  const createRoomApi = async (payload) => dispatch(commonAction.createRoomApi(payload, "patient"));


  useEffect(() => {
    PatientProfileInfo();
  }, []);

  


  // useEffect(() => {
  //   if(PatAppointmentData?.serviceProviderId?._id){
  //     createRoomFun()
      
  //   }
  // }, [PatAppointmentData]);

  useEffect(() => {
    viewAppointmentDetail(PatAppointmentId);
  }, [PatAppointmentId]);

  const createRoomFun  = async ()=>{
        const payload  = {
              "receiverId" : PatAppointmentData?.serviceProviderId?._id
        } 
       const res  =  await createRoomApi(payload);
       if (res && res?.data?.roomID){
        let roomId  = res?.data?.roomID ;
        setRoomId(roomId);
        console.log("roomId",payload, roomId )
        // connectRoom(roomId)
        getSocketConnection();
    }
    console.log("Create room response", res )

  }

   const connectRoom =  (RoomID)=>{
    console.log('ReceiverDetails?.chatId' ,RoomID);
    if (RoomID){
      socket.emit('join_room' ,RoomID);
    }
 }

 const getSocketConnection = () => {
    // socket.connect();
    // client-side
    socket.on("connect", () => {
      const payload = {
        senderId: PatientData?.userData?._id,
      };
      console.log("socket connected"); 
      console.log('connectUser emitted doctor payload' , payload); // x8WIv7-mJelg7on_ALbx
      socket.emit("connectUser", payload);
      if(roomId){

        connectRoom(roomId)
      }
    });

  };

  console.log("PatientData",PatientData);
  useEffect(() => {
    if (Bookmarktoggle) {
      if (getDoctorDetails.isFavourite) {
        ToasterFunc("success", "Added to favourite list");
      } else {
        ToasterFunc("success", "Removed from favourite list");
      }
    }
  }, [getDoctorDetails]);

  const viewAppointmentDetail = async (payload) => dispatch(patient.viewAppointmentPatientAction(payload));

  const setFav = async (payload) => dispatch(patient.setFavourite(payload));

  const handleBookmarked = async () => {
    setBookmarktoggle(true);
    const payload = {
      userId: PatAppointmentData?.serviceProviderId?._id,
      favourite: !activeBookMarked,
    };
    console.log(payload);
    const res = await setFav(payload);
    if (res.status === 200) {
      setActiveBookMarked(!activeBookMarked);
      getDoctorDetailsAction(PatAppointmentData?.serviceProviderId?._id);
      // ToasterFunc("success", "Added to favourite list");
    }
  };

  console.log("location", location);

  const handleToInbox = (patData) => {
    console.log("patientData", patData, "PatientData", PatientData);
    const receiver_id = patData?.serviceProviderId?._id;
    const idData = {
      senderid: PatientData?.userData?._id,
      receiverid: receiver_id,
      userType: "patient",
      path: "Through_appointmentdetails",
      patientData: patData,
    };
    history.push(ROUTES_CONSTANTS.PATIENT_MESSAGES, idData);
  };

  const handleRescheduleApp = (Docid, AppId) => {
    console.log("PatAppointmentData",PatAppointmentData);
    if(PatAppointmentData?.appointmentStartTime){

    let appTimestamp = new Date(PatAppointmentData?.appointmentStartTime).getTime();
    let diffwithCurrentTime = appTimestamp - new Date().getTime();
    let diffHour = diffwithCurrentTime / 1000 / 60 / 60;
    if (diffHour > 1) {
    history.push(`${ROUTES_CONSTANTS.PATIENT_SIDE_RESCHEDULE_APPOINTMENT}/${AppId}`, Docid);
  } else {
    toast.error(`You have to reschedule appointment before 60 minute of appointment time`, {
      position: toast.POSITION.TOP_RIGHT,
    });
    return
  }
  }

  };

  useEffect(() => {
    console.log(moment(PatAppointmentData?.appointmentStartTime).format("LT"));
    console.log(new Date(PatAppointmentData?.appointmentStartTime).getTime());
    console.log(new Date().getTime());
  }, [PatAppointmentData]);

  const handleCall = (data) => {
    console.log(data.target.id, PatAppointmentData);
    if (new Date().getTime() < new Date(PatAppointmentData?.appointmentStartTime).getTime()) {
      ToasterFunc("error", "You can't call before your appointment time");
    } else {
      if (data.target.id === "video") {
        history.push(`${ROUTES_CONSTANTS.VIDEO_CALL}/${PatAppointmentData?._id}/${PatAppointmentData?.serviceProviderId?._id}/${data.target.id}/outgoing`, {'path' : "WFCM"});
        IsCallerDetails.next(true);
        saveToSessionStorage(
          "callerDetails",
          JSON.stringify({
            fullName: `${PatAppointmentData?.serviceProviderId?.fullName}`,
            profilePic: PatAppointmentData?.serviceProviderId?.profilePic,
          })
        );
      } else if (data.target.id === "audio") {
        history.push(`${ROUTES_CONSTANTS.VIDEO_CALL}/${PatAppointmentData?._id}/${PatAppointmentData?.serviceProviderId?._id}/${data.target.id}/outgoing`, {'path' : "WFCM"});
        IsCallerDetails.next(true);
        saveToSessionStorage(
          "callerDetails",
          JSON.stringify({
            fullName: `${PatAppointmentData?.serviceProviderId?.fullName}`,
            profilePic: PatAppointmentData?.serviceProviderId?.profilePic,
          })
        );
      }
    }
  };

  const handleDocNavigation = (item) => {
    history.push(`${ROUTES_CONSTANTS.PATIENT_VIEW_DOCTOR_DETAILS}/${item?._id}`);
  };

  return (
    <div className=" col-lg-8 col-xl-9 appointments pat-appointment-detail">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Appointment Details</h4>
        </div>
        <div className="card-body ">
          <div className="tab-content pt-0">
            <div role="tabpanel" id="Appointments-d" className="tab-pane fade show active appointments">
              <div className="appointment-list">
                <div className="profile-info-widget">
                  <a className="booking-doc-img" onClick={()=>handleDocNavigation(PatAppointmentData && PatAppointmentData.serviceProviderId)}>
                    <img src={PatAppointmentData && PatAppointmentData.serviceProviderId && PatAppointmentData.serviceProviderId.profilePic ? PatAppointmentData.serviceProviderId.profilePic : defaultImg} alt="User Image" />
                  </a>
                  <div className="profile-det-info">
                    <h3 onClick={()=>handleDocNavigation(PatAppointmentData && PatAppointmentData.serviceProviderId)}>
                      <a>{PatAppointmentData && PatAppointmentData.serviceProviderId && PatAppointmentData.serviceProviderId.fullName ? PatAppointmentData.serviceProviderId.fullName : ""}</a>
                    </h3>
                    <div className="patient-details">
                      <h5>
                        ${PatAppointmentData && PatAppointmentData.amount ? PatAppointmentData.amount : ""}{' '}
                        Consultation Fee
                      </h5>
                      <h5>
                        {PatAppointmentData && PatAppointmentData.serviceProviderId && PatAppointmentData.serviceProviderId.experience_in_years ? `${PatAppointmentData.serviceProviderId.experience_in_years} Years  ` : ""}
                        {PatAppointmentData && PatAppointmentData.serviceProviderId && PatAppointmentData.serviceProviderId.experience_in_months ? `${PatAppointmentData.serviceProviderId.experience_in_months} Months` : ""} of Experience
                      </h5>
                      <a data-toggle="modal" data-target="#speciality-popup" data-dismiss="modal">
                        <h6>{PatAppointmentData?.serviceProviderId?.primary_specialtyId?.primary_specialty}</h6>
                      </a>
                      <div className=" rating ">
                        <ReactStars edit={false} count={5} value={PatAppointmentData?.serviceProviderId?.rating} size={16} style={{ selfAlign: "center" }} color2={"#ffd700"} />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="fav-wapper">
                  {PatAppointmentData && PatAppointmentData?.status !== 'cancelled' &&   <div>
                  <div onClick={handleBookmarked} className={`${activeBookMarked ? "favourite" :"unfavourite"} sm-fav-bx`}>
                    <i className={activeBookMarked ? "fa fa-bookmark" : "far fa-bookmark"}></i> <span>{!activeBookMarked?"Mark as favourite" : "Unmark as favourite"}</span>
                   </div>
                   <div class="appointment-action sm-appt-action">
                  <a onClick={() => handleToInbox(PatAppointmentData)} className="btn btn-sm bg-info-light">
                    Send Message
                  </a>
                  </div>
                </div>}
              </div>
              </div>
              <div className="card">
                <div className="card-body">
                  <h4>
                    <b>Appointment details</b>
                  </h4>
                  <div>
                    <span className="color-grey ">
                      <b>Date:</b>
                      {PatAppointmentData && PatAppointmentData.appointmentStartTime ? new Date(PatAppointmentData.appointmentStartTime).toLocaleDateString() : "--"}
                    </span>
                  </div>
                  <div>
                    <span className="color-grey">
                      <b>Time:</b>
                      {moment(PatAppointmentData && PatAppointmentData.appointmentStartTime).format("LT")} - {moment(PatAppointmentData && PatAppointmentData.appointmentEndTime).format("LT")}
                    </span>
                  </div>
                  <h4 className="mt-4 ">
                    <b>Reason for Appointment</b>
                  </h4>
                  <div className="mb-4">
                    <span className="color-grey ">{PatAppointmentData && PatAppointmentData.reason ? PatAppointmentData.reason : ""}</span>
                  </div>
                  <h4 className="mt-4 ">
                    <b>Medical History</b>
                  </h4>
                  <div className="card">
                    <div className="card-body">
                      {PatAppointmentData && PatAppointmentData.document ? (
                        <React.Fragment>
                          <a href={PatAppointmentData.document}>
                            <div>
                              <span>
                                <i className="fa fa-file-pdf fa-3x"></i>
                              </span>
                            </div>
                          </a>
                        </React.Fragment>
                      ) : (
                        <div>
                          <span>No attachments found.</span>
                        </div>
                      )}
                    </div>
                  </div>
                  {location?.state?.status === "cancelled" ? (
                    ""
                  ) : (
                    <div className="row">
                      <div className="col-md-6 col-lg-3 col-sm-6 col-6">
                        <a className="btn book-btn mb-2" onClick={() => handleRescheduleApp(PatAppointmentData.serviceProviderId._id, PatAppointmentData._id)}>
                          Reschedule
                        </a>
                      </div>
                      <div className="col-md-6 col-lg-3 col-sm-6 col-6">
                        <a href id="video" className="btn book-btn mb-2" onClick={handleCall}>
                          Video Call
                        </a>
                      </div>
                      <div className="col-md-6 col-lg-3 col-sm-6 col-6">
                        <a href id="audio" className="btn book-btn mb-2" onClick={handleCall}>
                          Audio Call
                        </a>
                      </div>
                      <div className="col-md-6 col-lg-3 col-sm-6 col-6">
                        <a
                          className="btn view-btn mb-2"
                          onClick={() => {
                            setCancelModal(!CancelModal);
                            setPatSideAppId(PatAppointmentData._id);
                          }}
                        >
                          Cancel
                        </a>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {CancelModal && <CancelAppointment CancelModal={CancelModal} setCancelModal={setCancelModal} PatSideAppId={PatSideAppId} />}
      
    </div>
  );
}

export default ViewAppointmentOfPatient;
