import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../constants";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { authentication } from "../../../redux/action";
import ToasterFunc from "../../common/toaster/Toaster";
import { useHistory } from "react-router-dom";
import { getSessionStorageOrDefault, saveToSessionStorage } from "../../../utils";

function LoginWithNumber({ setLoginWithNumModal, setSignInModal, LoginWithNumModal, handleForgetPasswordToggle }) {
  let history = useHistory();
  let dispatch = useDispatch();
  const [SignInWithMobileToggle, setSignInWithMobileToggle] = useState(false);
  const [showPwd, setShowPwd] = useState({
    oldPwd: false,
    newPwd: false,
    ConPwd: false,
  });

  const formik = useFormik({
    initialValues: {
      mobile: "",
      password: "",
    },
    validationSchema: Yup.object({
      mobile: Yup.string()
        .matches(/^[1-9][0-9]{9,13}$/g, "Invalid phone number")
        .required("*Mobile number is required."),
      password: Yup.string().required("*Password is required"),
    }),
    onSubmit: (values) => {
      const payload = {
        mobile: values.mobile,
        password: values.password,
      };
      handleSignIn();
    },
  });
  const handleClose = () => {
    sessionStorage.clear();
    setLoginWithNumModal(false);
    formik.values.mobile = "";
    formik.values.password = "";
  };

  const SignInUser = async (payload) => dispatch(authentication.signInAction(payload));

  const signInPatientUser = async (payload) => dispatch(authentication.signInPatientAction(payload));

  const handleSignIn = async (e) => {
    sessionStorage.setItem("SignInToggle", "True");
    if (formik.values.mobile !== "" && formik.values.password !== "") {
      if (getSessionStorageOrDefault("userType") == "1") {
        const res = await SignInUser(formik.values);
        if (res && res.status == 200) {
          formik.values.mobile = "";
          formik.values.password = "";

          saveToSessionStorage("authToken", res.data.result.token);
          if (res.data.result.isVerified) {
            if (res.data.result.isPersonal_profile_complete) {
              if (res.data.result.isProfessional_profile_complete) {
                setSignInModal(false);
                setLoginWithNumModal(false);
                history.push(ROUTES_CONSTANTS.DASHBOARD);
              } else {
                setSignInModal(false);
                setLoginWithNumModal(false);
                history.push(ROUTES_CONSTANTS.DOC_PROFESSIONAL_SIGNUP);
              }
            } else {
              setSignInModal(false);
              setLoginWithNumModal(false);
              history.push(ROUTES_CONSTANTS.DOC_PERSONAL_SIGNUP);
            }
          } else {
            saveToSessionStorage("isLoggedIn", "true");
            ToasterFunc("success", res.data.responseMessage);
            setLoginWithNumModal(false);
            history.push(ROUTES_CONSTANTS.DASHBOARD);
          }
        }
      } else if (getSessionStorageOrDefault("userType") == "2") {
        const res = await signInPatientUser(formik.values);
        if (res && res.status == 200) {
          formik.values.mobile = "";
          formik.values.password = "";
          saveToSessionStorage("authToken", res.data.result.token);
          console.log("patient login res====", res);
          if (res.data.result.isVerified) {
            if (res.data.result.isPersonal_profile_complete) {
              setSignInModal(false);
              setLoginWithNumModal(false);
              saveToSessionStorage("isLoggedIn", "true");
              history.push(ROUTES_CONSTANTS.PATIENT_DASHBOARD);
            } else {
              setSignInModal(false);
              setLoginWithNumModal(false);
              history.push(ROUTES_CONSTANTS.PAT_PERSONAL_SIGNUP);
            }
          } else {
            ToasterFunc("success", res.data.responseMessage);
            setLoginWithNumModal(false);
            setSignInModal(false);
            // history.push(ROUTES_CONSTANTS.PATIENT_DASHBOARD);
          }
        }
      }
    }
  };

  return (
    <Modal isOpen={LoginWithNumModal} className="sign-modal modal-dialog-centered index-modalBody">
      <div class="modal-content">
        <button type="button" class="close custom-model-close" aria-label="Close" data-dismiss="modal" onClick={handleClose}>
          <span aria-hidden="true">&times;</span>
        </button>

        <ModalBody>
          <div class="modal-body">
            <div class="container-fluid">
              <div class="account-content">
                <div class="row">
                  <div class="col-md-5 login-left m-auto">
                    <img src={IMAGE_CONSTANT.LOGIN_BANNER} class="img-fluid" alt="Doccure Login" />
                  </div>
                  <div class="col-md-7  login-right">
                    <div class="login-header">
                      <h3>Sign In With Phone Number</h3>
                      <p class="small text-muted">Please enter the details below to continue.</p>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                      <div class="form-group">
                        <div class="input-group">
                          <div class="input-group-prepend ">
                            <span class="input-group-text">+1</span>
                          </div>
                          <input
                            type="text"
                            class="form-control"
                            placeholder="Mobile Number"
                            name="mobile"
                            maxLength="10"
                            {...formik.getFieldProps("mobile")}
                          />
                        </div>
                        {formik.touched.mobile && formik.errors.mobile ? (
                          <span className="validation_error">{formik.errors.mobile}</span>
                        ) : null}
                      </div>
                      <div class="form-group pharmacy-home-slider">
                        <input
                          type={showPwd && showPwd.oldPwd ? "text" : "password"}
                          class="form-control"
                          name="password"
                          placeholder="Password"
                          {...formik.getFieldProps("password")}
                        />
                        <span class="eye-icon">
                          <i
                            class="fa fa-eye"
                            onMouseUp={() => setShowPwd({ oldPwd: false })}
                            onMouseDown={() => setShowPwd({ oldPwd: true })}
                          ></i>
                        </span>
                        {formik.touched.password && formik.errors.password ? (
                          <span className="validation_error">{formik.errors.password}</span>
                        ) : null}
                      </div>
                      <div class="text-right">
                        <a
                          class="forgot-link"
                          data-toggle="modal"
                          data-target="#patforgtP_Modal"
                          data-dismiss="modal"
                          onClick={handleForgetPasswordToggle}
                        >
                          Forgot Password ?
                        </a>
                      </div>
                      <button href="doctor-dashboard.html" class="btn btn-primary btn-block btn-lg login-btn " type="submit">
                        Sign In
                      </button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default LoginWithNumber;
