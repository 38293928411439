// import TimePicker from "rc-time-picker";
import React, { useEffect, useState } from "react";
import { IMAGE_CONSTANT } from "../../constants";
import EditModal from "./EditModal";
import moment from "moment";
import TimePicker from "rc-time-picker";
import { useSelector } from "react-redux";

// import { IMAGE_CONSTANT } from "../../constants";
import {
  convertUTCToLocal,
  DAYS,
  TIME_INTERVEL,
} from "../authentication/sign-up-as-doctor/availability";

function ScheduleAvailability() {
  const format = "h:mm A";
  const [availability, setAvailability] = useState(null);
  const [EditModals, setEditModals] = useState(false);

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [timeSlotDay, setTimeSlotDay] = useState("");
  const [intervel, setIntervel] = useState(30);

  const [currentDay, setCurrentDay] = useState(0);
  const docData = useSelector((state) => state?.doctor?.doctorProdileInfo);

  const handleEdit = () => {
    setEditModals(!EditModals);
    console.log("clicked");
  };

  const isGreater = (time) => {
    time = time ? new moment.utc(time, "HH:mm A").local() : null;
    const end = time ? new moment.utc(endDate, "HH:mm A").local() : null;
    return time.add(intervel, "minutes") > end;
  };

 

  useEffect(async () => {
    window.scrollTo(0, 0);
    if (docData?.result[0]?.availability) {
      const availabilityData = await docData?.result[0]?.availability;
      const dayArr  = DAYS.map(x=>  x.day);
      const dayIndex  = dayArr.indexOf(availabilityData[0].day)
      setCurrentDay(dayIndex);
      const newData = await DAYS.map((data) => {
        return {
          ...data,
          isChecked:
            availabilityData.filter(
              (availabilityDays) => availabilityDays.day === data.day
            )?.[0]?.slots.length > 0,
          startTime: availabilityData.filter(
            (availabilityDays) => availabilityDays.day === data.day
          )?.[0]?.startTime,
          endTime: availabilityData.filter(
            (availabilityDays) => availabilityDays.day === data.day
          )?.[0]?.endTime,
          slots: availabilityData.filter(
            (availabilityDays) => availabilityDays.day === data.day
          )?.[0]?.slots,
          interval: availabilityData.filter(
            (availabilityDays) => availabilityDays.day === data.day
          )?.[0]?.interval,
        };
      });
      setAvailability([...newData]);
    }
  }, [docData]);

  const addIntervel = (time) => {
    time = time ? new moment.utc(time, "HH:mm A").local() : null;
    time.add(availability?.[currentDay]?.interval, "minutes");
    return new moment(time, ["HH.mm"]).utc().format("hh:mm A");
  };

  return (
    <>
      <div className=" col-lg-8 col-xl-9 col-md-9 schedule-availability">
        <div className="row">
          <div className="col-sm-12">
            <div className="card">
              <div className="card-header d-flex time_bx">
                <h4 className="card-title">Schedule availability</h4>
                <button
                  onClick={handleEdit}
                  className="btn btn-primary btn-new"
                >
                  Edit
                </button>
              </div>
              <div className="card-body">
                <div className="profile-box">
                  <div className="row">
                    <div className="col-lg-4">
                      <div className="form-group">
                        <label>Timing Slot Duration</label>
                        <select className="select form-control" disabled>
                          <option>
                            {
                               TIME_INTERVEL.filter(
                                (data) =>
                                  data.value === availability?.[currentDay]?.interval
                              )?.[0]?.time
                            }
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card schedule-widget mb-0">
                        <div className="schedule-header">
                          <div className="schedule-nav">
                            <ul className="nav nav-tabs nav-justified">
                              {availability?.map((day, index) => {
                                return (
                                  <li
                                    className="nav-item blog-content"
                                    onClick={() => setCurrentDay(index)}
                                  >
                                    {/* {console.log(day)} */}
                                    <a
                                      className={
                                        day.isChecked
                                          ? "nav-link active "
                                          : "nav-link"
                                      }
                                      data-toggle="tab"
                                    >
                                      {day.fullname}
                                    </a>
                                  </li>
                                );
                              })}
                            </ul>
                            <div className="row align-items-center mt-3">
                              <div className="col-lg-3 col-md-4 col-12">
                                <div className="form-group">
                                  <label for="Start Time">Start Time</label>
                                  <div className="custom-time-picker">
                                  <TimePicker
                                    disabled={true}
                                    showSecond={false}
                                    value={
                                      availability?.[currentDay]?.startTime
                                        ? new moment.utc(
                                            availability?.[
                                              currentDay
                                            ]?.startTime,
                                            "HH:mm A"
                                          ).local()
                                        : null
                                    }
                                    className="timePickerClass"
                                    format={format}
                                    use12Hours
                                    placeholder="--:-- --"
                                    inputIcon={
                                      !availability?.[currentDay]
                                        ?.startTime && (
                                        <img
                                          src={IMAGE_CONSTANT.CLOCK_IMG}
                                          alt="clock"
                                          className="clockClass"
                                        />
                                      )
                                    }
                                  />
                                  </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 col-12">
                                <div className="form-group">
                                  <label for="end Time">End Time</label>
                                  <div className="custom-time-picker">
                                  <TimePicker
                                    disabled={true}
                                    showSecond={false}
                                    value={
                                      availability?.[currentDay]?.endTime
                                        ? new moment.utc(
                                            availability?.[currentDay]?.endTime,
                                            "HH:mm A"
                                          ).local()
                                        : null
                                    }
                                    className="timePickerClass"
                                    format={format}
                                    use12Hours
                                    placeholder="--:-- --"
                                    inputIcon={
                                      !availability?.[currentDay]?.endTime && (
                                        <img
                                          src={IMAGE_CONSTANT.CLOCK_IMG}
                                          alt="clock"
                                          className="clockClass"
                                        />
                                      )
                                    }
                                    />
                                    </div>
                                </div>
                              </div>
                              <div className="col-lg-3 col-md-4 col-12 d-flex"></div>
                            </div>
                          </div>
                        </div>
                        <div className="card-body pt-0">
                          <div className="tab-content schedule-cont">
                            <div id="slot_sunday" className="tab-pane fade">
                              <h4 className="card-title d-flex justify-content-between">
                                <span>Time Slots</span>
                              </h4>
                              <p className="text-muted mb-0">Not Available</p>
                            </div>
                            <div
                              id="slot_monday"
                              className="tab-pane fade show active"
                            >
                              <h4 className="card-title d-flex justify-content-between">
                                <span>Time Slots</span>
                              </h4>

                              <div className="doc-times">
                                {availability?.[currentDay]?.slots?.map(
                                  (item, index) => {
                                    return (

                                      (  <div
                                        className="doc-slot-list"
                                        key={Math.random()}
                                      >
                                        {`${convertUTCToLocal(
                                          item.slotTime
                                        )} - ${convertUTCToLocal(
                                          addIntervel(item.slotTime)
                                        )}`}
                                      </div>)
                                    );
                                  }
                                )}
                              </div>
                            </div>
                            <div id="slot_tuesday" className="tab-pane fade">
                              <h4 className="card-title d-flex justify-content-between">
                                <span>Time Slots</span>
                              </h4>
                              <p className="text-muted mb-0">Not Available</p>
                            </div>
                            <div id="slot_wednesday" className="tab-pane fade">
                              <h4 className="card-title d-flex justify-content-between">
                                <span>Time Slots</span>
                              </h4>
                              <p className="text-muted mb-0">Not Available</p>
                            </div>
                            <div id="slot_thursday" className="tab-pane fade">
                              <h4 className="card-title d-flex justify-content-between">
                                <span>Time Slots</span>
                              </h4>
                              <p className="text-muted mb-0">Not Available</p>
                            </div>
                            <div id="slot_friday" className="tab-pane fade">
                              <h4 className="card-title d-flex justify-content-between">
                                <span>Time Slots</span>
                              </h4>
                              <p className="text-muted mb-0">Not Available</p>
                            </div>
                            <div id="slot_saturday" className="tab-pane fade">
                              <h4 className="card-title d-flex justify-content-between">
                                <span>Time Slots</span>
                              </h4>
                              <p className="text-muted mb-0">Not Available</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <EditModal
        AvaialabiltyModal={EditModals}
        setAvaialabiltyModal={setEditModals}
        startDate={startDate}
        setStartDate={setStartDate}
        setEndDate={setEndDate}
        endDate={endDate}
        timeSlotDay={timeSlotDay}
        setTimeSlotDay={setTimeSlotDay}
        intervel={intervel}
        setIntervel={setIntervel}
        setAvailability={setAvailability}
        availability={availability}
      />
    </>
  );
}

export default ScheduleAvailability;
