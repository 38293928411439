import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Toast } from "reactstrap";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../constants";
import { useState, useEffect } from "react";
import { ForgotPassword } from "..";
import { LoginWithNumber } from "..";
import { SignUp } from "../signup";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { authentication } from "../../../redux/action";
import ToasterFunc from "../../common/toaster/Toaster";
import { VerifyOtp } from "..";
import { getSessionStorageOrDefault, saveToSessionStorage } from "../../../utils";

function Login({ SignInModal, setSignInModal, handleSignUpToggle, handleLoginWithMobiToggle, handleForgetPasswordToggle, DocID }) {
  let history = useHistory();
  const dispatch = useDispatch();
  const [VerifyOtpModal, setVerifyOtpModal] = useState(false);
  const [LateSignUpToggle, setLateSignUpToggle] = useState(false);
  const [showPwd, setShowPwd] = useState({
    oldPwd: false,
    newPwd: false,
    ConPwd: false,
  });
  const [ForgetPwdModal, setForgetPwdModal] = useState(false);
  const [LoginWithNumModal, setLoginWithNumModal] = useState(false);
  const [SignUpModal, setSignUpModal] = useState(false);
  const [Open, setOpen] = useState(false);

  const SignInUser = async (payload) => dispatch(authentication.signInAction(payload));

  const signInPatientUser = async (payload) => dispatch(authentication.signInPatientAction(payload));

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("*Email is required."),
      password: Yup.string().required("*Password is required"),
    }),
    onSubmit: (values) => {
      const payload = {
        email: values.email,
        password: values.password,
      };
    },
  });

  const handleClose = () => {
    setSignInModal(false);
    sessionStorage.clear();
    formik.values.email = "";
    formik.values.password = "";
  };

  // const handleForgetPwd=()=>{
  //     setForgetPwdModal(!ForgetPwdModal)
  // }

  // const handleSignInWithNum=()=>{
  //     setLoginWithNumModal(!LoginWithNumModal)

  // }
  // const handleSignUp=()=>{
  //     setSignUpModal(!SignUpModal)
  // }

  console.log("formik values", formik.values);
  console.log("formik errors", formik.errors);

  const handleSignIn = async (e) => {
    sessionStorage.removeItem("SignInToggle");
    if (formik.values.email !== "" && formik.values.password !== "") {
      console.log("clicked 77");
      if (getSessionStorageOrDefault("userType") == "1") {
        console.log("click 79");
        const res = await SignInUser(formik.values);
        if (res && res.status == 200) {
          formik.values.email = "";
          formik.values.password = "";
          ToasterFunc("success", res.data.responseMessage);
          saveToSessionStorage("authToken", res.data.result.token);
          // saveToSessionStorage("authToken", res.data.result.token);
          console.log("signinresponzzz", res);
          if (res.data.result.isVerified) {
            if (res.data.result.isPersonal_profile_complete) {
              if (res.data.result.isProfessional_profile_complete) {
                setSignInModal(false);
                saveToSessionStorage("isLoggedIn", "true");
                saveToSessionStorage("UserData", JSON.stringify(res.data.result));
                history.push(ROUTES_CONSTANTS.DASHBOARD);
              } else {
                setSignInModal(false);
                if (!res.data.result?.isSubscribe){
                  history.push(ROUTES_CONSTANTS.DOC_PERSONAL_SIGNUP);
                }else{
                  history.push(ROUTES_CONSTANTS.DOC_PROFESSIONAL_SIGNUP);
                }
                
              }
            } else {
              setSignInModal(false);
              history.push(ROUTES_CONSTANTS.DOC_PERSONAL_SIGNUP);
            }
          } else {
            setSignInModal(false);
            setLateSignUpToggle(true);
            // sessionStorage.setItem("isLoggedIn", true);
            saveToSessionStorage("DocUserId", res.data.result._id);
            setVerifyOtpModal(!VerifyOtpModal);
          }
        }
      } else if (getSessionStorageOrDefault("userType") == "2") {
        const res = await signInPatientUser(formik.values);
        if (res && res.status == 200) {
          formik.values.email = "";
          formik.values.password = "";
          saveToSessionStorage("authToken", res.data.result.token);
          console.log("signinresponzzz", res);
          console.log("DocIDDDDDDDDDDDDDDDDDD", DocID);
          if (DocID) {
            // history.push(`${ROUTES_CONSTANTS.PATIENT_VIEW_DOCTOR_DETAILS}/${DocID}`);
            history.push(`${ROUTES_CONSTANTS.DOCTOR_PROFILE}/${DocID}`);
          } else {
            if (res.data.result.isVerified) {
              if (res.data.result.isPersonal_profile_complete) {
                setSignInModal(false);
                saveToSessionStorage("isLoggedIn", "true");
                saveToSessionStorage("UserData", JSON.stringify(res.data.result));
                history.push(ROUTES_CONSTANTS.PATIENT_DASHBOARD);
              } else {
                setSignInModal(false);
                history.push(ROUTES_CONSTANTS.PAT_PERSONAL_SIGNUP);
              }
            } else {
              setSignInModal(false);
              setLateSignUpToggle(true);
              // sessionStorage.setItem("isLoggedIn", true);
              saveToSessionStorage("PatUserId", res.data.result._id);
              setVerifyOtpModal(!VerifyOtpModal);
            }
          }
        }
      }
    }
  };

  const handledataRemove = () => {
    formik.values.email = "";
    formik.values.password = "";
  };

  const handleForgetPwd = () => {
    setForgetPwdModal(!ForgetPwdModal);
    setSignInModal(false);
    setLoginWithNumModal(false);
  };

  const handleLoginWithMobi = () => {
    setLoginWithNumModal(!LoginWithNumModal);
    setSignInModal(false);
  };

  return (
    <>
      <Modal className="sign-modal modal-dialog-centered index-modalBody" isOpen={SignInModal}>
        {/* <div className="modal fade index-modalBody" id="docTor-login" tabindex="-1" role="dialog"> */}
        {/* <div className="modal-dialog modal-lg  modal-dialog-centered unique_style" role="document"> */}
        {/* <div className="modal-content"> */}
        <button type="button" className="close custom-model-close" aria-label="Close" data-dismiss="modal" onClick={handleClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <ModalBody>
          {/* <ToastContainer /> */}
          {/* <div className="modal-body"> */}
          {/* <!-- <div className="content"> --> */}
          <div className="container-fluid">
            {/* <!-- Login Tab Content --> */}
            <div className="account-content">
              <div className="row">
                <div className="col-lg-5 col-md-5 login-left m-auto">
                  {/* <!-- <div className="align-items-center justify-content-center"> --> */}
                  <img src={IMAGE_CONSTANT.LOGIN_BANNER} className="img-fluid" alt="Doccure Login" />
                  {/* <!-- </div> --> */}
                </div>
                <div className="col-lg-7 col-md-7  login-right">
                  <div className="login-header">
                    <h3>Sign In </h3>
                    <p className="small text-muted">Please enter the details below to continue</p>
                  </div>
                  <form onSubmit={formik.handleSubmit}>
                    <div className="form-group">
                      <input type="email" className="form-control" name="email" placeholder="Email" {...formik.getFieldProps("email")} />
                      {formik.touched.email && formik.errors.email ? <span className="validation_error">{formik.errors.email}</span> : null}
                    </div>
                    <div className="form-group pharmacy-home-slider">
                      <input
                        type={showPwd && showPwd.oldPwd ? "text" : "password"}
                        name="password"
                        className="form-control"
                        placeholder="Password"
                        {...formik.getFieldProps("password")}
                      />
                      <span className="eye-icon">
                        <i
                          className="fa fa-eye"
                          onMouseUp={() => setShowPwd({ oldPwd: false })}
                          onMouseDown={() => setShowPwd({ oldPwd: true })}
                        ></i>
                      </span>
                      {formik.touched.password && formik.errors.password ? (
                        <span className="validation_error">{formik.errors.password}</span>
                      ) : null}
                    </div>

                    <div className="text-right">
                      <a
                        className="forgot-link"
                        data-toggle="modal"
                        data-target="#docforgtP_Modal"
                        data-dismiss="modal"
                        // onClick={handleForgetPwd}
                        onClick={() => {
                          // handleForgetPasswordToggle();
                          handleForgetPwd();
                        }}
                      >
                        Forgot Password ?
                      </a>
                    </div>
                    <button className="btn btn-primary btn-block btn-lg login-btn" type="submit" onClick={(e) => handleSignIn(e)}>
                      Sign In
                    </button>
                    <button
                      className="btn btn-primary btn-block btn-lg signUp-btn"
                      type="submit"
                      //    onClick={handleSignInWithNum}
                      onClick={() => {
                        // handleLoginWithMobiToggle();
                        handleLoginWithMobi();
                        handledataRemove();
                      }}
                    >
                      Sign In with Phone Number
                    </button>

                    <div className="text-center dont-have" data-toggle="modal" data-target="#docSugnup_Modal" data-dismiss="modal">
                      Don’t have an account?
                      <a
                        className="ml-1"
                        // onClick={handleSignUp}
                        onClick={() => {
                          handleSignUpToggle();
                          handledataRemove();
                        }}
                      >
                        Signup
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </ModalBody>
        {/* </div> */}
      </Modal>
      {VerifyOtpModal ? (
        <VerifyOtp setVerifyOtpModal={setVerifyOtpModal} VerifyOtpModal={VerifyOtpModal} LateSignUpToggle={LateSignUpToggle} />
      ) : null}
      <ForgotPassword ForgetPwdModal={ForgetPwdModal} setForgetPwdModal={setForgetPwdModal} setSignInModal={setSignInModal} />
      <LoginWithNumber
        LoginWithNumModal={LoginWithNumModal}
        setLoginWithNumModal={setLoginWithNumModal}
        handleLoginWithMobiToggle={handleLoginWithMobiToggle}
        handleForgetPasswordToggle={handleForgetPasswordToggle}
        setSignInModal={setSignInModal}
      />
    </>
  );
}

export default Login;
