import { httpService } from "..";
import { toast } from "react-toastify";
import ToasterFunc from "../../components/common/toaster/Toaster";
import { API_CONSTANTS } from "../../constants";
import { getSessionStorageOrDefault } from "../../utils";

class authenticationServices {
  getCountryList() {
    httpService.get("common/countryStateList");
  }

  async signUpUser(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.DOCTOR_SIGN_UP,
        data
      );

      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async signUpDoctorPersonalDetails(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.DOCTOR_PERSONAL_SIGN_UP,
        data
      );

      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async signUpPatientUser(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.PATIENT_SIGN_UP,
        data
      );

      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async signInUser(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.DOCTOR_SIGN_IN,
        data
      );

      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async signInPatientUser(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.PATIENT_SIGN_IN,
        data
      );

      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async signInPatientWithMobileNumberUser(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.PATIENT_SIGN_IN,
        data
      );

      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async forgetPwdDocUser(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.DOCTOR_FORGET_PWD,
        data
      );

      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async forgetPwdPatUser(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.PATIENT_FORGET_PWD,
        data
      );

      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async verifyOtp(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.DOCTOR_VERIFY_OTP,
        data
      );
      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async verifyOtpPatient(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.PATIENT_VERIFY_OTP,
        data
      );
      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async resendOtp(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.DOCTOR_RESEND_OTP,
        data
      );
      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async resendOtpPatient(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.PATIENT_RESEND_OTP,
        data
      );
      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async resetPwdDocUser(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.DOCTOR_RESET_PWD,
        data
      );
      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async resetPwdPatUser(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.PATIENT_RESET_PWD,
        data
      );
      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async personalPatUser(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.PATIENT_PERSONAL_PROFILE,
        data
      );
      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async subscriptionListDoctor() {
    try {
      const response = await httpService.get(
        API_CONSTANTS.DOCTOR_SUBSCRIPTION_LIST
      );
      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async subscriptionListPatient() {
    try {
      const response = await httpService.get(
        API_CONSTANTS.PATIENT_SUBSCRIPTION_LIST
      );
      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }
  async getPatientTax() {
    try {
      const response = await httpService.get(
        API_CONSTANTS.PATIENT_GET_TAX
      );
      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async getDoctorTax() {
    try {
      const response = await httpService.get(
        API_CONSTANTS.DOCTOR_GET_TAX
      );
      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async couponList() {
    try {
      const userType = await getSessionStorageOrDefault("userType");
      const response = await httpService.get(
        userType == "1"
          ? API_CONSTANTS.DOCTOR_COUPON_LIST
          : API_CONSTANTS.PATIENT_COUPON_LIST
      );
      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async paymentAccountPatient(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.PATIENT_PAYMENT_ACCOUNT,
        data
      );
      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async paymentAccountPatientUpgrade(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.PATIENT_PAYMENT_ACCOUNT_UPGRADE,
        data
      );
      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async paymentAccountDoctor(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.DOCTOR_PAYMENT_ACCOUNT,
        data
      );
      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }
  async medicalProfilePatient(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.PATIENT_MEDICAL_DETAILS,
        data
      );
      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async doctorPrimarySpecialityList() {
    try {
      const response = await httpService.get(
        API_CONSTANTS.DOCTOR_PRIMARY_SPECIALITY_LIST
      );
      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async doctorSecondarySpecialityList(id) {
    try {
      const response = await httpService.get(
        `${API_CONSTANTS.DOCTOR_SECONDARY_SPECIALITY_LIST}${id}`
      );
      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async signUpForProfessional(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.DOCTOR_PROFESSIONAL_PROFILE,
        data
      );
      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }

  async paymentAccountDoctorUpgrade(data) {
    try {
      const response = await httpService.post(
        API_CONSTANTS.DOCTOR_PAYMENT_ACCOUNT_UPGRADE,
        data
      );
      return response;
    } catch (err) {
      toast.error(err.response.data.responseMessage, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return err.response;
    }
  }
}

export default new authenticationServices();
