import React, { Component } from "react";
import Participant from "./Participant";

class Room extends Component {
  constructor(props) {
    super(props);
    this.state = {
      remoteParticipants: Array.from(this.props.room?.participants.values()),
    };

    this.leaveRoom = this.leaveRoom.bind(this);
  }

  componentDidMount() {
    console.log("setIsParticipentJoined", this.state.remoteParticipants.length);
    if (this.state.remoteParticipants.length > 0) {
      this.props?.setIsParticipentJoined &&
        this.props.setIsParticipentJoined(true);
      this.props.audio?.current?.pause && this.props.audio?.current.pause();
    }
    this.props.room.on("participantConnected", (participant) => {
      console.log("participant connected");
      this.addParticipant(participant);
      this.props?.setIsParticipentJoined &&
        this.props.setIsParticipentJoined(true);
      this.props?.startTimer && this.props?.startTimer();
    });
    this.props.room.on("participantDisconnected", (participant) =>
      this.removeParticipant(participant)
    );
    this.leaveIfNotJoined();

    window.addEventListener("beforeunload", this.leaveRoom);
  }

  componentWillUnmount() {
    console.log("left");
    this.leaveRoom();
  }

  leaveIfNotJoined = () => {
    setTimeout(() => {
      if (this.state.remoteParticipants.length === 0) this.leaveRoom();
      console.log(10000);
    }, 60000);
  };

  addParticipant(participant) {
    this.props.audio?.current?.pause && this.props.audio?.current.pause();
    this.setState({
      remoteParticipants: [...this.state.remoteParticipants, participant],
    });
  }

  removeParticipant(participant) {
    this.setState({
      remoteParticipants: this.state.remoteParticipants.filter(
        (p) => p.identity !== participant.identity
      ),
    });
    this.leaveRoom();
  }

  leaveRoom() {
    const { audio } = this.props;
    audio?.current?.pause && audio.current.pause();
    this.props.room?.disconnect();
    this.props?.returnToLobby();
  }

  render() {
    const { isViewSwitch } = this.props;

    return (
      <>
        <div className="vdo_box">
        <figure>
          {!isViewSwitch ? (
            this.state.remoteParticipants.map((participant) => (
              <Participant
                key={participant.identity}
                participant={participant}
              />
            ))
          ) : (
            <Participant
              key={this.props.room.localParticipant.identity}
              localParticipant="true"
              participant={this.props.room.localParticipant}
            />
          )}
        </figure>
        <div className="userDisplay">
          <figure>
            {!isViewSwitch ? (
              <Participant
                key={this.props.room.localParticipant.identity}
                localParticipant="true"
                participant={this.props.room.localParticipant}
              />
            ) : (
              this.state.remoteParticipants.map((participant) => (
                <Participant
                  key={participant.identity}
                  participant={participant}
                />
              ))
            )}
          </figure>
        </div>
        </div>
      </>
    );
  }
}

export default Room;
