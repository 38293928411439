import moment from "moment";
import { useEffect, useState } from "react";
import PaginationComponent from "react-reactstrap-pagination";
import { useDispatch, useSelector } from "react-redux";
import ReactStars from "react-stars";
import { IMAGE_CONSTANT } from "../../../../constants";
import { ratingAction } from "../../../../redux/action";
import AddRatingModal from "../Appointment/CompletedViewApp.js/AddRatingModal";

function PatientReviews() {
  const dispatch = useDispatch();
  const [userId, setUserId] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [RatingModal, setRatingModal] = useState(false);
  const ratingListApi = async (pageNumber) => dispatch(ratingAction.getReviewListAction(pageNumber));

  const ratingListData = useSelector((state) => state.rating.ratingList);

  console.log("ratingListData", ratingListData);
  useEffect(() => {
    window.scrollTo(0, 0);
    ratingListApi(pageNumber);
  }, []);

  const handleRatingModal = async (data) => {
    await setUserId(data);
    await setRatingModal(true);
  };

  useEffect(() => {
    ratingListApi(pageNumber);
  }, [pageNumber]);

  return (
    <div class=" col-lg-8 col-xl-9 content_ipad_p">
      {console.log("$$$$$$$", ratingListData)}
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Reviews</h4>
        </div>
        <div class="card-body">
          <div class="doc-review review-listing">
            <ul class="comments-list row">
              {ratingListData?.documentList?.length ? (
                ratingListData?.documentList?.map((data) => {
                  return (
                    <li className="col-md-6">
                      <div class="comment">
                        <img class="avatar rounded-circle" alt="" src={data?.doctorId?.profilePic} />
                        <div class="comment-body">
                          <div class="meta-data">
                            <span class="comment-author">{data?.doctorId?.fullName}</span>
                            <span class="comment-date review-count">{moment(data?.createdAt).format("DD/MM/YYYY")}</span>
                            <div class="color-grey">{`$${data?.doctorId?.serviceCharge} Consultation Fee`}</div>
                            <div class="color-grey">{`${data?.doctorId?.experience_in_years} years ${data?.doctorId?.experience_in_months} months of Experience`}</div>
                            <div class="color-grey">{data?.doctorId?.secondry_specialty?.length > 1 ? "Multi Speciality" : data?.doctorId?.secondry_specialty[0]?.secondry_specialty}</div>
                            <div class=" rating">
                              <ReactStars count={5} value={data?.rating} size={16} color2={"#ffd700"} edit={false} />
                            </div>
                          </div>
                          <p class="comment-content mb-0">{data.review}</p>
                          <div class="text-right">
                            <button type="submit" class="btn  btn-primary " data-toggle="modal" data-target="#editReview" data-dismiss="modal" onClick={() => handleRatingModal(data)}>
                              <span>
                                <i class="fas fa-edit mr-1"></i>
                              </span>
                              Edit
                            </button>
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })
              ) : (
                <div className="col-md-12">
                  <p className="text-center">There are currently no reviews available.</p>
                </div>
              )}
            </ul>
          </div>
          {ratingListData && ratingListData.length > 5 ? (
            <PaginationComponent totalItems={ratingListData && ratingListData?.totalList} pageSize={10} maxPaginationNumbers={Math.ceil(ratingListData && ratingListData?.totalList / 10)} defaultActivePage={1} onSelect={(e) => setPageNumber(e)} />
          ) : (
            ""
          )}
        </div>
      </div>

      <AddRatingModal isEdit={true} userData={userId} userId={userId?.doctorId?._id} RatingModal={RatingModal} setRatingModal={setRatingModal} ratingListApi={ratingListApi} />
    </div>
  );
}

export default PatientReviews;
