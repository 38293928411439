import React, { useState } from "react";
import { IMAGE_CONSTANT } from "../../../constants";
import ConfirmationModal from "./ConfirmationModal";
import PatientCoupon from "./PatientCoupon";

function PatientAppointmentDetail() {
  const [ConfirmModal, setConfirmModal] = useState(false);
  const [CouponModal, setCouponModal] = useState(false);
  const handleCheckOut = () => {
    setConfirmModal(!ConfirmModal);
  };
  const handleCoupon = () => {
    setCouponModal(!CouponModal);
  };
  return (
    <div class="col-lg-8 col-xl-9 content_ipad_p appointments pat-appointment-detail">
      <div class="card">
        <div class="card-header">
          <h4 class="card-title">Appointment Details 3</h4>
        </div>
        <div class="card-body ">
          <div class="tab-content pt-0">
            <div role="tabpanel" id="Appointments-d" class="tab-pane fade show active appointments">
              <div class="appointment-list">
                <div class="profile-info-widget">
                  <a class="booking-doc-img">
                    <img src={IMAGE_CONSTANT.DOC_PROFILE} alt="" />
                  </a>
                  <div class="profile-det-info">
                    <h3>
                      <a>Carl Kelly</a>
                    </h3>
                    <div class="patient-details">
                      <h5>$10 Consultation Fee</h5>
                      <h5>10 Years of Experience</h5>
                      <a href="#" data-toggle="modal" data-target="#speciality-popup" data-dismiss="modal">
                        <h6>Dermatologist</h6>
                      </a>
                      <div class=" rating ">
                        <i class="fas fa-star filled"></i>
                        <i class="fas fa-star filled"></i>
                        <i class="fas fa-star filled"></i>
                        <i class="fas fa-star filled"></i>
                        <i class="fas fa-star filled"></i>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <i className="fa fa-bookmark"></i>
                </div>
              </div>
              <div class="card">
                <div class="card-body">
                  <h4>
                    <b>Appointment details</b>
                  </h4>
                  <div>
                    <span class="color-grey ">
                      <b>Date:</b>12/02/2021
                    </span>
                  </div>
                  <div>
                    <span class="color-grey">
                      <b>Time:</b>9.00 AM -9.30 AM
                    </span>
                  </div>
                  <h4 class="mt-4 ">
                    <b>Reason for Appointment</b>
                  </h4>
                  <div class="mb-4">
                    <span class="color-grey ">
                      Lorem ipsum dolor sit amet consectetur, adipisicing elit. Consequuntur reprehenderit cumque nesciunt ipsum nisi quae quo velit odio et, omnis doloribus rem quis perspiciatis, enim magnam soluta? A, aliquid omnis.
                    </span>
                  </div>
                  <h4 class="mt-4 ">
                    <b>Medical History</b>
                  </h4>
                  <div class="card">
                    <div class="card-body">
                      <div>
                        <span>
                          <i class="fa fa-file-pdf fa-3x"></i>
                        </span>
                      </div>
                    </div>
                  </div>
                  <h4 class="mt-4 ">
                    <b>ddddBill Details</b>
                  </h4>
                  <div class="invoice-item invoice-table-wrap">
                    <div class="row">
                      <div class="col-md-12">
                        <div class="table-responsive">
                          <table class="invoice-table table table-bordered">
                            <thead>
                              <tr>
                                <th>Description</th>
                                <th class="text-right">Total</th>
                              </tr>
                            </thead>
                            <tbody>
                              <tr>
                                <td>Consultation Fee</td>
                                <td class="text-right">$13</td>
                              </tr>
                              <tr>
                                <td>Tax </td>
                                <td class="text-right">$15</td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div class="col-md-6 col-xl-4 ml-auto">
                        <div class="table-responsive">
                          <table class="invoice-table-two table">
                            <tbody>
                              <tr>
                                <th>Subtotal:</th>
                                <td>
                                  <span>$28</span>
                                </td>
                              </tr>
                              <tr>
                                <th>Coupon:</th>
                                <td>
                                  <a onClick={handleCoupon}>Apply Coupon</a>
                                </td>
                              </tr>
                              <tr>
                                <th>Total :</th>
                                <td>
                                  <span>$18</span>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex justify-content-center mb-2 mt-5">
                    <button type="button" class="btn btn-primary" onClick={handleCheckOut}>
                      Checkout
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConfirmationModal ConfirmModal={ConfirmModal} setConfirmModal={setConfirmModal} />
      <PatientCoupon setCouponModal={setCouponModal} CouponModal={CouponModal} />
    </div>
  );
}

export default PatientAppointmentDetail;
