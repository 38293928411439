import { doctorAction, patientAction } from "../../action";
import initialState from "../initialState";

export default function doctorReducer(state = initialState.common, action) {
  switch (action.type) {
    case doctorAction.DOCTOR_PROFILE_DATA:
      return {
        ...state,
        doctorProdileInfo: action.doctorProdileInfo,
      };
    case doctorAction.DOCTOR_DASHBOARD_DATA:
      return {
        ...state,
        doctorDasboardInfo: action.doctorDasboardInfo,
      };
    case doctorAction.DOCTOR_UPCOMING_APPOINTMENTS:
      return {
        ...state,
        doctorDasboardUpcomingAppointment: action.doctorDasboardUpcomingAppointment,
      };
    case doctorAction.DOCTOR_PREVIOUS_APPOINTMENTS:
      return {
        ...state,
        doctorDashboardPreviousAppointment: action.doctorDashboardPreviousAppointment,
      };
    case doctorAction.DOCTOR_VIEW_APPOINTMENT:
      return {
        ...state,
        doctorViewAppointmentData: action.doctorViewAppointmentData,
      };
    case doctorAction.DOCTOR_HELP_LIST:
      return {
        ...state,
        doctorHelpRequestListData: action.doctorHelpRequestListData,
      };
    case doctorAction.DOCTOR_VIEW_HELP_REQUEST:
      return {
        ...state,
        doctorViewHelpRequestData: action.doctorViewHelpRequestData,
      };
    case doctorAction.DOCTOR_CHANGE_PWD:
      return {
        ...state,
        doctorChangePwd: action.doctorChangePwd,
      };
    case doctorAction.DOCTOR_EDIT_PROFILE:
      return {
        ...state,
        doctorEditProfile: action.doctorEditProfile,
      };
    case doctorAction.DOCTOR_CARD_LIST:
      return {
        ...state,
        doctorCardList: action.doctorCardList,
      };
    case doctorAction.DOCTOR_ADD_CARD:
      return {
        ...state,
        doctorAddCard: action.doctorAddCard,
      };
    case doctorAction.DOCTOR_DELETE_CARD:
      return {
        ...state,
        doctorDeleteCard: action.doctorDeleteCard,
      };
    case doctorAction.DOCTOR_EDIT_CARD:
      return {
        ...state,
        doctorEditCard: action.doctorEditCard,
      };
    case doctorAction.DOCTOR_TERMS_AND_CONDITIONS:
      return {
        ...state,
        doctorTermsAndConditions: action.doctorTermsAndConditions,
      };
    case doctorAction.DOCTOR_ABOUT_US:
      return {
        ...state,
        doctorAboutUs: action.doctorAboutUs,
      };
    case doctorAction.DOCTOR_WALLET_INFO:
      return {
        ...state,
        doctorWalletData: action.doctorWalletData,
      };

    case doctorAction.DOCTOR_SUBSCRIBE_INFO:
      return {
        ...state,
        doctorSubscribeData: action.doctorSubscribeData,
      };
    case doctorAction.DOCTOR_PRESCRIPTION_LIST:
      return {
        ...state,
        doctorPrescriptionData: action.doctorPrescriptionData,
      };
    case doctorAction.DOCTOR_ADD_PRESCRIPTION:
      return {
        ...state,
        doctorAddPrescriptionData: action.doctorAddPrescriptionData,
      };
    case doctorAction.DOCTOR_DELETE_PRESCRIPTION:
      return {
        ...state,
        doctorDeletePrescription: action.doctorDeletePrescription,
      };
    case doctorAction.DOCTOR_VIEW_PRESCRIPTION:
      return {
        ...state,
        doctorViewPrescription: action.doctorViewPrescription,
      };
    case doctorAction.DOCTOR_EDIT_PRESCRIPTION:
      return {
        ...state,
        doctorEditPrescription: action.doctorEditPrescription,
      };
    case doctorAction.STATIC_PAGE_CMS:
      return {
        ...state,
        staticPageData: action.staticPageData,
      };
    case doctorAction.TESTIMONIAL_LIST:
      return {
        ...state,
        testimonialsListData: action.testimonialsListData,
      };
    case doctorAction.OUR_TEAM_LIST:
      return {
        ...state,
        ourTeamListData: action.ourTeamListData,
      };
    case doctorAction.MEDICAL_RECORD_LIST:
      return {
        ...state,
        medicalListData: action.listData,
      };
    case doctorAction.RECOMMENDATION_LIST:
      return {
        ...state,
        recommendationListData: action.recommendationListData,
      };
    case doctorAction.INVOICE_LIST:
      return {
        ...state,
        invoiceListInfo: action.invoiceListInfo,
      };
    case doctorAction.INVOICE_VIEW:
      return {
        ...state,
        invoiceViewInfo: action.invoiceViewInfo,
      };
    case doctorAction.INVOICE_LIST_BILL:
      return {
        ...state,
        invoiceListBill: action.invoiceListBill,
      };
    case doctorAction.PRIVACY_POLICY:
      return {
        ...state,
        docPrivacyPolicy: action.docPrivacyPolicy,
      };
    case doctorAction.DOCTOR_WITHDRAW_TRANSACTION_HISTORY:
      return {
        ...state,
        doctorWithdrawTransactionHistory: action.doctorWithdrawTransactionHistory,
      };
    default:
      return state;
  }
}
