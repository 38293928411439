import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import ViewBankDetail from "./ViewBankDetail";
import { useFormik } from "formik";
import * as Yup from "yup";

const AddBankDetail = ({ addBankDetails, setAddBankDetails, amount, setreloadApi }) => {
  const [viewBankDetails, setViewBankDetails] = useState(false);
  const [viewData, setViewData] = useState("");

  const handleClose = () => {
    setAddBankDetails(false);
  };

  useEffect(() => {
    formik.resetForm();
  }, [addBankDetails]);

  const formik = useFormik({
    initialValues: {
      bankName: "",
      accountNumber: "",
      ifscCode: "",
      accountHolderName: "",
    },
    validationSchema: Yup.object({
      bankName: Yup.string().required("*Required."),
      accountNumber: Yup.number().required("*Required.").typeError("*Please enter only digits"),
      ifscCode: Yup.string().required("*Required."),
      accountHolderName: Yup.string().required("*Required."),
    }),
  });
  const handleSubmit = () => {
    console.log(formik.errors);
    if (formik.values.bankName && formik.values.accountNumber && formik.values.ifscCode && formik.values.accountHolderName) {
      setAddBankDetails(false);
      setViewBankDetails(true);
    }
    setViewData({
      amount: Number(amount),
      bankName: formik.values.bankName,
      accountNumber: formik.values.accountNumber,
      ifscCode: formik.values.ifscCode,
      accountHolderName: formik.values.accountHolderName,
    });
  };
  return (
    <>
      <Modal isOpen={addBankDetails} className="modal-dialog-centered wallet_add_bank_details modal-dialog-scrollable ">
        <form onSubmit={formik.handleSubmit}>
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="staticBackdropLabel">
                Add Bank Detail
              </h4>
              <button type="button" className="close " aria-label="Close" data-dismiss="modal" onClick={handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div class="modal-body p-4">
              <div class="row">
                <div class="col-lg-12 ">
                  <div class="card mb-0">
                    <div class="card-body">
                      <div class=" row">
                        <div class="col-lg-12 ">
                          <div class="form-group">
                            <label for="bnkname">Bank Name </label>
                            <input
                              type="text"
                              class={`${formik.touched.bankName && formik.errors.bankName ? "is-invalid" : ""} form-control`}
                              maxLength={60}
                              name="bankName"
                              {...formik.getFieldProps("bankName")}
                              placeholder="Select Bank"
                            />
                            {formik.touched.bankName && formik.errors.bankName ? (
                              <span className="validation_error">{formik.errors.bankName}</span>
                            ) : null}
                          </div>
                        </div>
                        <div class="col-lg-12 ">
                          <div class="form-group">
                            <label for="bnkname">Account Number </label>
                            <input
                              type="text"
                              class={`${formik.touched.accountNumber && formik.errors.accountNumber ? "is-invalid" : ""} form-control`}
                              maxLength={20}
                              name="accountNumber"
                              {...formik.getFieldProps("accountNumber")}
                              placeholder="Account Number"
                            />
                            {formik.touched.accountNumber && formik.errors.accountNumber ? (
                              <span className="validation_error">{formik.errors.accountNumber}</span>
                            ) : null}
                          </div>
                        </div>
                        <div class="col-lg-12 ">
                          <div class="form-group">
                            <label for="bnkname">IFSC Code</label>
                            <input
                              type="text"
                              class={`${formik.touched.ifscCode && formik.errors.ifscCode ? "is-invalid" : ""} form-control`}
                              maxLength={20}
                              name="ifscCode"
                              {...formik.getFieldProps("ifscCode")}
                              placeholder="Enter IFSC Code"
                            />
                            {formik.touched.ifscCode && formik.errors.ifscCode ? (
                              <span className="validation_error">{formik.errors.ifscCode}</span>
                            ) : null}
                          </div>
                        </div>
                        <div class="col-lg-12 ">
                          <div class="form-group mb-0">
                            <label for="bnkname">Account Holder Name</label>
                            <input
                              type="text"
                              class={`${
                                formik.touched.accountHolderName && formik.errors.accountHolderName ? "is-invalid" : ""
                              } form-control`}
                              maxLength={60}
                              name="accountHolderName"
                              {...formik.getFieldProps("accountHolderName")}
                              placeholder="Enter Name"
                            />
                            {formik.touched.accountHolderName && formik.errors.accountHolderName ? (
                              <span className="validation_error">{formik.errors.accountHolderName}</span>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      {/* <div class="mt-4 d-flex justify-content-center">
                                        <a href="#" data-toggle="modal" class="btn cancel-outline  mr-2">Cancel</a>
                                        <a href="" data-toggle="modal" data-target="#addaccntDetail_Modal" data-backdrop="static" data-dismiss="modal" class="btn btn-primary w-25 ">Add Bank</a>
                                    </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer justify-content-center">
              <button type="submit" className="btn btn-primary" onClick={handleSubmit}>
                Add Bank
              </button>
            </div>
          </div>
        </form>
      </Modal>
      <ViewBankDetail
        viewBankDetails={viewBankDetails}
        setViewBankDetails={setViewBankDetails}
        viewData={viewData}
        setreloadApi={setreloadApi}
      />
    </>
  );
};

export default AddBankDetail;
