import React, { useState } from 'react';
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from 'react-redux';
import { doctor } from '../../../redux/action';
import ToasterFunc from '../../common/toaster/Toaster';


const ContactUs = () => {
  let dispatch = useDispatch()


  const contactUsForm = async (payload) => dispatch(doctor.contactUsFormAction(payload));

  const formik = useFormik({
    initialValues: {
      name: "",
      mobile: "",
      email: "",
      message: ""

    },
    validationSchema: Yup.object({
      name: Yup.string().required("*Name is required.").trim(),
      mobile: Yup.number().required("*Mobile number is required.").typeError("*Please enter only digit"),
      email: Yup.string().email("Invalid email address").required("*Email is required.").trim(),
      message: Yup.string().required("*Message is required.").trim(),
    }),
  });

  const handleSubmit = async () => {
    if (formik.values.name.trim() && formik.values.mobile.trim() && formik.values.email.trim() && formik.values.message.trim()) {
      console.log("Success");
      const payload = {
        name: formik.values.name,
        mobileNumber: formik.values.mobile,
        email: formik.values.email,
        message: formik.values.message,
      };
      const res = await contactUsForm(payload);
      if (res.status == 200) {
        ToasterFunc("success", res.data.responseMessage);
        formik.resetForm()
      }
    }
  }
  return (
    <>
      <div className=" col-lg-12 col-xl-12 content_ipad_p">
        <div className="row">
          <div className="col-sm-12">
            <div className="about-gym-doc">
              <div className="container">
                <div className="card-body">
                  <h1 className='clr-white font-size-main pad-left-10'>Contact Us</h1>
                </div>
              </div>
            </div>
            <div className="container spacing-top contact-us">
              <div className="card">
                <div className="card-body">
                  <div className="contact-form">
                    <h4 className='mb-4'><b>Send Message</b></h4>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group">
                            <input type="text" maxLength="16" className={`form-control ${formik.touched.name && formik.errors.name ? 'is-invalid' : ''}`} name='name' placeholder='Name' {...formik.getFieldProps("name")} />
                            {formik.touched.name && formik.errors.name ? <span className="validation_error">{formik.errors.name}</span> : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group">
                            <input type="text" maxLength="10" className={`form-control ${formik.touched.mobile && formik.errors.mobile ? 'is-invalid' : ''}`} name='mobile' placeholder='Mobile Number' {...formik.getFieldProps("mobile")} />
                            {formik.touched.mobile && formik.errors.mobile ? <span className="validation_error">{formik.errors.mobile}</span> : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input type="text" className={`form-control ${formik.touched.email && formik.errors.email ? 'is-invalid' : ''}`} name='email' placeholder='Email' {...formik.getFieldProps("email")} />
                            {formik.touched.email && formik.errors.email ? <span className="validation_error">{formik.errors.email}</span> : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <textarea name="message" maxLength="180" className={`form-control ${formik.touched.message && formik.errors.message ? 'is-invalid' : ''}`} rows="3" placeholder='Type Your Message' {...formik.getFieldProps("message")}></textarea>
                            {formik.touched.message && formik.errors.message ? <span className="validation_error">{formik.errors.message}</span> : null}
                          </div>
                        </div>
                      </div>
                      <button type='submit' className='btn btn-primary btn-new mt-4' onClick={handleSubmit}>Send</button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default ContactUs
