import { Modal, ModalBody } from "reactstrap";
import { IMAGE_CONSTANT } from "../../../constants";
import { useState } from "react";
import { VerifyOtp } from "..";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { authentication } from "../../../redux/action";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { getSessionStorageOrDefault, saveToSessionStorage } from "../../../utils";
import { useLocation } from "react-router-dom";

function SignUp({ setSignUpModal, SignUpModal, handleSignInToggle }) {
  let location = useLocation();
  const [showPwd, setShowPwd] = useState({
    oldPwd: false,
    newPwd: false,
    ConPwd: false,
  });
  const [showConPwd, setShowConPwd] = useState({
    ConPwd: false,
  });
  const [VerifyOtpModal, setVerifyOtpModal] = useState(false);
  const [isSignUpOpen, setisSignUpOpen] = useState(false);
  const [value, setValue] = useState();
  const dispatch = useDispatch();

  const SignUpUser = async (payload, code) => dispatch(authentication.signUpAction(payload, code));

  const SignUpPatientUser = async (payload, code) => dispatch(authentication.signUpPatientAction(payload, code));

  const formik = useFormik({
    initialValues: {
      email: "",
      mobile: "",
      NewPassword: "",
      ConPassword: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("*Email is required."),
      mobile: Yup.string()
        .matches(/^[0-9\b\+\-\(\)]+$/, "Invalid phone number")
        .required("*Mobile number is required."),
      // /^[1-9][0-9]{9,13}$/g
      NewPassword: Yup.string()
        .required("*Required")
        .matches(
          /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        ),
      ConPassword: Yup.string()
        .required("*Required")
        .matches(
          /^(?=.*?[A-Z])(?=(.*[a-z]){1,})(?=(.*[\d]){1,})(?=(.*[\W]){1,})(?!.*\s).{8,}$/,
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
        .when("NewPassword", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf([Yup.ref("NewPassword")], "New password and Confirm password should be same"),
        }),
    }),
    onSubmit: (values) => {
      const payload = {
        email: values.email,
        mobile: values.mobile,
        NewPassword: values.NewPassword,
        ConPassword: values.ConPassword,
      };
      handleSignUpSubmit();
    },
  });

  // console.log(formik.errors);

  const handleClose = () => {
    setSignUpModal(false);
    sessionStorage.removeItem("isLoggedIn");
    sessionStorage.removeItem("userType");
    formik.values.email = "";
    formik.values.mobile = "";
    formik.values.NewPassword = "";
    formik.values.ConPassword = "";
    formik.resetForm();
  };

  const handleSignUp = () => {
    setVerifyOtpModal(!VerifyOtpModal);
    if (SignUpModal) {
      setSignUpModal(false);
      setisSignUpOpen(true);
    }
  };

  const handleSignUpSubmit = async (values) => {
    console.log("signupapicall", formik.values);
    console.log(formik.errors);
    if (
      formik.values.email !== "" &&
      formik.values.mobile !== "" &&
      formik.values.NewPassword !== "" &&
      formik.values.ConPassword !== "" &&
      formik.values.NewPassword === formik.values.ConPassword &&
      !formik.errors.NewPassword &&
      !formik.errors.ConPassword
    ) {
      // const payload={
      //   email: formik.values.email,
      //   mobileNumber: formik.values.mobile,
      //   password: formik.values.NewPassword,
      //   referralCode: location
      // }
      if (getSessionStorageOrDefault("userType") === "1") {
        const res = await SignUpUser(formik.values, location.pathname.split("/")[2] ? location.pathname.split("/")[2] : "");
        if (res && res.status == 200) {
          saveToSessionStorage("authToken", res?.data?.userData?.token);
          formik.values.email = "";
          formik.values.mobile = "";
          formik.values.NewPassword = "";
          formik.values.ConPassword = "";
          handleSignUp();
        }
      } else if (getSessionStorageOrDefault("userType") === "2") {
        const res = await SignUpPatientUser(formik.values, location.pathname.split("/")[2] ? location.pathname.split("/")[2] : "");
        if (res && res.status == 200) {
          saveToSessionStorage("authToken", res?.data?.userData?.token);
          formik.values.email = "";
          formik.values.mobile = "";
          formik.values.NewPassword = "";
          formik.values.ConPassword = "";
          handleSignUp();
        }
      }
    }
  };

  const handleRemoveData = () => {
    formik.values.email = "";
    formik.values.mobile = "";
    formik.values.NewPassword = "";
    formik.values.ConPassword = "";
  };

  return (
    <div>
      <Modal isOpen={SignUpModal} className="sign-modal modal-dialog-centered index-modalBody">
        <button type="button" class="close custom-model-close" aria-label="Close" data-dismiss="modal" onClick={handleClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <ModalBody className="modal-body">
          {/* <div class="modal-body"> */}
          <div class="container-fluid">
            <div class="account-content">
              <div class="row">
                <div class="col-md-5 login-left m-auto">
                  <img src={IMAGE_CONSTANT.LOGIN_BANNER} class="img-fluid" alt="Doccure Login" />
                </div>
                <div class="col-md-7 login-right">
                  <div class="login-header">
                    <h3>Sign Up </h3>
                    <p class="small text-muted">Please create a new account.</p>
                  </div>

                  <form onSubmit={formik.handleSubmit}>
                    <div class="form-group ">
                      <input
                        type="text"
                        class="form-control "
                        placeholder="Email Address"
                        name="email"
                        {...formik.getFieldProps("email")}
                      />
                      {formik.touched.email && formik.errors.email ? <span className="validation_error">{formik.errors.email}</span> : null}
                    </div>
                    <div class="form-group">
                      <div class="input-group">
                        <div class="input-group-prepend ">
                          <span class="input-group-text">+1</span>
                        </div>
                        <input
                          type="text"
                          class="form-control"
                          placeholder="Mobile Number"
                          name="mobile"
                          maxLength="10"
                          {...formik.getFieldProps("mobile")}
                        />
                      </div>
                      {formik.touched.mobile && formik.errors.mobile ? (
                        <span className="validation_error">{formik.errors.mobile}</span>
                      ) : null}
                    </div>

                    <div class="form-group pharmacy-home-slider">
                      <input
                        type={showPwd && showPwd.oldPwd ? "text" : "password"}
                        class="form-control"
                        placeholder="Create Password"
                        name="NewPassword"
                        {...formik.getFieldProps("NewPassword")}
                      />
                      <span class="eye-icon">
                        <i
                          class="fa fa-eye"
                          onMouseUp={() => setShowPwd({ oldPwd: false })}
                          onMouseDown={() => setShowPwd({ oldPwd: true })}
                        ></i>
                      </span>
                      {formik.touched.NewPassword && formik.errors.NewPassword ? (
                        <span className="validation_error">{formik.errors.NewPassword}</span>
                      ) : null}
                    </div>

                    <div class="form-group pharmacy-home-slider">
                      <input
                        type={showConPwd && showConPwd.conPwd ? "text" : "password"}
                        class="form-control"
                        placeholder="Confirm Password"
                        name="ConPassword"
                        {...formik.getFieldProps("ConPassword")}
                      />
                      <span class="eye-icon">
                        <i
                          class="fa fa-eye"
                          onMouseUp={() => setShowConPwd({ conPwd: false })}
                          onMouseDown={() => setShowConPwd({ conPwd: true })}
                        ></i>
                      </span>
                      {formik.touched.ConPassword && formik.errors.ConPassword ? (
                        <span className="validation_error">{formik.errors.ConPassword}</span>
                      ) : null}
                    </div>

                    <button
                      class="btn btn-primary btn-block btn-lg login-btn"
                      type="submit"
                      // onClick={handleSignUpSubmit}
                    >
                      Signup
                    </button>

                    <div class="text-center dont-have" data-toggle="modal" data-target="#docSugnup_Modal" data-dismiss="modal">
                      Already have an account?
                      <a
                        className="ml-1"
                        onClick={() => {
                          handleSignInToggle();
                          handleRemoveData();
                        }}
                      >
                        Sign In
                      </a>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </ModalBody>
      </Modal>
      {VerifyOtpModal && (
        <VerifyOtp
          VerifyOtpModal={VerifyOtpModal}
          setVerifyOtpModal={setVerifyOtpModal}
          isSignUpOpen={isSignUpOpen}
          setisSignUpOpen={setisSignUpOpen}
        />
      )}
    </div>
  );
}

export default SignUp;
