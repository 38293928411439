import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { IMAGE_CONSTANT } from "../../../constants";
import { useEffect } from "react";
import { VerifyOtp } from "..";
import { useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { authentication } from "../../../redux/action";
import ToasterFunc from "../../common/toaster/Toaster";
import { getSessionStorageOrDefault, saveToSessionStorage } from "../../../utils";

function ForgotPassword({ ForgetPwdModal, setForgetPwdModal, setSignInModal }) {
  const dispatch = useDispatch();
  const [VerifyOtpModal, setVerifyOtpModal] = useState(false);
  const [Open, setOpen] = useState(false);
  const [DocId, setDocId] = useState("");
  const [PatId, setPatId] = useState("");

  const ForgetPwdDocUser = async (payload) => dispatch(authentication.forgetPwdDocAction(payload));

  const ForgetPwdPatUser = async (payload) => dispatch(authentication.forgetPwdPatAction(payload));

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().email("Invalid email address").required("*Email is required."),
    }),
    onSubmit: (values) => {
      const payload = {
        email: values.email,
      };
    },
  });

  const handleClose = () => {
    setForgetPwdModal(false);
    formik.values.email = "";
  };
  const handleSend = () => {
    setVerifyOtpModal(!VerifyOtpModal);
    setForgetPwdModal(false);
    if (ForgetPwdModal) {
      setOpen(true);
    }
  };

  const handleforgetPwdSend = async (e) => {
    if (formik.values.email !== "") {
      if (getSessionStorageOrDefault("userType") === "1") {
        const res = await ForgetPwdDocUser(formik.values);
        if (res && res.status == 200) {
          formik.values.email = "";
          setDocId(res && res.data.result._id);
          saveToSessionStorage("UserData", JSON.stringify(res.data.result));
          handleSend();
        }
      } else if (getSessionStorageOrDefault("userType") === "2") {
        const res = await ForgetPwdPatUser(formik.values);
        if (res && res.status == 200) {
          formik.values.email = "";
          setPatId(res && res.data.result._id);
          saveToSessionStorage("UserData", JSON.stringify(res.data.result));
          handleSend();
        }
      }
    } else if (formik.errors.email) {
      e.preventDefault();
    }
  };

  return (
    <div>
      <div class="modal fade " id="docforgtP_Modal" tabindex="-1" role="dialog">
        <div class="modal-dialog modal-lg  modal-dialog-centered " role="document">
          <Modal isOpen={ForgetPwdModal} className="forgetPwd-modal modal-dialog-centered index-modalBody">
            <div class="modal-content">
              <button type="button" class="close custom-model-close" aria-label="Close" data-dismiss="modal" onClick={handleClose}>
                <span aria-hidden="true">&times;</span>
              </button>
              <ModalBody>
                <div class="container-fluid">
                  <div class="account-content">
                    <div class="row">
                      <div class="col-md-5 login-left m-auto">
                        <img src={IMAGE_CONSTANT.LOGIN_BANNER} class="img-fluid" alt="Doccure Login" />
                      </div>
                      <div class="col-md-7 login-right">
                        <div class="login-header">
                          <h3>Forgot Password?</h3>
                          <p class="small text-muted">Please enter your email associated with your account. We will send you a verification code to your email.</p>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                          <div class="form-group">
                            <input type="email" name="email" class="form-control" {...formik.getFieldProps("email")} />
                            {formik.touched.email && formik.errors.email ? <span className="validation_error">{formik.errors.email}</span> : null}
                          </div>
                          <button class="btn btn-primary btn-block btn-lg login-btn" type="submit" onClick={(e) => handleforgetPwdSend(e)}>
                            Send
                          </button>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
            </div>
          </Modal>
          {Open && <VerifyOtp VerifyOtpModal={VerifyOtpModal} setVerifyOtpModal={setVerifyOtpModal} Open={Open} setSignInModal={setSignInModal} DocId={DocId} PatId={PatId} />}
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
