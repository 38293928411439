import React, { useState , useEffect } from "react";
import { useDispatch ,  useSelector } from "react-redux";
import { authentication } from "../../../redux/action";
import "../billModal/style.css";
import stripeService from "../../../services/stripeService/stripeService";
import ToasterFunc from "../../common/toaster/Toaster";

function BillDetails(props) {
  const { SubscriptionId, setCouponId, couponId, handlePayment } = props;
  console.log("SubscriptionId",SubscriptionId , "couponId",couponId);
  const [tax, setTax] = useState(0);
  const [taxData, setTaxData] = useState({})
  const [showCoupon, setShowCoupon] = useState(false);
  const dispatch = useDispatch();

  const getPatientTax = async ()=> dispatch(authentication.getPatientTaxAction());
  const getDoctorTax = async ()=> dispatch(authentication.getDoctorTaxAction());

  useEffect(() => {
    getPatientDocTaxApi();

  }, []);
  
   const createSession =async (val) => {
    // handlePayment(val)
    console.log({val})
    // note//
      let paymentData = {
        subscriptionId: SubscriptionId,
        price: Number(val),
        // subscriptionId : SubscriptionType
      }
      if (paymentData) {
        paymentData.couponId = couponId?._id;
      }
  
      // create checkout session return url
       let res = await stripeService.createSessionCheckout(paymentData)
       if (res && res.status == 200) {
        if(res.data && res.data.url){
          window.open(res.data?.url, "_self");
        }else{
          ToasterFunc("info", res.data?.message);
        }
       }else{
        ToasterFunc("error", res.err);
        }
    //till//
      // if (DocSubcriptionModal) {
      //   setisOpenDocSide(true);
      // }
      // setPaymentModal(!PaymentModal);
      // setDocSubcriptionModal(false);
    
  };


  useEffect(() => {
    console.log("taxData ===>> ",taxData)
  }, [taxData])

  const getPatientDocTaxApi = async ()=>{
    var taxRes;
      if (getSessionStorageValue('userType') == '1'){
         taxRes =  await getDoctorTax();
      }else{
          taxRes =  await getPatientTax();

      }
        console.log("taxRes ===>>> ",taxRes);
        let result = taxRes?.data?.data;
        if ((taxRes.status === 200 )&& result && result?.value){
          setTaxData(result);
          setTax(result?.value)
        }
  }
  
  const getSessionStorageValue = (key) => {
    const stored = sessionStorage.getItem(key);
    console.log(stored);
    return JSON.parse(stored);
  };

  const [haveValidCoupon, sethaveValidCoupon] = useState(false);

  const subscriptionList = useSelector((state) => {
    if (getSessionStorageValue('userType') == '1') {
      return state?.authentication?.doctorSubscriptionList;
    } else if (getSessionStorageValue('userType') == '2') {
      return state?.authentication?.patientSubscriptionList;
    }
  });

  // const subscriptionList = useSelector(
  //   (state) => console.log('state===>',state)
  // );

  const couponList = useSelector((state) => state?.authentication?.couponList);
  console.log("subscriptionList", subscriptionList, SubscriptionId,"couponList ===>>",couponList);

  useEffect(() => {
    if (couponList.length && subscriptionList.length && SubscriptionId){
      let isanyvalidcoupon = false ; 
      couponList.forEach((ele) => {
         if (ele?.value < (subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price)){
          isanyvalidcoupon =  true ;
         }
      });
      if (isanyvalidcoupon === true){
         sethaveValidCoupon(isanyvalidcoupon);
      }
    }
    // subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price > item.value 
  }, [couponList, subscriptionList ,SubscriptionId])
  

  return (
    <>
      {!showCoupon ? (
        <>
          <div className="modal-content bill-detail-new">
            <div className="modal-header">
              <h4 className="modal-title text-center" id="staticBackdropLabel">
                Bill Details
              </h4>
            </div>
            <div className="modal-body">
              <div className="account-content">
                <div className="row">
                  <div className="col-md-12">
                    {/* <div className="card"> */}
                    <div className="row">
                      <div className="card-body">
                        <div className="d-flex justify-content-between mb-1">
                          <div>
                            <span className="color-grey">Subscription Fee</span>
                          </div>
                          <div>
                            <span className="color-grey">{`$${subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price || 0}`}</span>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mb-1">
                          <div>
                            <div>
                              {/* <input type="checkbox" id="status_72" /> */}
                              <label for="status_72" className="ml-2">
                                Tax
                              </label>
                            </div>
                          </div>
                          <div>
                            <span className="color-grey">{taxData?.taxType != "PERCENTAGE" ? `$${tax}` : `${(taxData?.taxType != "PERCENTAGE" ? tax : '$'+(subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price * (tax/100)).toFixed(2) )}`}</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row table-bill">
                      <div className="card-body">
                        <div className="d-flex justify-content-between mb-1">
                          <div>
                            <span className="color-grey">Subtotal</span>
                          </div>
                          <div>
                            <span className="color-grey">{`$${subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price +(taxData?.taxType != "PERCENTAGE" ? tax : subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price * (tax/100) ) || 0}`}</span>
                          </div>
                        </div>
                        <div className="d-flex justify-content-between mb-1">
                          <div>
                            <span className="color-grey">Coupon</span>
                          </div>
                          <div>
                            {console.log(couponId)}
                            {couponId ? (
                              <span className="color-grey pointer">{couponId.couponType == "PERCENTAGE" ? `$${(couponId.value / 100) * (subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price + (taxData?.taxType != "PERCENTAGE" ? tax : subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price * (tax/100) ) || 0)}` : couponId.value}</span>
                            ) : (
                              <span className="pointer"  onClick={() => setShowCoupon(!showCoupon)}>
                                Apply Coupon
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row pt-1">
                      <div className="card-body ">
                        <div className="d-flex justify-content-between mb-1">
                          <div>
                            <b>Total</b>
                          </div>
                          <div>
                            <span className="color-grey">
                              <b>
                                {!couponId
                                  ? `$${subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price + (taxData?.taxType != "PERCENTAGE" ? tax : subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price * (tax/100) ) || 0}`
                                  : `$${
                                      (subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price + (taxData?.taxType != "PERCENTAGE" ? tax : subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price * (tax/100) ) || 0) -
                                      (couponId.couponType == "PERCENTAGE" ? `${(couponId.value / 100) * (subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price + (taxData?.taxType != "PERCENTAGE" ? tax : subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price * (tax/100) ) || 0)}` : couponId.value)
                                    }` }
                              </b>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                    <div className="btn-p">
                      <button
                        type="submit"
                        className="btn btn-primary mt-4 btn-new"
                        onClick={(e) =>
                          createSession(
                            !couponId
                              ? `${subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price + (taxData?.taxType != "PERCENTAGE" ? tax : subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price * (tax/100) ) || 0}`
                              : `${
                                  (subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price + (taxData?.taxType != "PERCENTAGE" ? tax : subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price * (tax/100) ) || 0) -
                                  (couponId.couponType == "PERCENTAGE" ? `${(couponId.value / 100) * (subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price + tax || 0)}` : couponId.value)
                                }`
                          )
                        }
                      >
                        Pay
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="modal-content p-3 ">
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-12 ">
                <div className="card mb-0">
                  <div className="card-body">
                    {couponList.map((item) => {
                      return (
                        <>
                          {subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price > item.value ? (
                            <div className="form-check form-group">
                              <input className="form-check-input" type="radio" name="Coupon" id="Coupon-1" onClick={() => setCouponId(item)} />
                              <div className="d-flex justify-content-between">
                                <label className="form-check-label " for="exampleRadios1">
                                  {item.code}
                                </label>
                                <label for="" className="color-grey">
                                  {item.couponType == "PERCENTAGE" ? `$${(item.value / 100) * (subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price + (taxData?.taxType != "PERCENTAGE" ? tax : subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price * (tax/100) ) || 0)}` : item.value}
                                </label>
                              </div>
                              <p className="color-grey">
                                {item.couponType == "PERCENTAGE"
                                  ? `${item.value}% Discount on $${subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price + (taxData?.taxType != "PERCENTAGE" ? tax : subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price * (tax/100) ) || 0}`
                                  : `$${item.value} on $${subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price + (taxData?.taxType != "PERCENTAGE" ? tax : subscriptionList?.filter((item) => item._id == SubscriptionId)[0]?.price * (tax/100) ) || 0}`}
                              </p>
                            </div>
                          ) : (
                            <h3>
                               There is no coupon available 
                            </h3>
                          )}
                       
                      </>
                      )
                    })}
                    <div className="d-flex justify-content-center">
                      <button className="btn btn-primary mr-1" data-dismiss="modal" onClick={() => setShowCoupon(!showCoupon)}>
                        Cancel
                      </button>
                     {haveValidCoupon ?  <button onClick={() => couponId && setShowCoupon(!showCoupon)} className="btn btn-primary mr-1" disabled={couponId ? false : true}>
                        Apply
                      </button> : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default BillDetails;
