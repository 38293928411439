import React from 'react'
import CameraIc from '../../../../../assets/img/video-camera.svg'
import { IMAGE_CONSTANT } from '../../../../../constants'

function Calling() {
    return (
        <div role="tabpanel" id="calls" class="tab-pane fade show active">
        {/* <div class="content"> */}
            {/* <div class="container-fluid"> */}
                <div class="row">
                    <div class="col-xl-12">
                        <div class="chat-window">

                            {/* <!-- Chat Left --> */}
                            <div class="chat-cont-left">

                                <form class="chat-search">
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                            <i class="fas fa-search"></i>
                                        </div>
                                        <input type="text" class="form-control" placeholder="Search"/>
                                    </div>
                                </form>
                                <div class="chat-users-list">
                                    <div class="chat-scroll">
                                        <a href="javascript:void(0);" class="media">
                                            <div class="media-img-wrap">
                                                <div class="avatar avatar-away">
                                                    <img src={IMAGE_CONSTANT.DOC_PROFILE}
                                                        alt="" class="avatar-img rounded-circle mb-1"/>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <div>
                                                    <div class="user-name">Dr. Ruby Perrin</div>
                                                    <div class="user-last-chat">
                                                        <img
                                                            src={CameraIc}
                                                            alt="iconVideo camera" class="video-camera-icon"/>
                                                        Incoming</div>
                                                    <div class="user-last-chat">03:04 mins</div>
                                                </div>

                                                <div class="last-chat-time block d-flex align-items-center">
                                                    <p for="" class=" badge-success badge-pill  mb-0">Yesterday
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="javascript:void(0);" class="media read-chat active">
                                            <div class="media-img-wrap">
                                                <div class="avatar avatar-online">
                                                    <img src={IMAGE_CONSTANT.DOC_PROFILE}
                                                        alt="" class="avatar-img rounded-circle mb-1"/>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <div>
                                                    <div class="user-name">Dr. Darren Elder</div>
                                                    <div class="user-last-chat"><img
                                                            src={CameraIc}
                                                            alt="iconVideo camera" class="video-camera-icon"/>
                                                        Incoming</div>
                                                    <div class="user-last-chat">03:04 mins</div>
                                                </div>                                                   
                                                <div class="last-chat-time block d-flex align-items-center">
                                                    <p for="" class=" badge-success badge-pill  mb-0">Saturday
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="javascript:void(0);" class="media">
                                            <div class="media-img-wrap">
                                                <div class="avatar avatar-away">
                                                    <img src={IMAGE_CONSTANT.DOC_PROFILE}
                                                        alt="" class="avatar-img rounded-circle mb-1"/>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <div>
                                                    <div class="user-name">Dr. Deborah Angel</div>
                                                    <div class="user-last-chat"><img
                                                             src={CameraIc}
                                                            alt="iconVideo camera" class="video-camera-icon"/>
                                                        Incoming</div>
                                                    <div class="user-last-chat">03:04 mins</div>
                                                </div>
                                                <div class="last-chat-time block d-flex align-items-center">
                                                    <p for="" class=" badge-success badge-pill  mb-0">Saturday
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="javascript:void(0);" class="media read-chat">
                                            <div class="media-img-wrap">
                                                <div class="avatar avatar-online">
                                                    <img src={IMAGE_CONSTANT.DOC_PROFILE}
                                                        alt="" class="avatar-img rounded-circle mb-1"/>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <div>
                                                    <div class="user-name">Dr. Sofia Brient</div>
                                                    <div class="user-last-chat"><img
                                                              src={CameraIc}
                                                            alt="iconVideo camera" class="video-camera-icon"/>
                                                        Incoming</div>
                                                    <div class="user-last-chat">03:04 mins</div>
                                                </div>
                                                <div class="last-chat-time block d-flex align-items-center">
                                                    <p for="" class=" badge-success badge-pill  mb-0">Saturday
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="javascript:void(0);" class="media read-chat">
                                            <div class="media-img-wrap">
                                                <div class="avatar avatar-offline">
                                                    <img src={IMAGE_CONSTANT.DOC_PROFILE}
                                                        alt="" class="avatar-img rounded-circle mb-1"/>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <div>
                                                    <div class="user-name">Dr. Marvin Campbell</div>
                                                    <div class="user-last-chat"><img
                                                              src={CameraIc}
                                                            alt="iconVideo camera" class="video-camera-icon"/>
                                                        Incoming</div>
                                                    <div class="user-last-chat">03:04 mins</div>
                                                </div>
                                                <div class="last-chat-time block d-flex align-items-center">
                                                    <p for="" class=" badge-success badge-pill  mb-0">Saturday
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="javascript:void(0);" class="media read-chat">
                                            <div class="media-img-wrap">
                                                <div class="avatar avatar-online">
                                                    <img src={IMAGE_CONSTANT.DOC_PROFILE}
                                                        alt="" class="avatar-img rounded-circle mb-1"/>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <div>
                                                    <div class="user-name">Dr. Katharine Berthold</div>
                                                    <div class="user-last-chat"><img
                                                           src={CameraIc}
                                                            alt="iconVideo camera" class="video-camera-icon"/>
                                                        Incoming</div>
                                                    <div class="user-last-chat">03:04 mins</div>
                                                </div>
                                                <div class="last-chat-time block d-flex align-items-center">
                                                    <p for="" class=" badge-success badge-pill  mb-0">Saturday
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="javascript:void(0);" class="media read-chat">
                                            <div class="media-img-wrap">
                                                <div class="avatar avatar-away">
                                                    <img src={IMAGE_CONSTANT.DOC_PROFILE}
                                                        alt="" class="avatar-img rounded-circle mb-1"/>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <div>
                                                    <div class="user-name">Dr. Linda Tobin</div>
                                                    <div class="user-last-chat">This has allowed me to showcase
                                                        not only my technical skills</div>
                                                </div>
                                                <div class="last-chat-time block d-flex align-items-center">
                                                    <p for="" class=" badge-success badge-pill  mb-0">Saturday
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="javascript:void(0);" class="media read-chat">
                                            <div class="media-img-wrap">
                                                <div class="avatar avatar-offline">
                                                    <img src={IMAGE_CONSTANT.DOC_PROFILE}
                                                        alt="" class="avatar-img rounded-circle mb-1"/>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <div>
                                                    <div class="user-name">Dr. Paul Richard</div>
                                                    <div class="user-last-chat"><img
                                                             src={CameraIc}
                                                            alt="iconVideo camera" class="video-camera-icon"/>
                                                        Incoming</div>
                                                    <div class="user-last-chat">03:04 mins</div>
                                                </div>
                                                <div class="last-chat-time block d-flex align-items-center">
                                                    <p for="" class=" badge-success badge-pill  mb-0">Saturday
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="javascript:void(0);" class="media read-chat">
                                            <div class="media-img-wrap">
                                                <div class="avatar avatar-online">
                                                    <img src={IMAGE_CONSTANT.DOC_PROFILE}
                                                        alt="" class="avatar-img rounded-circle mb-1"/>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <div>
                                                    <div class="user-name">Dr. John Gibbs </div>
                                                    <div class="user-last-chat"><img
                                                             src={CameraIc}
                                                            alt="iconVideo camera" class="video-camera-icon"/>
                                                        Incoming</div>
                                                    <div class="user-last-chat">03:04 mins</div>
                                                </div>
                                                <div class="last-chat-time block d-flex align-items-center">
                                                    <p for="" class=" badge-success badge-pill  mb-0">Saturday
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                        <a href="javascript:void(0);" class="media read-chat">
                                            <div class="media-img-wrap">
                                                <div class="avatar avatar-away">
                                                    <img src={IMAGE_CONSTANT.DOC_PROFILE}
                                                        alt="" class="avatar-img rounded-circle mb-1"/>
                                                </div>
                                            </div>
                                            <div class="media-body">
                                                <div>
                                                    <div class="user-name">Dr. Olga Barlow</div>
                                                    <div class="user-last-chat"><img
                                                              src={CameraIc}
                                                            alt="iconVideo camera" class="video-camera-icon"/>
                                                        Incoming</div>
                                                    <div class="user-last-chat">03:04 mins</div>
                                                </div>
                                               
                                                <div class="last-chat-time block d-flex align-items-center">
                                                    <p for="" class=" badge-success badge-pill  mb-0">31/08/2021
                                                    </p>
                                                </div>
                                            </div>
                                        </a>
                                    </div>
                                </div>
                            </div>
                            {/* <!-- /Chat Left --> */}

                            {/* <!-- Chat Right --> */}
                            <div class="chat-cont-right">
                                <div class="card calls-box-card">
                                    <div>
                                        <h3>Click on the chat to check message received from recipients</h3>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>


            {/* </div> */}
        {/* </div> */}





    </div>
    )
}

export default Calling
