import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, ModalBody } from "reactstrap";

function SecondarySpecialityModule({
  SecondSpeModule,
  setSecondSpeModule,
  setSpecialityArray,
  specialityArray,
  SpeciolityArrData
}) {
  const secondaryList = useSelector(
    (state) => state?.authentication?.secondarySpecialityList
  );
  const secondarySpecialityMaxLength = useSelector(
    (state) => state?.authentication?.secondarySpecialityMaxLength
  );

  const [editableSecondaryList, setEditableSecondaryList] = useState([]);
  const [SpeciolityArr, setSpeciolityArr] = useState([]);
console.log(SpeciolityArr);
  useEffect(() => {
    setEditableSecondaryList(secondaryList);
  }, [secondaryList]);

  const handleClose = () => {
    setSecondSpeModule(false);
    SpeciolityArrData(SpeciolityArr);
  };

 


  const addSpeciality = (id , name ) => {
    console.log(specialityArray.length, secondarySpecialityMaxLength);
    if (
      specialityArray.length < secondarySpecialityMaxLength &&
      !specialityArray.includes(id)
    ) {
      setSpecialityArray((arr) => [...arr, id]);
      setSpeciolityArr((arr)=> [...arr , {id : id  , name : name }])
    }
  };

  const removeSpeciality = async (id) => {
    setSpecialityArray(specialityArray.filter((item) => item !== id));
  };

  const handleSearch = (e) => {
    console.log(e.target.value);
    if (e.target.value === "") setEditableSecondaryList(secondaryList);
    else
      setEditableSecondaryList(
        secondaryList.filter(
          (item) =>
            item?.secondry_specialty
              ?.toLowerCase()
              .search(e.target.value.toLowerCase()) !== -1
        )
      );
  };

  return (
    <Modal isOpen={SecondSpeModule} className="modal-dialog-centered">
      <div class="modal-content">
        <div class="modal-header">
          <h4 class="modal-title" id="exampleModalLongTitle">
            Select Secondary Speciality
          </h4>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            onClick={handleClose}
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <div class="modal-body">
            <div class="form-group pharmacy-home-slider">
              <input
                type="text"
                class="form-control pl-5"
                placeholder="Search"
                onChange={handleSearch}
              />
              <span class="search-icoN">
                <i class="fa fa-search"></i>
              </span>
            </div>
            <div class="">
              <input
                type="text"
                class="form-control "
                placeholder={`Choose Specializations(Max ${secondarySpecialityMaxLength})`}
                disabled
              />
            </div>
            <div class="doc-times">
              {console.log("@@", specialityArray)}
              {specialityArray?.map((item,i) => {
                return (
                  <div class="doc-slot-list" key={i}>
                    {
                      secondaryList?.filter(
                        (secondaryData) => secondaryData._id === item
                      )?.[0]?.secondry_specialty
                    }
                    <a
                      href
                      class="delete_schedule"
                      onClick={() => removeSpeciality(item)}
                    >
                      <i class="fa fa-times"></i>
                    </a>
                  </div>
                );
              })}
            </div>
            <div class="mt-1">
              {editableSecondaryList?.map((item) => {
                return (
                  <>
                    <div
                      id={item._id}
                      style={{ cursor: "pointer" }}
                      class="card p-1 mb-2 color-grey"
                      onClick={() => addSpeciality(item._id , item.secondry_specialty)}
                    >
                      {item?.secondry_specialty}
                    </div>
                  </>
                );
              })}
            </div>
            <div class="text-center">
              <button
                type="submit"
                className="btn btn-primary mt-4 text-center w-25"
                onClick={handleClose}
              >
                Done
              </button>
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default SecondarySpecialityModule;
