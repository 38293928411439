import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../constants";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { authentication } from "../../../redux/action";
import ToasterFunc from "../../common/toaster/Toaster";
import { getSessionStorageOrDefault } from "../../../utils";

function ResetPassword({ ResetPwdModal, setResetPwdModal, setSignInModal, DocId, PatId }) {
  let dispatch = useDispatch();
  let history = useHistory();
  const [showPwd, setShowPwd] = useState({
    oldPwd: false,
    newPwd: false,
    // ConPwd: false
  });

  const ResetDocUser = async (payload) => dispatch(authentication.resetPwdDocAction(payload));

  const ResetPatUser = async (payload) => dispatch(authentication.resetPwdPatAction(payload));

  const handleClose = () => {
    setResetPwdModal(false);
  };

  const formik = useFormik({
    initialValues: {
      NewPwd: "",
      ConPwd: "",
    },
    validationSchema: Yup.object({
      NewPwd: Yup.string()
        .required("*Required")
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"),
      ConPwd: Yup.string()
        .required("*Required")
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/, "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character")
        .when("NewPwd", {
          is: (val) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf([Yup.ref("NewPwd")], "New password and Confirm password should be same"),
        }),
    }),
    onSubmit: (values) => {},
  });

  const sendData = {
    userId: DocId ? DocId : PatId ? PatId : null,
    password: formik.values.NewPwd,
  };

  const handleSubmit = async (e) => {
    console.log(formik);
    if (formik.values.NewPwd !== "" && formik.values.ConPwd !== "" && formik.values.NewPwd === formik.values.ConPwd && !formik.errors.NewPwd && !formik.errors.ConPwd) {
      if (getSessionStorageOrDefault("userType") == "1") {
        const res = await ResetDocUser(sendData);
        if (res && res.status == 200) {
          console.log("signinresponzzz", res);
          ToasterFunc("success", res.data.responseMessage);
          setSignInModal(true);
          setResetPwdModal(false);
        }
      } else if (getSessionStorageOrDefault("userType") == "2") {
        const res = await ResetPatUser(sendData);
        if (res && res.status == 200) {
          // toast("Your Login is successful" || "Success")
          ToasterFunc("success", res.data.responseMessage);
          console.log("signinresponzzz", res);
          setSignInModal(true);
          setResetPwdModal(false);
        }
      }
    }
    // else {
    //   if (
    //     formik.values.NewPwd === "" &&
    //     formik.values.ConPwd === "" &&
    //     formik.values.NewPwd === formik.values.ConPwd
    //   )
    //     ToasterFunc("error", "New password and old password must be same.");
    //   else ToasterFunc("error", "Please enter required fields");
    //   e.preventDefault();
    // }
  };

  return (
    // <d 432qiv class="modal fade index-modalBody" id="docrestP_Modal" tabindex="-1" role="dialog">
    // <div class="modal-dialog modal-lg  modal-dialog-centered" role="document">
    <Modal isOpen={ResetPwdModal} className="sign-modal modal-dialog-centered index-modalBody">
      <div class="modal-content">
        <button type="button" class="close custom-model-close" aria-label="Close" data-dismiss="modal" onClick={handleClose}>
          <span aria-hidden="true">&times;</span>
        </button>
        <ModalBody>
          {/* <div class="modal-body"> */}
          {/* <!-- <div class="content"> --> */}
          <div class="container-fluid">
            {/* <!-- Login Tab Content --> */}
            <div class="account-content">
              <div class="row">
                <div class="col-lg-5 login-left m-auto">
                  {/* <!-- <div class="align-items-center justify-content-center"> --> */}
                  <img src={IMAGE_CONSTANT.LOGIN_BANNER} class="img-fluid" alt="Doccure Login" />
                  {/* <!-- </div> --> */}
                </div>
                <div class="col-lg-7 login-right">
                  <div class="login-header">
                    <h3>Reset Password</h3>
                    <p class="small text-muted">Enter your new password below,We are just being extra safe.</p>
                  </div>

                  {/* <!-- Forgot Password Form --> */}
                  <form onSubmit={formik.handleSubmit}>
                    <div class="form-group pharmacy-home-slider">
                      <input type={showPwd && showPwd.oldPwd ? "text" : "password"} name="NewPwd" placeholder="New Password" class="form-control" {...formik.getFieldProps("NewPwd")} />
                      <span class="eye-icon">
                        <i class="fa fa-eye" onMouseUp={() => setShowPwd({ oldPwd: false })} onMouseDown={() => setShowPwd({ oldPwd: true })}></i>
                      </span>
                      {formik.touched.NewPwd && formik.errors.NewPwd ? <span className="validation_error">{formik.errors.NewPwd}</span> : null}
                    </div>

                    <div class="form-group pharmacy-home-slider">
                      <input type={showPwd && showPwd.oldPwd ? "text" : "password"} name="ConPwd" placeholder="Confirm Password" class="form-control" {...formik.getFieldProps("ConPwd")} />
                      <span class="eye-icon">
                        <i class="fa fa-eye" onMouseUp={() => setShowPwd({ oldPwd: false })} onMouseDown={() => setShowPwd({ oldPwd: true })}></i>
                      </span>
                      {formik.touched.ConPwd && formik.errors.ConPwd ? <span className="validation_error">{formik.errors.ConPwd}</span> : null}
                    </div>

                    <button class="btn btn-primary btn-block btn-lg login-btn " type="submit" onClick={handleSubmit}>
                      Submit
                    </button>
                  </form>
                </div>
              </div>

              {/* <!-- /Login Tab Content --> */}
            </div>
          </div>
          {/* </div> */}
        </ModalBody>
        {/* <!-- </form> --> */}
        {/* <!-- /Register Form --> */}
      </div>
    </Modal>
    // </div>
  );
}

export default ResetPassword;
