import React from "react";
import { Modal, ModalBody } from "reactstrap";

function PatientCoupon({ CouponModal, setCouponModal }) {
  const handleClose = () => {
    setCouponModal(false);
  };
  return (
    <Modal isOpen={CouponModal} class="modal-dialog  modal-dialog-centered">
      <div class="modal-content p-3">
        <div class="text-right">
          <button type="button" class="dr_Modalclose " onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          <div class="row">
            <div class="col-lg-12 ">
              <div class="card mb-0">
                <div class="card-body">
                  <div class="form-check form-group">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="Coupon"
                      id="Coupon-1"
                    />
                    <div class="d-flex justify-content-between">
                      <label class="form-check-label " for="exampleRadios1">
                        Coupon 1
                      </label>
                      <label for="" class="color-grey">
                        $100
                      </label>
                    </div>
                    <p class="color-grey">10% Discount on 4000</p>
                  </div>
                  <div class="form-check form-group">
                    <input
                      class="form-check-input"
                      type="radio"
                      name="Coupon"
                      id="Coupon-2"
                    />
                    <div class="d-flex justify-content-between">
                      <label class="form-check-label " for="Coupon-2">
                        Coupon 2
                      </label>
                      <label for="" class="color-grey">
                        $10
                      </label>
                    </div>
                    <p class="color-grey">20% Discount on 20000</p>
                  </div>
                  <div class="d-flex justify-content-center">
                    <a
                      href=""
                      class="btn btn-primary mr-1"
                      data-dismiss="modal"
                    >
                      Cancel
                    </a>
                    <a href="" class="btn btn-primary mr-1">
                      Apply
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default PatientCoupon;
