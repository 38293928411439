import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ReactStars from "react-stars";
import { Modal } from "reactstrap";
import { patient } from "../../../../../redux/action";
import ToasterFunc from "../../../../common/toaster/Toaster";

function AddRatingModal({
  userId,
  RatingModal,
  setRatingModal,
  isEdit,
  userData,
  ratingListApi,
}) {
  const [serviceRating, setServiceRating] = useState(0);
  const [attentivenessRating, setAttentivenessRating] = useState(0);
  const [supportiveRating, setSupportiveRating] = useState(0);
  const [qualityRating, setQualityRating] = useState(0);
  const [review, setReview] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setServiceRating(userData?.overAllServices);
    setAttentivenessRating(userData?.attentiveness);
    setSupportiveRating(userData?.supportive);
    setQualityRating(userData?.serviceQuality);
    setReview(userData?.review);
  }, [userData]);

  const addRatings = async (payload, isEdit) =>
    dispatch(patient.addRatingsAction(payload, isEdit));

  const handleAddRating = async () => {
    const payload = {
      overAllServices: serviceRating,
      attentiveness: attentivenessRating,
      supportive: supportiveRating,
      serviceQuality: qualityRating,
      review: review,
    };
    if (isEdit) {
      payload.reviewId = userData._id;
    } else {
      payload.userId = userId;
    }
    console.log(payload);
    const res = await addRatings(payload, isEdit);
    if (res.status == 200) {
      ToasterFunc('success','Review updated successfully')
      setRatingModal(false);
      if (isEdit) ratingListApi();
    }
  };

  return (
    <Modal isOpen={RatingModal} class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button
          type="button"
          class="close custom-model-close"
          onClick={() => setRatingModal(false)}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body">
          <div class="account-content p-3">
            <div class="row">
              <div class="col-md-12">
                <div class="card mb-0">
                  <div class="card-body">
                    <div class="mb-2">
                      <b>Rating</b>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <span>Over all Service</span>
                      </div>
                      <div class="col-md-6">
                        <span>
                          <div class=" rating ">
                            <ReactStars
                              count={5}
                              value={serviceRating}
                              size={16}
                              color2={"#ffd700"}
                              onChange={setServiceRating}
                            />
                          </div>
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <span>Attentiveness</span>
                      </div>
                      <div class="col-md-6">
                        <span>
                          <div class=" rating ">
                            <ReactStars
                              count={5}
                              value={attentivenessRating}
                              size={16}
                              color2={"#ffd700"}
                              onChange={setAttentivenessRating}
                            />
                          </div>
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-md-6">
                        <span>Supportive</span>
                      </div>
                      <div class="col-md-6">
                        <span>
                          <div class=" rating ">
                            <ReactStars
                              count={5}
                              value={supportiveRating}
                              size={16}
                              color2={"#ffd700"}
                              onChange={setSupportiveRating}
                            />
                          </div>
                        </span>
                      </div>
                    </div>
                    <div class="row form-group">
                      <div class="col-md-6">
                        <span>Service Quality</span>
                      </div>
                      <div class="col-md-6">
                        <span>
                          <div class=" rating ">
                            <ReactStars
                              count={5}
                              value={qualityRating}
                              size={16}
                              color2={"#ffd700"}
                              onChange={setQualityRating}
                            />
                          </div>
                        </span>
                      </div>
                    </div>

                    <div class="form-group">
                      <label for=" Reviews">
                        <b> Reviews</b>
                      </label>
                      <textarea
                        class="form-control"
                        value={review}
                        onChange={(e) => setReview(e.target.value)}
                        id=" Reviews"
                        rows="3"
                      ></textarea>
                    </div>

                    <div class="d-flex justify-content-center mb-2">
                      <button
                        type="button"
                        class="btn btn-primary mr-1 btn-new"
                        data-dismiss="modal"
                        onClick={() => setRatingModal(false)}
                      >
                        Cancel
                      </button>
                      <button
                        type="button"
                        class="btn btn-primary btn-new"
                        onClick={handleAddRating}
                      >
                        {isEdit ? "Edit" : "Add"}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

export default AddRatingModal;
