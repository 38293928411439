import { combineReducers } from "redux";
import { commonReducer } from "./commonReducer";
import { authenticationReducer } from "./authentication";
import { bookingReducer } from "./bookingReducer";
import { doctorReducer } from "./doctorReducer";
import { patientReducer } from "./patientReducer";
import { notificationReducer } from "./notificationReducer";
import { ratingReducer } from "./ratingReducer";

const rootReducer = combineReducers({
  common: commonReducer,
  authentication: authenticationReducer,
  patient: patientReducer,
  doctor: doctorReducer,
  booking: bookingReducer,
  notification: notificationReducer,
  rating: ratingReducer,
});

export default rootReducer;
