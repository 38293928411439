import PatientService from "../../../services/patientService/PatientService";
import { commonAction } from "../commonAction";
import patientAction from "./patientAction";

export const serviceProviderTypeInPatientAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.serviceProviderTypeInPatient();
      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.SERVICE_PROVIDER_TYPE_IN_PATIENT,
          serviceProviderListInPatient: response?.data?.documentList,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};
export const patientProfileDetailsAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientProfileDetails();
      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_PROFILE_DATA,
          patientProdileInfo: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const nearByPhysicialAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.nearByPhysician(payload);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.FIND_NEAR_BY_PHYSICIANS,
          nearByPhysicianList: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const homeDataAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.homeDataPatient(payload);
      dispatch(commonAction.hideLoader());

      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.FIND_DOC_FOR_HOME_DATA_IN_PATIENT,
          homeDataOfPatient: response?.data ? response?.data : "",
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const homeDataLaboratoryPatientAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.homeDataLaboratoryPatient(payload);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.FIND_DOC_FOR_HOME_DATA_IN_PATIENT,
          homeDataOfLaboratoryPatient: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const changePasswordAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.changePassword(payload);

      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};
export const upcomingAppointmentPatientAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.upcomingAppointmentPatient();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_UPCOMING_APPOINTMENT,
          patientUpcomingAppointmentList: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const previousAppointmentPatientAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.previousAppointmentPatient();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_PREVIOUS_APPOINTMENT,
          patientPreviousAppointmentList: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const viewAppointmentPatientAction = (AppId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.viewAppointmentPatient(AppId);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_VIEW_APPOINTMENT,
          patientViewAppointmentData: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const editProfileInPatsettingsAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    const payloadData = {
      firstName: payload.firstName && payload.firstName,
      lastName: payload.lastName && payload.lastName,
      weightUnit: payload.weightUnit && payload.weightUnit,
      hightUnit: payload.hightUnit && payload.hightUnit,
      profilePic: payload.profilePic && payload.profilePic,
      gender: payload.gender && payload.gender,
      date_of_birth: payload.date_of_birth && payload.date_of_birth,
      marital_status: payload.marital_status && payload.marital_status,
      bio: payload.bio && payload.bio,
      lat: payload.lat && payload.lat,
      long: payload.long && payload.long,
      address: payload.address && payload.address,
      height: payload.height && payload.height,
      weight: payload.weight && payload.weight,
      bloodGroup: payload.bloodGroup && payload.bloodGroup,
      iSDiabetes: payload.iSDiabetes ? true : false,
      diabetesDetails: payload.diabetesDetails && payload.diabetesDetails,
      allergyDeatils: payload.allergyDeatils && payload.allergyDeatils,
      familyNotes: payload.familyNotes && payload.familyNotes,
      medicalFile: payload.medicalFile && payload.medicalFile,
    };

    try {
      const response = await PatientService.editProfileInPatsettings(payloadData);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const addCardInPatsettingsAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.addCardInPatsettings(payload);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const cardListInPatsettingsAction = (DataLimit, selectedPage) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.cardListInPatsettings(DataLimit, selectedPage);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_CARD_LIST_SETTINGS,
          patientCardList: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const cardListInPatAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.cardListInPat();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_CARD_LIST,
          patientCardList: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const deleteCardInPatsettingsAction = (deleteId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.deleteCardInPatsettings(deleteId);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_CARD_LIST_SETTINGS,
          patientCardList: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const editCardInPatsettingsAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    const payloadData = {
      cardId: payload.cardId,
      cardType: payload.cardType,
      cardHolderName: payload.cardHolderName,
      cardNumber: payload?.cardNumber,
      expMonth: payload.expMonth,
      expYear: payload.expYear,
    };

    try {
      const response = await PatientService.editCardInPatsettings(payloadData);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const patientPrivacyPolicyAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientPrivacyPolicy();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_PRIVACY_POLICY,
          patientSidePrivacyPolicy: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const patientTermsAndConditionAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientTermsAndCondition();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_TERMS_AND_CONDITIONS,
          patientSideTermsAndCondition: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const patientLogOutAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientLogOut();
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const patientSubscriptionInWebAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientSubscriptionInWeb();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_SUBSCRIPTION_INFO_IN_WEB,
          patientSubscriptionData: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const patientWalletInWebAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientWalletInWeb();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_WALLET_INFO,
          patientWalletData: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const patientRescheduleRequestListInWebAction = () => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientRescheduleRequestListInWeb();

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_RESCHEDULED_APPOINTMENT,
          patientRescheduledAppointment: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const patientAcceptRescheduleRequestAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientAcceptRescheduleRequest(payload);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const patientRejectRescheduleRequestAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientRejectRescheduleRequest(payload);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const patientSecondarySpecialityListAction = (payloadId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientSecondarySpecialityList(payloadId);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_SECONDARY_SPECIALITY_LIST,
          patientSecondarySpecialityList: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const patientMedicalRecordsListAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientMedicalRecordsList(payload.pageNumber, payload.limit);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_MEDICAL_RECORDS_LIST,
          patientMedicalRecordsList: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const patientPrescriptionListAction = (payloadId, DataLimit, selectedPage) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientPrescriptionList(payloadId, DataLimit, selectedPage);
      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_PRESCRIPTION_LIST,
          patientPrescriptionList: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const patientPrescriptionViewAction = (payloadId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientPrescriptionView(payloadId);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_PRESCRIPTION_VIEW,
          patientPrescriptionView: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const setFavourite = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.setFav(payload);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const favListAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.getFavList(payload);
      dispatch(commonAction.hideLoader());
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_FAV_LIST,
          patientFavList: response?.data,
        });
      }
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const bookAppointmentWithCardAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.bookAppointmentWithCard(payload);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const addRatingsAction = (payload, isEdit) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.addReview(payload, isEdit);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const viewLaboratoryDetailAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.viewLaboratoryDetail(payload);
      dispatch(commonAction.hideLoader());
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_LABORATORY_DETAIL,
          patientLaboratoryDetail: response?.data,
        });
      }
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};
export const labUpcomingListAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.getLabUpcomingList(payload);
      dispatch(commonAction.hideLoader());
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_UPCOMING_LABORATORY_APPOINTMENT,
          patientLabUpcomingList: response?.data,
        });
      }
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};
export const labPreviousListAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.getLabPreviousList(payload);
      dispatch(commonAction.hideLoader());
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_PREVIOUS_LABORATORY_APPOINTMENT,
          patientLabPreviousList: response?.data,
        });
      }
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const medicalRecordList = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.getMedicalRecordList(payload);
      dispatch(commonAction.hideLoader());
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_MEDICALRECORD_LIST,
          patientMedicalRecordData: response?.data,
        });
      }
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const patientinvoicelistAction = (payload, DataLimit, selectedPage) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientinvoicelist(payload, DataLimit, selectedPage);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.INVOICE_LIST,
          invoiceListInfo: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const patientinvoiceViewAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientinvoiceView(payload);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.INVOICE_VIEW,
          invoiceViewInfo: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const patientinvoicelistFullAction = (DataLimit, selectedPage) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientinvoicelistFull(DataLimit, selectedPage);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.INVOICE_LIST_BILL,
          invoiceListBill: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const patientHelplistAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientHelplist(payload);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.HELP_LIST,
          helplistInfo: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const patientSubmitHelpRequestAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientSubmitHelpRequest(payload);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const patientViewHelpAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientViewHelp(payload);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.VIEW_HELP,
          viewhelpInfo: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const patientReplyOnRequestAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientReplyOnRequest(payload);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.REPLY_ON_REQUEST,
          replyonrequest: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const patientReportListAction = (DataLimit, selectedPage) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientReportList(DataLimit, selectedPage);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: patientAction.PATIENT_REPORT_LIST,
          reportListdata: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const patientUpgradeSubscriptionFromWalletAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await PatientService.patientUpgradeSubscriptionFromWallet(payload);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export default {
  serviceProviderTypeInPatientAction,
  patientProfileDetailsAction,
  nearByPhysicialAction,
  homeDataAction,
  homeDataLaboratoryPatientAction,
  changePasswordAction,
  upcomingAppointmentPatientAction,
  previousAppointmentPatientAction,
  viewAppointmentPatientAction,
  editProfileInPatsettingsAction,
  addCardInPatsettingsAction,
  cardListInPatsettingsAction,
  deleteCardInPatsettingsAction,
  editCardInPatsettingsAction,
  patientPrivacyPolicyAction,
  patientTermsAndConditionAction,
  patientLogOutAction,
  patientSubscriptionInWebAction,
  patientWalletInWebAction,
  patientRescheduleRequestListInWebAction,
  patientAcceptRescheduleRequestAction,
  patientRejectRescheduleRequestAction,
  patientSecondarySpecialityListAction,
  patientMedicalRecordsListAction,
  patientPrescriptionListAction,
  patientPrescriptionViewAction,
  setFavourite,
  favListAction,
  bookAppointmentWithCardAction,
  addRatingsAction,
  viewLaboratoryDetailAction,
  labUpcomingListAction,
  labPreviousListAction,
  cardListInPatAction,
  medicalRecordList,
  patientinvoicelistAction,
  patientinvoiceViewAction,
  patientinvoicelistFullAction,
  patientHelplistAction,
  patientSubmitHelpRequestAction,
  patientViewHelpAction,
  patientReplyOnRequestAction,
  patientReportListAction,
  patientUpgradeSubscriptionFromWalletAction,
};
