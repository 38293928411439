import React, { useState, useEffect } from "react";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../constants";
import { useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { authentication, commonAction, patient } from "../../../redux/action";
import PdfIc from "../../../assets/img/pdf.svg";
import ToasterFunc from "../../common/toaster/Toaster";
import defaultPic from "../../../assets/img/default-img.png";

function PetEditMedical() {
  let dispatch = useDispatch();
  let history = useHistory();
  const [fileToggle, setfileToggle] = useState(false);
  const [Image, setImage] = useState("");
  const [ImageErr, setImageErr] = useState("");
  const [DiabeticError, setDiabeticError] = useState("");
  const [DiabeticDetailErr, setDiabeticDetailErr] = useState("");
  const [DiabeticDetail, setDiabeticDetail] = useState("");
  const [Diabetic, setDiabetic] = useState({
    Yes: false,
    No: false,
  });
  const [medicalFile, setmedicalFile] = useState("");

  const CommonProfilePicInMedical = async (payload) => dispatch(commonAction.commonProfilePicAction(payload));

  const commonUploadedFileInMedical = async (payload) => dispatch(commonAction.commonUploadedFileAction(payload));

  const editProfileInPatsetting = async (payload) => dispatch(patient.editProfileInPatsettingsAction(payload));

  const PatientProfileInfo = async () => dispatch(patient.patientProfileDetailsAction());

  const PatientData = useSelector((state) => state?.patient?.patientProdileInfo?.userData);

  console.log("PatientData", PatientData);

  useEffect(() => {
    PatientProfileInfo();
  }, []);

  useEffect(() => {
    if (Image !== "") {
      setImageErr("");
    }
    if (Diabetic.Yes !== false || Diabetic.No !== false) {
      setDiabeticError("");
    }
    if (DiabeticDetail !== "") {
      setDiabeticDetailErr("");
    }
  }, [Image, Diabetic, DiabeticDetail]);

  const formik = useFormik({
    initialValues: {
      height: "",
      weight: "",
      bloodGroup: "",
      AllergiesDetail: "",
      FamilyNote: "",
      weightUnit: "",
      heightUnit: "",
    },
    validationSchema: Yup.object({
      height: Yup.number().required("*Required.").typeError("*Please enter only digits"),
      weight: Yup.number().required("*Required.").typeError("*Please enter only digits"),
      bloodGroup: Yup.string().required("*Required"),
      AllergiesDetail: Yup.string().required("*Required"),
      FamilyNote: Yup.string().required("*Required"),
    }),
    onSubmit: (values) => {
      console.log("values", values);
    },
  });

  console.log("DiabeticDetailErr", DiabeticDetail, DiabeticDetailErr);

  useEffect(() => {
    formik.setFieldValue("height", PatientData && PatientData.height);
    formik.setFieldValue("weight", PatientData && PatientData.weight);
    formik.setFieldValue("bloodGroup", PatientData && PatientData.bloodGroup);
    formik.setFieldValue("AllergiesDetail", PatientData && PatientData.allergyDeatils);
    formik.setFieldValue("FamilyNote", PatientData && PatientData.familyNotes);
    formik.setFieldValue("weightUnit", PatientData && PatientData?.weightUnit);
    formik.setFieldValue("heightUnit", PatientData && PatientData?.hightUnit);
    if (PatientData && PatientData.iSDiabetes) {
      setDiabetic({
        Yes: true,
      });
    } else {
      setDiabetic({
        No: true,
      });
    }
    if (PatientData && PatientData.profilePic) {
      setImage(PatientData.profilePic);
    }
    if (Diabetic.Yes) {
      if (PatientData && PatientData.diabetesDetails) {
        setDiabeticDetail(PatientData.diabetesDetails);
      }
    }
    if (PatientData && PatientData.medicalFile) {
      setmedicalFile(PatientData.medicalFile);
    }
  }, [PatientData]);

  const handleNext = async () => {
    if (Diabetic.Yes === false && Diabetic.No === false) {
      setDiabeticError("*Please Choose any one");
    }
    if (Diabetic.Yes === true || Diabetic.No === true) {
      setDiabeticError("");
    }
    if (Diabetic.Yes) {
      if (DiabeticDetail === "") {
        setDiabeticDetailErr("*Required");
      }
    }
    if (Image === "") {
      setImageErr("*Please choose image");
    }
    if (Image !== "") {
      setImageErr("");
    }
    if (Diabetic.Yes === true || Diabetic.No === true) {
      if (Diabetic.Yes) {
        if (
          formik.values.height !== "" &&
          formik.values.weight !== "" &&
          formik.values.bloodGroup !== "" &&
          DiabeticDetail !== "" &&
          formik.values.AllergiesDetail !== "" &&
          formik.values.FamilyNote !== ""
        ) {
          const payload = {
            profilePic: Image ? Image : "",
            height: formik.values.height,
            weight: formik.values.weight,
            bloodGroup: formik.values.bloodGroup,
            iSDiabetes: Diabetic.Yes ? true : false,
            diabetesDetails: DiabeticDetail ? DiabeticDetail : "",
            allergyDeatils: formik.values.AllergiesDetail,
            familyNotes: formik.values.FamilyNote,
            medicalFile: medicalFile ? medicalFile : "",
            weightUnit: formik.values.weightUnit,
            hightUnit: formik.values.heightUnit,
          };
          console.log("payload==", payload);
          const res = await editProfileInPatsetting(payload);
          if (res && res.status == 200) {
            console.log("Complete profile", res);
            ToasterFunc("success", res.data.responseMessage);
            history.push(ROUTES_CONSTANTS.PATIENT_DASHBOARD);
          }
        }
      } else if (Diabetic.No) {
        if (
          formik.values.height !== "" &&
          formik.values.weight !== "" &&
          formik.values.bloodGroup !== "" &&
          formik.values.AllergiesDetail !== "" &&
          formik.values.FamilyNote !== ""
        ) {
          const payload = {
            weightUnit: formik.values.weightUnit,
            hightUnit: formik.values.heightUnit,
            profilePic: Image ? Image : "",
            height: formik.values.height,
            weight: formik.values.weight,
            bloodGroup: formik.values.bloodGroup,
            iSDiabetes: Diabetic.Yes ? true : false,
            diabetesDetails: DiabeticDetail ? DiabeticDetail : "",
            allergyDeatils: formik.values.AllergiesDetail,
            familyNotes: formik.values.FamilyNote,
            medicalFile: medicalFile ? medicalFile : "",
          };
          console.log("formik.values==", formik.values);
          console.log("payload==", payload);
          const res = await editProfileInPatsetting(payload);
          if (res && res.status == 200) {
            console.log("Complete profile", res);
            ToasterFunc("success", res.data.responseMessage);
            history.push(ROUTES_CONSTANTS.PATIENT_DASHBOARD);
          }
        }
      }
    }
  };
  console.log(formik.errors);

  const handleDiabetic = (e) => {
    let checked = e.target.checked;
    let value = e.target.value;
    if (value === "1") {
      setDiabetic({
        Yes: checked,
      });
    } else if (value === "2") {
      setDiabetic({
        No: checked,
      });
    }
  };

  const handleDiabeticDetail = (e) => {
    // console.log(e.target.value);
    setDiabeticDetail(e.target.value);
  };

  const handleImage = (e) => {
    let result = e.target.value;
    setImage(result);
    const file = e.target.files[0];
    console.log(file);
    let formData = new FormData();
    console.log("file changed");

    formData.append("file", file);
    fileUpload(formData);
  };

  const fileUpload = async (image) => {
    const res = await CommonProfilePicInMedical(image);
    if (res && res.status == 200) {
      console.log("Profile pIccccccccc", res.data.result.url);
      let picdata = res && res.data && res.data.result.url;
      setImage(picdata);
    }
  };

  const handleUploadFile = (e) => {
    // let result = e.target.value;
    console.log(e.target.value);
    // setmedicalFile(result);
    setfileToggle(true);
    if (e.target.files[0]) {
      var file = e.target.files[0];
    }

    console.log(file);
    let formData = new FormData();
    console.log("file changed");

    formData.append("file", file);
    medicalFileUpload(formData);
  };

  const medicalFileUpload = async (image) => {
    const res = await commonUploadedFileInMedical(image);
    if (res && res.status == 200) {
      console.log("medical reportttttt", res.data.result.url);
      //   let picdata= res &&  res.data && res.data.result.url
      setmedicalFile(res && res.data && res.data.result && res.data.result.url);
    }
  };

  return (
    <div class=" col-lg-8 col-xl-9 ">
      <div class="card">
        <div class="card-header">
          <h4>Edit Medical Profile</h4>
        </div>
        <div class="card-body">
          <div class="row form-row mb-4">
            <div class="col-md-12">
              <div class="change-avatar justify-content-center">
                <div class="profile-round-img">
                  <figure className="mb-0">
                    <img src={Image ? Image : defaultPic} alt="" />
                  </figure>
                  <label for="camera">
                    <input type="file" id="camera" accept="image/*" hidden onChange={(e) => handleImage(e)} />
                    <i class="fas fa-camera icon-camera"></i>
                  </label>
                </div>
              </div>
              {ImageErr ? <div className="validation_error Image">{ImageErr}</div> : null}
            </div>
          </div>

          {/* <!-- Register Form --> */}
          <form onSubmit={formik.handleSubmit}>
            <div class="row align-items-baseline">
              <div class="col-md-2">
                <label class="focus-label" for="Height">
                  Height
                </label>
              </div>
              <div class="col-md-10">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    maxLength="3"
                    id="Height"
                    name="height"
                    placeholder="Enter Height"
                    {...formik.getFieldProps("height")}
                  />

                  <div class="input-group-append">
                    {/* <span class="input-group-text">Cm</span> */}
                    <select class="form-control" {...formik.getFieldProps("heightUnit")}>
                      <option value="Feet">Feet</option>
                      <option value="CM">CM</option>
                    </select>
                  </div>
                </div>
                {formik.touched.height && formik.errors.height ? (
                  <span className="validation_error mt--12">{formik.errors.height}</span>
                ) : null}
              </div>
            </div>

            <div class="row align-items-baseline">
              <div class="col-md-2">
                <label class="focus-label" for="Weight">
                  Weight
                </label>
              </div>
              <div class="col-md-10">
                <div class="input-group mb-3">
                  <input
                    type="text"
                    class="form-control"
                    maxLength="3"
                    id="Weight"
                    name="weight"
                    placeholder="Enter Weight"
                    {...formik.getFieldProps("weight")}
                  />

                  <div class="input-group-append">
                    {/* <span class="input-group-text">Kgs</span> */}
                    <select class="form-control" {...formik.getFieldProps("weightUnit")}>
                      <option value="lbs">lbs</option>
                      <option value="KG">KG</option>
                    </select>
                  </div>
                </div>
                {formik.touched.weight && formik.errors.weight ? (
                  <span className="validation_error mt--12">{formik.errors.weight}</span>
                ) : null}
              </div>
            </div>

            <div class="form-group">
              <label for="">Blood Group*</label>
              <select class="form-control" name="bloodGroup" {...formik.getFieldProps("bloodGroup")}>
                <option hidden>Select</option>
                <option value="A+">A+</option>
                <option value="O+">O+</option>
                <option value="B+">B+</option>
                <option value="AB+">AB+</option>
                <option value="A-">A-</option>
                <option value="O-">O-</option>
                <option value="B-">B-</option>
                <option value="AB-">AB-</option>
              </select>
              {formik.touched.bloodGroup && formik.errors.bloodGroup ? (
                <span className="validation_error">{formik.errors.bloodGroup}</span>
              ) : null}
            </div>
            <div class="row form-group">
              <div class="col-md-6">
                <div class="">
                  <label class="form-check-label" for="exampleCheck1">
                    Are You diagonsed with diabetes?
                  </label>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-check">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="yes"
                    name="diabetic"
                    value="1"
                    checked={Diabetic.Yes ? Diabetic.Yes : false}
                    onClick={(e) => handleDiabetic(e)}
                  />
                  <label class="form-check-label color-grey" for="yes">
                    Yes
                  </label>
                </div>
              </div>
              <div class="col-md-3">
                <div class="form-check ">
                  <input
                    type="radio"
                    class="form-check-input"
                    id="no"
                    name="diabetic"
                    value="2"
                    checked={Diabetic.No ? Diabetic.No : false}
                    onClick={(e) => handleDiabetic(e)}
                  />
                  <label class="form-check-label color-grey" for="no">
                    No
                  </label>
                </div>
              </div>
              {DiabeticError ? <div className="validation_error Image">{DiabeticError}</div> : null}
            </div>
            <div class={Diabetic.Yes ? "form-group" : "form-group diabetic_hide"}>
              <label for="descriptionpersonalName">Diabetes Details</label>
              <input
                class="form-control"
                id="descriptionpersonalName"
                maxLength="180"
                placeholder="Type here...."
                name="DiabeteDetail"
                value={DiabeticDetail}
                onChange={(e) => handleDiabeticDetail(e)}
              ></input>
              {DiabeticDetailErr ? <div className="validation_error">{DiabeticDetailErr}</div> : null}
            </div>

            <div class="form-group">
              <label for="descriptionpersonalName">Do You Have Any Allergies?</label>
              <textarea
                class="form-control"
                id="descriptionpersonalName"
                rows="3"
                maxLength="180"
                placeholder="Type here...."
                name="AllergiesDetail"
                {...formik.getFieldProps("AllergiesDetail")}
              ></textarea>
              {formik.touched.AllergiesDetail && formik.errors.AllergiesDetail ? (
                <span className="validation_error">{formik.errors.AllergiesDetail}</span>
              ) : null}
            </div>
            <div class="form-group">
              <label for="descriptionpersonalName">Familiy Notes</label>
              <textarea
                class="form-control"
                id="descriptionpersonalName"
                rows="3"
                maxLength="180"
                placeholder="Type here...."
                name="FamilyNote"
                {...formik.getFieldProps("FamilyNote")}
              ></textarea>
              {formik.touched.FamilyNote && formik.errors.FamilyNote ? (
                <span className="validation_error">{formik.errors.FamilyNote}</span>
              ) : null}
            </div>
            <div class="form-group">
              <label for="uploadPdf">Upload Pdf</label>
              {medicalFile ? (
                <div class="upload-pdf-border">
                  {/* <label class="upload-reports-pdf ">
                 <input type="file" hidden />
                 <i class="fas fa-file-pdf color-grey"></i>
               </label> */}
                  <label className="pdf-box">
                    <a href={medicalFile}>
                      <img style={{ width: "50px", height: "50px" }} src={PdfIc} alt="img" />
                    </a>
                    <span className="pdf-del">
                      <i class="fa fa-times" aria-hidden="true"></i>
                      <input type="file" hidden onChange={(e) => handleUploadFile(e)} />
                    </span>
                  </label>
                </div>
              ) : (
                <div class="upload-pdf-border">
                  <label class="upload-reports-pdf ">
                    <input type="file" hidden onChange={(e) => handleUploadFile(e)} />
                    <i class="fas fa-file-pdf color-grey"></i>
                  </label>
                  {/* <label className="pdf-box">
               <img style={{ width: "40px", height: "40px" }}  src={PdfIc} alt="img" />
               <span className="pdf-del"><i class="fa fa-times" aria-hidden="true"></i></span>
               </label> */}
                </div>
              )}
            </div>
            <div class="submit-section btn-p">
              <button type="submit" class="btn btn-primary submit-btn w-25" onClick={handleNext}>
                Update
              </button>
            </div>
          </form>
          {/* <!-- /Register Form --> */}
        </div>
      </div>
    </div>
  );
}

export default PetEditMedical;
