import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import './style.css';
import { useHistory } from "react-router";
import { Modal, ModalBody } from "reactstrap";
import { authentication, patient } from "../../../redux/action";

function UpgradeCoupon({ CouponModal, setCouponModal, amount, setCouponAmount, couponAmount }) {
  let dispatch = useDispatch();
  let history = useHistory();
  const [tax, settax] = useState(0);
  const [Price, setPrice] = useState({
    PercentagePrice: "",
    FixedPrice: "",
  });
  const [PercentagePrice, setPercentagePrice] = useState("");
  const [FixedPrice, setFixedPrice] = useState("");

  const setHandleModal = () => {
    setCouponModal(false);
  };
  const couponListInfo = useSelector((state) => state?.authentication?.couponList);

  const SubscriptionListInfo = useSelector((state) => state?.authentication?.patientSubscriptionList);

  console.log("couponList", couponListInfo, SubscriptionListInfo);

  const couponList = async () => dispatch(authentication.couponListAction());

  const SubscriptionList = async () => dispatch(authentication.subscriptionListPatientAction());
  const PatientProfileInfo = async () => dispatch(patient.patientProfileDetailsAction());

  useEffect(() => {
    couponList();
    PatientProfileInfo();
    SubscriptionList();
  }, []);

  useEffect(() => {
    if (couponListInfo && couponListInfo.length) {
      couponListInfo.map((item) => {
        if (item.couponType == "PERCENTAGE") {
          SubscriptionListInfo &&
            SubscriptionListInfo.filter((item) => {
              if (item._id === PatientData && PatientData.subscriptionId) {
                setPrice({
                  PercentagePrice: item.price,
                });
                console.log("coming");
                setPercentagePrice(item.price);
              }
            });
        } else {
          SubscriptionListInfo &&
            SubscriptionListInfo.filter((item) => {
              if (item._id === PatientData && PatientData.subscriptionId) {
                setPrice({
                  FixedPrice: item.price,
                });
                setFixedPrice(item.price);
              }
            });
        }
      });
    }
  }, [couponListInfo, SubscriptionListInfo]);

  console.log(PercentagePrice, FixedPrice);

  const PatientData = useSelector((state) => state?.patient?.patientProdileInfo?.userData);
  console.log("PatientData", PatientData);

  console.log();

  return (
    <Modal isOpen={CouponModal} className="modal-dialog-centered">
      {/* <div className="modal fade modal-delete" id="couponCode" tabindex="-1" role="dialog">
        <div className="modal-dialog  modal-dialog-centered " role="document"> */}
      <div className="modal-content p-3">
        <div className="text-right">
          <button type="button" className="dr_Modalclose " data-dismiss="modal" aria-label="Close" onClick={setHandleModal}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody>
          {/* <div className="modal-body"> */}
          {console.log(couponListInfo)}
          <div className="row">
            <div className="col-lg-12 ">
              <div className="card mb-0">
                <div className="card-body">
                  {couponListInfo && couponListInfo.length ? (
                    couponListInfo.map((item) => (
                      <div className="form-check form-group">
                        <input className="form-check-input" type="radio" name="Coupon" id="Coupon-1" onClick={() => setCouponAmount(item.couponType == "PERCENTAGE" ? Number(item.value) : (Number(item.value) * Number(amount)) / 100)} />
                        <div className="d-flex justify-content-between">
                          <label className="form-check-label " for="exampleRadios1">
                            {item && item.code}
                          </label>
                          <label for="" className="color-grey">
                            {item.couponType == "PERCENTAGE" ? `$${item.value}` : `$${(Number(item.value) * Number(amount)) / 100}`}
                          </label>
                        </div>
                        <p className="color-grey">{item.couponType == "PERCENTAGE" ? `${item.value}% Discount on $${amount}` : `$${item.value} on $${Number(amount) + tax || 0}`}</p>
                      </div>
                    ))
                  ) : (
                    <p className="color-grey">No Coupon Available</p>
                  )}
                  <div className="d-flex justify-content-center">
                    <button className="btn btn-primary mr-1" data-dismiss="modal" onClick={setHandleModal}>
                      Cancel
                    </button>
                    <button onClick={couponAmount && setHandleModal} className="btn btn-primary mr-1">
                      Apply
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </ModalBody>
      </div>
      {/* </div>
    </div> */}
    </Modal>
  );
}

export default UpgradeCoupon;
