import FooterLogo from "../../assets/img/footer-logo.png";
import AppStore from "../../assets/img/appstore.png";
import Playstore from "../../assets/img/playstore.png";
import DocProfile from "../../assets/img/doctors/doctor-thumb-02.jpg";
import PatProfile from "../../assets/img/patients/patient2.jpg";
import PlaceHolderPic from "../../assets/img/img-02.jpg";
import LoginBanner from "../../assets/img/login-banner.png";
import SpecialitiesOne from "../../assets/img/specialities/specialities-01.png";
import SpecialitiesTwo from "../../assets/img/specialities/specialities-02.png";
import SpecialitiesThree from "../../assets/img/specialities/specialities-03.png";
import SpecialitiesFour from "../../assets/img/specialities/specialities-04.png";
import SpecialitiesFive from "../../assets/img/specialities/specialities-05.png";
import MedicalImgOne from "../../assets/img/medical-img1.jpg";
import MedicalImgTwo from "../../assets/img/medical-img2.jpg";
import MedicalImgThree from "../../assets/img/medical-img3.jpg";
import UserIcon from "../../assets/img/UserIcon.png";
import PlaceHolder from "../../assets/img/default-img.png";

import HeaderLogo from "../../assets/img/header-logo.png";
import footerlogoN from "../../assets/img/GDLOGO-white-new.png";
import defaultIcon from "../../assets/img/default-img copy.png";
import CoachImg from "../../assets/img/patients/patient2.jpg";
import Cloclimg from "../../assets/img/clock.png";
import DocImg1 from "../../assets/img/doctors/doctor-thumb-01.jpg";
import DocImg2 from "../../assets/img/doctors/doctor-thumb-02.jpg";
import DocImg3 from "../../assets/img/doctors/doctor-thumb-03.jpg";
import DocImg4 from "../../assets/img/doctors/doctor-thumb-04.jpg";
import DocImg5 from "../../assets/img/doctors/doctor-thumb-05.jpg";
import DocImg6 from "../../assets/img/doctors/doctor-thumb-06.jpg";
import DoctorProfile from "../../assets/img/doctor-thumb-02.jpg";


export const IMAGE_CONSTANT = {
  HEADER_LOGO: HeaderLogo,
  FOOTER_LOGO: FooterLogo,
  APP_STORE: AppStore,
  PLAY_STORE: Playstore,
  DOC_PROFILE: DocProfile,
  PAT_PROFILE: PatProfile,
  PLACE_HOLDERPIC: PlaceHolderPic,
  LOGIN_BANNER: LoginBanner,
  SPECIALITIES_ONE: SpecialitiesOne,
  SPECIALITIES_TWO: SpecialitiesTwo,
  SPECIALITIES_THREE: SpecialitiesThree,
  SPECIALITIES_FOUR: SpecialitiesFour,
  SPECIALITIES_FIVE: SpecialitiesFive,
  MEDICAL_IMAGE_ONE: MedicalImgOne,
  MEDICAL_IMAGE_TWO: MedicalImgTwo,
  MEDICAL_IMAGE_THREE: MedicalImgThree,
  FOOTER_LOGON: footerlogoN,
  USER_ICON: UserIcon,
  PLACEHOLDER: PlaceHolder,
  DEFAULT_ICON: defaultIcon,
  COACH_IMG: CoachImg,
  DOC_IMG1: DocImg1,
  DOC_IMG2: DocImg2,
  DOC_IMG3: DocImg3,
  DOC_IMG4: DocImg4,
  DOC_IMG5: DocImg5,
  DOC_IMG6: DocImg6,
  CLOCK_IMG: Cloclimg,
  DOC_PROFILE: DoctorProfile
};
