import React, { useEffect } from "react";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../constants";

import { ProSidebar, SidebarHeader, SidebarContent } from "react-pro-sidebar";
import { useHistory, useLocation, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { doctor, patient } from "../../../redux/action";
import ToasterFunc from "../toaster/Toaster";
import Sticky from "react-sticky-el";
import { getSessionStorageOrDefault, logOutManage } from "../../../utils";
import { loginData } from "../../../redux/action/authentication/authentication";

function DrawerMenu() {
  const { HelpId } = useParams();
  const history = useHistory();
  const url = useLocation();
  let dispatch = useDispatch();
  const handleNvigation = (route) => {
    history.push(route);
  };

  const handleViewProfile = () => {
    if (getSessionStorageOrDefault("userType") === "1") {
      history.push(ROUTES_CONSTANTS.DOC_VIEW_PROFILE);
    } else if (getSessionStorageOrDefault("userType") === "2") {
      history.push(ROUTES_CONSTANTS.PAT_VIEW_PROFILE);
    }
  };
  useEffect(() => {
    if (getSessionStorageOrDefault("userType") === "1"){
      DoctorProfileInfo();
    }else{
      PatientProfileInfo();
    }
  }, []);

  const PatientData = useSelector((state) => state?.patient?.patientProdileInfo);
  const DoctorData = useSelector((state) => state?.doctor?.doctorProdileInfo);

  console.log("PatientData", PatientData);

  useEffect(() => {
  console.log("DoctorData",DoctorData);
  const pathname = window.location.pathname;
  loginData.subscribe(res=>{
    if (res){
      let userParseData= JSON.parse(window.atob(res));
      console.log("userParseData",userParseData);
      if ((pathname === '/dashboard') && (userParseData?.data?.result?.userType === "DOCTOR" ) && (userParseData?.data?.result?.isProfessional_profile_complete === false) ){
        handleLogOut()
      }
    }else{
      if(!sessionStorage.getItem('loggedUserInfo') && (getSessionStorageOrDefault("userType") === "1")){
        handleLogOut()
      }
    }
  })
  }, [DoctorData])
  

  const PatientProfileInfo = async () => dispatch(patient.patientProfileDetailsAction());

  const DoctorProfileInfo = async () => dispatch(doctor.doctorProfileDetailsAction());

  const PatientLogOut = async () => dispatch(patient.patientLogOutAction());
  const DocLogout = async () => dispatch(doctor.docLogoutAction());

  const handleLogOut = async () => {
    if (getSessionStorageOrDefault("userType") === "2") {
      const res = await PatientLogOut();
      if (res && res.status === 200) {
        logOutManage();
        sessionStorage.clear();
        ToasterFunc("success", res.data.responseMessage);
        history.push(ROUTES_CONSTANTS.HOME);
      }
    } else if (getSessionStorageOrDefault("userType") === "1") {
      const res = await DocLogout();
      if (res && res.status === 200) {
        logOutManage();
        sessionStorage.clear();
        ToasterFunc("success", res.data.responseMessage);
        history.push(ROUTES_CONSTANTS.HOME);
      }
    }
  };
  console.log("hisss", url);

  const menuToggleClose = () => {
    document.body.classList.remove("menu-opened2");
  };

  return (
    <>
      <div className=" col-lg-4 col-xl-3 theiaStickySidebar sidebar_ipad_p">
        <Sticky boundaryElement=".theiaStickySidebar" className="nav_sticky" topOffset={80}>
          <div className="Stickysidebar">
            <div className="profile-sidebar main-menu-wrapper2">
              <div className="menu-header justify-content-end">
                <a id="menu_close2" className="menu-close " onClick={menuToggleClose}>
                  <i className="fas fa-times"></i>
                </a>
              </div>
              {getSessionStorageOrDefault("userType") === "1" ? (
                <div className="widget-profile pro-widget-content">
                  <div className="profile-info-widget">
                    <div className="booking-doc-img">
                      <img src={DoctorData && DoctorData.result && DoctorData.result[0].profilePic ? DoctorData.result[0].profilePic : IMAGE_CONSTANT.DEFAULT_ICON} alt="" />
                    </div>
                    <div className="profile-det-info">
                      <h3 className="mb-1">Dr. {DoctorData && DoctorData.result && DoctorData.result[0].fullName ? DoctorData.result[0].fullName : ""}</h3>
                      <a
                        className="mb-2"
                        onClick={() => {
                          handleViewProfile();
                          menuToggleClose();
                        }}
                      >
                        View Profile
                      </a>
                      <div className="patient-details">
                        <h5 className="mb-0">
                          {DoctorData && DoctorData.result && DoctorData.result[0].serviceProviderType[0] && DoctorData.result[0].serviceProviderType[0].primary_specialty ? DoctorData.result[0].serviceProviderType[0].primary_specialty : ""}
                          <br /> {DoctorData && DoctorData.result[0] && DoctorData.result[0].experience_in_years ? `${DoctorData.result[0].experience_in_years} Years  ` : ""}
                          {DoctorData && DoctorData.result[0].experience_in_months ? `${DoctorData.result[0].experience_in_months} Months` : ""} Of Experience{" "}
                        </h5>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="widget-profile pro-widget-content">
                  <div className="profile-info-widget">
                    <a href="#" className="booking-doc-img">
                      <img src={PatientData && PatientData.userData && PatientData.userData.profilePic ? PatientData.userData.profilePic : IMAGE_CONSTANT.USER_ICON} alt="" />
                    </a>
                    <div className="profile-det-info">
                      <h3>{PatientData && PatientData.userData && `${PatientData.userData.firstName} ${PatientData.userData.lastName}`}</h3>
                      <div className="patient-details">
                        <a
                          onClick={() => {
                            handleViewProfile();
                            menuToggleClose();
                          }}
                        >
                          View Profile
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="dashboard-widget">
                <nav className="dashboard-menu">
                  <ul onClick={menuToggleClose}>
                    <li className={url.pathname === ROUTES_CONSTANTS.DASHBOARD ? "active" : ""}>
                      {getSessionStorageOrDefault("userType") === "1" ? (
                        <a>
                          <div onClick={() => handleNvigation(ROUTES_CONSTANTS.DASHBOARD)}>
                            <i className="fas fa-columns"></i>
                            <span>Dashboard</span>
                          </div>
                        </a>
                      ) : (
                        <a>
                          <div onClick={() => handleNvigation(ROUTES_CONSTANTS.PATIENT_DASHBOARD)}>
                            <i className="fas fa-columns"></i>
                            <span>Dashboard</span>
                          </div>
                        </a>
                      )}
                    </li>
                    <li className={url.pathname === ROUTES_CONSTANTS.PATIENT_APPOINTMENT ? "active" : ""}>
                      {getSessionStorageOrDefault("userType") === "1" ? (
                        <a>
                          <div onClick={() => handleNvigation(ROUTES_CONSTANTS.INBOX)}>
                            <i className="far fa-comments"></i>
                            <span>Inbox</span>
                          </div>
                        </a>
                      ) : (
                        <a onClick={() => handleNvigation(ROUTES_CONSTANTS.PATIENT_APPOINTMENT)}>
                          <div>
                            <i className="fas fa-calendar-check"></i>
                            <span>Appointments</span>
                          </div>
                        </a>
                      )}
                    </li>
                    <li className={url.pathname === ROUTES_CONSTANTS.APPOINTMENTS ? "active" : ""}>
                      {getSessionStorageOrDefault("userType") === "1" ? (
                        <a>
                          <div onClick={() => handleNvigation(ROUTES_CONSTANTS.APPOINTMENTS)}>
                            <i className="fas fa-calendar-check"></i>
                            <span>Appointments</span>
                          </div>
                        </a>
                      ) : (
                        <a>
                          <div onClick={() => handleNvigation(ROUTES_CONSTANTS.PATIENT_MESSAGES)}>
                            <i className="far fa-comments"></i>
                            <span>Messages</span>
                          </div>
                        </a>
                      )}
                    </li>

                    <li className={url.pathname === ROUTES_CONSTANTS.SCHEDULE_AVAILABILITY ? "active" : ""}>
                      {getSessionStorageOrDefault("userType") === "1" ? (
                        <a>
                          <div onClick={() => handleNvigation(ROUTES_CONSTANTS.SCHEDULE_AVAILABILITY)}>
                            <i className="fas fa-hourglass-start"></i>
                            <span>Schedule Availability</span>
                          </div>
                        </a>
                      ) : (
                        <a>
                          <div onClick={() => handleNvigation(ROUTES_CONSTANTS.PATIENT_FAVOURITE_SPECIALIST)}>
                            <i className="fas fa-hourglass-start"></i>
                            <span>Favourite Specialists</span>
                          </div>
                        </a>
                      )}
                    </li>

                    <li className={url.pathname === ROUTES_CONSTANTS.REVIEW ? "active" : ""}>
                      {getSessionStorageOrDefault("userType") === "1" ? (
                        <a>
                          <div onClick={() => handleNvigation(ROUTES_CONSTANTS.REVIEW)}>
                            <i className="fas fa-star"></i>
                            <span>Reviews</span>
                          </div>
                        </a>
                      ) : (
                        <a>
                          <div onClick={() => handleNvigation(ROUTES_CONSTANTS.PATIENT_REQUEST)}>
                            <i className="fas fa-paper-plane"></i>
                            <span>Requests</span>
                          </div>
                        </a>
                      )}
                    </li>
                    <li className={url.pathname === ROUTES_CONSTANTS.BILLINGS ? "active" : ""}>
                      {getSessionStorageOrDefault("userType") === "1" ? (
                        <a>
                          <div onClick={() => handleNvigation(ROUTES_CONSTANTS.BILLINGS)}>
                            <i className="fas fa-file-invoice"></i>
                            <span>Billings</span>
                          </div>
                        </a>
                      ) : (
                        <a onClick={() => handleNvigation(ROUTES_CONSTANTS.PATIENT_REPORTS)}>
                          <div>
                            <i className="fas fa-file-medical-alt"></i>
                            <span>My Reports</span>
                          </div>
                        </a>
                      )}
                    </li>

                    <li className={url.pathname === ROUTES_CONSTANTS.SUBSCRIPTION ? "active" : ""}>
                      {getSessionStorageOrDefault("userType") === "1" ? (
                        <a>
                          <div onClick={() => handleNvigation(ROUTES_CONSTANTS.SUBSCRIPTION)}>
                            <i className="fa fa-rocket"></i>
                            <span>Subscription</span>
                          </div>
                        </a>
                      ) : (
                        <a onClick={() => handleNvigation(ROUTES_CONSTANTS.PATIENT_MEDICAL_HISTORY)}>
                          <div>
                            <i className="fas fa-file-medical"></i>
                            <span>Medical History</span>
                          </div>
                        </a>
                      )}
                    </li>
                    <li className={url.pathname === ROUTES_CONSTANTS.NOTIFICATIONS ? "active" : ""}>
                      {getSessionStorageOrDefault("userType") === "1" ? (
                        <a>
                          <div onClick={() => handleNvigation(ROUTES_CONSTANTS.NOTIFICATIONS)}>
                            <i className="fas fa-bell"></i>
                            <span>Notifications 
                             {DoctorData?.unreadNotificationCount > 0 && <span className="notCount text-center"> {DoctorData?.unreadNotificationCount} </span> }
                              </span>

                          </div>
                        </a>
                      ) : (
                        <a onClick={() => handleNvigation(ROUTES_CONSTANTS.PATIENT_HELP)}>
                          <div>
                            <i className="fas fa-question"></i>
                            <span>Help</span>
                          </div>
                        </a>
                      )}
                    </li>

                    <li className={url.pathname === ROUTES_CONSTANTS.HELP || url.pathname === ROUTES_CONSTANTS.DOCTOR_HELP_NEW || url.pathname === `${ROUTES_CONSTANTS.DOCTOR_VIEW_HELP_REQUEST}/${HelpId}` ? "active" : ""}>
                      {getSessionStorageOrDefault("userType") === "1" ? (
                        <a>
                          <div onClick={() => handleNvigation(ROUTES_CONSTANTS.HELP)}>
                            <i className="fas fa-question"></i>
                            <span>Help</span>
                          </div>
                        </a>
                      ) : (
                        <a onClick={() => handleNvigation(ROUTES_CONSTANTS.PATIENT_REVIEW)}>
                          <div>
                            <i className="fas fa-star"></i>
                            <span>Reviews</span>
                          </div>
                        </a>
                      )}
                    </li>
                    <li className={url.pathname === ROUTES_CONSTANTS.WALLETS ? "active" : ""}>
                      {getSessionStorageOrDefault("userType") === "1" ? (
                        <a>
                          <div onClick={() => handleNvigation(ROUTES_CONSTANTS.WALLETS)}>
                            <i className="fas fa-wallet"></i>
                            <span>Wallet</span>
                          </div>
                        </a>
                      ) : (
                        <a>
                          <div onClick={() => handleNvigation(ROUTES_CONSTANTS.PATIENT_WALLET)}>
                            <i className="fas fa-star"></i>
                            <span>Wallet</span>
                          </div>
                        </a>
                      )}
                    </li>
                    <li className={url.pathname === ROUTES_CONSTANTS.PROFILE_SETTINGS ? "active" : ""}>
                      {getSessionStorageOrDefault("userType") === "1" ? (
                        <a>
                          <div onClick={() => handleNvigation(ROUTES_CONSTANTS.PROFILE_SETTINGS)}>
                            <i className="fas fa-user-cog"></i>
                            <span>Profile Settings</span>
                          </div>
                        </a>
                      ) : (
                        <a>
                          <div onClick={() => handleNvigation(ROUTES_CONSTANTS.PATIENT_SUBSCRIPTION)}>
                            <i className="fa fa-rocket"></i>
                            <span>Subscription</span>
                          </div>
                        </a>
                      )}
                    </li>
                    <li className={url.pathname === ROUTES_CONSTANTS.ABOUT_US ? "active" : ""}>
                      {getSessionStorageOrDefault("userType") === "1" ? (
                        <a>
                          <div onClick={() => handleNvigation(ROUTES_CONSTANTS.ABOUT_US)}>
                            <i className="fa fa-info-circle"></i>
                            <span>About</span>
                          </div>
                        </a>
                      ) : (
                        <a onClick={() => handleNvigation(ROUTES_CONSTANTS.PATIENT_BILLING)}>
                          <div>
                            <i className="fas fa-file-invoice"></i>
                            <span>Billing</span>
                          </div>
                        </a>
                      )}
                    </li>
                    <li className={url.pathname === ROUTES_CONSTANTS.TERMS_AND_CONDITIONS ? "active" : ""}>
                      {getSessionStorageOrDefault("userType") === "1" ? (
                        <a>
                          <div onClick={() => handleNvigation(ROUTES_CONSTANTS.TERMS_AND_CONDITIONS)}>
                            <i className="fa fa-gavel"></i>
                            <span>Terms & Conditions </span>
                          </div>
                        </a>
                      ) : (
                        <a onClick={() => handleNvigation(ROUTES_CONSTANTS.PATIENT_SETTINGS)}>
                          <div>
                            <i className="fas fa-user-cog"></i>
                            <span>Settings</span>
                          </div>
                        </a>
                      )}
                    </li>
                    <li className={url.pathname === ROUTES_CONSTANTS.HOME ? "active" : ""}>
                      {getSessionStorageOrDefault("userType") === "1" ? (
                        <a>
                          <div onClick={handleLogOut}>
                            <i className="fas fa-sign-out-alt"></i>
                            <span>Logout</span>
                          </div>
                        </a>
                      ) : (
                        <a onClick={() => handleNvigation(ROUTES_CONSTANTS.PATIENT_NOTIFICATIONS)}>
                          <div>
                            <i className="fas fa-bell"></i>
                            {/* <span>Notifications  </span> */}
                            <span>Notifications 
                              {PatientData?.unreadNotificationCount > 0 &&  <span className="notCount text-center"> {PatientData?.unreadNotificationCount} </span>}
                               </span>
                          </div>
                        </a>
                      )}
                    </li>
                    <li>
                      {getSessionStorageOrDefault("userType") === "2" && (
                        <a>
                          <div onClick={handleLogOut}>
                            <i className="fas fa-sign-out-alt"></i>
                            <span>Logout</span>
                          </div>
                        </a>
                      )}
                    </li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </Sticky>
      </div>
    </>
  );
}

export default DrawerMenu;
