import React, { useEffect, useState } from "react";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../constants";
import DatePicker, { Calendar, utils } from "react-modern-calendar-datepicker";
import { useHistory, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bookingAction } from "../../../redux/action/bookingAction";
import ReactStars from "react-stars";
import { checkTime, convertUTCToLocal, convertUTCToLocalForInput, getTime } from "../../authentication/sign-up-as-doctor/availability";
import ToasterFunc from "../../common/toaster/Toaster";
import { commonAction, patient } from "../../../redux/action";
import moment from "moment";
import ConfirmationModal from "../findDoctorsNearBy/ConfirmationModal";

function BookLaboratory() {
  let history = useHistory();
  let location = useLocation();
  const [DocData, setDocData] = useState({});
  const [ConfirmModal, setConfirmModal] = useState(false);
  const [PaymentOption, setPaymentOption] = useState(false);
  const dispatch = useDispatch();
  const commonUploadedFile = async (payload) => dispatch(commonAction.commonUploadedFileAction(payload));
  const getDoctorDetails = async (payload) => dispatch(bookingAction.getDoctorDetailsAction(payload));
  // const bookAppointment = async (payload) => dispatch(bookingAction.bookAppointmentAction(payload));
  const setFav = async (payload) => dispatch(patient.setFavourite(payload));

  const getSlotsList = async (id, date) => dispatch(bookingAction.getLabSlotsListAction(id, date));

  const doctorDetails = useSelector((state) => state?.booking?.doctorDetails);
  const slotList = useSelector((state) => state?.booking?.slotListForLaboratory);
  const defaultValue = {
    year: Number(moment().format("YYYY")),
    month: Number(moment().format("MM")),
    day: Number(moment().format("DD")),
  };

  const { DocId } = useParams();
  const [selectedDay, setSelectedDay] = useState(defaultValue);
  const [slotSlected, setSLotSelected] = useState(null);
  const [reason, setReason] = useState("");
  const [fileUrl, setFIleUpload] = useState("");
  const [reasonErr, setreasonErr] = useState("");
  const [UpgradeToggle, setUpgradeToggle] = useState(false);

  useEffect(() => {
    console.log("@@@", DocId);
    getDoctorDetails(DocId);
    // setSelectedDay({
    //   year: moment().format("YYYY"),
    //   month: moment().format("MM"),
    //   day: moment().format("DD"),
    // });
    // callSlots();
  }, []);

  console.log("location in Book", location);

  useEffect(() => {
    if (selectedDay) callSlots();
  }, [selectedDay]);

  const callSlots = () => {
    getSlotsList(location?.state[0][0]?.LabId?.labId, selectedDay ? `${selectedDay.year}-${selectedDay.month}-${selectedDay.day}` : moment().format("YYYY-MM-DD"));
  };
  console.log("IDDDDDDDDDDDDDDD", location?.state[0][0].LabId);

  const handleNext = async () => {
    if (!slotSlected) {
      ToasterFunc("error", "Please select slots.");
    }
    if (!!slotSlected) {
      let fullSelectedDate = `${selectedDay.year}-${selectedDay.month < 10 ? "0" + selectedDay.month : selectedDay.month}-${selectedDay.day < 10 ? "0" + selectedDay.day : selectedDay.day}`;
      console.log("fullSelectedDate", fullSelectedDate);
      let endTime = new moment.utc(slotSlected.slotTime, "HH:mm A").local();
      endTime.add(30, "minutes");
      const payload = {
        ...location.state,
        laboratoryId: location?.state[0][0]?.LabId?.labId,
        packageId: location?.state[0][0]?.LabId?.packageId,
        slotTime: slotSlected.slotTime,
        dayId: slotList._id,
        slotId: slotSlected._id,
        bookingDate: `${selectedDay.year}-${selectedDay.month < 10 ? "0" + selectedDay.month : selectedDay.month}-${selectedDay.day < 10 ? "0" + selectedDay.day : selectedDay.day}`,
        appointmentStartTime: fullSelectedDate + "T" + getTime(slotSlected.slotTime) + ":00Z",
        appointmentEndTime: fullSelectedDate + "T" + new moment(endTime, ["HH.mm"]).utc().format("HH:mm") + ":00Z",
        // reason: reason,
        // document: fileUrl ? fileUrl : "",
      };

      console.log("payload", payload);

      history.push(ROUTES_CONSTANTS.PATIENT_BILL_DETAIL_LAB, payload);

      // const res = await bookAppointment(payload);

      // if (res && res.status === 200) {
      //   setConfirmModal(true);
      // } else if (res.status == 402) {
      //   console.log("Coming here");
      //   // setUpgradeToggle(true);
      //   const payloadWithPic = {
      //     docDetail: doctorDetails,
      //     otherDetail: payload,
      //     // upgradeDetail : UpgradeToggle
      //   };
      //   // history.push(ROUTES_CONSTANTS.PATIENT_NEW_APPOINTMENT_DETAIL, payloadWithPic)
      //   setDocData(payloadWithPic);
      //   setPaymentOption(!PaymentOption);
      // }
      // history.push(ROUTES_CONSTANTS.PATIENT_APPOINTMENT_DETAIL);
    }
  };

  console.log("fileUrl", fileUrl);

  // const handleUploadFile = (e) => {
  //   let result = e.target.value;
  //   console.log(e.target.value);
  //   const file = e.target.files[0];
  //   const fsize = e.target.files[0].size;

  //   console.log("@@@@", fsize);
  //   if (fsize < 1024 * 1024 * 10) {
  //     setFIleUpload(result);

  //     console.log(file);
  //     let formData = new FormData();
  //     console.log("file changed");

  //     formData.append("file", file);
  //     medicalFileUpload(formData);
  //   } else {
  //     ToasterFunc("error", "Please upload a file smaller than 10 MB");
  //   }
  // };

  // const medicalFileUpload = async (image) => {
  //   const res = await commonUploadedFile(image);
  //   if (res && res.status == 200) {
  //     console.log("medical reportttttt", res.data.result);
  //     setFIleUpload(res && res.data && res.data.result && res.data.result.url);
  //   }
  // };

  // const handleBookmarked = async () => {
  //   const payload = {
  //     userId: doctorDetails?._id,
  //     favourite: !doctorDetails?.isFavourite,
  //   };
  //   console.log(payload);
  //   const res = await setFav(payload);
  //   if (res.status === 200) getDoctorDetails(DocId);
  // };

  return (
    <div className=" col-lg-8 col-xl-9 content_ipad_p ">
      {console.log("@@@@@@", selectedDay)}
      <div className="card">
        <div className="card-body appointments">
          <div className="row">
            <div className="col-md-12 col-lg-8">
              <div className="card">
                {/* <div className="appointment-list"> */}
                <div class="card-header">
                  <h4>Select Date & timing</h4>
                </div>
                {/* </div> */}
                <div className="card-body custom-calender">
                  <Calendar value={selectedDay} onChange={setSelectedDay} shouldHighlightWeekends className="fc fc-unthemed fc-ltr" minimumDate={utils().getToday()} />
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="time-slot">
                    <ul className="clearfix">
                      {console.log(moment().format("DD/MM/YYYY") == `${selectedDay.day < 10 ? "0" + selectedDay.day : selectedDay.day}/${selectedDay.month < 10 ? "0" + selectedDay.month : selectedDay.month}/${selectedDay.year}`)}
                      {slotList?.slots?.map((data) => {
                        return moment().format("DD/MM/YYYY") == `${selectedDay?.day < 10 ? "0" + selectedDay?.day : selectedDay?.day}/${selectedDay?.month < 10 ? "0" + selectedDay?.month : selectedDay?.month}/${selectedDay?.year}` ? (
                          <>
                            {!checkTime(convertUTCToLocal(data.slotTime)) && (
                              <li onClick={() => setSLotSelected(data)}>
                                <a
                                  className="timing"
                                  href
                                  style={{
                                    backgroundColor: slotSlected?.slotTime === data.slotTime ? " #be141b" : " #eeeeee",
                                    color: slotSlected?.slotTime === data.slotTime ? "white" : " #be141b",
                                  }}
                                >
                                  <span>{convertUTCToLocal(data.slotTime)}</span>
                                </a>
                              </li>
                            )}
                          </>
                        ) : (
                          <li onClick={() => setSLotSelected(data)}>
                            <a
                              className="timing"
                              href
                              style={{
                                backgroundColor: slotSlected?.slotTime === data.slotTime ? " #be141b" : " #eeeeee",
                                color: slotSlected?.slotTime === data.slotTime ? "white" : " #be141b",
                              }}
                            >
                              <span>{convertUTCToLocal(data.slotTime)}</span>
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="row mb-4">
            <div className="col-md-12">
              <label for="Reason">Reason</label>
              <textarea id="Reason" rows="3" maxLength={180} className="form-control" value={reason} onChange={(e) => setReason(e.target.value)}></textarea>
              {reasonErr ? <span className="validation_error">{reasonErr}</span> : null}
            </div>
          </div> */}
          {/* <div className="row">
            <div className="col-md-12">
              <div className="card ">
                <div className="card-body">
                  <div className="d-flex justify-content-center flex-column">
                    <span className="text-center">
                      <label for="camera" className="upload_area">
                        <input type="file" hidden accept=".pdf" id="camera" onChange={(e) => handleUploadFile(e)} />
                        <i className="fa fa-file-pdf fa-3x  c-pointer "></i>
                        <p>Click here to upload your documents</p>
                      </label>
                    </span>
                    <span className="text-center">{fileUrl ? <a href>PDF</a> : <span className="suggestion">Only PDF file with max size of 10MB.</span>}</span>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>

        <div className="submit-section proceed-btn text-center">
          <button className="btn btn-primary  w-25" onClick={handleNext}>
            Next
          </button>
        </div>
      </div>
      <ConfirmationModal ConfirmModal={ConfirmModal} setConfirmModal={setConfirmModal} />
    </div>
  );
}

export default BookLaboratory;
