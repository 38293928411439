import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../constants";
import { doctor } from "../../../redux/action";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import FilterModal from "../../PatientDashboard/FilterModal";
import moment from "moment";
import { DebounceInput } from "react-debounce-input";

function PreviousAppointment() {
  let history = useHistory();
  let dispatch = useDispatch();
  const [PreAppToggle, setPreAppToggle] = useState("");
  const [PreviousAppointmentsList, setPreviousgAppointmentsList] = useState([]);
  const [searchTerm, setsearchTerm] = useState("");

  const previousAppointments = async () => dispatch(doctor.docDashboardPreviousAppointmentsAction());

  useEffect(() => {
    previousAppointments();
  }, []);

  useEffect(() => {
    setPreAppToggle("PreVApp");
  }, []);

  const handleViewAppointment = (id) => {
    history.push(`${ROUTES_CONSTANTS.DOCTOR_VIEW_UPCOMING_APPOINTMENT}/${id}`, PreAppToggle);
  };

  const PreviousAppointmentsListDummy = useSelector((state) => state?.doctor?.doctorDashboardPreviousAppointment?.appointmentData);
  const AppointmentList =
    PreviousAppointmentsListDummy &&
    PreviousAppointmentsListDummy.length &&
    PreviousAppointmentsListDummy.map((item) => {
      item["newStatus"] = "completed";
      return item;
    });

  // console.log("Previous==>", AppointmentList);

  useEffect(() => {
    if (AppointmentList) {
      setPreviousgAppointmentsList(AppointmentList);
    }
  }, [PreviousAppointmentsListDummy]);

  const handleSearch = (e) => {
    let data = e.target.value;
    setsearchTerm(data);
  };

  useEffect(() => {
    let searchResult = PreviousAppointmentsList.filter(
      (item) =>
        item?.patientId?.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.patientId?.lastName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (searchTerm === "") {
      setPreviousgAppointmentsList(AppointmentList);
    } else {
      setPreviousgAppointmentsList(searchResult);
    }
  }, [searchTerm]);

  return (
    <div className="tab-pane active" id="pat-previous-appnt">
      <div className="input-social-media search_box">
        {/* <input type="text" className="form-control  mb-2" /> */}
        <DebounceInput
          minLength={1}
          debounceTimeout={500}
          aria-controls="DataTables_Table_0"
          className="form-control  mb-2"
          onChange={(e) => handleSearch(e)}
        />
        <span>
          <i className="fas fa-search appt-search-icon"></i>
        </span>
      </div>
      <Scrollbars className="appointment-height" autoHide>
        <div className="row">
          {PreviousAppointmentsList && PreviousAppointmentsList.length ? (
            PreviousAppointmentsList.map((item, index) => (
              <div className="col-sm-6 col-md-6 col-lg-3 pr-1" key={index}>
                <a>
                  <div className="card widget-profile pat-widget-profile">
                    <div className="card-body li-listing-profile">
                      <div onClick={() => handleViewAppointment(item._id)}>
                        <div className="profile-info-widget">
                          <div className="booking-doc-img">
                            <img
                              src={
                                item && item.patientId && item.patientId.profilePic ? item.patientId.profilePic : IMAGE_CONSTANT.PAT_PROFILE
                              }
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="profile-det-info text-center">
                          <h3>
                            {item && item.patientId.firstName} {item && item.patientId.lastName}
                          </h3>
                        </div>
                        <div className="patient-info profile-info-custom">
                          <ul>
                            <li>
                              Date : <span> {moment(item?.appointmentStartTime).format("MM/DD/YYYY")}</span>
                            </li>
                            <li>
                              Time :
                              <span>
                                {moment(item && item.appointmentStartTime).format("LT")} -{" "}
                                {moment(item && item.appointmentEndTime).format("LT")}{" "}
                              </span>
                              {/* <span>{`${
                                item && item.appointmentStartTime
                                  ? `${new Date(item.appointmentStartTime).toLocaleTimeString().split(":")[0]}:${new Date(item.appointmentStartTime).toLocaleTimeString().split(":")[1]} ${
                                      new Date(item.appointmentStartTime).toLocaleTimeString().split(" ")[1]
                                    }`
                                  : ""
                              } -
                    ${
                      item && item.appointmentEndTime
                        ? `${new Date(item.appointmentEndTime).toLocaleTimeString().split(":")[0]}:${new Date(item.appointmentEndTime).toLocaleTimeString().split(":")[1]} ${new Date(item.appointmentEndTime).toLocaleTimeString().split(" ")[1]}`
                        : ""
                    }`}</span> */}
                            </li>
                            <li>
                              Paid : <span>${item && item.amount}</span>
                            </li>
                          </ul>
                        </div>
                        <div className="completedAppoint">
                          <span>
                            <i className="fa fa-check-circle mr-1"></i>
                          </span>
                          Completed
                        </div>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
            ))
          ) : (
            <div className="col-md-12">
              <p className="text-center">There are currently no appointments available.</p>
            </div>
          )}
        </div>
      </Scrollbars>
    </div>
  );
}

export default PreviousAppointment;
