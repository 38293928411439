import PreviousAppointment from "./previousAppointment/PreviousAppointment"
import UpcomingAppointment from "./uppcomingAppointmment/UpcomingAppointment"
import { useState } from "react"

function Appoitments() {
  const [isUpcoming, setisUpcoming] = useState(true)
  const [isPrevious, setisPrevious] = useState(false)

  const handleUpcomming = () => {
    setisUpcoming(true)
    setisPrevious(false)
  }
  const handlePrevious = () => {
    setisPrevious(true)
    setisUpcoming(false)

  }
  
  return (
    <>
      {/* <!-- Page Content --> */}
      <div className="col-lg-8 col-xl-9 content_ipad_p">
        <div className="card">
          <div className="card-body pt-0">
            {/* <!-- Tab Menu --> */}
            <nav className="user-tabs mb-4">
              <ul className="nav nav-tabs nav-tabs-bottom nav-justified mobile-tabs">
                <li className="nav-item">
                  <a className={isUpcoming ? "nav-link active" : "nav-link"} onClick={handleUpcomming}>
                    Upcoming Appointment
                  </a>
                </li>
                <li className="nav-item">
                  <a className={isPrevious ? "nav-link active" : "nav-link"} onClick={handlePrevious}>
                    Previous Appointment
                  </a>
                </li>
              </ul>
            </nav>
            {/* <!-- /Tab Menu --> */}

            {/* <!-- Tab Content --> */}
            <div className="tab-content">
              {isUpcoming ? <UpcomingAppointment /> : isPrevious ? <PreviousAppointment /> : ''}
            </div>
          </div>
        </div>
      </div>
    </>
    // {/* <!-- /Page Content --> */}
  );
}

export default Appoitments;
