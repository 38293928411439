import React, { useEffect, useState } from "react";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../constants";
import DatePicker, { Calendar, utils } from "react-modern-calendar-datepicker";
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { bookingAction } from "../../../redux/action/bookingAction";
import ReactStars from "react-stars";
import { convertUTCToLocal, convertUTCToLocalForInput, getTime } from "../../authentication/sign-up-as-doctor/availability";
import ToasterFunc from "../../common/toaster/Toaster";
import { commonAction, doctor, patient } from "../../../redux/action";
import moment from "moment";
import ConfirmationModal from "./ConfirmationModal";
// import ConfirmationModal from "./ConfirmationModal";

function RescheduleAppointment() {
  let history = useHistory();
  const [ConfirmModal, setConfirmModal] = useState(false);
  const [SlotObject, setSlotObject] = useState({});
  const dispatch = useDispatch();
  const [Bookmarktoggle, setBookmarktoggle] = useState(false);

  const rescheduleAppointment = async (payload) => dispatch(bookingAction.rescheuduleAppointmentAction(payload));
  const getSlotsListForReschedule = async (date) => dispatch(bookingAction.getSlotsListForRescheduleAction(date));
  const slotListForReschedule = useSelector((state) => state?.booking?.slotListForReschedule);
  const setFav = async (payload) => dispatch(patient.setFavourite(payload));

  console.log("slotListForReschedule", slotListForReschedule);

  const { AppointmentId } = useParams();
  const [selectedDay, setSelectedDay] = useState(null);
  const [slotSlected, setSLotSelected] = useState(null);
  const [SlotVisible, setSlotVisible] = useState(false);

  useEffect(() => {
    console.log("@@@", AppointmentId);
    DoctorProfileInfo();
  }, []);
  useEffect(() => {
    if (slotListForReschedule && slotListForReschedule.slots) {
      const currentTime = convertTime12to24(new Date().toLocaleTimeString());
      slotListForReschedule.slots.length &&
        slotListForReschedule.slots.map((item) => {
          item["localSlotTime"] = convertUTCToLocalForInput(item.slotTime);
          if (selectedDay?.day === new Date().getDate() && currentTime > item.localSlotTime) {
            item["show"] = false;
          } else {
            item["show"] = true;
            if (!selectedDay?.day) {
              item["show"] = false;
            }
          }

          return item;
        });
      setSlotObject(slotListForReschedule);
    }
  }, [slotListForReschedule, selectedDay?.day]);

  //----------Format 12 to 24-----------------
  const convertTime12to24 = (time12h) => {
    const [time, modifier] = time12h.split(" ");
    let [hours, minutes] = time.split(":");
    if (hours === "12") {
      hours = "00";
    }
    if (modifier === "PM") {
      hours = parseInt(hours, 10) + 12;
    }
    console.log(`${hours}:${minutes}`);

    return `${hours}:${minutes}`;
  };

  useEffect(() => {
    if (selectedDay) callSlots();
  }, [selectedDay]);
  console.log("selectedDay", selectedDay);

  const callSlots = () => {
    getSlotsListForReschedule(selectedDay ? `${selectedDay.year}-${selectedDay.month}-${selectedDay.day}` : moment().format("YYYY-MM-DD"));
  };

  const DoctorDataa = useSelector((state) => state?.doctor?.doctorProdileInfo);

  console.log("DoctorDataaa", DoctorDataa);

  const DoctorProfileInfo = async () => dispatch(doctor.doctorProfileDetailsAction());

  const handleSet = async () => {
    if (!slotSlected) {
      ToasterFunc("error", "Please select slots.");
    }
    if (!!slotSlected) {
      let fullSelectedDate = `${selectedDay.year}-${selectedDay.month < 10 ? "0" + selectedDay.month : selectedDay.month}-${selectedDay.day < 10 ? "0" + selectedDay.day : selectedDay.day}`;
      console.log("fullSelectedDate", fullSelectedDate);
      let endTime = new moment.utc(slotSlected.slotTime, "HH:mm A").local();
      endTime.add(30, "minutes");
      const payload = {
        appointmentId: AppointmentId,
        slotTime: slotSlected.slotTime,
        dayId: slotListForReschedule._id,
        slotId: slotSlected._id,
        bookingDate: `${selectedDay.year}-${selectedDay.month < 10 ? "0" + selectedDay.month : selectedDay.month}-${selectedDay.day < 10 ? "0" + selectedDay.day : selectedDay.day}`,
        appointmentStartTime: fullSelectedDate + "T" + getTime(slotSlected.slotTime) + ":00Z",
        appointmentEndTime: fullSelectedDate + "T" + new moment(endTime, ["HH.mm"]).utc().format("HH:mm") + ":00Z",
      };

      console.log("payloadddddddddddd", payload);

      const res = await rescheduleAppointment(payload);

      if (res && res.status == 200) {
        setConfirmModal(true);
      }
    }
  };
  useEffect(() => {
    if (Bookmarktoggle) {
      if (DoctorDataa?.result[0]?.isFavourite) {
        ToasterFunc("success", "Added to favourite list");
      } else {
        ToasterFunc("success", "Removed from favourite list");
      }
    }
    console.log(DoctorDataa?.result[0]?.isFavourite);
  }, [DoctorDataa?.result[0]?.isFavourite]);

  const handleBookmarked = async () => {
    setBookmarktoggle(true)
    const payload = {
      userId: DoctorDataa?.result[0]?._id,
      favourite: !DoctorDataa?.result[0]?.isFavourite,
    };
    console.log(payload); 
    const res = await setFav(payload);
    if (res.status === 200) DoctorProfileInfo();
  };

  return (
    <div className=" col-lg-8 col-xl-9 content_ipad_p ">
      {console.log("@@@@@@", selectedDay)}
      <div className="card">
        <div className="card-body appointments">
          <div className="appointment-list">
            <div className="profile-info-widget">
              <a className="booking-doc-img">
                <img src={DoctorDataa?.result[0]?.profilePic || IMAGE_CONSTANT.PLACEHOLDER} alt="" />
              </a>
              <div className="profile-det-info">
                <h3>
                  <a>{DoctorDataa?.result[0]?.fullName}</a>
                </h3>
                <div className="patient-details">
                  <h5>{`$${DoctorDataa?.result[0]?.serviceCharge} Consultation Fee`}</h5>
                  <h5>{`${DoctorDataa?.result[0]?.experience_in_years} Years ${DoctorDataa?.result[0]?.experience_in_months} months of Experience`}</h5>
                  <a href data-toggle="modal" data-target="#speciality-popup" data-dismiss="modal">
                    <h6>{DoctorDataa?.result[0]?.serviceProviderType[0]?.primary_specialty}</h6>
                  </a>
                  <div className=" rating ">
                    <ReactStars edit={false} count={5} value={DoctorDataa?.result[0]?.rating} size={16} color2={"#ffd700"} />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="fav-wapper">
            <div onClick={handleBookmarked} className={`${DoctorDataa?.result[0]?.isFavourite ? "favourite" :"unfavourite"} sm-fav-bx`}>
              <i className="fa fa-bookmark" ></i> <span>{!DoctorDataa?.result[0]?.isFavourite?"Mark as favourite" : "Unmark as favourite"}</span>
            </div>
          </div> */}
          </div>
          <div className="row">
            <div className="col-md-12 col-lg-8">
              <div className="card">
                <div className="card-body custom-calender">
                  <Calendar value={selectedDay} onChange={setSelectedDay} shouldHighlightWeekends className="fc fc-unthemed fc-ltr" minimumDate={utils().getToday()} />
                </div>
              </div>
            </div>
          </div>
          <div className={slotListForReschedule && slotListForReschedule.slots ? "row" : "row hide_Slotlist"}>
            <div className="col-md-12">
              <div className="card">
                <div className="card-body">
                  <div className="time-slot">
                    <ul className="clearfix">
                      {console.log("slot console on ui ===>179", slotListForReschedule)}
                      {SlotObject && SlotObject.slots
                        ? SlotObject.slots.map((data) => {
                            return (
                              // SlotVisible?
                              <React.Fragment key={data?._id}>
                                {data?.show && (
                                  <li onClick={() => setSLotSelected(data)}>
                                    <a
                                      className="timing"
                                      href
                                      style={{
                                        backgroundColor: slotSlected?.slotTime === data.slotTime ? "red" : "gray",
                                        color: slotSlected?.slotTime === data.slotTime ? "white" : "black",
                                      }}
                                    >
                                      <span>{convertUTCToLocal(data.slotTime)}</span>
                                    </a>
                                  </li>
                                )}
                              </React.Fragment>

                              // : null
                              /*: null*/
                              /*: null*/
                              /*: null*/
                              /*: null*/
                              /*: null*/
                              /*: null*/
                              /*: null*/
                             /*: null*/);
                          })
                        : ""}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="submit-section proceed-btn text-center">
          <button className="btn btn-primary  w-25" onClick={handleSet}>
            Set
          </button>
        </div>
      </div>
      <ConfirmationModal ConfirmModal={ConfirmModal} setConfirmModal={setConfirmModal} />
    </div>
  );
}

export default RescheduleAppointment;
