import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal } from "reactstrap";
import Payment from "../paymentModal/Payment";
import ToasterFunc from "../../common/toaster/Toaster";
import stripeService from "../../../services/stripeService/stripeService";

function DocSubcription({ DocSubcriptionModal, setDocSubcriptionModal, Image }) {
  const subscriptionList = useSelector((state) => state?.authentication?.doctorSubscriptionList);

  const [PaymentModal, setPaymentModal] = useState(false);

  const [isOpenDocSide, setisOpenDocSide] = useState(false);
  const [SubscriptionType, setSubscriptionType] = useState("");
  const [SubscriptionErr, setSubscriptionErr] = useState("");
  const handleClose = () => {
    setDocSubcriptionModal(false);
  };

  useEffect(() => {
    if (SubscriptionType !== "") {
      setSubscriptionErr("");
    }
  }, [SubscriptionType]);
  const handlePay = () => {
    console.log(SubscriptionType);
    if (!SubscriptionType) {
      setSubscriptionErr("Please choose atleast one");
    } else {
      setSubscriptionErr("");
      if (DocSubcriptionModal) {
        setisOpenDocSide(true);
      }
      setPaymentModal(!PaymentModal);
      setDocSubcriptionModal(false);
    }
  };
  // const handlePay =async () => {
  //   console.log("******",SubscriptionType);
  //   if (!SubscriptionType) {
  //     setSubscriptionErr("Please choose atleast one");
  //   } else {
  //     setSubscriptionErr("");

  //     let paymentData = {
  //       subscriptionId : SubscriptionType
  //     }
  //     // let url = "https://checkout.stripe.com/c/pay/cs_test_a11YYufWQzNY63zpQ6QSNRQhkUpVph4WRmzW0zWJO2znZKdVujZ0N0S22u#fidkdWxOYHwnPyd1blpxYHZxWjA0SDdPUW5JbmFMck1wMmx9N2BLZjFEfGRUNWhqTmJ%2FM2F8bUA2SDRySkFdUV81T1BSV0YxcWJcTUJcYW5rSzN3dzBLPUE0TzRKTTxzNFBjPWZEX1NKSkxpNTVjRjN8VHE0YicpJ2N3amhWYHdzYHcnP3F3cGApJ2lkfGpwcVF8dWAnPyd2bGtiaWBabHFgaCcpJ2BrZGdpYFVpZGZgbWppYWB3dic%2FcXdwYHgl"
  //     // window.open(url, "_blanck");


  //     // create checkout session return url
  //      await stripeService.createSessionCheckout(paymentData)
  //     .then((data)=>{
  //       console.log("data------",data);
  //       if (data?.url) {
  //         window.open(data?.url, "_self");
  //       } else {
  //         ToasterFunc("info", data?.message);
  //       }
  //     }).catch((err)=>{
  //       ToasterFunc("error", err);
  //     })
     
  //     // if (DocSubcriptionModal) {
  //     //   setisOpenDocSide(true);
  //     // }
  //     // setPaymentModal(!PaymentModal);
  //     // setDocSubcriptionModal(false);
  //   }
  // };

  const handleSubscription = (e) => {
    let value = e.target.id;
    setSubscriptionType(value);
  };
  return (
    <div>
      <Modal isOpen={DocSubcriptionModal} className="modal-dialog-centered modal-dialog-scrollable subscription upgrade-subsrcription">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="modal-title text-center" id="staticBackdropLabel">
              Select Subscription
            </h4>
            <button type="button" className="close " aria-label="Close" data-dismiss="modal" onClick={handleClose}>
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          {SubscriptionErr ? <div className="validation_error">{SubscriptionErr}</div> : null}
          <div className="modal-body pt-0">
            {subscriptionList?.map((item) => {
              return (
                <div className="account-content">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="card subscription-packages">
                        <div className="card-body">
                          <div className="d-flex justify-content-between mb-2">
                            <div className="form-check">
                              <input type="radio" className="form-check-input" name="Subscription1" id={item?._id || ""} checked={item?._id === SubscriptionType} onClick={(e) => handleSubscription(e)} />
                              <label className="form-check-label" for={item?._id || ""}>
                                {item?.name || ""}
                              </label>
                            </div>
                            <div>
                              <span className="color-black">
                                <b>{`$${item.price}/month`}</b>
                              </span>
                            </div>
                          </div>
                          <div className="color-black">
                            Description
                          </div>
                          <div className="card mb-0 p-2 mt-1 package-description">
                            <div>
                              <label className="color-grey">{`Number of Secondary Speciality:${item?.numberOfSecondrySpecialty}`}</label>
                            </div>
                            <div>
                              <label className="color-grey">{`Minutes of Audio Calls:${item?.minutesOfAudioCalls}`}</label>
                            </div>
                            <div>
                              <label className="color-grey">{`Minutes of Video Calls:${item?.minutesOfVideoCalls}`}</label>
                            </div>
                            <div>
                              <label className="color-grey">{`Number of Leads:${item?.numberOfLeads}`}</label>
                            </div>
                            <div>
                              <label className="color-grey">{`Number of Messages:${item?.numberOfMessages}`}</label>
                            </div>
                            <div>
                              <label className="color-grey">{`Subscription Validity:${item?.days_duration}`}</label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
           
          </div>
          <div className="modal-footer">
            <button className="btn btn-primary btn-block " type="submit" onClick={handlePay}>
              Pay
            </button>
          </div>
        </div>
      </Modal>
      <Payment DocImage={Image} PaymentModal={PaymentModal} setPaymentModal={setPaymentModal} isOpenDocSide={isOpenDocSide} SubscriptionId={SubscriptionType} />
    </div>
  );
}

export default DocSubcription;
