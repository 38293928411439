import React, { useEffect, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { patient } from "../../../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import ToasterFunc from "../../../../common/toaster/Toaster";
import { useHistory } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../../../../constants";
import moment from "moment";
import dayjs from "dayjs";

function AddCard({ CardDetail, setCardDetail, CardListPatientwithPagination }) {
  let history = useHistory();
  let dispatch = useDispatch();
  const [CardErr, setCardErr] = useState("");
  const [CardnumErr, setCardnumErr] = useState("");
  const [ExpiryMonthErr, setExpiryMonthErr] = useState("");
  const [CardType, setCardType] = useState({
    credit: true,
    debit: false,
  });
  const [bothCardDetails, setCardDetails] = useState({
    credit: {},
    debit: {},
  });
  const handleClose = () => {
    setCardDetail(false);
  };

  React.useEffect(() => {
    document.addEventListener("keydown", function (event) {
      if (event.target.name === "CardNum"){
        event.target.value = event.target.value.replace(/[^\dA-Z]/g, '').replace(/(.{4})/g, '$1 ').trim();
      }
      if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
        var form = event.target.form;
        var index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + 1].focus();
        event.preventDefault();
      }
    });
  }, []);

  const addCardInPatSide = async (payload) =>
    dispatch(patient.addCardInPatsettingsAction(payload));

  const formik = useFormik({
    initialValues: {
      fullname: "",
      CardNum: "",
      cardexpiry: "",
    },
    validationSchema: Yup.object({
      fullname: Yup.string()
        .required("*Required.")
        .matches(/[A-Za-z]/, "Only Characters are allowed")
        .trim(),
      CardNum: Yup.number()
        .required("*Required.")
        .typeError("*Please enter only digits"),
      cardexpiry: Yup.string()
        .required("*Required.")
        .matches(/^[0-9/]*$/, "*Please enter only digits"),
    }),
  });

  const handleExpiryDate = () => {
    var inp = document.querySelector("#input");
    console.log("inp", inp);
    inp.onkeydown = function (e) {
      const key = e.keyCode || e.charCode;

      if (key !== 8 && key !== 46) {
        if (inp.value.length == 2) {
          inp.value = inp.value + "/";
        }
      }

      if ((key == 8 || key == 46) && inp.value.length === 4) {
        inp.value = inp.value.slice(0, 3);
      }
    };
  };

  let currentYear = dayjs().year().toString();
  let modifiedcurrentYear = `${currentYear.split("")[2]}${
    currentYear.split("")[3]
  }`;

  useEffect(() => {
    if (CardType.credit === true || CardType.debit === true) {
      setCardErr("");
    }
    if (formik.values.CardNum.replaceAll(" " , "").length === 16) {
      setCardnumErr("");
    }
    if (formik.values.cardexpiry.split("/")[0] <= 12) {
      setExpiryMonthErr("");
    }
    if (formik.values.cardexpiry.split("/")[1] > modifiedcurrentYear) {
      setExpiryMonthErr("");
    }
    if (formik.values.cardexpiry.length === 5) {
      setExpiryMonthErr("");
    }
  }, [CardType, formik.values.CardNum, formik.values.cardexpiry]);

  console.log(formik.values);

  const handleConfirm = async () => {
    if (CardType.credit === false && CardType.debit === false) {
      setCardErr("*Please Choose any one");
    }
    if (CardType.credit === true || CardType.debit === true) {
      setCardErr("");
    }
    if (
      formik.values.cardexpiry !== "" &&
      formik.values.cardexpiry.length < 5
    ) {
      setExpiryMonthErr("*Invalid Expiry Date");
    }
    if (formik.values.CardNum !== "" && formik.values.CardNum.replaceAll(" " , "").length < 16) {
      setCardnumErr("*Invalid Card Number");
    }
    if (formik.values.cardexpiry.split("/")[0] > 12) {
      setExpiryMonthErr("*Invalid Expiry Month");
    }

    if (formik.values.cardexpiry.split("/")[1] < modifiedcurrentYear) {
      setExpiryMonthErr("*Invalid Expiry Year");
    }

    const paymentPayload = {
      cardType: CardType.credit ? "CREDIT" : "DEBIT",
      cardHolderName: formik.values.fullname,
      cardNumber: formik.values.CardNum.replaceAll(" " , ""),
      expMonth:
        formik.values.cardexpiry && formik.values.cardexpiry.split("/")[0],
      expYear:
        formik.values.cardexpiry && formik.values.cardexpiry.split("/")[1],
    };

    console.log(paymentPayload.exp_month, paymentPayload.exp_year);

    console.log(CardType.credit, CardType.debit);
    if (CardType.credit !== false || CardType.debit !== false) {
      if (
        formik.values.CardNum !== "" &&
        formik.values.CardNum.replaceAll(" " , "").length === 16 &&
        formik.values.fullname !== "" &&
        formik.values.cardexpiry !== "" &&
        formik.values.cardexpiry.length <= 5 &&
        formik.values.cardexpiry.split("/")[0] <= 12 &&
        formik.values.cardexpiry.split("/")[1] > modifiedcurrentYear
      ) {
        let res = null;
        res = await addCardInPatSide(paymentPayload);
        if (res && res.status == 200) {
          ToasterFunc("success", res.data.responseMessage);
          console.log("paymenttttttttt", res);
          formik.values.CardNum = "";
          formik.values.fullname = "";
          formik.values.cardexpiry = "";
          setCardDetail(false);
          CardListPatientwithPagination();
        }
      }
    }
  };

  const handleCard = (e) => {
    let name = e.target.value;
    let value = e.target.checked;
    if (name === "Credit") {
      setCardType({
        credit: value,
      });
      setCardDetails({
        ...bothCardDetails,
        debit: {
          CardNum: formik.values.CardNum.replaceAll(" " , ""),
          fullname: formik.values.fullname,
          cardexpiry: formik.values.cardexpiry,
          cvv: formik.values.cvv,
        },
      });
      formik.setFieldValue("CardNum", bothCardDetails?.credit?.CardNum.replaceAll(" " , "") || "");

      formik.setFieldValue("fullname", bothCardDetails?.credit?.fullname || "");

      formik.setFieldValue(
        "cardexpiry",
        bothCardDetails?.credit?.cardexpiry || ""
      );

      formik.setFieldValue("cvv", bothCardDetails?.credit?.cvv || "");
    } else if (name === "Debit") {
      setCardType({
        debit: value,
      });
      setCardDetails({
        ...bothCardDetails,
        credit: {
          CardNum: formik.values.CardNum.replaceAll(" " , ""),
          fullname: formik.values.fullname,
          cardexpiry: formik.values.cardexpiry,
          cvv: formik.values.cvv,
        },
      });
      formik.setFieldValue(
        "CardNum",
        bothCardDetails?.debit?.CardNum?.replaceAll(" " , "") ? bothCardDetails?.debit?.CardNum?.replaceAll(" " , "") : ""
      );
      formik.setFieldValue(
        "fullname",
        bothCardDetails?.debit?.fullname ? bothCardDetails?.debit?.fullname : ""
      );
      formik.setFieldValue(
        "cardexpiry",
        bothCardDetails?.debit?.cardexpiry
          ? bothCardDetails?.debit?.cardexpiry
          : ""
      );
      formik.setFieldValue(
        "cvv",
        bothCardDetails?.debit?.cvv ? bothCardDetails?.debit?.cvv : ""
      );
    }
  };

  return (
    <Modal isOpen={CardDetail} className="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <button
          type="button"
          class="close custom-model-close"
          onClick={handleClose}
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="modal-body text-center p-4">
          <div class="card mb-0">
            <ModalBody>
              <div class="card-body">
                <h4 class="card-title mb-4">Add Card Detail</h4>
                <div class="row mb-2">
                  <div class="col-md-12 text-left">
                    <div class="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="Card"
                        id="inlineRadio1"
                        value="Credit"
                        defaultChecked
                        onClick={(e) => handleCard(e)}
                      />
                      <label class="form-check-label" for="inlineRadio1">
                        Credit
                      </label>
                    </div>
                    <div class="form-check form-check-inline">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="Card"
                        id="inlineRadio1"
                        value="Debit"
                        onClick={(e) => handleCard(e)}
                      />
                      <label class="form-check-label" for="inlineRadio2">
                        Debit
                      </label>
                    </div>
                  </div>
                </div>
                {CardErr ? (
                  <div className="validation_error card">{CardErr}</div>
                ) : null}
                <form onSubmit={formik.handleSubmit}>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group text-left">
                        <label for="Cardholder name">Full Name on Card</label>
                        <input
                          type="text"
                          class="form-control"
                          name="fullname"
                          maxLength="16"
                          {...formik.getFieldProps("fullname")}
                        />
                        {formik.touched.fullname &&
                        formik.errors.fullname &&
                        !formik.values.fullname ? (
                          <span className="validation_error">
                            {formik.errors.fullname}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="form-group text-left">
                        <label for="Cardholder name">Card Number</label>
                        <input
                          type="text"
                          class="form-control"
                          name="CardNum"
                          onPaste={(e)=>{
                            e.preventDefault()
                            return false;
                          }}
                          maxLength="19"
                          {...formik.getFieldProps("CardNum")}
                        />
                        {formik.touched.CardNum &&
                        formik.errors.CardNum &&
                        !formik.values.CardNum ? (
                          <span className="validation_error">
                            {formik.errors.CardNum}
                          </span>
                        ) : null}
                        {formik.errors.CardNum ? (
                          ""
                        ) : CardnumErr ? (
                          <span className="validation_error">{CardnumErr}</span>
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div class="row">
                    <div class="col-md-6">
                      <div class="form-group text-left">
                        <label for="Cardholder name">Card Expiry</label>
                        <input
                          type="text"
                          id="input"
                          className="form-control"
                          placeholder="MM/YY"
                          name="cardexpiry"
                          maxLength="5"
                          {...formik.getFieldProps("cardexpiry")}
                          onFocus={handleExpiryDate}
                        />

                        {formik.touched.cardexpiry &&
                        formik.errors.cardexpiry &&
                        !formik.values.cardexpiry ? (
                          <span className="validation_error">
                            {formik.errors.cardexpiry}
                          </span>
                        ) : null}
                        {formik.errors.cardexpiry ? (
                          ""
                        ) : ExpiryMonthErr ? (
                          <span className="validation_error">
                            {ExpiryMonthErr}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div class="submit-section btn-p">
                    <button
                      type="submit"
                      className="btn btn-primary mt-2"
                      onClick={(e) => handleConfirm(e)}
                    >
                      Done
                    </button>
                  </div>
                </form>
              </div>
            </ModalBody>
          </div>
        </div>
      </div>
    </Modal>
    //     </div>
    // </div>
  );
}

export default AddCard;
