import { ForgotPassword, Login, LoginWithNumber } from "../authentication";
import { useEffect, useState } from "react";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../constants";
import { SignUp } from "../authentication/signup";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import axios from "axios";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import ReactStars from "react-stars";
import MultipleSpeciality from "../PatientDashboard/findDoctorsNearBy/MultipleSpeciality";
import { saveToSessionStorage } from "../../utils";

function Home() {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });
  let history = useHistory();
  const getSessionStorageValue = (key) => {
    const stored = sessionStorage.getItem(key);
    console.log(stored);
    return JSON.parse(stored);
  };

  useEffect(() => {
    if (getSessionStorageValue("userType") == "1") {
      console.log("Coming here");
      history.push(ROUTES_CONSTANTS.DASHBOARD);
    }
    if (getSessionStorageValue("userType") == "2") {
      console.log("Coming here");
      history.push(ROUTES_CONSTANTS.PATIENT_DASHBOARD);
    }
  }, []);

  const [address, setAddress] = useState("");
  const [Lat, setLat] = useState("");
  const [Lng, setLng] = useState("");
  const [SearchValue, setSearchValue] = useState("");
  const [TopDoctors, setTopDoctors] = useState([]);
  const [SignInModal, setSignInModal] = useState(false);
  const [ViewMultiSpe, setViewMultiSpe] = useState(false);
  const [ServiceProviderType, setServiceProviderType] = useState([]);
  const [primarySpecialityList, setprimarySpecialityList] = useState([]);
  const [FindToggle, setFindToggle] = useState(false);
  const [SignUpModal, setSignUpModal] = useState(false);
  const [LoginWithNumModal, setLoginWithNumModal] = useState(false);
  const [ForgetPwdModal, setForgetPwdModal] = useState(false);

  console.log(ServiceProviderType);
  const spec = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 2,
    arrows: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const helth = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleSearch = () => {
    let SearchArr = [];
    SearchArr.push = [
      {
        SearchLat: Lat,
        SearchLng: Lng,
        DoctorName: SearchValue,
      },
    ];
    history.push(ROUTES_CONSTANTS.HOME_SEARCH, SearchArr);
  };

  const handleFindData = (id) => {
    setFindToggle(true);
    let SearchArr = [];
    SearchArr.push = [
      {
        findtoggle: FindToggle,
        SearchLat: Lat,
        SearchLng: Lng,
        DoctorName: SearchValue,
        serviceProviderId: id,
      },
    ];
    history.push(ROUTES_CONSTANTS.HOME_SEARCH, SearchArr);
  };

  console.log(Lat, Lng);

  useEffect(() => {
    getLocation();
    getServiceProviderType();
    getPrimarySpeciality();
  }, []);
  const error = (err) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          // if ((sessionStorage.getItem("lat") && sessionStorage.getItem("lng")) || (Lat && Lng)) {
          callApi(position);
          getTopDoctors(position);
          // }
          console.log("position", position);
          console.log("Latitude is :", position.coords.latitude);
          console.log("Longitude is :", position.coords.longitude);

          setLat(position.coords.latitude);
          setLng(position.coords.longitude);

          var latlng = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
          // This is making the Geocode request
          var geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ 'latLng': latlng },  (results, status) =>{
              if (status !== window.google.maps.GeocoderStatus.OK) {
                  alert(status);
              }
              // This is checking to see if the Geoeode Status is OK before proceeding
              if (status == window.google.maps.GeocoderStatus.OK) {
                  console.log(results);
                  let address = (results[0].formatted_address);
                  console.log("address==>>>",address);
                  setAddress(address);

              }
          });

          console.log("accuracy", position.coords.accuracy);
          // let response = null;
          // if (Lat && Lng) {
          //   response = await axios.get(
          //     ` https://maps.googleapis.com/maps/api/geocode/json?latlng=${Lat},${Lng}&accuracy=1&radious=10&sensor=true&key=AIzaSyATz3lpNEJKLYbTVFpb-Yn30q15Htd4qp4
          //   `
          //   );
          // } else {
          // const response = await axios.get(
          //   ` https://maps.googleapis.com/maps/api/geocode/json?latlng=${Lat ? Lat : position.coords.latitude},${
          //     Lng ? Lng : position.coords.longitude
          //   }&accuracy=1&radious=10&sensor=true&key=AIzaSyATz3lpNEJKLYbTVFpb-Yn30q15Htd4qp4
          //   `
          // );
          // // }

          // console.log(
          //   "@@@@@@@",
          //   response?.data?.results[0].address_components
          //     .map((data) => data.long_name)
          //     .slice(response?.data?.results[0].address_components.length - 4, response?.data?.results[0].address_components.length)
          // );

          // setAddress(
          //   response?.data?.results[0].address_components
          //     .map((data) => data.long_name)
          //     .slice(response?.data?.results[0].address_components.length - 4, response?.data?.results[0].address_components.length)
          // );
          // https://maps.googleapis.com/maps/api/geocode/json?latlng=28.536288450553798,77.38301406357039&sensor=true&key=AIzaSyATz3lpNEJKLYbTVFpb-Yn30q15Htd4qp4

          // var lat = position.coords.latitude;
          // var lang = position.coords.longitude;
          // var url = "http://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lang + "&sensor=true";
        },
        error,
        {
          enableHighAccuracy: true,
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const getTopDoctors = async (position) => {
    const payload = {
      lat: position.coords.latitude,
      long: position.coords.longitude,
    };
    await axios.post(`${process.env.REACT_APP_GYM_DOCTOR_API_BASE_URL}patient/topHealthCoaches`, payload).then((res) => {
      // dispatch(commonAction.showLoader());
      console.log("typeresult", res);
      if (res?.status === 200) {
        setTopDoctors(res?.data?.userData);
      }
    });
  };

  console.log("TopDoctors", TopDoctors);

  const callApi = (position) => {
    let payload = {};
    // if (sessionStorage.getItem("lat") && sessionStorage.getItem("lng")) {
    //   payload = {
    //     lat: sessionStorage.getItem("lat"),
    //     long: sessionStorage.getItem("lng"),
    //   };
    //   homeData(payload);
    //   homeDataLaboratory(payload);
    // } else
    if (Lat && Lng) {
      payload = {
        lat: Lat,
        long: Lng,
      };
    } else {
      payload = {
        lat: position.coords.latitude,
        long: position.coords.longitude,
      };
    }
    // homeData(payload);
    // homeDataLaboratory(payload);
  };

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
    setAddress(e.target.value);
  };

  const getPrimarySpeciality = async () => {
    await axios.get(`${process.env.REACT_APP_GYM_DOCTOR_API_BASE_URL}doctor/primarySpecialtyList`).then((res) => {
      // dispatch(commonAction.showLoader());
      console.log("doctor/primarySpecialtyList===", res);
      if (res?.status === 200) {
        setprimarySpecialityList(res?.data?.documentList);
      }
    });
  };

  useEffect(() => {
    console.log("primarySpecialityList", primarySpecialityList);
  }, [primarySpecialityList]);

  const getServiceProviderType = async () => {
    await axios.get(`${process.env.REACT_APP_GYM_DOCTOR_API_BASE_URL}patient/serviceProviderTypeList`).then((res) => {
      // dispatch(commonAction.showLoader());
      console.log("typeresult", res);
      if (res?.status === 200) {
        setServiceProviderType(res?.data?.documentList);
      }
    });
  };

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();
      console.log("description", description);
      setAddress(description);

      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          console.log("📍 Coordinates: ", { lat, lng });
          setLat(lat);
          setLng(lng);
        })
        .catch((error) => {
          console.log("😱 Error: ", error);
        });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  const handlebook = () => {
    setSignInModal(!SignInModal);
    saveToSessionStorage("isLoggedIn", "true");
    saveToSessionStorage("userType", "2");
  };
  const handleSignInToggle = () => {
    setSignInModal(!SignInModal);
    setSignUpModal(false);
  };
  const handleSignUpToggle = () => {
    setSignUpModal(!SignUpModal);
    setSignInModal(false);
  };

  const handleLoginWithMobiToggle = () => {
    setLoginWithNumModal(!LoginWithNumModal);
    setSignInModal(false);
  };
  const handleForgetPasswordToggle = () => {
    console.log("CLICKED");
    setLoginWithNumModal(false);
    setForgetPwdModal(!ForgetPwdModal);
    setSignInModal(false);
  };

  return (
    <div className="col-md-12">
      <section class="section section-search">
        <div class="container-fluid">
          <div class="banner-wrapper">
            <div class="banner-header text-center">
              <h1>Your Health Is In Your Hands</h1>
              <p>Your Ultimate Health Partner Awaits!</p>
            </div>
            <div class="search-box">
              <form action="#">
                <div class="form-group search-location address-suggesstion">
                  <input
                    type="text"
                    className="form-control pl-5"
                    name="address"
                    value={value ? value : address}
                    maxLength="60"
                    onChange={handleInput}
                    disabled={!ready}
                    placeholder="Search Location"
                    autoComplete="off"
                  />
                  {status === "OK" && <ul>{renderSuggestions()}</ul>}
                  <span class="form-text">Based on your Location</span>
                </div>
                <div class="form-group search-info">
                  <input type="text" class="form-control" placeholder="Search Doctors" onChange={(e) => setSearchValue(e.target.value)} />
                  {/* <span class="form-text">Ex : Dental or Sugar Check up etc</span> */}
                </div>
                <button type="submit" class="btn btn-primary search-btn mt-0" onClick={(e) => handleSearch(e)}>
                  <i class="fas fa-search"></i> <span>Search</span>
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>

      <section className="section home-tile-section">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-11 m-auto">
              <div className="section-header text-center">
                <h2>Unleash the power of Gym Doctor and discover your true health potential!</h2>
              </div>
              <div className="row">
                {ServiceProviderType?.length ? (
                  ServiceProviderType.map((item, ind) => (
                    <div className="col-md-6 col-sm-6 col-lg-4 col-xl-3 mb-3" key={ind}>
                      <div className="card text-center doctor-book-card">
                        <figure className="find_suit">
                          <img src={item.image} alt={item?.primary_specialty} className="img-fluid" />
                        </figure>
                        <div className="doctor-book-card-content tile-card-content-1">
                          <div>
                            <h3 className="card-title mb-0">Find {item?.primary_specialty}</h3>
                            <a
                              className="btn book-btn1 px-3 py-2 mt-3"
                              tabindex="0"
                              onClick={(e) => {
                                handleFindData(item._id);
                              }}
                            >
                              Search Now
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="col-md-12">
                    <p className="text-center">There are currently no doctors in your area.</p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section-specialities">
        <div className="container-fluid">
          <div className="section-header text-center">
            <h2> Specialities</h2>
            <p className="sub-title">
               Experience the Pinnacle of Personalized and Accessible Concierge Medical, Health, andFitness Care, Tailored Exclusively to Elevate Your Well-being!
            </p>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-9">
              <div className="specialities-slider slider">
                <Slider {...spec} className="doctor-slider">
                  {primarySpecialityList?.length
                    ? primarySpecialityList.map(({ image, primary_specialty, _id }) => (
                        <div className="speicality-item text-center" key={_id}>
                          <div className="speicality-img">
                            <div className="spec-img">
                              <img src={image} className="img-fluid" alt="Speciality" />
                            </div>
                            <span>
                              <i className="fa fa-circle" aria-hidden="true"></i>
                            </span>
                          </div>
                          <p>{primary_specialty}</p>
                        </div>
                      ))
                    : ""}
                  {/* <div className="speicality-item text-center">
                    <div className="speicality-img">
                      <img src={IMAGE_CONSTANT.SPECIALITIES_ONE} className="img-fluid" alt="Speciality" />
                      <span>
                        <i className="fa fa-circle" aria-hidden="true"></i>
                      </span>
                    </div>
                    <p>Urology</p>
                  </div>
                  <div className="speicality-item text-center">
                    <div className="speicality-img">
                      <img src={IMAGE_CONSTANT.SPECIALITIES_TWO} className="img-fluid" alt="Speciality" />
                      <span>
                        <i className="fa fa-circle" aria-hidden="true"></i>
                      </span>
                    </div>
                    <p>Neurology</p>
                  </div>
                  <div className="speicality-item text-center">
                    <div className="speicality-img">
                      <img src={IMAGE_CONSTANT.SPECIALITIES_THREE} className="img-fluid" alt="Speciality" />
                      <span>
                        <i className="fa fa-circle" aria-hidden="true"></i>
                      </span>
                    </div>
                    <p>Orthopedic</p>
                  </div>
                  <div className="speicality-item text-center">
                    <div className="speicality-img">
                      <img src={IMAGE_CONSTANT.SPECIALITIES_FOUR} className="img-fluid" alt="Speciality" />
                      <span>
                        <i className="fa fa-circle" aria-hidden="true"></i>
                      </span>
                    </div>
                    <p>Cardiologist</p>
                  </div>
                  <div className="speicality-item text-center">
                    <div className="speicality-img">
                      <img src={IMAGE_CONSTANT.SPECIALITIES_FIVE} className="img-fluid" alt="Speciality" />
                      <span>
                        <i className="fa fa-circle" aria-hidden="true"></i>
                      </span>
                    </div>
                    <p>Dentist</p>
                  </div> */}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section section-doctor">
        <div className="container-fluid">
          <div className="row">
            <div className="col-lg-4">
              <div className="section-header ">
                <h2>See Our top Practitioners</h2>
              </div>
              <div className="about-content">
                <p>
                  GymDoctor Introduces the Dynamic "Huddle Approach" - Where Our Experts Collaborate Seamlessly to Unveil Unparalleled Solutions Catered Precisely to Your Medical and Fitness Needs!
                </p>
                {/* <a href="javascript:;">Read More..</a> */}
              </div>
            </div>
            <div className="col-lg-8">
              <div className="doctor-slider slider">
                <Slider {...helth}>
                  {TopDoctors?.length ? (
                    TopDoctors.map((item) => (
                      <div className="card widget-profile pat-widget-profile">
                        <div className="card-body li-listing-profile">
                          <div className="profile-info-widget">
                            <a href className="booking-doc-img">
                              <img src={item?.profilePic} className="img-fluid" alt="" />
                            </a>
                          </div>
                          <div className="profile-det-info text-center">
                            <h3>Dr. {item?.fullName}</h3>
                          </div>
                          <div className="patient-info color-grey">
                            <div className="color-grey">
                              <b>${item?.serviceCharge} Consultation Fee</b>
                            </div>
                            <div className="color-gray">
                              {" "}
                              {`${item?.experience_in_years} Years ${item?.experience_in_months} Months`} Of Experience
                            </div>
                            <div className="color-gray">
                              {/* {item && item.specialties && item.specialties.length === 1 ? (
                                item.specialties[0].secondry_specialty
                              ) : (
                                <span
                                  onClick={() => {
                                    setViewMultiSpe(!ViewMultiSpe);
                                    setPrimarySpeId(item?.serviceProviderType[0]?._id);
                                  }}
                                >
                                  {" "} */}
                              {item?.serviceProviderType[0]?.primary_specialty}
                              {/* </span> */}
                              {/* )} */}
                            </div>
                            <div className=" rating ">
                              <ReactStars
                                edit={false}
                                count={5}
                                value={item?.rating}
                                size={16}
                                style={{ selfAlign: "center" }}
                                color2={"#ffd700"}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12 mb-2 mt-3">
                              <a className="btn book-btn" onClick={handlebook}>
                                Book Appointment
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="col-md-12">
                      <p className="text-center">There are currently no doctors in your area.</p>
                    </div>
                  )}
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <Login
        SignInModal={signInModal}
        setSignInModal={setSignInModal}
        handleSignUpToggle={handleSignUpToggle}
        handleLoginWithMobiToggle={handleLoginWithMobiToggle}
        handleForgetPasswordToggle={handleForgetPasswordToggle}
      />
      <SignUp
        SignUpModal={SignUpModal}
        setSignUpModal={handleSignUpToggle}
        handleSignInToggle={handleSignInToggle}
      />
      <ForgotPassword
        ForgetPwdModal={ForgetPwdModal}
        setForgetPwdModal={setForgetPwdModal}
        setSignInModal={setSignInModal}
      />
      <LoginWithNumber
        LoginWithNumModal={LoginWithNumModal}
        setLoginWithNumModal={setLoginWithNumModal}
        handleLoginWithMobiToggle={handleLoginWithMobiToggle}
        handleForgetPasswordToggle={handleForgetPasswordToggle}
      /> */}
      <Login
        setSignInModal={setSignInModal}
        SignInModal={SignInModal}
        handleSignUpToggle={handleSignUpToggle}
        handleForgetPasswordToggle={handleForgetPasswordToggle}
      />

      <SignUp
        SignUpModal={SignUpModal}
        setSignUpModal={handleSignUpToggle}
        SignInModal={SignInModal}
        handleSignInToggle={handleSignInToggle}
      />

      <ForgotPassword ForgetPwdModal={ForgetPwdModal} setForgetPwdModal={setForgetPwdModal} setSignInModal={setSignInModal} />

      <LoginWithNumber
        LoginWithNumModal={LoginWithNumModal}
        setLoginWithNumModal={setLoginWithNumModal}
        handleLoginWithMobiToggle={handleLoginWithMobiToggle}
        handleForgetPasswordToggle={handleForgetPasswordToggle}
        setSignInModal={setSignInModal}
      />

      <MultipleSpeciality ViewMultiSpe={ViewMultiSpe} setViewMultiSpe={setViewMultiSpe} />
    </div>
  );
}

export default Home;
