import React, { useState, useEffect ,useContext} from "react";
import AboutViewAppointment from "./AboutViewAppointment";
import MedicalViewAppointment from "./MedicalViewAppointment";
import { useDispatch, useSelector } from "react-redux";
import { doctor ,commonAction} from "../../../redux/action";
import { useParams, useLocation } from "react-router-dom";
import AppointmentsDetails from "./appointments-details/AppointmentsDetails";
import Prescription from "./prescription/Prescription";
import { SocketContext } from '../../..';
import MedicalRecords from "./medical-records/MedicalRecords";
import Billing from "./billing/Billing";

function ViewAppointment() {
  const [Patid, setPatId] = useState("");
  let { DocId } = useParams();
  let dispatch = useDispatch();
  let location = useLocation();
  const [roomId, setRoomId] = useState('');
  const socket = useContext(SocketContext);
    console.log("socket==",socket);
  useEffect(() => {
    docViewAppointmentInfo(DocId);
  }, [DocId]);

  // useEffect(() => {
  //   if(Patid){
  //     createRoomFun()
  //   }
  // }, [Patid]);

  console.log("location", location);

  const docViewAppointmentInfo = async (payload) => dispatch(doctor.docViewAppointmentAction(payload));
  const UpcomingAppointsList = useSelector((state) => state?.doctor?.doctorViewAppointmentData?.appointmentDetails);

  const createRoomApi = async (payload) => dispatch(commonAction.createRoomApi(payload, "doctor"));


  useEffect(() => {
    const Patid = UpcomingAppointsList && UpcomingAppointsList.patientId && UpcomingAppointsList.patientId._id;
    setPatId(Patid);
  }, [UpcomingAppointsList]);

  const [ViewApt, setViewApt] = useState({
    isAptDetail: true,
    isPrescription: false,
    isMedicalRecords: false,
    isBilling: false,
  });

  const handleApptDetail = () => {
    setViewApt({
      isAptDetail: true,
    });
  };

  const handlePrescription = () => {
    setViewApt({
      isPrescription: true,
    });
  };
  const handleMedicalRecords = () => {
    setViewApt({
      isMedicalRecords: true,
    });
  };
  const handleBilling = () => {
    setViewApt({
      isBilling: true,
    });
  };

  const createRoomFun  = async ()=>{
        const payload  = {
              "receiverId" : Patid
        } 
       const res  =  await createRoomApi(payload);
       if (res && res?.data?.roomID){
        let roomId  = res?.data?.roomID ;
        setRoomId(roomId);
        console.log("roomId", payload,roomId )
        // connectRoom(roomId)
        getSocketConnection()
    }
    console.log("Create room response", res )

  }

    const connectRoom =  (RoomID)=>{
    console.log('ReceiverDetails?.chatId' ,RoomID);
     if (RoomID){
     console.log("RoomID && socket",RoomID , socket);
      socket.emit('join_room' ,RoomID);
    }
 }

  const getSocketConnection = () => {
    // socket.connect();
    // client-side
    socket.on("connect", () => {
      const payload = {
        senderId: DocId,
      };
      console.log("socket connected"); 
      console.log('connectUser emitted doctor payload' , payload); // x8WIv7-mJelg7on_ALbx
      socket.emit("connectUser", payload);
      if(roomId){

        connectRoom(roomId)
      }
    });

  };


  return (
    <div class=" col-lg-8 col-xl-9 content_ipad_p">
      <div class="card">
        {location?.state === true ? (
          <div class="card-header">
            <h4 class="card-title">Appointment Details </h4>
          </div>
        ) : (
          <nav class="user-tabs">
            <ul class="nav nav-tabs nav-tabs-bottom nav-justified">
              <li class="nav-item">
                <a class={`nav-link ${ViewApt.isAptDetail ? "active" : ""}`} href onClick={handleApptDetail}>
                  Appointments Details
                </a>
              </li>
              <li class="nav-item">
                <a class={`nav-link ${ViewApt.isPrescription ? "active" : ""}`} href onClick={handlePrescription}>
                  Prescription
                </a>
              </li>
              <li class="nav-item">
                <a class={`nav-link ${ViewApt.isMedicalRecords ? "active" : ""}`} href onClick={handleMedicalRecords}>
                  Medical Records
                </a>
              </li>
              <li class="nav-item">
                <a class={`nav-link ${ViewApt.isBilling ? "active" : ""}`} href onClick={handleBilling}>
                  Billing
                </a>
              </li>
            </ul>
          </nav>
        )}
        <div class="card-body ">
          {/* <!-- Tab Content --> */}
          <div class="tab-content pt-0">
            {ViewApt.isAptDetail ? (
              <AppointmentsDetails UpcomingAppointsList={UpcomingAppointsList} roomId = {roomId} PreviousAppToggole={location?.state} />
            ) : ViewApt.isPrescription ? (
              <Prescription Patid={Patid} />
            ) : ViewApt.isMedicalRecords ? (
              <MedicalRecords Patid={Patid} />
            ) : ViewApt.isBilling ? (
              <Billing Patid={Patid} />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ViewAppointment;
