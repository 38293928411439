import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import "./index.css";
import App from "./components/app/App";
import configureStore from "./redux/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Spinner } from "./components/common/Loader";
// import socket from "./socket/socket";
import io  from "socket.io-client";


const store = configureStore();
export const SocketContext  = React.createContext();
const socket  = io.connect(`${process.env.REACT_APP_GYM_DOCTOR_BASE_URL}`) ; 
// const socket  = io.connect(`http://localhost:4000`) ; 

console.log('socket===============>>>>>>',socket);

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <ToastContainer />
      <Spinner />
        <SocketContext.Provider value={socket}>
          <App />
        </SocketContext.Provider>
    </Router>
  </Provider>,
  document.getElementById("root")
);
