import { IMAGE_CONSTANT } from "../../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { patient } from "../../../../redux/action";
import { useEffect, useState } from "react";
import defaultImg from "../../../../assets/img/default-img.png";
import ReactStars from "react-stars";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import AcceptAndRejectRequest from "./AcceptAndRejectRequest";

function Requests() {
  let history = useHistory();
  let dispatch = useDispatch();
  const [AcceptModal, setAcceptModal] = useState(false);
  const [AccToggle, setAccToggle] = useState(false);
  const [RejToggle, setRejToggle] = useState(false);
  const [ApptId, setApptId] = useState("");

  const patientRescheduledList = useSelector((state) => state?.patient?.patientRescheduledAppointment?.appointmentData);

  console.log("patientRescheduledList", patientRescheduledList);

  useEffect(() => {
    patientRescheduleRequestList();
  }, []);

  const patientRescheduleRequestList = async () => dispatch(patient.patientRescheduleRequestListInWebAction());

  const handleAcceptAndReject = (AppId, data) => {
    setAcceptModal(!AcceptModal);
    setApptId(AppId);
    if (data === "accept") {
      setAccToggle(true);
      setRejToggle(false);
    } else if (data === "reject") {
      setRejToggle(true);
      setAccToggle(false);
    }
  };

  return (
    <div className=" col-lg-8 col-xl-9 content_ipad_p">
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Rescheduled Request</h4>
        </div>
        <div className="card-body">
          <div className="row">
            <div className="col-md-12">
              <section className="section mb-2 custom-card">
                <div className="row">
                  {patientRescheduledList?.length ? (
                    patientRescheduledList.map((item) =>
                      item ? (
                        <div className="col-sm-6 col-md-6 col-lg-4">
                          <div className="card widget-profile pat-widget-profile">
                            <div className="card-body p-3">
                              <div className="pro-widget-content">
                                <div className="profile-info-widget">
                                  <a href="#" className="booking-doc-img">
                                    <img src={item && item.serviceProviderId && item.serviceProviderId.profilePic ? item.serviceProviderId.profilePic : defaultImg} alt="" />
                                  </a>
                                  <div className="profile-det-info">
                                    <h3>Dr. {item && item.serviceProviderId && item.serviceProviderId.fullName ? item.serviceProviderId.fullName : ""}</h3>
                                  </div>
                                </div>
                              </div>
                              <div className="patient-info color-grey">
                                <div className="color-grey">
                                  <b>${item && item.amount ? item.amount : "--"} Consultation Fee</b>
                                </div>
                                <div className="color-gray">
                                  {" "}
                                  {item && item.serviceProviderId && item.serviceProviderId.experience_in_years ? `${item.serviceProviderId.experience_in_years} Years  ` : ""}
                                  {item && item.serviceProviderId && item.serviceProviderId.experience_in_months ? `${item.serviceProviderId.experience_in_months} Months` : ""} Of Experience
                                </div>

                                <div className="color-gray">
                                  {item && item.serviceProviderId && item.serviceProviderId.primary_specialtyId && item.serviceProviderId.primary_specialtyId.primary_specialty ? item.serviceProviderId.primary_specialtyId.primary_specialty : "--"}
                                </div>
                                {/* <div className=" rating ">
                                <i className="fas fa-star filled"></i>
                                <i className="fas fa-star filled"></i>
                                <i className="fas fa-star filled"></i>
                                <i className="fas fa-star filled"></i>
                                <i className="fas fa-star filled"></i>
                              </div> */}
                                <ReactStars
                                  count={item && item.serviceProviderId && item.serviceProviderId.rating ? item.serviceProviderId.rating : ""}
                                  value={item && item.serviceProviderId && item.serviceProviderId.rating ? item.serviceProviderId.rating : ""}
                                  size={16}
                                  color2={"#ffd700"}
                                  className="rating"
                                />
                              </div>
                              <div className="request-profile-border">
                                <h5>Appointment History</h5>
                                <label for="" className="mb-0 color-grey">
                                  <span>
                                    <b>Date: </b>
                                    {item && item.oldAppointmentId && item.oldAppointmentId.appointmentStartTime ? new Date(item.oldAppointmentId.appointmentStartTime).toLocaleDateString() : "--"}
                                  </span>
                                </label>
                                <div className="color-grey">
                                  <span>
                                    <b>Time: </b>
                                    {`${
                                      item && item.oldAppointmentId && item.oldAppointmentId.appointmentStartTime
                                        ? `${new Date(item.oldAppointmentId.appointmentStartTime).toLocaleTimeString().split(":")[0]}:${new Date(item.oldAppointmentId.appointmentStartTime).toLocaleTimeString().split(":")[1]} ${
                                            new Date(item.oldAppointmentId.appointmentStartTime).toLocaleTimeString().split(" ")[1]
                                          }`
                                        : ""
                                    } -
                                ${
                                  item && item.oldAppointmentId && item.oldAppointmentId.appointmentEndTime
                                    ? `${new Date(item.oldAppointmentId.appointmentEndTime).toLocaleTimeString().split(":")[0]}:${new Date(item.oldAppointmentId.appointmentEndTime).toLocaleTimeString().split(":")[1]} ${
                                        new Date(item.oldAppointmentId.appointmentEndTime).toLocaleTimeString().split(" ")[1]
                                      }`
                                    : ""
                                }`}
                                  </span>
                                </div>
                              </div>
                              <div>
                                <label for="" className="color-grey font-size-14px">
                                  Your appointment has been rescheduled on {item && item.appointmentStartTime ? new Date(item.appointmentStartTime).toLocaleDateString() : "--"} at &nbsp;
                                  {new Date(item.appointmentStartTime).toLocaleTimeString().split(":")[0]}:{new Date(item.appointmentStartTime).toLocaleTimeString().split(":")[1]}
                                  {new Date(item.appointmentStartTime).toLocaleTimeString().split(" ")[1]}
                                </label>
                              </div>
                              <div>
                                <label for="" className="color-grey font-size-14px">
                                  Would You Like to accept the invitation of rescheduled appointment
                                </label>
                              </div>
                              <div className="d-flex justify-content-center mt-2">
                                <button type="button" className="btn btn-primary mr-1 btn-new" onClick={() => handleAcceptAndReject(item._id, "accept")}>
                                  <span>
                                    <i className="fa fa-close"></i>
                                  </span>
                                  Accept
                                </button>
                                <button type="button" className="btn btn-primary btn-new" onClick={() => handleAcceptAndReject(item._id, "reject")}>
                                  <span>
                                    <i className="fa fa-close"></i>
                                  </span>
                                  Reject
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        <div className="col-md-12">
                          <p className="text-center">Data Not Found</p>
                        </div>
                      )
                    )
                  ) : (
                    <div className="col-md-12">
                      <p className="text-center">Data Not Found</p>
                    </div>
                  )}
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
      <AcceptAndRejectRequest AcceptModal={AcceptModal} setAcceptModal={setAcceptModal} ApptId={ApptId} patientRescheduleRequestList={() => patientRescheduleRequestList()} AccToggle={AccToggle} RejToggle={RejToggle} />
    </div>
  );
}

export default Requests;
