import { useState  , useEffect } from "react";
import ChangePassword from "./DocChangePassword/ChangePassword";
import InviteFriends from "./DocInviteFriends/InviteFriends";
import PaymentDetail from "./DocPaymentDetail/PaymentDetail";
import PersonalDetail from "./DocPersonalDetail/PersonalDetail";
import ProfessionalDetail from "./DocProfessionalDetail/ProfessionalDetail";
function ProfileSettings() {
  const [Profile, setProfile] = useState({
    isChngPwd: true,
    isPersonal: false,
    isProfessional: false,
    isPayment: false,
    isInvite: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [])
  
  const handleChngPwd = () => {
    setProfile({
      // ...Profile,
      isChngPwd: true,
    });
  };
  const handlePersonalDetail = () => {
    setProfile({
      // ...Profile,
      isPersonal: true,
    });
  };
  const handleProfessionalDetail = () => {
    setProfile({
      // ...Profile,
      isProfessional: true,
    });
  };
  const handlePaymentDetail = () => {
    setProfile({
      // ...Profile,
      isPayment: true,
    });
  };
  const handleInvite = () => {
    setProfile({
      // ...Profile,
      isInvite: true,
    });
  };
  return (
    <div class=" col-lg-8 col-xl-9 content_ipad_p ">
      <div class="card">
        <div class="card-body pt-0">
          {/* <!-- setting sidebar tab menu  --> */}

          <nav class="user-tabs mb-4">
            <ul class="nav nav-tabs nav-tabs-bottom nav-justified text-nowrap pat-profile-setting-nav">
              <li class="nav-item">
                <a
                  href
                  class={Profile.isChngPwd ? "nav-link active" : "nav-link"}
                  value="ChngPwd"
                  onClick={handleChngPwd}
                >
                  Change Password
                </a>
              </li>
              <li class="nav-item">
                <a
                  href
                  class={Profile.isPersonal ? "nav-link active" : "nav-link"}
                  onClick={handlePersonalDetail}
                >
                  Personal Details
                </a>
              </li>
              <li class="nav-item">
                <a
                  href
                  class={
                    Profile.isProfessional ? "nav-link active" : "nav-link"
                  }
                  onClick={handleProfessionalDetail}
                >
                  Professional Details
                </a>
              </li>
              <li class="nav-item">
                <a
                  href
                  class={Profile.isPayment ? "nav-link active" : "nav-link"}
                  onClick={handlePaymentDetail}
                >
                  Payment Info
                </a>
              </li>
              {/* <!-- <li class="nav-item">
                        <a class="nav-link" href="#Help" data-toggle="tab">Help</a>
                    </li> --> */}
              <li class="nav-item">
                <a
                  href
                  class={Profile.isInvite ? "nav-link active" : "nav-link"}
                  onClick={handleInvite}
                >
                  Invite Friends
                </a>
              </li>
            </ul>
          </nav>

          {/* <!-- setting sidebar tab menu  --> */}

          {/* <!-- setting tab content menu  --> */}
          <div class="tab-content pt-0">
            {Profile.isChngPwd ? (
              <ChangePassword />
            ) : Profile.isPersonal ? (
              <PersonalDetail />
            ) : Profile.isProfessional ? (
              <ProfessionalDetail />
            ) : Profile.isPayment ? (
              <PaymentDetail />
            ) : Profile.isInvite ? (
              <InviteFriends />
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ProfileSettings;
