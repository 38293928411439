import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal } from "reactstrap";
import { patient } from "../../../redux/action";

function MultipleSpeciality({ ViewMultiSpe, setViewMultiSpe, PrimarySpeId, MultiSpeData }) {
  let dispatch = useDispatch();
  const handleClose = () => {
    setViewMultiSpe(false);
  };
  console.log("PrimarySpeId", PrimarySpeId);

  useEffect(() => {
    if (PrimarySpeId) {
      patientSecondarySpecialityData(PrimarySpeId);
    }
  }, [PrimarySpeId]);

  const patientSecondarySpecialityList = useSelector((state) => state?.patient?.patientSecondarySpecialityList?.documentList);
  console.log("patientSecondarySpecialityList", patientSecondarySpecialityList);

  const patientSecondarySpecialityData = async (payloadId) => dispatch(patient.patientSecondarySpecialityListAction(payloadId));
  return (
    // <div className="modal fade" id="speciality-popup" data-backdrop="static" aria-hidden="true" role="dialog">
    // <div className="modal-dialog modal-dialog-centered " role="document">
    <Modal isOpen={ViewMultiSpe} className="modal-dialog modal-dialog-centered ">
      <div className="modal-content p-3">
        <div className="text-right">
          <button type="button" className="dr_Modalclose payment-method-modal" onClick={handleClose}>
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="card mb-0">
            <div className="card-header">
              <h4>Secondary Speciality</h4>
            </div>
            <div className="card-body  text-center">{MultiSpeData ? MultiSpeData.map((item) => <h5 className="border-speciality">{item.secondry_specialty}</h5>) : <div> No data</div>}</div>
          </div>
        </div>
      </div>
    </Modal>
    //     </div>
    // </div>
  );
}

export default MultipleSpeciality;
