import React, { useEffect, useState } from "react";
import PaginationComponent from "react-reactstrap-pagination";
import { useDispatch, useSelector } from "react-redux";
import { commonAction, doctor } from "../../../redux/action";
import AddNewCard from "./AddNewCard";
import DeleteCard from "./DeleteCard";

function PaymentDetail() {
  let dispatch = useDispatch();
  const [addNewCardModal, setAddNewCardModal] = useState(false);
  const [editCardModal, setEditCardModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [editCardId, setEditCardId] = useState();
  const [selectPage, setselectPage] = useState(1);
  const [DataLimit, setDataLimit] = useState(4);
  const [Cvv, setCvv] = useState();

  const [cardData, setCardData] = useState({
    cardNumber: "",
    cardId: "",
  });
  const [CardnumErr, setCardnumErr] = useState("");
  const [CardErr, setCardErr] = useState("");

  const setPrimaryCard = async (cardId) => dispatch(commonAction.setPrimaryCardAction(cardId));

  useEffect(() => {
    if (DataLimit) {
      callCardListApi();
    }
  }, [DataLimit, selectPage]);

  const callCardListApi = () => {
    cardListData(DataLimit, selectPage);
  };
  const cardList = useSelector((state) => state?.doctor?.doctorCardList);

  const cardListData = async (DataLimit, selectPage) => dispatch(doctor.docCardListAction(DataLimit, selectPage));

  console.log("cardList", cardList);

  const handleAddNewCard = () => {
    setAddNewCardModal(true);
    console.log("working...");
  };

  const editCard = (id) => {
    console.log(id);
    setEditCardModal(true);
    setEditCardId(id);
  };
  const deleteCard = (id, cardNumber) => {
    console.log(id);
    setDeleteModal(true);
    setCardData({ cardNumber: cardNumber, cardId: id });
  };
  const handleSelected = (data) => {
    console.log("clicked page", data);
    setselectPage(data);
  };
  const handlePageLimit = (e) => {
    console.log("page data", e.target.value);
    setDataLimit(e.target.value);
  };

  const handleChange = (e, index) => {
    setCvv(index);
  };

  return (
    <div role="tabpanel" id="Payment" className="tab-pane fade show active">
      <div className="card doc_card_list">
        <div className="card-header ">
          <div className="row align-button">
            <div className="col-md-9">
              <h4 className="mt-2">Payment Details</h4>
            </div>
            <div className="col-md-3">
              <div className="text-right">
                <a onClick={handleAddNewCard} className="add-new-btn add-new-card mb-0">
                  Add Card Details
                </a>
              </div>
            </div>
          </div>
        </div>
        <div className="card-body">
          <div className="row">
            {cardList && cardList.documentList ? (
              cardList.documentList.map((item, i) => (
                <div className="col-md-6" key={i}>
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <label>
                            <b>Card Holder Name</b>
                            <br />
                            <span className="default-content-clr">{item.cardHolderName}</span>
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="radio"
                            name="chooseCard"
                            checked={item?.isPrimary ? true : false}
                            onChange={async (e) => {
                              await setPrimaryCard(item?._id);
                              handleChange(e, i);
                              callCardListApi();
                            }}
                            className="form-check-input"
                          />
                        </div>
                      </div>
                      <div>
                        <label for="">
                          <b>Card Number</b>
                          <br />
                          <span className="default-content-clr">{item.cardNumber.replace(/.(?=.{4})/g, "X")}</span>
                        </label>
                      </div>
                      <div className="d-flex justify-content-between">
                        <div>
                          <span>
                            <b>Expiry Date : </b>
                            <span className="default-content-clr">
                              {item.expMonth}/{item.expYear}
                            </span>
                          </span>
                        </div>
                        <div className="card-action">
                          <a onClick={() => editCard(item._id)}>
                            <span>
                              <i className="far fa-edit c-pointer"></i>
                            </span>
                          </a>
                          <a onClick={() => deleteCard(item._id, item.cardNumber)}>
                            <span>
                              <i className="fas fa-trash-alt c-pointer"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-center">Data not found!</p>
            )}
          </div>
          {cardList?.totalList > 4 ? (
            <div class="row">
              <div className="col-sm-3 col-md-3">
                <div className="dataTables_length" id="DataTables_Table_0_length">
                  <label>
                    Show{" "}
                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" onChange={(e) => handlePageLimit(e)}>
                      <option value="4">4</option>
                      <option value="8">8</option>
                      <option value="10">10</option>
                    </select>{" "}
                    entries
                  </label>
                </div>
              </div>
              <div class="col-sm-5 col-md-5">
                <div class="dataTables_info showing_pagination" id="DataTables_Table_0_info" role="status" aria-live="polite">
                  Showing {(selectPage - 1) * DataLimit + 1} to {(selectPage - 1) * DataLimit + (cardList && cardList.documentList && cardList.documentList.length)} of {cardList && cardList.totalList} entries
                </div>
              </div>
              <div class="col-sm-4 col-md-4">
                <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                  <PaginationComponent totalItems={cardList && cardList.totalList} pageSize={DataLimit} maxPaginationNumbers={Math.ceil(cardList && cardList.totalList / DataLimit)} defaultActivePage={1} onSelect={(e) => handleSelected(e)} />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <AddNewCard addNewCardModal={addNewCardModal} setAddNewCardModal={setAddNewCardModal} editCardModal={editCardModal} setEditCardModal={setEditCardModal} editCardId={editCardId} />
      <DeleteCard deleteModal={deleteModal} setDeleteModal={setDeleteModal} cardData={cardData} />
    </div>
  );
}

export default PaymentDetail;
