import bookingServices from "../../../services/booking/bookingServices";
import { commonAction } from "../commonAction";
import bookingActionType from "./bookingActionType";

export const getDoctorDetailsAction = (DocId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await bookingServices.getDoctorDetails(DocId);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: bookingActionType.VIEW_DOCTOR_DETAILS,
          doctorDetails: response?.data?.result?.[0],
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const getDoctorDetailsForShowingAction = (DocId) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await bookingServices.getDoctorDetails(DocId);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: bookingActionType.VIEW_DOCTOR_DETAILS_FOR_SHOWING,
          doctorDetailsforShowing: response?.data,
        });
      }
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const getSlots = (id, date) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await bookingServices.getSlotsList(id, date);
      dispatch(commonAction.hideLoader());
      if (response.status == 200) {
        dispatch({
          type: bookingActionType.GET_SLOT,
          slotList: response?.data?.slotData,
        });
      } else {
        dispatch({
          type: bookingActionType.GET_SLOT,
          slotList: {},
        });
      }
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      dispatch({
        type: bookingActionType.GET_SLOT,
        slotList: {},
      });
      reject(error.response);
    }
  });
};

export const bookAppointmentAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await bookingServices.bookAppoitment(payload);

      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const getSlotsListForRescheduleAction = (date) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await bookingServices.getSlotsListForReschedule(date);

      dispatch(commonAction.hideLoader());
      resolve(response);
      if (response.status == 200) {
        dispatch({
          type: bookingActionType.GET_SLOT_FOR_RESCHEDULE,
          slotListForReschedule: response?.data?.slotData,
        });
      } else {
        dispatch({
          type: bookingActionType.GET_SLOT_FOR_RESCHEDULE,
          slotListForReschedule: {},
        });
      }
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      dispatch({
        type: bookingActionType.GET_SLOT_FOR_RESCHEDULE,
        slotListForReschedule: {},
      });
      reject(error.response);
    }
  });
};

export const rescheuduleAppointmentAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await bookingServices.rescheduleAppoitment(payload);

      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const cancelAppointmentAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    const payloadData = {
      appointmentId: payload.appointmentId,
      reason: payload.reason,
    };
    try {
      const response = await bookingServices.cancelAppointment(payloadData);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const rescheuduleAppointmentPatientAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await bookingServices.rescheduleAppoitmentPatient(payload);

      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const cancelAppointmentActionPatient = (payload) => (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(commonAction.showLoader());
    const payloadData = {
      appointmentId: payload.appointmentId,
      reason: payload.reason,
    };
    try {
      const response = bookingServices.cancelAppointmentPatient(payloadData);
      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const getLabSlotsListAction = (id, date) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await bookingServices.getLabSlotsList(id, date);
      dispatch(commonAction.hideLoader());
      if (response.status == 200) {
        dispatch({
          type: bookingActionType.GET_SLOT_FOR_LABORATORY,
          slotListForLaboratory: response?.data?.slotData,
        });
      } else {
        dispatch({
          type: bookingActionType.GET_SLOT_FOR_LABORATORY,
          slotList: {},
        });
      }
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      dispatch({
        type: bookingActionType.GET_SLOT_FOR_LABORATORY,
        slotListForLaboratory: {},
      });
      reject(error.response);
    }
  });
};

export const bookLabAppointmentAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await bookingServices.bookLabAppoitment(payload);

      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const rescheduleLabAppointmentAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await bookingServices.rescheduleLabAppoitment(payload);

      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export const cancelLabAppointmentAction = (payload) => (dispatch) => {
  return new Promise(async (resolve, reject) => {
    dispatch(commonAction.showLoader());
    try {
      const response = await bookingServices.cancelLabAppointment(payload);

      dispatch(commonAction.hideLoader());
      resolve(response);
    } catch (error) {
      dispatch(commonAction.hideLoader());
      reject(error.response);
    }
  });
};

export default {
  getDoctorDetailsAction,
  getDoctorDetailsForShowingAction,
  getSlots,
  bookAppointmentAction,
  getSlotsListForRescheduleAction,
  rescheuduleAppointmentAction,
  cancelAppointmentAction,
  rescheuduleAppointmentPatientAction,
  cancelAppointmentActionPatient,
  getLabSlotsListAction,
  bookLabAppointmentAction,
  rescheduleLabAppointmentAction,
  cancelLabAppointmentAction,
};
