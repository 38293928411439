import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../constants";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { patient, patientAction } from "../../redux/action";
import { useEffect, useState } from "react";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { httpService } from "../../services";
import axios from "axios";
import ReactStars from "react-stars";
import MultipleSpeciality from "./findDoctorsNearBy/MultipleSpeciality";
import usePlacesAutocomplete, { getGeocode, getLatLng } from "use-places-autocomplete";
import useOnclickOutside from "react-cool-onclickoutside";
import ToasterFunc from "../common/toaster/Toaster";

function PatientDashboard() {
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
    },
    debounce: 300,
  });
  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });
  let dispatch = useDispatch();
  let history = useHistory();
  const [address, setAddress] = useState("");
  const [DocData, setDocData] = useState([]);
  const [ViewMultiSpe, setViewMultiSpe] = useState(false);
  const [PrimarySpeId, setPrimarySpeId] = useState("");
  const [PrimarySpeByAdmin, setPrimarySpeByAdmin] = useState([]);
  const [DocSpe, setDocSpe] = useState([]);
  const [LaboratoryList, setLaboratoryList] = useState([]);
  const [docList, setdocList] = useState([]);
  const [Lat, setLat] = useState("");
  const [Lng, setLng] = useState("");
  const [MultiSpeData, setMultiSpeData] = useState([]);

  const ServiceProviderTypeInPatient = async () => dispatch(patient.serviceProviderTypeInPatientAction());

  const homeInfoPatient = async (payload) => dispatch(patient.homeDataAction(payload));

  const homeInfoLaboratory = async (payload) => dispatch(patient.homeDataLaboratoryPatientAction(payload));

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 1,
    loop: false,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    ServiceProviderTypeInPatient();
    getLocation();
    // homeData()
  }, []);

  const ServiceProviderTypeData = useSelector((state) => state?.patient?.serviceProviderListInPatient);

  const homeDataOfPAtient = useSelector((state) => state?.patient?.homeDataOfPatient);

  const homeDataLaboratoryPatient = useSelector((state) => state);

  console.log("homeDataLaboratoryPatient", homeDataLaboratoryPatient);

  useEffect(() => {
    console.log("homeDataOfPAtient", homeDataOfPAtient);
    if (homeDataOfPAtient && homeDataOfPAtient.homeData && homeDataOfPAtient?.homeData?.length >= 0) {
      setdocList(homeDataOfPAtient.homeData);
      homeDataOfPAtient.homeData.map((item) => {
        if (item && item.docs && item.docs.length) {
          console.log("item docs", item.docs);
          setDocData(item && item.docs);
        }
      });
    }
  }, [homeDataOfPAtient]);

  console.log("docList", docList);

  useEffect(() => {
    let arr = [];
    if (DocData) {
      DocData.map((data) => {
        arr.push(data && data.serviceProviderType[0].primary_specialty);
      });
    }
    setDocSpe(arr);
  }, [DocData]);

  console.log("DocSpe", DocSpe);

  // useEffect(() => {
  //   let arr=[]
  //   if(ServiceProviderTypeData &&
  //     ServiceProviderTypeData.length){
  //       ServiceProviderTypeData.forEach((item)=>{
  //         arr.push(
  //           item.primary_specialty
  //         )
  //       })

  //     }
  //     console.log("arr" ,arr)
  //     setPrimarySpeByAdmin(arr)

  // }, [ServiceProviderTypeData])

  console.log("PrimarySpeByAdmin", ServiceProviderTypeData, PrimarySpeByAdmin);

  console.log("DocData", DocData);

  const handleNavigation = (item) => {
    console.log(item);
    let arr = [];
    arr.push = {
      PrimarySpeId: item.primary_specialty,
      manualAddress: address,
      lat: Lat,
      lng: Lng,
    };
    history.push(`${ROUTES_CONSTANTS.FIND_PHYSICIANS_NEAR}/${item._id}`, arr);
  };

  const handleDocNavigation = (item) => {
    history.push(`${ROUTES_CONSTANTS.PATIENT_VIEW_DOCTOR_DETAILS}/${item?._id}`);
  };

  const error = (err) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  };
  function getCurrentReverseGeocodingData() {
    if (navigator.geolocation){
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          var latlng = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
          // This is making the Geocode request
          var geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ 'latLng': latlng },  (results, status) =>{
              if (status !== window.google.maps.GeocoderStatus.OK) {
                  alert(status);
              }
              // This is checking to see if the Geoeode Status is OK before proceeding
              if (status == window.google.maps.GeocoderStatus.OK) {
                  let address = (results[0].formatted_address);
                  sessionStorage.removeItem("ManualAddress")
                  console.log("address==>>>",address);
                  setAddress(address);
                  setValue(address);
              }
          });
        })
    }else{
      ToasterFunc('error' , "please enable your location")
    }
   
    
}
  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          if ((sessionStorage.getItem("lat") && sessionStorage.getItem("lng")) || (Lat && Lng)) {
            callApi(position);
          }
           console.log("position", position);
          // console.log("Latitude is :", position.coords.latitude);
          // console.log("Longitude is :", position.coords.longitude);
          if (sessionStorage.getItem("ManualAddress")) {
            setAddress(sessionStorage.getItem("ManualAddress"));
          } else {
           
          var latlng = new window.google.maps.LatLng(position.coords.latitude, position.coords.longitude);
          // This is making the Geocode request
          var geocoder = new window.google.maps.Geocoder();
          geocoder.geocode({ 'latLng': latlng },  (results, status) =>{
              if (status !== window.google.maps.GeocoderStatus.OK) {
                  alert(status);
              }
              // This is checking to see if the Geoeode Status is OK before proceeding
              if (status == window.google.maps.GeocoderStatus.OK) {
                  console.log(results);
                  let address = (results[0].formatted_address);
                  console.log("address==>>>",address);
                  setAddress(address);

              }
          });
          // setAddress(
          //   response?.data?.results[0].address_components
          //     .map((data) => data.long_name)
          //     .slice(response?.data?.results[0].address_components.length - 4, response?.data?.results[0].address_components.length)
          // );
        }
          // console.log("accuracy", position.coords.accuracy);
          // let response = null;
          // if (Lat && Lng) {
          //   response = await axios.get(
          //     ` https://maps.googleapis.com/maps/api/geocode/json?latlng=${Lat},${Lng}&accuracy=1&radious=10&sensor=true&key=AIzaSyATz3lpNEJKLYbTVFpb-Yn30q15Htd4qp4
          //   `
          //   );
          // } else {
          // const response = await axios.get(
          //   ` https://maps.googleapis.com/maps/api/geocode/json?latlng=${Lat ? Lat : position.coords.latitude},${
          //     Lng ? Lng : position.coords.longitude
          //   }&accuracy=1&radious=10&sensor=true&key=AIzaSyATz3lpNEJKLYbTVFpb-Yn30q15Htd4qp4
          //   `
          // );
          // }

          // console.log(
          //   "@@@@@@@",
          //   response?.data?.results[0].address_components
          //     .map((data) => data.long_name)
          //     .slice(response?.data?.results[0].address_components.length - 4, response?.data?.results[0].address_components.length)
          // );
         
          // https://maps.googleapis.com/maps/api/geocode/json?latlng=28.536288450553798,77.38301406357039&sensor=true&key=AIzaSyATz3lpNEJKLYbTVFpb-Yn30q15Htd4qp4

          // var lat = position.coords.latitude;
          // var lang = position.coords.longitude;
          // var url = "http://maps.googleapis.com/maps/api/geocode/json?latlng=" + lat + "," + lang + "&sensor=true";
        },
        error,
        {
          enableHighAccuracy: true,
        }
      );
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  };

  const callApi = (position) => {
    let payload = {};
    if (sessionStorage.getItem("lat") && sessionStorage.getItem("lng")) {
      payload = {
        lat: sessionStorage.getItem("lat"),
        long: sessionStorage.getItem("lng"),
      };
      homeData(payload);
      homeDataLaboratory(payload);
    } else if (Lat && Lng) {
      payload = {
        lat: Lat,
        long: Lng,
      };
      homeData(payload);
      homeDataLaboratory(payload);
    } else {
      payload = {
        lat: position.coords.latitude,
        long: position.coords.longitude,
      };
      homeData(payload);
      homeDataLaboratory(payload);
    }
    // homeData(payload);
    // homeDataLaboratory(payload);
  };

  useEffect(() => {
    //   let payload = {};
    //   if (sessionStorage.getItem("lat") && sessionStorage.getItem("lng")) {
    //     payload = {
    //       lat: sessionStorage.getItem("lat"),
    //       long: sessionStorage.getItem("lng"),
    //     };
    //   } else
    if (Lat && Lng) {
      const payload = {
        lat: Lat,
        long: Lng,
      };
      homeData(payload);
      homeDataLaboratory(payload);
    }
  }, [Lat, Lng]);

  const homeData = async (payload) => {
    const res = await homeInfoPatient(payload);
    if (res && res.status == 200) {
      console.log("Got dataaaaaaaaaaaaaa", res);
    }
  };

  const homeDataLaboratory = async (payload) => {
    const res = await homeInfoLaboratory(payload);
    if (res && res.status == 200) {
      console.log("Got lab dataaaaaaaaaaaaaa", res);
      console.log(res && res.data && res.data.laboratory);
      setLaboratoryList(res && res.data && res.data.laboratory && res.data.laboratory.docs);
    }
  };

  const handleLab = (id) => {
    history.push(`${ROUTES_CONSTANTS.PATIENT_LABORATORY_PACKAGES}/${id}`);
  };

  const handleInput = (e) => {
    // Update the keyword of the input element
    setValue(e.target.value);
    setAddress(e.target.value);
  };

  const handleSelect =
    ({ description }) =>
    () => {
      // When user selects a place, we can replace the keyword without request data from API
      // by setting the second parameter to "false"
      setValue(description, false);
      clearSuggestions();
      console.log("description", description);
      setAddress(description);
      sessionStorage.setItem("ManualAddress", description);

      // Get latitude and longitude via utility functions
      getGeocode({ address: description })
        .then((results) => getLatLng(results[0]))
        .then(({ lat, lng }) => {
          console.log("📍 Coordinates: ", { lat, lng });
          setLat(lat);
          setLng(lng);
          sessionStorage.setItem("lat", lat);
          sessionStorage.setItem("lng", lng);
        })
        .catch((error) => {
          console.log("😱 Error: ", error);
        });
    };

  const renderSuggestions = () =>
    data.map((suggestion) => {
      const {
        place_id,
        structured_formatting: { main_text, secondary_text },
      } = suggestion;

      return (
        <li key={place_id} onClick={handleSelect(suggestion)}>
          <strong>{main_text}</strong> <small>{secondary_text}</small>
        </li>
      );
    });

  return (
    <div className=" col-lg-8 col-xl-9 content_ipad_p ">
      <div className="row mb-4">
        <div className="col-md-12">
          <div className="section custom-card pat-dashboard-nearby">
            <div className="row form-group">
              <div className="col-md-12">
                <div className="pharmacy-home-slider address-suggesstion">
                  {/* <input className="form-control pl-5" type="text" placeholder="Location" value={address} /> */}
                  <input
                    type="text"
                    className="form-control pl-5"
                    name="address"
                    value={value ? value : address}
                    maxLength="60"
                    onChange={handleInput}
                    disabled={!ready}
                    placeholder="Location"
                    autoComplete="off"
                  />
                  {status === "OK" && <ul>{renderSuggestions()}</ul>}
                  <span className="map-marker pointer" onClick={()=>getCurrentReverseGeocodingData()}>
                    <i className="fas fa-map-marker-alt fa-lg"></i>
                  </span>
                </div>
              </div>
            </div>
            <h3 className="mb-2">What We Offer</h3>
            <Slider {...settings} className="doctor-slider">
              {ServiceProviderTypeData &&
                ServiceProviderTypeData.length &&
                ServiceProviderTypeData.map((item, index) => (
                  <>
                    <div onClick={() => handleNavigation(item)}>
                      <div className="card widget-profile pat-widget-profile">
                        <div className="card-body">
                          <div className="profile-info-widget">
                            <div className="doc_img_frame">
                              <img src={item ? item.image : IMAGE_CONSTANT.MEDICAL_IMAGE_ONE} className="img-fluid" alt="" />
                            </div>
                          </div>
                          <div className="patient-info ">
                            <div className="patient-info ">
                              <label className="text-center">
                                <b>Top {item && item.primary_specialty} Near By Your Region</b>
                              </label>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                ))}
            </Slider>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="col-md-12">
          <section className="section mb-2 custom-card">
            {docList && docList.length
              ? docList.map((item, index) => (
                  <>
                    {item && item.docs ? (
                      item.docs.length ? (
                        <>
                          <h4 className="mb-4">
                            Latest
                            <span className="ml-2 md-2">{item.docs[0].serviceProviderType[0].primary_specialty}</span> in Your Area
                          </h4>
                          <div className="row">
                            {item.docs
                              ? item.docs.map((data) => (
                                  <div className="col-sm-6 col-md-6 col-lg-3">
                                    <div>
                                      <div className="card widget-profile pat-widget-profile">
                                        <div className="card-body">
                                          <div className="pro-widget-content" onClick={() => handleDocNavigation(data)}>
                                            <div className="profile-info-widget">
                                              <div className="booking-doc-img">
                                                <img
                                                  src={data && data.profilePic ? data.profilePic : IMAGE_CONSTANT.DOC_PROFILE}
                                                  alt="User"
                                                />
                                              </div>
                                              <div className="profile-det-info">
                                                <h3>
                                                  Dr.
                                                  {data ? data.fullName : ""}
                                                </h3>
                                              </div>
                                            </div>
                                          </div>
                                          <div className="patient-info color-grey">
                                            <div className="color-grey">
                                              <b>
                                                ${data ? data.serviceCharge : ""}
                                                &nbsp;Consultation Fee
                                              </b>
                                            </div>
                                            <div className="color-gray">
                                              {data ? `${data.experience_in_years} Years ${data.experience_in_months} Months` : ""}
                                              &nbsp;of Experience
                                            </div>
                                            <div class="color-gray">
                                              {data && data.specialties && data.specialties.length === 1 ? (
                                                data.specialties[0].secondry_specialty
                                              ) : (
                                                <span
                                                  onClick={() => {
                                                    setViewMultiSpe(!ViewMultiSpe);
                                                    setPrimarySpeId(data.primary_specialtyId);
                                                    setMultiSpeData(data?.specialties);
                                                  }}
                                                >
                                                  {" "}
                                                  Multi Speciality
                                                </span>
                                              )}
                                            </div>

                                            <div className=" rating ">
                                              <ReactStars
                                                edit={false}
                                                count={5}
                                                value={data?.rating}
                                                size={16}
                                                style={{ selfAlign: "center" }}
                                                color2={"#ffd700"}
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                ))
                              : ""}
                          </div>
                        </>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </>
                ))
              : ""}
          </section>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12">
          <h4 className="mb-4">{LaboratoryList?.length ? "Latest Laborateries in Your Area" : ""}</h4>
          <div className="section mb-2 custom-card">
            <div className="row">
              {LaboratoryList && LaboratoryList.length
                ? LaboratoryList.map((item, index) => (
                    <>
                      <div className="col-sm-6 col-md-6 col-lg-3">
                        <a onClick={() => handleLab(item._id)}>
                          <div className="card widget-profile pat-widget-profile">
                            <div className="card-body">
                              <div className="profile-info-dash laboratery-img">
                                <div className="lab_image">
                                  <img src={item ? item.image : IMAGE_CONSTANT.MEDICAL_IMAGE_ONE} className="img-fluid" alt="User" />
                                </div>
                              </div>
                              <div className="patient-info color-grey">
                                <div className="color-grey">
                                  <b>{item ? item.name : ""}</b>
                                </div>
                                {/* <div className="color-gray"> Opening time:</div>
                                <div className="color-gray">Closing time :</div> */}
                                {/* <div className=" rating ">
                                  <i className="fas fa-star filled"></i>
                                  <i className="fas fa-star filled"></i>
                                  <i className="fas fa-star filled"></i>
                                  <i className="fas fa-star filled"></i>
                                  <i className="fas fa-star filled"></i>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </a>
                      </div>
                    </>
                  ))
                : " "}
            </div>
          </div>
        </div>
      </div>
      <MultipleSpeciality
        ViewMultiSpe={ViewMultiSpe}
        setViewMultiSpe={setViewMultiSpe}
        PrimarySpeId={PrimarySpeId}
        MultiSpeData={MultiSpeData}
      />
    </div>
  );
}

export default PatientDashboard;
