import { useSelector, useDispatch } from "react-redux";
import { doctor } from "../../redux/action";
import { useEffect, useState } from "react";
import UpgradeSubscription from "../PatientDashboard/findDoctorsNearBy/UpgradeSubscription";
import WithdrawAmount from "./withdraw-amount/WithdrawAmount";
import moment from "moment";
import PaginationComponent from "react-reactstrap-pagination";

function Wallet() {
  let dispatch = useDispatch();
  const [PatSubcriptionModal, setPatSubcriptionModal] = useState(false);
  const [DataSub, setDataSub] = useState(false);
  const [selectedPage, setselectedPage] = useState(1);
  const [DataLimit, setDataLimit] = useState(8);
  const [docWalletHistory, setdocWalletHistory] = useState([]);
  const docWalletInfo = useSelector((state) => state?.doctor?.doctorWalletData?.walletData);
  const doctorWalletHistory = useSelector((state) => state?.doctor?.doctorWithdrawTransactionHistory);
  const docSubscriptionInfo = useSelector((state) => state?.doctor?.doctorSubscribeData?.result?.subscriptionId);
  console.log("docSubscriptionInfo", docSubscriptionInfo);
  useEffect(() => {
    window.scrollTo(0, 0);
    docSubscriptionInWeb();
  }, []);
  const docSubscriptionInWeb = async () => dispatch(doctor.docSubscriptionInWebAction());
  console.log("doctorWalletHistory===>", doctorWalletHistory);
  const [withdrawAmt, setWithdrawAmt] = useState(false);
  const [reloadApi, setreloadApi] = useState(false);

  console.log("docWalletInfo", docWalletInfo);

  useEffect(() => {
    setdocWalletHistory(doctorWalletHistory);
  }, [doctorWalletHistory]);

  useEffect(() => {
    window.scrollTo(0, 0);
    docWalletInWeb();
  }, []);

  useEffect(() => {
    docHistoryData(DataLimit, selectedPage);
  }, [DataLimit, selectedPage, reloadApi]);

  const docWalletInWeb = async () => dispatch(doctor.docWalletInWebAction());
  const docHistoryData = async (DataLimit, selectedPage) => dispatch(doctor.docWalletHistoryAction(DataLimit, selectedPage));

  const handleWithdrawAmt = () => {
    setWithdrawAmt(true);
    setreloadApi(false);
  };
  const handleSelected = (data) => {
    console.log("clicked page", data);
    setselectedPage(data);
  };
  return (
    <>
      <div className=" col-lg-8 col-xl-9 content_ipad_p">
        <div className="card">
          <div className="card-header">
            <h4>Wallet</h4>
          </div>
          <div className="card-body">
            <div className=" text-right mb-2">
              {" "}
              <button
                className="btn btn-primary"
                onClick={() => {
                  setPatSubcriptionModal(!PatSubcriptionModal);
                  setDataSub(true);
                }}
              >
                <span>
                  <i className="fa fa-plus-square"></i>
                </span>{" "}
                Upgrade/Downgrade Subscription
              </button>
            </div>

            <div className="row">
              <div className="col-lg-6 col-md-12">
                <div className="card">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-md-6 col-lg-6">
                        <div className="mb-4">
                          <h3 className="mb-0">${docWalletInfo && docWalletInfo?.totalBalance}</h3>
                          <span for="" className="color-grey">
                            Total Balance
                          </span>
                        </div>
                        <div className="color-grey ">
                          {" "}
                          <label className="mb-0" for="">
                            Number of Secondary Specialities : {docWalletInfo && docWalletInfo?.numberOfSecondrySpecialty}
                          </label>
                        </div>
                        <div className="color-grey ">
                          {" "}
                          <label className="mb-0" for="">
                            Minutes of Video Calls : {docWalletInfo && docWalletInfo?.minutesOfVideoCalls}
                          </label>
                        </div>
                        <div className="color-grey ">
                          {" "}
                          <label className="mb-0" for="">
                            Minutes of Audio Calls: {docWalletInfo && docWalletInfo?.minutesOfAudioCalls}
                          </label>
                        </div>
                        <div className="color-grey ">
                          <label className="mb-0" for="">
                            Number of Leads: {docWalletInfo && docWalletInfo?.numberOfLeads}
                          </label>
                        </div>
                        <div className="color-grey ">
                          <label className="mb-0" for="">
                            Number of Messages: {docWalletInfo && docWalletInfo?.numberOfMessages}
                          </label>
                        </div>
                        <div className="color-grey ">
                          <label className="mb-0" for="">
                            Subscription Expiry Date :{" "}
                            {docWalletInfo && new Date(docWalletInfo?.expiryDate).toDateString().split(" ").slice(1).join(" ")}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6 col-lg-6">
                        <div>
                          <button className="btn btn-primary" onClick={handleWithdrawAmt}>
                            Withdraw Amount
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="history_box">
              <h4>Withdraw history</h4>

              <div class="card mb-2 color-grey p-2">
                <table>
                  <tr>
                    <th>Request Date</th>
                    <th>Received Date</th>
                    <th className="text-center">Amount</th>
                    <th>Status</th>
                  </tr>
                  {docWalletHistory && docWalletHistory.transactionData && docWalletHistory.transactionData.length ? (
                    docWalletHistory.transactionData.map((item, i) => (
                      <tr key={i}>
                        <td>{moment(item.createdAt).format("L")}</td>
                        <td>{item.paymentStatus === "PAID" ? <>{moment(item.updatedAt).format("L")}</> : "--"}</td>
                        <td className="text-center">{item.amount}</td>
                        <td>{item.paymentStatus}</td>
                      </tr>
                    ))
                  ) : (
                    <div className="text-center">
                      <p>Data not found!</p>
                    </div>
                  )}
                </table>
              </div>
              <div className="row">
                <div class="col-sm-5 col-md-5">
                  <div class="dataTables_info showing_pagination" id="DataTables_Table_0_info" role="status" aria-live="polite">
                    Showing {(selectedPage - 1) * DataLimit + 1} to{" "}
                    {(selectedPage - 1) * DataLimit +
                      (docWalletHistory && docWalletHistory.transactionData && docWalletHistory.transactionData.length)}{" "}
                    of {docWalletHistory && docWalletHistory.totalList} entries
                  </div>
                </div>
                <div class="col-sm-7 col-md-7">
                  <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                    <PaginationComponent
                      totalItems={docWalletHistory && docWalletHistory.totalList}
                      pageSize={DataLimit}
                      maxPaginationNumbers={3}
                      defaultActivePage={1}
                      onSelect={(e) => handleSelected(e)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <UpgradeSubscription PatSubcriptionModal={PatSubcriptionModal} docSubscriptionInfo = {docSubscriptionInfo} setPatSubcriptionModal={setPatSubcriptionModal} DataSub={DataSub} />
      <WithdrawAmount withdrawAmt={withdrawAmt} setWithdrawAmt={setWithdrawAmt} setreloadApi={setreloadApi} />
    </>
  );
}

export default Wallet;
