import React from "react";
import { Modal, ModalBody } from "reactstrap";

function DeleteMedicalRecord({ DeleteModal, setDeleteModal }) {
  return (
    <Modal isOpen={DeleteModal} className="modal-dialog modal-dialog-centered">
      <div className="modal-content">
        <ModalBody>
          <div className="modal-body text-center">
            <div className="form-content p-2">
              <h4 className="modal-title text-center mb-2">Delete</h4>
              <p className="default-content-clr text-center">
                Are you really want to delete the Medical record?
              </p>
              <button
                type="button"
                className="btn btn-primary"
                onClick={() => setDeleteModal(false)}
              >
                Cancel
              </button>
              <button
                type="button"
                className="btn btn-primary modal-confirm-btn"
              >
                Confirm
              </button>
            </div>
          </div>
        </ModalBody>
      </div>
    </Modal>
  );
}

export default DeleteMedicalRecord;
