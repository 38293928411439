import React, { useEffect, useState } from "react";
import { IMAGE_CONSTANT, ROUTES_CONSTANTS } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { doctor } from "../../../redux/action";
import { useHistory } from "react-router-dom";
import CancelAppointment from "../../common/CancelModal/CancelModal";
import FilterModal from "../../PatientDashboard/FilterModal";
import moment from "moment";
import { toast } from "react-toastify";
import { DebounceInput } from "react-debounce-input";

function UpcomingAppointment() {
  const [CancelModal, setCancelModal] = useState(false);
  const [CancelAppId, setCancelAppId] = useState(false);
  const [UppAppToggle, setUppAppToggle] = useState(false);
  const [UpcomingAppointsList, setUpcomingAppointsList] = useState([]);
  const [searchTerm, setsearchTerm] = useState("");
  let dispatch = useDispatch();
  let history = useHistory();

  const upcomingAppointments = async () => dispatch(doctor.docDashboardupcomingAppointmentsAction());
  useEffect(() => {
    upcomingAppointments();
  }, []);

  const AppointmentList = useSelector((state) => state?.doctor?.doctorDasboardUpcomingAppointment?.appointmentData);

  useEffect(() => {
    setUpcomingAppointsList(AppointmentList);
  }, [AppointmentList]);

  useEffect(() => {
    console.log("UpcomingAppointsList", UpcomingAppointsList);
    UpcomingAppointsList &&
      UpcomingAppointsList.map((item) => {
        // let appTimestamp  = new Date (item.appointmentStartTime).getTime();
        // let diffwithCurrentTime = appTimestamp - new Date().getTime();
        // let diffHour = diffwithCurrentTime / 1000 / 60 / 60 ;
        // console.log(diffHour)
        let currentTime = new Date().getTime();
        let appointmentTime = new Date(item.appointmentStartTime).getTime();
        if (appointmentTime - currentTime > 3600000) {
          // if (diffHour > 1){
          item["rescheduleEligible"] = true;
        } else {
          item["rescheduleEligible"] = false;
        }
        return item;
      });
  }, [UpcomingAppointsList]);

  useEffect(() => {
    setUppAppToggle(true);
  }, []);

  const handleViewAppointment = (id) => {
    history.push(`${ROUTES_CONSTANTS.DOCTOR_VIEW_UPCOMING_APPOINTMENT}/${id}`, UppAppToggle);
  };

  const handleReschedule = (id, eligible) => {
    console.log("eligible", eligible);
    if (eligible === true) {
      history.push(`${ROUTES_CONSTANTS.DOCTOR_SIDE_RESCHEDULE_APPOINTMENT}/${id}`);
    } else {
      toast.error(`You have to reschedule appointment before 60 minute of appointment time`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleCancel = (id, eligible) => {
    if (eligible == false) {
      toast.error(`You have to cancel appointment before 60 minute of appointment time`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      setCancelModal(!CancelModal);
      setCancelAppId(id);
    }
  };

  const handleSearch = (e) => {
    let data = e.target.value;
    setsearchTerm(data);
  };

  useEffect(() => {
    let searchResult = UpcomingAppointsList.filter(
      (item) =>
        item?.patientId?.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item?.patientId?.lastName.toLowerCase().includes(searchTerm.toLowerCase())
    );
    if (searchTerm === "") {
      setUpcomingAppointsList(AppointmentList);
    } else {
      setUpcomingAppointsList(searchResult);
    }
  }, [searchTerm]);

  return (
    <>
      <div className="tab-pane active" id="pat-current-appnt">
        <div className="input-social-media search_box">
          {/* <input type="text" className="form-control  mb-2" /> */}
          <DebounceInput
            minLength={1}
            debounceTimeout={500}
            aria-controls="DataTables_Table_0"
            className="form-control  mb-2"
            onChange={(e) => handleSearch(e)}
          />
          <span>
            <i className="fas fa-search appt-search-icon"></i>
          </span>
        </div>
        <div className="row">
          {UpcomingAppointsList && UpcomingAppointsList.length ? (
            UpcomingAppointsList.map((item, ind) =>
              item && item.status === "accepted" ? (
                <div className="col-sm-6 col-md-6 col-lg-3 pr-0" key={ind}>
                  <div className="card widget-profile pat-widget-profile">
                    <div className="card-body li-listing-profile">
                      <div
                        onClick={() => {
                          handleViewAppointment(item._id);
                        }}
                      >
                        <div className="profile-info-widget">
                          <div className="booking-doc-img">
                            <img
                              src={item && item.patientId.profilePic ? item.patientId.profilePic : IMAGE_CONSTANT.USER_ICON}
                              className="img-fluid"
                              alt=""
                            />
                          </div>
                        </div>
                        <div className="profile-det-info text-center">
                          <h3>
                            {item && item.patientId.firstName} {item && item.patientId.lastName}{" "}
                          </h3>
                        </div>
                        <div className="patient-info profile-info-custom">
                          <ul>
                            <li>
                              Date : <span>{moment(item?.appointmentStartTime).format("MM/DD/YYYY")}</span>
                            </li>
                            <li>
                              Time :{" "}
                              <span>
                                {" "}
                                {moment(item && item.appointmentStartTime).format("LT")} -{" "}
                                {moment(item && item.appointmentEndTime).format("LT")}
                                {/* {`${
                                item && item.appointmentStartTime
                                  ? `${new Date(item.appointmentStartTime).toLocaleTimeString().split(":")[0]}:${new Date(item.appointmentStartTime).toLocaleTimeString().split(":")[1]} ${
                                      new Date(item.appointmentStartTime).toLocaleTimeString().split(" ")[1]
                                    }`
                                  : ""
                              } -
                    ${
                      item && item.appointmentEndTime
                        ? `${new Date(item.appointmentEndTime).toLocaleTimeString().split(":")[0]}:${new Date(item.appointmentEndTime).toLocaleTimeString().split(":")[1]} ${new Date(item.appointmentEndTime).toLocaleTimeString().split(" ")[1]}`
                        : ""
                    }`} */}
                              </span>
                            </li>
                            <li>
                              Paid :{" "}
                              <span>
                                {"$"}
                                {item && item.amount}
                              </span>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-12 mb-2">
                          <a className="btn book-btn" onClick={() => handleReschedule(item._id, item?.rescheduleEligible)}>
                            Reschedule
                          </a>
                        </div>

                        <div className="col-12">
                          <a className="btn view-btn" onClick={() => handleCancel(item._id, item?.rescheduleEligible)}>
                            Cancel
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-sm-6 col-md-6 col-lg-3">
                  <a>
                    <div className="card widget-profile pat-widget-profile">
                      <div className="card-body li-listing-profile">
                        <div onClick={() => handleViewAppointment(item._id)}>
                          <div className="profile-info-widget">
                            <div className="booking-doc-img">
                              <img
                                src={
                                  item && item.patientId && item.patientId.profilePic
                                    ? item.patientId.profilePic
                                    : IMAGE_CONSTANT.PAT_PROFILE
                                }
                                alt=""
                              />
                            </div>
                          </div>
                          <div className="profile-det-info text-center">
                            <h3>
                              {item && item.patientId.firstName}
                              {item && item.patientId.lastName}
                            </h3>
                          </div>
                          <div className="patient-info profile-info-custom">
                            <ul>
                              <li>
                                Date :<span>{item && moment(item?.appointmentStartTime).format("MM/DD/YYYY")}</span>
                              </li>
                              <li>
                                Time :
                                <span>
                                  {moment(item && item.appointmentStartTime).format("LT")} -{" "}
                                  {moment(item && item.appointmentEndTime).format("LT")}
                                  {/* {`${
                                  item && item.appointmentStartTime
                                    ? `${new Date(item.appointmentStartTime).toLocaleTimeString().split(":")[0]}:${new Date(item.appointmentStartTime).toLocaleTimeString().split(":")[1]} ${
                                        new Date(item.appointmentStartTime).toLocaleTimeString().split(" ")[1]
                                      }`
                                    : ""
                                } -
                    ${
                      item && item.appointmentEndTime
                        ? `${new Date(item.appointmentEndTime).toLocaleTimeString().split(":")[0]}:${new Date(item.appointmentEndTime).toLocaleTimeString().split(":")[1]} ${new Date(item.appointmentEndTime).toLocaleTimeString().split(" ")[1]}`
                        : ""
                    }`} */}
                                </span>
                              </li>
                              <li>
                                Paid :{" "}
                                <span>
                                  {"$"}
                                  {item && item.amount}
                                </span>
                              </li>
                            </ul>
                          </div>
                          <div className="cancelledAppoint">
                            <span>
                              <i class="fa fa-times-circle mr-1"></i>
                            </span>
                            Cancelled
                          </div>
                        </div>
                      </div>
                    </div>
                  </a>
                </div>
              )
            )
          ) : (
            <div className="col-md-12">
              <p className="text-center">There are currently no appointments available.</p>
            </div>
          )}
        </div>
      </div>
      <CancelAppointment CancelModal={CancelModal} setCancelModal={setCancelModal} CancelAppId={CancelAppId} />
    </>
  );
}
export default UpcomingAppointment;
