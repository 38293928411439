export default {
  DOCTOR_PROFILE_DATA: "DOCTOR_PROFILE_DATA",
  DOCTOR_DASHBOARD_DATA: "DOCTOR_DASHBOARD_DATA",
  DOCTOR_UPCOMING_APPOINTMENTS: "DOCTOR_UPCOMING_APPOINTMENTS",
  DOCTOR_PREVIOUS_APPOINTMENTS: "DOCTOR_PREVIOUS_APPOINTMENTS",
  DOCTOR_VIEW_APPOINTMENT: "DOCTOR_VIEW_APPOINTMENT",
  DOCTOR_HELP_LIST: "DOCTOR_HELP_LIST",
  DOCTOR_SUBMIT_HELP_REQUEST: "DOCTOR_SUBMIT_HELP_REQUEST",
  DOCTOR_VIEW_HELP_REQUEST: "DOCTOR_VIEW_HELP_REQUEST",
  DOCTOR_CHANGE_PWD: "DOCTOR_CHANGE_PWD",
  DOCTOR_EDIT_PROFILE: "DOCTOR_EDIT_PROFILE",
  DOCTOR_CARD_LIST: "DOCTOR_CARD_LIST",
  DOCTOR_ADD_CARD: "DOCTOR_ADD_CARD",
  DOCTOR_EDIT_CARD: "DOCTOR_EDIT_CARD",
  DOCTOR_DELETE_CARD: "DOCTOR_DELETE_CARD",
  DOCTOR_TERMS_AND_CONDITIONS: "DOCTOR_TERMS_AND_CONDITIONS",
  DOCTOR_ABOUT_US: "DOCTOR_ABOUT_US",
  DOCTOR_PRESCRIPTION_LIST: "DOCTOR_PRESCRIPTION_LIST",
  DOCTOR_ADD_PRESCRIPTION: "DOCTOR_ADD_PRESCRIPTION",
  DOCTOR_DELETE_PRESCRIPTION: "DOCTOR_DELETE_PRESCRIPTION",
  DOCTOR_VIEW_PRESCRIPTION: "DOCTOR_VIEW_PRESCRIPTION",
  DOCTOR_WALLET_INFO: "DOCTOR_WALLET_INFO",
  DOCTOR_SUBSCRIBE_INFO: "DOCTOR_SUBSCRIBE_INFO",
  DOCTOR_EDIT_PRESCRIPTION: "DOCTOR_EDIT_PRESCRIPTION",
  STATIC_PAGE_CMS: "STATIC_PAGE_CMS",
  TESTIMONIAL_LIST: "TESTIMONIAL_LIST",
  CONTACT_US_FORM: "CONTACT_US_FORM",
  SUBSCRIBE_NEWS_LETTER: "SUBSCRIBE_NEWS_LETTER",
  OUR_TEAM_LIST: "OUR_TEAM_LIST",
  MEDICAL_RECORD_LIST: "MEDICAL_RECORD_LIST",
  RECOMMENDATION_LIST: "RECOMMENDATION_LIST",
  INVOICE_LIST: "INVOICE_LIST",
  INVOICE_VIEW: "INVOICE_VIEW",
  INVOICE_LIST_BILL: "INVOICE_LIST_BILL",
  PRIVACY_POLICY: "PRIVACY_POLICY",
  DOCTOR_WITHDRAW_REQUEST:"DOCTOR_WITHDRAW_REQUEST",
  DOCTOR_WITHDRAW_TRANSACTION_HISTORY: "DOCTOR_WITHDRAW_TRANSACTION_HISTORY",

};
