export const API_CONSTANTS = {
  DOCTOR_SIGN_UP: "doctor/signup",
  DOCTOR_PERSONAL_SIGN_UP: "doctor/createPersonalProfile",
  PATIENT_SIGN_UP: "patient/signup",
  DOCTOR_VERIFY_OTP: "doctor/verifyOtp",
  DOCTOR_RESEND_OTP: "doctor/resendOtp",
  DOCTOR_SUBSCRIPTION_LIST: "doctor/subscriptionList",
  PATIENT_VERIFY_OTP: "doctor/verifyOtp",
  PATIENT_RESEND_OTP: "doctor/resendOtp",
  PATIENT_SUBSCRIPTION_LIST: "patient/subscriptionList",
  PATIENT_GET_TAX: "patient/getTax",

  DOCTOR_SIGN_IN: "doctor/login",
  DOCTOR_CREATE_ROOM : "doctor/createRoom",
  DOCTOR_GET_TAX: "doctor/getTax",

  
  PATIENT_CREATE_ROOM : "patient/createRoom",

  PATIENT_SIGN_IN: "patient/login",
  DOCTOR_FORGET_PWD: "doctor/forgotPassword",
  PATIENT_FORGET_PWD: "patient/forgotPassword",
  DOCTOR_RESET_PWD: "doctor/resetPassword",
  PATIENT_RESET_PWD: "patient/resetPassword",
  COMMON_PROFILE_PIC: "common/getUrl?file",
  COMMON_PRIMARY_CARD: "common/makeCard_primary",
  COMMON_GET_TWILIO_TOKEN: "call/getTwilioToken",
  COMMON_DISCONNECT_CALL: "call/callDisconnect",
  COMMON_GET_REFFERAL_CODE: "common/getReferralCode",
  COMMON_REFFERAL_REPORT_LIST: "common/invitationReportList",
  COMMON_DOCTOR_LIST: "patient/webHomeSearch",
  COMMON_GET_REMAINING_MINUTE: "call/getRemainingMinute",
  COMMON_UPDATE_REMAINING_MINUTE: "call/updateRemainingMinute",

  PATIENT_PERSONAL_PROFILE: "doctor/createPersonalProfile",
  DOCTOR_PROFESSIONAL_PROFILE: "doctor/createProfessionalProfile",
  PATIENT_PAYMENT_ACCOUNT: "patient/BuySubscription",
  PATIENT_PAYMENT_ACCOUNT_UPGRADE: "patient/upgradeSubscription",
  DOCTOR_PAYMENT_ACCOUNT: "doctor/BuySubscription",
  PATIENT_MEDICAL_DETAILS: "patient/createMedicalProfile",

  PATIENT_HOME_SERVICE_PROVIDER_TYPE: "patient/serviceProviderTypeList",
  PATIENT_COUPON_LIST: "patient/couponList",
  DOCTOR_COUPON_LIST: "doctor/couponList",
  DOCTOR_DETAILS: "patient/viewDoctorDetails",
  SLOTS_LIST: "patient/getSlot?",
  BOOK_APPOITMENTS: "patient/bookAppointment",
  DOCTOR_PRIMARY_SPECIALITY_LIST: "doctor/primarySpecialtyList",
  DOCTOR_SECONDARY_SPECIALITY_LIST: "doctor/secondrySpecialtyList?primary_specialtyId=",
  PATIENT_PROFILE_DATA: "patient/myProfile",
  NEAR_BY_PHISICIAN: "patient/nearByServiceProvider",
  PATIENT_HOME_DATA: "patient/homeData",
  PATIENT_LABORATORY_HOME_DATA: "patient/laboratoryHomeData",
  PATIENT_UPCOMING_APPOINTMENT: "patient/upcomingAppointments",
  PATIENT_PREVIOUS_APPOINTMENT: "patient/previousAppointments",
  PATIENT_VIEW_APPOINTMENT: "patient/viewAppointment",
  PATIENT_LABORATORY_HOME_DATA: "patient/laboratoryHomeData",
  PATIENT_CHANGE_PASSWORD: "patient/changePassword",
  PATIENT_ADD_CARD_IN_SETTINGS: "patient/addCard",
  PATIENT_CARDLIST_IN_SETTINGS: "patient/cardList",
  PATIENT_DELETE_CARD_IN_SETTINGS: "patient/deleteCard",
  PATIENT_EDIT_CARD_IN_SETTINGS: "patient/editCard",
  PATIENT_SUBSCRIPTION_IN_WEB: "patient/mySubscription",
  PATIENT_WALLET_DETAIL: "patient/myWallet",
  PATIENT_RESCHEDULED_LIST: "patient/rescheduleRequestList",
  PATIENT_ACCEPT_RESCHEDULED_REQUEST: "patient/acceptRescheduleRequest",
  PATIENT_REJECT_RESCHEDULED_REQUEST: "patient/rejectRescheduleRequest",
  PATIENT_SECONDARY_SPE_LIST: "patient/secondrySpecialtyList",
  SET_FAV: "patient/favourite",
  PATIENT_MEDICAL_RECORDS: "patient/medicalHistory",
  PATIENT_PRESCRIPTION_LIST: "patient/prescriptionList",
  PATIENT_PRESCRIPTION_VIEW: "patient/viewPrescription",
  PATIENT_GET_FAV_LIST: "patient/favouriteList?",
  PATIENT_RESCHEDULE_APPOINTMENT: "patient/rescheduleAppointment",
  PATIENT_CANCEL_APPOINTMENT: "patient/cancelAppointment",
  PATIENT_BOOK_APPONTMENT_WITH_CARD: "patient/bookAppointment_fromCard",
  PATIENT_VIEW_LABORATORY_DETAIL: "patient/viewLaboratory",
  PATIENT_LAB_SLOT_LIST: "patient/getLaboratorySlot",
  PATIENT_BOOK_LABORATORY: "patient/bookLaboratory_appointment",
  PATIENT_RESCHEDULED_LABORATORY: "patient/rescheduleLaboratory_appointment",
  PATIENT_CANCEL_LABORATORY: "patient/cancelLaboratory_appointment",
  PATIENT_MEDICAL_RECORDS_LIST: "patient/medicalRecordList",
  PATIENT_INVOICE_LIST: "patient/invoiceList",
  PATIENT_INVOICE_VIEW: "patient/viewInvoice",
  PATIENT_INVOICE_BILL_LIST: "patient/invoiceBillList",
  PATIENT_HELP_LIST: "patient/helpRequestList",
  PATIENT_SUBMIT_HELP_REQUEST: "patient/submitHelpRequest",
  PATIENT_VIEW_HELP: "patient/viewHelpRequest",
  PATIENT_REPLY_ON_REQUEST: "patient/replyOnRequest",
  PATIENT_REPORT_LIST: "patient/reportList",
  PATIENT_WALLET_PAYMENT: "patient/upgradeSubscription_fromWallet",

  PATIENT_ADD_RATINGS: "patient/rating",

  PATIENT_EDIT_RATINGS: "patient/editRating",
  DOCTOR_PROFILE_DATA: "doctor/myProfile",
  DOCTOR_DASHBOARD_DATA: "doctor/dashboard",
  NOTIFICATION_LIST: "notificationList",
  DELETE_NOTIFICATION: "deleteNotification",
  DOCTOR_PROFILE_DATA: "doctor/myProfile",
  DOCTOR_DASHBOARD_DATA: "doctor/dashboard",
  DOCTOR_UPCOMING_APPOINTMENTS: "doctor/upcomingAppointments",
  DOCTOR_PREVIOUS_APPOINTMENTS: "doctor/previousAppointments",
  DOCTOR_VIEW_APPOINTMENTS: "doctor/viewAppointment",
  DOCTOR_GET_SLOTS_FOR_RESCHEDULE: "doctor/getSlot?",
  DOCTOR_RESCHEDULE_APPOINTMENT: "doctor/rescheduleAppointment",
  DOCTOR_CANCEL_APPOINTMENT: "doctor/cancelAppointment",
  PATIENT_CANCEL_APPOINTMENT: "patient/cancelAppointment",

  DOCTOR_HELP_REQUEST_LIST: "doctor/helpRequestList",
  DOCTOR_SUBMIT_HELP_REQUEST: "doctor/submitHelpRequest",
  DOCTOR_VIEW_HELP_REQUEST: "doctor/viewHelpRequest",
  DOCTOR_REPLY_ON_REQUEST: "doctor/replyOnRequest",
  DOCTOR_CHANGE_PASSWORD: "doctor/changePassword",
  PATIENT_EDIT_PROFILE_IN_SETTINGS: "patient/editProfile",
  DOCTOR_EDIT_PROFILE: "doctor/editProfile",
  DOCTOR_CARD_LIST: "doctor/cardList",
  DOCTOR_ADD_CARD: "doctor/addCard",
  DOCTOR_DELETE_CARD: "doctor/deleteCard",
  DOCTOR_EDIT_CARD: "doctor/editCard",
  DOCTOR_TERMS_AND_CONDITIONS: "doctor/termsAndConditions",
  DOCTOR_ABOUT_US: "doctor/aboutUs",
  DOCTOR_LOG_OUT: "doctor/logout",
  DOCTOR_WALLET_IN_WEB: "doctor/myWallet",
  DOCTOR_SUBSCRIPTION_IN_WEB: "doctor/mySubscription",
  DOCTOR_LOG_OUT: "doctor/logout",
  DOCTOR_PRESCRIPTION_LIST: "doctor/prescriptionList",
  DOCTOR_ADD_PRESCRIPTION: "doctor/addPrescription",
  DOCTOR_DELETE_PRESCRIPTION: "doctor/deletePrescription",
  DOCTOR_VIEW_PRESCRIPTION: "doctor/viewPrescription",
  RATING_LIST: "doctor/reviewsList",
  DOCTOR_CHALLANGE_REVIEWS: "doctor/challengeReviews",
  RATING_PAT_LIST: "patient/reviewsList",
  DOCTOR_EDIT_PRESCRIPTION: "doctor/editPrescription",
  DOCTOR_PAYMENT_ACCOUNT_UPGRADE: "doctor/upgradeSubscription",
  DOCTOR_RECOMMENDATION_LIST: "doctor/recommendation",
  DOCTOR_INVOICE_LIST: "doctor/invoiceList",
  DOCTOR_INVOICE_VIEW: "doctor/viewInvoice",
  DOCTOR_INVOICE_BILL_LIST: "doctor/invoiceBillList",
  DOCTOR_WALLET_PAYMENT: "doctor/upgradeSubscription_fromWallet",
  DOCTOR_PRIVACY_POLICY: "doctor/privacyPolicy",
  DOCTOR_WITHDRAW_REQUEST: "doctor/withdrawRequest",
  DOCTOR_WITHDRAW_TRANSACTION_HISTORY: "doctor/transactionHistory",

  PATIENT_PRIVACY_POLICY: "patient/privacyPolicy",
  PATIENT_TERMS_CONDITIONS: "patient/termsAndConditions",
  PATIENT_LOGOUT: "patient/logout",
  PATIENT_UPCOMING_LABORATORY_APPOINTMENT: "patient/upcomingLaboratory_appointments",
  PATIENT_PREVIOUS_LABORATORY_APPOINTMENT: "patient/previousLaboratory_appointments",

  STATIC_PAGE_SMS: "cms/getStaticPage",
  TESTIMONIAL_LIST: "cms/testimonialList",
  CONTACT_US_FORM: "cms/contactUs_form",
  SUBSCRIBE_NEWS_LETTER: "cms/subscribe_newsLetter",
  OUR_TEAM_LIST: "cms/teamList",
  EDIT_AVAILABILITY: "doctor/editAvailability",

  ADD_MEDICAl_RECORD: "doctor/addMedicalRecord",
  EDIT_MEDICAL_RECORD: "doctor/editMedicalRecord",
  GET_MEDICAl_RECORD: "doctor/medicalRecordList",

  PATIENT_MEDICAL_RECORD_LIST: "patient/medicalRecordList",

  STRIPE_SESSION_CHECKOUT:"stripe/createSessionCheckout",
  STRIPE_SESSION_CHECKOUT_FOR_APPOINTMENT : "stripe/createSessionCheckoutForAppointment",

};
