import React, { useState, useEffect } from "react";
import { IMAGE_CONSTANT } from "../../../constants";
import DocViewPersonal from "./DocViewPersonal";
import DocViewProfessional from "./DocViewProfessional";
import { useDispatch, useSelector } from "react-redux";
import { doctor } from "../../../redux/action";

function DocViewProfile() {
  let dispatch = useDispatch();
  const [ProfileData, setProfileData] = useState({});
  const [isPersoanlOpen, setisPersoanlOpen] = useState(true);
  const [isProfessionalOpen, setisProfessionalOpen] = useState(false);
  const handlePersoalDetail = () => {
    setisPersoanlOpen(true);
    setisProfessionalOpen(false);
  };
  const handleProfessionalDetail = () => {
    setisProfessionalOpen(true);
    setisPersoanlOpen(false);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    DoctorProfileInfo();
  }, []);

  const DoctorData = useSelector(
    // (state) => state?.patient?.patientProdileInfo
    (state) => state?.doctor?.doctorProdileInfo
  );
  useEffect(() => {
    setProfileData(DoctorData && DoctorData ? DoctorData.result[0] : "");
  }, [DoctorData]);

  console.log("DoctorData", DoctorData);

  const DoctorProfileInfo = async () => dispatch(doctor.doctorProfileDetailsAction());

  return (
    <div className=" col-lg-8 col-xl-9 content_ipad_p">
      <div className="card">
        <div className="card-body pt-0">
          <div className="row">
            <div className="col-md-12">
              <div className="profile-header p-3">
                <div className="row align-items-center">
                  <div className="booking-doc-img">
                    <a href="#">
                      <img className="rounded-circle view-profile-resize" alt="" src={ProfileData && ProfileData.profilePic ? ProfileData.profilePic : IMAGE_CONSTANT.DEFAULT_ICON} />
                    </a>
                  </div>
                  <div className="col ml-md-n2 profile-user-info">
                    <h4 className="user-name mb-0">{ProfileData ? ProfileData.fullName : ""}</h4>
                    <h6 className="text-muted">{ProfileData ? ProfileData.email : ""}</h6>
                    <div className="user-Location">
                      <i className="fa fa-map-marker"></i> {ProfileData && ProfileData ? ProfileData.location && ProfileData.location.address : ""}
                    </div>
                    <div className="about-text">{ProfileData ? ProfileData.bio : ""}</div>
                  </div>
                </div>
              </div>
              <nav className="user-tabs">
                <ul className="nav nav-tabs nav-tabs-bottom nav-justified">
                  <li className="nav-item">
                    <a className={isPersoanlOpen ? "nav-link active text-nowrap" : "nav-link  text-nowrap"} onClick={handlePersoalDetail}>
                      Personal Details
                    </a>
                  </li>
                  <li className="nav-item">
                    <a className={isProfessionalOpen ? "nav-link active text-nowrap" : "nav-link  text-nowrap"} onClick={handleProfessionalDetail}>
                      Professional Details
                    </a>
                  </li>
                </ul>
              </nav>
              <div className="tab-content profile-tab-cont">{isPersoanlOpen ? <DocViewPersonal ProfileData={ProfileData} /> : isProfessionalOpen ? <DocViewProfessional ProfileData={ProfileData} /> : null}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DocViewProfile;
