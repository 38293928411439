import React, { Component } from "react";
// import { Input } from 'reactstrap';

export default class AutoComplete extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.autocomplete = null;
    this.textInput = React.createRef();
    console.log(" this.textInput", this.textInput);
    this.handlePlaceChanged = this.handlePlaceChanged.bind(this);

    // console.log('streetAddress',this.props.streetAddress);
    // console.log('streetAddress',this.streetAddress);

    // window.process.env
    // console.log(process.env.REACT_APP_GOOGLE_API_KEY);
  }

  componentDidMount() {
    let input = this.textInput.current;
    if (this.props.streetAddress && this.textInput?.current) {
      this.textInput.current.value = this.props.streetAddress;
    }
    if (window.google && window.google.maps) {
      const center = { lat: 51.509865, lng: -0.118092 };
      // Create a bounding box with sides ~10km away from the center point
      const defaultBounds = {
        north: center.lat + 0.1,
        south: center.lat - 0.1,
        east: center.lng + 0.1,
        west: center.lng - 0.1,
      };
      const options = {
        bounds: defaultBounds,
        componentRestrictions: { country: ["us", "ind"] }, //"uk"
        origin: center,
        // fields: ["address_components", "geometry", "icon", "name"],
        strictBounds: false,
        types: ["establishment"],
      };
      this.autocomplete = new window.google.maps.places.Autocomplete(input, options);
      this.autocomplete.addListener("place_changed", this.handlePlaceChanged);
    }
  }

  // componentDidUpdate(){
  //     this.props.onPlaceSelected(this.autocomplete.getPlace())
  // }

  handlePlaceChanged = () => {
    const place = this.autocomplete.getPlace();
    this.setState({ place: place });
    this.props.onPlaceSelected(this.autocomplete.getPlace());

    // console.log(place);
  };

  render() {
    return (
      <div>
        <div id="pac-container">
          <input id="pac-input" addressdata={this.state.addressdata} type="text" placeholder="Search" ref={this.textInput} defaultValue={this.props.data} className="form-control" />
        </div>
      </div>
    );
  }
}
