import React, { useEffect, useState } from "react";
import PaginationComponent from "react-reactstrap-pagination";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { ROUTES_CONSTANTS } from "../../../../../constants";
import { commonAction, patient } from "../../../../../redux/action";
import ToasterFunc from "../../../../common/toaster/Toaster";
import AddCard from "./AddCard";
import DeleteCard from "./DeleteCard";
import EditCard from "./EditCard";

function PaymentDetail({ setisPayment }) {
  // let history = useHistory();
  let dispatch = useDispatch();
  const [CvvToggle, setCvvToggle] = useState(false);
  const [CvvId, setCvvId] = useState("");
  const [CvvIndex, setCvvIndex] = useState(false);
  const [selectedPage, setselectedPage] = useState(1);
  const [DataLimit, setDataLimit] = useState(4);
  const [DeleteId, setDeleteId] = useState("");
  const [CardData, setCardData] = useState({});
  const [DeleteModal, setDeleteModal] = useState(false);
  const [CardDetail, setCardDetail] = useState(false);
  const [EditCardModal, setEditCardModal] = useState(false);
  const handleCard = () => {
    setCardDetail(!CardDetail);
  };

  const CardListInfo = useSelector((state) => state?.patient?.patientCardList);
  console.log("CardListInfo", CardListInfo);

  const CardListPatientwithPagination = async (DataLimit, selectedPage) => dispatch(patient.cardListInPatsettingsAction(DataLimit, selectedPage));

  useEffect(() => {
    window.scrollTo(0, 0);

    if (DataLimit) {
      CardListPatientwithPagination(DataLimit, selectedPage);
    }
  }, [DataLimit, selectedPage]);

  const setPrimaryCard = async (cardId) => dispatch(commonAction.setPrimaryCardAction(cardId));

  const handleDelete = (id) => {
    setDeleteModal(!DeleteModal);
    setDeleteId(id);
  };

  const handleEdit = (id, name, num, month, year, cardtype) => {
    setEditCardModal(!EditCardModal);
    var arr = [];
    arr.push({
      CardId: id,
      CardHolderName: name,
      CardNum: num,
      ExMonth: month,
      ExYear: year,
      CardType: cardtype,
    });
    console.log("arr", arr[0]);
    if (arr.length) {
      setCardData(arr[0]);
    }

    // history.push(`${ROUTES_CONSTANTS.PATIENT_EDIT_CARD_SETTING}/${id}`, arr[0]);
  };
  useEffect(() => {
    console.log("CardData", CardData);
  }, [CardData]);

  const handleSelected = (data) => {
    console.log("clicked page", data);
    setselectedPage(data);
  };

  const handlePageLimit = (e) => {
    console.log("page data", e.target.value);
    setDataLimit(e.target.value);
  };

  const handleCheck = (e, id, index) => {
    let result = e.target.checked;
    console.log(result);
    if (result === true) {
      setCvvToggle(true);
      setCvvIndex(index);
    } else if (result === false) {
      setCvvToggle(false);
    }
  };

  useEffect(() => {
    if (CardListInfo && CardListInfo.documentList && CardListInfo.documentList.length) {
      let index = CardListInfo.documentList.findIndex((item) => item._id === CvvId);
      console.log("indexxx", index);
      setCvvIndex(index);
    }
  }, [CardListInfo, CvvToggle]);

  const callCardListApi = () => {
    CardListPatientwithPagination(DataLimit, selectedPage);
  };

  return (
    <div role="tabpanel" id="Payment" class="tab-pane fade show active">
      <div class="card pat-card-list">
        <div class="card-header align-button">
          <h4>Payment Details</h4>
          <button onClick={handleCard} class="add-new-btn ">
            Add Card Details
          </button>
        </div>
        <div class="card-body">
          <div class="row">
            {CardListInfo?.documentList?.length ? (
              CardListInfo.documentList.map((item, index) => (
                <div className="col-md-6">
                  <div className="card">
                    <div className="card-body">
                      <div className="d-flex justify-content-between">
                        <div>
                          <label>
                            <b>Card Holder Name</b>
                            <br />
                            <span className="default-content-clr">{item && item.cardHolderName ? item.cardHolderName : "--"}</span>
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            type="radio"
                            className="form-check-input"
                            checked={item?.isPrimary ? true : false}
                            onChange={async (e) => {
                              await setPrimaryCard(item?._id);
                              handleCheck(e, item._id, index);
                              ToasterFunc("success", "Added to default card");
                              callCardListApi();
                            }}
                          />
                        </div>
                      </div>
                      <div>
                        <label for="">
                          <b>Card Number</b>
                          <br />
                          <span className="default-content-clr">{item && item.cardNumber ? item.cardNumber.replace(/.(?=.{4})/g, "X") : "--"}</span>
                        </label>
                      </div>

                      <div className="d-flex justify-content-between">
                        <div>
                          <span>
                            <b>Expiry Date : </b>
                            <span className="default-content-clr">
                              {" "}
                              {item.expMonth} / {item.expYear}
                            </span>
                          </span>
                        </div>
                        <div className="card-action">
                          <a onClick={() => handleEdit(item._id, item.cardHolderName, item.cardNumber, item.expMonth, item.expYear, item.cardType)}>
                            <span>
                              <i className="far fa-edit"></i>
                            </span>
                          </a>
                          <a onClick={() => handleDelete(item._id)}>
                            <span>
                              <i className="fas fa-trash-alt c-pointer"></i>
                            </span>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <div className="col-md-12">
                <p className="text-center">No Card added! </p>
              </div>
            )}
          </div>
          {CardListInfo && CardListInfo?.totalList && CardListInfo?.totalList > DataLimit ? (
            <div class="row">
              <div className="col-sm-3 col-md-3">
                <div className="dataTables_length" id="DataTables_Table_0_length">
                  <label>
                    Show{" "}
                    <select name="DataTables_Table_0_length" aria-controls="DataTables_Table_0" className="custom-select custom-select-sm form-control form-control-sm" onChange={(e) => handlePageLimit(e)}>
                      <option value="4">4</option>
                      <option value="8">8</option>
                      <option value="10">10</option>
                    </select>{" "}
                    entries
                  </label>
                </div>
              </div>
              <div class="col-sm-5 col-md-5">
                <div class="dataTables_info showing_pagination" id="DataTables_Table_0_info" role="status" aria-live="polite">
                  Showing {(selectedPage - 1) * DataLimit + 1} to {(selectedPage - 1) * DataLimit + (CardListInfo && CardListInfo.documentList && CardListInfo.documentList.length)} of {CardListInfo && CardListInfo.totalList} entries
                </div>
              </div>
              <div class="col-sm-4 col-md-4">
                <div class="dataTables_paginate paging_simple_numbers" id="DataTables_Table_0_paginate">
                  <PaginationComponent
                    totalItems={CardListInfo && CardListInfo.totalList}
                    pageSize={DataLimit}
                    maxPaginationNumbers={Math.ceil(CardListInfo && CardListInfo.totalList / DataLimit)}
                    defaultActivePage={1}
                    onSelect={(e) => handleSelected(e)}
                  />
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <AddCard CardDetail={CardDetail} setCardDetail={setCardDetail} setisPayment={setisPayment} CardListPatientwithPagination={() => CardListPatientwithPagination()} />
      <EditCard EditCardModal={EditCardModal} setEditCardModal={setEditCardModal} CardData={CardData} CardListPatientwithPagination={() => CardListPatientwithPagination()} />
      <DeleteCard DeleteModal={DeleteModal} setDeleteModal={setDeleteModal} DeleteId={DeleteId} CardListPatientwithPagination={() => CardListPatientwithPagination()} />
    </div>
  );
}

export default PaymentDetail;
