import { commonActionType } from "../../action";
import initialState from "../initialState";

export default function commonReducer(state = initialState.common, action) {
  switch (action.type) {
    case commonActionType.SHOW_LOADER:
      return { ...state, loadingIndicator: action.loadingIndicator };
    case commonActionType.HIDE_LOADER:
      return { ...state, loadingIndicator: action.loadingIndicator };
    case commonActionType.GET_REFFERAL_CODE:
      return { ...state, refferalCodeInfo: action.refferalCodeInfo };
    case commonActionType.GET_REFFERAL_REPORT_LIST:
      return { ...state, refferalReportListInfo: action.refferalReportListInfo };
    case commonActionType.SEARCHED_DOC_LIST:
      return { ...state, getSearchDocdata: action.refferalReportListInfo };
    default:
      return state;
  }
}
