import React, { useContext, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import moment from "moment";
import ChatRight from "./ChatRight";
import { doctor } from "../../../redux/action";
import { useDispatch, useSelector } from "react-redux";
import { SocketContext } from "../../..";
import userIcon from "../../../assets/img/UserIcon.png"
// import socket from "../../../socket/socket";


function Chatting() {
  let location = useLocation();
  console.log('location',location);
  // const [socket, setSocket] = useState(socketData);
  const socket  = useContext(SocketContext);
  const DoctorData = useSelector((state) => state?.doctor?.doctorProdileInfo?.result);

  const DoctorProfileInfo = async () => useDispatch(doctor.doctorProfileDetailsAction());

  const [ChatlistOfDoc, setChatlistOfDoc] = useState([]);
  const [ChatToggle, setChatToggle] = useState(true);
  const [ReceiverDetails, setReceiverDetails] = useState({});
  const [IdData, setIdData] = useState({});
  const [receiverOnline, setreceiverOnline] = useState({});

  console.log('DoctorData',DoctorData);
  let doctorId  = DoctorData && DoctorData[0]?._id
   if (doctorId){
      localStorage.setItem('doctorId' ,doctorId );
   }else{
     doctorId = localStorage.getItem('doctorId');
   }
  useEffect(() => {
    console.log('doctorId',doctorId);
    if (doctorId){
      getSocketConnection();
      getUserOnlineStatus();
      var interval = setInterval(() => {
        getChatList();   
      }, 10000);
    }
 
    return ()=>{
      clearInterval(interval);
      socket.on("disconnect", () => {
        console.log("socket disconnected");
      });
    }
  }, [socket]);
  

  useEffect(() => {
    setIdData(doctorId);
  }, [doctorId]);

  
  

  const getSocketConnection = () => {
    // socket.connect();
    // client-side
    socket.on("connect", () => {
      const payload = {
        senderId: doctorId,
      };
      console.log("socket connected"); 
      console.log('connectUser emitted doctor payload' , payload); // x8WIv7-mJelg7on_ALbx
      socket.emit("connectUser", payload);
      getChatList();
    });

  };
  

  const getUserOnlineStatus = () => {
    socket.on("userOnlineStatus", (arg) => {
      console.log('userOnlineStatus' , arg); 
      setreceiverOnline(arg);
    });
  };

  const getChatList = () => {
    const payload = {
      senderId: doctorId,
    };
    socket.emit("chatList", payload);
    socket.on("chatLists", (data) => {
      console.log("chatLists", data);
      if (data && data.length){
        setChatlistOfDoc(data);
      }
    });
  };

  useEffect(() => {
    console.log('ChatlistOfDoc',ChatlistOfDoc);
  }, [ChatlistOfDoc])
  
  const handleChatDetails = (receiverDetails , chatId ) => {
    console.log('receiverDetails',receiverDetails , 'chatId',chatId);
    setChatToggle(true);
    let obj  = {...receiverDetails};
    obj['chatId'] = chatId;
    setReceiverDetails(obj);
  };

  return (
    <div>
      <div className="tab-content pt-0">
        {/* <!-- chats Content --> */}
        <div role="tabpanel" id="chats" className="tab-pane fade show active">
          {/* <div className="content"> */}
          {/* <div className="container-fluid"> */}
          <div className="row">
            <div className="col-xl-12">
              <div className="chat-window">
                {/* <!-- Chat Left --> */}
                <div className="chat-cont-left">
                  <form className="chat-search">
                    <div className="input-group">
                      {/* <div className="input-group-prepend">
                        <i className="fas fa-search"></i>
                      </div> */}
                      {/* <input type="text" className="form-control" placeholder="Search" /> */}
                    </div>
                  </form>
                  <div className="chat-users-list">
                    <div className="chat-scroll">
                      {ChatlistOfDoc?.length  ? (
                        ChatlistOfDoc.map((item , i ) => (
                          <a key={i} onClick={() => handleChatDetails(item?.lastmsgId?.receiverId , item?._id)} className="media">
                            <div className="media-img-wrap">
                            {/* receiverOnline.status === 1 ? "avatar avatar-online" : "avatar avatar-away */}
                              <div className={receiverOnline.status === 1 ? "avatar" : "avatar"}>
                                <img src={item?.lastmsgId?.receiverId?.profilePic ? item?.lastmsgId?.receiverId?.profilePic : userIcon} alt="" className="avatar-img rounded-circle mb-1" />
                              </div>
                            </div>
                            <div className="media-body">
                              <div>
                                <div className="user-name">{`${item?.lastmsgId?.receiverId?.firstName ? item?.lastmsgId?.receiverId?.firstName : item?.lastmsgId?.receiverId?.fullName}
                                 ${item?.lastmsgId?.receiverId?.lastName}`}</div>
                                {item?.lastmsgId?.receiverId?.isTyping ? (
                                  <div className="msg-typing">
                                    <span></span>
                                    <span></span>
                                    <span></span>
                                  </div>
                                ) : (
                                  <div className="user-last-chat">{item?.lastmsgId?.receiverId?.message ? item?.type ===0 ? item?.lastmsgId?.receiverId?.message : "File": ""}</div>
                                )}
                              </div>
                              <div>
                                <div className="last-chat-time block">{moment(item?.updatedAt).fromNow()}</div>
                                {/* <!-- <div className="badge badge-success badge-pill">15</div> --> */}
                              </div>
                            </div>
                          </a>
                        ))
                      ) : (
                        <div className="media-img-wrap">
                          <p className="text-center">There are currently no chats available.</p>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* <!-- /Chat Left --> */}

                {/* <!-- Chat Right --> */}
                {ChatToggle ? <ChatRight ReceiverDetails={ReceiverDetails}
                ChatlistOfDoc = {ChatlistOfDoc} userDetails = {location}
                getChatList = {getChatList}
                IdData={IdData} doctorId = {doctorId} /> : ""}
                {/* <!-- /Chat Right --> */}
              </div>
            </div>
          </div>
          {/* <!-- /Row --> */}

          {/* </div> */}

          {/* </div> */}
        </div>
      </div>
    </div>
  );
}

export default Chatting;
